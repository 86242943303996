import { fetchJson } from "../../net/fetch";
import { SystemClassListToFlags } from "../schema/system-class";
import { System, SystemElement, SystemConnectionType } from "./Connectivity";
import * as dte from "../DtEventTypes";
import { DtConstants } from "../schema/DtConstants";
import { DtFacility } from "../DtFacility";
import { ConnectivityUtils as CU } from "./ConnectivityUtils";
import { RowKeySize, ElementFlags, QC } from "../schema/dt-schema";
import { base64EncArr, base64DecToArr } from "../encoding/base64";
import { vlqID2ElementKey, elementKey2vlqID } from "../encoding/varint";
import { SystemVisualizer } from "./SystemVisualizer";
import { isNodeJS } from "../../compat";

// base64 encoded "[0]" byte array
const STANDALONE_ELEMENT_CONN_VAL = "AA";

const SYSTEM_CATEGORY = DtConstants.RC['MEP System'];

function encodeSystemFilters(stringsFilter) {
  const res = {};

  if (stringsFilter.systemClasses?.length) {
    res.systemClasses = SystemClassListToFlags(stringsFilter.systemClasses);
  }

  // TODO: Add filter checks here when we allow more system filters

  return res;
}

/**
 * Merge multiple System filters into one to speed up element isolation.
 * @param {object[]} filters List of System.filter
 * @returns {object} A combined System.filter
 */
function mergeSystemFilters(filters) {

  const res = {
    systemClasses: 0
  };

  for (const filter of filters) {
    if (filter.systemClasses) {
      res.systemClasses |= filter.systemClasses;
    }

    // TODO: Add filter merge here when we allow more system filters
  }

  return res;
}

/**
 * Manages the lifecycle of systems at the facility level through API endpoints.
 */
export class SystemsManager {

  /**
   * @constructor
   * @param {DtFacility} facility
   */
  constructor(facility) {
    this.facility = facility;
    this.systemsCache;

    if (!isNodeJS()) {
      this.visualizer = new SystemVisualizer();
    }

    this.boundOnModelChanged = this.onModelChangedEventHandler.bind(this);
  }

  init() {
    this.facility.eventTarget.addEventListener(dte.DT_MODEL_CHANGED_EVENT, this.boundOnModelChanged);
    this.visualizer?.init(this.facility);
  }

  dispose() {
    this.facility.eventTarget.removeEventListener(dte.DT_MODEL_CHANGED_EVENT, this.boundOnModelChanged);
    this.visualizer?.dispose();
    delete this.systemsCache;
  }

  /**
   * Handles model events and dispatch notification if systems changed
   * @param {(string | object)} event
   */
  async onModelChangedEventHandler(event) {
    let systemsChanged = false;

    switch (event.change.ctype) {
      case DtConstants.ChangeTypes.Mutate:
        if (event.change.isOwn) {
          // Process non-defaultModel mutations from the current session
          systemsChanged = await this.onOwnMutateEvent(event);
        } else if (this.systemsCache) {
          systemsChanged = await this.onRemoteMutateEvent(event);
        }
        break;
      case DtConstants.ChangeTypes.UpdateSystemConnections:
        if (this.systemsCache) {
          systemsChanged = await this.onRemoteConnectionsUpdateEvent(event);
        }
        break;
    }

    // Notify of system changes
    if (systemsChanged) {
      this.facility.eventTarget.dispatchEvent({
        type: dte.DT_SYSTEMS_CHANGED_EVENT,
        change: { ctype: DtConstants.ChangeTypes.UpdateSystems }
      });
    }
  }

  /**
   * When a mutation from the current session happens,
   * we need to check whether a system filtered property is affected and mark the affected system(s) as dirty.
   * @param {*} event
   * @returns {boolean} systemsChanged
   */
  async onOwnMutateEvent(event) {
    const eventSystemClasses = event.change.details.systemClassesDiff;
    const isDefaultModelEvent = event.change.modelId === this.facility.getDefaultModelId();
    let systemsChanged = false;

    // Ignore mutations on defaultModel elements since logical system element creation uses mutate
    // No-op if mutation doesn't affect Filtered properties
    if (isDefaultModelEvent || !eventSystemClasses) {
      return systemsChanged;
    }

    if (!this.systemsCache) {
      await this.getAll();
    }

    for (const [_, system] of this.systemsCache) {
      if (system.filter.systemClasses & eventSystemClasses) {
        // Change and filter collide, so system should be remapped and recounted
        await this.setAsDirty(system.id);
        systemsChanged = true;

        if (system.hasCachedConnections()) {
          // Connections are not really updated, we just need to update the
          // connectivity graph so new elements behave like they are part of the system.
          // countSystemElements will be called later
          await this.getConnections(system.id, true);
        } else {
          CU.countSystemElements(this.facility, system);
        }
      }
    }

    return systemsChanged;
  }

  /**
   * Update cached systems when a remote DT_SYSTEM_CONNECTIONS_CHANGED_EVENT is received
   * @param {*} event
   * @returns {boolean} systemsChanged
   */
  async onRemoteConnectionsUpdateEvent(event) {
    let systemsChanged = false;

    // Backend mapping was performed on a system (there should only be one id)
    for (const sysId of event.change.details.ids) {
      systemsChanged = true;
      const system = await this.get(sysId);
      if (system?.hasCachedConnections()) {
        // Only update cached systems
        // TODO: debounce this, otherwise one reload per model with changes.
        system.isDirty = false; // We know this has to be true since a mapping was done on this system
        await this.getConnections(sysId, true);

        this.facility.eventTarget.dispatchEvent({ type: dte.DT_SYSTEM_CONNECTIONS_CHANGED_EVENT, systemId: sysId });
      } else if (system) {
        // No cached connections, needs a recount
        system.isDirty = false; // We know this has to be true since a mapping was done on this system
        CU.countSystemElements(this.facility, system);
      }
    }

    return systemsChanged;
  }

  /**
   * Non-default model: check whether it affects system connections, and update connections of affected systems.
   * Default model: Update the system properties of affected systems
   * @param {*} event
   * @returns {boolean} systemsChanged
   */
  async onRemoteMutateEvent(event) {
    const isDefaultModelEvent = event.change.modelId === this.facility.getDefaultModelId();
    let systemsChanged = false;

    if (!isDefaultModelEvent) {
      /** Connections Edit check */

      // Check if it's system related
      const attrs = event.change.details.attributes;
      // Needs dedup if multiple changes to the same system
      let systemsToUpdate = new Set();

      for (const attr of attrs) {
        const [fam, col] = attr.split(":");

        if (fam === DtConstants.ColumnFamilies.Systems) {
          // Should be an override column, but check just in case
          const sysId = col[0] === "!" ? col.slice(1) : col;
          systemsToUpdate.add(sysId);
        }
      }

      systemsChanged = !!systemsToUpdate.size;

      for (const sysId of systemsToUpdate) {
        const system = this.systemsCache.get(sysId);

        if (system && system.hasCachedConnections()) {
          // TODO: only reload affected elements' connections
          // const changes = event.change.addedElements || event.change.changedElements;
          await this.getConnections(sysId, true);

          this.facility.eventTarget.dispatchEvent({ type: dte.DT_SYSTEM_CONNECTIONS_CHANGED_EVENT, systemId: sysId });
        } else if (system) {
          // No cached connections, needs a recount
          CU.countSystemElements(this.facility, system);
        }
      }
    } else {
      /** Systems info changed */
      const dbIdsChanged = event.change.dbIds ?? [];
      const dbIdsRemoved = event.change.dbIdsRemoved ?? [];

      const systems = [...this.systemsCache.values()];
      const defaultModel = this.facility.getDefaultModel();
      const dbId2flags = defaultModel.getData().dbId2flags;

      // Check if a system's properties changed
      let systemsChanged = dbIdsChanged.some((dbId) => dbId2flags[dbId] === DtConstants.ElementFlags.System);
      // Check if a system was deleted
      systemsChanged ||= systems.some((sys) => dbIdsRemoved.includes(sys.dbId));

      // Probably not worth updating individually, we refetch everything anyway
      if (systemsChanged) {
        // handle updates
        await this.getAll(true);
      }
    }

    return systemsChanged;
  }

  /**
   * Create a new system for the current facility
   * @param {String} name
   * @param {*} stringsFilter
   * @param {Number} tolerance
   * @returns {Promise<System>}
   */
  async create(name, stringsFilter, tolerance) {let mapCenterLines = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

    let defaultModel = this.facility.getDefaultModel();
    let newElementKey = DtConstants.SystemElementFirstKey;

    if (!defaultModel) {
      defaultModel = await this.facility.createDefaultModel();
    } else {
      newElementKey = await defaultModel.getNewSystemElementKey();
    }

    const filter = encodeSystemFilters(stringsFilter);
    const config = { tolerance, mapCenterLines };

    const props = {
      [QC.RowKey]: newElementKey,
      [QC.Name]: name,
      [QC.CategoryId]: SYSTEM_CATEGORY,
      [QC.ElementFlags]: ElementFlags.System,
      [QC.UniformatClass]: "D", // Services
      [QC.SystemClass]: filter.systemClasses,
      [QC.Config]: config,
      [QC.State]: DtConstants.SystemState.Dirty
    };

    await defaultModel.createElementsWithData([props], 'Create system');

    const id = elementKey2vlqID(newElementKey);
    const [dbId] = await defaultModel.getDbIdsFromElementIds([newElementKey]);
    const newSystem = new System(id, dbId, name, filter, tolerance, true, mapCenterLines);
    this.systemsCache.set(id, newSystem);

    // Trigger mapping
    // Wait for the success response so the UI doesn't block if trigger fails
    await this.map(id);

    this.facility.eventTarget.dispatchEvent({
      type: dte.DT_SYSTEMS_CHANGED_EVENT,
      change: {
        ctype: DtConstants.ChangeTypes.CreateSystems
      }
    });

    return newSystem;
  }

  /**
   * @param {String} id
   * @param {Boolean} forceRefetch
   * @returns {Promise<System>}
   */
  async get(id) {let forceRefetch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    if (this.systemsCache?.has(id) && !forceRefetch) {
      return this.systemsCache.get(id);
    }

    // Refresh cached values
    await this.getAll(true);

    return this.systemsCache.get(id);
  }

  /**
   * @param {Boolean} forceRefetch
   * @returns {Promise<Array<System>>}
   */
  async getAll() {let forceRefetch = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

    if (this.systemsCache && !forceRefetch) {
      return [...this.systemsCache.values()];
    }

    const defaultModel = this.facility.getDefaultModel();
    const newCache = new Map();

    if (defaultModel) {
      // Wait for default model and its instance tree
      await defaultModel.waitForLoad(false, true);

      const dmSystems = await defaultModel.getLogicalSystemElements();

      // Populate new cache with fetched systems
      for (const id in dmSystems) {
        const { dbId, name, filter, tolerance, isDirty, mapCenterLines } = dmSystems[id];
        const fetchedSystem = new System(id, dbId, name, filter, tolerance, isDirty, mapCenterLines);

        const cached = this.systemsCache?.get(id);
        if (cached) {
          // Preserve cached connections, if any
          fetchedSystem.connections = cached.connections;
        }

        newCache.set(id, fetchedSystem);
      }
    }

    this.systemsCache = newCache;
    return [...this.systemsCache.values()];
  }

  /**
   * @param {String} id
   * @param {String} name
   * @param {*} stringsFilter
   * @param {Number} tolerance
   * @returns {Promise<System>}
   */
  async update(id, name, stringsFilter, tolerance) {let mapCenterLines = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

    const defaultModel = this.facility.getDefaultModel();
    if (!defaultModel) {
      return;
    }

    const filter = encodeSystemFilters(stringsFilter);
    const current = await this.get(id);

    let hasChange;
    let isDirty = false;
    const changes = {};
    // Find/store diffs
    if (name !== current.name) {
      hasChange = true;
      changes[QC.Name] = name;
    }

    if (filter.systemClasses !== current.filter.systemClasses) {
      isDirty = true;
      hasChange = true;
      changes[QC.SystemClass] = filter.systemClasses;
    }

    // If one value changes in the config column, the entire blob has to be updated
    if (tolerance !== current.tolerance || mapCenterLines !== current.mapCenterLines) {
      // Trigger mapping if tolerance change is above threshold to avoid no-diff changes
      isDirty ||= Math.abs(tolerance, current.tolerance) > 0.001;
      // Trigger mapping if centerline config is changed and system contains centerlines
      isDirty ||= mapCenterLines !== current.mapCenterLines && CU.hasCenterlines(this.facility, filter);

      hasChange = true;

      changes[QC.Config] = { tolerance, mapCenterLines };
    }

    if (hasChange) {
      changes[QC.LmvDbId] = current.dbId;

      if (!current.isDirty && isDirty) {
        changes[QC.State] = DtConstants.SystemState.Dirty;
      }

      await defaultModel.updateElementsWithData([changes], 'Update system');
    }

    // In case the system is already dirty, and the current update doesn't trigger mapping
    current.isDirty ||= isDirty;
    current.name = name;
    current.filter = filter;
    current.tolerance = tolerance;
    current.mapCenterLines = mapCenterLines;

    if (isDirty) {
      // Wait for the success response so the UI doesn't block if trigger fails
      await this.map(id);
    }

    this.facility.eventTarget.dispatchEvent({
      type: dte.DT_SYSTEMS_CHANGED_EVENT,
      change: {
        ctype: DtConstants.ChangeTypes.UpdateSystems,
        systemId: id,
        isPostProcessing: isDirty
      }
    });

    return current;
  }

  /** @param {string[]} ids */
  async deleteMultiple(ids) {
    const defaultModel = this.facility.getDefaultModel();
    if (!defaultModel) {
      return;
    }

    const deleteDbIds = [];
    for (const id of ids) {
      const system = await this.get(id);
      if (system) {
        deleteDbIds.push(system.dbId);
      }
    }

    if (!deleteDbIds.length) {
      return;
    }

    // Create mutation to mark default model system elements as deleted
    await defaultModel.deleteElements(deleteDbIds, 'system-delete');

    ids.forEach((id) => this.systemsCache.delete(id));

    this.facility.eventTarget.dispatchEvent({
      type: dte.DT_SYSTEMS_CHANGED_EVENT,
      change: {
        ctype: DtConstants.ChangeTypes.DeleteSystems
      }
    });
  }

  async map(id) {
    const defaultModel = this.facility.getDefaultModel();
    if (!defaultModel) {
      return;
    }

    const elementKey = vlqID2ElementKey(id);
    return await fetchJson(
      defaultModel.loadContext,
      `/models/${defaultModel.urn()}/systems/${elementKey}/map`,
      'POST'
    );
  }

  /**
   * Retrieve connections for specified system
   * @param {string} id
   * @param {boolean} forceRefetch
   * @returns {Promise<System>} Stored system
   */
  async getConnections(id) {let forceRefetch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    const system = await this.get(id);

    if (system?.hasCachedConnections() && !forceRefetch) {
      // Already loaded, assuming it's being kept up-to-date
      return system;
    }

    // Cleanup previous connections and elements counter
    system.clear();

    // Determine which elements (by dbId) are part of the system
    const dbIdsByURN = CU.getFilteredDbIdsByURN(this.facility, system.filter);

    // Cache models to avoid getting from array each time when building connections
    // and create a promise of dbId -> connections/refs for each model
    const modelsData = {};
    for (const urn in dbIdsByURN) {
      const model = this.facility.getModelByUrn(urn);
      await model.waitForLoad(false, true);
      modelsData[urn] = {
        model,
        data: model.getDbIdToConns(dbIdsByURN[urn], system.id)
      };
    }

    // Build stored system
    for (const urn in dbIdsByURN) {
      // Wait for element model promise
      const { model, data } = modelsData[urn];
      const dbId2xConns = (await data)[0];

      for (const dbId of dbIdsByURN[urn]) {
        let element = system.getElement(urn, dbId);
        if (!element) {
          // Create&Add element to the system
          element = new SystemElement(model, dbId);
          system.addElement(element);
        }

        // Iterate through and create all connections
        for (const [connUrn, connElementID] of dbId2xConns[dbId]) {
          if (!modelsData[connUrn]) {
            // Asking for connections of a model that was likely filtered out.
            continue;
          }
          // Wait for element neighbour's model promise
          const { model: connModel, data: connData } = modelsData[connUrn];
          const resolvedRefs = (await connData)[1];

          const connDbId = resolvedRefs[connElementID];

          if (!connDbId) {
            // Neighbour's model promise determined that element is not part of the system
            // Most likely because it's a connection to an element that was removed due to
            // a filtered property change, just ignore it.
            continue;
          }

          let connectedElement = system.getElement(connUrn, connDbId);
          if (!connectedElement) {
            // Create&Add element to the system
            connectedElement = new SystemElement(connModel, connDbId);
            system.addElement(connectedElement);
          }

          // Add connection with element in the current system
          element.connect(connectedElement, SystemConnectionType.Next);
          connectedElement.connect(element, SystemConnectionType.Previous);
        }
      }
    }

    CU.countSystemElements(this.facility, system);

    return system;
  }

  /**
   * Call after finalizing changes locally. Store user changes to a System's connections.
   * @param {string} id
   * @param {SystemElement[]} elements
   * @return {Promise<*>[]} Array of promises from DtModel.prototype.updateElementsWithData
   */
  async updateConnections(id, elements) {

    if (!elements?.length) {
      return;
    }

    const system = await this.get(id);

    if (elements.some((e) => !system.hasElement(e.model.urn(), e.dbId))) {
      // Bail if some elements don't belong to the system
      console.error(`Failed to store System "${system.id}": System/Elements mismatch`);
      return;
    }

    const dbIdsByURN = CU.getElementsDbIdsByURN(elements);
    const fullIds = await this.facility.encodeIds(dbIdsByURN, true);

    const mutations = {};
    const overrideQC = DtConstants.ColumnFamilies.Systems + ":!" + system.id;
    let tempBuffer = new Uint8Array(RowKeySize);

    for (const element of elements) {
      const urn = element.model.urn();
      let perModel = mutations[urn] = mutations[urn] ?? [];

      const changeData = { [DtConstants.QC.LmvDbId]: element.dbId };

      // Store as directed graph
      const connCount = element.next.size;
      if (tempBuffer.length < connCount * RowKeySize) {
        tempBuffer = new Uint8Array(connCount * RowKeySize);
      }

      // We update override columns for every element in the change, so no need to ensure diff between stored/loaded.
      let offset = 0;
      for (const conn of element.next) {
        const fullId = fullIds[conn.model.urn()][conn.dbId];
        base64DecToArr(fullId, tempBuffer, offset);
        offset += RowKeySize;
      }

      if (connCount === 0) {
        // Empty list
        changeData[overrideQC] = STANDALONE_ELEMENT_CONN_VAL;
      } else {
        changeData[overrideQC] = base64EncArr(tempBuffer, 0, offset);
      }

      perModel.push(changeData);
    }

    const changePromises = [];

    for (const urn in mutations) {
      const model = this.facility.getModelByUrn(urn);
      const promise = model.updateElementsWithData(mutations[urn], `Update system (${system.id}) connections`);

      changePromises.push(promise);
    }

    const result = await Promise.all(changePromises);

    this.facility.eventTarget.dispatchEvent({ type: dte.DT_SYSTEM_CONNECTIONS_CHANGED_EVENT, systemId: system.id });

    return result;
  }

  /**
   * Mark system as dirty to indicate remapping is needed
   * Note the only way to mark system as not dirty is by remapping, in the importer
   * @param {String} id SystemID
   */
  async setAsDirty(id) {
    const defaultModel = this.facility.getDefaultModel();
    if (!defaultModel) {
      return;
    }

    const system = await this.get(id);

    if (!system || system.isDirty) {
      return;
    }

    await defaultModel.updateElementsWithData([{
      [QC.LmvDbId]: system.dbId,
      [QC.State]: DtConstants.SystemState.Dirty
    }], 'Update system state');

    system.isDirty = true;
  }
}