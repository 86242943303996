
import { isNodeJS, getGlobal } from "../compat";

const _window = getGlobal();

var endp = {};

var CDN_ROOT = null;
endp.ENDPOINT_API_TANDEM_V1 = 'dt';

var _apis_data = {
  [endp.ENDPOINT_API_TANDEM_V1]: {
    baseURL: '/modeldata',
    itemURL: '/modeldata/:derivativeurn',
    //thumbnailsURL: '/derivativeservice/v2/thumbnails/:urn',
    cdnURL: '/cdn',
    cdnWS: '/cdnws',
    msgWS: '/msgws',
    fragsWS: '/fragsws'
  }
};

var _endpoint = '';
var _api = endp.ENDPOINT_API_TANDEM_V1;
var _useCredentials = false;
var _useCookie = false;

endp.HTTP_REQUEST_HEADERS = {};
endp.queryParams = {};

/**
 * Sets the endpoint and api to be used to create REST API request strings.
 * @param {string} endpoint
 * @param {string} [api] - Possible values are derivativeV2, modelDerivativeV2
 */
endp.setEndpointAndApi = function (endpoint, api) {
  if (endpoint) {
    _endpoint = endpoint;
  }
  if (api) {
    _api = api;
  }
};

/**
 * Returns the endpoint plus the api used to create REST API request strings.
 * Example: "developer.api.autodesk.com/modelderivative/v2"
 * @returns {string}
 */
endp.getEndpointAndApi = function () {
  return _endpoint + _apis_data[_api].baseURL;
};

/**
 * Returns the endpoint used to create REST API request strings.
 * Examples: "developer.api.autodesk.com"
 * @returns {string}
 */
endp.getApiEndpoint = function () {
  return _endpoint;
};

/**
 * @private
 * @returns {string}
 */
endp.getApiFlavor = function () {
  return _api;
};

/**
 * Returns the default shared resource CDN location.
 * For best performance (and to not overload our servers), this should
 * be replaced by a direct CloudFront url during initialization, by
 * calling the cdnRedirectUrl and looking at the result.
 */
endp.getCdnUrl = function () {
  return CDN_ROOT || (_endpoint ? _endpoint + _apis_data[_api].cdnURL : undefined);
};

endp.getWebSocketEndpoint = function (which) {
  return _endpoint + (_apis_data[_api][which] || '');
};

endp.setCdnUrl = function (url) {
  CDN_ROOT = url;
};

/**
 * Returns a REST API request strings to be used to get a derivative urn.
 * Example: "developer.api.autodesk.com/modelderivative/v2/designdata/:urn/manifest/:derivativeUrn"
 * @param {string | null} endpoint - When provided is used instead of the globally set API endpoint.
 * @param {string} derivativeUrn
 * @param {string} api - When provided is used instead of the globally set API flavor
 * @returns {string}
 */
endp.getItemApi = function (endpoint, derivativeUrn, api) {
  var theApi = api || _api;
  var itemApi = (endpoint || _endpoint) + _apis_data[theApi].itemURL;
  // If urn is not provided we return same string that before for backward compatibility.
  derivativeUrn = derivativeUrn || '';

  itemApi = itemApi.replace(':derivativeurn', derivativeUrn);

  return itemApi;
};

endp.getUseCredentials = function () {
  return _useCredentials;
};

/**
 * Adds a URL parameter that will be used in all data load requests.
 * @param {string} param - The name of the parameter
 * @param {string} value - The value of the parameter. It will be URI encoded when constructing the final URL.
 */
endp.addQueryParam = function (param, value) {
  this.queryParams[param] = value;
};

/**
 * Deletes a previously specified URL parameter.
 * @param {string} param - The name of the parameter to delete
 */
endp.deleteQueryParam = function (param) {
  delete this.queryParams[param];
};

endp.getQueryParams = function (inputObj) {

  let qParam = inputObj.api !== "dt" && this.getUseCredentials() && !isNodeJS() ? "domain=" + encodeURIComponent(_window.location.origin) : "";

  let addedParams = [];
  for (let p in this.queryParams) {
    addedParams.push(encodeURIComponent(p) + "=" + encodeURIComponent(this.queryParams[p]));
  }

  if (addedParams.length) {
    if (qParam)
    qParam += "&" + addedParams.join("&");else

    qParam = addedParams.join("&");
  }

  if (qParam && inputObj) {
    if (inputObj.queryParams) {
      inputObj.queryParams += "&" + qParam;
    } else {
      inputObj.queryParams = qParam;
    }
  }

  return qParam;
};

endp.setUseCredentials = function (useCredentials) {
  _useCredentials = useCredentials;
};

endp.setUseCookie = function (useCookie) {
  _useCookie = useCookie;
};

endp.getUseCookie = function () {
  return _useCookie;
};

endp.initLoadContext = function (inputObj) {

  inputObj = inputObj || {};

  inputObj.auth = this.getUseCredentials();

  if (!inputObj.endpoint)
  inputObj.endpoint = this.getApiEndpoint();

  if (!inputObj.api)
  inputObj.api = this.getApiFlavor();

  if (!inputObj.headers)
  inputObj.headers = {};

  for (var p in this.HTTP_REQUEST_HEADERS) {
    inputObj.headers[p] = this.HTTP_REQUEST_HEADERS[p];
  }

  //This is done to avoid CORS errors on content served from proxy or browser cache
  //The cache will respond with a previously received response, but the Access-Control-Allow-Origin
  //response header might not match the current Origin header (e.g. localhost vs. developer.api.autodesk.com)
  //which will cause a CORS error on the second request for the same resource.
  this.getQueryParams(inputObj);

  //shared geometry/material storage
  inputObj.otg_cdn = CDN_ROOT || this.getCdnUrl();
  inputObj.otg_ws = this.getWebSocketEndpoint("cdnWS");
  inputObj.msg_ws = this.getWebSocketEndpoint("msgWS");
  inputObj.frags_ws = this.getWebSocketEndpoint("fragsWS");

  return inputObj;
};

//TODO: Globals that need a better place
var _env; //formerly avp.env
export function getEnv() {
  return _env;
}
export function setEnv(env) {
  _env = env;
}

// Set viewer in offline mode if set to true. In offline mode, viewer would ignore all URNs in bubble JSON
// and assume the viewables are laid out in local file system path relative to the bubble.json.
var _offline = false;
export function isOffline() {
  return _offline;
}
export function setOffline(offline) {
  _offline = offline;
}

export let endpoint = endp;

//For backwards compatibility until all code is converted to use
//the function from the endpoint instance.
export let initLoadContext = endp.initLoadContext.bind(endp);