
function arrayToIntBuffer(a, maxVal) {
  let b;
  if (maxVal < 256) {
    b = new Uint8Array(a.length);
  } else if (maxVal < 65536) {
    b = new Uint16Array(a.length);
  } else {
    b = new Uint32Array(a.length);
  }

  b.set(a);
  return b;
}

export class FlatStringStorage {

  constructor(initial) {
    if (initial) {
      this.buf = initial.buf;
      this.idx = initial.idx;
      this.next = initial.next;
      this.sid2idx = initial.sid2idx;
    } else {
      this.buf = "";
      this.next = 0;
      this.idx = [0];
      this.sid2idx = [];
      this.s2i = new Map();
      this.maxIdx = 0;
    }
  }

  add(s, id) {
    let idx = this.s2i.get(s);

    if (idx !== undefined) {
      this.sid2idx[id] = idx;
      return idx;
    }

    if (s === null || typeof s === "undefined") {
      idx = 0;
    } else if (!s.length) {
      this.idx.push(this.next);
      idx = this.idx.length - 1;
    } else {
      this.buf += s;
      this.next += s.length;
      this.idx.push(this.next);
      idx = this.idx.length - 1;
    }

    this.sid2idx[id] = idx;
    this.s2i.set(s, idx);

    if (this.maxIdx < idx) {
      this.maxIdx = idx;
    }

    return idx;
  }

  get(i) {
    if (!i) {
      return undefined;
    }

    let start = this.idx[i - 1];
    let end = this.idx[i];
    if (start === end)
    return "";
    return this.buf.substring(start, end);
  }

  getById(id) {
    return this.get(this.sid2idx[id]);
  }

  /** If the ID would return a non-empty string. */
  hasById(id) {
    let i = this.sid2idx[id];
    return i && this.idx[i - 1] !== this.idx[i];
  }

  flatten() {
    this.s2i = null;
    this.idx = arrayToIntBuffer(this.idx, this.next);
    this.sid2idx = arrayToIntBuffer(this.sid2idx, this.maxIdx);
  }

  addTransferBuffers(transferList) {
    transferList.push(this.idx.buffer);
    transferList.push(this.sid2idx.buffer);
  }
}