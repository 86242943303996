
//Base class for simple uniform buffers
export class UniformBuffer {

  #device;

  #bufferCPU;
  #bufferCPUInt;

  #buffer;
  #layout;
  #bindGroup;

  #visibility;

  #dirty = false;

  constructor(device, sizeInFloats) {let visibleInFragment = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;let visibleInVertex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    this.#device = device;

    let roundedSize = sizeInFloats;
    let rm = sizeInFloats % 4;
    if (rm) {
      roundedSize += 4 - rm;
    }

    this.#bufferCPU = new Float32Array(roundedSize);
    this.#bufferCPUInt = new Int32Array(this.#bufferCPU.buffer);

    let visibility = 0;
    if (visibleInFragment) {
      visibility |= GPUShaderStage.FRAGMENT;
    }

    if (visibleInVertex) {
      visibility |= GPUShaderStage.VERTEX;
    }

    this.#visibility = visibility;

    if (this.#device) {
      this.#createResources();
    }

  }

  #createResources() {
    if (this.#buffer) {
      return;
    }

    this.#buffer = this.#device.createBuffer({
      size: this.#bufferCPU.byteLength,
      usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST
    });

    this.#layout = this.#device.createBindGroupLayout({
      entries: [
      {
        binding: 0,
        visibility: this.#visibility,
        buffer: {}
      }]

    });

    this.#bindGroup = this.#device.createBindGroup({
      layout: this.#layout,
      entries: [
      {
        binding: 0,
        resource: {
          buffer: this.#buffer
        }
      }]

    });

  }


  setDevice(device) {
    this.#device = device;
    this.#createResources();
  }

  getLayout() {
    return this.#layout;
  }

  getBindGroup() {
    return this.#bindGroup;
  }

  getBuffer() {
    return this.#buffer;
  }

  setFloat(offset, value) {
    this.#bufferCPU[offset] = value;
  }

  getFloat(offset) {
    return this.#bufferCPU[offset];
  }

  setFloat2(offset, x, y) {
    this.#bufferCPU[offset] = x;
    this.#bufferCPU[offset + 1] = y;
  }

  setFloat4(offset, x, y, z, w) {
    this.#bufferCPU[offset] = x;
    this.#bufferCPU[offset + 1] = y;
    this.#bufferCPU[offset + 2] = z;
    this.#bufferCPU[offset + 3] = w;
  }

  setVector3(offset, value) {
    this.#bufferCPU[offset] = value.x;
    this.#bufferCPU[offset + 1] = value.y;
    this.#bufferCPU[offset + 2] = value.z;
  }

  setVector4(offset, value) {
    this.#bufferCPU[offset] = value.x;
    this.#bufferCPU[offset + 1] = value.y;
    this.#bufferCPU[offset + 2] = value.z;
    this.#bufferCPU[offset + 3] = value.w;
  }

  setColor(offset, color, opacity) {
    this.#bufferCPU[offset] = color.r;
    this.#bufferCPU[offset + 1] = color.g;
    this.#bufferCPU[offset + 2] = color.b;

    if (opacity !== undefined) {
      this.#bufferCPU[offset + 3] = opacity;
    }
  }

  setMatrix3x3(offset, src) {
    //Note the 4-float padding on the destination
    for (let i = 0; i < 3; i++)
    for (let j = 0; j < 3; j++)
    this.#bufferCPU[offset + j + i * 4] = src[j + i * 3];
  }

  setInt = function (offset, value) {
    this.#bufferCPUInt[offset] = value;
  };

  getInt(offset) {
    return this.#bufferCPUInt[offset];
  }

  setBuffer(offset, buffer) {
    this.#bufferCPU.set(buffer, offset);
  }

  upload() {
    this.#device.queue.writeBuffer(
      this.#buffer,
      0,
      this.#bufferCPU.buffer,
      0,
      this.#bufferCPU.byteLength
    );
  }

}