import { flyToView } from "../tools/viewtransitions/ViewTransition";
import * as et from "../application/EventTypes";
import { DT_PRIMARY_MODEL_LOADED, DT_FACETS_LOADED } from "./DtEventTypes";
import { getDefaultFacetList, FacetTypes } from "./facets/Facets";

const THREE = require("three");

const HalfPI = Math.PI * 0.5;

function updateFovAndIsPerspective(sceneCamera, viewCamera) {
  if (viewCamera.fov !== undefined && sceneCamera.fov !== viewCamera.fov) {
    sceneCamera.setFov(viewCamera.fov);
  }

  if (viewCamera.isPerspective && !sceneCamera.isPerspective) {
    sceneCamera.toPerspective();
  } else if (!viewCamera.isPerspective && sceneCamera.isPerspective) {
    sceneCamera.toOrthographic();
  }
}

function fuzzyEqual(a, b) {let eps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1e-4;
  return Math.abs(b - a) < eps;
}

function _showElementsForView(facility, view, currentView) {
  function onAllFacetsLoaded() {
    // this callback will be executed when user opens some facility and switches a view while loading isn't done yet
    // we have to wait until all models for the previous view are loaded and show all elements for them, and
    // hide all hidden elements for the current view
    if (currentView?.hiddenElements) {
      facility.setHiddenElementsForView(currentView?.hiddenElements);
    }

    if (facility.eventTarget.hasEventListener(DT_FACETS_LOADED, onAllFacetsLoaded)) {
      facility.eventTarget.removeEventListener(DT_FACETS_LOADED, onAllFacetsLoaded);
    }
  }

  const someNotLoaded = facility.
  getModels().
  map((m) => !m.isLoadDone()).
  some(Boolean);

  // view loading, this.currentView could be yet undefined
  if (view.hiddenElements) {
    facility.setHiddenElementsForView(view.hiddenElements);
  }

  if (someNotLoaded) {
    facility.eventTarget.addEventListener(DT_FACETS_LOADED, onAllFacetsLoaded);
  }
}

/**
 * Update ViewCubeUi extension's current face with current camera position
 * @param {Autodesk.Viewing.Viewer3D} viewer
 */
function updateViewCubeCurrentFace(viewer) {

  // TODO: Expose this function from LMV (this code is copy-pasted from ViewCube constructor)
  const updateViewCube = () => {
    const EPSILON = 0.00001;
    const viewCubeUi = viewer.getExtension('Autodesk.ViewCubeUi');
    const viewCube = viewCubeUi.cube;
    if (!viewCube) {
      return;
    }

    const cam = viewer.autocam;
    const camera = cam.camera;

    const viewDir = cam.center.clone().sub(camera.position).normalize();
    const sceneRight = cam.sceneFrontDirection.clone().cross(cam.sceneUpDirection);
    const dotUp = viewDir.dot(cam.sceneUpDirection);
    const dotFront = viewDir.dot(cam.sceneFrontDirection);
    const dotRight = viewDir.dot(sceneRight);

    if (1 - Math.abs(dotUp) < EPSILON) {
      viewCube.currentFace = dotUp > 0 ? 'bottom' : 'top';
    } else if (1 - Math.abs(dotFront) < EPSILON) {
      viewCube.currentFace = dotFront > 0 ? 'front' : 'back';
    } else if (1 - Math.abs(Math.abs(dotRight)) < EPSILON) {
      viewCube.currentFace = dotRight > 0 ? 'left' : 'right';
    } else {
      viewCube.currentFace = 'front';
    }
  };

  function onExtensionLoaded(evt) {
    if (evt.extensionId === 'Autodesk.ViewCubeUi') {
      updateViewCube();
      viewer.removeEventListener(et.EXTENSION_LOADED_EVENT, onExtensionLoaded);
    }
  }

  if (viewer.getExtension('Autodesk.ViewCubeUi')) {
    updateViewCube();
  } else {
    viewer.addEventListener(et.EXTENSION_LOADED_EVENT, onExtensionLoaded);
  }
}


function setToolbarsClickable(viewer) {
  if (viewer.modelTools) {
    viewer.modelTools.container.style['pointerEvents'] = "";
    viewer.navTools.container.style['pointerEvents'] = "";
    viewer.settingsTools.container.style['pointerEvents'] = "";
    viewer.container.style['pointerEvents'] = "";
  }
}

function setToolbarsUnclickable(viewer) {
  if (viewer.modelTools) {
    viewer.modelTools.container.style['pointerEvents'] = "none";
    viewer.navTools.container.style['pointerEvents'] = "none";
    viewer.settingsTools.container.style['pointerEvents'] = "none";
    viewer.container.style['pointerEvents'] = "none";
  }
}

function isMissingWorldUp(worldup) {
  if (!worldup) {
    return true;
  }

  return worldup.x === 0 && worldup.y === 0 && worldup.z === 0;
}

//Restore THREE.js object prototypes in case the input is raw JSON
function restoreCameraObjects(camera) {

  if (!camera) {
    return null;
  }

  return {
    position: new THREE.Vector3().copy(camera.position),
    target: new THREE.Vector3().copy(camera.target),
    up: new THREE.Vector3().copy(camera.up),
    isPerspective: camera.isPerspective,
    fov: camera.fov,
    worldup: isMissingWorldUp(camera.worldup) ?
    new THREE.Vector3(0, 0, 1) :
    new THREE.Vector3().copy(camera.worldup)
  };
}

//Restore THREE.js object prototypes in case the input is raw JSON
function restoreCutplaneObjects(cutPlanes) {

  if (!cutPlanes) {
    return [];
  }

  return cutPlanes.map((_ref) => {let { x, y, z, w } = _ref;return new THREE.Vector4(x, y, z, w);});
}

export class DtViewerState {

  static flyToView(facility, view) {let duration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2.0;let transformCamera = arguments.length > 3 ? arguments[3] : undefined;let CB = arguments.length > 4 ? arguments[4] : undefined;
    facility.viewAnimControl?.stop();

    let cc = restoreCameraObjects(view.camera);
    if (transformCamera) {
      let xform = facility.getSharedToLocalSpaceTransform(view.version < 2);
      cc.position = cc.position.clone().applyMatrix4(xform);
      cc.target = cc.target.clone().applyMatrix4(xform);
      cc.up = cc.up.clone().transformDirection(xform);
    }

    // View cube currentFace is only updated when the user clicks on a face.
    // In our case, a user might save a view of a specific face (ex "Top") and we need to update the cube state
    // after the camera transition.
    // currentFace is needed by the cube to know what is the next face to show when clicking on the directional arrows
    const onFinished = () => {
      facility.viewAnimControl = null;
      updateViewCubeCurrentFace(facility.viewer);
      if (CB) {
        CB();
      }
    };

    const angle = cc.up.angleTo(facility.viewer.impl.camera.worldup);

    const worldUpAligned = !(fuzzyEqual(angle, 0.0) || fuzzyEqual(angle, HalfPI) || fuzzyEqual(angle, Math.PI));

    facility.viewAnimControl = flyToView(facility.viewer, cc, duration, onFinished, worldUpAligned);
  }

  static async updateCutPlanes(facility, view) {
    const ext = facility.viewer.getExtension('Autodesk.Section');

    await facility.viewer.impl.waitForLoaderIdle();

    let cutPlanes = restoreCutplaneObjects(view.cutPlanes);
    cutPlanes = cutPlanes.map((v) => {
      let pl = new THREE.Plane().setComponents(v.x, v.y, v.z, v.w);
      pl.applyMatrix4(facility.getSharedToLocalSpaceTransform(view.version < 2));
      return new THREE.Vector4(pl.normal.x, pl.normal.y, pl.normal.z, pl.constant);
    });

    // Unload any existing planes first
    ext.setSectionFromPlane(null);
    if (cutPlanes?.length === 1) {
      ext.setSectionFromPlane(cutPlanes[0]);
    } else if (cutPlanes?.length === 6) {
      ext.setBoxFromPlanes(cutPlanes);
    }
  }


  static async getCurrentView(facility) {

    const camera = facility.viewer.getCamera();

    const hiddenElements = await Promise.all(
      facility.getHiddenElementsByModel().map(async (node) => {
        const eids = await node.model.getElementIdsFromDbIds(node.ids);

        return {
          ids: eids.filter((i) => i),
          modelUrn: node.model.urn()
        };
      })
    );

    let cameraForView = {
      position: camera.position.clone(),
      target: camera.target.clone(),
      up: camera.up.clone(),
      isPerspective: camera.isPerspective,
      fov: camera.fov,
      worldup: camera.worldup
    };

    let cutPlanes = facility.viewer.getExtension('Autodesk.Section')?.getSectionPlanes();
    let heatmap = facility.streamMgr?.dataVizToolbarCtrl?.heatmapCfgUi?.getStateForView();

    let xform = facility.getLocalToSharedSpaceTransform(false);
    cameraForView.position = cameraForView.position.clone().applyMatrix4(xform);
    cameraForView.target = cameraForView.target.clone().applyMatrix4(xform);
    cameraForView.up = cameraForView.up.clone().transformDirection(xform);

    cutPlanes = cutPlanes.map((v) => {
      let pl = new THREE.Plane().setComponents(v.x, v.y, v.z, v.w);
      pl.applyMatrix4(xform);
      return new THREE.Vector4(pl.normal.x, pl.normal.y, pl.normal.z, pl.constant);
    });

    let hud = facility.getHUD()?.getStateForView();

    return {
      camera: cameraForView,
      cutPlanes,
      heatmap,
      hiddenElements: hiddenElements,
      facets: {
        filters: facility.facetsManager.getFilterMap(),
        settings: facility.facetsManager.getFacetDefs().map((facetDef) => facetDef.getSettings()),
        hiddenCategories: facility.facetsManager.hiddenCategories,
        useTopLevel: facility.facetsManager.options.useTopLevel
      },
      hud
    };
  }

  /* Creates a base view without loading the facility first. */
  static getBaseView(facility) {
    let settings = getDefaultFacetList(true).map((ctor) => new ctor().getSettings());
    let filters = {};
    for (const s of settings) {
      filters[s.id] = new Set();
    }
    for (const model of facility.getModels()) {
      filters[FacetTypes.models].add(model.urn());
    }

    return {
      cutPlanes: [],
      heatmap: {},
      hiddenElements: [],
      facets: {
        filters: filters,
        settings: settings,
        isFloorplanEnabled: false,
        hiddenCategories: []
      }
    };
  }

  static async setView(facility, view, currentView) {
    facility.deactivateExtensions();
    if (!view) {
      facility.facetsManager.viewId = undefined;
      return;
    }

    facility.facetsManager.viewRestoreInProgress = true;
    facility.facetsManager.viewId = view.id;

    // Restore facets settings before any model is loaded
    // to prevent default facets from being used during asynchronous model loading
    let facetsSettings = view.facets?.settings;

    if (!facetsSettings) {
      facetsSettings = getDefaultFacetList(true).map((ctor) => new ctor().getSettings());
    }

    await facility.facetsManager.setSettings(facetsSettings, view.facets?.hiddenCategories, view.facets?.useTopLevel);

    const viewer = facility.viewer;

    const setCurrentViewImpl = async () => {

      if (!facility.globalOffset) {
        return;
      }

      // restore camera
      if (view.camera) {
        updateFovAndIsPerspective(viewer.getCamera(), view.camera);

        DtViewerState.flyToView(facility, view, undefined, true);
      }

      // Restore cut planes
      if (viewer.getExtension('Autodesk.Section')) {
        DtViewerState.updateCutPlanes(facility, view);
      } else {
        viewer.loadExtension('Autodesk.Section').then((sectionExt) => {
          if (!sectionExt) {
            console.warn('unexpected section extension unavailable');
            return;
          }
          DtViewerState.updateCutPlanes(facility, view);
        });
      }

      // Restore heatmaps
      facility.streamMgr?.dataVizToolbarCtrl?.heatmapCfgUi?.setStateFromView(view.heatmap);

      // Restore facets filters
      const filters = view.facets?.filters;
      if (filters) {
        facility.facetsManager.setFilterMap(filters);
      } else {
        //If there is a filter in this view, it will
        //trigger updateFacets() inside setFilterMap(),
        //but if not, we call it here.
        facility.facetsManager.updateFacets();
      }

      // Restore hiddenElements
      _showElementsForView(facility, view, currentView);

      facility.facetsManager.viewRestoreInProgress = false;
    };

    // here we wait until at least the primary model's root is loaded
    // cause it will trigger the internal camera setup ("Viewer3D#setViewFromCamera()")
    // which we want to override with the view's camera position etc.
    if (!facility.getPrimaryModel().isLoadDone()) {
      return new Promise((resolve) => {
        const onModelRootLoaded = () => {
          facility.eventTarget.removeEventListener(DT_PRIMARY_MODEL_LOADED, onModelRootLoaded);
          setCurrentViewImpl().then(resolve);
        };

        facility.eventTarget.addEventListener(DT_PRIMARY_MODEL_LOADED, onModelRootLoaded);
      });
    }

    await setCurrentViewImpl();
  }

  static addBlockingDiv(viewer) {let message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Loading";
    const box = document.createElement("div");
    box.id = "dt-viewer-loading__overlay";
    box.className = "dt-viewer-loading__overlay";

    const messageBox = document.createElement("div");
    messageBox.className = "dt-viewer-loading__overlay-message";
    messageBox.innerHTML = message;
    box.appendChild(messageBox);

    const viewerContainer = viewer.container;
    viewerContainer.appendChild(box);

    viewer._loadingSpinner.show();

    setToolbarsUnclickable(viewer);
  }

  static removeBlockingDiv(viewer) {
    const viewerContainer = viewer.container;
    const child = document.getElementById("dt-viewer-loading__overlay");
    if (child) {
      viewerContainer.removeChild(child);
    }

    viewer._loadingSpinner.hide();

    setToolbarsClickable(viewer);
  }
}