const SchemaVersion = 1;

/**
 * Type-specific prefixes for rows in the Meta table
 */
const MetaTablePrefix = {
  Model: 0x00000000,
  User: 0x80000000
};

/**
 * Type specific key prefix in the time series (log) table
 */
const LogTablePrefix = {
  DataMutation: 0x00000000
};

const ColumnFamilies = {

  Status: "s",
  Attributes: "p",
  AttributeHashes: "h",
  AccessControl: "c",

  LMV: "0",
  Standard: "n",
  Refs: "l",
  Xrefs: "x",
  Source: "r",
  DtProperties: "z",
  Tags: "t",
  Systems: "m",

  UserInfo: "u",
  ChangeInfo: "c",

  Virtual: "v"
};

const ColumnNames = {
  //LMV-isms
  BoundingBox: "0", //family: LMV, data table
  Fragment: "1", //family: LMV, data table
  LmvModelRoot: "lmv", //family: LMV, data table
  AecModelData: "aec", //family: LMV, data table

  DocumentId: "docid", //family: Standard, meta table, the GUID of the Revit document originally used to populate this model
  ForgeUrn: "urn", //family: Standard, meta table, the URN of the source Derivative Service model used to populate our model
  Version: "v", //family: Status. Schema version used for this Digital Twin,

  Deleted: "e", // family: Status
  ODeleted: "!e", // family: Status, override

  ElementFlags: "a", //family: Standard
  UniformatClass: "u", //family Standard, data table
  OUniformatClass: "!u", //family Standard, data table, override
  Classification: "v", //family Standard, data table (value) + family Status, meta table (scheme)
  OClassification: "!v", //family Standard, data table, override
  CategoryId: "c", //family: Standard
  FamilyPath: "f", //family: Standard, Family Type elements only.
  Name: "n", //family: Standard
  OName: "!n", //family: Standard, name override
  SystemClass: "b", //family: Standard, data table, Revit System Classification as bitmask
  OSystemClass: "!b", //family: Standard, data table, Revit System Classification as bitmask, override
  Elevation: "el", //family: Standard, data table, Level elevation in model display units (as given by Revit)
  OElevation: "!el",
  CategoryName: "vc", //family: Virtual
  Tolerance: "tol", // DEPRECATED. family: Standard, data table, MEPSystem mapping tolerance value
  Config: "cfg", //family: Standard, data table. Element configuration (MEPSystems)
  State: "s", //family: Status, data table, MEPSystem states (isDirty)
  Settings: "s", //family: Standard, data & meta table. Application settings pertaining to the resource (model, twin, group, document, element)

  Parent: "p", //family: Refs
  FamilyType: "t", //family: Refs
  SubFamily: "s", //family: Refs
  LmvDbId: "d", //family: Refs
  Level: "l", //family: Refs
  OLevel: "!l", //family: Refs, override
  TopLevel: "m", //family: Refs, data table
  Rooms: "r", //family: Refs, data table
  Elements: "e", //family: Xrefs, data table. Holds an information about which elements are linked to the current element

  UserID: "i", //family: UserInfo
  ClientID: "c", //family: UserInfo
  UserName: "n", //family: UserInfo
  ChangeType: "t", //family: ChangeInfo
  ChangeDesc: "d" //family: ChangeInfo
};

//Elements that have graphics have 0x00 in the highest byte (first byte in big endian)
//Elements that do not have graphics have something other than 0x00 in the highest byte
//Elements that are graphical children of a composite element (which owns the Assembly Code, etc) have nonzero third byte.
//TODO: The numbering scheme stil needs some rationalization
const ElementFlags = {

  //Physical elements key flags (they have geometry)
  SimpleElement: 0x00000000,
  NestedChild: 0x00000001, //Repeated/instanced or nested element (inside a host)
  NestedParent: 0x00000002, //Host Family, e.g. for Casework, Elevators, etc.

  CompositeChild: 0x00000003, //Curtain Wall element (system panel, mullion). Has geometry, but not counted as separate Assembly Code, etc
  CompositeParent: 0x00000004, //Curtain Wall parent element. Owns the system panels, mullions, etc. Has no geometry normally.
  Room: 0x00000005, //Room boundary. Has geometry, but is not part of the physical world

  //Logical elements (and in the future annotations)

  //Parents of physical elements that
  //do not have geometry
  FamilyType: 0x01000000,

  //Levels, etc logical Locations
  //NOTE: Rooms have geometry, so they
  //use 0x00 in the most significant byte
  Level: 0x01000001,

  //Holds the Revit document properties
  DocumentRoot: 0x01000002,

  // IoT data stream element - has no geometry
  Stream: 0x01000003,

  // MEP system element - contains system meta data - has no geometry
  System: 0x01000004,

  // Generic Assets - custom uses and external tracking of assets - has no geometry
  GenericAsset: 0x01000005,

  //"Fake" logical elements created by the instance tree logic as inner node placeholders (e.g. Rooms, Category, Family parent elements)
  Virtual: 0x03000000,

  AllLogicalMask: 0xff000000,

  Deleted: 0xfffffffe, // runtime flag to indicate to-be-skipped elements deleted within the session

  Unknown: 0xffffffff
};

const ChangeType = {
  ImportBegin: "import_begin",
  ImportEnd: "import_end",
  ImportFail: "import_fail"
};

//Primary key prefix flags. This is a subset/generalization
//of the detailed element flags, to aid narrowing down
//table scans when we need to list specific categories of elements.
//We should not get too specific here because it makes it harder to
//update elements just by their UniqueID
const KeyFlags = {
  Physical: 0x00000000, //Has geometry (or potentially has geometry), i.e. physically exists in the real world
  Logical: 0x01000000 //Level, Document, FamilyType, Group...
};

const QC = {
  Parent: ColumnFamilies.Refs + ":" + ColumnNames.Parent,
  SubFamily: ColumnFamilies.Refs + ":" + ColumnNames.SubFamily,
  FamilyType: ColumnFamilies.Refs + ":" + ColumnNames.FamilyType,
  Level: ColumnFamilies.Refs + ":" + ColumnNames.Level,
  OLevel: ColumnFamilies.Refs + ":" + ColumnNames.OLevel,
  TopLevel: ColumnFamilies.Refs + ":" + ColumnNames.TopLevel,
  Rooms: ColumnFamilies.Refs + ":" + ColumnNames.Rooms,
  XRooms: ColumnFamilies.Xrefs + ":" + ColumnNames.Rooms,
  XParent: ColumnFamilies.Xrefs + ":" + ColumnNames.Parent,
  XElements_DEPRECATED: ColumnFamilies.Xrefs + ":" + ColumnNames.Elements,
  Name: ColumnFamilies.Standard + ":" + ColumnNames.Name,
  OName: ColumnFamilies.Standard + ":" + ColumnNames.OName,
  CategoryId: ColumnFamilies.Standard + ":" + ColumnNames.CategoryId,
  CategoryName: ColumnFamilies.Virtual + ":" + ColumnNames.CategoryName,
  ElementFlags: ColumnFamilies.Standard + ":" + ColumnNames.ElementFlags,
  SystemClass: ColumnFamilies.Standard + ":" + ColumnNames.SystemClass,
  OSystemClass: ColumnFamilies.Standard + ":" + ColumnNames.OSystemClass,
  Elevation: ColumnFamilies.Standard + ":" + ColumnNames.Elevation,
  OElevation: ColumnFamilies.Standard + ":" + ColumnNames.OElevation,
  FamilyPath: ColumnFamilies.Standard + ":" + ColumnNames.FamilyPath,
  UniformatClass: ColumnFamilies.Standard + ":" + ColumnNames.UniformatClass,
  OUniformatClass: ColumnFamilies.Standard + ":" + ColumnNames.OUniformatClass,
  Classification: ColumnFamilies.Standard + ":" + ColumnNames.Classification,
  OClassification: ColumnFamilies.Standard + ":" + ColumnNames.OClassification,
  Tolerance: ColumnFamilies.Standard + ":" + ColumnNames.Tolerance, // DEPRECATED
  Settings: ColumnFamilies.Standard + ":" + ColumnNames.Settings,
  Config: ColumnFamilies.Standard + ":" + ColumnNames.Config,
  State: ColumnFamilies.Status + ":" + ColumnNames.State,
  LmvDbId: ColumnFamilies.Refs + ":" + ColumnNames.LmvDbId,
  LmvFragment: ColumnFamilies.LMV + ":" + ColumnNames.Fragment,
  LmvBoundingBox: ColumnFamilies.LMV + ":" + ColumnNames.BoundingBox,
  Deleted: ColumnFamilies.Status + ':' + ColumnNames.Deleted,
  ODeleted: ColumnFamilies.Status + ':' + ColumnNames.ODeleted,
  FragmentCount: "f",
  RowKey: "k"
};

const SyntheticColumns = {
  GUID: "guid",
  SourceFile: "sf"
};

const QCOverrides = {
  [QC.UniformatClass]: QC.OUniformatClass,
  [QC.Classification]: QC.OClassification,
  [QC.Level]: QC.OLevel,
  [QC.Name]: QC.OName,
  [QC.SystemClass]: QC.OSystemClass,
  [QC.Elevation]: QC.OElevation
};

const ModelIdSize = 16;
const ElementIdSize = 20;
const ElementFlagsSize = 4;
const ElementIdWithFlagsSize = ElementIdSize + ElementFlagsSize;
const RowKeySize = 40;

module.exports = {
  MetaTablePrefix,
  ColumnFamilies,
  ColumnNames,
  ElementFlags,
  KeyFlags,
  SchemaVersion,
  ChangeType,
  LogTablePrefix,
  QC,
  QCOverrides,
  SyntheticColumns,
  ModelIdSize,
  ElementIdSize,
  ElementFlagsSize,
  ElementIdWithFlagsSize,
  RowKeySize
};