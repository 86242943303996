import { isNodeJS } from "../../compat";
import { DtConstants } from "../schema/DtConstants";

export function urnToDt(urn, prefix) {

  if (urn.startsWith(DtConstants.DT_URN_PREFIX)) {
    return urn;
  }

  if (!prefix) {
    console.warn("Please specify explicit URN prefix");
    prefix = DtConstants.DT_MODEL_URN_PREFIX;
  }

  //convert from websafe to regular so it works with atob()
  let urn_ = urn.replace(/-/g, '+').replace(/_/g, '/');
  let decoded = isNodeJS() ? Buffer.from(urn_, 'base64').toString('binary') : atob(urn_);

  if (decoded.length === 16) {
    console.warn("Unqualified modelId encountered, where qualified was expected. Assuming staging namespace");
    return prefix + ":" + urn;
  }

  let parts = decoded.split(":");

  let vfId = parts[parts.length - 1];

  //TODO: We need to use different prefixes per model/twin/user
  return prefix + ":" + vfId.slice(3, 3 + 22);
}

export function fixModelUrn(urn) {
  return urnToDt(urn, DtConstants.DT_MODEL_URN_PREFIX);
}

export function fixTwinUrn(urn) {
  return urnToDt(urn, DtConstants.DT_TWIN_URN_PREFIX);
}

export function fixGroupUrn(urn) {
  return urnToDt(urn, DtConstants.DT_GROUP_URN_PREFIX);
}