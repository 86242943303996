import { FacetsManager } from "./FacetsManager";
import { DtViewerState } from "../DtViewerState";
import { FacetTypes, UNASSIGNED } from "./Facets";
import { getFloorBoxFuzzy } from "./floor-footprint";
import { Label3D } from "../../gui/Label3D";
import { ANIM_ENDED } from "../../application/EventTypes";
import i18n from "i18next";

const av = Autodesk.Viewing;
const ave = av.Extensions;

// Taken from ClusterGizmo, default flyTime == layout animation's default
function flyToView(facility, bboxes) {let flyTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2.0;
  // Sum bboxes
  let bbox;

  bboxes.forEach((b) => {
    if (!bbox) {
      bbox = b.clone();
    } else {
      bbox.union(b);
    }
  });

  if (!bbox || bbox.isEmpty()) {
    return;
  }

  // Set camera position/distance to center of clusters
  const center = bbox.getCenter();
  const size = bbox.size();
  const dist = Math.max(size.x, size.y, size.z);

  const dstView = facility.viewer.impl.camera.clone();
  dstView.target.copy(center);

  // Set camera angle to top down view
  const viewDir = { x: 0.5, y: -0.5, z: 0.5 };
  dstView.position.set(center.x + viewDir.x * dist, center.y + viewDir.y * dist, center.z + viewDir.z * dist);

  DtViewerState.flyToView(facility, { camera: dstView }, flyTime);
}

// Taken from VisualClusters
function createSceneLayout(models, clusters, alignShapeRotation) {
  // Make sure that we only work on supported models
  models = models.filter((m) => m.is3d() && Boolean(m.getInstanceTree()));

  // Exclude topography, rooms and empty clusters
  const filter = (c) => c.name !== 'Revit Topography' && c.name !== 'Revit Rooms' && c.name !== 'Revit <Sketch>' && c.shapeIds.length !== 0;
  clusters = clusters.filter(filter);

  // Use RotationLayout to orient all shapes in a way that the projected x/y-extent is small
  const rotationAlignment = alignShapeRotation ? new ave.VisualClusters.RotationAlignment(models) : null;

  // Create helper for bbox access
  const shapeBoxes = new ave.VisualClusters.ShapeBoxes(models, rotationAlignment);

  // Compute layouts
  return ave.VisualClusters.createClusterSetLayout(clusters, shapeBoxes, rotationAlignment);
}

/**
 * @private
 */
FacetsManager.prototype.clusterGeneric = function (ext, models, clusteredFacetId, fitClustersToView, skipAnimate) {
  const modelMap = {};
  models.forEach((m) => {
    modelMap[m.urn()] = { id: m.id, isolatedNodes: new Set(m.visibilityManager.isolatedNodes) };
  });

  const facetDefs = this.facetDefs;
  const mergedFacets = this.getFacets();
  const facetIdx = facetDefs.findIndex((f) => f.id === clusteredFacetId);
  const targetFacet = mergedFacets[facetIdx];
  const noFilters = facetDefs.slice(1).every((fd) => !fd.filter.size);

  // Create clusters map to dedup facets across models
  let multipleElementsClustername = i18n.t('Multiple');
  if (clusteredFacetId === FacetTypes.mepSystems) {
    multipleElementsClustername = i18n.t('Multiple Systems');
  } else if (clusteredFacetId === FacetTypes.spaces) {
    multipleElementsClustername = i18n.t('Multiple Rooms');
  }

  let clusters = {
    [multipleElementsClustername]: {
      name: multipleElementsClustername,
      shapeIds: []
    }
  };

  /**
   * @param {MergedFacetNode} f
   */
  function clusterFacet(f) {
    for (const modelUrn in f.idsSets) {
      const modelId = modelMap[modelUrn].id;
      const isolatedNodes = modelMap[modelUrn].isolatedNodes;
      const idsInMultipleFacets = f.idsInMultipleFacets[modelUrn];
      const dbIds = f.idsSets[modelUrn];

      for (const dbId of dbIds) {
        if (noFilters || isolatedNodes.has(dbId)) {
          const assignedToMultiple = idsInMultipleFacets?.has(dbId);

          const name = assignedToMultiple ? multipleElementsClustername : f.label || f.id;

          let cluster = clusters[name] ? clusters[name] : { name: name, shapeIds: [] };
          if (!cluster) {
            console.log(`No cluster found for element with db id ${dbId}`);
            continue;
          }

          cluster.shapeIds.push({ modelId, dbId });

          clusters[name] = cluster;
        }
      }
    }

    // Recursively cluster children in case of hierarchical facets
    if (f.children) {
      for (const child of f.children) {
        clusterFacet(child);
      }
    }
  }

  targetFacet?.forEach(clusterFacet);

  ext.clusters = clusters;
  const customLayout = createSceneLayout(models, Object.values(clusters), false);
  ext.customSceneLayout = customLayout;
  ext.setLayoutActive(true, skipAnimate);

  if (fitClustersToView && customLayout.clusterLayouts?.length) {
    const bboxes = customLayout.clusterLayouts.map((layout) => layout.getBBox());
    flyToView(this.facility, bboxes);
  }

  return customLayout;
};

/**
 * @private
 */
FacetsManager.prototype.clusterFloor = function (ext, fitClustersToView, skipLabels, skipAnimate) {

  const facetDefs = this.facetDefs;
  const mergedFacets = this.getFacets();
  const facetIdx = facetDefs.findIndex((f) => f.id === FacetTypes.levels);
  const facetFilter = facetDefs[facetIdx].filter;

  const hasFacetFilter = facetFilter.size > 0;
  const filteredFacet = mergedFacets[facetIdx].filter((f) => facetFilter.has(f.id));
  if (hasFacetFilter && filteredFacet.length === 0) {
    console.warn(`Facet filter for "${facetDefs[facetIdx].id}" facet is probably outdated`, facetFilter);
  }

  const floorFacet = hasFacetFilter ? filteredFacet : mergedFacets[facetIdx];

  let floorBoxes = {};

  let area = 0;

  //Calculate horizontal floor areas
  for (let i = 0; i < floorFacet.length; i++) {
    let floor = floorFacet[i];

    let fbox = getFloorBoxFuzzy(this.facility, floor);

    if (!fbox) {
      continue;
    }

    let xSpan = fbox.max.x - fbox.min.x;
    let ySpan = fbox.max.y - fbox.min.y;
    area += xSpan * ySpan;

    floorBoxes[floor.id] = {
      box: fbox,
      facet: floor
    };
  }

  //Sort floors by elevation, we will distribute them
  //left to right and bittom to top starting with the lowest.
  let floorBoxesList = Object.values(floorBoxes).slice();
  floorBoxesList.sort((a, b) => {

    if (a.facet.id === UNASSIGNED) return -1;

    if (b.facet.id === UNASSIGNED) return 1;

    return a.box.min.z - b.box.min.z;
  });


  let rowWidthHalf = Math.sqrt(area) * 0.5;

  //Calculate move distance for each floor's geometries
  let curX = -rowWidthHalf;
  let curY = -rowWidthHalf;
  let maxHeight = -1;

  for (let i = 0; i < floorBoxesList.length; i++) {

    let fbox = floorBoxesList[i];
    let box = fbox.box; // fbox.roomBox;

    if (!box.isEmpty()) {
      fbox.move = new THREE.Vector3(curX, curY, -box.min.z);
    } else {
      fbox.move = new THREE.Vector3(10000, 10000, 10000);
    }

    fbox.movedBox = fbox.box.clone();
    fbox.movedBox.min.add(fbox.move);
    fbox.movedBox.max.add(fbox.move);

    let h = box.max.y - box.min.y;
    if (h > maxHeight) {
      maxHeight = h;
    }

    if (curX > rowWidthHalf) {
      curY += maxHeight + 0.1 * rowWidthHalf;
      curX = -rowWidthHalf;
      maxHeight = -1;
    } else {
      curX += box.max.x - box.min.x + 0.1 * rowWidthHalf;
    }
  }

  //Set up the clustering animation "manually"
  let vc = Autodesk.Viewing.Extensions.VisualClusters;
  let sl = new vc.SceneAnimState(this.facility.facetsManager.models);

  this.labels = [];

  for (let facetNode of floorFacet) {

    let fbox = floorBoxes[facetNode.id];

    if (!fbox?.move) {
      continue;
    }

    for (let modelUrn in facetNode.idsSets) {

      let model = this.facility.getModelByUrn(modelUrn);

      let idSet = facetNode.idsSets[modelUrn];
      for (let dbId of idSet) {
        let as = new vc.ObjectAnimState(dbId);
        as.move.copy(fbox.move);
        sl.setAnimState(model.id, dbId, as);
      }
    }

    if (skipLabels) {
      continue;
    }

    let labelPos = fbox.movedBox.getCenter();
    labelPos.z = fbox.movedBox.min.z;

    const label = new Label3D(this.viewer, labelPos, facetNode.label || facetNode.id);
    label.removeLabelListeners();
    label.setVisible(false);
    this.labels.push(label);

    // Hide label if ClusterGizmo size on screen is below MinPixels threshold.
    const MinPixels = 50;
    label.setWorldBox(fbox.movedBox, MinPixels);

    label.container.style.pointerEvents = 'auto';

    const fitToCluster = () => this.viewer.navigation.fitBounds(false, fbox.movedBox);
    label.container.addEventListener('click', fitToCluster);
  }

  let onAnimEnd = () => {
    this.labels?.forEach((label) => {
      label.setVisible(true);
    });
  };

  if (!skipAnimate) {
    this.viewer.addEventListener(ANIM_ENDED, onAnimEnd, { once: true });
  }

  // Make sure there's no custom scene, otherwise VC will generate a sceneAnimState
  ext.customSceneLayout = null;
  ext.sceneAnimState = sl;
  ext.setLayoutActive(true, skipAnimate);

  if (fitClustersToView) {
    let newBoxes = [];
    for (let fbox of floorBoxesList) {
      let box = fbox.box;
      box.min.add(fbox.move);
      box.max.add(fbox.move);
      newBoxes.push(box);
    }

    flyToView(this.facility, newBoxes);
  }

  if (skipAnimate) {
    onAnimEnd();
  }

  return floorBoxes;
};

/**
 * @param {Boolean} fitClustersToView
 * @param {Boolean|true} updateScene Add listener to indicate elements' positions may have changed
 * @param {Boolean} skipAnimate Whether to skip the animation the layout of the visual clusters.
 */
FacetsManager.prototype.clearClusters = function (fitClustersToView) {let updateScene = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;let skipAnimate = arguments.length > 2 ? arguments[2] : undefined;
  const viewer = this.viewer;
  const ext = viewer.getExtension('Autodesk.VisualClusters');

  if (!ext) {
    // Extension may not be loaded before cleanup
    return;
  }

  // Cleanup custom floor labels
  this.labels?.forEach((l) => l.dispose());

  const onAnimEnd = () => {
    this.facetsEffects.enableHighlighting(true);
    this.facetsEffects.invalidateFloorHighlights();
    this.facetsEffects.clearFacetHighlight();
    this.highlightRooms();
    // Regenerate the ground shadow & updates ghosting positions
    viewer.impl.sceneUpdated(true);
  };

  if (updateScene && !skipAnimate) {
    viewer.addEventListener(ANIM_ENDED, onAnimEnd, { once: true });
  }

  ext.setLayoutActive(false, skipAnimate);

  // fit view to active (visible) models
  const models = this.viewer.getVisibleModels();
  if (fitClustersToView && models.length) {
    const bboxes = models.map((m) => m.getBoundingBox());
    flyToView(this.facility, bboxes);
  }

  this.facetsEffects.enableHighlighting(false);

  if (updateScene && skipAnimate) {
    onAnimEnd();
  }
};

/**
 * @param {String} clusteredFacetId
 * @param {Boolean} fitClustersToView
 * @param {Boolean} skipLabels - Option to skip display of clickable labels (supported for floor clustering only)
 * @param {Boolean} skipAnimate Whether to skip the animation the layout of the visual clusters.
 */
FacetsManager.prototype.updateClusters = async function (clusteredFacetId, fitClustersToView, skipLabels, skipAnimate) {

  const scene = this.facility;
  const ext = scene.viewer.getExtension('Autodesk.VisualClusters');

  const models = scene.viewer.getVisibleModels();

  const doClustering = models.length && clusteredFacetId;

  if (!models.length || this.currentClusterFacet) {
    // Defer scene update until clustering is done
    this.clearClusters(!doClustering && fitClustersToView, !doClustering, skipAnimate);
  }

  let res;

  if (doClustering) {
    // Clustering requires bounding boxes (and fragments) to be loaded.
    await waitForAllIsolatedFragments(models);

    const onAnimEnd = () => {
      this.facetsEffects.enableHighlighting(true);
      this.facetsEffects.invalidateFloorHighlights();
      // Clear again in case user re-enabled clustering while animating
      this.facetsEffects.clearFacetHighlight();
      this.facetsEffects.clearRoomsOverlay();
      // Regenerate the ground shadow & updates ghosting positions
      scene.viewer.impl.sceneUpdated(true);
    };

    if (!skipAnimate) {
      scene.viewer.addEventListener(ANIM_ENDED, onAnimEnd, { once: true });
    }

    this.facetsEffects.enableHighlighting(false);

    // Clear rooms overlay as it is not supposed to be used together
    this.facetsEffects.clearRoomsOverlay();

    if (clusteredFacetId === FacetTypes.levels) {
      res = this.clusterFloor(ext, fitClustersToView, skipLabels, skipAnimate);
    } else {
      res = this.clusterGeneric(ext, models, clusteredFacetId, fitClustersToView, skipAnimate);
    }

    if (skipAnimate) {
      onAnimEnd();
    }
  }

  this.currentClusterFacet = clusteredFacetId;

  return res;
};

/** Awaits fragments for all isolated elements for given models. */
async function waitForAllIsolatedFragments(models) {
  const thenAll = [];
  for (const { loader, visibilityManager } of models) {
    if (!loader || !visibilityManager) continue;

    const waitForFrags = loader.loadFragmentsForElements([...visibilityManager.isolatedNodes]);
    thenAll.push(waitForFrags);
  }
  await Promise.all(thenAll);
}