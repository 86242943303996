import * as THREE from 'three';

const _ray = new THREE.Ray();

/** Gets first room hit by a ray from viewport to pos3D. */
export function getFirstRoomHitBeforePt(viewerImpl, pos3D, models) {
  // A ray from the client's viewport projection of the label 3D position.
  const { x, y } = viewerImpl.worldToClient(pos3D);
  const vpVec = viewerImpl.clientToViewport(x, y);
  viewerImpl.viewportToRay(vpVec, _ray);

  // Only checks up to the label, plus padding against float equality. Exact distance is not important.
  const maxDistance = vpVec.distanceTo(pos3D) + 1;
  let minDistance = maxDistance;
  let first;
  for (const model of models) {
    const volumeHierarchy = model.getConsolidatedBVH();
    if (!volumeHierarchy) {
      return null;
    }

    // Get first hit, in the room only BVH.
    const [dbId, distance] = volumeHierarchy.rayIntersect(_ray, { maxDistance });
    if (distance < minDistance) {
      minDistance = distance;
      first = { dbId, model };
    }
  }

  return first;
}

/**
 * Are sets containing the same objects.
 * @param {Set} lhs
 * @param {Set} rhs
 * @return {boolean}
 */
export function areSetsEqual(lhs, rhs) {
  if (lhs === rhs) return true;
  if (lhs.size !== rhs.size) return false;
  for (const el of lhs) {
    if (!rhs.has(el)) {
      return false;
    }
  }
  return true;
}

/**
 * Get level/floor for an element.
 * @param {DtModel} model Host model
 * @param {Number} dbId Element DB ID
 * @return {?Level}
 */
export function getLevelOfElement(model, dbId) {
  const data = model.getData();
  if (!data) return null;

  const levelId = data.dbId2levelId[dbId];
  if (levelId <= 0) return null;

  return model.getLevels()[levelId];
}

/**
 * Get unique consolidate level name of selection, or nothing.
 * @param {Array.<{model, selection}>} modelSelectionEntries Aggregate selection object.
 * @param {Boolean} [levelsOnly=false] if true, return the name only if all selections are levels
 * @return {?String}
 */
export function getConsolidatedLevelName(modelSelectionEntries, levelsOnly) {
  let levelName = null;

  for (const { model, selection: dbIds } of modelSelectionEntries) {
    const data = model.getData();
    if (!data) continue;

    const levelMap = model.getLevels();
    const getLvlName = (dbId) => {
      const levelId = data.dbId2levelId[dbId];
      if (levelId > 0) return levelMap[levelId]?.name; /* Element is on a level */
      if (dbId in levelMap) return levelMap[dbId].name; /* Element is a level */
      return null;
    };
    for (const dbId of dbIds) {
      if (levelsOnly && !levelMap[dbId]) return;

      const name = getLvlName(dbId);
      if (name == null) continue;
      if (levelName == null) {
        levelName = name;
        continue;
      }
      if (levelName !== name) {
        // More than one consolidated levels.
        return null;
      }
    }
  }

  return levelName;
}