/*
 * Reusable sets of uniforms that can be merged with other uniforms in specific shaders.
 */

import * as THREE from "three";

export let CutPlanesUniforms = {
  "cutplanes": { type: "v4v", value: [] },
  "hatchParams": { type: "v2", value: new THREE.Vector2(1.0, 10.0) },
  "hatchTintColor": { type: "c", value: new THREE.Color(0xFFFFFF) },
  "hatchTintIntensity": { type: "f", value: 1.0 }
};

export let ThemingUniform = {
  "themingColor": { type: "v4", value: new THREE.Vector4(0, 0, 0, 0) }
};

export let HeatmapUniform = {
  "heatmapAlpha": { type: "f", value: 0.0 },
  "heatmapColors": { type: "v3v", value: Array(5).fill(new THREE.Vector3(0, 0, 0)) },
  "heatmapSensors": { type: "v4v", value: Array(10).fill(new THREE.Vector4(0, 0, 0, 0)) },
  "heatmapStops": { type: "fv1", value: [0, 0, 0, 0, 0] }
};

// Uniforms shared by material shader chunks and ShadowMapShader
// Included by ShadowMapUniforms below.
export let ShadowMapCommonUniforms = {
  "shadowESMConstant": { type: "f", value: 0.0 }
};

// Uniforms needed by material shaders to apply shadow mapping.
export let ShadowMapUniforms = THREE.UniformsUtils.merge([
{
  "shadowMap": { type: "t", value: null },
  "shadowMapSize": { type: "v2", value: new THREE.Vector2(0, 0) },
  "shadowBias": { type: "f", value: 0.0 },
  "shadowDarkness": { type: "f", value: 0.0 },
  "shadowMatrix": { type: "m4", value: new THREE.Matrix4() },
  "shadowLightDir": { type: "v3", value: new THREE.Vector3() }
},
ShadowMapCommonUniforms]
);

// Uniform for point-set point size
export let PointSizeUniforms = {
  "point_size": { type: "f", value: 1.0 }
};

// Uniform for wide lines shader
export let WideLinesUniforms = {
  "view_size": { type: "v2", value: new THREE.Vector2(640, 480) }
};

/*
 * Chunks are code snippets that can be included in specific shaders
 * using the three.js-style include directive:
 *
 *      #include <name_of_chunk>
 *
 * During runtime this directive can be expanded into the corresponding
 * code snippet using the `resolve` method available below.
 */
var chunks = {};

// We include default three.js chunks, too
for (var name in THREE.ShaderChunk) {
  chunks[name] = THREE.ShaderChunk[name];
}

import pack_depth from './chunks/pack_depth.glsl';
import tonemap from './chunks/tonemap.glsl';
import cutplanes from './chunks/cutplanes.glsl';
import ghosting from './chunks/ghosting.glsl';
import pack_normals from './chunks/pack_normals.glsl';
import hatch_pattern from './chunks/hatch_pattern.glsl';
import env_sample from './chunks/env_sample.glsl';
import id_decl_frag from './chunks/id_decl_frag.glsl';
import id_frag from './chunks/id_frag.glsl';
import final_frag from './chunks/final_frag.glsl';
import heatmap_decl_frag from './chunks/heatmap_decl_frag.glsl';
import heatmap_frag from './chunks/heatmap_frag.glsl';
import theming_decl_frag from './chunks/theming_decl_frag.glsl';
import theming_frag from './chunks/theming_frag.glsl';
import shadowmap_decl_common from './chunks/shadowmap_decl_common.glsl';
import shadowmap_decl_vert from './chunks/shadowmap_decl_vert.glsl';
import shadowmap_vert from './chunks/shadowmap_vert.glsl';
import shadowmap_decl_frag from './chunks/shadowmap_decl_frag.glsl';
import line_decl_common from './chunks/line_decl_common.glsl';
import normal_map from './chunks/normal_map.glsl';
import model_view_xform from './chunks/model_view_xform.glsl';

chunks['pack_depth'] = pack_depth;
chunks['tonemap'] = tonemap;
chunks['cutplanes'] = cutplanes;
chunks['ghosting'] = ghosting;
chunks['pack_normals'] = pack_normals;
chunks['hatch_pattern'] = hatch_pattern;
chunks['env_sample'] = env_sample;
chunks['id_decl_frag'] = id_decl_frag;
chunks['id_frag'] = id_frag;
chunks['final_frag'] = final_frag;
chunks['heatmap_decl_frag'] = heatmap_decl_frag;
chunks['heatmap_frag'] = heatmap_frag;
chunks['theming_decl_frag'] = theming_decl_frag;
chunks['theming_frag'] = theming_frag;
chunks['shadowmap_decl_common'] = shadowmap_decl_common;
chunks['shadowmap_decl_vert'] = shadowmap_decl_vert;
chunks['shadowmap_vert'] = shadowmap_vert;
chunks['shadowmap_decl_frag'] = shadowmap_decl_frag;
chunks['line_decl_common'] = line_decl_common;
chunks['normal_map'] = normal_map;
chunks['model_view_xform'] = model_view_xform;

/*
 * Macros are simple JavaScript functions that can be evaluated from
 * within the shader code using a similar syntax as the include directive:
 *
 *      #name_of_macro<first_param, second_param, third_param, ...>
 *
 * All parameters are simply passed to the JavaScript code as strings,
 * i.e., they are not parsed in any way.
 *
 */
var macros = {};

// Precompile regexes for the macros
var _regExCache = {};
for (name in macros) {
  _regExCache[name] = new RegExp('#' + name + ' *<([\\w\\d., ]*)>', 'g');
}

/**
 * Recursively resolves include directives and macros.
 * @param {string} source Original shader code.
 * @returns {string} Shader code with all includes resolved.
 */
export let resolve = function (source) {
  for (var name in macros) {
    var re = _regExCache[name];
    source = source.replace(re, function (match, parens) {
      var params = parens.split(',').map(function (param) {return param.trim();});
      return macros[name].apply(null, params);
    });
  }

  var pattern = /#include *<([\w\d.]+)>/g;
  var func = function (match, include) {
    if (!chunks[include]) {
      throw new Error('Cannot resolve #include<' + include + '>');
    }
    return resolve(chunks[include]);
  };
  return source.replace(pattern, func);
};

export let ShaderChunks = {
  ThemingUniform: ThemingUniform,
  HeatmapUniform: HeatmapUniform,
  CutPlanesUniforms: CutPlanesUniforms,
  ShadowMapCommonUniforms: ShadowMapCommonUniforms,
  ShadowMapUniforms: ShadowMapUniforms,
  PointSizeUniforms: PointSizeUniforms,
  WideLinesUniforms: WideLinesUniforms,

  ...chunks,
  resolve: resolve
};