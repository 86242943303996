/*
//   This file contains the logic of choosing the right view to watch a selected object in the 3D scene.
//   The logic of choosing the right position is created in steps:
//   1) Creating 8 different positions around the selected object.
//   2) Check if there are intersections between the optional positions to the selected object.
//      Choose the ones which have the least intersections, and ignore positions which looks on very narrow sides of the object
//      (like in doors and walls).
//   3) From the chosen positions, check more intersections between the position and different points on the object.
//      Choose the one with the least intersections. If there are more then one with the same - choose the closest.
//   We check intersections with raycast and actual geometries.
*/

import * as THREE from "three";

export default class ViewObjectIn3D {
  constructor(viewer) {
    this.viewer = viewer;
  }

  getViewForObject(model, dbId) {
    this.selectedObjectID = dbId;
    this.selectionBox = model.getElementBounds(dbId);
    if (this.selectionBox.isEmpty()) {
      console.warn("empty selection box: skipping placeMeToObject");
      return;
    }

    this.selectionCenter = this.selectionBox.getCenter();

    this.cameraHeight = this.#getCameraHeight(model);

    // Create 8 optional positions around the selected target
    const optionalPositionsObjects = this.#createCandidates();

    // Choose the position options which have the least interferences
    const betterOptions = this.#getBetterCandidates(optionalPositionsObjects);

    // Choose the best option from the betterOptions
    const chosenPos = this.#chooseBestCandidate(betterOptions);
    if (!chosenPos) {
      console.warn("getViewForObject: couldn't find a good position to view the object.");
      return null;
    }

    return {
      position: chosenPos.position,
      target: this.selectionCenter,
      up: new THREE.Vector3(0, 0, 1)
    };
  }

  #createCandidates() {
    // Checks if the object has a very wide side relatively to it's other side so we can ignore
    // angles that look at it from the narrower side.
    const rightVec = this.viewer.navigation.getWorldRightVector();
    const upVec = this.viewer.navigation.getWorldUpVector();
    const frontVec = upVec.cross(rightVec);
    const size = this.selectionBox.getSize(new THREE.Vector3());

    const rightSide = Math.abs(size.clone().multiply(rightVec).length());
    const frontSide = Math.abs(size.clone().multiply(frontVec).length());

    let widerVector;
    const wideSide = Math.max(rightSide, frontSide);
    const narrowSide = Math.min(rightSide, frontSide);
    if (wideSide / narrowSide > ViewObjectIn3D.COVERAGE_FACTOR)
    widerVector = wideSide === rightSide ? rightVec : frontVec;

    // Set the camera to be Perspective before calling this.viewer.navigation.computeFit
    // so we will get the right positions for bimwalk.
    this.viewer.navigation.toPerspective();

    const options = [];
    const rayTarget = new THREE.Vector3();
    for (let index = 0; index < ViewObjectIn3D.NUMBER_OF_POSITION; ++index) {
      const angle = index / (ViewObjectIn3D.NUMBER_OF_POSITION / 2) * Math.PI;

      // get a positon that fits the the selected object to view
      const newPos = this.selectionCenter.clone();
      newPos.x += Math.cos(angle);
      newPos.y -= Math.sin(angle);

      const fov = this.viewer.navigation.getVerticalFov();
      const camera = this.viewer.getCamera();
      const aspect = camera.aspect;
      const optionalPositionObject = this.viewer.navigation.computeFit(
        newPos,
        this.selectionCenter,
        fov,
        this.selectionBox,
        aspect
      );
      const { position } = optionalPositionObject;

      // get the camera a bit further from the object
      const direction = position.clone().sub(this.selectionCenter);
      position.add(direction.clone().multiplyScalar(0.1));

      // set the height
      position.z = this.cameraHeight;

      // If the wider side dot eye vector is greater then 0.8 we would like to ignore this position.
      let dot = widerVector && direction.normalize().dot(widerVector);
      let ignore = dot && Math.abs(dot) > Math.cos(THREE.Math.degToRad(ViewObjectIn3D.IGNORE_ANGLE));

      // create a ray from the optional position towards the selected target and compute distances
      const newDiff = this.selectionCenter.clone().sub(position.clone());
      const dir = newDiff.clone().normalize();
      const ray = new THREE.Ray(position.clone(), dir.clone());
      const intersectionPos = ray.intersectBox(this.selectionBox, rayTarget);
      const distanceToTarget = intersectionPos && intersectionPos.sub(position).length();
      const distanceToCenterTarget = newDiff.length();

      // When the bounding box's size is 0 in one or more dimensions, the ray can miss the object, and in that case we eliminate this option.
      distanceToTarget &&
      !ignore &&
      options.push({
        index,
        position,
        ray,
        distanceToTarget,
        distanceToCenterTarget,
        selectionID: this.selectedObjectID,
        intersections: [],
        cornerIntersections: []
      });
    }

    return options;
  }

  // Choose the position options which have the least interferences
  #getBetterCandidates(optionalPositionsObjects) {
    let betterOptions = [];
    let minIntersects = Number.POSITIVE_INFINITY;

    for (let i = 0; i < optionalPositionsObjects.length; ++i) {
      const posObj = optionalPositionsObjects[i];

      const candidates = [];
      posObj.intersections = [];
      this.viewer.impl.rayIntersect(posObj.ray, false, false, false, candidates);
      let distToObject = this.#getClosestIntersectionWithSelectedObject(candidates);
      if (!distToObject) {
        // If no intersection with object, use the distance to it's center.
        distToObject = posObj.distanceToCenterTarget;
      } else {
        // If there is an intersection with object - use it, instead of the not-always-accurate the distance to the bounding-box.
        posObj.distanceToTarget = distToObject;
      }

      // Consider only intersections that are closer than the selected object's intersection.
      candidates.forEach((item) => {
        if (
        item.dbId !== this.selectedObjectID &&
        posObj.intersections.indexOf(item.dbId) === -1 &&
        item.distance < distToObject)
        {
          posObj.intersections.push(item.dbId);
        }
      });

      // Make a list of the optional positions that have the least objects interfering
      if (posObj.intersections.length === minIntersects) {
        betterOptions.push(posObj);
      } else if (posObj.intersections.length < minIntersects) {
        betterOptions = [posObj];
        minIntersects = posObj.intersections.length;
      }
    }

    return betterOptions;
  }

  // Choose the best option from the betterOptions
  #chooseBestCandidate(betterOptions) {
    let chosenPos;
    let minIntersections = Number.MAX_VALUE;

    const boundingBoxPoints = getCornerPoints(this.selectionBox);

    betterOptions.forEach((posObj) => {
      chosenPos = chosenPos || posObj;

      // Create rays from the optional position to 8 corenrs of the selected object's bounding box
      // and store the intersections
      for (let k = 0; k < boundingBoxPoints.length; k++) {
        const vec = boundingBoxPoints[k];

        // Choose a point that is slightly towards the center of the object
        const offset = vec.clone().sub(this.selectionCenter).multiplyScalar(0.1);
        vec.sub(offset);

        // To Do: If geometry is already loaded: find a better way to choose more points on the object.

        const dir = vec.clone().sub(posObj.position).normalize();
        const ray = new THREE.Ray(posObj.position.clone(), dir);

        let cornerIntersections = [];

        this.viewer.impl.rayIntersect(ray, false, false, false, cornerIntersections);
        const distToObject = this.#getClosestIntersectionWithSelectedObject(cornerIntersections);
        // If no intersection with the selected object, don't check intersections with other objects for this corner.
        if (!distToObject) {
          continue;
        }

        // Consider only objects that are closer then the selected object's intersection.
        cornerIntersections = cornerIntersections.filter(
          (intersection) =>
          intersection.dbId !== this.selectedObjectID && intersection.distance <= distToObject
        );
        cornerIntersections = cornerIntersections.map((intersection) => intersection.dbId);

        // This list is made for help choosing the right position.
        // Make the array of corner intersections to be of unique ones.
        if (cornerIntersections.length) {
          posObj.cornerIntersections.push(...cornerIntersections);
          posObj.cornerIntersections = [...new Set(posObj.cornerIntersections)];
          // No need to continiune checking in case there are more intersections then the minimum (of the current chosen one).
          if (posObj.cornerIntersections.length > minIntersections) break;
        }
      }

      const count = posObj.cornerIntersections.length;
      // TODO: original implementation in firefly.js increments count if the position is inside another object.
      // However, boxes object is constructed incorrectly by merging boxes from different models but using dbId as key. Boxes
      // from different models might have the same dbId. If this check is critical in practice (e.g., tie breaker for two candidate positions)
      // bring it back (and/or implement a better more efficient tie breaker):
      // if (this.#isInsideOtherObject(posObj, boxes, closeBoxesKeys)) {
      // 	count++;
      // }

      // Choose the option with the least corner intersections.
      // If it is the same, choose the one that is closest to the center of the object.
      // If it is the same, choose the one that is closer to intersection with the object / it's boundng-box.
      if (count < minIntersections) {
        minIntersections = count;
        chosenPos = posObj;
      } else if (count === minIntersections) {
        if (posObj.distanceToCenterTarget === chosenPos.distanceToCenterTarget) {
          if (posObj.distanceToTarget < chosenPos.distanceToTarget) {
            chosenPos = posObj;
          }
        } else if (posObj.distanceToCenterTarget < chosenPos.distanceToCenterTarget) {
          chosenPos = posObj;
        }
      }
    });

    return chosenPos;
  }

  #getClosestIntersectionWithSelectedObject(intersections) {
    const selectionDist = intersections.filter((intersection) => intersection.dbId === this.selectedObjectID);
    let min = Number.MAX_VALUE;
    if (!selectionDist[0]) {
      return null;
    }
    for (let j = 0; j < selectionDist.length; j++) {
      if (selectionDist[j].distance < min) {
        min = selectionDist[j].distance;
      }
    }
    return min;
  }

  // get camera's position from nearest level elevation + avatar's height
  // falls back to object's min.z + avatar's height
  #getCameraHeight(model) {
    const avatarHeightInMeters = 1.8;
    const avatarHeightInModelUnit = Autodesk.Viewing.Private.convertUnits(
      "meters",
      model.getParentFacility().mainModelDistanceUnit,
      1,
      avatarHeightInMeters,
      "default"
    );

    const objectCenterZ = model.getElementBounds(this.selectedObjectID).getCenter().z;

    const elevation = getLevelElevation(model, objectCenterZ) ?? this.selectionBox.min.z;
    return elevation + avatarHeightInModelUnit;
  }
}

// In case of narrow objects like doors and walls, when one side is wider then the other by more then COVERAGE_FACTOR times
//  we would like to ignore positions that looks at the object from its narrow side.
ViewObjectIn3D.COVERAGE_FACTOR = 4.0;

// When the angle between the camera's direction and to wider side of the object is less hen IGNORE_ANGLE dgrees
// Ignore this position
ViewObjectIn3D.IGNORE_ANGLE = 35;

// The number of position candidates we create around the chosen object.
ViewObjectIn3D.NUMBER_OF_POSITION = 8;

function getCornerPoints(box) {
  const points = [];
  for (let k = 0; k < 8; k++) {
    const point = new THREE.Vector3(
      box[k & 1 ? "min" : "max"].x,
      box[k & 2 ? "min" : "max"].y,
      box[k & 4 ? "min" : "max"].z
    );
    points.push(point);
  }
  return points;
}

// returns the elevation of the level corresponding to the given height
function getLevelElevation(model, height) {
  const levels = Object.values(model.getLevels());
  if (levels.some((_ref) => {let { elev } = _ref;return elev === undefined;})) return;

  const { placementWithOffset } = model.getData();
  if (!placementWithOffset) return;

  // `transformZ` is `transformPoint` but removing anything not relevant to Z.
  const transformZ = (z) => placementWithOffset.elements[10] * z + placementWithOffset.elements[14];
  const elevations = levels.map((_ref2) => {let { elev } = _ref2;return transformZ(elev);}).sort((a, b) => a - b);

  if (elevations.length === 0 || elevations[0] > height) return;

  let i = 1;
  while (i < elevations.length) {
    if (elevations[i] > height) {
      break;
    }
    i += 1;
  }

  return elevations[i - 1];
}