import { FacetsManager } from "./FacetsManager";
import { FacetTypes } from "./Facets";

const MultipleMepSystemsFacetColorId = FacetTypes.mepSystems + ':multiple';
const MultipleRoomsFacetColorId = FacetTypes.spaces + ':multiple';

export class PaletteGenerator {

  constructor() {
    this.color = new THREE.Color();
    this.hue = 0; // [0, 360]
    this.hueI = 0; // [0, 10]
    this.saturation = 100; // [30, 100]
    this.lightness = 75; // [25, 75]
  }

  next() {
    // Palette is "consumed": generate differentiable colors
    this.color.setHSL(this.hue / 360, this.saturation / 100, this.lightness / 100);
    let colorHex = '#' + this.color.getHexString();

    // tweak hue, saturation and lightness to generate colors:
    // - increment by the "golden angle" (~= 137 deg): next colors are distinguishable and it's not a divisor of 360
    // - decrement saturation every 10 colors
    // - decrement lightness every 70 colors
    this.hue = (this.hue + 137) % 360;
    this.hueI += 1;
    if (this.hueI === 10) {
      this.hueI = 0;
      this.saturation -= 10;
      // Saturation below 30% is too grey
      if (this.saturation === 30) {
        this.saturation = 100;
        this.lightness -= 10;
        // Lightness below 30% is too dark
        if (this.lightness <= 30) {
          // use pastel colors
          this.lightness = 75;
        }
      }
    }

    return colorHex;
  }
}

FacetsManager.prototype._convertColorMap = function (colorMap) {
  const colorMapV4 = {};
  for (let c in colorMap) {
    let hex = colorMap[c];
    if (hex) {
      // THREE's color parsing (which is used under the hood of applyTheme) only copes
      // with RGB hex colors, hence we strip the alpha channel here.
      // TODO: tweak THREE.Color.setStyle, or make it faster some other way
      if (hex.length === 9) {
        hex = hex.slice(0, 7);
      }
      const clr = new THREE.Color(hex);
      colorMapV4[c] = new THREE.Vector4(clr.r, clr.g, clr.b, 0.9);
    }
  }
  return colorMapV4;
};


FacetsManager.prototype.generateColorMap = function (defaultColorMaps) {

  const facetDefs = this.facetDefs;

  //TODO: Disabled the fix for https://jira.autodesk.com/browse/DTWIN-2601 here because it
  //has the side effect of very significantly increaing load time, even for models and views that
  //do not have a color map set at all. We need a solution that perhaps delays color map generation
  //until it is first needed (see Facets.js in dt-client)
  const mergedFacets = this.mergedFacets; //this.collectUnfilteredMergedFacets();

  const colorMaps = defaultColorMaps || {};

  for (let i = 0; i < mergedFacets.length; i++) {
    const facetDef = facetDefs[i];
    const { palette, paletteById } = facetDef;

    let colorGen = new PaletteGenerator();

    if (!colorMaps[facetDef.id]) {
      colorMaps[facetDef.id] = {};
    }

    const colorMap = colorMaps[facetDef.id];
    // The use of RGBA (# + 8 char) indicates user defined value
    const hasUserDefinition = Object.values(colorMap).some((v) => v.length === 9);

    const usedColors = {};
    const reuseColorsByPrefix = facetDef.id === FacetTypes.spaces;

    let j = 0;
    const q = [...mergedFacets[i]];

    while (q.length > 0) {
      const mergedFacetNode = q.shift();

      // skip virtual nodes
      // don't override values as they can be updated by the user
      if (!hasUserDefinition && mergedFacetNode.count > 0 && colorMap[mergedFacetNode.id] === undefined) {
        let colorHex;

        let prefix;
        if (reuseColorsByPrefix) {
          prefix = mergedFacetNode.id.split(" ")[0];
          colorHex = usedColors[prefix];
        }

        if (mergedFacetNode.parentColorHex) {
          colorHex = mergedFacetNode.parentColorHex;
        }

        // respect hierarchical colors
        if (!colorHex && paletteById) {
          colorHex = paletteById[mergedFacetNode.id];
        } else if (!colorHex) {
          colorHex = palette[j];
        }

        if (!colorHex) {
          // Palette is "consumed": generate differentiable colors
          colorHex = colorGen.next();

          if (prefix) {
            usedColors[prefix] = colorHex;
          }
        }
        colorMap[mergedFacetNode.id] = colorHex;
      }

      j++;

      if (mergedFacetNode.children) {
        for (const child of mergedFacetNode.children) {
          child.parentColorHex = mergedFacetNode.parentColorHex || colorMap[mergedFacetNode.id];
          q.push(child);
        }
      }
    }

    const multipleFacetsColorId = facetDef.id === FacetTypes.mepSystems ? MultipleMepSystemsFacetColorId : MultipleRoomsFacetColorId;
    colorMap[multipleFacetsColorId] = '#5A5A5A';
  }

  return colorMaps;
};

FacetsManager.prototype._applyThemeToModel = function (m, facetIdx, colorMapV4) {

  function dfs(facetId, id, node) {
    if (!node) {
      return;
    }

    //If an element is assigned to multiple facets,
    //we can't color it with a per-facet color, so we skip it.
    //This can be the case for Spaces and System Classification facets.
    //(And probably will be true for floor assignment in the future).
    if (Array.isArray(node)) {
      if (node.length !== 1) {
        const multipleFacetsColorId = facetId === FacetTypes.mepSystems ? MultipleMepSystemsFacetColorId : MultipleRoomsFacetColorId;
        const color = colorMapV4[multipleFacetsColorId];
        if (color) {
          m.setThemingColor(id, color);
        }

        return;
      }

      node = node[0];
    }

    const valId = node.id;
    const color = colorMapV4[valId];
    m.setThemingColor(id, color);

    if (node.children) {
      for (const child of node.children) {
        dfs(facetId, id, child);
      }
    }
  }

  if (facetIdx === 0) {
    // theme models
    m.setThemingColor(m.getRootId(), colorMapV4[m.urn()], true);
  } else {
    // theme facets
    const facetId = this.getFacetDefs()[facetIdx].id;
    const facetsConfig = m.facetsClassifiers?.[facetId];

    if (!facetsConfig) {
      console.error("Missing classifier for model");
      return;
    }

    const dbId2flags = m.getData().dbId2flags;
    const dbId2catId = m.getData().dbId2catId;
    for (let id = 0; id < dbId2flags.length; ++id) {

      if (this.skipElement(dbId2flags[id], dbId2catId[id])) {
        continue;
      }

      dfs(facetId, id, facetsConfig(id));
    }
  }
};

FacetsManager.prototype.applyTheme = function (facetId, colorMap) {
  const colorMapV4 = this._convertColorMap(colorMap);
  const emptyColorMap = {};

  // Clear any existing themes
  for (let i = 0; i < this.facetDefs.length; i++) {
    for (let m of this.models) {
      this._applyThemeToModel(m, i, emptyColorMap);
    }
    this.facetDefs[i].theme = emptyColorMap;
  }

  //Set new color theme on the requested facet
  const facetIdx = this.facetDefs.findIndex((facetDef) => facetDef.id === facetId);
  if (facetIdx >= 0) {
    for (let m of this.models) {
      this._applyThemeToModel(m, facetIdx, colorMapV4);
    }
    this.facetDefs[facetIdx].theme = colorMapV4;
    this.facetsEffects.makeRoomsVisible(true);
  } else {
    this.facetsEffects.makeRoomsVisible(false);
  }

  this.viewer.impl.invalidate(true);
};

FacetsManager.prototype.clearTheme = function (facetIdx) {
  this.models.forEach((model) => {
    this._applyThemeToModel(model, facetIdx, {});
  });
  this.facetsEffects.makeRoomsVisible(false);
};