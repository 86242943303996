
//Initializes the global Autodesk namespace, required by GUI and  extensions
//Also exports the Autodesk namespace from the node module for new/future code to use.
module.exports.Autodesk = require('./module-prefix').Autodesk;

function _export(m, ns) {
  for (var prop in m) {
    if (m.hasOwnProperty(prop)) {
      //Export directly into the module (e.g. for node.js use, where LMV is used via require instead from global namespace)
      module.exports[prop] = m[prop];

      //Export into the desired viewer namespace
      if (ns)
      module.exports[ns][prop] = m[prop];
    }
  }
}

var _extend = Object.assign || function (target, source) {
  for (var prop in source) {
    if (source.hasOwnProperty(prop)) {
      target[prop] = source[prop];
    }
  }
};


//Add the global exports to expose the libraries to code that's not yet modular.
module.exports.THREE = require('three');

//Create the two target namespaces for the module exports
//This is mostly for backwards API compatibility, telling which
//global namespace to put each export in once included from the web build.
module.exports.av = {};
module.exports.avp = {};
module.exports.avu = {};
module.exports.ave = {};


//We will need to export Hammer to the global namespace because it's used
//by various extensions
//Hammer does not work on Node because it references window and document
if (BUILD_FLAG__BUILD_TYPE !== 'NodeJs') {
  var Hammer = require('../thirdparty/hammer/hammer.js');
  //Also Hammer sets module.exports to itself, which fools our _export function,
  //so we wrap it in an object.
  _export({ Hammer: Hammer }, "av");
} else {
  _export(require("./dt/worker/geom/OtgGeomDecoder"), "avp");
  _export(require("./dt/worker/geom/OtgGeomEncoder"), "avp");
}

//TODO_TS: stuff from compat/globas/envinit goes into both namespaces because they
//contain a mix of public and private exports,
//make it cosistent in client code, then remove one of those
_export(require("./compat"), "av");
_export(require("./compat"), "avp");
_export(require("./globals"), "avp");
_export(require("./globals"), "av");
_export(require("./envinit"), "avp");
_export(require("./envinit"), "av");


_export(require("./resource-loader"), "avp");
const i18init = require("./globalization/i18init");
_export(i18init, "avp");
const i18n = i18init.i18n;
i18n.translate = i18n.t.bind(i18n);
i18n.localize = () => {};
_export({ i18n }, "av");
_export(require("./globalization/langs"), "avp");


_export(require("./logger/Logger"), "avp");

_export(require("./wgs/globals"), "avp");

_export(require("./wgs/render/GroundReflection"), "avp");
_export(require("./wgs/render/GroundShadow"), "avp");
_export(require("./wgs/render/WebGLShader"), "avp");
_export(require("./wgs/render/PhongShader"), "avp");
_export(require("./wgs/render/BasicShader"), "avp");
_export(require("./wgs/render/DecodeEnvMap"), "avp");
_export(require("./wgs/render/ShaderChunks"), "avp");
_export(require("./wgs/render/SAOShader"), "avp");
_export(require("./wgs/render/ShaderPass"), "avp");
_export(require("./wgs/render/LineStyleDef"), "avp");
_export(require("./wgs/render/HalfFloat"), "avp");
_export(require("./wgs/render/ShaderUtils"), "avp");
_export(require("./wgs/render/WebGLProgram"), "avp");
_export(require("./wgs/render/ShadowMap"), "avp");
_export(require("./wgs/render/MaterialConverterCommon"), "avp");
_export(require("./wgs/render/MaterialConverter"), "avp");
_export(require("./wgs/render/MaterialManager"), "avp");
_export(require("./wgs/render/RenderContext"), "avp");
_export(require("./wgs/render/WebGLRenderer"), "avp");
_export(require("./wgs/render/GroundFlags"), "avp");

_export(require("./wgs/scene/MeshFlags"), "avp");
_export(require("./wgs/scene/RenderFlags"), "avp");
_export(require("./wgs/scene/LmvVector3"), "avp");
_export(require("./wgs/scene/LmvMatrix4"), "avp");
_export(require("./wgs/scene/LmvBox3"), "avp");
_export(require("./wgs/scene/VertexEnumerator"), "avp");
_export(require("./wgs/scene/VertexBufferReader"), "avp");
_export(require("./wgs/scene/DeriveTopology"), "avp");
_export(require("./wgs/scene/VBIntersector"), "avp");
_export(require("./wgs/scene/GeometryList"), "avp");
_export(require("./wgs/scene/RenderBatch"), "avp");
_export(require("./wgs/scene/ModelIteratorLinear"), "avp");
_export(require("./wgs/scene/ModelIteratorBVH"), "avp");
_export(require("./wgs/scene/BufferGeometry"), "avp");
_export(require("./wgs/scene/RenderScene"), "avp");
_export(require("./wgs/scene/RenderModel"), "avp");
_export(require("./wgs/scene/FrustumIntersector"), "avp");
_export(require("./wgs/scene/FragmentList"), "avp");
_export(require("./wgs/scene/SelectionMode"), "av");
_export(require("./wgs/scene/InstanceTree"), "avp");
_export(require("./wgs/scene/InstanceTreeStorage"), "avp");
_export(require("./wgs/scene/BVHBuilder"), "avp");
_export(require("./wgs/scene/SceneMath"), "avp");

_export(require("./wgs/scene/VertexBufferBuilder"), "avp");

//Common networking
_export(require("./net/ErrorCodes"), "av");
_export(require("./net/endpoints"), "av");
_export(require("./net/Xhr"), "avp");

_export(require("../thirdparty/three.js/DDSLoader"), "avp");

//Application layers
_export(require("./application/EventDispatcher"), "av");
_export(require("./application/Extension"), "av");
_export(require("./application/ExtensionManager"), "av");
_export(require("./application/EventTypes"), "av");
_export(require("./application/EventUtils"), "av");
_export(require("./application/ScreenModeDelegate"), "av");
_export(require("./application/LightPresets"), "avp");
_export(require("./application/LocalStorage"), "avp");
_export(require("./application/Preferences"), "avp");
_export(require("./application/PreferenceNames"), "avp");
_export(require("./application/Viewer3D.js"), "av");
_export(require("./application/Viewer3DImpl"), "avp");
_export(require("./application/ViewerState"), "avp");
_export(require("./application/ScreenShot"), "av");

//Icky reconstruction of the MeasureCommon namespace from its interdependent modules.
_export(require('./measurement/UnitFormatter'), "avp");
_export(require('./measurement/UnitParser'), "avp");
_export(require('./measurement/DisplayUnits'), "avp");

var mc = module.exports.av.MeasureCommon = {};
_extend(mc, require('./measurement/MeasureCommon'));
mc.MeasurementTypes = require('./measurement/MeasurementTypes').MeasurementTypes;
mc.MeasurementTypesToAnalytics = require('./measurement/MeasurementTypes').MeasurementTypesToAnalytics;
mc.SnapType = require('./measurement/SnapTypes').SnapType;
mc.Events = require('./measurement/MeasureEvents').MeasureEvents;
mc.Measurement = require('./measurement/Measurement').Measurement;
mc.SnapResult = require('./measurement/SnapResult').SnapResult;

// GlobalManager
_export(require('./application/GlobalManager'), "av");
_export(require('./application/GlobalManagerMixin'), "av");

//Tools
_export(require("./tools/Navigation"), "av");
_export(require("./tools/UnifiedCamera"), "av");
_export(require("./tools/Selector"), "avp");
_export(require("./tools/SelectionType"), "av");
_export(require("./tools/VisibilityManager"), "avp");
_export(require("./tools/KeyCode"), "av");
_export(require("./tools/ToolController"), "av");
_export(require("./tools/HotkeyManager"), "av");
_export(require("./tools/DefaultHandler"), "av");
_export(require("./tools/ViewingUtilities"), "av");
_export(require("./tools/GestureHandler"), "av");
_export(require("./tools/ToolInterface"), "av");
_export(require("./tools/OrbitDollyPanTool"), "av");
_export(require("./tools/HotGestureTool"), "av");
_export(require("./tools/FovTool"), "av");
_export(require("./tools/autocam/Autocam"), "av");
_export(require("./tools/viewtransitions/ViewTransition"), "avp");

_export(require("./dt/loader/TextureLoader"), "avp");

_export(require("./application/ProgressState"), "av");



if (BUILD_FLAG__WANT_GUI) {
  // Bundle CSS files into a single one.
  require('./index-css');
  //TODO: probably not all of these need to be explicitly exported now
  _export(require("./gui/CommonWidgets"), "avp");
  _export(require("./gui/DockingPanel.js"), "avu");
  _export(require("./gui/ContextMenu"), "avp");
  _export(require("./gui/AlertBox"), "avp");
  _export(require("./gui/ErrorHandler"), "avp");
  _export(require("./gui/ObjectContextMenu"), "avu");
  _export(require("./gui/ProgressBar"), "avp"); //TODO: remove from export
  _export(require("./gui/ViewerPanelMixin"), "ave"); //TODO: mode to avu namespace
  _export(require("./gui/controls/Control"), "avu");
  _export(require("./gui/controls/ControlGroup"), "avu");
  _export(require("./gui/controls/Button"), "avu");
  _export(require("./gui/controls/RadioButtonGroup"), "avu");
  _export(require("./gui/controls/ComboButton"), "avu");
  _export(require("./gui/toolbar/ToolBar"), "avu");
  _export(require("./gui/HudMessage"), "avp");
  _export(require("./gui/SettingsPanel"), "avu");
  _export(require("./gui/controls/Filterbox"), "avu");
  _export(require("./gui/ViewerSettingsPanel"), "ave"); //TODO: mode to avu namespace
  _export(require("./gui/ViewerObjectContextMenu"), "ave"); //TODO: mode to avu namespace
  _export(require("./gui/GuiViewerToolbarConst"), "av");
  _export(require("./gui/GuiViewer3D"), "av");
  _export(require("./gui/Label3D"), "av");
  _export(require("./gui/PointMarker"), "av");
}


// Register extensions bundled into their own JS files
_export(require("../extensions/registerExternalExtensions"), "avp");

//Tandem API
var dt = AutodeskNamespace("Autodesk.Tandem");
_extend(dt, require("./dt/DtApp"));
_extend(dt, require("./dt/DtEventTypes"));
_extend(dt, require("./dt/DtFacility"));
_extend(dt, require("./dt/DtViewerState"));
_extend(dt, require("./dt/DtTeam"));
_extend(dt, require("./dt/DtUser"));
_extend(dt, require("./dt/DtViews"));
_extend(dt, require("./net/fetch"));
_extend(dt, require("./dt/encoding/base64"));

_extend(dt, require("./dt/schema/DtConstants"));
_extend(dt, require("./dt/schema/system-class"));
_extend(dt, require("./dt/facets/Facets"));
_extend(dt, require("./dt/facets/FacetsManager"));
_extend(dt, require("./dt/facets/clustering"));
_extend(dt, require("./dt/facets/theming"));
_extend(dt, require("./dt/loader/DtLoader"));
_extend(dt, require("./dt/schema/Attribute"));
_extend(dt, require("./dt/DtWorkerCreator"));
_extend(dt, require("./dt/connectivity/ConnectivityUtils"));

_extend(dt, require("./dt/streams/StreamUtils"));

require("./module-suffix").initializeLegacyNamespaces(module.exports);