import { EventDispatcher } from "../../../application/EventDispatcher";
import { DT_STREAMS_INFO_CHANGED_EVENT, DT_TELEMETRY_CHANGED_EVENT, DT_TELEMETRY_TICK_EVENT } from "../../DtEventTypes";
import { GRANULARITY } from "../ReadingsByDate";
import { DtConstants } from "../../schema/DtConstants";
import { OFFLINE_AGE, StreamUtils } from "../StreamUtils";
import { dayjs } from "./time";

const { Aggregates, Intervals, Now } = DtConstants.Timeline;
/** @typedef {number|"dynamic_now"} MaybeNowTS Since JSDoc types are static, make sure "Now" matches DtConstants. */

const DATE_FORMAT = 'YYYY-MM-DD';

/** Desired number of interval subdivisions. (likely will need adjusting) */
const PTS_PER_INTERVAL = 500;

const MIN = 60 * 1000;

// Central telemetry ticker.
const TICK_MS = 60 * 1000;


/** Provides a restricted temporal view on telemetry data. */
export class TelemetryView extends EventDispatcher {
  /** Aggregate function to use for the value of rollups. */
  aggregate;
  /** Time interval (maybe symbolic). */
  interval;
  /** Point in time (maybe symbolic). */
  time;

  /** Cached data for the given interval */
  #data;
  /** Cached data ranges (min,max) for the given interval */
  #dataRanges;
  /** Cancellation ID for the ticker interval. */
  #intervalID;
  #streamMgr;
  #taskQueue;
  /** Cached stream thresholds */
  #thresholds;

  constructor(streamMgr) {
    super();

    this.#streamMgr = streamMgr;
    this.#taskQueue = new TaskQueue();

    // this.debugEvents(true);
  }

  dispose() {
    this.#streamMgr.facility.eventTarget.removeEventListener(DT_STREAMS_INFO_CHANGED_EVENT, this.#onStreamsInfoChanged);
    this.clearListeners();
    this.#updateTicker();
    this.#taskQueue.dispose();
  }

  init( /* viewer */) {
    this.aggregate = Aggregates.AVERAGE;
    this.time = new FocusedTime(Now);
    this.interval = TimeInterval.Create(Intervals.LAST_7_DAYS);
    this.setInterval(this.interval.key);

    this.#streamMgr.facility.eventTarget.addEventListener(DT_STREAMS_INFO_CHANGED_EVENT, this.#onStreamsInfoChanged);
    //this.#streamMgr.facility.eventTarget.debugEvents(true);
  }

  /**
   * Get readings that applies for a specific instant in time, or nothing if it has no data.
   *
   * TODO:
   *   - Reconsider how to better pass filters for the occasional caller.
   *   - Reconsider if and how instant readings should look back "up-to-offline" age.
   *   - Actually optimize the data structure for retrieval.
   */
  getInstantReadings(filter) {
    if (!this.#data) return;

    const { matchAttribute, matchElement } = computeReadingFilter(filter);

    const utcTime = this.time.resolve('UTC');
    const utcDate = utcTime.format(DATE_FORMAT);
    const granularity = this.interval.asGranularity();
    const now = Date.now();

    // To note, when NOW these differ from last readings because of different mechanisms and pooling rates.
    const readings = [];
    const fullIds = {};
    const attrIds = {};
    for (const row of this.#data) {
      if (row.d !== utcDate || !matchAttribute(row) || !matchElement(row)) continue;

      const threshold = this.#thresholds?.[row.k]?.[row.s];
      const reading = granularity === GRANULARITY.Raw ?
      decodeRaw(row, utcTime.valueOf(), threshold, now) :
      decodeRollup(row, utcTime, threshold, granularity, this.aggregate, now);

      // Skip no data to keep metadata consistent with available readings.
      if (reading.state === DtConstants.StreamStates.NoData) continue;

      const idx = readings.push(reading) - 1;
      (fullIds[row.k] ?? (fullIds[row.k] = [])).push(idx);
      (attrIds[row.s] ?? (attrIds[row.s] = [])).push(idx);
    }

    return {
      fullIds,
      attrIds,
      time: utcTime.valueOf(),
      duration: granularity,
      readings
    };
  }

  /** Get per attribute range (i.e. min max) */
  getAttrRange(filter) {
    if (!this.#data) return {};
    if (this.#dataRanges) return this.#dataRanges;

    const { matchAttribute, matchElement } = computeReadingFilter(filter);
    const isRaw = this.interval.asGranularity() === GRANULARITY.Raw;

    const byAttr = {};
    const add = (aid, min, max) => {
      if (byAttr[aid]) {
        byAttr[aid].min = Math.min(byAttr[aid].min, min);
        byAttr[aid].max = Math.max(byAttr[aid].max, max);
      } else {
        byAttr[aid] = { min, max };
      }
    };
    for (const row of this.#data) {
      if (!matchAttribute(row) || !matchElement(row)) continue;

      if (isRaw) {
        // Foreach values add passing in the attribute ID.
        row.v.forEach((v) => add(row.s, v, v));
      } else {
        // Foreach bucket, use pre-made min and max.
        for (const values of row.b) {
          if (!values) continue;
          const [_, __, min, max] = values;
          add(row.s, min, max);
        }
      }
    }

    return this.#dataRanges = byAttr;
  }

  setAggregate(aggregate) {let ts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Date.now();
    if (aggregate === this.aggregate) return;
    if (!(aggregate in Aggregates)) {
      console.warn('Ignoring unrecognized aggregate function', aggregate);
      return;
    }

    this.aggregate = aggregate;
    this.dispatchEvent({ type: DT_TELEMETRY_CHANGED_EVENT, askedAt: ts });

    return ts;
  }

  /** Set the timeline's time as a MaybeNowTS */
  setTime(time) {let ts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Date.now();
    if (time === this.time.focus) return;

    this.time.focus = time;
    this.time.clamp(this.interval);
    this.dispatchEvent({ type: DT_TELEMETRY_CHANGED_EVENT, askedAt: ts });

    return ts;
  }

  /**
   * Sets a time interval.
   * @param {string} key One of the named intervals key
   * @param {any} [opt] Optional configuration
   * @param {number} [ts] Optional timestamp of the request.
   * @return {number}
   */
  setInterval(key, opt) {let ts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Date.now();
    const interval = TimeInterval.Create(key, opt);
    const request = async (_) => {
      const granularity = interval.asGranularity();
      const utcRange = interval.asUTCDateRange();
      const [data, thresholds] = await Promise.all([
      this.#streamMgr.readingsByDate.getReadings(utcRange, granularity),
      this.#streamMgr.getAllStreamInfos().then(toThresholdMap),
      this.#streamMgr.refreshStreamsLastReadings()]
      );

      this.interval = interval;
      this.time.clamp(interval);
      this.#data = data;
      this.#dataRanges = null;
      this.#thresholds = thresholds;

      this.dispatchEvent({ type: DT_TELEMETRY_CHANGED_EVENT, view: this, askedAt: ts });
    };

    this.#taskQueue.enqueue(request).
    catch((err) => {
      if (err instanceof TaskDroppedError) return;
      console.error(err);
    });

    return ts;
  }

  /**
   * Returns an instantiated time and interval, as DayJS datetimes.
   * @param {string} [tzId] TZ Database timezone identifier. Defaults to user's
   * @param {number} [now] Optional override on what is now, as a UNIX timestamp.
   */
  resolve(tzId) {let now = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Date.now();
    const res = this.interval.resolve(tzId, now);
    res.time = this.time.resolve(tzId, now);

    return res;
  }

  // Keeping internal utility methods lower
  addEventListener(type, listener, options) {
    super.addEventListener(type, listener, options);
    this.#updateTicker();
  }

  clearListeners() {
    super.clearListeners();
    this.#updateTicker();
  }

  removeEventListener(type, listener) {
    super.removeEventListener(type, listener);
    this.#updateTicker();
  }

  #onStreamsInfoChanged = () => {
    const askedAt = Date.now();
    const request = async (_) => {
      this.#thresholds = await this.#streamMgr.getAllStreamInfos().then(toThresholdMap);

      this.dispatchEvent({ type: DT_TELEMETRY_CHANGED_EVENT, view: this, askedAt });
    };

    this.#taskQueue.enqueue(request).
    catch((err) => {
      if (err instanceof TaskDroppedError) return;
      console.error(err);
    });
  };

  #patchData(entries, isRaw) {
    if (entries.length === 0) return false;

    const key = (_ref) => {let { k, s, d } = _ref;return k + s + d;};
    const [{ d: date }] = entries;

    const patched = [];
    const rowByKey = {};
    for (const row of this.#data) {
      if (row.d !== date) {
        patched.push(row);
        continue;
      }
      rowByKey[key(row)] = row;
    }

    // Patch records for the current date.
    let hasChanged = false;
    for (const e of entries) {
      const k = key(e);

      const org = rowByKey[k];
      if (org) {
        // We already have this row.
        if (isRaw ? !areSortedEqual(org.v, e.v) : !areSortedEqual(org.b, e.b)) {
          hasChanged = true;
        }
      } else {
        // New row being added.
        hasChanged = true;
      }

      patched.push(e);
    }

    // Check for deletion
    if (patched.length !== this.#data.length) {
      hasChanged = true;
    }

    if (hasChanged) {
      this.#data = patched;
    }

    return hasChanged;
  }

  #tick = () => {
    // Ensures time stays within the window's range, if live.
    this.time.clamp(this.interval);
    const askedAt = Date.now();

    const request = async (_) => {
      // An open range of the day's data
      const now = dayjs.utc(Date.now());
      const range = [now.format(DATE_FORMAT), now.add(1, 'day').format(DATE_FORMAT)];

      // Update the latest day of data.
      const granularity = this.interval.asGranularity();
      const data = await this.#streamMgr.readingsByDate.getReadings(range, granularity);

      const hasChanged = this.#patchData(data, granularity === GRANULARITY.Raw);
      if (hasChanged) {
        this.dispatchEvent({ type: DT_TELEMETRY_CHANGED_EVENT, view: this, askedAt });
      }
    };

    this.#taskQueue.enqueue(request).
    catch((err) => {
      if (err instanceof TaskDroppedError) return;
      console.error(err);
    });

    this.dispatchEvent(DT_TELEMETRY_TICK_EVENT);
  };

  #updateTicker() {
    const hasSubscribers = this.listeners?.[DT_TELEMETRY_TICK_EVENT]?.length > 0;

    if (!hasSubscribers && this.#intervalID) {
      clearInterval(this.#intervalID);
      this.#intervalID = null;
      return;
    }
    if (hasSubscribers && !this.#intervalID) {
      this.#intervalID = setInterval(this.#tick, TICK_MS);
    }
  }
}

/** Window of time, allows both symbolic and explicit definitions. */
export class TimeInterval {
  /** @type {dayjs.Duration} Symbolic length of the interval */
  duration;
  /** @type {MaybeNowTS} Exclusive end to the interval */
  end;
  /** @type {string} Key for the interval */
  key;

  constructor(end, duration, key) {
    this.duration = duration;
    this.end = end;
    this.key = key;
  }

  /**
   * Interval of time covered, as DayJS datetimes.
   * @param {string} [tzId] TZ Database timezone identifier. Defaults to user's
   * @param {number} [now] Optional override on what is now, as a UNIX timestamp.
   */
  resolve(tzId) {let now = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Date.now();
    const end = resolve(this.end, now);
    const beg = end - this.duration.asMilliseconds();
    return {
      beg: dayjs(beg).tz(tzId),
      end: dayjs(end).tz(tzId)
    };
  }

  /** Returns UTC date range in the form of [from, to) */
  asUTCDateRange() {let now = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Date.now();
    const utcEnd = dayjs.utc(this.end === Now ? now : this.end);
    const utcBeg = utcEnd.subtract(this.duration);
    return [utcBeg.format(DATE_FORMAT), utcEnd.add(1, 'day').format(DATE_FORMAT)];
  }

  asGranularity() {
    const divisionInMs = this.duration.asMilliseconds() / PTS_PER_INTERVAL;

    // Choose the crudest granularity that matches this number of subdivisions.
    for (const key in GRANULARITY) {
      const granularity = GRANULARITY[key];
      if (divisionInMs > granularity) {
        return granularity;
      }
    }

    throw new Error('unmatched granularity');
  }

  /**
   * Creates a window based on one of the supported keys.
   * @param {String} key One of KEYS.
   * @param {any} [opt] Optional configuration for the interval.
   * @return {TimeInterval}
   */
  static Create(key, opt) {
    switch (key) {
      case Intervals.LAST_3_DAYS:
        return new TimeInterval(Now, dayjs.duration({ days: 3 }), key);
      case Intervals.LAST_7_DAYS:
        return new TimeInterval(Now, dayjs.duration({ days: 7 }), key);
      case Intervals.CUSTOM:
        return createCustomInterval(opt);
      default:
        console.warn('Unimplemented time window key: ' + key);
    }
  }
}

function createCustomInterval(options) {
  // TODO: Maybe also support a end + duration directly.
  if (options.beg == null || options.end == null) {
    throw new Error('invalid interval configuration');
  }

  let { beg, end } = options;
  if (typeof beg !== 'number' || typeof end !== 'number' && end !== Now) {
    throw new Error('invalid interval configuration');
  }

  // Truncate end past now.
  const now = Date.now();
  if (end !== Now) end = Math.min(end, now);

  const duration = end === Now ? now - beg : end - beg;
  if (duration <= 0) {
    throw new Error('invalid reversed interval');
  }

  return new TimeInterval(end, dayjs.duration(duration), Intervals.CUSTOM);
}

/** Time of interest, either symbolic or explicit timestamp */
export class FocusedTime {
  /** @type {MaybeNowTS} Selected/Focused point of time in the interval */
  focus;

  constructor(focus) {
    this.focus = focus;
  }

  /** Milliseconds elapsed between chosen time and now. */
  ago() {
    return this.focus === Now ? 0 : Date.now() - this.focus;
  }

  /** ensures focused time stays within the time interval. */
  clamp(interval) {
    // Live interval and focus can't be stale
    if (this.focus === Now && interval.end === Now) return;

    // Instantiating symbolic end for the purposes of clamping.
    const now = Date.now();
    const realFocus = resolve(this.focus, now);
    const realEnd = resolve(interval.end, now);
    const focus = clamp(realFocus, realEnd - interval.duration.asMilliseconds(), realEnd);

    // Cases where focus might be made live.
    if (focus === now) {
      // Focus was live and can stay there.
      if (this.focus === Now) return;
      // Focus was in the future, and is made live by setting a live interval.
      if (interval.end === Now) {
        this.focus = Now;
        return;
      }
    }

    this.focus = focus;
  }

  /**
   * Focused time as a timestamp or DayJS datetime if passed a Timezone.
   * @param {string} [tzId] TZ Database timezone identifier. Defaults to user's
   * @param {number} [now] Optional override on what is now, as a UNIX timestamp.
   * @return {number|dayjs.Dayjs}
   */
  resolve(tzId) {let now = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Date.now();
    return dayjs(resolve(this.focus, now)).tz(tzId);
  }
}

const clamp = (value, min, max) => Math.min(Math.max(value, min), max);
const resolve = (optSymbolic, now) => optSymbolic === Now ? now : optSymbolic;

function areSortedEqual(lhs, rhs) {
  if (lhs === rhs) return true;
  if (lhs?.length !== rhs?.length) return false;

  for (let i = 0; i < lhs.length; i++) {
    // null or [n, avg, min, max]
    const l = lhs[i];
    const r = rhs[i];
    if (l == r) continue;
    if (l?.length !== r?.length) return false;
    if (l.some((lv, i) => lv !== r[i])) return false;
  }
  return true;
}

function toThresholdMap(streamInfos) {
  const res = {};
  for (const stream of streamInfos) {
    for (const attr of stream.streamAttrs) {
      const threshold = attr.allowedValues?.thresholds;
      if (!threshold) continue;

      const perConn = res[stream.fullId] ?? (res[stream.fullId] = {});
      perConn[attr.id] = threshold;
    }
  }
  return res;
}

function computeReadingFilter(filter) {
  // Filters are sets for now. Might change to make it easier to call, but usual call with hook should facilitate this.
  return {
    matchElement: filter?.fullIdSet ? (_ref2) => {let { k: fullId } = _ref2;return filter.fullIdSet.has(fullId);} : () => true,
    matchAttribute: filter?.attrIdSet ? (_ref3) => {let { s: attrId } = _ref3;return filter.attrIdSet.has(attrId);} : () => true
  };
}

function decodeRaw(row, time, threshold, now) {
  const { t, v } = row;

  // Early check if we even have the right range.
  if (t.length === 0 || t[0] > time) {
    return { s: DtConstants.StreamStates.NoData };
  }

  const lookBack = 15 * MIN;
  for (let i = t.length - 1; i >= 0; i--) {
    if (t[i] > time) continue;
    if (t[i] < time - lookBack) break;

    return {
      k: row.k,
      s: row.s,
      t: t[i],
      v: v[i],
      state: StreamUtils.evaluateState(threshold, v[i], t[i], true)
    };
  }
}

function readRowValue(rollup, aggregate) {
  if (!rollup) return;

  const [, avg, min, max] = rollup;
  switch (aggregate) {
    case Aggregates.MINIMUM:return min;
    case Aggregates.AVERAGE:return avg;
    case Aggregates.MAXIMUM:return max;
    default:
      console.warn('Unrecognized aggregate', aggregate);
      return avg;
  }
}

function decodeRollup(row, instant, threshold, granularity, aggregate, now) {
  const time = instant.valueOf();
  const startOfDay = instant.startOf('day').valueOf();

  let idx = Math.floor((time - startOfDay) / granularity);
  let t = startOfDay + idx * granularity;
  let v = row.b[idx]; /* [count, avg, min, max] */

  // Since bucket are aligned on 15min boundary and conceptually "last 15 min" isn't. We look-back one bucket if the
  // correct one is empty, adjusting the bucket time to ensure the look-back doesn't cause it to be marked offline.
  if (v === null && instant.isAfter(now - 15 * MIN)) {
    idx = Math.max(0, idx - 1); // Ignoring going to the last day for now due to the complexity of the niche case.
    t = time;
    v = row.b[idx];
  }

  return {
    k: row.k,
    s: row.s,
    t,
    v: readRowValue(v, aggregate),
    b: v,
    state: StreamUtils.evaluateAggregateState(threshold, v, t)
  };
}

/**
 * Asynchronous processing.
 *
 * @callback Runnable
 * @param {number} RequestID
 * @return {Promise<any>}
 */

class TaskDroppedError extends Error {
  constructor(taskId) {
    super('dropped ' + taskId);
    this.taskId = taskId;
  }
}

let serialID = 0;
class TaskQueue {
  /** Asynchronous queue of requests */
  #requests = [];

  /**
   * Adds a request to the sequential processing queue. Can be dropped if new request is made before it starts.
   * @param {Runnable} runnable
   * @return {Promise<any>}
   */
  enqueue(runnable) {
    let resolve, reject;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });

    const request = {
      id: serialID++,
      promise,
      drop: () => reject(new TaskDroppedError(request.id))
    };

    // It is not started automatically, to allow sequential processing and evicting of outdated inactive requests.
    request.start = () => {
      runnable(request.id).
      then(resolve).
      catch(reject).
      finally(() => {
        this.#requests.shift();
        if (this.#requests.length) {
          this.#requests[0].start();
        }
      });
    };

    // Since requests are sequential only the first is active, drops all inactive.
    while (this.#requests.length > 1) this.#requests.pop().drop();

    // Start if first in line.
    if (this.#requests.push(request) === 1) request.start();

    return promise;
  }

  /** Drop all requests. */
  dispose() {
    while (this.#requests.length > 0) {
      this.#requests.pop().drop();
    }
  }

  /** Current size of the task queue. */
  get length() {
    return this.#requests.length;
  }
}