// For enum type values
export class EnumType {
  constructor(values, current) {
    if (!Array.isArray(values) || values.length === 0) {
      throw new Error(`Invalid ${values}`);
    }

    this.values = new Set(values);
    this.current = values.includes(current) ? current : values[0];
  }

  get value() {
    return this.current;
  }

  set value(newValue) {
    if (this.values.has(newValue)) {
      this.current = newValue;
    }
  }

  clone() {
    return EnumType.deSerialize(JSON.parse(this.toString()));
  }

  // for serializing
  toString() {
    return JSON.stringify({
      type: '__enum',
      values: Array.from(this.values),
      current: this.current
    });
  }

  static deSerialize(obj) {
    if (obj.type !== '__enum') {
      return;
    }

    return new EnumType(obj.values, obj.current);
  }
}