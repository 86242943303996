import { pathToURL, ViewingService } from "../../net/Xhr";
import { initPlacement } from "./SvfPlacementUtils";
import { binToPackedString, unpackHexString } from '../encoding/hex-strings';
import { LmvVector3 } from '../../wgs/scene/LmvVector3';
import { MeshFlags } from '../../wgs/scene/MeshFlags';
import { LmvBox3 } from '../../wgs/scene/LmvBox3';
import { LmvMatrix4 } from '../../wgs/scene/LmvMatrix4';
import { allocateUintArray } from "../../wgs/scene/IntArray";
import { IdMapper } from "../encoding/IdMapper";
import * as THREE from "three";
import { KeyFlags } from "../schema/dt-schema";
import { ProgressiveReadContext2 } from "./ProgressiveReadContext2";
import { isNodeJS } from "../../compat";

const LOAD_FRAGMENTS_FROM_WORKER = false;

const FRAGMENT_LOADED_FLAG = 0x1;
const FRAGMENT_TRANSPARENT_BIT = 0x2;
//Note that MESH_HIDE flag is 0x4 and we use that in both the FragmentStorage and FragmentList right now,
//until further refactoring.
const FRAGMENT_IS_ROOM_BIT = MeshFlags.MESH_HIDE;
const MESH_NOTLOADED = 0x40;

let _b = new LmvBox3();

function extractBox(src, srfOffset, fragOffset) {

  _b.min.x = src[srfOffset] + fragOffset.x;
  _b.min.y = src[srfOffset + 1] + fragOffset.y;
  _b.min.z = src[srfOffset + 2] + fragOffset.z;
  _b.max.x = src[srfOffset + 3] + fragOffset.x;
  _b.max.y = src[srfOffset + 4] + fragOffset.y;
  _b.max.z = src[srfOffset + 5] + fragOffset.z;

  return _b;
}


let _t = new LmvVector3();
let _s = new LmvVector3();
let _q = new THREE.Quaternion();
let _m = new LmvMatrix4(true);

function composeFragmentTransform(fdata, offset, translation) {

  //Read the fragment transform
  let to = offset;
  _t.set(fdata[to + 0] + translation.x, fdata[to + 1] + translation.y, fdata[to + 2] + translation.z);
  _q.x = fdata[to + 3];
  _q.y = fdata[to + 4];
  _q.z = fdata[to + 5];
  _q.w = fdata[to + 6];
  _s.set(fdata[to + 7], fdata[to + 8], fdata[to + 9]);

  _m.compose(_t, _q, _s);

  return _m;
}


let zeroHash = new Uint8Array(20);
let zeroString = binToPackedString(zeroHash, 0, 20);

class ResourceHashes {

  constructor() {

    this.hashes = [zeroString];
    this.byteStride = 20;
    this.version = 0;
    this.numLoaded = 1;
    this.hashToIndex = new Map();
    this.hashToIndex.set(zeroString, 0);
  }

  addHashFromBuf(buf, offset) {
    const hash = binToPackedString(buf, offset, this.byteStride);
    return this.addHash(hash);
  }

  addHash(hash) {
    let resid = this.hashToIndex.get(hash);
    if (resid === undefined) {
      resid = this.numLoaded++;
      this.hashToIndex.set(hash, resid);
      this.hashes[resid] = hash;
    }

    return resid;
  }

}

class FragmentStorage {

  constructor(numFrags, numGeoms, numMaterials, doublePrecision) {
    this.length = numFrags;
    this.numLoaded = 0;
    this.boxes = doublePrecision ? new Float64Array(this.length * 6) : new Float32Array(this.length * 6);
    this.polygonCounts = new Uint32Array(this.length);
    this.transforms = doublePrecision ? new Float64Array(this.length * 12) : new Float32Array(this.length * 12);
    this.materials = numMaterials ? allocateUintArray(this.length, numMaterials) : new Array(this.length);
    this.geomDataIndexes = numGeoms ? allocateUintArray(this.length, numGeoms) : new Array(this.length);
    this.fragId2dbId = new Int32Array(this.length);
    this.dbId2fragId = {};
    this.visibilityFlags = new Uint8Array(this.length);
  }

  initEmptyFragments(fragId2dbId) {

    this.fragId2dbId = fragId2dbId;
    this.dbId2fragCount = new Map();
    this.hasFragMapping = true;

    for (let i = 0; i < this.length; i++) {

      this.visibilityFlags[i] = MESH_NOTLOADED;

      //Initialize bounding box to empty (max < min)
      let bo = i * 6;
      let boxes = this.boxes;
      boxes[bo + 3] = -1;
      boxes[bo + 4] = -1;
      boxes[bo + 5] = -1;
    }

    for (let i = 0; i < fragId2dbId.length; i++) {
      this.addDbIdReference(i, fragId2dbId[i]);
    }
  }

  getNextFragIdSlot(dbId) {

    let fragIds = this.dbId2fragId[dbId];
    let numUsedSlots = this.dbId2fragCount.get(dbId) || 0;

    if (typeof fragIds === "number") {
      if (numUsedSlots >= 1) {
        console.log("unexpected number of fragments for element");
      } else {
        numUsedSlots++;
      }
      this.dbId2fragCount.set(dbId, numUsedSlots);
      return fragIds;
    } else {
      if (numUsedSlots >= fragIds.length) {
        console.log("unexpected number of fragments for element");
      } else {
        numUsedSlots++;
      }
      this.dbId2fragCount.set(dbId, numUsedSlots);
      return fragIds[numUsedSlots - 1];
    }
  }

  resetFragIdSlot(dbId) {

    if (typeof dbId === "number") {
      this.dbId2fragCount.set(dbId, 0);
    } else {
      for (let dbId of this.dbId2fragCount.keys()) {
        this.dbId2fragCount.set(dbId, 0);
      }
    }

  }

  addDbIdReference(fragId, dbId) {

    this.fragId2dbId[fragId] = dbId;

    let fid = this.dbId2fragId[dbId];
    if (fid === undefined) {
      this.dbId2fragId[dbId] = fragId;
    } else {
      if (typeof fid === "number") {
        this.dbId2fragId[dbId] = [fid, fragId];
      } else {
        fid.push(fragId);
      }
    }
  }

  setTransform(fragId, m) {
    let e = m.elements;
    let dst = this.transforms;
    let off = fragId * 12;
    dst[off + 0] = e[0];
    dst[off + 1] = e[1];
    dst[off + 2] = e[2];
    dst[off + 3] = e[4];
    dst[off + 4] = e[5];
    dst[off + 5] = e[6];
    dst[off + 6] = e[8];
    dst[off + 7] = e[9];
    dst[off + 8] = e[10];
    dst[off + 9] = e[12];
    dst[off + 10] = e[13];
    dst[off + 11] = e[14];
  }

  setBoundingBox(fragId, b) {

    let dst = this.boxes;
    let dstOffset = fragId * 6;

    dst[dstOffset] = b.min.x;
    dst[dstOffset + 1] = b.min.y;
    dst[dstOffset + 2] = b.min.z;
    dst[dstOffset + 3] = b.max.x;
    dst[dstOffset + 4] = b.max.y;
    dst[dstOffset + 5] = b.max.z;
  }

  setFragmentLoaded(fragId, value) {
    if (value) {
      this.visibilityFlags[fragId] = this.visibilityFlags[fragId] | FRAGMENT_LOADED_FLAG;
    } else {
      this.visibilityFlags[fragId] = this.visibilityFlags[fragId] & ~FRAGMENT_LOADED_FLAG;
    }
  }

  isFragmentLoaded(fragId) {
    return !!(this.visibilityFlags[fragId] & FRAGMENT_LOADED_FLAG);
  }

  setMeshLoaded(fragId, value) {
    //Note the flag bit represents the opposite of the function signature -- not loaded vs loaded.
    if (value) {
      this.visibilityFlags[fragId] = this.visibilityFlags[fragId] & ~MESH_NOTLOADED;
    } else {
      this.visibilityFlags[fragId] = this.visibilityFlags[fragId] | MESH_NOTLOADED;
    }
  }

  isMeshLoaded(fragId) {
    return !(this.visibilityFlags[fragId] & MESH_NOTLOADED);
  }

  isRoom(fragId) {
    return (this.visibilityFlags[fragId] & FRAGMENT_IS_ROOM_BIT) !== 0;
  }
}


export function DtPackage(loadContext, parentLoader) {

  this.loadContext = loadContext;
  this.parentLoader = parentLoader;
  this.modelId = loadContext.modelId || "unknown";

  this.materials = null; //The materials json as it came from the SVF

  this.fragments = null; //will be wrapped in a FragmentList

  this.bbox = null; //Overall scene bounds

  this.globalOffset = { x: 0, y: 0, z: 0 };
  this.placementWithOffset = null;
  this.placementIsTranslation = false;

  this.fragmentsLoaded = false;
  this.fragmentsInProgress = false;
  this.queuedFragmentRequests = [];
  this._numFragsInProgress = 0;

  this.metadataInProgress = false;

  this.aborted = false;
}


//Set up the fragments, materials, and meshes lists
//which get filled progressively as we receive their data
DtPackage.prototype.initEmptyLists = function (stats) {

  let svf = this;
  let loadContext = this.loadContext;

  //See if we are getting already pre-loaded fragment and dbId mappings
  let autoDbIds = loadContext.autoDbIds;
  let fragId2dbId = loadContext.fragId2dbId;
  let numFrags = fragId2dbId ? fragId2dbId.length : 0;

  if (!stats && !fragId2dbId) {
    console.warn("Expect at least one of stats or fragId2DbId to be specified to initialize fragment list");
  }

  //If we are getting pre-loaded fragment list, we keep the geometry and material lists dynamic (instead of getting them from the stats)
  if (stats) {
    svf.fragments = new FragmentStorage(stats.num_fragments, stats.num_geoms, stats.num_materials, loadContext.fragmentTransformsDouble);
  } else {
    svf.fragments = new FragmentStorage(numFrags, 0, 0, loadContext.fragmentTransformsDouble);
    svf.fragments.initEmptyFragments(fragId2dbId);
  }

  svf.geomMetadata = new ResourceHashes();

  svf.materialHashes = new ResourceHashes();

  svf.autoDbIds = new IdMapper(autoDbIds);

  //Shell object to make it compatible with SVF.
  //Not sure which parts of this are really needed,
  //SceneUnit is one.
  svf.materials = {
    "name": "LMVTK Simple Materials",
    "version": "1.0",
    "scene": {
      "SceneUnit": 8215,
      "YIsUp": 0
    },
    materials: {}
  };

};

DtPackage.loadAsyncResource = function (loadContext, resourcePath, responseType, callback, onErrorCB) {

  //Launch an XHR to load the data from external file

  resourcePath = pathToURL(resourcePath, loadContext.basePath);

  function onError() {for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {args[_key] = arguments[_key];}
    loadContext.onFailureCallback?.(...args);
    onErrorCB(...args);
  }

  ViewingService.getItem(loadContext, resourcePath, callback, onError,
  { responseType: responseType || "arraybuffer" }
  );
};

function getAsyncProgressiveHandlers(loadContext, svf, ctx, resourceName, postData, resolve, reject) {
  const onDone = function (data, receivedLength) {
    if (svf.aborted) {
      return;
    }

    ctx.onEnd(data, receivedLength);

    svf.onFragmentsLoaded({ keys: postData.rowIds, allFragments: resourceName === 'all_fragments' });

    resolve();
  };

  const onError = function () {
    if (svf.aborted) {
      return;
    }for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {args[_key2] = arguments[_key2];}

    loadContext.onFailureCallback?.(...args);
    reject(...args);
  };

  const onData = function (chunk) {
    if (svf.aborted) {
      return true;
    }

    //Read as many fragments as we can at this time
    ctx.onData(chunk, false);
  };

  return { onDone, onError, onData };
}

DtPackage.prototype.loadAsyncProgressive = async function (loadContext, resourcePath, ctx, resourceName, postData) {

  return new Promise((resolve, reject) => {

    let svf = this;

    resourcePath = pathToURL(resourcePath, loadContext.basePath);

    const { onDone, onError, onData } = getAsyncProgressiveHandlers(loadContext, svf, ctx, resourceName, postData, resolve, reject);

    svf.fragmentsCtx = ctx;

    postData = postData || {};

    let payload = {
      method: "POST",
      postData: JSON.stringify(postData),
      responseType: "text",
      ondata: onData,
      headers: {
        ...loadContext.headers,
        "Content-Type": "application/json"
      },
      useFetch: true
    };

    ViewingService.getItem(loadContext, resourcePath, onDone, onError, payload);

  });

};

DtPackage.prototype.loadFragmentListFromWorker = async function (loadContext, resourcePath, ctx, resourceName, postData) {

  return new Promise((resolve, reject) => {

    let svf = this;

    resourcePath = pathToURL(resourcePath, loadContext.basePath);

    const { onDone, onError, onData } = getAsyncProgressiveHandlers(loadContext, svf, ctx, resourceName, postData, resolve, reject);

    const onData2 = (chunk, dbHashes, geomHashes, matHashes) => {
      /**
       * There are two different cases that can occur here:
       * 1.	The (complete) instance tree was loaded first. In this case, the dbId -> fragId map is already
       * 		complete and we just need to send it to the worker to replace hashes with the correct dbId in the
       * 		fragment list. The worker won't send any db hashes back (so dbHashes will always be an empty array).
       * 		Strictly speaking, we would need to avoid adding geometry and material hashes to their maps if the
       * 		corresponding fragments are not loaded due to the dbId filter. But that would require to resolve
       * 		the db hashes back to their ids and check them against the filter, so we simply add them instead.
       * 		That shouldn't cause any harm.
       * 2.	The complete fragment list is loaded first. In this case, we build up the dbId -> fragId map in
       * 		the worker and need to add all entries to the autoDbIds map here. Subsequent fragment requests
       * 		(if they can actually occur; there shouldn't be any missing fragments) need to send the existing
       * 		map to the worker. They won't send any new db hashes back either.
       */
      for (let dbHash of dbHashes) {
        svf.autoDbIds.addEntity(dbHash);
      }

      for (let geomHash of geomHashes) {
        svf.geomMetadata.addHash(geomHash);
      }

      for (let matHash of matHashes) {
        this.materialHashes.addHash(matHash);
      }

      return onData(chunk);
    };

    svf.fragmentsCtx = ctx;

    postData = postData || {};

    let metadata;
    if (svf.metadata) {
      metadata = {
        fragmentTransformsOffset: svf.metadata.fragmentTransformsOffset,
        placementWithOffset: svf.placementWithOffset
      };
    }

    // Send the hash -> id maps to the worker if they exist already.
    let dbIdMap = svf.autoDbIds?.extIdToIndex;
    let geomIdMap = svf.geomMetadata?.hashToIndex;
    let matIdMap = svf.materialHashes?.hashToIndex;

    // The node.js code path uses fake workers and runs everything in the main thread, so sending the maps to the
    // worker won't copy them. We need to copy them manually here, because modifying them in the worker code has
    // undesired side effects otherwise.
    if (isNodeJS()) {
      dbIdMap = new Map(dbIdMap);
      geomIdMap = new Map(geomIdMap);
      matIdMap = new Map(matIdMap);
    }

    svf.parentLoader.viewer3DImpl.geomCache().loadFragmentList(resourcePath, metadata, dbIdMap, geomIdMap, matIdMap, postData, onDone, onError, onData2);

  });

};

DtPackage.prototype.sendMetadataToWorker = async function () {
  const resourcePath = pathToURL("fragments", this.loadContext.basePath);

  const metadata = {
    fragmentTransformsOffset: this.metadata.fragmentTransformsOffset,
    placementWithOffset: this.placementWithOffset,
    placementIsTranslation: this.placementIsTranslation
  };

  this.parentLoader.viewer3DImpl.geomCache().setFragmentListMetadata(resourcePath, metadata);
};

DtPackage.prototype.loadMetadata = async function (path, skipFragmentInit) {

  let svf = this;
  let loadContext = this.loadContext;
  svf.metadataInProgress = true;

  return new Promise((resolve, reject) => {

    DtPackage.loadAsyncResource(loadContext, path, "json", function (data) {

      //For OTG, there is a single JSON for metadata and manifest,
      //and it's the root
      svf.metadata = data;
      svf.manifest = svf.metadata.manifest;

      // Set numGeoms. Note that this must happen before creating the GeometryList.
      svf.numGeoms = svf.metadata.stats.num_geoms;

      svf.processMetadata();

      //Was fragment list already initialized by the object tree loading step?
      //If not, do it now.
      if (!skipFragmentInit) {
        svf.initEmptyLists(svf.metadata.stats);
      }

      svf.metadataInProgress = false;

      resolve(svf.metadata);
    }, reject);
  });
};


DtPackage.prototype.computeMissingFragments = function (dbIdFilter) {

  let fragStorage = this.fragments;

  let dbIdsToLoad;
  let fragmentsToActivate;
  let numFragments = 0;

  //If it makes sense, load a subset of the fragment list
  if (fragStorage?.hasFragMapping && dbIdFilter) {
    //Case where the instance tree was loaded in full first, and then the fragment list
    //was loaded potentially in parts

    dbIdsToLoad = new Set();
    fragmentsToActivate = [];
    for (let dbId of dbIdFilter.values()) {
      let needLoad = false;
      let numFrags = 0;
      this.instanceTree.enumNodeFragments(dbId, (fragId) => {
        ++numFrags;
        if (!fragStorage.isFragmentLoaded(fragId)) {
          needLoad = true;
        } else {
          fragmentsToActivate.push(fragId);
        }
      }, false);

      if (needLoad) {
        numFragments += numFrags;
        dbIdsToLoad.add(dbId);
      }
    }
  } else if (this.fragmentsLoaded && fragStorage) {
    //Case where the full fragment list was loaded up front

    dbIdsToLoad = new Set();
    fragmentsToActivate = [];
    let fragId2dbId = this.fragments.fragId2dbId;
    for (let fragId = 0; fragId < fragId2dbId.length; fragId++) {
      let dbId = fragId2dbId[fragId];
      if (dbId && !fragStorage.isFragmentLoaded(fragId)) {
        numFragments++;
        dbIdsToLoad.add(dbId);
      } else {
        fragmentsToActivate.push(fragId);
      }
    }
  }

  return { dbIdsToLoad, fragmentsToActivate, numFragments };
};

let _zero = { x: 0, y: 0, z: 0 };

DtPackage.prototype.receiveOneFragment = function (ctx, idx, baseOffset, dbIdsToLoad, dbIdFilter) {

  //Fragments have ot wait for the metadata (placement transform)
  //before they can be fully processed
  if (!this.metadata)
  return false;

  let idata = ctx.idata();
  let fdata = ctx.fdata();
  let bdata = ctx.bdata();
  let offset = baseOffset / 4;

  let frags = this.fragments;

  let fragId;
  let dbId = this.autoDbIds.addEntityFromBuf(bdata, baseOffset, 20);

  //Check if this is a "surplus" fragment that we requested because
  //we expanded the request list to minimize the POST data payload,
  //and skip processing if so.
  if (dbIdsToLoad && !dbIdsToLoad.has(dbId)) {
    return true;
  }

  if (frags.hasFragMapping) {
    fragId = frags.getNextFragIdSlot(dbId);
  } else {
    //The index is 1-based (due to the record at index 0 being the file header
    //while the runtime FragmentList is a classic 0-based array, so we subtract 1 here.
    fragId = idx - 1;
    frags.addDbIdReference(fragId, dbId);
  }

  const meshid = this.geomMetadata.addHashFromBuf(bdata, baseOffset + 20);
  frags.geomDataIndexes[fragId] = meshid;

  frags.materials[fragId] = this.materialHashes.addHashFromBuf(bdata, baseOffset + 40);

  let flags = idata[offset + 15];

  //Decode mesh flags (currently, we only support bit value 1 to mark hidden-by-default meshes)
  //If a filter is specified, set the "unloaded" flag to prevent the mesh geometry from being loaded up front
  let wantLoadBit = 0;
  if (dbIdFilter) {
    if (!dbIdFilter.has(dbId)) {
      wantLoadBit = MESH_NOTLOADED;
    }
  }
  //Is the fragment marked as hidden geometry? Then mark it as hidden but do not skip loading of the geometry
  let isRoomBit = flags & 1 ? FRAGMENT_IS_ROOM_BIT : 0;

  //Transparent bit -- this hack marks meshes with the hide flag to also be transparent.
  //This helps sort room meshes (which come with the hidden flag set) into the transparent objects bucket
  //of the spatial index. That will make sure rooms draw last, on top of opaque objects and will have slightly
  //more correct rollover highlighting.
  let transparentBit = isRoomBit ? FRAGMENT_TRANSPARENT_BIT : 0;

  frags.visibilityFlags[fragId] = isRoomBit | wantLoadBit | transparentBit | FRAGMENT_LOADED_FLAG;

  let lo = this.metadata.fragmentTransformsOffset || _zero;

  //Read the fragment transform
  let m = composeFragmentTransform(fdata, offset + 16, lo);

  if (this.placementIsTranslation) {
    const e = this.placementWithOffset.elements;
    m.elements[12] += e[12];
    m.elements[13] += e[13];
    m.elements[14] += e[14];
  } else if (this.placementWithOffset) {
    m.multiplyMatrices(this.placementWithOffset, m);
  }

  frags.setTransform(fragId, m);

  //Read off bounding box
  let b = extractBox(fdata, offset + 26, lo);

  //Bring the bounding box to placement space
  if (this.placementIsTranslation) {
    const e = this.placementWithOffset.elements;
    b.min.x += e[12];
    b.min.y += e[13];
    b.min.z += e[14];

    b.max.x += e[12];
    b.max.y += e[13];
    b.max.z += e[14];
  } else if (this.placementWithOffset) {
    b.applyMatrix4(this.placementWithOffset);
  }

  frags.setBoundingBox(fragId, b);

  frags.polygonCounts[fragId] = idata[offset + 32] & 0xffffff;

  //Integrate the transparency flag into the fragment visibilityFlags,
  //instead of allocating yet another byte array. The BVH Builder will take it from there.
  let transparent = idata[offset + 32] >> 24 & 0x1;
  frags.visibilityFlags[fragId] |= transparent ? FRAGMENT_TRANSPARENT_BIT : 0;

  frags.numLoaded = fragId + 1; //TODO: revise this once we are not loading all fragments in order

  this.parentLoader.notifyFragmentDone(fragId);

  return true;

};

DtPackage.prototype.receiveOneFragmentFromWorker = function (ctx, idx, baseOffset, dbIdsToLoad, dbIdFilter) {

  //Fragments have ot wait for the metadata (placement transform)
  //before they can be fully processed
  if (!this.metadata)
  return false;

  let idata = ctx.idata();
  let fdata = ctx.fdata();
  let offset = baseOffset / 4;

  let frags = this.fragments;

  let fragId;
  const dbId = idata[0];

  //Check if this is a "surplus" fragment that we requested because
  //we expanded the request list to minimize the POST data payload,
  //and skip processing if so.
  if (dbIdsToLoad && !dbIdsToLoad.has(dbId)) {
    return true;
  }

  if (frags.hasFragMapping) {
    fragId = frags.getNextFragIdSlot(dbId);
  } else {
    //The index is 1-based (due to the record at index 0 being the file header
    //while the runtime FragmentList is a classic 0-based array, so we subtract 1 here.
    fragId = idx - 1;
    frags.addDbIdReference(fragId, dbId);
  }

  const meshid = idata[1];
  frags.geomDataIndexes[fragId] = meshid;

  frags.materials[fragId] = idata[2];

  let flags = idata[offset + 3];

  //Decode mesh flags (currently, we only support bit value 1 to mark hidden-by-default meshes)
  //If a filter is specified, set the "unloaded" flag to prevent the mesh geometry from being loaded up front
  let wantLoadBit = 0;
  if (dbIdFilter) {
    if (!dbIdFilter.has(dbId)) {
      wantLoadBit = MESH_NOTLOADED;
    }
  }
  //Is the fragment marked as hidden geometry? Then mark it as hidden but do not skip loading of the geometry
  let isRoomBit = flags & 1 ? FRAGMENT_IS_ROOM_BIT : 0;

  //Transparent bit -- this hack marks meshes with the hide flag to also be transparent.
  //This helps sort room meshes (which come with the hidden flag set) into the transparent objects bucket
  //of the spatial index. That will make sure rooms draw last, on top of opaque objects and will have slightly
  //more correct rollover highlighting.
  let transparentBit = isRoomBit ? FRAGMENT_TRANSPARENT_BIT : 0;

  frags.visibilityFlags[fragId] = isRoomBit | wantLoadBit | transparentBit | FRAGMENT_LOADED_FLAG;

  // Read the fragment transform
  _m.elements.set(fdata.subarray(offset + 4, offset + 20));
  frags.setTransform(fragId, _m);

  //Read off bounding box
  let b = extractBox(fdata, offset + 20, _zero /*lo*/);
  frags.setBoundingBox(fragId, b);

  frags.polygonCounts[fragId] = idata[offset + 26] & 0xffffff;

  //Integrate the transparency flag into the fragment visibilityFlags,
  //instead of allocating yet another byte array. The BVH Builder will take it from there.
  let transparent = idata[offset + 26] >> 24 & 0x1;
  frags.visibilityFlags[fragId] |= transparent ? FRAGMENT_TRANSPARENT_BIT : 0;

  frags.numLoaded = fragId + 1; //TODO: revise this once we are not loading all fragments in order

  this.parentLoader.notifyFragmentDone(fragId);

  return true;

};

DtPackage.prototype._addWorkInProgress = function (numFragments) {
  this._numFragsInProgress += numFragments;
  this.parentLoader.viewer3DImpl.geomCache().addWorkInProgress(numFragments);
};

DtPackage.prototype._abortWorkInProgress = function () {
  if (this._numFragsInProgress) {
    this.parentLoader.viewer3DImpl.geomCache().updateProgress(this._numFragsInProgress);
    this._numFragsInProgress = 0;
    this.parentLoader.viewer3DImpl.geomCache().unregisterLoader(this.modelId);
  }
};

DtPackage.prototype._updateProgress = function (numFragments) {
  this._numFragsInProgress -= numFragments;
  this.parentLoader.viewer3DImpl.geomCache().updateProgress(numFragments);
  if (this._numFragsInProgress === 0) {
    this.parentLoader.viewer3DImpl.geomCache().unregisterLoader(this.modelId);
  }
};

DtPackage.prototype._activateLoadedFragments = function (fragmentsToActivate) {let updateProgress = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  // Process already loaded fragment data. Just flip the wantLoad flag and call back immediately.
  if (fragmentsToActivate?.length) {
    this.parentLoader.trackFragmentProgress = false;

    const fragStorage = this.fragments;
    let fragId;
    let numFragsToLoad = 0;
    for (let i = 0; i < fragmentsToActivate.length; ++i) {
      fragId = fragmentsToActivate[i];
      fragStorage.setMeshLoaded(fragId, true);
      // If the fragment's geometry and material are already loaded, `notifyFragmentDone` returns true.
      // We count the number of fragments that we actually need to load data for and only communicate those to
      // the geom cache for progress tracking.
      if (!this.parentLoader.notifyFragmentDone(fragId)) {
        numFragsToLoad++;
      };
    }

    if (updateProgress && numFragsToLoad) {
      // Tell the geomCache how many fragments we're loading (to update the progress bar)
      this._addWorkInProgress(numFragsToLoad);
    }

    this.parentLoader.trackFragmentProgress = true;
  }
};

DtPackage.prototype.loadFragmentList = async function (ids, resolveDeferred, rejectDeferred) {

  let svf = this;
  let loadContext = this.loadContext;

  if (!svf.metadata && !svf.metadataInProgress) {
    console.warn("Metadata needs to be loaded before or during a fragment list request");
  }

  if (svf.fragmentsInProgress) {
    //Avoid re-entry into loadFragmentList.
    //This code path will remember resolve/reject and later pass them in
    //as resolveDeferred and rejectDeferred when the request is actually processed
    return new Promise((resolve, reject) => {
      this.queuedFragmentRequests.push({ ids, resolve, reject });
    });
  }

  svf.fragmentsInProgress = true;

  let dbIdFilter;
  if (Array.isArray(ids)) {
    dbIdFilter = new Set(ids);
  } else if (ids && typeof ids === "object") {
    dbIdFilter = new Set(Object.keys(ids));
  }

  //Flag if _all_ fragments are loaded
  if (!dbIdFilter) {
    svf.fragmentsLoaded = true;
  }

  //Find out which fragments are missing locally and need to be requested
  let { dbIdsToLoad, fragmentsToActivate, numFragments } = this.computeMissingFragments(dbIdFilter);

  //No missing fragments to load, skip the request.
  if (dbIdsToLoad && dbIdsToLoad.size === 0) {
    svf._activateLoadedFragments(fragmentsToActivate, true);
    svf.onFragmentsLoaded({ keys: [], allFragments: false });
    resolveDeferred?.();
    return;
  } else if (!dbIdsToLoad) {
    // We need to load all fragments. If the metadata is already known, we can set the number of expected fragments.
    if (svf.metadata) {
      numFragments = svf.fragments.length;
    }
  }

  //We are loading too many fragments, request the whole fragment list instead, to avoid a large post payload
  //and large key list query on the server.
  let isTooMany = false;
  if (svf.fragments && dbIdsToLoad && dbIdsToLoad.size > 0.25 * svf.fragments.length) {
    for (let dbId of dbIdsToLoad) {
      svf.fragments.resetFragIdSlot(dbId);
    }
    isTooMany = true;
  }

  let rowIds;
  if (!isTooMany && dbIdsToLoad) {
    rowIds = [];
    for (let dbId of dbIdsToLoad) {
      rowIds.push(this.autoDbIds.getEncodedElementIdWithFlags(dbId, KeyFlags.Physical /*TODO do we really need to support logical element flag here?*/));
      svf.fragments.resetFragIdSlot(dbId);
    }
    rowIds.sort();
  }

  //If allowed (no recent changes), randomly direct the request to a database replica, rather than
  //the primary (random = based on the last byte if the model GUID).
  //let lastByte = loadContext.modelId.charCodeAt(loadContext.modelId.length - 1);
  let usePrimary = loadContext.mustUsePrimary; // || lastByte % 3 === 0; //assumes one primary and two replicas, for a total of 3.

  let postData = {
    modelId: loadContext.modelId,
    readPreference: usePrimary ? "primary" : "secondaryPreferred",
    batchSize: 248, //nearest multiple to get a message size of just under 32768, which seems to be a sweet spot with current setup
    compressionLevel: 3 + 1,
    keys: rowIds
  };

  //console.log("load fragment list", rowIds?.length);

  try {
    let t0 = Date.now();

    // Tell the geomCache how many fragments we're going to process (to update the progress bar).
    // If we're loading all fragments and metadata isn't there yet, numFragments will be 0.
    // In this case, we defer this call to after loading the metadata. Fragments won't be processed
    // until then anyway.
    if (numFragments > 0) {
      this._addWorkInProgress(numFragments);
    } else {
      this.delayedSomeFragments = true;
    }

    const fragCallback = LOAD_FRAGMENTS_FROM_WORKER ? this.receiveOneFragmentFromWorker : this.receiveOneFragment;
    const defaultStride = LOAD_FRAGMENTS_FROM_WORKER ? 108 : 132;

    let ctx = new ProgressiveReadContext2((ctx, idx) => {
      return fragCallback.call(svf, ctx, idx, 0, dbIdsToLoad, dbIdFilter);
    }, defaultStride);

    const resourceName = rowIds ? "partial_fragments" : "all_fragments";

    let loadPromise;
    if (LOAD_FRAGMENTS_FROM_WORKER) {
      // Load fragment list from the worker
      loadPromise = this.loadFragmentListFromWorker(loadContext, "fragments", ctx, resourceName, postData);
    } else {
      // Load fragment list directly in the main thread
      loadPromise = this.loadAsyncProgressive(loadContext, "fragments", ctx, resourceName, postData);
    }

    // Activate fragments that have already been loaded
    svf._activateLoadedFragments(fragmentsToActivate);

    await loadPromise;

    let t1 = Date.now();
    //console.log("frag load time", t1 - t0);
    resolveDeferred?.();
  } catch (e) {
    rejectDeferred?.(e);
  }
};

function closeEnough(x, y) {
  return Math.abs(x - y) < 1e-10;
}
function isTranslationOnly(m) {
  const e = m.elements;

  return closeEnough(e[0], 1) && closeEnough(e[1], 0) && closeEnough(e[2], 0) && closeEnough(e[3], 0) &&
  closeEnough(e[4], 0) && closeEnough(e[5], 1) && closeEnough(e[6], 0) && closeEnough(e[7], 0) &&
  closeEnough(e[8], 0) && closeEnough(e[9], 0) && closeEnough(e[10], 1) && closeEnough(e[11], 0) &&
  closeEnough(e[15], 1);
}

DtPackage.prototype.processMetadata = function () {

  let svf = this;

  let metadata = svf.metadata;

  initPlacement(svf, this.loadContext);
  let pt = svf.placementWithOffset;
  svf.placementIsTranslation = pt && isTranslationOnly(pt);

  if (metadata.cameras) {
    svf.cameras = metadata.cameras;

    if (!pt) {
      pt = new LmvMatrix4(true);
    }

    for (let i = 0; i < svf.cameras.length; i++) {
      let cam = svf.cameras[i];
      cam.position = new LmvVector3(cam.position.x, cam.position.y, cam.position.z);
      cam.position.applyMatrix4(pt);
      cam.target = new LmvVector3(cam.target.x, cam.target.y, cam.target.z);
      cam.target.applyMatrix4(pt);

      cam.up = new LmvVector3(cam.up.x, cam.up.y, cam.up.z);
      cam.up.transformDirection(pt);
    }
  }

};


DtPackage.prototype.beginLoad = function (otgPath, skipFragmentList, dbIdFilter) {

  if (this.metadata) {
    this.onMetadataLoaded();
  } else if (!this.metadataInProgress) {
    this.loadMetadata(otgPath, skipFragmentList).then(() => {
      if (LOAD_FRAGMENTS_FROM_WORKER) {
        this.sendMetadataToWorker();
      }
      this.onMetadataLoaded();
    });
  }

  if (!skipFragmentList) {
    this.loadFragmentList(dbIdFilter);
  }
};

const prefixes = {
  "materials": "m",
  "geometry": "g",
  "textures": "t"
};

DtPackage.prototype.makeSharedResourcePath = function (cdnUrl, whichType, hash) {

  if (hash.length === 10)
  hash = unpackHexString(hash);

  return cdnUrl + "/" + prefixes[whichType] + "/" + this.modelId + "/" + hash;
};

DtPackage.prototype.getMaterialHash = function (materialIndex) {
  return this.materialHashes.hashes[materialIndex];
};

DtPackage.prototype.getGeometryHash = function (geomIndex) {
  return this.geomMetadata.hashes[geomIndex];
};

DtPackage.prototype.onMetadataLoaded = function () {

  this.parentLoader.notifyModelRootDone();

  if (this.delayedSomeFragments) {
    // We have requested all fragments but delayed processing until now.
    // Tell the resource cache how many we're going to process.
    this._addWorkInProgress(this.fragments.length);

    this.delayedSomeFragments = false;
  }

  if (this.delayedAllFragments) {
    //In case the fragment list already arrived, we need to finish processing it
    //once the metadata is here too
    this.delayedAllFragments = false;

    this.onFragmentsLoaded({ allFragments: true });
  }
};

DtPackage.prototype.onFragmentsLoaded = function (data) {

  //In rare cases the fragment list can appear before the metadata json is loaded
  //so we have to delay the final fragment list done notification until the metadata arrives
  if (this.metadataInProgress) {
    this.delayedAllFragments = true;
    return;
  }

  if (this.fragmentsCtx) {
    const numFrags = this.fragmentsCtx.flush();
    if (data.allFragments && numFrags < this.fragments.length) {
      // In some cases, the metadata is wrong and we don't load as many fragments as we originally thought.
      // We have to inform the resource cache that the remaining fragments will never arrive, i.e. just mark them
      // as done.
      this._updateProgress(this.fragments.length - numFrags);
    }
    this.fragmentsCtx = null;
  }

  if (!this.fragmentsInProgress) {
    console.warn("expected fragmentsInProgress flag");
  }
  this.fragmentsInProgress = false;

  this.parentLoader.notifyAllFragmentsDone(data);

  if (this.queuedFragmentRequests.length) {
    let nextFrags = this.queuedFragmentRequests.shift();
    this.loadFragmentList(nextFrags.ids, nextFrags.resolve, nextFrags.reject);
  }
};

DtPackage.prototype.abort = function () {
  this._abortWorkInProgress();
  this.aborted = true;
};