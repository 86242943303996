import { Actions } from '../../../extensions/Edit2D/Actions';
import { createRoom } from './CreateRoom';

export class ExtrudeShapeAction extends Actions.Action {
  constructor(facility, viewer, layer, shape) {
    super(layer);
    this.shape = shape;

    createRoom(facility, viewer, shape);
  }

  undo() {
  }

  redo() {
  }

  // After undo, recovered shape should be selected
  getSelectionHint(afterUndo) {
    return afterUndo ? { shape: this.shape } : null;
  }
}