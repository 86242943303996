import * as THREE from 'three';
import i18n from "i18next";

import { SpacesIcon, LevelsIcon, AssetsIcon } from "./icons";

export const HUD_LAYER = Object.freeze({
  LEVELS: { id: 'LEVELS', priority: 10, defaultPrefs: { enabled: true } },
  SPACES: { id: 'SPACES', priority: 20, defaultPrefs: { enabled: true } },
  ASSETS: { id: 'ASSETS', priority: 30, defaultPrefs: { enabled: false } }
});

export const BUTTON_CONFIG_BY_LAYER = Object.freeze({
  [HUD_LAYER.LEVELS.id]: {
    id: 'toolbar-levels-layer',
    icon: LevelsIcon,
    iconId: 'icon-levels-layer',
    tooltip: () => i18n.t('Level labels'),
    index: 1.
  },
  [HUD_LAYER.SPACES.id]: {
    id: 'toolbar-spaces-layer',
    icon: SpacesIcon,
    iconId: 'icon-spaces-layer',
    tooltip: () => i18n.t('Space labels'),
    index: 0
  },
  [HUD_LAYER.ASSETS.id]: {
    id: 'toolbar-assets-layer',
    icon: AssetsIcon,
    iconId: 'icon-assets-layer',
    tooltip: () => i18n.t('Assets labels'),
    index: 2
  }
});

export const LAYERS_CONTENT_CHANGED_EVENT = "layers_content_event";
export const LAYERS_VISIBILITY_CHANGED_EVENT = "layers_visibility_event";

const CSS_WARNING_CLASS = 'adsk-button-layer-warning';

const SCENE_UP = new THREE.Vector3(0, 0, 1);
const _tmpVec3 = new THREE.Vector3();

export class HudLayer {
  #visibility;

  facility;
  hud;
  isDirty;
  layerID;
  viewer;

  constructor(layerID, facility, hud, viewer) {
    this.facility = facility;
    this.hud = hud;
    this.viewer = viewer;

    this.layerID = layerID;
    this.isDirty = false;
    this.#visibility = true;
  }

  static byPriority(l, r) {
    return HUD_LAYER[l.layerID].priority - HUD_LAYER[r.layerID].priority;
  }

  dispose() {}

  init() {
    return this;
  }

  invalidate() {
    this.isDirty = true;
  }

  isVisible() {
    return this.#visibility;
  }

  setVisible(visible) {
    if (visible === this.#visibility) return;

    this.#visibility = visible;

    this.onLayerVisibilityChanged && this.onLayerVisibilityChanged(visible);
  }

  update(ts, ctx) {/* default behaviour */}

  setErrorMsg(msg) {
    const ctrl = this.hud.layers.controlsById[this.layerID];

    if (msg) {
      ctrl.addClass(CSS_WARNING_CLASS);
      ctrl.setToolTip(msg);
    } else {
      // Set original tooltip back when clearing the error.
      const { tooltip } = BUTTON_CONFIG_BY_LAYER[this.layerID];
      ctrl.removeClass(CSS_WARNING_CLASS);
      ctrl.setToolTip(tooltip());
    }
  }
}

/** Context provided to layers while performing their update. */
export class HudLayerCtx {
  cameraLookAtPitch;
  layerChanged;
  hudLayers;

  constructor(hudLayers) {
    this.hudLayers = hudLayers;
    this.layerChanged = {};
    this.cameraLookAtPitch = -1;
  }

  changedLayers() {
    const changed = [];
    for (const layerID in this.layerChanged) {
      if (this.layerChanged[layerID]) {
        changed.push(layerID);
      }
    }
    return changed;
  }

  reset(layers, viewer) {
    // Update helpers
    this.cameraLookAtPitch = lookAtPitchInTau(viewer.impl.camera);

    // Reset layer change.
    layers.forEach((l) => this.layerChanged[l.layerID] = false);

    return this;
  }
}

/** Angle in turns for look at pitching away from scene up. (ex: from bottom: 0, from side: 0.5) */
function lookAtPitchInTau(_ref) {let { position, target } = _ref;
  const lookAt = _tmpVec3.copy(target).sub(position);
  const rad = lookAt.angleTo(SCENE_UP);
  return Math.abs(rad) / (2 * Math.PI);
}