/** Control next to a label to clear the associated filter. */
export default class LayerBackButton {
  /** @type {string} Facet ID to clear on click. */
  facetId;
  /** @type {?(SpaceLabel|LevelLabel)} Label to host the back button on the side of. */
  hostLabel;
  /** @type {HudLayer} Layer hosting the labels and button. */
  layer;
  /** @type {HTMLDivElement} HTML element of the button. */
  el;

  constructor(layer, document, facetId) {
    this.facetId = facetId;
    this.layer = layer;
    this.el = this.#create(document, layer.facility.facetsManager);
  }

  /**
   * Adds a back button next to the passed-in label.
   * @param {?(SpaceLabel|LevelLabel)} label Label or null to detach.
   */
  attachTo(label) {
    // Already attached to the correct label.
    if (label && label === this.hostLabel) return;

    // Unmount if switching.
    if (this.el.parentElement && label !== this.hostLabel) {
      this.el.parentElement.removeChild(this.el);
    }

    if (label) {
      // Skipping as the DOM label is not yet available.
      if (!label.label) return;

      const { container } = label.label;
      container.insertBefore(this.el, container.children[0]);
    }

    this.hostLabel = label;
  }

  #create(_document, facetsManager) {
    const el = _document.createElement('div');
    el.classList.add('adsk-button-layer-back');
    el.addEventListener('click', (event) => {
      facetsManager.clearFilter(this.facetId);

      event.preventDefault();
      event.stopPropagation();
    });

    return el;
  }
}