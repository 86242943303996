import { ROOMS_OVERLAY } from "./facets/FacetVizEffects";
import { VBIntersector } from "../wgs/scene/VBIntersector";
import * as et from "../application/EventTypes";
import { Prefs } from "../application/PreferenceNames";
import THREE from "three";

const av = Autodesk.Viewing;

const _idRes = [0, 0];

export class DtSelectionTool {

  constructor(viewer) {let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    this.viewer = viewer;
    this.options = options;
    this.facility = options.facility;
    this.shouldClearIntersections = true;
    this.isDragging = false;

    this.resetIntersections();
  }

  onSelectionChanged = () => {
    if (this.shouldClearIntersections) {
      this.resetIntersections();
    } else {
      this.shouldClearIntersections = true;
    }
  };

  resetIntersections() {
    this.intersections = [];
    this.selectIdx = -1;
  }

  getName() {
    return 'dtselect';
  }

  getNames() {
    return ['dtselect'];
  }

  activate() {
    this.active = true;
  }

  deactivate() {
    this.active = false;
  }

  isActive() {
    return this.active;
  }

  register() {
    this.viewer.addEventListener(et.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);
  }

  deregister() {
    this.viewer.removeEventListener(et.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);
  }

  handleButtonDown() {
    this.isDragging = true;
  }

  handleButtonUp() {
    this.isDragging = false;
  }

  update() {
    return false;
  }

  handleMouseMove(event) {

    if (this.isDragging) {
      return false;
    }

    if (!this.viewer.prefs.get(Prefs.DISPLAY_OBJECT_INFO)) {
      return false;
    }

    let vImpl = this.viewer.impl;
    if (event.target === vImpl.canvas)
    {
      let vpVec = vImpl.clientToViewport(event.canvasX, event.canvasY);

      // Disable highlight for dbids that have a selection lock.
      const dbId = vImpl.renderer().idAtPixel(vpVec.x, vpVec.y, _idRes);

      if (dbId > 0) {
        let model = vImpl.modelQueue().findModel(_idRes[1]);

        if (model) {
          let name, typeName, famName;

          let it = model.getInstanceTree();

          if (it) {
            name = it.getNodeName(dbId, false);
            let fTypeId = it.getNodeParentId(dbId);
            if (fTypeId) {
              typeName = it.getNodeName(fTypeId, false);
              let famId = it.getNodeParentId(fTypeId);
              if (famId) {
                famName = it.getNodeName(famId, false);
              }
            }
          }

          if (famName === name) {
            this.viewer.getStatusBar().setText([famName, typeName].join(" : "));
          } else {
            this.viewer.getStatusBar().setText(name + " (" + [famName, typeName].join(" : ") + ")");
          }

        }
      } else {
        this.viewer.getStatusBar().setText("");
      }
    }

  }

  handleSingleClick(event, button) {
    this.resetIntersections();

    const control = event.ctrlKey || event.metaKey;
    const shift = event.shiftKey;
    const alt = event.altKey;

    if (button === 0 && !control && !shift && !alt) {
      const vpVec = this.viewer.impl.clientToViewport(event.canvasX, event.canvasY);

      //Check if any objects in the main scene are hit
      let intersections = [];
      this.viewer.impl.hitTestViewport(vpVec.clone(), false, null, null, intersections);

      //In clustering mode `intersections` array could contain cluster plane geometry
      //which doesn't have any model assigned
      intersections = intersections.filter((a) => a.model);

      if (intersections.length) {
        // sort the intersections by increasing distance but put all rooms last
        intersections.sort((a, b) => {
          const aIsRoom = a.model.isRoom(a.dbId);
          if (aIsRoom != b.model.isRoom(b.dbId)) {
            return aIsRoom ? 1 : -1;
          }
          return a.distance - b.distance;
        });

      }

      // if nothing else was hit, compute intersect with rooms temp geometries in rooms overlay
      const roomOverlay = this.viewer.impl.overlayScenes[ROOMS_OVERLAY];
      if (roomOverlay?.scene && roomOverlay.scene.children.length > 0) {
        const ray = new THREE.Ray();
        this.viewer.impl.viewportToRay(vpVec.clone(), ray);
        let raycaster = new THREE.Raycaster(ray.origin, ray.direction);

        const intersectsInRoomOverlay = [];
        VBIntersector.intersectObject(roomOverlay.scene, raycaster, intersectsInRoomOverlay, true);
        intersectsInRoomOverlay.sort((a, b) => a.distance - b.distance);

        intersectsInRoomOverlay.forEach((i) => intersections.push(i));
      }

      if (intersections.length) {
        // deduplicate hits, so that intersection cycling does not
        // repeatedly visit the same element
        const unique = {};
        intersections = intersections.filter((_ref) => {let { model, dbId } = _ref;
          const dbIds = unique[model] || (unique[model] = new Set());
          if (dbIds.has(dbId)) {
            return false;
          }
          dbIds.add(dbId);
          return true;
        });
        // select the first hit
        this.viewer.select(intersections[0].dbId, intersections[0].model);
        // retain all hits to be able to cycle through them (on TAB)
        this.intersections = intersections;
        this.selectIdx = 0;
        return true;
      }

    }

    return false;
  }

  handleDoubleClick(event, button) {
    // Prevent the next AGGREGATE_SELECTION_CHANGED_EVENT from clearing the list of intersections in this tool.
    // Needed because we want to preserve the list of intersections beyond the DefaultHandler's behaviour.
    this.shouldClearIntersections = false;

    return false;
  }

  handleKeyDown(event, keyCode) {
    // TAB cycles the intersection result (in reverse w/ SHIFT modifier)
    if (keyCode === av.KeyCode.TAB && this.intersections.length) {
      if (event.shiftKey) {
        if (--this.selectIdx < 0) {
          this.selectIdx = this.intersections.length - 1;
        }
      } else {
        if (++this.selectIdx >= this.intersections.length) {
          this.selectIdx = 0;
        }
      }

      // fire the event first before setting the selection to avoid clear the cached intersection in selection
      // changed event handler.
      this.shouldClearIntersections = false;
      this.viewer.select(this.intersections[this.selectIdx].dbId, this.intersections[this.selectIdx].model);

      return true;
    }

    return false;
  }
}