
import { TOL } from "./fuzzy-math";

function ABS(x) {
  return Math.abs(x);
}

const EPS = TOL;

export const ONE_INTERSECTION = 4;
export const OVERLAP = 5;


//Returns true if the given point lies on and inside the given line segment
export function pointOnLine(x, y, e, checkInsideSegment, precisionDistance, outPt) {

  if (e.length < EPS) {
    return false;
  }

  let dot = (x - e.v1.x) * e.dx + (y - e.v1.y) * e.dy;

  if (!precisionDistance)
  precisionDistance = EPS * e.length;

  let u = dot / e.length2;

  if (checkInsideSegment) {
    if (u * e.length < -precisionDistance || u * e.length > e.length + precisionDistance)
    return false;
  }

  let lx = e.v1.x + u * e.dx;
  let ly = e.v1.y + u * e.dy;

  let len2 = (lx - x) * (lx - x) + (ly - y) * (ly - y);

  if (outPt) {
    outPt.x = lx;
    outPt.y = ly;
    outPt.d = Math.sqrt(len2);
    outPt.u = u;
  }

  if (len2 < precisionDistance * precisionDistance)
  return true;

  return false;
}


function parallelLinesOverlap(e1, e2, precisionDistance) {

  //Check of the segments are parallel but not on the same infinite line
  if (!pointOnLine(e2.v1.x, e2.v1.y, e1, false, precisionDistance)) {
    return null;
  }

  let res = {
    status: OVERLAP,
    e1: [],
    e2: []
  };

  //They are on the same line. Find overlap points.
  //TODO: There is probably a more efficient way to do this
  let p3_seg1 = pointOnLine(e2.v1.x, e2.v1.y, e1, true, precisionDistance);
  let p4_seg1 = pointOnLine(e2.v2.x, e2.v2.y, e1, true, precisionDistance);

  //If both points of the second segment are inside the first
  //then the reverse cannot be true...
  if (p3_seg1 && p4_seg1) {
    res.e1.push(e2.v1.x, e2.v1.y, e2.v2.x, e2.v2.y);
    return res;
  }

  let p1_seg2 = pointOnLine(e1.v1.x, e1.v1.y, e2, true, precisionDistance);
  let p2_seg2 = pointOnLine(e1.v2.x, e1.v2.y, e2, true, precisionDistance);

  if (p3_seg1)
  res.e1.push(e2.v1.x, e2.v1.y);
  if (p4_seg1)
  res.e1.push(e2.v2.x, e2.v2.y);
  if (p1_seg2)
  res.e2.push(e1.v1.x, e1.v1.y);
  if (p2_seg2)
  res.e2.push(e1.v2.x, e1.v2.y);

  return res;
}


/*
   Determine the intersection point of two line segments
   Modified source from here:
   http://www.paulbourke.net/geometry/pointlineplane/
*/
export function segmentsIntersect(e1, e2, precisionDistance)
{
  precisionDistance ??= EPS;
  let denom = e2.dy * e1.dx - e2.dx * e1.dy;
  let numera = e2.dx * (e1.v1.y - e2.v1.y) - e2.dy * (e1.v1.x - e2.v1.x);
  let numerb = e1.dx * (e1.v1.y - e2.v1.y) - e1.dy * (e1.v1.x - e2.v1.x);

  /* Are the lines parallel */
  if (ABS(denom) < precisionDistance) {
    /* check for overlap */
    return parallelLinesOverlap(e1, e2, precisionDistance);
  }

  /* Is the intersection along the segments */
  let mua = numera / denom;
  let da = mua * e1.length;
  if (da < -precisionDistance || da > e1.length + precisionDistance) {
    return null;
  }

  let mub = numerb / denom;
  let db = mub * e2.length;
  if (db < -precisionDistance || db > e2.length + precisionDistance) {
    return null;
  }

  let x = e1.v1.x + mua * e1.dx;
  let y = e1.v1.y + mua * e1.dy;

  return {
    status: ONE_INTERSECTION,
    e1: [x, y],
    e2: [x, y]
  };
}

const delta = { x: 0, y: 0 };
const deltaPt = { x: 0, y: 0 };
const closestPt = { x: 0, y: 0 };
/**
 * Square distance between a point and an infinite line in 2D.
 * Inspired from http://www.paulbourke.net/geometry/pointlineplane/
 * @param {THREE.Vector2} pt 2D Point
 * @param {THREE.Vector2} start Start of segment representing infinite 2D line
 * @param {THREE.Vector2} end End of segment representing infinite 2D line (must not equal start)
 * @return {number}
 */
export function pointLineDistanceSq(pt, start, end) {
  delta.x = end.x - start.x;
  delta.y = end.y - start.y;

  deltaPt.x = pt.x - start.x;
  deltaPt.y = pt.y - start.y;

  // Coefficient along the line where to find the intersection.
  const u = (deltaPt.x * delta.x + deltaPt.y * delta.y) / (delta.x * delta.x + delta.y + delta.y);

  // Closest point on the line, to the given point.
  closestPt.x = start.x + u * delta.x;
  closestPt.y = start.y + u * delta.y;

  return ptDistanceToSquared(closestPt, pt);
}

function ptDistanceToSquared(p1, p2) {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  return dx * dx + dy * dy;
}

export const CROSS_TYPE = {
  EMPTY_SET: 0,
  POINT: 1,
  SEGMENT: 2
};

/**
 * Gets the intersection between a segment and a line in 2D.
 *
 * The result can be either the segment of coincidence, the point of intersection, or nothing. Similar to
 * segmentsIntersect but leverages the use of an infinite line to simplify computation. Also uses a tolerance in model
 * unit. Both impl. from http://www.paulbourke.net/geometry/pointlineplane/
 * @param {{v1, v2}} segment 2D Line segment
 * @param {{v1, v2}} line Infinite 2D line, must be non-punctual
 * @param {number} [tolerance] Tolerance in model unit.
 * @return {{ type: number }}
 */
export function segmentToLineIntersection(segment, line) {let tolerance = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1E-4;
  const tolSq = tolerance * tolerance;

  // If segment is coincident to the line, the overlap is the segment.
  let d1 = pointLineDistanceSq(segment.v1, line.v1, line.v2);
  let d2 = pointLineDistanceSq(segment.v2, line.v1, line.v2);
  if (Math.max(d1, d2) < tolSq) {
    return { type: CROSS_TYPE.SEGMENT, segment };
  }

  // Find intersection between segment and line. (line is 1-2, segment is 3-4)
  const denom = (segment.v2.y - segment.v1.y) * (line.v2.x - line.v1.x) - (segment.v2.x - segment.v1.x) * (line.v2.y - line.v1.y);
  // Lines are parallel but not coincident.
  if (denom === 0) {
    return { type: CROSS_TYPE.EMPTY_SET };
  }

  // Not parallel, find intersection along segment
  const ub = ((line.v2.x - line.v1.x) * (line.v1.y - segment.v1.y) - (line.v2.y - line.v1.y) * (line.v1.x - segment.v1.x)) / denom;
  const point = {
    x: segment.v1.x + ub * (segment.v2.x - segment.v1.x),
    y: segment.v1.y + ub * (segment.v2.y - segment.v1.y)
  };

  // If point is squarely inside the segment.
  if (ub >= 0 && ub <= 1) {
    return { type: CROSS_TYPE.POINT, point };
  }

  // If point is close enough to either of the two segment ends.
  if (ptDistanceToSquared(point, segment.v1) < tolSq) return { type: CROSS_TYPE.POINT, point };
  if (ptDistanceToSquared(point, segment.v2) < tolSq) return { type: CROSS_TYPE.POINT, point };

  // Else no intersections
  return { type: CROSS_TYPE.EMPTY_SET };
}