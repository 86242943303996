
import { ControlGroup } from "../controls/ControlGroup";
import { Control } from "../controls/Control";

const VERTICAL_TOOLBAR_RIGHT_OFFSET = 15;

/**
 * Core class representing a toolbar UI.
 *
 * It consists of {@link Autodesk.Viewing.UI.ControlGroup} that group controls by functionality.
 *
 * @alias Autodesk.Viewing.UI.ToolBar
 * @param {string} id - The id for this toolbar.
 * @param {object} [options] - An optional dictionary of options.
 * @param {boolean} [options.collapsible=true] - Whether this toolbar is collapsible.
 * @param {boolean} [options.alignVertically=false] - Whether this toolbar should be vertically positioned on the right side.
 * @class
 * @augments Autodesk.Viewing.UI.ControlGroup
 * @memberof Autodesk.Viewing.UI
 */
export function ToolBar(id, options) {
  ControlGroup.call(this, id, options);

  this.removeClass('adsk-control-group');
  this.addClass('adsk-toolbar');

  if (options && options.alignVertically) {
    this.addClass('adsk-toolbar-vertical');
  }

  this.isVertical = function () {
    return this.container.classList.contains("adsk-toolbar-vertical");
  };

  // returns the amount vertical toolbar is shifted from right excluding its default right offset (e.g. using shiftControls method)
  this.getRightShift = function () {
    if (!this.isVertical() || !this.container.style.right) {
      return 0;
    }

    return parseInt(this.container.style.right) - VERTICAL_TOOLBAR_RIGHT_OFFSET;
  };
}

/**
 * Enum for toolbar event IDs.
 *
 * @readonly
 * @enum {string}
 */
ToolBar.Event = {
  // Inherited from Control
  VISIBILITY_CHANGED: Control.Event.VISIBILITY_CHANGED,
  COLLAPSED_CHANGED: Control.Event.COLLAPSED_CHANGED,

  // Inherited from ControlGroup
  CONTROL_ADDED: ControlGroup.Event.CONTROL_ADDED,
  CONTROL_REMOVED: ControlGroup.Event.CONTROL_REMOVED,
  SIZE_CHANGED: ControlGroup.Event.SIZE_CHANGED
};

ToolBar.prototype = Object.create(ControlGroup.prototype);
ToolBar.prototype.constructor = ToolBar;

ToolBar.shiftControls = function (amount) {
  const toolbarSelector = '.adsk-viewing-viewer .adsk-toolbar.adsk-toolbar-vertical';
  const cubeSelector = '.viewcubeWrapper';
  if (amount) {
    const toolbarRightValue = `${amount + VERTICAL_TOOLBAR_RIGHT_OFFSET}px`;
    for (const element of document.querySelectorAll(toolbarSelector)) {
      element.style.right = toolbarRightValue;
    }
    const cubeRightValue = `${amount - 1}px`;
    for (const element of document.querySelectorAll(cubeSelector)) {
      element.style.right = cubeRightValue;
    }
  } else {
    for (const element of document.querySelectorAll(toolbarSelector)) {
      element.style.right = null;
    }
    for (const element of document.querySelectorAll(cubeSelector)) {
      element.style.right = null;
    }
  }

  const bimWalkInfoButton = document.querySelector('#tooltip-info');
  if (bimWalkInfoButton) {
    // shift amount + info button right offset (12px)
    bimWalkInfoButton.style.right = amount ? `${amount + 12}px` : null;
  }
};