import { EventDispatcher } from "../application/EventDispatcher";
import { DtUser } from "./DtUser";
import { fetchFile, fetchJson } from "../net/fetch";
import { fixGroupUrn } from "./encoding/urn";
import { DT_TEAM_USERS_CHANGED_EVENT, DT_TEAM_CHANGED_EVENT, DT_TEAM_FACILITIES_CHANGED_EVENT } from "./DtEventTypes";
import { PermissionChecker } from "./PermissionChecker";
import { DtConstants } from "./schema/DtConstants";

/**
 * Represents team.
 *
 * @alias Autodesk.Tandem.DtTeam
 */
export class DtTeam {
  /**
   * Constructor
   *
   * @param {DtApp} app
   * @param {object} team
   */
  constructor(app, team) {
    this.app = app;

    this.id = fixGroupUrn(team.urn);
    this.name = team.name;
    this.accessLevel = team.accessLevel;
    this.accountSettings = team.accountSettings;
    this.owner = team.owner;

    this.users = null;
    this.facilities = null;
    this.thumbnailLastUpdate = team.thumbnailLastUpdate;
    this.usageEmailStatus = team.usageEmailStatus;
  }

  /**
   * Returns the URN of the team.
   *
   * @returns {string}
   *
   * @alias Autodesk.Tandem.DtTeam#urn
   */
  urn() {
    return this.id;
  }

  /**
   * Sets the name of the team.
   *
   * @param {string} name
   *
   * @alias Autodesk.Tandem.DtTeam#setName
   */
  async setName(name) {
    if (this.name !== name) {
      await fetchJson(this.app.loadContext, `/groups/${this.urn()}`, "PATCH", { name });
      this.name = name;
      this.app.dispatchEvent({
        type: DT_TEAM_CHANGED_EVENT,
        team: this
      });
    }
  }

  async updateThumbnail(file) {
    await fetchFile(this.app.loadContext, `/groups/${this.urn()}/thumbnail`, "PUT", file);
    this.thumbnailLastUpdate = Date.now();
    this.app.dispatchEvent({
      type: DT_TEAM_CHANGED_EVENT,
      team: this
    });
  }

  async deleteThumbnail() {
    await fetchJson(this.app.loadContext, `/groups/${this.urn()}/thumbnail`, "DELETE");
    this.thumbnailLastUpdate = null;
    this.app.dispatchEvent({
      type: DT_TEAM_CHANGED_EVENT,
      team: this
    });
  }


  get thumbnailUrl() {
    return this.thumbnailLastUpdate ? this.app.loadContext.endpoint + `/groups/${this.urn()}/thumbnail?t=${this.thumbnailLastUpdate}` : null;
  }

  // PermissionChecker impl
  _getAccessLevel() {
    return this.accessLevel;
  }

  /**
   * Returns the users of the team.
   *
   * @returns {Promise<object[]>}
   *
   * @alias Autodesk.Tandem.DtTeam#getUsers
   */
  async getUsers() {
    if (!this._usersPromise) {
      this._usersPromise = (async () => {
        const team = await fetchJson(this.app.loadContext, `/groups/${this.urn()}`);
        this._users = team.users.map((u) => new DtUser(u));
      })();
    }

    await this._usersPromise;
    return this._users;
  }

  async updateUserAccessBulk(dtUsers) {
    await Promise.all(
      dtUsers.map((_ref) => {let { id, accessLevel } = _ref;return (
          fetchJson(this.app.loadContext, `/groups/${this.urn()}/users/${id}`, "PUT", { accessLevel }));}
      )
    );

    this._usersPromise = null;

    this.app.dispatchEvent({
      type: DT_TEAM_USERS_CHANGED_EVENT,
      team: this
    });
  }

  async updateUserAccess(dtUser) {
    return this.updateUserAccessBulk([dtUser]);
  }

  async resendInvitation(dtUser) {
    await fetchJson(this.app.loadContext, `/groups/${this.urn()}/users/${dtUser.id}/resend-email`, "POST");
  }

  // facilites
  async loadFacilities() {let forceRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    return !forceRefresh && this.loadFacilitiesPromise || (this.loadFacilitiesPromise = this._loadFacilities());
  }

  async _loadFacilities() {
    const twins = await fetchJson(this.app.loadContext, `/groups/${this.urn()}/twins`);

    this.facilities = [];
    for (let twinID in twins) {
      const settings = twins[twinID];
      this.facilities.push(
        this.app.getOrCreateFacility(
          twinID,
          settings)
      );
    }

    this.app.dispatchEvent({
      type: DT_TEAM_FACILITIES_CHANGED_EVENT,
      team: this
    });
  }

  async getFacilities() {let forceRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    await this.loadFacilities(forceRefresh);
    return this.facilities;
  }

  findFacility(urn) {
    if (this.facilities) {
      for (let f of this.facilities) {
        if (f.urn() === urn) {
          return f;
        }
      }
    }
    return null;
  }

  async _createFacility(path, settings) {
    const res = await fetchJson(this.app.loadContext, path, "POST", settings ? { settings } : null);
    const facility = this.app.getOrCreateFacility(res.k);
    // TODO: would be best if the above json would return enough to skip this load step
    await facility.load();
    if (!this.facilities) {
      this.facilities = [facility];
    } else {
      this.facilities.push(facility);
    }
    this.app.dispatchEvent({
      type: DT_TEAM_FACILITIES_CHANGED_EVENT,
      team: this
    });
    return facility;
  }

  async createSampleFacility() {
    return this._createFacility(`/groups/${this.urn()}/make-sample`);
  }

  async createFacility(settings) {
    return this._createFacility(`/groups/${this.urn()}/twins`, settings);
  }

  async deleteFacility(urn) {
    await fetchJson(this.app.loadContext, `/twins/${urn}`, 'DELETE', null);
    if (this.facilities) {
      const facilityIdx = this.facilities.findIndex((a) => a.urn() === urn);
      if (facilityIdx !== -1) {
        this.facilities.splice(facilityIdx, 1);
        this.app.dispatchEvent({
          type: DT_TEAM_FACILITIES_CHANGED_EVENT,
          team: this
        });
      }
    }
    return this.facilities;
  }

  // metrics
  async loadMetrics(start, end) {
    const params = start && end ? `?start=${start}&end=${end}` : '';

    return await fetchJson(this.app.loadContext, `/groups/${this.urn()}${params}/metrics`);
  }

  // Handover

  /**
   * Validate target account info prior twin handover
   * @param {Object} validationRequest - The required input for validation
   * @param {string} validationRequest.groupID - The id of target account
   * @param {string} validationRequest.email - The email of the account admin
   */
  async validateGroup(validationRequest) {
    const path = `/groups/${this.urn()}/validate`;
    return await fetchJson(this.app.loadContext, path, "POST", validationRequest);
  }

  /**
   * Transfers the current facility to the specified target group
   * @param {DtFacility} twin - Twin to move.
   * @param {string} targetGroupID - The group ID of where this facility will be delivered to
   * @param {boolean} revokeApiKeys - Whether to revoke all Twin-level API key permissions as part of the move.
   */
  async deliver(twin, targetGroupID, revokeApiKeys) {
    const idx = this.facilities?.indexOf(twin);
    if (idx === -1) throw new Error(`twin to deliver does not exist in account: ${twin.urn()}`);

    let path = `/twins/${twin.urn()}/move/${targetGroupID}`;
    await fetchJson(this.app.loadContext, path, 'POST', { revokeApiKeys: !!revokeApiKeys });

    // Proactively updates the listed twins instead of waiting for resulting events. (One removed and receipt added)
    this.loadFacilities(true).
    catch((err) => console.error('Failed to force refresh of team twins', err));
  }

  /** Reacts to external change notifications for the active DtTeam */
  onTeamChanged(change) {
    if (change.ctype === DtConstants.ChangeTypes.UpdateGroupTwins) {

      // There are no case of group Twins updated, with no added and no removed.
      if (change.removedElements.length && !change.addedElements.length) {
        const twinUrns = change.removedElements.map((id) => DtConstants.DT_TWIN_URN_PREFIX + ":" + id);
        for (const urn of twinUrns) {
          const idx = this.facilities?.findIndex((f) => f.urn() === urn);
          if (idx && idx !== -1) {
            this.facilities.splice(idx, 1);
          }
        }

        this.app.dispatchEvent({
          type: DT_TEAM_FACILITIES_CHANGED_EVENT,
          team: this
        });

      } else {
        // If it's not purely a removal, reload everything.
        this.loadFacilities(true).
        catch((err) => console.error('Failed to force refresh of team twins', err));
      }
    }

    this.app.dispatchEvent({
      type: DT_TEAM_CHANGED_EVENT,
      team: this,
      change
    });
  }

  async getHistory(timestamps, min, max, includeChanges, limit) {
    const body = { timestamps, min, max, includeChanges, limit };
    return fetchJson(this.app.loadContext, `/groups/${this.urn()}/history`, 'POST', body);
  }
}

EventDispatcher.prototype.apply(DtTeam.prototype);
PermissionChecker.prototype.apply(DtTeam.prototype);
DtTeam.prototype.constructor = DtTeam;