"use strict";

// http://www.onicos.com/staff/iz/amuse/javascript/expert/utf.txt
/* utf.js - UTF-8 <=> UTF-16 convertion
 *
 * Copyright (C) 1999 Masanao Izumo <iz@onicos.co.jp>
 * Version: 1.0
 * LastModified: Dec 25 1999
 * This library is free.  You can redistribute it and/or modify it.
 */
export function utf8BlobToStr(array, start, length) {
  var i, len, c;
  var char2, char3;

  var outcodes = new Array(length);
  var idx = 0;

  len = length;
  i = 0;
  while (i < len) {
    c = array[start + i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        outcodes[idx++] = c;
        break;
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[start + i++];
        outcodes[idx++] = (c & 0x1F) << 6 | char2 & 0x3F;
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[start + i++];
        char3 = array[start + i++];
        outcodes[idx++] = (c & 0x0F) << 12 |
        (char2 & 0x3F) << 6 |
        (char3 & 0x3F) << 0;
        break;
    }
  }

  if (idx === length) {
    return String.fromCharCode.apply(null, outcodes);
  } else {
    return String.fromCharCode.apply(null, outcodes.slice(0, idx));
  }
}

/**
 * Safe version of utf8BlobToStr(), where Arrays are used to concatenate chars via join().
 * This function exists because string::operator += crashes on Chrome with large inputs.
 */
export function safeUtf8BlobToStr(array, start, length) {
  var out, i, len, c, outArray, count;
  var char2, char3;

  var STR_CVT_LIMIT = 32 * 1024;
  out = '';
  outArray = [];
  len = length;
  count = 0;
  i = 0;
  while (i < len) {
    c = array[start + i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        outArray.push(String.fromCharCode(c));
        break;
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[start + i++];
        outArray.push(String.fromCharCode((c & 0x1F) << 6 | char2 & 0x3F));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[start + i++];
        char3 = array[start + i++];
        outArray.push(String.fromCharCode((c & 0x0F) << 12 |
        (char2 & 0x3F) << 6 |
        (char3 & 0x3F) << 0));
        break;
    }
    if (++count >= STR_CVT_LIMIT || i >= len) {
      out += outArray.join("");
      outArray.length = 0;
      count = 0;
    }
  }

  return out;
}

export function utf16to8Length(str) {

  let j = 0;
  let len = str.length;

  //estimate the required buffer size and return that.
  for (let i = 0; i < len; i++) {
    let c = str.charCodeAt(i);
    if (c >= 0x0001 && c <= 0x007F) {
      j++;
    } else if (c > 0x07FF) {
      j += 3;
    } else {
      j += 2;
    }
  }

  return j;
}

export function utf16to8(str, array, start) {

  let j = start || 0;
  let len = str.length;

  for (let i = 0; i < len; i++) {
    let c = str.charCodeAt(i);
    if (c >= 0x0001 && c <= 0x007F) {
      array[j++] = c;
    } else if (c > 0x07FF) {
      array[j++] = 0xE0 | c >> 12 & 0x0F;
      array[j++] = 0x80 | c >> 6 & 0x3F;
      array[j++] = 0x80 | c >> 0 & 0x3F;
    } else {
      array[j++] = 0xC0 | c >> 6 & 0x1F;
      array[j++] = 0x80 | c >> 0 & 0x3F;
    }
  }

  return j - (start || 0);
}

export const SAFE_UTF_LENGTH = 2048;

export function utf8ArrayToString(array, start, length) {

  if (start === undefined)
  start = 0;
  if (length === undefined)
  length = array.length;

  if (length > SAFE_UTF_LENGTH) {
    return safeUtf8BlobToStr(array, start, length);
  }
  return utf8BlobToStr(array, start, length);
}

export function blobToJson(blob) {
  var decodedString = utf8ArrayToString(blob, 0, blob.length);
  return JSON.parse(decodedString);
}

//parses a piece of json from a given blob (representing an array of json values)
//up to the next comma+newline combo (i.e. array delimiter).
export function subBlobToJson(blob, startIndex, endIndex) {

  let i;

  if (endIndex) {
    i = endIndex;

    //If approximate end index is given, find the closing } character right before it
    while (blob[i - 1] !== 125) {
      i--;
    }
  } else {
    i = startIndex;

    while (i < blob.length - 1) {
      let c = blob[i];
      if (c === 44 && (blob[i + 1] === 10 || blob[i + 1] === 13)) //comma followed by newline?
        break;
      if (c === 10 || c === 13) //detect newline or line feed
        break;
      i++;
    }
  }

  let length = i - startIndex;

  let decodedString;
  if (length > SAFE_UTF_LENGTH) {
    decodedString = safeUtf8BlobToStr(blob, startIndex, length);
  } else {
    decodedString = utf8BlobToStr(blob, startIndex, length);
  }

  return JSON.parse(decodedString);
}