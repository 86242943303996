// Copyright 2023 Autodesk, Inc.
// All rights reserved.
// This computer source code and related instructions and comments are the
// unpublished confidential and proprietary information of Autodesk, Inc.
// and are protected under Federal copyright and state trade secret law.
// They may not be disclosed to, copied or used by any third party without
// the prior written consent of Autodesk, Inc.


import { v4 } from "uuid";
import { endpoint } from "../net/endpoints";
import { fetchJson, fetchFile, fetchJsonAndETag, DtHttpError } from "../net/fetch";
import { isNodeJS } from "../compat";
import { DtModel } from "./DtModel";
import { fixTwinUrn } from "./encoding/urn";
import { EventDispatcher } from "../application/EventDispatcher";
import * as dte from "./DtEventTypes";
import { intersectPropertiesDt } from "./intersect-props";
import { FacetsManager } from "./facets/FacetsManager";
import { FacetTypes } from "./facets/Facets";
import { DtConstants } from "./schema/DtConstants";
import { PermissionChecker } from "./PermissionChecker";
import { DtFacilityTemplate } from "./DtFacilityTemplate";
import { SystemsManager } from "./connectivity/SystemsManager";
import { LmvMatrix4 } from "../wgs/scene/LmvMatrix4";
import { StreamManager } from "./streams/StreamManager";
import { Prefs3D } from "../application/PreferenceNames";
import * as et from "../application/EventTypes";
import { InstanceTreeAccess } from "../wgs/scene/InstanceTreeStorage";
import { base64DecToArr, base64EncArr } from "./encoding/base64";
import { ElementIdSize, ElementIdWithFlagsSize, ElementFlags, ModelIdSize, QC, RowKeySize } from "./schema/dt-schema";
import { DtSelectionTool } from "./DtSelectionTool";
import { DtViewerState } from "./DtViewerState";
import { DtUser } from "./DtUser";
import { HUD } from "./hud/Hud";
import { searchFacility } from "./search/FacilityIndex";
import { enumB64FullId } from "./schema/DtKeysEnumerator";
import { AutoViewMenu } from "./autoview/AutoViewMenu";

const ChangeTypes = DtConstants.ChangeTypes;

const ModelState = DtConstants.ModelImportState;

const BULK_IMPORT_EVENTS = Object.freeze(
  new Set([
  DtConstants.ChangeTypes.BulkFail,
  DtConstants.ChangeTypes.BulkImport,
  DtConstants.ChangeTypes.BulkUpdate]
  )
);

const LoadPriority = {
  Primary: 0,
  Visible: 1,
  Hidden: 2
};

/**
 * Represents facility.
 *
 * @alias Autodesk.Tandem.DtFacility
 */
export class DtFacility {

  /**
   * @constructor
   *
   * @param {DtApp} app
   * @param {string} twinId
   * @param {Object} initialSettings
   */
  constructor(twinId, app, initialSettings) {

    this.app = app;
    this.eventTarget = app; //TODO: backwards compatibility, too much code references this to clean up at once.

    this.models = {};
    this.loadQueue = [];
    this.modelsInProgress = new Set();
    this.modelsToAbort = new Set();
    this.waitForAllLoadCallbacks = [];

    this.twinId = fixTwinUrn(twinId);

    if (initialSettings) {
      const { etag, ...rest } = initialSettings;
      this.settingsEtag = etag;
      this.settings = rest;
    }

    //This will be set later when the main model is loaded.
    this.globalOffset = undefined;

    //LMV loader options.
    //The will be overridden by the external viewer config
    //once the viewer is set, but these are the defaults we want.
    let loadOptions = {};

    //To support partial and delayed loading of geometry, we
    //will need to keep some loader data around for longer than the initial load
    //sequence.
    loadOptions.keepLoaderAlive = true;

    this.loadOptions = loadOptions;

    this.loadContext = { ...app.loadContext };
    this.loadContext.twinId = this.twinId;

    //Setting -1 means that the central worker callback handling only will be used
    //and not any specific registered worker message callback function.
    this.loadContext.cbId = -1;

    this.worker = app.getWorker();

    this.systemsManager = new SystemsManager(this);

    this.streamMgr = new StreamManager(this);

    if (this.settings) {
      this._initModels();
      this.settings.template = new DtFacilityTemplate(this, this.settings.template ?? {});
    }

    this._cachedTwinMetrics = undefined;
  }

  urn() {
    return this.twinId;
  }

  async onFacilityChanged(change) {

    // one-time notification, unsubscribe
    if (change.ctype === ChangeTypes.UpdateMetrics) {

      this.app.unsubscribeFromEvents(this);

    } else if (change.ctype === ChangeTypes.ApplyTemplate || change.ctype === ChangeTypes.RemoveTemplate) {

      //If event.change.template/deleteMode is set, the event was something that was triggered based on a local change
      //so we don't have to re-fetch the data from the server.
      if (change.template) {
        this.settings.classification = change.template.classification;
      } else if (change.deleteMode) {
        const masterformat = await DtConstants.getClassification(DtConstants.MASTERFORMAT_UUID);
        // fire and forget, set classification to default masterformat after removing facility template
        this.updateClassificationTemplate(masterformat);
        this.settings.classification = masterformat;
      } else {
        await this.getClassificationTemplate(true);
      }

      let needFacetUpdate = false;

      // If Classification Facet is used, update it
      const facetDefs = this.facetsManager?.getFacetDefs() || [];
      const classificationFacet = facetDefs.find((facetDef) => facetDef.id === FacetTypes.classifications);
      if (classificationFacet) {
        classificationFacet.setClassification(this.settings?.classification);
        needFacetUpdate = true;
      }

      //Need to refresh the cached attribute lists in the property workers, as those change when a template
      //is applied.
      await this._reloadAttributes();

      // invalidate parameters classifier for each model (since we just reloaded attributes after template change)
      for (const model of this.getModels()) {
        if (model.facetsClassifiers?.[FacetTypes.parameters]) {
          model.facetsClassifiers[FacetTypes.parameters] = null;
        }
      }

      // need facet update if parameters facet is already in use
      const parametersFacet = facetDefs.find((facetDef) => facetDef.id === FacetTypes.parameters);
      if (parametersFacet) {
        needFacetUpdate = true;
      }

      // if the facet manager shows custom facets for user defined attributes we need to refresh them
      // as e.g. a change in precision or unit would impact the items in the facet (which are formatted strings).
      // note that recalculateModelFacets also prunes hidden attributes internally, so we don't need
      // to take care of removed attributes here ourselves.
      const facetDefsToUpdate = facetDefs.filter((facetDef) => facetDef.attribute?.isNative());
      if (facetDefsToUpdate.length > 0) {
        await this.facetsManager.updateFacetsAttributes(facetDefsToUpdate, this.getModels());
        needFacetUpdate = true;
      }

      if (needFacetUpdate) {
        this.facetsManager.recalculateModelFacets(null, this.getModels());
      }

      this.app.dispatchEvent({
        type: dte.DT_FACILITY_CHANGED_EVENT,
        facility: this,
        change: { classification: this.settings?.classification, ctype: ChangeTypes.UpdateClassification }
      });
    } else if (change.ctype === ChangeTypes.UpdateTwinSettings) {
      await this.reloadSettings();

      // remove models that aren't linked to the facility anymore
      const linkedUrns = new Set(this.settings.links.map((link) => link.modelId));
      this.modelsList = this.modelsList.filter((m) => linkedUrns.has(m.urn()));
    } else if (
    change.ctype === ChangeTypes.AddDocument ||
    change.ctype === ChangeTypes.DeleteDocument ||
    change.ctype === ChangeTypes.UpdateDocument)
    {
      await this.reloadSettings();

      this.app.dispatchEvent({
        type: dte.DT_DOCUMENTS_CHANGED_EVENT,
        facility: this
      });
    }

    this.app.dispatchEvent({
      type: dte.DT_FACILITY_CHANGED_EVENT,
      facility: this,
      change
    });
  }

  async onModelChanged(model, change) {

    //If instance tree (and information relevant to it) is changing, update
    //the model's internal structures before firing the event
    model.lastChangeTime = Date.now();

    const instanceTree = model.getData()?.instanceTree;
    const nodeAccess = instanceTree?.nodeAccess;
    // Update node access
    // Note we reuse the same rootId and nodeBoxes as they're not supposed to change with a mutation
    if (change.instanceTreeStorage && nodeAccess) {
      instanceTree.nodeAccess = new InstanceTreeAccess(change.instanceTreeStorage, nodeAccess.rootId, nodeAccess.nodeBoxes);
    }

    let needFacetUpdate = false;

    let facetInfo = change.facetInfo;
    if (facetInfo) {
      model.updateFacetInfo(facetInfo);
      needFacetUpdate = true;
    }

    if (BULK_IMPORT_EVENTS.has(change.ctype)) {
      const desc = change.description;
      if (!desc || desc === "otgImport") {
        const mid = change.modelId;
        const m = this.getModelByUrn(mid);
        if (this.modelsInProgress.has(m)) {
          console.warn("Bulk import event received for model that is still loading");
        }
        if (m) {
          m.invalidateModelProperties();
          // if this model is showing, reload it (this is the model update case)
          if (this.models[mid]) {
            this.loadModel(m);
          }
          this.processLoadQueue();
        }
      }
    } else if (change.ctype === DtConstants.ChangeTypes.Mutate) {
      // If a mutation on an attribute managed by Facets manager, update it
      if (change.details?.attributes?.length > 0) {
        const facetDefs = this.facetsManager.getFacetDefs();
        const facetDefsAttributeHashes = facetDefs.map((facetDef) => facetDef.settings.attributeHash).filter((hash) => hash);

        // If any facet definition with attribute hash
        if (facetDefsAttributeHashes.length > 0) {
          // mutations are based on ids and we are storing attribute per hash -> get attribute ids
          // and check if a facet needs to be updated
          const hash2Attr = await model.getHash2Attr();
          const facetDefsAttributeIds = new Set();

          for (const attrHash in hash2Attr) {
            const attr = hash2Attr[attrHash];
            if (attr) {
              facetDefsAttributeIds.add(attr.id);
            }
          }
          for (const qColId of change.details.attributes) {
            if (facetDefsAttributeIds.has(qColId)) {
              // removing the cached facetClassifiers here so the change can actually take effect
              model.facetsClassifiers = null;
              needFacetUpdate = true;
              break;
            }
          }
        }

        // Parameter facet needs to update based on this mutated parameter value.
        if (model.facetsClassifiers &&
        facetDefs.some((f) => f.id === FacetTypes.parameters) &&
        change.details.attributes.some((a) => a.startsWith(DtConstants.ColumnFamilies.DtProperties))) {
          // removing the cached facetClassifiers here so the change can actually take effect
          model.facetsClassifiers = null;
          needFacetUpdate = true;
        }
      }
      if (change.dbIdsRemoved) {
        this.viewer.impl.selector.deselectNodes(change.dbIdsRemoved, model);
      }
    } else
    if (change.ctype === ChangeTypes.StateChange && change.details.state === ModelState.Deleting) {
      this.forgetModel(model);
    }

    if (needFacetUpdate) {
      this.facetsManager.recalculateModelFacets(null, [model], false, false);
    }

    this.app.dispatchEvent({
      type: dte.DT_MODEL_CHANGED_EVENT,
      model: model,
      facility: this,
      change
    });
  }

  setViewer(viewer) {
    if (this.viewer === viewer) {
      return;
    }

    this.viewer = viewer;

    this.facetsManager?.setViewer(viewer);

    //Override our default load options with whatever
    //was given to the specific viewer instance.
    this.loadOptions = { ...this.loadOptions, ...this.viewer.config };

    // register the dt-tool to hook mouse event
    this.dtTool = new DtSelectionTool(this.viewer, { facility: this });

    // Heads-up display integration.
    if (!isNodeJS()) {
      this.hud = new HUD(this.viewer, this);
    }

    if (!isNodeJS() && this.app?.flags?.autoView) {
      this.autoViewMenu = new AutoViewMenu(this);
      this.autoViewMenu.register();
    }
  }

  getWorker(seqNo) {
    return this.app.getWorker(seqNo);
  }

  _initModels() {
    this.modelsList = this.settings.links.map((link) => new DtModel(this, link.modelId, this.loadContext));
  }

  /**
   * Initialize modelsList
   */
  async load() {let needResetFacetsManager = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    if (!this.facetsManager) {
      if (!this.settings) {
        await this.reloadSettings();
      }
      const wantClassificationFacet = this.settings?.template?.getClassificationID() && this.settings.template.getClassificationID() !== DtConstants.UNIFORMAT_UUID || false;
      this.facetsManager = new FacetsManager(this, this.loadContext, wantClassificationFacet);
      this.viewer && this.facetsManager.setViewer(this.viewer);
    }
    if (needResetFacetsManager) {
      this.facetsManager.resetVisibility();
    }
    // initialize models if needed
    if (!this.modelsList) {
      this._initModels();
    }
  }

  async reloadSettings() {
    const { data, etag } = await fetchJsonAndETag(this.loadContext, `/twins/${this.urn()}`);
    this.settings = data;
    this.settingsEtag = etag;
    this.settings.template = new DtFacilityTemplate(this, this.settings.template ?? {});

    return this.settings;
  }

  get thumbnailUrl() {
    return this.loadContext.endpoint + `/twins/${this.urn()}/thumbnail`;
  }

  getThumbnail() {
    return fetchFile(this.loadContext, `/twins/${this.urn()}/thumbnail`);
  }

  /**
   * @return facility classifition. Default to Masterformat
   */
  async getClassificationTemplate() {let forceRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    if (!this.settings) {
      return;
    }

    if (!forceRefresh && this.settings.classification) {
      return this.settings.classification;
    }

    let classification = await fetchJson(this.loadContext, `/twins/${this.urn()}/classification`);
    if (!classification) {
      classification = await DtConstants.getClassification(DtConstants.MASTERFORMAT_UUID);
    }

    this.settings.classification = classification;
    return classification;
  }

  async getUsers() {
    const data = await fetchJson(this.loadContext, `/twins/${this.urn()}/users`);
    return Object.entries(data).map((_ref) => {let [id, details] = _ref;return new DtUser({ id, ...details });});
  }

  async getUser(id) {
    const data = await fetchJson(this.loadContext, `/twins/${this.urn()}/users/${id}`);
    const users = Object.entries(data).map((_ref2) => {let [id, details] = _ref2;return new DtUser({ id, ...details });});
    return users.length === 1 ? users[0] : null;
  }

  async getSubjects() {
    // returns groups and users
    return await fetchJson(this.loadContext, `/twins/${this.urn()}/subjects`);
  }

  /**
   * Returns list of facility models.
   *
   * @param {boolean} [skipDefault] - If true, default model is not included in the list.
   * @returns {DtModel[]} List of models.
   *
   * @alias Autodesk.Tandem.DtFacility#getModels
   */
  getModels() {let skipDefault = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    if (skipDefault) {
      return this.modelsList.filter((m) => !m.isDefault());
    }

    return this.modelsList;
  }

  /**
   * Returns model by its URN.
   *
   * @param {string} urn
   * @returns {DtModel|undefined}
   *
   * @alias Autodesk.Tandem.DtFacility#getModelByUrn
   */
  getModelByUrn(urn) {
    return this.modelsList?.find((m) => m.urn() === urn);
  }

  async asyncDatabaseOperation(opArgs) {

    opArgs = { ...this.loadContext, ...opArgs };

    opArgs.twinId = this.urn();

    opArgs.headers = {
      ...this.loadContext.headers,
      ...opArgs.headers
    };

    return this.getWorker().asyncDatabaseOperation(opArgs);
  }

  async _reloadAttributes() {

    let proms = [];

    for (let model of this.getModels()) {
      proms.push(model.reloadAttributes());
    }

    return Promise.all(proms);
  }

  /**
   * @param {string} realFileName user-defined filename
   */
  getUploadModelLink(_ref3) {let { realFileName } = _ref3;
    const body = { realFileName };
    return fetchJson(this.loadContext, `/twins/${this.urn()}/s3uploadlink`, 'POST', body);
  }

  /**
   * Only required/applicable in the direct-to-s3 case
   * @param {*} linkDetails // return value from getUploadModelLink (with direct)
   */
  confirmUploadModel(linkDetails) {
    return fetchJson(this.loadContext, `/twins/${this.urn()}/confirmupload`, 'POST', linkDetails);
  }


  /**
   * @typedef {Object} ModelParams
   * @param {string} fileName
   * @param {string} label
   * @param {boolean} isVisibleByDefault
   * @param {boolean} isPrimaryModel
   * @param {string} urn
   * @param {string} docsProjectId
   * @param {string} docsAccountId
   * @param {string} phaseOrViewName
   */

  /**
   * @param {ModelParams[]} modelsParams
   * @returns {Array<DtModel|DtHttpError>} models and possible error if operation partially failed
   *
   * @ignore
   */
  async createModels(modelsParams) {
    if (modelsParams.length === 0) {
      return [];
    }

    const linkDefs = [];
    for (const params of modelsParams) {
      linkDefs.push({
        label: params.label || 'default',
        on: params.isVisibleByDefault
      });
    }

    const primaryModelExists = this.getModels().length > 0 && Boolean(this.getPrimaryModel());

    const correlationId = modelsParams.length > 1 ? v4() : undefined;

    const doImportForModel = async (params, i) => {
      try {
        const createdModel = await fetchJson(this.loadContext, `/twins/${this.urn()}/model`, 'POST', {
          realFilename: params.fileName,
          docsProjectId: params.docsProjectId,
          docsAccountId: params.docsAccountId,
          linkDef: linkDefs[i],
          urn: params.urn
        });

        const newModel = new DtModel(this, createdModel.modelId);

        newModel.subscribeToEvents();

        if (params.alignment)
        await newModel.saveAlignment(params.alignment);

        // trigger import
        await newModel.updateFromFile(params.fileName, params.urn, params.phaseOrViewName, correlationId);

        return newModel;
      } catch (err) {
        if (err instanceof DtHttpError) {
          err.setErrorCtx({ fileName: params.fileName });
          return err;
        } else {
          console.error("Unknown error on model import", err);
          throw err;
        }
      }
    };

    const modelsRes = await Promise.all(modelsParams.map(doImportForModel));

    // success
    await this.reloadSettings();

    // we track the URNs of successfully imported models to set the first one out of that list as a main model
    let successfullyImportedModels = [];

    for (let i = 0; i < modelsRes.length; i++) {
      const newModel = modelsRes[i];

      if (!(newModel instanceof DtModel)) {
        // model has failed to import
        continue;
      }

      successfullyImportedModels.push(newModel.urn());

      this.modelsList.push(newModel);
      this.app.dispatchEvent({
        type: dte.DT_MODEL_METADATA_CHANGED_EVENT,
        change: { model: newModel, type: 'create' }
      });
      const params = modelsParams[i];

      // schedule for loading when import is done
      this.loadModel(newModel, params.isVisibleByDefault ? LoadPriority.Visible : LoadPriority.Hidden);
    }

    // set main model
    if (!primaryModelExists) {
      if (successfullyImportedModels.length > 0) {
        const firstSuccessfullyImportedModel = this.getModelByUrn(successfullyImportedModels[0]);
        await this.makePrimary(firstSuccessfullyImportedModel);
      } else {
        console.error("All selected models failed to import");
      }
    }

    return modelsRes;
  }

  /**
   * @typedef {Object} AccDocumentCreationRequest
   * @param {string} name // file name
   * @param {string} accProjectId // project guid in ACC
   * @param {string} accAccountId // account guid in ACC
   * @param {string} accLineage // lineage of the document
   * @param {string} accVersion // decoded version string of the document
   */

  /**
   * @typedef {AccDocumentCreationRequest | Object} AccDocumentResponse
   * @param {string} id // tandem-style resource urn
   * @param {string} contentType // file type
   * @param {string} signedLink // s3 signed link, suitable for direct in-browser document viewing (PDF for now)
   *
   */

  /**
   * @param {AccDocumentCreationRequest[]} documentsList
   * @returns {Promise<AccDocumentResponse[]>} a list of tandem doc which had been uploaded successfully
   *
   * @ignore
   */
  async createAccDocuments(documentsList) {
    const res = await fetchJson(this.loadContext, `/twins/${this.urn()}/documents`, 'POST', documentsList);

    await this.reloadSettings();

    this.app.dispatchEvent({
      type: dte.DT_DOCUMENTS_CHANGED_EVENT,
      facility: this,
      docs: res
    });

    return res;
  }

  /**
   * @typedef {Object} TandemHostedDocumentResponse
   * @param {string} id // tandem-style resource urn
   * @param {string} contentType // file type
   * @param {string} name // fileName
   * @param {string} s3Path // path to the file in s3
   * @param {string} signedLink // s3 signed link, suitable for direct in-browser document viewing (PDF for now)
   *
   */

  /**
   * @param {string} filename
   * @param {Blob} data
   * @returns {Promise<TandemHostedDocumentResponse>} // note that response will not contain viewble links, and additional GET document will be required to get those
   *
   * @ignore
   */
  async createTandemHostedDocument(filename, file) {
    // get upload link
    let res = await fetchJson(this.loadContext, `/twins/${this.urn()}/documents/upload`, 'POST', {
      name: filename,
      contentType: file.type,
      contentLength: file.size
    });

    // upload document
    const options = {
      method: 'PUT',
      headers: { ...Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS, 'content-type': file.type, 'content-length': file.size },
      body: file
    };

    const s3Res = await fetch(res.uploadLink, options);
    if (s3Res.status !== 200) {
      this.deleteDocument(res.id);
      return Promise.reject({ error: {}, msg: "failed to upload document to s3" });
    }

    // confirm upload
    res = await fetchJson(this.loadContext, `/twins/${this.urn()}/documents/confirms3upload`, 'POST', {
      "docUrn": res.id
    });

    await this.reloadSettings();

    this.app.dispatchEvent({
      type: dte.DT_DOCUMENTS_CHANGED_EVENT,
      facility: this,
      newDocument: res
    });

    return res;
  }

  /**
   * @param {string} documentId
   *
   * @ignore
   */
  async deleteDocument(documentId) {
    await fetchJson(this.loadContext, `/twins/${this.urn()}/documents/${documentId}`, 'DELETE');

    await this.reloadSettings();

    this.app.dispatchEvent({
      type: dte.DT_DOCUMENTS_CHANGED_EVENT,
      facility: this,
      deletedDocumentId: documentId
    });

  }

  /**
   * @param {string} documentId
   * @returns {Promise<AccDocumentResponse>}
   *
   * @ignore
   */
  async getDocument(documentId) {
    return await fetchJson(this.loadContext, `/twins/${this.urn()}/documents/${documentId}`, 'GET');
  }


  /**
   * @typedef {Object} AccDocumentUpdateRequest
   * @param {string} name // file name
   * @param {string} accVersion // decoded version string of the document
   */
  /**
   * @param {string} documentId
   * @param {AccDocumentUpdateRequest} document
   * @returns {Promise<AccDocumentResponse>} // note that response will not contain viewble links, and additional GET document will be required to get those
   *
   * @ignore
   */
  async updateDocument(documentId, document) {
    const res = await fetchJson(this.loadContext, `/twins/${this.urn()}/documents/${documentId}`, 'PATCH', document);

    this.settings.docs = this.settings.docs.map((doc) => doc.id == documentId ? res : doc);

    this.app.dispatchEvent({
      type: dte.DT_DOCUMENTS_CHANGED_EVENT,
      facility: this,
      updateDocumentId: documentId
    });

    return res;
  }

  async updateModel(model, filename, urn, phaseOrViewName) {
    // trigger import
    await model.updateFromFile(filename, urn, phaseOrViewName);

    await this.reloadSettings();

    this.app.dispatchEvent({
      type: dte.DT_MODEL_METADATA_CHANGED_EVENT,
      change: { model }
    });
  }

  async deleteModel(model) {
    await fetchJson(this.loadContext, `/twins/${this.urn()}/model/${model.urn()}`, 'DELETE');

    // synthetic event as sent by the model server to concurrent clients
    this.app.dispatchEvent({
      type: dte.DT_MODEL_CHANGED_EVENT,
      model,
      facility: this,
      change: { modelId: model.urn(), ctype: ChangeTypes.StateChange, details: { state: ModelState.Deleting } }
    });

    // TODO this legacy event can probably be removed
    this.app.dispatchEvent({
      type: dte.DT_MODEL_METADATA_CHANGED_EVENT,
      change: { model: model, type: 'delete' }
    });

    this.forgetModel(model);
  }

  async updateSettings(data) {

    //The FacilityTemplate does not need to be sent up when updating the settings
    //TODO: store it outside the settings.
    data.template = undefined;

    const headers = { etag: this.settingsEtag };
    await fetchJson(this.loadContext, `/twins/${this.twinId}`, 'PUT', data, headers);
    // update settings
    await this.reloadSettings();
  }

  getPrimaryModel() {
    let main = this.settings.links.find((link) => link.main);
    return main && this.getModelByUrn(main.modelId);
  }

  async makePrimary(model) {
    const currentPrimary = this.getPrimaryModel();
    if (currentPrimary !== model) {

      const links = this.settings.links.map((link) => ({
        ...link,
        main: Boolean(link.modelId === model.urn())
      }));
      await this.updateSettings({
        ...this.settings,
        links
      });

      this.app.dispatchEvent({
        type: dte.DT_MODEL_METADATA_CHANGED_EVENT,
        change: { model }
      });

      if (currentPrimary) {
        this.app.dispatchEvent({
          type: dte.DT_MODEL_METADATA_CHANGED_EVENT,
          change: { model: currentPrimary }
        });
      }
    }

  }

  getLinkForModel(model) {
    return this.settings.links.find((l) => l.modelId === model.urn());
  }

  async updateLinkForModel(model, link) {
    await this.updateSettings({
      ...this.settings,
      links: this.settings.links.map((l) => l.modelId === model.urn() ? link : l)
    });

    this.facetsManager.recalculateModelFacets(null, [model]);

    this.app.dispatchEvent({
      type: dte.DT_MODEL_METADATA_CHANGED_EVENT,
      change: { model }
    });
  }

  updateThumbnail(file) {
    return fetchFile(this.loadContext, `/twins/${this.twinId}/thumbnail`, 'POST', file);
  }

  async updateClassificationTemplate(classification) {
    if (!this.settings) {
      return;
    }

    // Masterformat is not "stored" in facility metadata, it's the default value
    const newClassification = classification.uuid === "masterformat" ? null : classification;
    await fetchJson(this.loadContext, `/twins/${this.urn()}/classification`, 'PUT', newClassification);

    this.settings.classification = newClassification;
    return newClassification;
  }

  async setUserAccess(userID, accessLevel) {
    await fetchJson(this.loadContext, `/twins/${this.urn()}/users/${userID}`, "PUT", { accessLevel });
    this.app.dispatchEvent({ type: dte.DT_FACILITY_USER_CHANGED_EVENT, facility: this, userID });
  }

  // name is optional
  async setGroupAccess(groupID, accessLevel, name) {
    return await fetchJson(this.loadContext,
    `/twins/${this.urn()}/groups/${groupID}`,
    'PUT',
    { accessLevel, name }
    );
  }

  _getAccessLevel() {
    return this.settings?.accessLevel;
  }

  /**
   * get properties for given models / dbids and intersect them
   * @param {DtModel[]} models
   * @param {number[][]} dbIds models indexed dbIds
   * @example
   * getCommonProperties(m, [[1,2,3]])
   */
  async getCommonProperties(models, dbIds) {
    if (models.length !== dbIds.length) {
      console.error('Malformed inputs: models and dbIds sizes mismatch');
      return;
    }

    const modelsProps = await Promise.all(
      models.map((m, i) => {
        // single dbid
        if (dbIds[i].length === 1) {
          // Get the history only for single element selection
          return m.getPropertiesDt(dbIds[i][0], {
            history: dbIds.length === 1,
            classificationId: this.settings.template.getClassificationID(),
            wantTimeSeries: m.isDefault()
          });
        }
        // intersect dbids across the same model
        return m.getPropertiesDt(dbIds[i], {
          intersect: true,
          history: false,
          classificationId: this.settings.template.getClassificationID(),
          wantTimeSeries: m.isDefault()
        });
      })
    );

    // intersect across models
    if (modelsProps.length > 1) {
      const res = intersectPropertiesDt(modelsProps, 'hash');
      return res;
    }
    return modelsProps[0];
  }

  loadModels() {let visibleModelsForView = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

    //Note that the subscribe function does not know that twinId and/or modelId is
    //available in the loadContext. It wants the URN given explicitly.
    this.app.subscribeToEvents(this);

    //Used to react to changes in the ghosting preference that happen when
    //the facility is already partially loaded, and we need to load more
    //of the primary model for ghosting
    this.ghostingListener = (e) => {
      if (e.name === Prefs3D.GHOSTING && e.value === true) {
        for (let model of this.viewer.getVisibleModels()) {
          if (model.isPrimaryModel()) {
            model.loadAllElements();
          }
        }
      }
    };
    this.viewer.addEventListener(et.PREF_CHANGED_EVENT, this.ghostingListener);

    // unit test fails due to the null this.viewer.toolController, so add the null check here.
    if (this.viewer.toolController) {
      this.viewer.toolController.registerTool(this.dtTool);
      this.viewer.toolController.activateTool(this.dtTool.getName());
      this.viewer.toolController.registerTool(this.hud);
      this.viewer.toolController.activateTool(this.hud.getName());
    }

    let models = this.getModels();

    this.visibleModelsForView = visibleModelsForView;
    const isRestoringView = !!this.visibleModelsForView;

    if (models.length === 0) {
      return;
    }

    //load the root model first (plus some sanity check)
    const primaryModels = models.filter((model) => model.isPrimaryModel());
    if (primaryModels.length === 1) {
      this.loadModel(primaryModels[0], LoadPriority.Primary, true);
    } else {
      if (primaryModels.length === 0) {
        console.error("Main model not found. Stopping model load.");
      } else {
        console.error("More than one main model found. Stopping model load.");
      }
      return;
    }
    //load the models that are initially visible
    for (let i = 0; i < models.length; i++) {
      const visible = isRestoringView ? this.visibleModelsForView.has(models[i].urn()) : models[i].isVisibleByDefault();
      const performLoad = !models[i].isPrimaryModel() && visible;
      performLoad && this.loadModel(models[i], LoadPriority.Visible, true);
    }
    //load models that are off by default
    for (let i = 0; i < models.length; i++) {
      const visible = isRestoringView ? this.visibleModelsForView.has(models[i].urn()) : models[i].isVisibleByDefault();
      const performLoad = !models[i].isPrimaryModel() && !visible;
      performLoad && this.loadModel(models[i], LoadPriority.Hidden, true);
    }

    this.processLoadQueue();

    return this.waitForAllModels();
  }

  loadModel(model) {let priority = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : LoadPriority.Visible;let skipQueue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    if (!this.viewer) {
      console.error("Missing viewer instance.");
      return;
    }

    if (!this.loadQueue.includes(model)) {
      model.initLoadPromise();
      model.loadPriority = priority;
      this.loadQueue.push(model);
    }

    if (skipQueue) {
      return;
    }

    this.processLoadQueue();
  }

  async processLoadQueue() {
    if (!this.isProcessing) {
      this.isProcessing = true;
      try {
        await this.processLoadQueueImpl();
      } catch (e) {
        console.error("Loading failed...", e);
      } finally {
        this.isProcessing = false;
      }
    }
    return this.isProcessing;
  }

  /**
   * Waits for all models to be loaded.
   *
   * @returns {Promise}
   *
   * @alias Autodesk.Tandem.DtFacility#waitForAllModels
   */
  async waitForAllModels() {
    return new Promise((resolve) => {
      this.waitForAllLoadCallbacks.push(resolve);
      this.processLoadQueue();
    });
  }

  getModelLoadOptions(model, visibleModelsForView) {

    const isRestoringView = !!visibleModelsForView;
    const visible = isRestoringView ? visibleModelsForView.has(model.urn()) : model.isVisibleByDefault();

    const loadOptions = { ...this.loadOptions };
    loadOptions.modelUrn = model.urn();
    loadOptions.modelObj = model;

    const ghostingEnabled = this.viewer.prefs.get(Prefs3D.GHOSTING);

    // geometry loading can be delayed if a model is not visible or partially visible due to a stored view
    //TODO: We can delay the primary model as well, once we have a reasonable Loading screen for stored views
    const isPartiallyVisible = isRestoringView && !(model.isPrimaryModel() && ghostingEnabled);

    //This setting delays loading of geometry when a model is visible (not the same as loadAsHidden flag)
    loadOptions.delayGeomLoad = !visible || isPartiallyVisible || model.isDefault();

    if (this.globalOffset) {
      loadOptions.keepCurrentModels = true;
      loadOptions.preserveView = true;
      loadOptions.loadAsHidden = !visible;
      loadOptions.skipPrefs = true;

      //These settings will make sure the loaded model gets transformed
      //into Revit shared coordinates and then transformed from shared
      //coordinates into the native coordinates of the main model.
      //This way we get reasonable Front/Right vectors in the view cube
      //that are not necessarily aligned with the North compass needle, but
      //are usually more aligned to what makes sense for the building itself.
      loadOptions.globalOffset = this.globalOffset;
      // Makes other models conform to the main model's distance unit
      if (this.mainModelDistanceUnit)
      loadOptions.applyScaling = { to: this.mainModelDistanceUnit };
      loadOptions.placementTransform = this.toMainModelNativeSpace.clone();

      const alignment = model.modelProps?.dataSource?.alignment;
      if (alignment) {
        loadOptions.overrideRefPointTransform = new LmvMatrix4(true).fromArray(alignment.transform);
      }
      loadOptions.applyRefPoint = true;
    } else {
      //Main model -- do not apply any transformation to its placement,
      //we will put all other models in the Revit native space of the main model
      loadOptions.globalOffset = undefined;
      loadOptions.placementTransform = null;
      loadOptions.applyRefPoint = false;

      //The primary model is an exception to loading as hidden.
      //We can't load it as hidden because then the initial viewpoint will not be set correctly,
      //which will screw up the world up vector (and probably other stuff).
      //See the logic in Viewer3D.loadModel which then calls addModel() which then goes into
      //Viewer3D.initializeFirstModelPresets and calls setViewFromCamera.
      //A more nuanced approach should be possible in cases when the main model is not initially visible
      //where we load just the model metadata to initialize the view, but this case should be rare enough
      //that is not a huge issue.
      //The below facetManager.addModel will then turn it off eventually
      //(but that is only shortly before the other models start showing up).
      loadOptions.loadAsHidden = false; // visible ? false : ghostingEnabled;
    }

    //Not the same as loadAsHidden for the primary model, because of the check for ghosting right above
    //so we store this flag separately.
    loadOptions.visibleInFilters = visible;

    // we need to pass query params to the model loader because it might include `until` parameter, which must be inluded in all queries to deliver a consistent model
    // it must be done after call to get model properties was completed and processed
    loadOptions.queryParams = loadOptions.queryParams ? loadOptions.queryParams + "&" + model.getQueryParams() : model.getQueryParams();

    return loadOptions;
  }

  async processLoadQueueImpl() {

    let loadPromises = [];
    let skippedModels = [];
    const MAX_PARALLEL = 50;

    let lastPriority = -1;
    while (this.loadQueue.length > 0) {

      this.loadQueue.sort((a, b) => a.loadPriority - b.loadPriority);

      const model = this.loadQueue[0];

      const priority = model.loadPriority;
      model.loadPriority = undefined;

      //We wait for models with higher priority to load, before
      //kicking off load of models with the next (lower) priority
      if (priority !== lastPriority || loadPromises.length >= MAX_PARALLEL) {
        if (loadPromises.length) {
          await Promise.all(loadPromises);
          loadPromises = [];
        }
        lastPriority = priority;
      }

      const modelUrn = model.urn();

      if (this.models[modelUrn]) {
        // reloading request, unload first
        delete this.models[model.urn()];
        this.facetsManager.removeModel(model);
        this.viewer.unloadModel(model);

        if (Object.keys(this.models).length === 0) {
          this.globalOffset = undefined;
        }
      }

      const props = await model.getModelProperties();

      // the model queue has changed in the meantime -> start over
      if (this.loadQueue[0] !== model) {
        continue;
      }

      if (props.state.state === ModelState.Deleting) {
        console.info("Skipping model queued for deletion.", model);
        this.loadQueue.shift();
        continue;
      } else
      if (!props.dataSource.forgeUrn) {
        if (props.state.state === ModelState.Failed) {
          model.settleLoadPromise(false, 'model failed to import');
          console.error("Import failed for model. Skipping load.", model);
          this.loadQueue.shift();
          continue;
        }

        if (priority === LoadPriority.Primary) {
          console.info("Import pending: pausing model load.");
          return;
        } else {
          this.loadQueue.shift();
          skippedModels.push(model);
          continue;
        }
      }

      this.loadQueue.shift();
      this.modelsInProgress.add(model);

      model.subscribeToEvents();

      const loadOptions = this.getModelLoadOptions(model, this.visibleModelsForView);

      if (priority === LoadPriority.Primary && loadOptions.delayGeomLoad) {
        this.viewer._loadingSpinner?.show();
      }

      let modelRootUrl = endpoint.getApiEndpoint() + "/modeldata/" + modelUrn + "/model";
      let prom = this.viewer.loadDocumentNode(modelRootUrl, loadOptions);

      prom = prom.then(async () => {
        const modelData = model.getData();
        if (!this.globalOffset && modelData) {
          this.setupTransforms(model);
        }

        this.modelsInProgress.delete(model);

        if (this.modelsToAbort.has(model)) {
          // loading was aborted
          model.settleLoadPromise(false, 'model loading aborted');

          this.viewer.unloadModel(model);
          this.modelsToAbort.delete(model);
          return;
        }

        model.settleLoadPromise(true);

        if (model.isPrimaryModel()) {

          this.viewer._loadingSpinner?.hide();

          this.app.dispatchEvent({
            model,
            type: dte.DT_PRIMARY_MODEL_LOADED
          });
        }

        return this.facetsManager.addModel(model, loadOptions.visibleInFilters);
      }).catch((e) => {
        model.settleLoadPromise(false, 'model loading failed: ' + e.toString());
        throw e;
      });

      loadPromises.push(prom);

      this.models[modelUrn] = model;
    }

    await Promise.all(loadPromises);

    // notify everyone waiting for the queue to drain.
    // Note: here we intentionally do not wait for models
    // that are pending import.
    for (let cb of this.waitForAllLoadCallbacks) {
      cb();
    }
    this.waitForAllLoadCallbacks = [];

    this.loadQueue = skippedModels;
  }

  unloadModels() {
    for (let model of this.modelsList) {
      this.unloadModel(model, true);
    }

    if (this.facetsManager.clusteredFacetId) {
      this.facetsManager.clearClusters(false, false, true);
    }

    this.facetsManager.updateFacets();
    this.facetsManager.facetsEffects.removeSelectionEventListener();
    this.streamMgr?.dispose();
    this.systemsManager.dispose();
    this.app.unsubscribeFromEvents(this);

    this.ghostingListener && this.viewer?.removeEventListener(et.PREF_CHANGED_EVENT, this.ghostingListener);
    this.ghostingListener = null;

    this.viewer.getExtension('Autodesk.Section')?.deactivate();

    if (this.viewer.toolController) {
      this.viewer.toolController.deregisterTool(this.dtTool);
      this.viewer.toolController.deregisterTool(this.hud);
    }

    this.autoViewMenu?.unregister();
  }

  // TODO: this method should not exists, but reality is harsh.
  // Ideally we would be able to load and unload models as we see fit. The loading queue
  // actually is testament of the fact that at least initially a model can be found in modelsList
  // *and* being not (yet) loaded. Also a failed initial import results in a model that can't be loaded.
  // However a good portion of the code base assumes a model to be loaded and interacts with it
  // without any further checks.
  forgetModel(model) {
    this.unloadModel(model);
    // keepModelInList can be toggled via feature flag to weed out the above issues over time
    if (this.loadContext.keepModelInList) {
      return;
    }
    this.modelsList = this.modelsList.filter((m) => m !== model);
  }

  unloadModel(model, skipFacetUpdate) {
    // does nothing if the promise already settled (or loading never started)
    model.settleLoadPromise(false, 'model unloaded');

    const queueIdx = this.loadQueue.indexOf(model);
    if (queueIdx != -1) {
      this.loadQueue.splice(queueIdx, 1);
    }

    if (this.modelsInProgress.has(model)) {
      this.modelsToAbort.add(model);
    }

    const urn = model.urn();
    if (this.models[urn]) {
      delete this.models[urn];
      this.facetsManager.removeModel(model, skipFacetUpdate);

      if (this.viewer) {
        this.viewer.unloadModel(model);
      }

      if (Object.keys(this.models).length === 0) {
        this.globalOffset = undefined;
      }
    }
  }

  isModelVisible(model) {
    if (!this.viewer) {
      console.error("Missing viewer instance.");
      return;
    }

    return this.viewer.impl.modelVisible(model.id);
  }

  showModel(model) {
    if (!this.viewer) {
      console.error("Can't show model without a viewer.");
      return;
    }

    this.viewer.showModel(model);
  }

  hideModel(model) {
    if (!this.viewer) {
      console.error("Can't hide model without a viewer.");
      return;
    }

    this.viewer.hideModel(model);
  }

  async excelImport(importData) {

    const allPossibleColumns = Object.keys(importData[0]);

    if (!importData.length) {
      return Promise.reject({ error: {}, msg: "import data is empty" });
    }

    // Break rows down by model id
    const rowsToImport = importData.slice(1).reduce((acc, curr) => {
      (acc[curr["DtModelId"]] = acc[curr["DtModelId"]] || []).push(curr);
      return acc;
    }, {});

    const correlationId = Object.keys(rowsToImport).length > 1 ? v4() : undefined;
    let proms = [];

    for (let modelId in rowsToImport) {
      let model = this.getModelByUrn(modelId);
      let event = this.app.waitForEvent(dte.DT_MODEL_CHANGED_EVENT, {
        modelId: model.urn(),
        ctype: DtConstants.ChangeTypes.Mutate,
        isOwn: true
      });
      let perModelMutation = model.asyncPropertyOperation({
        operation: "EXCEL_IMPORT",
        rowsToImport: rowsToImport[modelId],
        allPossibleColumns,
        correlationId
      });
      proms.push(event);
      proms.push(perModelMutation);
    }

    const results = await Promise.all(proms);
    return results.filter((_, idx) => idx % 2 === 1);
  }

  mutate(dbIdsByUrn, mutsByUrn, desc) {
    if (typeof dbIdsByUrn !== "object" || typeof mutsByUrn !== "object") {
      console.error("Invalid change object(s):", dbIdsByUrn, mutsByUrn);
      return;
    }

    const modelUrns = Object.keys(dbIdsByUrn);
    const correlationId = modelUrns.length > 1 ? v4() : undefined;
    const promiseByUrn = {};

    for (const model of this.modelsList) {
      const mUrn = model.urn();
      const dbIds = dbIdsByUrn[mUrn];
      const muts = mutsByUrn[mUrn];

      if (!dbIds || !muts) continue;

      promiseByUrn[mUrn] = model.mutate(dbIds, muts, desc, undefined, undefined, correlationId);
    }

    return promiseByUrn;
  }

  /**
   * @typedef {Object} MutationDataEntry
   * @param {number} d (QC.LmvDbId/dbId) - Required
   * @param {*} qCol Any number of key value pairs corresponding to qualified column name and value
   */

  /**
   *
   * @param {{ string: MutationDataEntry[] }} dataByModelUrn
   * @param {*} desc context the update was made in
   * @returns {{ modelUrn: Promise<{ serverResponse: *, numUpdated: number }> }}}
   */
  updateElementsWithData(dataByModelUrn, desc) {
    if (typeof dataByModelUrn !== "object") {
      console.error("Invalid change object:", dataByModelUrn);
      return;
    }

    const modelUrns = Object.keys(dataByModelUrn);
    const correlationId = modelUrns.length > 1 ? v4() : undefined;
    const promiseByUrn = {};

    for (const model of this.modelsList) {
      const mUrn = model.urn();
      const elementsData = dataByModelUrn[mUrn];

      if (!elementsData) continue;

      promiseByUrn[mUrn] = model.updateElementsWithData(elementsData, desc, correlationId);
    }

    return promiseByUrn;
  }

  /**
   * Undo change(s), see DtModel.undoChange for more details
   *
   * @param {object} undoByUrn - list of dbIds to revert, along with the associated timestamp. Grouped by model urn
   * @param {boolean} dryRun - if true, the operation will not be executed, but the result will be returned
   * @returns {{ modelUrn: Promise }} return promises for the result(s) of the undo operation(s)
   *
   * @alias Autodesk.Viewing.Model#undoChange
   */
  undoChange(undoByUrn) {let dryRun = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;let allowNonLatest = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    if (typeof undoByUrn !== "object") {
      console.error("Invalid request object:", undoByUrn);
      return;
    }

    const modelUrns = Object.keys(undoByUrn);
    const correlationId = modelUrns.length > 1 ? v4() : undefined;
    const promiseByUrn = {};

    for (const model of this.modelsList) {
      const mUrn = model.urn();
      const params = undoByUrn[mUrn];

      if (!params || !params.dbIds || !params.timestamp) continue;

      promiseByUrn[mUrn] = model.undoChange(params.dbIds, params.timestamp, dryRun, allowNonLatest, correlationId);
    }

    return promiseByUrn;
  }

  async loadUsageMetrics(fromCache) {
    if (fromCache) {
      if (!this._cachedTwinMetrics) {
        await fetchJson(this.loadContext, "/admin/stats/twins/metrics/" + this.urn()).then((data) => {
          this._cachedTwinMetrics = data;
        });
      }
      return Promise.resolve(this._cachedTwinMetrics);
    }

    let resPromise = fetchJson(this.loadContext, "/admin/stats/twins/metrics/" + this.urn());

    resPromise.then((data) => this._cachedTwinMetrics = data);

    return resPromise;
  }

  async triggerAssetCleanup() {
    this.app.subscribeToEvents(this);

    return fetchJson(this.loadContext, `/twins/${this.urn()}/cleanup`, "POST").
    catch((e) => {
      this.app.unsubscribeFromEvents(this);
      throw e;
    });
  }

  setHiddenElementsForView(hiddenElements) {
    this.facetsManager.partialFacets = {};

    hiddenElements.forEach(async (e) => {
      const model = this.getModelByUrn(e.modelUrn);
      if (!model) {
        console.warn("Model referenced in view not found", e.modelUrn);
        return;
      }
      await model.waitForLoad(false, true);
      const dbIds = await model.getDbIdsFromElementIds(e.ids);
      this.facetsManager.setHiddenElementsForView({ model: model, ids: dbIds });
    });
  }

  getHiddenElementsByModel() {
    const hiddenByModel = this.facetsManager.getHiddenElementsByModel();

    let result = [];

    const models = Object.keys(hiddenByModel);
    models.forEach((m) => {
      result.push({ model: this.getModelByUrn(m), ids: hiddenByModel[m] });
    });

    return result;
  }

  getVisibleElementsByURN() {let skipDefault = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    const dbIdsByURN = {};

    for (const model of this.getModels(skipDefault)) {
      const urn = model.urn();

      if (model.selector && model.getInstanceTree()) {
        dbIdsByURN[urn] = [...new Set(model.getVisibleDbIds())];
      }
    }

    return dbIdsByURN;
  }

  /**
   * Selects all currently visible elements (e.g. elements that match the currently active filter.
   */
  selectAllVisibleElements() {

    const selection = [];

    for (const model of this.modelsList) {
      if (model.selector && model.getInstanceTree()) {
        selection.push({ model, ids: model.getVisibleDbIds() });
      }
    }

    this.viewer.impl.selector.setAggregateSelection(selection);

  }

  async getHistory(timestamps, min, max, includeChanges, limit) {
    const body = { timestamps, min, max, includeChanges, limit };

    return fetchJson(this.loadContext, `/twins/${this.urn()}/history`, 'POST', body);
  }

  deactivateExtensions() {
    let ext = this.viewer.getExtension('Autodesk.Edit2D');
    if (ext?.isActive()) {
      ext.deactivate();
    }
  }

  // constructs a url to the content of a given document that can be used for download or display.
  // add the lastupdated query param as a cache buster.
  getDocumentContentUrl(doc) {
    return this.loadContext.endpoint + `/twins/${this.urn()}/documents/${doc.id}/content?lastupdated=${
    new Date(doc.lastUpdated).getTime() / 1e3
    }`;
  }


  setupTransforms(mainModel) {

    let modelData = mainModel.getData();

    //Set the floating point precision fix offset based on the bbox
    //of the main model.
    this.globalOffset = modelData.globalOffset;

    // Since other models are placed in the main model's space, we need to know
    // what distance unit it is using.
    if (modelData.metadata?.['distance unit']?.value)
    this.mainModelDistanceUnit = modelData.metadata['distance unit'].value;

    //Remember the inverse of the Revit model -> shared coordinates,
    //so we can use it to load all subsequent models
    this.toRevitSharedCoordinates = modelData.refPointTransform || new LmvMatrix4(true);

    // If an "alignment" is found for the primary model, the alignment transform matrix overrides the
    // model's refPointTransform for the purpose of setting the world's native space.
    const alignment = mainModel.modelProps?.dataSource?.alignment;

    this.toMainModelNativeSpace = (alignment ?
    new LmvMatrix4(true).fromArray(alignment.transform) :
    this.toRevitSharedCoordinates).clone().invert();
  }

  getSharedToLocalSpaceTransform(useV1Offset) {
    // Transforms a given point from Revit shared coordinates (minus global offset relative to those for older versions) to
    // Revit model local coordinates (minus runtime global offset). This is used to convert
    // cameras stored in views (which were stored in shared coordinates minus global offset for v1 views)
    // back to usable cameras for runtime (which now uses model local coordinates (minus global offset relative to those).
    // [subtract runtime global offset] * [Revit shared coords to model native transform] * [optional add shared coords global offset]
    let addSharedOffset = new LmvMatrix4(true);
    if (useV1Offset) {
      let go = this.globalOffset.clone().applyMatrix4(this.toRevitSharedCoordinates);
      addSharedOffset.makeTranslation(go.x, go.y, go.z);
    }

    return new LmvMatrix4(true).
    makeTranslation(-this.globalOffset.x, -this.globalOffset.y, -this.globalOffset.z).
    multiply(this.toRevitSharedCoordinates.clone().invert().
    multiply(addSharedOffset));
  }

  getLocalToSharedSpaceTransform(useV1Offset) {
    // Inverse of getSharedToLocalSpaceTransform()
    // [optional subtract shared coords global offset] * [Revit native to shared coords transform] * [add global offset of Revit native coords]
    return this.getSharedToLocalSpaceTransform(useV1Offset).invert();
  }

  getDefaultModelId() {
    return DtConstants.DT_MODEL_URN_PREFIX + this.urn().slice(DtConstants.DT_TWIN_URN_PREFIX.length);
  }

  isSampleFacility() {
    // sample facilities use the same (16-char) ID as the account they belong to
    // hence we can simply strip the urn prefix of group and twin to find a match.
    const accountId = this.settings.accountGroup.slice(DtConstants.DT_GROUP_URN_PREFIX.length);
    const twinId = this.urn().slice(DtConstants.DT_TWIN_URN_PREFIX.length);
    return twinId == accountId;
  }

  async createDefaultModel() {
    let worldBBox = {
      minXYZ: null,
      maxXYZ: null
    };

    let distanceUnit = {
      value: "foot"
    };

    let customValues = {
      refPointTransform: null,
      angleToTrueNorth: 0.0
    };

    let georeference = {
      positionLL84: null,
      refPointLMV: null
    };

    const data = this.getPrimaryModel()?.getData();
    if (data) {
      worldBBox = data.metadata["world bounding box"];
      distanceUnit = data.metadata["distance unit"];
      customValues = data.metadata["custom values"];
      georeference = data.metadata.georeference;
    }

    const modelRootData = {
      version: 1,
      stats: {
        num_fragments: 0,
        num_geoms: 0,
        num_materials: 0,
        num_polys: 0,
        num_textures: 0
      },
      "world bounding box": worldBBox,
      "distance unit": distanceUnit,
      "custom values": customValues,
      georeference: georeference
    };

    await fetchJson(this.loadContext, `/twins/${this.urn()}/defaultmodel`, "POST", modelRootData);
    await this.reloadSettings();

    const model = new DtModel(this, this.getDefaultModelId());
    this.modelsList.push(model);

    this.loadModel(model);
    await model.waitForLoad();

    this.app.dispatchEvent({
      type: dte.DT_MODEL_METADATA_CHANGED_EVENT,
      change: { model: model, type: 'create' }
    });

    return model;
  }

  getDefaultModel() {
    const defaultModel = this.getModels().find((m) => m.isDefault());
    return defaultModel;
  }

  getStreamManager() {
    return this.streamMgr;
  }

  // DEPRECATED: call StreamManager directly
  // create a logical stream element and link it to some physical asset - link information has to be provided all the time as we do not support creation free floating logical stream elements.
  async createStream(name, modelToLinkTo, dbIdToLinkTo) {
    return this.streamMgr.createStream(name, modelToLinkTo, dbIdToLinkTo);
  }

  //Sets the "hidden" flag on the models, which makes them temporarily skipped by the renderer without having to modify
  //each fragment individually.
  //Used when displaying things in floor plan mode, where the graphics are 2D but 3D hit testing is still used.
  hide3D() {
    let models = this.getModels();

    for (let model of models) {

      if (!this.isModelVisible(model)) {
        continue;
      }

      model.hidden = true;
    }
  }

  show3D() {
    let models = this.getModels();

    for (let model of models) {

      if (!this.isModelVisible(model)) {
        continue;
      }

      model.hidden = false;
    }
  }

  async setTwinClassificationSource(classificationSourceAttr) {let skipMapAttrTask = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const body = classificationSourceAttr;
    await fetchFile(this.loadContext, `/twins/${this.urn()}/map-classification-from-source${skipMapAttrTask ? '?skipTask' : ''}`, 'PUT', body);
  }


  getElementsInRoom(modelId, roomDbId) {

    let models = this.getModels();

    let roomModel = models.filter((m) => m.urn() === modelId)[0];

    let roomInfo = roomModel.getRooms()[roomDbId];

    let roomExternalId = roomInfo.externalId;

    let elementsInRoom = [];

    for (let model of models) {
      //Is the DtModel the room's own model, or another model?

      if (roomInfo.model === model) {
        let dbIdToRoomId = model.getData().dbId2roomIds;
        for (let dbId in dbIdToRoomId) {
          let elementRooms = dbIdToRoomId[dbId];
          if (typeof elementRooms === "number") {
            if (elementRooms === roomDbId) {
              elementsInRoom.push({ dbId: parseInt(dbId), model: model });
            }
          } else {
            if (elementRooms.includes(roomDbId)) {
              elementsInRoom.push({ dbId: parseInt(dbId), model: model });
            }
          }
        }
      } else {
        //Case of a model that is not the owner of the room element

        //find the local integer ID of this room in the current model
        let xrefs = model.getData().xrefs[roomModel.urn()];
        let localRoomXref = xrefs?.[roomExternalId];
        if (localRoomXref) {
          let dbIdToXRoomId = model.getData().dbId2xroomIds;

          for (let dbId in dbIdToXRoomId) {
            let elementRooms = dbIdToXRoomId[dbId];
            if (typeof elementRooms === "number") {
              if (elementRooms === localRoomXref) {
                elementsInRoom.push({ dbId: parseInt(dbId), model: model });
              }
            } else {
              if (elementRooms.includes(localRoomXref)) {
                elementsInRoom.push({ dbId: parseInt(dbId), model: model });
              }
            }
          }
        }

      }
    }

    //Reorganize the result to be a list of model -> dbIds pairs
    //for easier use with downstream APIs.
    //TODO: Can be combined with the loop above but will make the code harder to follow
    let elemsMap = elementsInRoom.reduce((acc, cur) => {
      let perModel = acc.get(cur.model);
      if (!perModel) {
        perModel = [];
        acc.set(cur.model, perModel);
      }

      perModel.push(cur.dbId);
      return acc;
    }, new Map());

    let res = [];
    elemsMap.forEach((val, key) => {
      res.push({ model: key, dbIds: val });
    });

    return res;
  }

  getRoomsOfElement(model, elementId) {
    return model.getRoomsOfElement(elementId, this.models);
  }

  /**
   * Update facility-wide room assignment settings if needed, then trigger a room assignment task
   * @param {boolean} boundByUpperFloor Use overhead floor slab as ceiling instead of room geometry, defaults to stored setting
   * @param {boolean} skipRoomBounders Don't assign room bounder elements to rooms, defaults to stored setting
   * @returns {Promise<void>}
   *
   * @ignore
   */
  async assignRooms(boundByUpperFloor, skipRoomBounders) {

    // Determines whether the options flags will be in the query
    const flags = [];
    if (boundByUpperFloor ?? this.settings.boundByUpperFloor) {
      flags.push('boundByUpperFloor');
    }

    if (skipRoomBounders ?? this.settings.skipRoomBounders) {
      flags.push('skipRoomBounders');
    }

    const params = flags.length > 0 ? `?${flags.join('&')}` : '';
    const headers = { etag: this.settingsEtag };
    const res = await fetchJson(this.loadContext, `/twins/${this.urn()}/assignrooms${params}`, "POST", null, headers);

    // Settings changed, reload needed
    if (res && !!res.t) {
      await this.reloadSettings();
    }
  }

  /**
   * Converts a map of model URN -> dbIds[] to a map of modelIds to database row IDs. If the useFullIds option
   * is specified, the model ID is also included in the encoded Id (40 byte key), otherwise simple qualified IDs
   * are generated (24 byte).
   */
  async encodeIds(modelToDbIds, useFullIds) {

    let proms = [];
    let models = [];
    let dbIdLists = [];
    for (let modelId in modelToDbIds) {
      let model = this.getModelByUrn(modelId);

      if (!model) {
        console.warn("Failed to find model", modelId);
        continue;
      }

      let dbIds = modelToDbIds[modelId];

      //Just in case it's a Set
      if (!Array.isArray(dbIds)) {
        dbIds = Array.from(dbIds);
      }

      proms.push(model.getElementIdsFromDbIds(dbIds));
      models.push(model);
      dbIdLists.push(dbIds);
    }

    let encodedIds = await Promise.all(proms);

    let buf = new Uint8Array(RowKeySize);
    let result = {};

    for (let i = 0; i < models.length; i++) {

      let perModelDst = {};
      result[models[i].urn()] = perModelDst;

      const modelGuid = models[i].urn().slice(DtConstants.DT_MODEL_URN_PREFIX.length + 1);
      base64DecToArr(modelGuid, buf, 0);

      let modelDbIds = dbIdLists[i];
      let modelEncodedIds = encodedIds[i];

      for (let j = 0; j < modelEncodedIds.length; j++) {
        base64DecToArr(modelEncodedIds[j], buf, ModelIdSize);

        if (useFullIds) {
          perModelDst[modelDbIds[j]] = base64EncArr(buf, 0, RowKeySize);
        } else {
          perModelDst[modelDbIds[j]] = base64EncArr(buf, ModelIdSize, ElementIdWithFlagsSize);
        }
      }
    }

    return result;
  }

  /**
   * converts an array of fullIDs each encoded in base64 into an array of ({model, dbId})
   */
  async resolveFullIDs(fullIDs) {
    const xrefs = fullIDs.reduce((xrefs, fullID) => {
      if (fullID) {
        enumB64FullId(fullID, (urn, elementId) => {
          xrefs.push([urn, elementId]);
        });
      } else {
        xrefs.push(fullID);
      }
      return xrefs;
    }, []);
    return this.resolveXrefs(xrefs);
  }

  /**
   * converts an array of external references each encoded as an array/tuple [urn, elementID] (as used
   * by xrefId2fullId) into an array of ({model, dbId})
   */
  async resolveXrefs(xrefs) {
    // group xrefs by model urn
    const byUrn = xrefs.reduce((byUrn, xref) => {
      if (xref) {
        const [urn, elementID] = xref;
        if (byUrn[urn]) {
          byUrn[urn][elementID] = -1;
        } else {
          byUrn[urn] = {
            [elementID]: -1
          };
        }
      }
      return byUrn;
    }, {});
    // find dbIds for elementIds (per model, in parallel)
    const proms = [];
    for (let urn in byUrn) {
      const m = this.getModelByUrn(urn);
      if (m) {
        const elementIDs = Object.keys(byUrn[urn]);
        const then = m.waitForLoad(false, true).
        then(() => m.getDbIdsFromElementIds(elementIDs)).
        then((dbIds) => dbIds.forEach((el, i) => byUrn[urn][elementIDs[i]] = el));
        proms.push(then);
      }
    }
    await Promise.all(proms);
    // match incoming array
    return xrefs.map((xref) => {
      if (xref) {
        const [urn, elementID] = xref;
        const dbId = byUrn[urn][elementID];
        if (dbId != null && dbId != -1) {
          return { model: this.getModelByUrn(urn), dbId };
        }
      }
    });
  }

  /**
   * Load the facility meta data from all models.
   */
  async loadMetaData() {
    let proms = [];

    for (let model of this.getModels()) {
      proms.push(model.loadMetaData());
    }

    const allModelMetaData = await Promise.all(proms);
    return allModelMetaData;
  }

  /**
   * Updates the rooms for a particular element
   *
   * @param {array} rooms Array of rooms, modelUrn, and elementIds to update [[modelUrn, dbId], modelUrn, elementId]
   */
  async updateRoomsLink(rooms) {
    const allChangeDataByModelUrn = {};

    // Loop through each elementId for this model
    for (const [newRooms, modelUrn, elementIds] of rooms) {
      for (const elementId of elementIds) {
        const selectedModel = this.getModelByUrn(modelUrn);
        const currentRooms = this.getRoomsOfElement(selectedModel, elementId).map((r) => [r.model.urn(), r.dbId]);
        let oldInternalRooms = [],oldExternalRooms = [],newInternalRooms = [],newExternalRooms = [];

        for (let currentRoom of currentRooms) {
          currentRoom[0] === modelUrn ? oldInternalRooms.push(currentRoom) : oldExternalRooms.push(currentRoom);
        }

        for (let newRoom of newRooms) {
          newRoom[0] === modelUrn ? newInternalRooms.push(newRoom) : newExternalRooms.push(newRoom);
        }

        const changeData = {
          [QC.LmvDbId]: elementId
        };

        let tempDecodedIdBuffer = new Uint8Array(RowKeySize);
        const encodeRooms = (buffer, encLen, b64IDs, encodedIdSize) => {
          if (b64IDs.length === 0) {
            return '';
          }

          encodedIdSize = encodedIdSize ?? encLen;

          let offset = 0;
          for (const encodedId of b64IDs) {
            // To use the same temp buffer, we only want to write the last encodedIdSize bytes of the array
            base64DecToArr(encodedId, tempDecodedIdBuffer, tempDecodedIdBuffer.length - encodedIdSize);

            // Only collect the last encLen bytes
            let i = 0;
            while (i < encLen) {
              buffer[offset + (encLen - i - 1)] = tempDecodedIdBuffer[tempDecodedIdBuffer.length - i - 1];
              i++;
            }
            offset += encLen;
          }
          return base64EncArr(buffer, 0, offset);
        };

        // Update rooms only if needed i.e prev vs new rooms have changed
        const updateInternalRooms = oldInternalRooms.length !== newInternalRooms.length ||
        oldInternalRooms.map((r) => r[1]).sort().toString() !== newInternalRooms.map((r) => r[1]).sort().toString();

        // External rooms need a more rigorous check against the model urn as well as id
        const updateExternalRooms = oldExternalRooms.length !== newExternalRooms.length ||
        oldExternalRooms.map((r) => r[1]).sort().toString() !== newExternalRooms.map((r) => r[1]).sort().toString() ||
        oldExternalRooms.map((r) => r[0]).sort().toString() !== newExternalRooms.map((r) => r[0]).sort().toString();

        if (updateInternalRooms) {
          const internalEncodedIds = await selectedModel.getElementIdsFromDbIds(newInternalRooms.map((r) => r[1]));
          let buffer = new Uint8Array(ElementIdSize * newInternalRooms.length);

          changeData[QC.Rooms] = encodeRooms(buffer, ElementIdSize, internalEncodedIds, ElementIdWithFlagsSize);
        }

        if (updateExternalRooms) {
          let externalModelToDbIds = {};

          for (const [modelURN, roomDbId] of newExternalRooms) {
            externalModelToDbIds[modelURN] = externalModelToDbIds[modelURN] ?? [];
            externalModelToDbIds[modelURN].push(roomDbId);
          }

          // Get the encodedIds of each external model and then the values of each (two level nested object)
          const externalEncodedIdsMap = Object.values(await this.encodeIds(externalModelToDbIds, true));
          const externalEncodedIds = externalEncodedIdsMap.map((o) => Object.values(o)).flat();
          let buffer = new Uint8Array(RowKeySize * newExternalRooms.length);

          changeData[QC.XRooms] = encodeRooms(buffer, RowKeySize, externalEncodedIds);
        }

        if (QC.Rooms in changeData || QC.XRooms in changeData) {
          if (!allChangeDataByModelUrn[modelUrn]) {
            allChangeDataByModelUrn[modelUrn] = [];
          }

          allChangeDataByModelUrn[modelUrn].push(changeData);
        }
      }
    }

    const promiseByUrn = this.updateElementsWithData(allChangeDataByModelUrn, "Update rooms relationship");
    await Promise.all(Object.values(promiseByUrn));
  }

  getAllImportedSystemClasses() {
    const models = this.getModels();

    let res = 0;
    for (let m of models) {
      const sc = m.getAllSystemClasses();
      if (sc) {
        res |= sc;
      }
    }

    return res;
  }

  /**
   * Updates system classification for given selection set
   *
   * @param selections current viewer selection set
   * @param systemsToAdd system list to add defined as a bit mask
   * @param systemsToDelete system list to delete defined as a bit mask
   */
  async updateSystemClass(selections, systemsToAdd, systemsToDelete) {
    const changeByModelUrn = {};
    for (let i = 0; i < selections.length; i++) {
      const sel = selections[i];
      const selectedModel = sel.model;

      const modelData = selectedModel.getData();
      if (!modelData) {
        continue;
      }

      let dataToUpdate = [];

      const modelMasks = modelData.dbId2SystemClass;
      const dbIds = sel.selection;

      for (let i = 0; i < dbIds.length; i++) {
        const dbId = dbIds[i];
        let mask = modelMasks[dbId];

        // Add elements
        mask |= systemsToAdd;

        // Remove elements
        mask &= ~systemsToDelete;

        const changeData = {
          [QC.LmvDbId]: dbId,
          [QC.SystemClass]: mask
        };

        dataToUpdate.push(changeData);
      }

      changeByModelUrn[selectedModel.urn()] = dataToUpdate;
    }

    // Update
    const promiseByUrn = this.updateElementsWithData(changeByModelUrn, 'Update systems classification');
    await Promise.all(Object.values(promiseByUrn));
  }

  /**
   * Returns the list of saved views for the current facility.
   *
   * @returns {Promise<Array.<View>>} The list of saved views.
   *
   * @alias Autodesk.Tandem.DtFacility#getSavedViewsList
   */
  async getSavedViewsList() {
    return this.app.views.fetchFacilityViews(this);

  }

  async goToView(view) {
    let prev = this.currentView;
    this.currentView = view;
    return DtViewerState.setView(this, view, prev);
  }


  /**
   * @callback roomCallback
   * @param {DtModel} model
   * @param {object} roomInfo
   * @param {Number} catId
   * @param {Number} roomLevelDbId
   * @param {Number[]} fragIds
   */
  /**
   * Iterates over all rooms in the twin and calls the given callback
   * @param {roomCallback} cb
   * @param {string} levelNameFilter
   * @param {Boolean} includeMEPSpaces
   */
  forEachRoom(cb, levelNameFilter, includeMEPSpaces) {
    for (let model of this.modelsList) {

      //If the model is off completely, we ignore any rooms in it
      if (!this.isModelVisible(model)) {
        continue;
      }

      let modelData = model.getData();

      let dbId2catId = modelData.dbId2catId;
      let dbId2levelId = modelData.dbId2levelId;
      let it = model.getInstanceTree();

      let roomsMap = model.getRooms();

      for (let roomDbIdStr in roomsMap) {

        //The roomsMap contains each room twice, once with its integer dbId, and once
        //with its full database key. Hence this silly check
        if (roomDbIdStr.length >= 20) {
          continue;
        }

        let roomDbId = parseInt(roomDbIdStr);

        let roomInfo = roomsMap[roomDbId];

        if (!includeMEPSpaces && roomInfo.isMEPSpace) {
          continue; //Skips spaces by default because of too much clutter and overlap with rooms
        }

        let roomLevelDbId = dbId2levelId[roomDbId];

        if (roomLevelDbId) {
          let rmLevelName = it.getNodeName(roomLevelDbId, false);
          if (levelNameFilter && levelNameFilter !== rmLevelName) {
            continue;
          }
        } else if (levelNameFilter) {
          //If the room has no level but there is a level filter, we filter it out
          continue;
        }

        let fragIds = [];
        it.enumNodeFragments(roomDbId, (fragId) => {
          fragIds.push(fragId);
        }, true);

        if (!fragIds.length) {
          //console.warn("Room without fragIds");
          continue;
        }

        // TODO, the caller only cares about catId being RC.MEPSpaces or not. This
        // is a boolean on the roomInfo and hence should be used instead, so we can
        // stop passing it around separately.
        let catId = dbId2catId[roomInfo.dbId];
        cb(model, roomInfo, catId, roomLevelDbId, fragIds);
      }
    }
  }

  async search(term, options) {
    return searchFacility(this, term, options);
  }

  getSelectionTool() {
    return this.dtTool;
  }

  getHUD() {
    return this.hud;
  }

  // replicates the level references from a given source element into elmData
  // and as a side effect, copies relevant level elements into default model
  async addLevelFromElement(elmData, model, levelId) {
    const sourceLevels = model.getLevels();
    const sourceLevel = sourceLevels[levelId];
    if (!sourceLevel) {
      console.warn('Level not found');
      return;
    }

    const defaultModel = this.getDefaultModel();
    const levels = defaultModel.getLevels();

    const copyLevels = (levels) => levels.map((l) => ({
      [QC.RowKey]: l.externalId,
      [QC.Name]: l.name,
      [QC.ElementFlags]: ElementFlags.Level,
      [QC.CategoryId]: DtConstants.RC.Levels,
      ...(l.elev !== undefined && { [QC.Elevation]: l.elev })
    }));

    if (Object.keys(levels).length == 0) {
      // no levels in the model (yet): copy all source levels (keeping their element ID)
      const sourceLevelArray = Object.values(sourceLevels);
      await defaultModel.createElementsWithData(copyLevels(sourceLevelArray), "Create levels");
    } else {
      // model has some levels already: only copy the source one, if missing
      let found = false;
      for (let id in levels) {
        const level = levels[id];
        // match by external id or name
        if (level.externalId == sourceLevel.externalId || level.name === sourceLevel.name) {
          found = true;
          break;
        }
      }
      if (!found) {
        await defaultModel.createElementsWithData(copyLevels([sourceLevel]), "Create levels");
      }
    }

    elmData[QC.Level] = sourceLevel.name;
  }

  /** Triggers a server-side task to map attributes. */
  triggerAttributeMappingTask() {
    return fetchJson(this.loadContext, `/twins/${this.urn()}/map-attributes`, 'POST').
    catch((err) => console.error('unable to trigger attribute mapping due to', err));
  }
}

EventDispatcher.prototype.apply(DtFacility.prototype);
PermissionChecker.prototype.apply(DtFacility.prototype);
DtFacility.prototype.constructor = DtFacility;