import * as globals from "../globals.js";
import * as THREE from "three";
import { logger } from "../../logger/Logger";
import { getIndicesCount, VertexEnumerator } from "./VertexEnumerator";

//Finds a precanned BufferAttribute corresponding to the given
//attribute data, so that we don't have to allocate the same exact
//one over and over and over.
var bufattrs = {};

function findBufferAttribute(attributeName, attributeData, numInstances) {

  //Note .array could be undefined in case we are using
  //an interleaved buffer.
  var attr;
  var attrNormalized = attributeData.normalize || attributeData.normalized;
  if (attributeData.array) {
    attr = new THREE.BufferAttribute(attributeData.array, attributeData.itemSize);
  } else
  {
    var id = attributeName + "|" +
    attributeData.bytesPerItem + "|" +
    attrNormalized + "|" +
    attributeData.isPattern + "|" +
    attributeData.divisor + "|" +
    attributeData.offset;

    attr = bufattrs[id];
    if (attr)
    return attr;

    attr = new THREE.BufferAttribute(undefined, attributeData.itemSize);
    bufattrs[id] = attr;
  }

  attr.bytesPerItem = attributeData.bytesPerItem;
  attr.normalized = attrNormalized;
  attr.isPattern = attributeData.isPattern;

  if (numInstances) {
    attr.divisor = attributeData.divisor;
  }

  if (attributeData.array) {


    //Is the data for the attribute specified separately
    //from the interleaved VB?
  } else if (attributeData.hasOwnProperty("offset")) {//If the attribute is in the interleaved VB, it has
    //an offset into it.
    attr.itemOffset = attributeData.offset;
  } else
  {
    logger.warn("VB attribute is neither interleaved nor separate. Something is wrong with the buffer specificaiton.");
  }

  return attr;
}

var attrKeys = {};

function findAttributesKeys(geometry) {
  var key = "";

  for (var p in geometry.attributes)
  key += p + "|";

  var res = attrKeys[key];
  if (res)
  return res;

  res = Object.keys(geometry.attributes);
  attrKeys[key] = res;

  return res;
}

var indexAttr8 = new THREE.BufferAttribute(undefined, 1);
indexAttr8.bytesPerItem = 1;

var indexAttr16 = new THREE.BufferAttribute(undefined, 1);
indexAttr16.bytesPerItem = 2;

var indexAttr32 = new THREE.BufferAttribute(undefined, 1);
indexAttr32.bytesPerItem = 4;


var idcounter = 1;

export function LmvBufferGeometry() {

  //Avoid calling the superclass constructor for performance reasons.
  //Skips the creation of a uuid and defining an accessor for the .id property.
  //THREE.BufferGeometry.call(this);

  this.id = idcounter++;
  this.refcount = 0;
  this.streamingIndex = false;
  this.streamingDraw = false;

  this.attributes = {};

  // Note:
  //  1. Although __webglInit would also be undefined without this assignment, it is still essential
  //     for performance reasons, because it makes this property known to the JIT compiler. Otherwise,
  //     it would be attached to each buffer later in WebGLRenderer - which would waste performance.
  //  2. It is essential to use "undefined" and not "false" here. The reason is that WebGLRenderer
  //     only checks in the form "__webglInit === undefined", i.e., setting it to "false" here would have
  //     the same effect like setting it to "true" and would finally cause a memory leak.
  this.__webglInit = undefined;

}

LmvBufferGeometry.prototype = Object.create(THREE.BufferGeometry.prototype);
LmvBufferGeometry.prototype.constructor = LmvBufferGeometry;

//Converts a mesh description passed back from worker threads into a renderable three.js
//compatible BufferGeometry.
//Sets various extra flags we need.
function meshToGeometry(mdata) {

  var mesh = mdata.mesh;
  var geometry = new LmvBufferGeometry();

  geometry.byteSize = 0;

  geometry.vb = mesh.vb;
  geometry.vbbuffer = undefined;
  geometry.vbNeedsUpdate = true;
  geometry.byteSize += mesh.vb.byteLength;
  geometry.hash = mdata.hash;

  geometry.vbstride = mesh.vbstride;
  if (mesh.isLines) /* mesh is SVF lines */
    geometry.isLines = mesh.isLines;
  if (mesh.isWideLines) {/* mesh is SVF wide lines */
    geometry.isWideLines = true;
    geometry.lineWidth = mesh.lineWidth;
  }
  if (mesh.isPoints) {/* mesh is SVF points */
    geometry.isPoints = mesh.isPoints;
    geometry.pointSize = mesh.pointSize;
  }
  if (mdata.is2d) /* mesh is from F2D */{
      geometry.is2d = true;
    }

  geometry.numInstances = mesh.numInstances;

  for (var attributeName in mesh.vblayout) {
    var attributeData = mesh.vblayout[attributeName];

    //geometry.setAttribute(attributeName, findBufferAttribute(attributeData, geometry.numInstances));
    geometry.attributes[attributeName] = findBufferAttribute(attributeName, attributeData, geometry.numInstances);
  }
  //Index buffer setup
  if (!globals.memoryOptimizedLoading) {
    var iAttr = new THREE.BufferAttribute(mesh.indices, 1);
    iAttr.bytesPerItem = mesh.indices instanceof Uint32Array ? 4 : mesh.indices instanceof Uint16Array ? 2 : 1;
    geometry.setIndex(iAttr);
  } else {

    geometry.index = mesh.indices instanceof Uint32Array ? indexAttr32 : mesh.indices instanceof Uint16Array ? indexAttr16 : indexAttr8;
    geometry.ib = mesh.indices;
    geometry.ibbuffer = undefined;

    if (mesh.iblines) {
      geometry.attributes.indexlines = mesh.iblines instanceof Uint32Array ? indexAttr32 : mesh.iblines instanceof Uint16Array ? indexAttr16 : indexAttr8;
      geometry.iblines = mesh.iblines;
      geometry.iblinesbuffer = undefined;
    }
  }

  geometry.attributesKeys = findAttributesKeys(geometry);

  geometry.byteSize += mesh.indices.byteLength;

  //TODO: Not sure chunking into list of smaller offset/counts
  //is required for LMV data since it's already broken up.
  //if (mesh.indices.length > 65535)
  // Works fine now. Left in for debugging.
  //if (mesh.vb.length / mesh.vbstride > 65535)
  //    logger.warn("Mesh with " + (mesh.vb.length / mesh.vbstride) + " > 65535 vertices. It will fail to draw.");

  //TODO: This is a transient object that gets freed once the geometry
  //is added to the GeometryList. We can save on the object creation
  //eventually when we do micro optimizations.
  geometry.boundingBox = new THREE.Box3().copy(mesh.boundingBox);
  geometry.boundingSphere = new THREE.Sphere().copy(mesh.boundingSphere);

  mdata.geometry = geometry;

  mdata.mesh = null;
}


//Combines geometry with multiple vertex attribute channels into single interleaved buffer
//and generates new the geometry buffer attributes accordingly
function interleaveGeometry(geometry, packNormals) {

  if (geometry.vb && !geometry.vbNeedsUpdate) {
    return;
  }

  let attributes = {};
  let curOffset = 0;
  let oldAttrs = geometry.attributes;

  let position = oldAttrs.position;
  if (position) {
    attributes.position = findBufferAttribute("position", { offset: curOffset, bytesPerItem: 4, itemSize: 3 });
    curOffset += 3;
  }

  let normal = oldAttrs.normal;
  if (normal) {
    if (packNormals || normal.bytesPerItem === 2) {
      attributes.normal = findBufferAttribute("normal", { offset: curOffset, bytesPerItem: 2, itemSize: 2 });
      curOffset += 1;
    } else {
      attributes.normal = findBufferAttribute("normal", { offset: curOffset, bytesPerItem: 4, itemSize: 3 });
      curOffset += 3;
    }
  }

  let uv = oldAttrs.uv;
  if (uv) {
    attributes.uv = findBufferAttribute("uv", { offset: curOffset, bytesPerItem: 4, itemSize: 2 });
    curOffset += 2;
  }

  //TODO: vertexColor, if needed

  let stride = curOffset;
  let numVerts = position.array.length / (position.array.itemSize || 3);
  let vb = new Float32Array(numVerts * stride);
  let vbU = new Uint16Array(vb.buffer);

  if (position) {
    let pos = 0;
    let src = position.array;
    for (let i = 0; i < numVerts; i++, pos += stride) {
      vb[pos] = src[i * 3];
      vb[pos + 1] = src[i * 3 + 1];
      vb[pos + 2] = src[i * 3 + 2];
    }
  }

  if (normal) {
    if (normal.itemSize === 2) {
      //normals are already packed
      let pos = attributes.normal.itemOffset * 2; //12 bytes offset into the vertex data, expressed in uint16s (2 bytes per)
      let src = normal.array;
      for (let i = 0; i < numVerts; i++, pos += stride * 2) {
        vbU[pos] = src[i * 2];
        vbU[pos + 1] = src[i * 2 + 1];
      }
    } else {
      //input normals are not packed
      if (!packNormals) {
        let pos = attributes.normal.itemOffset;
        let src = normal.array;
        for (let i = 0; i < numVerts; i++, pos += stride) {
          vb[pos] = src[i * 3];
          vb[pos + 1] = src[i * 3 + 1];
          vb[pos + 2] = src[i * 3 + 2];
        }
      } else {
        let pos = attributes.normal.itemOffset * 2; //12 bytes offset into the vertex data, expressed in uint16s (2 bytes per)
        let src = normal.array;
        for (let i = 0; i < numVerts; i++, pos += stride * 2) {
          let nx = src[i * 3];
          let ny = src[i * 3 + 1];
          let nz = src[i * 3 + 2];

          vbU[pos] = (Math.atan2(ny, nx) / Math.PI + 1.0) * 0.5 * 65535 | 0;
          vbU[pos + 1] = (nz + 1.0) * 0.5 * 65535 | 0;
        }
      }
    }
  }

  if (uv) {
    let pos = attributes.uv.itemOffset;
    let src = uv.array;
    for (let i = 0; i < numVerts; i++, pos += stride) {
      vb[pos] = src[i * 2];
      vb[pos + 1] = src[i * 2 + 1];
    }
  }

  let ib;

  if (geometry.attributes.index) {

    //TODO: sizes other than uint16
    ib = oldAttrs.index.array;
    attributes.index = indexAttr16;

  } else {
    //generate trivial index buffer
    ib = new Uint16Array(numVerts);
    for (let i = 0; i < numVerts; i++) {
      ib[i] = i;
    }

    attributes.index = indexAttr16;
  }

  let iblines;
  if (geometry.attributes.indexlines) {
    iblines = oldAttrs.indexlines.array;
    attributes.indexlines = indexAttr16;
  }

  //TODO: this may need to be more delicate in case we want to allow dynamic modification
  geometry.vbstride = stride;
  geometry.vb = vb;
  geometry.ib = ib;
  geometry.iblines = iblines;
  geometry.attributesInterleaved = attributes;
  geometry.vbNeedsUpdate = false;

}


export let BufferGeometryUtils = {
  meshToGeometry,
  interleaveGeometry
};