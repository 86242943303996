import { getResourceUrl } from "../../globals";
import { ViewingService } from "../../net/Xhr";
import { QC, QCOverrides, ColumnFamilies, ColumnNames, ElementFlags, SyntheticColumns } from "./dt-schema";
import { SystemClassNames } from "./system-class";
import { AttributeDef, AttributeType, AttributeFlags, AttributeContext } from "./Attribute";
import { matchClassification, checkClassificationSystem } from './matchClassification';
import { Debounce } from "../../net/debounce";
import RC from "../resources/cats_enum.json";

let RESOURCES = {};

async function getResource(url) {
  return new Promise((resolve, reject) => {
    ViewingService.getItem({}, url, resolve, reject, {
      responseType: "json",
      withCredentials: false
    });
  });
}

const getResourceDebounce = new Debounce(async (name) => {
  let url = getResourceUrl(name);
  RESOURCES[name] = await getResource(url);
  return RESOURCES[name];
});


async function getResourceDebounced(name) {

  if (RESOURCES[name]) {
    return RESOURCES[name];
  }

  return getResourceDebounce.debounce(name, [name]);
}

async function getForgeUnits() {
  return getResourceDebounced("units.json");
}

async function getForgeUnitSpecs() {
  return getResourceDebounced("specs.json");
}

async function getRevitCategories() {
  return getResourceDebounced("cat_id_to_name.json");
}

async function getParameterSetsLibrary() {
  return getResourceDebounced("parameterSets.json");
}

async function getParameterLibrary() {
  return getResourceDebounced("parameters.json");
}

async function getFacilityTemplatesLibrary() {
  return getResourceDebounced("facilityTemplates.json");
}

async function getClassificationsLibrary() {
  return getResourceDebounced("classifications.json");
}

async function getClassification(uuid) {
  const classifications = await getClassificationsLibrary();
  return classifications?.find((classification) => classification.uuid === uuid);
}

async function getRevitCategoryToDisciplineMapping() {

  if (CAT_TO_DISC) return CAT_TO_DISC;

  let d2c = await getRevitDisciplines();

  let res = {};

  for (let d in d2c) {
    let cats = d2c[d];

    for (let i = 0; i < cats.length; i++) {
      res[cats[i]] = d;
    }
  }

  return res;
}

function getSystemClassNames() {
  return SystemClassNames.slice();
}

/**
 * Curated collection of interesting Revit category IDs for system tracing.
 * @return {Promise<number[]>}
 */
async function getInterestingTracingCats() {
  return getResourceDebounced("cats_of_interest.json");
}

const UNIFORMAT_UUID = 'uniformat';
const MASTERFORMAT_UUID = 'masterformat';

const StandardAttributes = {
  [QC.RowKey]: Object.freeze(new AttributeDef(QC.RowKey, ['externalId', 'ID', AttributeType.String, null, '', '', AttributeFlags.afHidden | AttributeFlags.afReadOnly, 0, '', '', '', QC.RowKey, "", null])),
  [SyntheticColumns.GUID]: Object.freeze(new AttributeDef(SyntheticColumns.GUID, ['GUID', 'Common', AttributeType.String, null, '', '', AttributeFlags.afReadOnly, 0, '', '', '', SyntheticColumns.GUID, "", null])),
  [SyntheticColumns.SourceFile]: Object.freeze(new AttributeDef(SyntheticColumns.SourceFile, ['Source File', 'Common', AttributeType.String, null, 'Source File', '', AttributeFlags.afReadOnly, 0, '', '', '', SyntheticColumns.SourceFile, "", null])),

  [QC.LmvDbId]: Object.freeze(new AttributeDef(QC.LmvDbId, ['dbId', 'ID', AttributeType.DbKey, null, '', '', AttributeFlags.afHidden | AttributeFlags.afReadOnly, 0, '', '', '', ColumnNames.LmvDbId, ColumnFamilies.Standard, null])),
  [QC.ElementFlags]: Object.freeze(new AttributeDef(QC.ElementFlags, ['Flags', 'Common', AttributeType.Integer, null, '', '', AttributeFlags.afReadOnly, 0, '', '', '', ColumnNames.ElementFlags, ColumnFamilies.Standard, null])),
  [QC.SystemClass]: Object.freeze(new AttributeDef(QC.SystemClass, ['System Class', 'Common', AttributeType.Integer, null, '', '', 0, 0, '', '', '', ColumnNames.SystemClass, ColumnFamilies.Standard, null])),
  [QC.OSystemClass]: Object.freeze(new AttributeDef(QC.OSystemClass, ['System Class Override', 'Common', AttributeType.Integer, null, '', '', 0, 0, '', '', '', ColumnNames.OSystemClass, ColumnFamilies.Standard, null])),
  [QC.Name]: Object.freeze(new AttributeDef(QC.Name, ['Name', 'Common', AttributeType.String, null, '', '', 0, 0, '', '', '', ColumnNames.Name, ColumnFamilies.Standard, null])),
  [QC.OName]: Object.freeze(new AttributeDef(QC.OName, ['Name Override', 'Common', AttributeType.String, null, '', '', 0, 0, '', '', '', ColumnNames.OName, ColumnFamilies.Standard, null])),
  [QC.UniformatClass]: Object.freeze(new AttributeDef(QC.UniformatClass, ['Assembly Code', 'Common', AttributeType.String, null, '', '', 0, 0, '', '', '', ColumnNames.UniformatClass, ColumnFamilies.Standard, null])),
  [QC.OUniformatClass]: Object.freeze(new AttributeDef(QC.OUniformatClass, ['Assembly Code Override', 'Common', AttributeType.String, null, '', '', 0, 0, '', '', '', ColumnNames.OUniformatClass, ColumnFamilies.Standard, null])),
  [QC.Classification]: Object.freeze(new AttributeDef(QC.Classification, ['Classification', 'Common', AttributeType.String, null, '', '', 0, 0, '', '', '', ColumnNames.Classification, ColumnFamilies.Standard, null])),
  [QC.OClassification]: Object.freeze(new AttributeDef(QC.OClassification, ['Classification Override', 'Common', AttributeType.String, null, '', '', 0, 0, '', '', '', ColumnNames.OClassification, ColumnFamilies.Standard, null])),
  [QC.CategoryId]: Object.freeze(new AttributeDef(QC.CategoryId, ['Category Id', 'Common', AttributeType.Integer, null, '', '', AttributeFlags.afReadOnly, 0, '', '', '', ColumnNames.CategoryId, ColumnFamilies.Standard, null])),
  [QC.CategoryName]: Object.freeze(new AttributeDef(QC.CategoryName, ['Category Name', 'Common', AttributeType.String, null, '', '', AttributeFlags.afReadOnly, 0, '', '', '', ColumnNames.CategoryName, ColumnFamilies.Virtual, null])),
  [QC.FamilyPath]: Object.freeze(new AttributeDef(QC.FamilyPath, ['Category/Family', 'Common', AttributeType.String, null, '', '', AttributeFlags.afHidden | AttributeFlags.afReadOnly, 0, '', '', '', ColumnNames.FamilyPath, ColumnFamilies.Standard, null])),
  [QC.Elevation]: Object.freeze(new AttributeDef(QC.Elevation, ['Elevation', 'Common', AttributeType.Double, null, '', '', 0, 0, '', '', '', ColumnNames.Elevation, ColumnFamilies.Standard, null])),
  [QC.OElevation]: Object.freeze(new AttributeDef(QC.OElevation, ['Elevation Override', 'Common', AttributeType.Double, null, '', '', 0, 0, '', '', '', ColumnNames.OElevation, ColumnFamilies.Standard, null])),
  [QC.Tolerance]: Object.freeze(new AttributeDef(QC.Tolerance, ['Tolerance', 'Common', AttributeType.Double, null, '', '', 0, 0, '', '', '', ColumnNames.Tolerance, ColumnFamilies.Standard, null])), // DEPRECATED
  [QC.Config]: Object.freeze(new AttributeDef(QC.Config, ['Configuration', 'Common', AttributeType.BLOB, null, '', '', AttributeFlags.afHidden, 0, '', '', '', ColumnNames.Config, ColumnFamilies.Standard, null])),
  [QC.Settings]: Object.freeze(new AttributeDef(QC.Settings, ['Settings', 'Common', AttributeType.BLOB, null, '', '', AttributeFlags.afHidden, 0, '', '', '', ColumnNames.Settings, ColumnFamilies.Standard, null])),
  [QC.State]: Object.freeze(new AttributeDef(QC.State, ['State', 'Common', AttributeType.String, null, '', '', AttributeFlags.afHidden, 0, '', '', '', ColumnNames.State, ColumnFamilies.Status, null])),
  [QC.Deleted]: Object.freeze(new AttributeDef(QC.Deleted, ['Deleted', 'Common', AttributeType.Boolean, null, '', '', AttributeFlags.afHidden, 0, '', '', '', ColumnNames.Deleted, ColumnFamilies.Status, null])),
  [QC.ODeleted]: Object.freeze(new AttributeDef(QC.ODeleted, ['Deleted Override', 'Common', AttributeType.Boolean, null, '', '', AttributeFlags.afHidden, 0, '', '', '', ColumnNames.ODeleted, ColumnFamilies.Status, null])),

  [QC.Level]: Object.freeze(new AttributeDef(QC.Level, ['Level', 'Common', AttributeType.String, null, '', '', 0, 0, '', '', '', ColumnNames.Level, ColumnFamilies.Refs, null])),
  [QC.OLevel]: Object.freeze(new AttributeDef(QC.OLevel, ['Level Override', 'Common', AttributeType.String, null, '', '', 0, 0, '', '', '', ColumnNames.OLevel, ColumnFamilies.Refs, null])),
  [QC.TopLevel]: Object.freeze(new AttributeDef(QC.TopLevel, ['Top Level', 'Common', AttributeType.String, null, '', '', 0, 0, '', '', '', ColumnNames.TopLevel, ColumnFamilies.Refs, null])),
  [QC.Rooms]: Object.freeze(new AttributeDef(QC.Rooms, ['Rooms', 'Common', AttributeType.DbKeyList, null, '', '', 0, 0, '', '', '', ColumnNames.Rooms, ColumnFamilies.Refs, null])),
  [QC.XRooms]: Object.freeze(new AttributeDef(QC.XRooms, ['Rooms External', 'Common', AttributeType.ExDbKeyList, null, '', '', 0, 0, '', '', '', ColumnNames.Rooms, ColumnFamilies.Xrefs, null])),
  [QC.Parent]: Object.freeze(new AttributeDef(QC.Parent, ['Parent', 'Common', AttributeType.DbKey, null, '', '', AttributeFlags.afReadOnly, 0, '', '', '', ColumnNames.Parent, ColumnFamilies.Refs, null])),
  [QC.XParent]: Object.freeze(new AttributeDef(QC.XParent, ['Parent External', 'Common', AttributeType.ExDbKeyList, null, '', '', AttributeFlags.afHidden, 0, '', '', '', ColumnNames.Parent, ColumnFamilies.Xrefs, null])),
  [QC.FamilyType]: Object.freeze(new AttributeDef(QC.FamilyType, ['FamilyType', 'Common', AttributeType.DbKey, null, '', '', AttributeFlags.afReadOnly, 0, '', '', '', ColumnNames.FamilyType, ColumnFamilies.Refs, null])),
  [QC.SubFamily]: Object.freeze(new AttributeDef(QC.SubFamily, ['SubFamily', 'Common', AttributeType.DbKey, null, '', '', AttributeFlags.afReadOnly, 0, '', '', '', ColumnNames.SubFamily, ColumnFamilies.Refs, null]))
};


const FamilyAttributes = {
  // Special Attr definition for systems column. Includes ":" to match StandardAttributes format
  [ColumnFamilies.Systems + ':']: Object.freeze(new AttributeDef(ColumnFamilies.Systems + ':', ['Systems', 'Common', null, null, '', '', AttributeFlags.afReadOnly, 0, '']))
};

//Revit properties with these categories are excluded from the data grid UI.
//Some of these are normally not visible in Revit while the others are considered not relevant.
const HiddenRevitCategories = ["__category__", "__categoryId__", "Graphics", "Phasing", "__node_flags__"];

const ModelImportState = {
  // ModelCreated model row was created
  Created: "c",
  // ModelReady model is ready for viewing and editing
  Ready: "r",
  // ModelImportPending model is queued for import
  ImportPending: "q",
  // ModelImporting import process is running
  Importing: "i",
  // ModelFailed something went wrong during the pipeline steps
  Failed: "f",
  // ModelTranslating processing by Forge Derivative Service
  Translating: "t",
  // ModelPostProcessing operations that do bulk updates to model data
  PostProcessing: "p",
  // ModelDeleting - model was queued for deletion
  Deleting: "d"
};

const SystemState = {
  // System created and mapped with up-to-date element properties
  Ready: "r",
  // Possible element(s) added/removed from system, remapping may be needed
  Dirty: "d"
};

// TODO: normalize accesslevel definitions across layers
const DtAccessLevel = Object.freeze({
  NONE: "None",
  READ: "Read",
  READWRITE: "ReadWrite",
  MANAGE: "Manage",
  OWNER: "Owner"
});

const DT_URN_PREFIX = "urn:adsk.dt";
const DT_MODEL_URN_PREFIX = "urn:adsk.dtm";
const DT_TWIN_URN_PREFIX = "urn:adsk.dtt";
const DT_USER_URN_PREFIX = "urn:adsk.dtu";
const DT_GROUP_URN_PREFIX = "urn:adsk.dtg";
const DT_DOCUMENT_URN_PREFIX = "urn:adsk.dtd";

const ChangeTypes = Object.freeze({
  BulkImport: 'bulk_import',
  BulkUpdate: 'bulk_update',
  BulkFail: 'bulk_fail',
  Mutate: 'mutate',
  ApplyPset: 'apply_pset',
  DeletePset: 'delete_pset',
  UpdateClassification: 'update_classification',
  StateChange: 'state_change',
  UpdateMetrics: 'metrics_update',
  ApplyTemplate: 'apply_template',
  RemoveTemplate: 'remove_template',
  DeleteStreamData: 'delete_stream_data',
  CreateSystems: 'create_systems',
  UpdateSystems: 'update_systems',
  UpdateSystemConnections: 'update_system_connections',
  DeleteSystems: 'delete_systems',
  UpdateTwinSettings: 'update_twin_settings',
  MoveTwin: "move_twin",
  UpdateGroupTwins: "update_group_twins",
  UpdateGroupACL: "acl_update_group",
  UpdateTwinACL: "acl_update_twin",
  AddDocument: 'add_document',
  DeleteDocument: 'delete_document',
  UpdateDocument: 'update_document'
});

const StreamsImportExportTableHeader = {
  Name: "[Common][Name][][]",
  "Assembly Code": "[Common][Assembly Code][][]",
  Classification: "[Common][Classification][][]",
  HostModelID: "[HostModelID]",
  HostElementID: "[HostElementID]"
};

const MutationActions = Object.freeze({
  Upsert: 'i',
  Delete: 'd',
  DeleteRow: 'a'
});

const SystemFilterNames = Object.freeze({
  SystemClasses: "systemClasses"
});

const StreamStates = Object.freeze({
  NoData: "no data",
  Normal: "normal",
  Warning: "warning",
  Alert: "alert",
  Offline: "offline"
});

// order by key index
const StreamStatePriority = Object.values(StreamStates).
reduce((current, key, i) => ({ ...current, [key]: i }), {});

/**
 * 1, 0, 0, 0,														(4 Logical element flags)
 * 77, 79, 78, 84, 82, 69, 65, 76, 80, 79, 84, 72, 79, 76, 69, 83,	(16 Hard coded MEPSystem UUIDv4: 4d4f4e54-5245-414c-504f-54484f4c4553)
 * 0, 0, 0, 0,														(4 Incrementing System ID)
 */
const SystemElementFirstKey = "AQAAAE1PTlRSRUFMUE9USE9MRVMAAAAA";

// Same list as CENTERLINE_CATEGORY_IDS_SET on the server
const CenterlineCatIDs = Object.freeze(new Set([
RC.DuctCurvesCenterLine,
RC.FlexDuctCurvesCenterLine,
RC.PipeCurvesCenterLine,
RC.FlexPipeCurvesCenterLine,
RC.DuctFittingCenterLine,
RC.PipeFittingCenterLine,
RC.CableTrayCenterLine,
RC.ConduitCenterLine,
RC.CableTrayFittingCenterLine,
RC.ConduitFittingCenterLine,
RC.FabricationDuctworkCenterLine,
RC.FabricationPipeworkCenterLine,
RC.FabricationContainmentCenterLine]
));

const Timeline = Object.freeze({
  Aggregates: {
    MINIMUM: 'MINIMUM',
    AVERAGE: 'AVERAGE',
    MAXIMUM: 'MAXIMUM'
  },
  // Time intervals for the telemetry window.
  Intervals: {
    LAST_3_DAYS: 'LAST_3_DAYS',
    LAST_7_DAYS: 'LAST_7_DAYS',
    CUSTOM: 'CUSTOM'
  },
  // Symbolic representation of the current time.
  Now: 'dynamic_now'
});

export let DtConstants = {
  getForgeUnits,
  getForgeUnitSpecs,
  getInterestingTracingCats,
  getRevitCategories,
  getParameterSetsLibrary,
  getParameterLibrary,
  getFacilityTemplatesLibrary,
  getClassificationsLibrary,
  getClassification,
  getRevitCategoryToDisciplineMapping,
  getSystemClassNames,
  matchClassification,
  checkClassificationSystem,
  QC,
  QCOverrides,
  ColumnFamilies,
  ColumnNames,
  ElementFlags,
  StandardAttributes,
  FamilyAttributes,
  StreamsImportExportTableHeader,
  HiddenRevitCategories,
  ModelImportState,
  MutationActions,
  UNIFORMAT_UUID,
  MASTERFORMAT_UUID,
  DtAccessLevel,
  DT_MODEL_URN_PREFIX,
  DT_TWIN_URN_PREFIX,
  DT_USER_URN_PREFIX,
  DT_GROUP_URN_PREFIX,
  DT_DOCUMENT_URN_PREFIX,
  DT_URN_PREFIX,
  ChangeTypes,
  RC,
  AttributeFlags,
  AttributeType,
  AttributeContext,
  SystemFilterNames,
  SystemElementFirstKey,
  SystemState,
  CenterlineCatIDs,
  StreamStates,
  StreamStatePriority,
  Timeline
};