import { UniformBuffer } from "../UniformBuffer";


export class SAOUniforms extends UniformBuffer {

  constructor(device) {
    super(device, 48, true, false);
  }

  setCamera(camera, w, h) {

    let near = camera.near;
    let far = camera.far;

    this.setFloat(5, near);
    this.setFloat(6, far);
    //_saoMipFirstPass.uniforms['cameraNear'].value = near;
    //_saoMipFirstPass.uniforms['cameraInvNearFar'].value = 1.0 / (near - far);

    let P = camera.projectionMatrix.elements;

    //Note that we flip Y here to take into account that sampling
    //from a render target on WebGPU requires the flipped Y, compared
    //to the same shader in WebGL.
    if (camera.isPerspective) {
      // vec4(-2.0f / (width*P[0][0]),
      //-2.0f / (height*P[1][1]),
      //( 1.0f - P[0][2]) / P[0][0],
      //( 1.0f + P[1][2]) / P[1][1])
      this.setFloat4(0,
      -2.0 / (w * P[0]),
      2.0 / (h * P[5]),
      (1.0 - P[8]) / P[0],
      -(1.0 + P[9]) / P[5]);
    } else {
      this.setFloat4(0,
      -2.0 / (w * P[0]),
      2.0 / (h * P[5]),
      (1.0 - P[12]) / P[0],
      -(1.0 - P[13]) / P[5]);
    }

    let isOrtho = camera.isPerspective ? 0 : 1;
    this.setInt(11, isOrtho);

    //Scaling factor needed to increase contrast of our SSAO.
    let hack_scale = 0.25;
    let projScale = hack_scale * 0.5 * (h * P[5]);
    this.setFloat(4, projScale);
  }

  setAOOptions(radius, bias, intensity) {
    if (radius !== undefined) {
      this.setFloat(8, radius);
    }

    if (bias !== undefined) {
      this.setFloat(9, bias);
    }

    if (intensity !== undefined) {
      this.setFloat(10, intensity);
    }
  }

}