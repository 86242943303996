import { FacetRegistry, FacetTypes } from "../facets/Facets";
import { ElementFlags } from "../schema/dt-schema";
import { MakeStringMatcher } from "./Search";

function getFacetNodeIds(data) {
  const ids = new Set();

  function traverse(items) {
    for (let item of items) {
      ids.add(item.id);
      if (item.children?.length > 0) {
        traverse(item.children);
      }
    }
  }

  traverse(data);

  return ids;
}

function _loadAndIsolate(facility, isolation) {
  // make sure the found elements are loaded before toggling their visibility
  for (let { model, ids } of isolation) {
    facility.facetsManager.setModelVisibility(model.urn(), true);
    model.loadElements(ids);
  }
  facility.viewer.impl.visibilityManager.aggregateIsolate(isolation);
}

function addElementIsolationToResults(facility, isolation, results) {
  const count = isolation.reduce((c, i) => c + i.ids.length, 0);
  if (count > 0) {
    results.push({
      id: 'isolate-in-facility',
      label: `Isolate ${count} elements in facility`,
      data: {
        count
      },
      type: 'Elements',
      scope: 'Facility',
      run: () => {
        facility.facetsManager.setFilterMap({});
        _loadAndIsolate(facility, isolation);
      }
    });

    const viewIsolation = isolation.map((_ref) => {let { model, ids } = _ref;return {
        model,
        ids: ids.filter((id) => model.visibilityManager?.isNodeVisible(id))
      };}).filter((_ref2) => {let { ids } = _ref2;return ids.length > 0;});

    const inViewCount = viewIsolation.reduce((c, i) => c + i.ids.length, 0);
    if (inViewCount > 0) {
      results.push({
        id: 'isolate-in-view',
        label: `Isolate ${inViewCount} elements in view`,
        data: {
          count: inViewCount
        },
        type: 'Elements',
        scope: 'View',
        run: () => {
          _loadAndIsolate(facility, viewIsolation);
        }
      });
    }
  }
}

export async function searchFacility(facility, term) {let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const models = facility.facetsManager.models;

  const allFacetDefs = facility.facetsManager.getFacetDefs();
  const [modelFacet, ...facetDefs] = allFacetDefs;

  const results = [];
  options.logVerbose && console.time("Searching");

  const matcher = MakeStringMatcher(term);
  const isMatch = (text) => matcher(typeof text == "string" ? text : String(text));

  // search facets and elements
  {
    const isolation = [];
    const filterMap = facility.facetsManager.getFilterMap();

    const facets = facility.facetsManager.getFacets();
    const facetsInView = allFacetDefs.reduce((inView, def, i) => {
      inView[def.id] = getFacetNodeIds(facets[i]);
      return inView;
    }, {});

    const allModels = {
      [modelFacet.id]: models.map((m) => m.urn())
    };

    const foundFacets = new Set();

    await Promise.all(models.map((model) => {
      const searchModel = async () => {
        const idSet = new Set();

        const traverse = (facets, depth) => {
          const def = facetDefs[depth];
          const inViewBaseFilter = {};
          for (let h = 0; h <= depth; h++) {
            const facetId = allFacetDefs[h].id;
            inViewBaseFilter[facetId] = filterMap[facetId];
          }

          for (const facetId in facets) {
            const facet = facets[facetId];
            const id = facet.id; // note, facetId is always a string, while this might be e.g. numeric 
            let label = facet.label || facet.id;
            if (isMatch(label) || facet.description && isMatch(facet.description)) {
              facet.ids?.forEach((id) => idSet.add(id));
              facet.idsInMultipleFacets?.forEach((id) => idSet.add(id));

              const resultId = `${def.id}_${id}`;
              if (!foundFacets.has(resultId)) {
                foundFacets.add(resultId);
                // include the facet's description in the label
                if (facet.description) {
                  label += ` (${facet.description})`;
                }
                // test if facet is in view
                if (facetsInView[def.id]?.has(id)) {
                  results.push({
                    id: 'facet-in-view-' + resultId,
                    label,
                    data: {
                      id,
                      facetDefId: def.id,
                      facetDefLabel: def.label
                    },
                    type: `Facets`,
                    scope: 'View',
                    run: () => {
                      facility.facetsManager.setFilterMap({
                        ...inViewBaseFilter,
                        [def.id]: [id]
                      });
                    }
                  });
                }

                results.push({
                  id: 'facet-' + resultId,
                  label,
                  data: {
                    id,
                    facetDefId: def.id,
                    facetDefLabel: def.label
                  },
                  type: `Facets`,
                  scope: 'Facility',
                  run: () => {
                    facility.facetsManager.setFilterMap({
                      ...allModels,
                      [def.id]: [id]
                    });
                  }
                });
              }
            }

            if (facet.children) {
              traverse(facet.children, depth + 1);
            }
          }
        };
        traverse(model.modelFacets.children, 0);

        // loop over node names too
        const { dbId2flags, dbId2ftypeId } = model.getData();
        const it = model.getInstanceTree();
        const getName = (id) => it.getNodeName(id);

        for (let dbId = 0; dbId < model.getElementCount(); ++dbId) {

          const flags = dbId2flags[dbId];
          if ((flags & ElementFlags.AllLogicalMask) !== 0 && flags !== ElementFlags.Level && flags !== ElementFlags.Stream && flags !== ElementFlags.GenericAsset) {
            continue;
          }

          if (isMatch(getName(dbId))) {
            idSet.add(dbId);
          }
        }

        // do deep search (optionally)
        if (options.deepSearch) {
          const ftypeIds = new Set();
          const ids = await model.search({ text: term, displayUnit: options.displayUnit });
          ids.forEach((id) => {
            if (dbId2flags[id] === ElementFlags.FamilyType) {
              // Element matching search is a "type" element which does not have geometry.
              // Isolation result should consist of elements with geometry.
              // Collect this parent element so we can add the children:
              ftypeIds.add(id);
            } else {
              idSet.add(id);
            }
          });

          if (ftypeIds.size) {
            // Add child elements of collected parent "type" elements:
            for (let childDbId = 0; childDbId < dbId2ftypeId.length; ++childDbId) {
              const parentDbId = dbId2ftypeId[childDbId];
              if (ftypeIds.has(parentDbId)) {
                idSet.add(childDbId);
              }
            }
          }
        }

        if (idSet.size) {
          isolation.push({
            model,
            ids: Array.from(idSet)
          });
        }
      };
      return searchModel();
    }));

    if (isolation.length) {
      addElementIsolationToResults(facility, isolation, results);
    }
  }

  // search views
  {
    const views = await facility.getSavedViewsList();
    for (let view of views) {
      if (isMatch(view.viewName)) {
        results.push({
          id: "view-" + view.id,
          label: view.viewName,
          data: {
            view
          },
          type: 'Views',
          run: () => facility.goToView(view)
        });
      }
      if (view.dashboardName && isMatch(view.dashboardName)) {
        results.push({
          id: "dashboard-" + view.id,
          label: view.dashboardName,
          data: {
            view
          },
          type: 'Dashboards'
        });
      }
    }
  }

  // search documents
  {
    facility.settings?.docs?.forEach((doc) => {
      if (isMatch(doc.name)) {
        results.push({
          id: "doc-" + doc.id,
          label: doc.name,
          data: {
            doc
          },
          type: 'Docs'
        });
      }
    });
  }

  // search standard facets
  {
    for (const facetId in FacetRegistry) {
      if (facetId === FacetTypes.attributes) {
        continue;
      }

      // TODO: instantiating a facet just to get the label, need a refactor
      const facet = new FacetRegistry[facetId]();
      if (isMatch(facet.label)) {
        results.push({
          id: "standard-attr-" + facetId,
          label: facet.label,
          data: {
            facetId
          },
          type: "StandardAttribute",
          run: async () => facility.facetsManager.addFacetDefType(facetId)
        });
      }
    }
  }

  // search attrs
  {
    const seenHashes = new Set();
    const models = facility.facetsManager.models;
    for (let model of models) {
      const attrs = await model.getAttributes({ source: true, native: true });
      for (let attr of attrs) {
        if (seenHashes.has(attr.hash)) {
          continue;
        }
        seenHashes.add(attr.hash);
        if (isMatch(attr.name)) {
          results.push({
            id: "attr-" + attr.hash,
            label: `${attr.name} (${attr.category})`,
            data: {
              attr
            },
            type: 'Attribute',
            run: async () => {
              // TODO: it's way too difficult to add a facet
              const fm = facility.facetsManager;
              const id = FacetTypes.attributes + "_" + attr.hash;
              if (fm.findFacetDef(id)) return;
              const defs = await fm.addFacetDef({
                attributeHash: attr.hash,
                id
              });
              const settings = defs.map((d) => d.getSettings());
              await fm.setSettings(settings, fm.hiddenCategories);
            }
          });
        }
      }
    }
  }

  options.logVerbose && console.timeEnd("Searching");
  return {
    results,
    matcher
  };
}