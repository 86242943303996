
import { getParameterByName, getScript } from "./globals";
import { logger } from "./logger/Logger";
import { endpoint, setEnv, getEnv, setOfflineResourcePrefix, setOffline } from "./net/endpoints";
import { getGlobal, isNodeJS, disableDocumentTouchSafari } from "./compat";
import { ViewingService } from "./net/Xhr";
import { initializeLocalization } from "./globalization/i18init";
import { initDtWorkerScript } from "./dt/DtWorkerCreator";

var global = getGlobal();
const _window = global;

var _token = {
  accessToken: null,
  getAccessToken: null,
  tokenRefreshInterval: null
};

export let token = _token;

let WEBGL_HELP_LINK = null;
/**
 * @private
 */
export function getWebGLHelpLink() {
  return WEBGL_HELP_LINK;
}

var currentHost = "";
var dsProxyPort = 3000;
var currentDomain = "";
if (getGlobal().location) {
  var location = getGlobal().location;
  currentDomain = location.protocol + "//" + location.hostname;
  currentHost = location.protocol + "//" + location.host;
}

var DtApiUrls = {
  dev: "https://dtw-stg.autodesk.com/api/v1",
  stg: "https://tandem-stg.autodesk.com/api/v1",
  prod: "https://tandem.autodesk.com/api/v1"
};

export let EnvironmentConfigurations = {
  Local: {
    ROOT: ''
  },
  Test: {
    ROOT: `${currentDomain}:${dsProxyPort}`
  },
  DtDev: {
    ROOT: DtApiUrls["dev"]
  },
  DtStaging: {
    ROOT: DtApiUrls["stg"]
  },
  DtProduction: {
    ROOT: DtApiUrls["prod"]
  },
  DtProductionAWS: {
    ROOT: "https://prod.constructwin.com/api/v1"
  }
};

/**
 * @param {object} options - Configurations for environment
 * @private
 */
export function initializeEnvironmentVariable(options) {
  var env;

  // Use the enviroment that was explicitly specified.
  //
  if (options && options.env) {
    env = options.env;
  }

  // If not available, check if the environment was specified in the query parameters.
  //
  if (!env) {
    env = getParameterByName("env");
  }

  setOffline(options && options.offline === "true");

  // If still not available, try to resolve the environment based on the url.
  //
  if (!env) {
    switch (_window.location.hostname) {
      case "localhost.autodesk.com":
        env = 'Local';
        break;
      case "": // IP addresses on Chrome.
        env = 'Local';
        break;
      case "127.0.0.1":
        env = 'Local';
        break;
      default:
        env = 'DtProduction';
    }
  }

  setEnv(env);

  if (typeof window !== "undefined") {
    logger.info("Host name : " + window.location.hostname);
  }
  logger.info("Environment initialized as : " + env);
}

/**
 * @param {object} options - Initialization options
 * @private
 */
export function initializeResourceRoot(options) {
  //Derive the root for static viewer resources based on the
  //location of the main viewer script
  var libList = [
  "viewer3D.js",
  "viewer3D.min.js"];

  if (options?.libraryName)
  libList.push(options.libraryName);

  var root;
  var scriptUrl;
  var hasVersionInURL = false;

  // TODO_NOP: this doesn't work for Polymer / Web Components
  for (let i = 0; i < libList.length; i++) {
    var script = getScript(libList[i]);
    scriptUrl = script ? script.src : "";
    var idx = scriptUrl.indexOf(libList[i]);
    if (idx >= 0) {
      root = scriptUrl.substr(0, idx);
      if (scriptUrl.indexOf('&v=', idx) > 0 || scriptUrl.indexOf('?v=', idx) > 0) {
        hasVersionInURL = true;
      }
      break;
    }
  }

  global.LMV_RESOURCE_ROOT = root || global.LMV_RESOURCE_ROOT;

  // Transfer version from URL-param into viewer/path/ now that Forge supports it.
  if (hasVersionInURL) {

    // However, only do this when the viewer code is being downloaded from Forge CDN
    var patchResourceRoot = false;
    var checkEnvs = ['dev', 'stg', 'prod'];
    for (let i = 0; i < checkEnvs.length; ++i) {
      var theEnv = DevApiUrls[checkEnvs[i]];
      if (global.LMV_RESOURCE_ROOT.indexOf(theEnv) !== -1) {
        patchResourceRoot = true;
        break;
      }
    }

    if (patchResourceRoot) {
      // Embed the version into the base URL.
      global.LMV_RESOURCE_ROOT += global.LMV_VIEWER_VERSION + '/';
    }
  }
}

/**
 * @param {object} options - Initialization options
 * @private
 */
export function initializeServiceEndPoints(options) {

  // Get endpoint.
  var endp = options.endpoint;
  if (!endp) {
    var config = EnvironmentConfigurations[getEnv()];
    endp = config['ROOT'];
  }

  endpoint.setEndpointAndApi(endp, options.api);

  if (isNodeJS())
  return; // No need for Promise

  initializeResourceRoot(options);

  return Promise.resolve();
}


//By now, the use of cookie is Fluent-specific only
/**
 * @param {string} token - Token to update
 * @param {Function} onSuccess - Callback on success case
 * @param {Function} onError - Callback on error case
 * @private
 */
export function refreshCookie(token, onSuccess, onError) {

  // rawGet doesn't accept undefined onSuccess callbacks
  onSuccess = onSuccess || function () {};
  ViewingService.rawGet(endpoint.getApiEndpoint(), null, "/auth/settoken", onSuccess, onError,
  {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    withCredentials: true,
    postData: "access-token=" + token
  }
  );
}

// Refresh the token in request header, in case that the third party cookie is disabled
/**
 * @param {string} token - Token to update
 * @private
 */
export function refreshRequestHeader(token) {
  if (token) {
    endpoint.HTTP_REQUEST_HEADERS["Authorization"] = "Bearer " + token;
  } else {
    delete endpoint.HTTP_REQUEST_HEADERS["Authorization"];
  }
}

/**
 * @param {string} in_tokenStr - Token to update
 * @param {Function} onSuccess - Callback on success case
 * @param {Function} onError - Callback on error case
 * @private
 */
export function refreshToken(in_tokenStr, onSuccess, onError) {

  // Store the token, it will be used when third-party cookies are disabled
  _token.accessToken = in_tokenStr;

  //TODO: Fluent. Currently we need to use the cookie based approach,
  //until the server is fixed to respond to CORS pre-flight requests
  //with Authorization header.
  // At the beginning, try to store the token in cookie
  if (endpoint.getUseCookie()) {

    var wrapError = function (e) {
      logger.warn("Failed to set token in cookie. Will use header instead.");
      endpoint.setUseCookie(false);
      refreshRequestHeader(in_tokenStr);
      onError && onError(e);
    };

    refreshCookie(in_tokenStr, onSuccess, wrapError);
  } else {
    refreshRequestHeader(in_tokenStr);
    onSuccess && onSuccess();
  }
}

/**
 * @param {object} options - Initialization options
 * @private
 */
function initializeAuthPromise(options) {
  return new Promise((resolve) => {
    initializeAuth(resolve, options);
  });
}

/**
 * @param {Function} onSuccessCallback - Callback on success case
 * @param {object} options - Initialization options
 * @private
 */
export function initializeAuth(onSuccessCallback, options) {

  var shouldInitializeAuth = options ? options.shouldInitializeAuth : undefined;
  if (shouldInitializeAuth === undefined) {
    var p = getParameterByName("auth");
    shouldInitializeAuth = p.toLowerCase() !== "false";
  }

  if (!shouldInitializeAuth) {
    refreshRequestHeader(null);
  }

  //Skip Auth in case we are serving the viewer locally
  if (getEnv() == "Local" || !shouldInitializeAuth) {
    setTimeout(onSuccessCallback, 0);
    endpoint.setUseCredentials(typeof options.useCredentials !== "undefined" ? options.useCredentials : false);
    return;
  }

  endpoint.setUseCredentials(typeof options.useCredentials !== "undefined" ? options.useCredentials : true);
  endpoint.setUseCookie(options.useCookie);

  //Must zero this out every time the initializer is called -- which could happen
  //several times with the same globally loaded LMV script/module
  if (_token.tokenRefreshInterval) {
    clearTimeout(_token.tokenRefreshInterval);
    _token.tokenRefreshInterval = null;
  }

  var accessToken;

  /**
   * @param {string} token - Access token value
   * @param {number|string} expire - Expire time in seconds, or an ISOstring containing an expiration date.
   *                                 Default is 3599 seconds.
   */
  function onGetAccessToken(token) {let expire = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3599;
    accessToken = token;

    var isFirstTimeInit = !_token.tokenRefreshInterval;

    if (!isFirstTimeInit) {
      refreshToken(accessToken);
    } else {
      //The goal of onSuccessCallback is to continue viewer initialization.
      //In case refreshToken fails, we don't really want to block viewer initialization
      //(in order to be able to display error messages later on, or retry the token refresh, etc.)
      //This is why we use the same callback regardless of result.
      refreshToken(accessToken, onSuccessCallback, onSuccessCallback);
    }

    if (typeof expire !== "number") {
      const expireDate = new Date(expire);
      // Based on https://esganzerla.medium.com/simple-date-validation-with-javascript-caea0f71883c
      const isValidDate = Boolean(+expireDate) && expireDate.toISOString() === expire;
      if (isValidDate) {
        expire = (expireDate - Date.now()) / 1000; // Date is in ms so convert to seconds
      } else {
        expire = 3599; // Default of ~1 hour
      }
    }

    var tokenExpirationBuffer = options?.tokenExpirationBuffer || 5; // Refresh 5 seconds before token expire.
    var interval = expire - tokenExpirationBuffer;
    if (interval <= 0) {
      // We can't get a precise upper bound if the token is such a short lived one (expire in a minute),
      // so just use the original one.
      interval = expire;
    }

    _token.tokenRefreshInterval = setTimeout(function () {options.getAccessToken(onGetAccessToken);}, interval * 1000);
  }

  if (options && options.getAccessToken) {
    _token.getAccessToken = options.getAccessToken;

    accessToken = options.getAccessToken(onGetAccessToken);

    //Backwards compatibility with the old synchronous API
    if (typeof accessToken === "string" && accessToken) {
      refreshToken(accessToken, onSuccessCallback);
    }

  } else if (options && options.accessToken) {
    accessToken = options.accessToken;
    refreshToken(accessToken, onSuccessCallback);
  } else {
    accessToken = getParameterByName("accessToken");
    if (!accessToken) {
      logger.error("No access token is provided, but authorization requested. This is a problem.");
    }
    refreshToken(accessToken, onSuccessCallback);
  }

}

/**
 * @param {object} options - Initialization options
 * @private
 */
export function initializeLogger(options) {

  logger.initialize(options);

  var logLevel = getParameterByName("logLevel");
  if (logLevel) {
    logger.setLevel(parseInt(logLevel));
  }
}

/**
 * Static method for initializing the viewer runtime. Models must be loaded after this
 * step is completed.
 *
 * Includes:
 *  - End points of cloud services the viewer uses, like Model Derivative
 *  - Authentication and authorization cookie settings on the client side
 *  - Misc runtime environment variables and global viewer configurations parameters
 *
 * @alias Autodesk.Viewing#Initializer
 *
 * @param {object} options - The options object contains configuration parameters used to do initializations. If no
 * access token or authentication callback is provided, the Initializer will fall back
 * on an access token provided in the URL query string, or a previous access token stored in
 * the cookie cache, if available.
 * @param {string} [options.env] - Either "AutodeskProduction", "AutodeskStaging", or "AutodeskDevelopment".
 * "Local" can be used to avoid attaching athentication headers to XHR requests.
 * @param {string} [options.api] - "modelDerivativeV2" or "derivativeV2" for US data center,
 * or use "derivativeV2_EU" for European data center.
 * @param {Function} [options.getAccessToken] - An function that provides an access token asynchronously.
 * The function signature is `getAccessToken(onSuccess)`, where onSuccess is a callback that getAccessToken
 * function should invoke when a token is granted, with the token being the first input parameter for the
 * onSuccess function, and the token expire time (in seconds) being the second input parameter for the
 * function. Viewer relies on both getAccessToken and the expire time to automatically renew token, so
 * it is critical that getAccessToken must be implemented as described here.
 * @param {string} [options.accessToken] - An access token. Not needed when `options.getAccessToken` is provided.`
 * @param {string} [options.webGLHelpLink] - A link to a help page on webGL if it's disabled.
 * @param {string} [options.language] - Preferred language code as defined in RFC 4646, such as "en", "de", "fr", etc.
 * If no language is set, viewer will pick it up from the browser. If language is not as defined in RFC,
 * viewer will fall back to "en" (English) but the behavior is undefined.
 * @param {Function} callback - A method the client executes when initialization is finished.
 * @param {boolean} [options.corsWorker] - Set to true when the viewer is loaded from a third party URL
 *
 * @example
 *  var options = {
 *     env: "AutodeskProduction",
 *     language: "en",
 *     getAccessToken: function(onSuccess) {
 *         // TODO: Get actual forge token and expiration time.
 *         var accessToken = 'your_access_token';
 *         var expirationTimeSeconds = 5 * 60; // 5 minutes
 *         onSuccess(accessToken, expirationTimeSeconds);
 *     }
 *  };
 *  var myCallback = function() {
 *     console.log("initialization complete, creating the viewer...");
 *  };
 *  Autodesk.Viewing.Initializer(options, myCallback);
 *
 */
export function Initializer(options, callback) {

  if (isNodeJS()) {

    initializeEnvironmentVariable(options);
    initializeServiceEndPoints(options);
    initializeLogger(options);
    initializeAuth(callback, options);
    return;
  }

  // Web //
  if (options.webGLHelpLink)
  WEBGL_HELP_LINK = options.webGLHelpLink;

  initializeEnvironmentVariable(options);
  var apiProm = initializeServiceEndPoints(options);
  initializeLogger(options);
  initializeLocalization(options);
  disableDocumentTouchSafari();

  //Kick off a request for the web worker script in case it needs to be loaded
  //manually because the viewer is served from a URL that is not the same as the
  //web page it's embedded in.
  let workerProm;
  if (options.corsWorker) {
    Autodesk.Viewing.Private.ENABLE_INLINE_WORKER = true;
    workerProm = new Promise((resolve, reject) => {
      initDtWorkerScript(resolve, reject);
    });
  }

  var authProm = initializeAuthPromise(options);

  Promise.all([apiProm, authProm, workerProm]).then(callback);
}

/**
 * Counterpart to {@link Autodesk.Viewing.Initializer}, use it to free up memory.
 * Developers need to uninitialize all {@link Autodesk.Viewing.Viewer3D} instances before invoking this function.
 *
 * @alias Autodesk.Viewing#shutdown
 */
export function shutdown() {

  logger.shutdown();

  Autodesk.Viewing.Private.shutdownPropWorker();

}