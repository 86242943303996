import * as THREE from 'three';

import { Label3D } from '../../../../gui/Label3D';
import { getLevelElevationByName } from './LevelUtils';
import { LABEL_ANCHORING } from '../../HudLabel';
import { enumMeshVertices } from '../../../../wgs/scene/VertexEnumerator';

const _box2 = new THREE.Box2();
const _box3 = new THREE.Box3();
const _vec2 = new THREE.Vector2();

export function guessPlacement(facility, levels, modelRoomsPerLevel, useCacheOnly) {
  const placements = guessPlacementByFloorMesh(facility, levels, useCacheOnly);
  if (placements.size === 0 && levels.length !== 0 && modelRoomsPerLevel.size > 0) {
    // Fallback to level by room mesh.
    return guessPlacementByRoomMesh(facility, levels, modelRoomsPerLevel);
  }
  return placements;
}

const LABEL_HEIGHT_PX = 18; // match css
const LABEL_FONT = '800 17px ArtifaktElement'; // match css

export class LevelLabelPlacer {
  boxes = [];
  layer;
  measureText;

  constructor(levelsLayer) {
    this.layer = levelsLayer;

    Label3D.configureTextMeasurement(levelsLayer.viewer, { font: LABEL_FONT });
    this.measureText = (txt) => Label3D.getTextMeasurements(levelsLayer.viewer, txt);

    // TODO identify which levels might be in which building? to allow per building anchoring.
  }

  getBoundingBox(text, pos3D, anchor, box2) {
    const textWidthPx = this.measureText(text.toUpperCase()).width;
    const { x, y } = this.layer.viewer.impl.worldToClient(pos3D);

    box2.makeEmpty();

    // Point close to the building.
    _vec2.x = x;
    _vec2.y = y + LABEL_HEIGHT_PX * 0.5;
    box2.expandByPoint(_vec2);

    // Point away from the building.
    const deltaX = anchor === LABEL_ANCHORING.START ? textWidthPx : anchor === LABEL_ANCHORING.END ? -textWidthPx : 0;
    _vec2.x += deltaX;
    _vec2.y -= LABEL_HEIGHT_PX;
    box2.expandByPoint(_vec2);

    return box2;
  }

  tryToPlace(placement, text) {
    if (!placement) return [];

    const { left } = placement;

    // Only care about left of the building for now.
    const pos3D = left;
    const anchor = LABEL_ANCHORING.END;
    const box2 = this.getBoundingBox(text, pos3D, anchor, _box2);

    // Overlap with previously set label.
    for (const existing of this.boxes) {
      if (existing.isIntersectionBox(box2)) {
        // Found a collision
        return [];
      }
    }

    this.boxes.push(box2.clone());
    return [pos3D, LABEL_ANCHORING.END];
  }
}

function guessPlacementByRoomMesh(facility, levels, modelRoomsPerLevel) {
  const viewerImpl = facility.viewer.impl;
  // To get mid-point of most common min and max Z.
  const minZ = new ModeAccumulator();
  const maxZ = new ModeAccumulator();
  // To get left and right most room mesh vertex based on screen space location
  let minX = Infinity,minVal;
  let maxX = -Infinity,maxVal;
  let matrixWorld;

  function addMeshVertex(vertex) {
    vertex.applyMatrix4(matrixWorld);
    let { x } = viewerImpl.worldToClient(vertex);
    if (x < minX) {
      minX = x;
      minVal = vertex.clone();
    }
    if (x > maxX) {
      maxX = x;
      maxVal = vertex.clone();
    }
  }

  const placementById = new Map();
  for (const level of levels) {
    const modelRooms = modelRoomsPerLevel[level.id];
    if (!modelRooms) continue;

    for (const modelUrn in modelRooms) {
      const model = facility.getModelByUrn(modelUrn);
      if (!model) continue;

      const it = model.getInstanceTree();
      const fl = model.getFragmentList();
      for (const dbId of modelRooms[modelUrn]) {
        // Log room BB
        model.getElementBounds(dbId, _box3);
        minZ.add(_box3.min.z);
        maxZ.add(_box3.max.z);

        // Log screen left and right most points in room meshes.
        it.enumNodeFragments(
          dbId,
          (fragId) => {
            const mesh = fl.getVizmesh(fragId);
            if (!mesh.geometry) {
              return;
            }

            matrixWorld = mesh.matrixWorld;
            enumMeshVertices(mesh.geometry, addMeshVertex);
          },
          true);
      }
    }

    if (minZ.size === 0 || !minVal) {
      // This placement algorithm skips levels with no rooms.
      continue;
    }

    // Base label Z-height as the middle of the most common min and most common max Z.
    const midZ = minZ.mode + (maxZ.mode - minZ.mode) * 0.5;
    minZ.clear();
    maxZ.clear();

    // Provide both left & right-most vertex to let the caller choose.
    const left = new THREE.Vector3(minVal.x, minVal.y, midZ);
    const right = new THREE.Vector3(maxVal.x, maxVal.y, midZ);
    placementById.set(level.id, { left, right });

    minX = Infinity;
    maxX = -Infinity;
    minVal = maxVal = undefined;
  }

  return placementById;
}

/** Finds the left and right most point of the cached floor mesh based on screen coordinates. */
function guessPlacementByFloorMesh(facility, levels, useCacheOnly) {
  const placementById = new Map();
  if (levels.length === 0) {
    return placementById;
  }

  // Gather meshes first as it allows short-circuiting the rest if no geometry is found.
  const meshes = [];
  let someHaveGeometry = false;
  const { facetsEffects } = facility.facetsManager;
  for (let i = 0; i < levels.length; i++) {
    const node = levels[i];
    // use cache only is needed as getFloorMesh currently erroneously retries ad infinitum on some models.
    meshes[i] = useCacheOnly ? facetsEffects._floorHighlightCache[node.id]?.mesh : facetsEffects.getFloorMesh(node);
    if (meshes[i]?.geometry) someHaveGeometry = true;
  }

  // Restricting to levels with a mesh that has geometry.
  if (!someHaveGeometry) {
    return new Map();
  }

  // Some level have geometry get the necessary data in place
  const viewerImpl = facility.viewer.impl;
  let minX = Infinity,minVal = new THREE.Vector3();
  let maxX = -Infinity,maxVal = new THREE.Vector3();
  let minZ = Infinity;
  let maxZ = -Infinity;

  const elevByName = getLevelElevationByName(facility);
  for (let i = 0; i < levels.length; i++) {
    const level = levels[i];
    const mesh = meshes[i];
    if (!mesh?.geometry) {
      continue;
    }

    enumMeshVertices(mesh.geometry, (v) => {
      const { x } = viewerImpl.worldToClient(v);

      // Find left and right
      if (x < minX) {
        minX = x;
        minVal.copy(v);
      }
      if (x > maxX) {
        maxX = x;
        maxVal.copy(v);
      }

      // Find top and bottom
      if (v.z > maxZ) maxZ = v.z;
      if (v.z < minZ) minZ = v.z;
    }, mesh.matrix /* Manually set and does not auto-update */);

    // Using AEC elevation Z or defaults to half mesh.
    const z = elevByName?.[level.id] ?? minZ + (maxZ - minZ) * 0.5;

    // Provide both left & right-most vertex to let the caller choose.
    const left = new THREE.Vector3(minVal.x, minVal.y, z);
    const right = new THREE.Vector3(maxVal.x, maxVal.y, z);
    placementById.set(level.id, { left, right });

    minX = minZ = Infinity;
    maxX = maxZ = -Infinity;
  }

  return placementById;
}

/** Accumulator to get the mode of a collection. */
class ModeAccumulator {
  #dict = new Map();
  mode;
  size = 0;

  add(value) {
    const count = (this.#dict.get(value) ?? 0) + 1;
    if (count > this.size) {
      this.mode = value;
      this.size = count;
    }

    this.#dict.set(value, count);
  }

  clear() {
    this.#dict.clear();
    this.mode = undefined;
    this.size = 0;
  }
}