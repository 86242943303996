// Obtained from Figma directly
export const HeatmapIcon = `<svg width="24" height="24" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke-width="0">
	<circle cx="3.27719" cy="3.52523" r="2.64706"/>
	<circle cx="12.4792" cy="13.7353" r="1.76471"/>
	<circle cx="11.5967" cy="5.79411" r="3.40336"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M7.56302 12.3488C7.56302 14.4372 5.86998 16.1303 3.78151 16.1303C1.69304 16.1303 0 14.4372 0 12.3488C0 10.2603 1.69304 8.56726 3.78151 8.56726C5.86998 8.56726 7.56302 10.2603 7.56302 12.3488ZM1.26055 12.3488C1.26055 13.7411 2.38924 14.8698 3.78156 14.8698C5.17387 14.8698 6.30256 13.7411 6.30256 12.3488C6.30256 10.9565 5.17387 9.82776 3.78156 9.82776C2.38924 9.82776 1.26055 10.9565 1.26055 12.3488Z"/>
</svg>`;

export const PinIcon = `
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke-width="0">
        <path d="M12 13.4a3.7 3.4 0 1 1 3.7-3.4 3.7 3.4 0 0 1-3.7 3.4zM12 8a2.2
            2 0 1 0 2.2 2A2.2 2 0 0 0 12 8zm0-5.8A8.6 7.8 0 0 0 3.4 10v.1c0 1 0 3 2.6 6.1a50.3
            46 0 0 0 5.4 5.4.7.6 0 0 0 .4.2h.3a.7.6 0 0 0 .4-.1 50.3 46 0 0 0 5.5-5.5c2.6-3 2.6-5
            2.6-6V10A8.6 7.8 0 0 0 12 2.2zm7 8c0 .8 0 2.5-2.2 5.2a50.8 46.4 0 0 1-4.8 4.8 49.7
            45.4 0 0 1-4.8-4.9c-2.3-2.6-2.3-4.3-2.3-5.2a7 6.5 0 1 1 14.2 0z"/>
    </svg>`;

export const PulseIcon = `
	<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke-width="0">
		<path d="M15,20.25a.74.74,0,0,1-.69-.47L9,6.52,6.69,12.28a.75.75,0,0,1-.69.47H3a.75.75,0,0,1,0-1.5h2.5l2.81-7a.78.78,0,0,1,1.39,0L15,17.48l2.31-5.76a.75.75,0,0,1,.69-.47h3a.75.75,0,1,1,0,1.5h-2.5l-2.81,7A.74.74,0,0,1,15,20.25Z"/>
	</svg>`;

export const GearIcon = `
	<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
		<path d="M2,8.86s0,0,0-.08a.53.53,0,0,0,0,.13S2,9,2,9ZM21.74,14.8a3.24,3.24,0,0,1-1.43-3.39,3.2,3.2,0,0,1,1.43-2.05.75.75,0,0,0,.25-1L20.14,5.19a.75.75,0,0,0-1-.3,3.25,3.25,0,0,1-2,.24,3.21,3.21,0,0,1-2.54-2.92.75.75,0,0,0-.75-.7H10.17a.74.74,0,0,0-.75.69,2.44,2.44,0,0,1-.07.46A3.17,3.17,0,0,1,8,4.68a3.24,3.24,0,0,1-2.41.45,3.7,3.7,0,0,1-.71-.23.75.75,0,0,0-1,.29L2,8.33a.72.72,0,0,0-.1.38v.07s0,.06,0,.08A.5.5,0,0,0,2,9a.58.58,0,0,0,.14.24.61.61,0,0,0,.15.12l.14.1a3.18,3.18,0,0,1,1.31,3.3A3.15,3.15,0,0,1,2.28,14.8a.75.75,0,0,0-.25,1v0L3.87,19a.75.75,0,0,0,1,.29,3.26,3.26,0,0,1,2-.24,3.21,3.21,0,0,1,2.53,2.8.75.75,0,0,0,.74.67h3.71a.76.76,0,0,0,.75-.67c0-.11,0-.22.05-.33A3.22,3.22,0,0,1,18.46,19a4.56,4.56,0,0,1,.71.24.75.75,0,0,0,1-.3L22,15.81A.75.75,0,0,0,21.74,14.8Zm-2.58,2.87a2.79,2.79,0,0,0-.39-.1A4.7,4.7,0,0,0,13.24,21H10.78a4.68,4.68,0,0,0-3.59-3.43,4.57,4.57,0,0,0-2.35.1l-1.17-2A4.69,4.69,0,0,0,5.2,13.06,4.72,4.72,0,0,0,3.66,8.51l1.19-2,.39.09A4.69,4.69,0,0,0,10.81,3h2.38a4.71,4.71,0,0,0,3.63,3.58,4.64,4.64,0,0,0,2.34-.1l1.2,2a4.7,4.7,0,0,0,0,7.1ZM12,8a4.09,4.09,0,1,0,4.09,4.09A4.1,4.1,0,0,0,12,8Zm0,6.68a2.59,2.59,0,1,1,2.59-2.59A2.6,2.6,0,0,1,12,14.67ZM2.42,9.45a.38.38,0,0,1-.13-.06.71.71,0,0,1-.35-.48.53.53,0,0,1,0-.13s0,.06,0,.08L2,9H2a.58.58,0,0,0,.14.24.61.61,0,0,0,.15.12Z"/>
	</svg>
`;

export const OfflineIcon = `
	<svg width="20" height="20" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M30 16c0 7.732-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2s14 6.268 14 14Zm-5.254-8.616c.63.631.63 1.654 0 2.285L9.669 24.746a1.615 1.615 0 1 1-2.285-2.285L22.461 7.384a1.618 1.618 0 0 1 2.285 0Z"
			fill-rule="evenodd"
			clip-rule="evenodd"
		/>
	</svg>
`;

export const AlertIcon = `
	<svg width="20px" height="20px" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M29.7867 23.0135C29.16 21.8668 26.0667 16.4668 23.3467 11.6935C21.3334 8.17347 19.5467 5.0268 19.24 4.5068C19.0016 3.83835 18.5622 3.26003 17.9822 2.8511C17.4021 2.44217 16.7098 2.22266 16 2.22266C15.2903 2.22266 14.598 2.44217 14.0179 2.8511C13.4379 3.26003 12.9985 3.83835 12.76 4.5068C12.4534 5.0668 10.6667 8.20014 8.64005 11.7468C5.97338 16.4935 2.85338 21.8668 2.22671 23.0135C1.88993 23.6021 1.71277 24.2686 1.71277 24.9468C1.71277 25.625 1.88993 26.2915 2.22671 26.8801C2.52982 27.3809 2.95898 27.7935 3.47136 28.0766C3.98374 28.3597 4.56141 28.5034 5.14671 28.4935H26.92C27.5093 28.5054 28.0915 28.3628 28.6085 28.0797C29.1255 27.7966 29.5593 27.383 29.8667 26.8801C30.1888 26.2837 30.3508 25.614 30.3368 24.9362C30.3227 24.2585 30.1332 23.5961 29.7867 23.0135V23.0135ZM14.6667 10.4935C14.6667 10.1398 14.8072 9.80071 15.0572 9.55066C15.3073 9.30061 15.6464 9.16013 16 9.16013C16.3537 9.16013 16.6928 9.30061 16.9429 9.55066C17.1929 9.80071 17.3334 10.1398 17.3334 10.4935V17.8668C17.3334 18.2204 17.1929 18.5596 16.9429 18.8096C16.6928 19.0597 16.3537 19.2001 16 19.2001C15.6464 19.2001 15.3073 19.0597 15.0572 18.8096C14.8072 18.5596 14.6667 18.2204 14.6667 17.8668V10.4935ZM16 25.1601C15.6467 25.1601 15.3012 25.0553 15.0074 24.859C14.7136 24.6627 14.4846 24.3837 14.3494 24.0572C14.2142 23.7307 14.1788 23.3715 14.2477 23.0249C14.3167 22.6783 14.4868 22.36 14.7367 22.1101C14.9866 21.8602 15.3049 21.6901 15.6515 21.6211C15.9981 21.5522 16.3573 21.5876 16.6838 21.7228C17.0102 21.858 17.2893 22.087 17.4856 22.3808C17.6819 22.6747 17.7867 23.0201 17.7867 23.3735C17.7695 23.8357 17.5736 24.2732 17.2404 24.594C16.9072 24.9147 16.4626 25.0938 16 25.0935V25.1601Z" fill="currentColor"/>
	</svg>
`;

export const WarningIcon = `
	<svg width="20px" height="20px" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM17.75 21.25C17.75 22.2165 16.9665 23 16 23C15.0335 23 14.25 22.2165 14.25 21.25C14.25 20.2835 15.0335 19.5 16 19.5C16.9665 19.5 17.75 20.2835 17.75 21.25ZM17.3125 9C17.3125 8.27513 16.7249 7.6875 16 7.6875C15.2751 7.6875 14.6875 8.27513 14.6875 9V16C14.6875 16.7249 15.2751 17.3125 16 17.3125C16.7249 17.3125 17.3125 16.7249 17.3125 16V9Z" fill="currentColor"/>
	</svg>
`;

export const CheckmarkIcon = `
	<svg width="20px" height="20px" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M29 16C29 18.5712 28.2376 21.0846 26.8091 23.2224C25.3807 25.3603 23.3503 27.0265 20.9749 28.0104C18.5995 28.9944 15.9856 29.2518 13.4638 28.7502C10.9421 28.2486 8.6257 27.0105 6.80762 25.1924C4.98954 23.3743 3.75141 21.0579 3.2498 18.5362C2.74819 16.0144 3.00563 13.4006 3.98957 11.0251C4.97351 8.64968 6.63975 6.61935 8.77759 5.1909C10.9154 3.76244 13.4288 3 16 3C19.4467 3.00353 22.7513 4.3743 25.1885 6.81151C27.6257 9.24872 28.9965 12.5533 29 16ZM14.2 20.9467L22.4267 12.72C22.5516 12.596 22.6508 12.4486 22.7185 12.2861C22.7862 12.1236 22.8211 11.9493 22.8211 11.7733C22.8211 11.5973 22.7862 11.423 22.7185 11.2606C22.6508 11.0981 22.5516 10.9506 22.4267 10.8267C22.1769 10.5783 21.8389 10.4389 21.4867 10.4389C21.1344 10.4389 20.7965 10.5783 20.5467 10.8267L13.2533 18.12L11.4533 16.32C11.1983 16.1016 10.8702 15.9874 10.5346 16.0004C10.199 16.0133 9.88071 16.1525 9.64325 16.3899C9.4058 16.6274 9.26669 16.9457 9.25372 17.2813C9.24076 17.6168 9.3549 17.9449 9.57334 18.2L12.32 20.9467C12.5684 21.193 12.9036 21.3319 13.2533 21.3333C13.4288 21.3343 13.6028 21.3007 13.7652 21.2344C13.9277 21.168 14.0754 21.0702 14.2 20.9467Z" fill="currentColor"/>
	</svg>
`;