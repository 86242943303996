import { NumIdTargets } from "../CommonRenderTargets";
import { RenderBatch } from "../../scene/RenderBatch";
import { vectorToABGR } from "./ObjectUniforms";
import { UberPipeline } from "./UberPipeline";
import { EdgePipeline } from "./EdgePipeline";
import { LinePipeline } from "../2d/LinePipeline";
import { MaterialUniforms } from "./MaterialUniforms";
import { sceneToBatch } from "../sceneToBatch";
import { IBL } from "./IBL";
import { CameraUniforms } from "./CameraUniforms";
import { LineUniforms } from "../2d/LineUniforms";

// These settings are only used during pipeline creation. No actual material needed.
const edgeMaterialSettings = {
  depthTest: true,
  depthWrite: true,
  depthFunc: "less-equal"
};

const edgeHighlightMaterialUnder = {
  depthTest: true,
  depthWrite: false,
  depthFunc: "greater"
};

const edgeHighlightMaterialOver = {
  depthTest: true,
  depthWrite: false,
  depthFunc: "less-equal"
};

const ghostMaterialSettings = {
  depthTest: true,
  depthWrite: true,
  depthFunc: "less"
};

const EDGE_COLOR_DARK = { x: 0, y: 0, z: 0, w: 1.0 };
const EDGE_COLOR_DARK_GHOSTED = { x: 0, y: 0, z: 0, w: 0.15 };
const EDGE_COLOR_LIGHT_GHOSTED = { x: 0.75, y: 0.875, z: 1, w: 0.15 };
const EDGE_COLOR_HIGHLIGHT = { x: 1, y: 1, z: 1, w: 1 };
const EDGE_COLOR_HIGHLIGHT_UNDER = { x: 1, y: 1, z: 1, w: 0.5 };

/**
 * @param {Renderer} renderer
 */
export class MainPass {

  #renderer;
  #device;

  #mainPassDescriptorProg;#edgePassDescriptor;#clearPassDescriptor;
  #overlayPassDescriptorWithClear;#overlayPassDescriptorNoClear;
  #renderBundleDescriptor;#renderBundleEdgesDescriptor;

  #useRenderBundles;#renderBundle;

  #encoder;#count;

  #iblUniforms;

  //TODO: Investigate using a ring buffer here in case uploading
  //object uniforms for each render batch using the same buffer is considered a bottleneck
  #objectUniforms;#materialUniforms;#cameraUniforms;#lineUniforms;

  #mainPipeline;#edgePipeline;#overlayPipeline;#linePipeline;
  #linePipelineOverlays; // For overlays, we need a separate pipeline, because the Pipelines must be created for different target setups.

  #edgeColorMainInt = vectorToABGR(EDGE_COLOR_DARK);
  #edgeColorGhostedInt = vectorToABGR(EDGE_COLOR_LIGHT_GHOSTED);

  #bindGroupLayouts;#bindGroupLayouts2D;

  #geometriesList = [];
  #renderIndicesList = [];

  constructor(renderer) {
    this.#renderer = renderer;
    this.#iblUniforms = new IBL(renderer);
  }

  #createMainPass() {

    let rt = this.#renderer.getRenderTargets();
    let colorTargetView = rt.getColorTarget().createView();
    let depthTargetView = rt.getDepthTarget().createView();
    let normalsTargetView = rt.getNormalsTarget().createView();
    let viewDepthTargetView = rt.getViewDepthTarget().createView();
    let overlayTargetView = rt.getOverlayTarget().createView();

    this.#clearPassDescriptor = {
      colorAttachments: [
      {
        view: normalsTargetView,
        clearValue: { r: 0, g: 0, b: 0, a: 0 },
        loadOp: 'clear',
        storeOp: 'store'
      },
      {
        view: viewDepthTargetView,
        clearValue: { r: 0, g: 0, b: 0, a: 0 },
        loadOp: 'clear',
        storeOp: 'store'
      }],

      depthStencilAttachment: {
        view: depthTargetView,
        depthClearValue: 1.0,
        depthLoadOp: 'clear',
        depthStoreOp: 'store'
      }
    };

    this.#mainPassDescriptorProg = {
      colorAttachments: [
      {
        view: colorTargetView,
        loadOp: 'load',
        storeOp: 'store'
      },
      {
        view: normalsTargetView,
        loadOp: 'load',
        storeOp: 'store'
      },
      {
        view: viewDepthTargetView,
        loadOp: 'load',
        storeOp: 'store'
      }],

      depthStencilAttachment: {
        view: depthTargetView,
        depthLoadOp: 'load',
        depthStoreOp: 'store'
      }
    };

    this.#renderBundleDescriptor = {
      colorFormats: [
      rt.getColorTarget().format,
      rt.getNormalsTarget().format,
      rt.getViewDepthTarget().format],

      depthStencilFormat: [rt.getDepthTarget().format]
    };

    for (let i = 0; i < NumIdTargets; i++) {

      let attachment = {
        view: rt.getIdTarget(i).createView(),
        clearValue: { r: 0, g: 0, b: 0, a: 0 },
        loadOp: "clear",
        storeOp: "store"
      };

      let attachmentProg = {
        view: attachment.view,
        loadOp: "load",
        storeOp: "store"
      };

      let format = rt.getIdTarget(i).format;

      this.#clearPassDescriptor.colorAttachments.push(attachment);
      this.#mainPassDescriptorProg.colorAttachments.push(attachmentProg);
      this.#renderBundleDescriptor.colorFormats.push(format);
    }

    this.#renderBundleEdgesDescriptor = {
      colorFormats: [
      rt.getColorTarget().format],

      depthStencilFormat: [rt.getDepthTarget().format]
    };

    this.#edgePassDescriptor = {
      colorAttachments: [
      {
        view: colorTargetView,
        loadOp: 'load',
        storeOp: 'store'
      }],

      depthStencilAttachment: {
        view: depthTargetView,
        depthLoadOp: 'load',
        depthStoreOp: 'store'
      }
    };

    this.#overlayPassDescriptorWithClear = {
      colorAttachments: [
      {
        view: overlayTargetView,
        clearValue: { r: 0, g: 0, b: 0, a: 0 },
        loadOp: 'clear',
        storeOp: 'store'
      }]

    };

    this.#overlayPassDescriptorNoClear = {
      colorAttachments: [
      {
        view: overlayTargetView,
        loadOp: 'load',
        storeOp: 'store'
      }],

      depthStencilAttachment: {
        view: depthTargetView,
        depthLoadOp: 'load',
        depthStoreOp: 'store'
      }
    };

  }

  init(objectUniforms) {

    this.#device = this.#renderer.getDevice();

    this.#iblUniforms.init();
    this.#cameraUniforms = new CameraUniforms(this.#device);

    //TODO: Investigate using a ring buffer here in case uploading
    //object uniforms for each render batch using the same buffer is considered a bottleneck
    this.#objectUniforms = objectUniforms;
    this.#geometriesList.length = this.#objectUniforms.MAX_BATCH;
    this.#renderIndicesList.length = this.#objectUniforms.MAX_BATCH;

    this.#materialUniforms = new MaterialUniforms(this.#device, undefined, this.#renderer.getPlaceholderTexture());

    this.#lineUniforms = new LineUniforms(this.#device);

    this.#mainPipeline = new UberPipeline(this.#renderer);
    this.#edgePipeline = new EdgePipeline(this.#renderer);
    this.#linePipeline = new LinePipeline(this.#renderer);
    this.#linePipelineOverlays = new LinePipeline(this.#renderer);

    //The overlay pipeline uses a different set of render targets,
    //so we maintain a separate instance of the UberPipeline for it (since targets list is not taken into account
    //in pipeline cache keys)
    this.#overlayPipeline = new UberPipeline(this.#renderer);

    this.#bindGroupLayouts = [
    this.#iblUniforms.getLayout(),
    this.#cameraUniforms.getLayout(),
    this.#objectUniforms.getLayout(),
    this.#materialUniforms.getLayout()];


    this.#bindGroupLayouts2D = [
    this.#iblUniforms.getLayout(),
    this.#cameraUniforms.getLayout(),
    this.#objectUniforms.getLayout(),
    this.#lineUniforms.getLayout()];

  }

  resize(w, h) {

    let edgeOpacity = this.#renderer.getPixelRatio() > 1.5 ? 1.0 : 0.6;
    EDGE_COLOR_DARK.w = edgeOpacity;
    this.#edgeColorMainInt = vectorToABGR(EDGE_COLOR_DARK);

    this.#createMainPass(w, h);
    this.#lineUniforms.setTargetSize(w, h);
  }

  #clearTarget(descriptor) {
    let commandEncoder = this.#device.createCommandEncoder();
    let passEncoder = commandEncoder.beginRenderPass(descriptor);
    passEncoder.end();
    this.#device.queue.submit([commandEncoder.finish()]);
  }

  clearMainTargets() {
    this.#clearTarget(this.#clearPassDescriptor);
  }

  clearOverlayTargets() {
    this.#clearTarget(this.#overlayPassDescriptorWithClear);
  }

  setGhostingBrightness(darker) {
    if (darker) {
      this.#edgeColorGhostedInt = vectorToABGR(EDGE_COLOR_DARK_GHOSTED);
    } else {
      this.#edgeColorGhostedInt = vectorToABGR(EDGE_COLOR_LIGHT_GHOSTED);
    }
  }

  updatePixelScale(pixelsPerUnit, camera) {
    if (!this.#lineUniforms) return;
    this.#lineUniforms.updatePixelScale(pixelsPerUnit, camera);
    this.#lineUniforms.upload();
  }

  setLineStyleBuffer(buffer, width) {
    if (!this.#lineUniforms) return;
    this.#lineUniforms.setLineStyleBuffer(buffer, width);
    this.#lineUniforms.upload();
  }

  getIBL() {return this.#iblUniforms;}

  #beginRenderPass(commandEncoder, passDescriptor, renderBundle) {let modelId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : -1;let sceneStart = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
    let passEncoder = commandEncoder.beginRenderPass(passDescriptor);

    let encoder = passEncoder;
    if (renderBundle) {
      if (!renderBundle.record) {
        return passEncoder;
      }
      encoder = renderBundle;
    }

    encoder.setBindGroup(0, this.#iblUniforms.getBindGroup());
    encoder.setBindGroup(1, this.#cameraUniforms.getBindGroup());
    encoder.setBindGroup(2, this.#objectUniforms.getBindGroup(modelId, sceneStart));
    encoder.setBindGroup(3, this.#materialUniforms.getBindGroup());

    return passEncoder;
  }

  #beginRenderPass2D(commandEncoder, passDescriptor) {
    let passEncoder = commandEncoder.beginRenderPass(passDescriptor);

    passEncoder.setBindGroup(0, this.#iblUniforms.getBindGroup());
    passEncoder.setBindGroup(1, this.#cameraUniforms.getBindGroup());
    passEncoder.setBindGroup(2, this.#objectUniforms.getBindGroup(-1));
    passEncoder.setBindGroup(3, this.#lineUniforms.getBindGroup());

    return passEncoder;
  }

  #flushObjects(commandEncoder, count) {let submit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    let commandGroup = commandEncoder.finish();

    // Submit will be false for main scene rendering.
    // Uniforms are updated separately, and command groups are submitted at a higher level,
    // which allows to batch multiple groups into a single submit call for better performance.
    if (submit && count) {
      this.#objectUniforms.writeToQueue(count);
    }

    if (submit) {
      this.#device.queue.submit([commandGroup]);
    } else {
      return commandGroup;
    }
  }

  beginScene(camera, lights) {
    this.#cameraUniforms.update(camera);
    this.#iblUniforms.update();
  }

  // Render callbacks (passed to forEachWebGPU) for the main pass.
  // 2d and overlay passes still have custom callbacks defined inline.
  #renderBatchCallback(geometry, material, index) {
    let materialTextureMask;
    if (!this.#useRenderBundles || this.#renderBundle.record) {
      // TODO: Recording these is only required for the edge pass, so we could skip it when edges are not enabled.
      const renderIndex = this.#objectUniforms.getRenderIndex(index);
      this.#geometriesList[this.#count] = geometry;
      this.#renderIndicesList[this.#count] = renderIndex;

      //TODO: Two pass transparency (if we want that) needs to draw with flipped culling first
      //then a second time with regular culling

      //It looks like we set the uniforms after the draw call here, but remember
      //that all these calls are just queuing commands that get issued when we
      //flush the command encoder, nothing is actually getting drawn yet.
      materialTextureMask = this.#mainPipeline.drawOne(this.#encoder, renderIndex, geometry, material);

      this.#objectUniforms.initMaterialUpdateHook(material, materialTextureMask);
      material.needsUpdate = false;

      this.#count++;
    }
  }

  #renderBatchCallbackGhosted(geometry, material, index) {
    const renderIndex = this.#objectUniforms.getRenderIndex(index);

    this.#edgePipeline.drawOneGhosted(this.#encoder, renderIndex, geometry, ghostMaterialSettings);
  }

  #threeSceneCallback(mesh) {
    const geometry = mesh.geometry;
    const material = mesh.material;

    this.#geometriesList[this.#count] = geometry;
    this.#renderIndicesList[this.#count] = this.#count;

    //TODO: Two pass transparency (if we want that) needs to draw with flipped culling first
    //then a second time with regular culling

    //It looks like we set the uniforms after the draw call here, but remember
    //that all these calls are just queuing commands that get issued when we
    //flush the command encoder, nothing is actually getting drawn yet.
    const materialTextureMask = this.#mainPipeline.drawOne(this.#encoder, this.#count, geometry, material);

    this.#objectUniforms.setOneObjectData(mesh, this.#count);
    this.#objectUniforms.setOneMaterialData(material, materialTextureMask);

    this.#count++;
  }

  #threeSceneCallbackGhosted(mesh) {
    this.#objectUniforms.setOneObjectData(mesh, this.#count);

    this.#edgePipeline.drawOneGhosted(this.#encoder, this.#count, mesh.geometry, ghostMaterialSettings);
    this.#count++;
  }

  #createRenderBundle(descriptor) {
    const bundleEncoder = this.#device.createRenderBundleEncoder(descriptor);
    bundleEncoder.record = true;
    return bundleEncoder;
  }

  #finishRenderBundle(rBatch, index, passEncoder, renderBundle) {
    if (renderBundle) {
      let bundle = renderBundle;
      if (renderBundle.record) {
        bundle = renderBundle.finish();
        rBatch.setRenderBundle(index, bundle);
      }
      passEncoder.executeBundles([bundle]);
    }
  }

  renderScenePart(scene, showEdges) {

    let rt = this.#renderer.getRenderTargets();
    rt.setTargetsDirty();

    let rBatch, modelId, isModelScene, callback, callbackGhosted;
    this.#useRenderBundles = false;
    let renderBundle, renderBundleEdges, renderBundleGhosted;
    if (!(scene instanceof RenderBatch)) {
      rBatch = sceneToBatch(scene, this.#cameraUniforms.getViewProjectionMatrix());
      if (!rBatch) {
        return;
      }
      modelId = -1;
      isModelScene = false;
      callback = this.#threeSceneCallback.bind(this);
      callbackGhosted = this.#threeSceneCallbackGhosted.bind(this);
    } else {
      rBatch = scene;
      modelId = rBatch.frags.modelId;
      isModelScene = true;
      callback = this.#renderBatchCallback.bind(this);
      callbackGhosted = this.#renderBatchCallbackGhosted.bind(this);
      this.#objectUniforms.resetUpdateHeuristic(modelId);
      if (rBatch.uniformsNeedUpdate) {
        this.#objectUniforms.updateBatch(rBatch);
        rBatch.uniformsNeedUpdate = false;
      }
      this.#useRenderBundles = rBatch.useRenderBundles;
      this.#renderer.clearModelVisibilityDirty(modelId);
      if (this.#useRenderBundles) {
        renderBundle = rBatch.getRenderBundle(0);
        renderBundleEdges = rBatch.getRenderBundle(1);
        if (showEdges && renderBundle && !renderBundleEdges) {
          // The color render bundle has been recorded, but there's no edge render bundle yet
          // (edges might just have been enabled at runtime). We discard / re-record the existing color
          // render bundle, because we need to create the list of geometries for the edge pass anyway.
          renderBundle = null;
        }
        if (!showEdges && renderBundleEdges) {
          // A render bundle for edges has been recorded before, but edges are now disabled.
          // We discard the edge render bundle, to avoid using a stale one if edges are re-enabled later.
          rBatch.setRenderBundle(1, null);
          renderBundleEdges = null;
        }
        renderBundleGhosted = rBatch.getRenderBundle(2);
      }
    }

    if (rBatch.is2d()) {
      this.#renderScenePart2D(rBatch);
      return;
    }

    let targets = rt.getTargetsListMainPass();
    let edgeTargets = rt.getTargetsListEdgePass();

    this.#objectUniforms.setDoNotCutOverride(false);

    let startIndex = 0;
    let commandGroup;
    do {
      this.#count = 0;
      let commandEncoder = this.#device.createCommandEncoder();

      if (!scene.edgesOnly) {

        this.#objectUniforms.setEdgeColorInt(this.#edgeColorMainInt);

        if (this.#useRenderBundles) {
          if (!renderBundle) {
            renderBundle = this.#createRenderBundle(this.#renderBundleDescriptor);
          }
          this.#renderBundle = renderBundle;
        }

        //Main forward pass
        let passEncoder = this.#beginRenderPass(commandEncoder, this.#mainPassDescriptorProg, renderBundle, modelId, rBatch.start);

        this.#encoder = renderBundle ? renderBundle : passEncoder;

        this.#mainPipeline.reset(this.#bindGroupLayouts, targets);

        let endIndex = 0;
        if (!this.#useRenderBundles || renderBundle.record) {
          endIndex = rBatch.forEachWGPU(startIndex, this.#objectUniforms.MAX_BATCH, callback);
        }

        this.#finishRenderBundle(rBatch, 0, passEncoder, renderBundle);

        passEncoder.end();

        //Draw edge pass if required
        if (showEdges) {

          //Main pass edges
          if (this.#useRenderBundles && !renderBundleEdges) {
            renderBundleEdges = this.#createRenderBundle(this.#renderBundleEdgesDescriptor);
          }

          let edgePassEncoder = this.#beginRenderPass(commandEncoder, this.#edgePassDescriptor, renderBundleEdges, modelId, rBatch.start);

          this.#encoder = renderBundleEdges ? renderBundleEdges : edgePassEncoder;

          this.#edgePipeline.reset(this.#bindGroupLayouts, edgeTargets);

          if (!this.#useRenderBundles || renderBundleEdges.record) {
            for (let i = 0; i < this.#count; i++) {
              this.#edgePipeline.drawOne(this.#encoder, this.#renderIndicesList[i], this.#geometriesList[i], edgeMaterialSettings);
            }
          }

          this.#finishRenderBundle(rBatch, 1, edgePassEncoder, renderBundleEdges);

          edgePassEncoder.end();
        }

        commandGroup = this.#flushObjects(commandEncoder, this.#count, !isModelScene);

        startIndex = endIndex;

      } else {
        //Ghosted pass

        //This tricky bit plays along with the logic in RenderContext and RenderCommandSystem
        //Ghosting pass is edges only, but uses the override material.
        //Main pass edges use edgeMaterial, with color in its uniforms.
        //let overrideMaterial = scene.overrideMaterial;

        this.#objectUniforms.setEdgeColorInt(this.#edgeColorGhostedInt);

        //Ghosted pass (currently draws edges and lines in a "ghosted/stippled" effect
        if (this.#useRenderBundles && !renderBundleGhosted) {
          renderBundleGhosted = this.#createRenderBundle(this.#renderBundleEdgesDescriptor);
        }

        let edgePassEncoder = this.#beginRenderPass(commandEncoder, this.#edgePassDescriptor, renderBundleGhosted, modelId, rBatch.start);

        this.#encoder = renderBundleGhosted ? renderBundleGhosted : edgePassEncoder;

        this.#edgePipeline.reset(this.#bindGroupLayouts, edgeTargets);

        this.#count = 0;

        let endIndex = 0;
        if (!this.#useRenderBundles || renderBundleGhosted.record) {
          endIndex = rBatch.forEachWGPU(startIndex, this.#objectUniforms.MAX_BATCH, callbackGhosted);
        }

        this.#finishRenderBundle(rBatch, 2, edgePassEncoder, renderBundleGhosted);

        edgePassEncoder.end();

        commandGroup = this.#flushObjects(commandEncoder, this.#count, !isModelScene);
        startIndex = endIndex;
      }

    } while (startIndex > 0);

    this.#encoder = null;
    this.#renderBundle = null;

    return commandGroup;
  }

  #renderScenePart2D(scene, overrideMaterial) {let renderToOverlay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    let rt = this.#renderer.getRenderTargets();
    rt.setTargetsDirty();

    let rBatch = scene;

    let targets = renderToOverlay ? rt.getOverlayTargetsList() : rt.getTargetsListMainPass();

    this.#objectUniforms.setDoNotCutOverride(false);

    let startIndex = 0;
    do {
      let count = 0;
      let commandEncoder = this.#device.createCommandEncoder();

      const passDescriptor = renderToOverlay ? this.#overlayPassDescriptorNoClear : this.#mainPassDescriptorProg;

      //Main forward pass
      let passEncoder = this.#beginRenderPass2D(commandEncoder, passDescriptor);

      // The targets setup is different for 2D in overlay passes. Therefore, we need a separate pipeline for this case,
      // because the target setup is assumed to be constant by LinePipeline.
      const linePipeline = renderToOverlay ? this.#linePipelineOverlays : this.#linePipeline;
      linePipeline.reset(this.#bindGroupLayouts2D, targets);

      let endIndex = rBatch.forEachWGPU(startIndex, this.#objectUniforms.MAX_BATCH, (mesh) => {
        const geometry = mesh.geometry;
        const material = overrideMaterial || mesh.material;

        //TODO: update line uniforms per material here
        linePipeline.drawOne(passEncoder, count, geometry, material);

        this.#objectUniforms.setOneObjectData(mesh, count);
        this.#objectUniforms.setOneMaterialData2D(material, 0);

        count++;
      });

      passEncoder.end();

      this.#flushObjects(commandEncoder, count);
      startIndex = endIndex;

    } while (startIndex > 0);
  }

  renderOverlay(scene, camera, materialPre, materialPost, showEdges, customEdgeColor, lights) {

    //TODO: is that really needed, given we always have a beginScene first?
    this.#cameraUniforms.update(camera);

    //NOTE: This logic renders the top side of the highlighted objects first,
    //and then the bottom side. The reason is that the top side material is opaque,
    //while we want to render the hidden parts of the object with faint transparency.
    //For objects that covers themselves and are also covered by other objects
    //this is a problem, since the opaque parts would prevent the back parts from showing.

    //However, edge rendering uses painter's algorithm settings for the depth,
    //since we don't care to show hidden edges from under top edges.

    // Note: We assume that this is only called for three scenes, i.e. actual overlays,
    // and NOT for RenderBatches that are part of an actual model.
    let renderBatch = sceneToBatch(scene, this.#cameraUniforms.getViewProjectionMatrix());

    if (renderBatch.is2d()) {
      this.#renderScenePart2D(renderBatch, materialPre, true);
      return;
    }

    let rt = this.#renderer.getRenderTargets();
    let targets = rt.getOverlayTargetsList();

    this.#objectUniforms.setDoNotCutOverride(true);

    let startIndex = 0,endIndex;
    do {
      let count = 0;
      let commandEncoder = this.#device.createCommandEncoder();

      //Render top side of the object using the primary highlight material
      //or the overlay object's own material
      let overrideMaterial;
      if (materialPre) {
        overrideMaterial = materialPre;
      }

      this.#objectUniforms.setEdgeColorInt(vectorToABGR(customEdgeColor || EDGE_COLOR_HIGHLIGHT_UNDER));

      let passEncoder = this.#beginRenderPass(commandEncoder, this.#overlayPassDescriptorNoClear);

      this.#overlayPipeline.reset(this.#bindGroupLayouts, targets);

      endIndex = renderBatch.forEachWGPU(startIndex, this.#objectUniforms.MAX_BATCH, (mesh) => {
        const geometry = mesh.geometry;
        const objMaterial = mesh.material;

        this.#geometriesList[count] = geometry;

        const material = overrideMaterial || objMaterial;

        //It looks like we set the uniforms after the draw call here, but remember
        //that all these calls are just queuing commands that get issued when we
        //flush the command encoder, nothing is actually getting drawn yet.
        const materialTextureMask = this.#overlayPipeline.drawOne(passEncoder, count, geometry, material);

        //The uniforms set here are also used for the edges pass below
        mesh.material = material; // make sure to set the correct material reference
        this.#objectUniforms.setOneObjectData(mesh, count);
        this.#objectUniforms.setOneMaterialData(material, materialTextureMask);

        count++;
      });

      passEncoder.end();


      if (materialPost) {

        if (showEdges) {

          let edgePassEncoder = this.#beginRenderPass(commandEncoder, this.#overlayPassDescriptorNoClear);

          this.#edgePipeline.reset(this.#bindGroupLayouts, targets);

          for (let i = 0; i < count; i++) {
            this.#edgePipeline.drawOne(edgePassEncoder, i, this.#geometriesList[i], edgeHighlightMaterialUnder);
          }

          edgePassEncoder.end();
        }

        //We need to flush the rendering pipe here, because the material settings are encoded
        //into the per-object uniforms (even though the same material is used for all objects in the scene)
        //This can be optimized by using two set of object uniforms buffers, or by using a pipeline
        //other than the uber shader pipeline, that can draw all objects with a fixed material
        this.#flushObjects(commandEncoder, count);

        commandEncoder = this.#device.createCommandEncoder();

        //Render bottom side of the object
        //for selection that's done using light transparency to show
        //areas the object spans under other objects
        {
          materialPost.depthFunc = "greater";
          materialPost.needsUpdate = true;
          this.#objectUniforms.setEdgeColorInt(vectorToABGR(customEdgeColor || EDGE_COLOR_HIGHLIGHT));

          let passEncoder = this.#beginRenderPass(commandEncoder, this.#overlayPassDescriptorNoClear);

          this.#overlayPipeline.reset(this.#bindGroupLayouts, targets);

          let count3 = 0;
          const material = materialPost;

          // TODO: This can be optimized away. The list of geometries to render has already been recorded
          // above. All we really need to do here is iterate over all indices and set the material reference.
          endIndex = renderBatch.forEachWGPU(startIndex, this.#objectUniforms.MAX_BATCH, (mesh) => {
            const materialTextureMask = this.#overlayPipeline.drawOne(passEncoder, count3, mesh.geometry,
            material);

            //The uniforms set here are also used for the edges pass below
            this.#objectUniforms.setMaterialReference(count3 * this.#objectUniforms.OBJECT_STRIDE_32, material);
            this.#objectUniforms.setOneMaterialData(material, materialTextureMask);
            count3++;
          });

          passEncoder.end();

        }

      }

      //Finally render top side edges last
      if (showEdges) {

        let edgePassEncoder = this.#beginRenderPass(commandEncoder, this.#overlayPassDescriptorNoClear);

        this.#edgePipeline.reset(this.#bindGroupLayouts, targets);

        for (let i = 0; i < count; i++) {
          this.#edgePipeline.drawOne(edgePassEncoder, i, this.#geometriesList[i], edgeHighlightMaterialOver);
        }

        edgePassEncoder.end();
      }

      this.#flushObjects(commandEncoder, count);

      startIndex = endIndex;

    } while (startIndex > 0);
  }

}