import { Button } from "./Button";
import { stringToDOM } from "../../globals";
import { ControlGroup } from "./ControlGroup";

/**
 * Active if any sub-control is active, else disabled if all sub-controls are disabled.
 * @param controls Controls in the sub-menu.
 * @returns {Autodesk.Viewing.UI.Button.State}
 */
function chooseParentState(controls) {
  let allDisabled = true;
  for (let c of controls) {
    const st = c?.getState();
    if (st === Button.State.ACTIVE) {
      return Button.State.ACTIVE;
    }
    if (st === Button.State.INACTIVE) {
      allDisabled = false;
    }
  }
  return allDisabled ? Button.State.DISABLED : Button.State.INACTIVE;
}

/**
 * Button with a submenu that can be added to toolbars
 *
 * @param {string} [id] - The id for this SubMenu. Optional.
 * @param {object} [options] - An optional dictionary of options.
 * @constructor
 * @augments Autodesk.Viewing.UI.Button
 * @alias Autodesk.Viewing.UI.SubMenu
 */
export class SubMenu extends Button {
  constructor(id, options) {
    super(id, options);

    this.subMenu = new ControlGroup(id + 'SubMenu');
    this.subMenu.addClass('toolbar-vertical-group');
    this.subMenu.setVisible(false);

    this.container.insertBefore(this.subMenu.container, this.container.firstChild);

    this.onSubStateChange = () => this.setState(chooseParentState(this.subMenu._controls));
    this.onClick = () => this.subMenu.setVisible(!this.subMenu.isVisible());

    // put up an invisible div to catch click-off close submenu
    let clickOff = stringToDOM('<div class="clickoff" style="position:fixed; top:0; left:0; width:100vw; height:100vh;"></div>');
    this.subMenu.container.insertBefore(clickOff, this.subMenu.container.firstChild);
    clickOff.addEventListener("click", (e) => {
      this.subMenu.setVisible(false);
      e.stopPropagation();
    });
  }

  /**
   * Adds a new control to the fly-out.
   *
   * @param {Autodesk.Viewing.UI.Button} button
   * @param {object} [options] - An option dictionary of options.
   * @param {Number} [options.index] - The index to insert the control at.
   *
   * @alias Autodesk.Viewing.UI.SubMenu#addControl
   */
  addControl(button, options) {
    this.subMenu.addControl(button, options);
    button.addEventListener(Button.Event.STATE_CHANGED, this.onSubStateChange);
  }

  /**
   * Removes a control from the fly-out.
   *
   * @param {Autodesk.Viewing.UI.Button} button
   *
   * @alias Autodesk.Viewing.UI.SubMenu#removeControl
   */
  removeControl(button) {
    button.addEventListener(Button.Event.STATE_CHANGED, this.onSubStateChange);
  }
}