import * as globals from "../globals";
import { isMobileDevice } from "../../compat";

export class GPUMemoryTracker {

  constructor(is2d, disableStreaming) {
    this.numGeomsInMemory = 0; // total number of geoms added via addGeometry(..) (may be <this.geoms.length)
    this.geomMemory = 0; // total memory in bytes of all geoms
    this.gpuMeshMemory = 0; // total memory in bytes of all geoms, exluding those that we draw from system memory
    this.gpuNumMeshes = 0; // total number of geoms etries that we fully upload to GPU for drawing
    this.geomPolyCount = 0; // summed number of polygons, where geometries with mulitple instances are counted only once.
    this.instancePolyCount = 0; // summed number of polygons, counted per instance

    this.GPU_MEMORY_LOW = globals.GPU_MEMORY_LIMIT;
    this.GPU_MEMORY_HIGH = 2 * this.GPU_MEMORY_LOW;
    this.GPU_MESH_MAX = globals.GPU_OBJECT_LIMIT;

    // If false, we use a heuristic to determine which shapes are uploaded to GPU and which
    // ones we draw from CPU memory using (slower) streaming draw.
    this.disableStreaming = !!disableStreaming;


    this.is2d = !!is2d;
  }

  /**
   * Determines if a given BufferGeometry should be stored on CPU or GPU.
   *
   * @param {LmvBufferGeometry} geometry The BufferGeometry whose storage is to
   * be determined. If the BufferGeometry is to be retained in the GPU memory, then
   * its 'streamingDraw' and 'streamingIndex' will be set to 'false'. Otherwise,
   * they will be set to 'true' to enable its streaming draw from system memory.
   * @param {number} numInstances The number of fragments that made up the Mesh
   * object that owns this BufferGeometry object.
   */
  chooseMemoryType(geometry, numInstances) {

    //Already accounted for
    if (geometry.refcount > 0) {
      return;
    }

    if (this.GPU_MEMORY_LOW === 0) {
      geometry.streamingDraw = true;
      geometry.streamingIndex = true;
      return;
    }

    //TODO: This logic needs to be made to track memory globally across all GeometryLists

    //Heuristically determine if we want to load this mesh onto the GPU
    //or use streaming draw from system memory
    if (this.disableStreaming || this.gpuMeshMemory < this.GPU_MEMORY_LOW && this.gpuNumMeshes < this.GPU_MESH_MAX) {
      //We are below the lower limits, so the mesh automatically is
      //assigned to retained mode
      geometry.streamingDraw = false;
      geometry.streamingIndex = false;
    } else if (this.gpuMeshMemory >= this.GPU_MEMORY_HIGH) {
      //We are above the upper limit, so mesh is automatically
      //assigned to streaming draw
      geometry.streamingDraw = true;
      geometry.streamingIndex = true;
    } else {
      //Between the lower and upper limits,
      //Score mesh importance based on its size
      //and number of instances it has. If the score
      //is high, we will prefer to put the mesh on the GPU
      //so that we don't schlep it across the bus all the time.
      let weightScore;

      if (!this.is2d) {
        weightScore = geometry.byteSize * (numInstances || 1);
      } else {
        //In the case of 2D, there are no instances, so we just keep
        //piling into the GPU until we reach the "high" mark.
        weightScore = 100001;
      }

      if (weightScore < 100000) {
        geometry.streamingDraw = true;
        geometry.streamingIndex = true;
      }
    }
  }


  addGeometry(geometry, polyCount, numInstances) {

    this.chooseMemoryType(geometry, numInstances);

    // track overall GPU workload
    let size = geometry.byteSize + globals.GEOMETRY_OVERHEAD;
    if (!geometry.streamingDraw) {
      if (isMobileDevice())
      size += geometry.byteSize;
      this.gpuMeshMemory += geometry.byteSize;
      this.gpuNumMeshes += 1;
    }

    this.geomMemory += size;
    this.numGeomsInMemory++;
    this.geomPolyCount += polyCount;
    this.instancePolyCount += polyCount * (numInstances || 1);
  }

  removeGeometry(geometry) {

    let size = geometry.byteSize + globals.GEOMETRY_OVERHEAD;

    if (!geometry.streamingDraw) {
      if (isMobileDevice())
      size += geometry.byteSize;
      this.gpuMeshMemory -= geometry.byteSize;
      this.gpuNumMeshes -= 1;
    }

    // decrease its related counts
    this.geomMemory -= size;
    this.numGeomsInMemory--;
    this.geomPolyCount -= geometry.polyCount;
    this.instancePolyCount -= geometry.instanceCount * geometry.polyCount;
  }


  printStats() {
    console.log("Total geometry size: " + this.geomMemory / (1024 * 1024) + " MB");
    console.log("Number of meshes: " + this.numGeomsInMemory);
    console.log("Num Meshes on GPU: " + this.gpuNumMeshes);
    console.log("Net GPU geom memory used: " + this.gpuMeshMemory);
  }

}