import * as THREE from 'three';

import { Label3D, getMaxPixelAxialDistance } from '../../../../gui/Label3D';

const _tmpMat4 = new THREE.Matrix4();

const LABEL_MAXW_FULL = 300;
const LABEL_MAXW_TRUNCATED = 100; // Same as max-width in SpacesLayer.css

export class SpaceLabel {
  #availablePx;
  #isFullLabel;

  constructor(space, placement, hud) {
    const { dbId, model } = space;

    const it = model.getInstanceTree();

    this.hud = hud;
    this.name = it.getNodeName(dbId);
    this.placement = placement;
    this.space = space;
    this.bounds = null;
    this.worldBox = null;
    this.viewBox = new THREE.Box2();

    this.isSpaceVisible = () => !it.isNodeHidden(dbId) && !it.isNodeOff(dbId);
  }

  dispose() {
    if (!this.label) return;

    if (this.boundOnClick) this.label.removeEventListener(Label3D.Events.CLICK, this.boundOnClick);
    if (this.boundOnMouseOver) this.label.removeEventListener(Label3D.Events.ON_MOUSE_OVER, this.boundOnMouseOver);
    if (this.boundOnMouseOut) this.label.removeEventListener(Label3D.Events.ON_MOUSE_OUT, this.boundOnMouseOut);

    this.label.dtor();
    this.label = null;
  }

  isVisible() {
    return Boolean(this.label?.visible);
  }

  isInDOM() {
    return !!this.label;
  }

  hasEnoughSpace(drawRect) {
    if (!this.label) {
      // Label not created yet, guess bounds if it's empty
      this.bounds ??= estimateCanvasTextDimensions(this.hud.viewer, this.name);
    }

    if (this.label && !this.label.isOnScreen(drawRect)) {
      return false;
    }

    // Same check as Label3D.shouldBeHidden
    this.#availablePx = getMaxPixelAxialDistance(this.hud.viewer, this.#getWorldBox(), true);
    const shouldBeHidden = this.#availablePx < this.bounds.width;
    return !shouldBeHidden;
  }

  setVisible(visible) {
    if (!this.label && !visible) {
      return;
    }

    if (!this.label) {
      // Delay label instantiation until asked to be visible.
      this.label = this.#makeLabel(this.hud, this.placement.position);
      // Label position may have been initialized without animated transform, update it
      this.updateLabelPosition();
      this.updateRoomName();
    }

    this.label.setVisible(visible);
  }

  refreshMaxWidth() {
    const fullLabel = this.#availablePx * 2 > this.hud.viewer.canvasWrap.clientWidth;

    if (this.#isFullLabel === undefined || this.#isFullLabel !== fullLabel) {
      this.label.textDiv.style.maxWidth = `${fullLabel ? LABEL_MAXW_FULL : LABEL_MAXW_TRUNCATED}px`;
      setPerWord(this.label.textDiv, this.name, fullLabel);

      this.#isFullLabel = fullLabel;
    }
  }

  setClickHandler(handler) {
    if (this.label && this.boundOnClick) {
      this.label.removeEventListener(Label3D.Events.CLICK, this.boundOnClick);
    }

    this.boundOnClick = handler ? (_ref) => {let { event } = _ref;handler(event, this.space);} : null;
    if (this.label && this.boundOnClick) {
      this.label.addEventListener(Label3D.Events.CLICK, this.boundOnClick);
    }
  }

  setMouseOverHandler(handler) {
    if (this.label && this.boundOnMouseOver) {
      this.label.removeEventListener(Label3D.Events.ON_MOUSE_OVER, this.boundOnMouseOver);
    }

    this.boundOnMouseOver = handler ? (_ref2) => {let { event } = _ref2;handler(event, this.space);} : null;
    if (this.label && this.boundOnMouseOver) {
      this.label.addEventListener(Label3D.Events.ON_MOUSE_OVER, this.boundOnMouseOver);
    }
  }

  setMouseOutHandler(handler) {
    if (this.label && this.boundOnMouseOut) {
      this.label.removeEventListener(Label3D.Events.ON_MOUSE_OUT, this.boundOnMouseOut);
    }

    this.boundOnMouseOut = handler ? (_ref3) => {let { event } = _ref3;handler(event, this.space);} : null;
    if (this.label && this.boundOnMouseOut) {
      this.label.addEventListener(Label3D.Events.ON_MOUSE_OUT, this.boundOnMouseOut);
    }
  }

  updateRoomName() {
    const { dbId, model } = this.space;
    const it = model.getInstanceTree();

    this.name = it.getNodeName(dbId);
    this.#updateLabelBounds();

    // if needed, update textDiv
    this.label?.update();
  }

  #updateLabelBounds() {
    if (!this.label) {
      this.bounds = estimateCanvasTextDimensions(this.hud.viewer, this.name);
    } else {
      this.#isFullLabel = this.#availablePx * 2 > this.hud.viewer.canvasWrap.clientWidth;
      setPerWord(this.label.textDiv, this.name, this.#isFullLabel);
      this.bounds.height = this.label.textDiv.scrollHeight;
      this.bounds.width = this.label.textDiv.scrollWidth;
      this.label.minPixels = this.bounds.width;
    }
  }

  #makeLabel(hud, position) {
    const { dbId, model } = this.space;

    const lbl = hud.addLabel(model, dbId, false /* isPin */, { noDefaultStyle: true });
    lbl.setElement(this.space);

    if (this.boundOnClick) lbl.addEventListener(Label3D.Events.CLICK, this.boundOnClick);
    if (this.boundOnMouseOver) lbl.addEventListener(Label3D.Events.ON_MOUSE_OVER, this.boundOnMouseOver);
    if (this.boundOnMouseOut) lbl.addEventListener(Label3D.Events.ON_MOUSE_OUT, this.boundOnMouseOut);

    if (!lbl.textDiv) lbl.initTextLabel(); // Labels don't initialize textDiv if not needed. We still want it.
    lbl.textDiv.classList.add('annotation-text');

    if (position) {
      lbl.setPosition(position);
    } else {
      console.info('Creating space label without explicit positioning', this.name);
    }

    lbl.worldBox = this.#getWorldBox();

    return lbl;
  }

  updateLabelPosition() {
    // No label or position to update
    if (!this.placement.position) return;

    const { dbId, model } = this.space;

    const it = model.getInstanceTree();
    const fl = model.getFragmentList();

    let moved = false;
    it?.enumNodeFragments(
      dbId,
      (fragId) => {
        moved = fl.getAnimTransformMatrix(fragId, _tmpMat4);
        return moved; // Only need the first valid transformation matrix
      }, false);

    this.placement.position.copy(this.placement.defaultPosition);

    if (moved) {
      this.placement.position.applyMatrix4(_tmpMat4);
    }

    this.label?.setPosition(this.placement.position);
  }

  updateWorldBox() {
    if (!this.worldBox) return;
    this.worldBox = getSpaceTopPlane(this.space.model, this.space.dbId);
    if (!this.label) return;
    this.label.worldBox = this.worldBox;
  }

  #getWorldBox() {
    this.worldBox ??= getSpaceTopPlane(this.space.model, this.space.dbId);
    return this.worldBox;
  }

  updateViewBox() {
    const { x, y } = this.label ?
    this.label.getCanvasPosition() :
    this.hud.viewer.impl.worldToClient(this.placement.position);
    const halfWidth = this.bounds.width * 0.5;
    const halfHeight = this.bounds.height * 0.5;

    // Assumes LABEL_ANCHORING.MIDDLE
    this.viewBox.min.set(x - halfWidth, y - halfHeight);
    this.viewBox.max.set(x + halfWidth, y + halfHeight);
  }
}

function getSpaceTopPlane(model, dbId) {let dstBox = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new THREE.Box3();
  model.getElementBounds(dbId, dstBox);

  dstBox.min.z = dstBox.max.z; // Force to the top plane

  return dstBox;
}

/**
 * Splits label into multi-word divs allowing flex labelling to wrap it around
 * in available space.
 */
function setPerWord(textDiv, text, fullLabel) {
  // Empties old words.
  while (textDiv.firstChild) {
    textDiv.firstChild.remove();
  }

  const maxWidth = fullLabel ? LABEL_MAXW_FULL : LABEL_MAXW_TRUNCATED;
  const words = text.split(' ');
  const _document = textDiv.ownerDocument;


  let lineDiv = _document.createElement('div');
  textDiv.append(lineDiv);

  // Add words to first div until it wraps
  for (const word of words) {
    lineDiv.textContent += word + ' ';
    const lineLen = lineDiv.textContent.length;
    const addedLen = word.length + 1;

    // This word will cause wrapping, pop it out of current div and add it to the next one
    if (lineLen > addedLen && lineDiv.scrollWidth > maxWidth) {

      if (!fullLabel && textDiv.childElementCount === 2) return; // Two lines max for truncated labels

      lineDiv.textContent = lineDiv.textContent.slice(0, lineLen - addedLen);
      const newDiv = _document.createElement('div');
      textDiv.append(newDiv);
      newDiv.textContent = word + ' ';
      lineDiv = newDiv;
    }
  }
}

// Try to guess height and width of a canvas text element
function estimateCanvasTextDimensions(viewer, text) {
  const style = getComputedStyle(viewer.canvasWrap);
  Label3D.configureTextMeasurement(viewer, {
    font: `${style.fontSize} ${style.fontFamily}`
  });

  const padding = 5; // space between adjacent words, per SpacesLayer.CSS
  const lineHeight = 18; // line height is set in SpacesLayer.css
  const words = text.split(' ');

  // Maximum width defined by 100px, per SpacesLayer.CSS
  let firstLineWidth = 0;
  let wrapped;

  for (let i = 0; i < words.length; i++) {
    const wordMeasure = Label3D.getTextMeasurements(viewer, words[i]);
    const wordWidth = wordMeasure.width + padding;

    if (!wrapped) {
      // Track wrap state
      firstLineWidth += wordWidth;
      wrapped = firstLineWidth > LABEL_MAXW_TRUNCATED;
    } else {
      // First line overflowed, return maximum allowed label size
      return { width: LABEL_MAXW_TRUNCATED, height: lineHeight * 2 };
    }
  }

  // No wrapping
  const maxWidth = Math.min(firstLineWidth, LABEL_MAXW_TRUNCATED); // Max 100px
  const maxHeight = lineHeight; // Max two lines

  return { width: maxWidth, height: maxHeight };
}