//See: https://git.autodesk.com/tandem/dt-server/blob/master/src/autodesk.com/double-trouble/schemas/system-class.go

const SystemClass = Object.freeze({
  SupplyAir: 0, // A supply air duct system
  ReturnAir: 1, // A return air duct system
  ExhaustAir: 2, // Obsolete classification not used anymore
  SupplyHydronic: 3, // A hydronic supply piping system
  ReturnHydronic: 4, // A hydronic return piping system
  DomesticHotWater: 5, // A hot water piping system
  DomesticColdWater: 6, // A cold water piping system
  Sanitary: 7, // A sanitary piping system
  PowerCircuit: 8, // An electrical circuit
  Vent: 9, // A piping ventilation system
  Controls: 10, // An electrical circuit classified as controls

  FireProtectWet: 11, // A wet fire protection system
  FireProtectDry: 12, // A dry fire protection system
  FireProtectPreaction: 13, // A preaction fire protection system

  OtherAir: 14, // An user classified air duct system
  OtherPipe: 15, // An user classified piping system
  FireProtectOther: 16, // A user defined fire protection system

  Communication: 17, // An electrical circuit classified as communication
  DataCircuit: 18, // An electrical data circuit
  Telephone: 19, // An electrical circuit classified as telephone
  Security: 20, // An electrical security circuit
  FireAlarm: 21, // An electrical fire alarm circuit
  NurseCall: 22, // An electrical circuit classified as nurse call
  SwitchTopology: 23, // An electrical switch system
  CableTrayConduit: 24, // Indicates the default type for cable trays and conduits

  Storm: 25, // Reserved for future use

  //Not seen in any sample files -- add strings to the mapping below if/when encountered
  CondensateDrain: 26, // Reserved for future use
  Recirculation: 27, // Reserved for future use

  Fitting: 28, // Reserved for application usage, used for UI filtering in Family Editor
  Global: 29, // Reserved for application usage, used for UI filtering in Family Editor
  PowerBalanced: 30, // Equivalent with PowerCircuit
  PowerUnBalanced: 31 // Equivalent with PowerCircuit
});

const SystemClassNames = Object.freeze([
"Supply Air", //0
"Return Air", //1
"Exhaust Air", //2
"Hydronic Supply", //3
"Hydronic Return", //4
"Domestic Hot Water", //5
"Domestic Cold Water", //6
"Sanitary", //7
"Power", //8
"Vent", //9
"Controls", //10
"Fire Protection Wet", //11
"Fire Protection Dry", //12
"Fire Protection Pre-Action", //13
"Other Air", //14
"Other", //15
"Fire Protection Other", //16
"Communication", //17
"Data Circuit", //18
"Telephone", //19
"Security", //20
"Fire Alarm", //21
"Nurse Call", //22
"Switch Topology", //23
"Cable Tray Conduit", //24
"Storm" //25
]);

/**
 * @param {Number} flags
 * @returns {String[]}
 */
function SystemClassToList(flags) {

  if (!flags) {
    return [];
  }

  let res = [];
  for (let i = 0; i < SystemClassNames.length; i++) {
    if (flags & 1 << i) {
      res.push(SystemClassNames[i]);
    }
  }

  return res;
}

/**
 * @param {String[]} list
 * @returns {Number}
 */
function SystemClassListToFlags(list) {
  let flags = 0;
  for (let i = 0; i < list.length; i++) {
    let power = SystemClassNames.indexOf(list[i]);
    if (power >= 0) {
      flags |= 1 << power;
    } else {
      console.warn("unknown system class", list[i]);
    }
  }

  return flags;
}

module.exports = {
  SystemClass,
  SystemClassNames,
  SystemClassListToFlags,
  SystemClassToList
};