import * as THREE from 'three';

const _tmpBox = new THREE.Box3();
const _tmpMat4 = new THREE.Matrix4();
const _tmpVec2 = new THREE.Vector2();

/**
 * Placement of a label in 3D space.
 * @typedef {Object} LblPlacement
 * @property {THREE.Vector3} position - Position in 3D world coordinate of the label.
 * @property {?THREE.Vector3} orientation - Optional orientation for the label
 */

/**
 * Guesses good placements for placing labels for spaces.
 * Note: this method expects the spaces to be loaded (including their geometry).
 * @param viewer Active viewer.
 * @param spaces Single or collection of spaces.
 * @return {Object.<string, LblPlacement>}
 */
export function guessPlacement(viewer, spaces) {
  const dict = {};
  if (!spaces.length) {
    return dict;
  }

  const ext = viewer.getExtension('Autodesk.CompGeom');
  if (!ext) {
    return dict;
  }

  // Need fully loaded room data for polygon computation
  const els = Array.isArray(spaces) ? spaces : [spaces];
  for (const space of els) {
    dict[space.externalId] = computeLabelPlacementByAxis(space);
  }
  return dict;
}

function computeLabelPlacementByAxis(room) {
  const { model, dbId } = room;

  model.getElementBounds(dbId, _tmpBox);
  const roomTop = _tmpBox.max.z;
  const roomEdges = sliceSpaceInXYPlane(room, _tmpBox);

  // Decomposes room in main and cross axis based on most common contour orientation.
  const { main, cross } = getAxialDecomposition(roomEdges);
  const solutions = getPossibleSolutions(roomEdges, main, cross, room.name);

  // Labels needs horizontal space, valuing space along the main axis 3 times more.
  let bestScore = -1;
  let best = null;
  for (const { space: crossSpace, main } of solutions) {
    for (const { space: mainSpace, pt } of main) {
      const metric = crossSpace + 3 * mainSpace;
      if (metric > bestScore) {
        bestScore = metric;
        best = pt;
      }
    }
  }

  if (!best) {
    // TODO: We should pick a default here. Centroid can break, but the center of any top polygons could do.
    console.info('No label placement solution for room', room.name);
    return null;
  }

  const positions = {
    defaultPosition: new THREE.Vector3(best.x, best.y, roomTop),
    position: new THREE.Vector3(best.x, best.y, roomTop)
  };

  unApplyAnimTransform(model, dbId, positions.defaultPosition);

  return positions;
}

function unApplyAnimTransform(model, dbId, position) {
  const fl = model.getFragmentList();
  model.getInstanceTree().enumNodeFragments(dbId, (fragId) => {
    if (!fl.getAnimTransformMatrix(fragId, _tmpMat4)) return;

    _tmpMat4.invert();
    // Undo animation transform to get the "default" placement
    position.applyMatrix4(_tmpMat4);
    // Only need the first valid transformation matrix
    return true;
  });
}

function getFirstFragmentID(_ref) {let { model, dbId } = _ref;
  const it = model.getInstanceTree();

  let first;
  it.enumNodeFragments(dbId, (fid) => {
    first = fid;
    return true; // finished.
  }, false);

  return first;
}

const xyPlane = new THREE.Plane().setComponents(0, 0, 1, 0);
function sliceSpaceInXYPlane(space, bounds) {
  const cg = Autodesk.Viewing.Extensions.CompGeom;
  const { model } = space;

  const fid = getFirstFragmentID(space);
  const mesh = model.getFragmentList().getVizmesh(fid);
  if (!mesh.geometry || mesh.geometry.is2d || mesh.geometry.isLines) return;

  xyPlane.constant = -(bounds.min.z + bounds.max.z) * 0.5;

  const roomEdges = [];
  cg.xMeshPlane(xyPlane, mesh, roomEdges);

  // Bounds gets modified by convertToPlaneCoords, use what you need first.
  const toPlaneCoords = cg.makePlaneBasis(xyPlane);
  cg.convertToPlaneCoords(toPlaneCoords, roomEdges, bounds);

  return roomEdges;
}

function getAxialDecomposition(edges) {
  // Since rooms won't necessarily be oriented in the direction of our axis,
  // we rotate to work in axis-aligned space, and then un-rotate.
  const mainAxis = getMainAxis(edges);
  const angle = Math.atan2(mainAxis.y, mainAxis.x);

  // Generation of a bounding box aligned with the main axis.
  const box = new THREE.Box2();
  const _tmp = new THREE.Vector2();
  let cos = Math.cos(-angle);
  let sin = Math.sin(-angle);
  // We first undo the rotation of the main-axis, to get an AABB.
  const undoRot = (pt) => {
    // 2D rotation matrix manipulation written out.
    return pt.set(cos * pt.x - sin * pt.y, sin * pt.x + cos * pt.y);
  };
  for (const { v1, v2 } of edges) {
    box.expandByPoint(undoRot(_tmp.copy(v1)));
    box.expandByPoint(undoRot(_tmp.copy(v2)));
  }

  // In order to find intersection inward (i.e. not being collocated on a line, giving us no intersection).
  box.expandByScalar(1);

  // Segment following main axis.
  let halfCross = (box.max.y - box.min.y) / 2 + box.min.y;
  let halfMain = (box.max.x - box.min.x) / 2 + box.min.x;

  cos = Math.cos(angle);
  sin = Math.sin(angle);
  const rot = (pt) => {
    return pt.set(cos * pt.x - sin * pt.y, sin * pt.x + cos * pt.y);
  };
  // Then we redo the rotation to get axis oriented with the room.
  let fromMain = rot(box.min.clone().setY(halfCross));
  let toMain = rot(box.max.clone().setY(halfCross));

  let fromCross = rot(box.min.clone().setX(halfMain));
  let toCross = rot(box.max.clone().setX(halfMain));

  // Aim is to get the main and cross normalized axis, and also get the segment, along those axis cutting the points.
  //  ____________
  // |     |     |
  // |-----------|
  // |_____|_____|
  return {
    main: {
      axis: mainAxis,
      from: fromMain,
      to: toMain
    },
    cross: {
      axis: mainAxis.clone().set(mainAxis.y, mainAxis.x),
      from: fromCross,
      to: toCross
    }
  };
}

function swapEdge(edge) {
  const tmp = edge.v1;
  edge.v1 = edge.v2;
  edge.v2 = tmp;
  return edge;
}

function lineToEdgesIntersects(edges, line) {
  const cg = Autodesk.Viewing.Extensions.CompGeom;
  const intersects = [];
  for (const edge of edges) {
    const result = cg.segmentToLineIntersection(edge, line);

    switch (result.type) {
      case cg.CROSS_TYPE.EMPTY_SET:continue;
      case cg.CROSS_TYPE.POINT:
        result.distanceSq = line.v1.distanceToSquared(result.point);
        break;
      case cg.CROSS_TYPE.SEGMENT:
        // We don't know the orientation of the segment, we change it if it is reversed.
        const v1Sq = line.v1.distanceToSquared(result.segment.v1);
        const v2Sq = line.v1.distanceToSquared(result.segment.v2);

        // Numerical inaccuracy with points near segments breaks ordering, we order based on avg for segments.
        result.distanceSq = (v1Sq + v2Sq) / 2;
        if (v1Sq > v2Sq) {
          swapEdge(result.segment);
        }
        break;
    }

    intersects.push(result);
  }

  intersects.sort((a, b) => a.distanceSq - b.distanceSq);
  return intersects;
}

function collapseEnds(intersections) {let tol = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1E-4;
  const cg = Autodesk.Viewing.Extensions.CompGeom;
  const tolSq = tol * tol;

  function collapseMaybe(idx, segEnd) {
    const el = intersections[idx];
    if (el.type === cg.CROSS_TYPE.SEGMENT) {
      end = segEnd(el.segment);
      intersections.splice(idx, 1);
      return true;
    }

    const distSq = _tmpVec2.copy(end).distanceToSquared(el.point);
    if (distSq < tolSq) {
      // Remove trailing point if within tolerance.
      intersections.splice(idx, 1);
    }
    return false;
  }

  // From the start
  let end = intersections[0].point;
  while (collapseMaybe(1, (_ref2) => {let { v2 } = _ref2;return v2;})) {}
  // From the end
  end = intersections.at(-1).point;
  while (collapseMaybe(intersections.length - 2, (_ref3) => {let { v1 } = _ref3;return v1;})) {}
}

/** Finds intersection between the axis-line and contour, retrying if co-linear or co-incident segments are found */
export function findIntersections(edges, _ref4) {let { from, to } = _ref4;
  const cg = Autodesk.Viewing.Extensions.CompGeom;
  const line = { v1: from, v2: to };

  // A room can give segment intersection when there are coincident segments to the cut line.
  // ex:1 |---------------|
  //    2 |               |
  //    3 |----|     |----| <-
  //    4      |     |
  //    5      |-----|
  // This room would return 4 intersections, which would results in the inverse of what our hole detection expects.
  // If any overlap is found, we collapse it.
  let inters = lineToEdgesIntersects(edges, line);
  if (!inters.length) {
    return [];
  }

  // If either of the ends are segment, there was a hit missing, adding it back.
  if (inters[0].type === cg.CROSS_TYPE.SEGMENT) {
    inters.unshift({ type: cg.CROSS_TYPE.POINT, point: inters[0].segment.v1 });
  }
  if (inters.at(-1).type === cg.CROSS_TYPE.SEGMENT) {
    inters.unshift({ type: cg.CROSS_TYPE.POINT, point: inters.at(-1).segment.v2 });
  }

  if (inters.length > 2) {
    // Collapsing end segment intersection as per the diagram up-top.
    collapseEnds(inters);
  }

  // Now that we have collapsed meaningful segment intersection, the rest are equivalent to unwanted regions if
  // removed. It is possible to keep and collapse them, but mostly better to just remove them.
  const points = [];
  for (const result of inters) {
    if (result.type !== cg.CROSS_TYPE.POINT) continue;

    const { point } = result;
    point.distanceSq = result.distanceSq;
    points.push(point);
  }

  // Some are still very exactly the same point. We use a much tighter threshold here.
  makeSortedUnique(points, (lhs, rhs) => Math.abs(rhs.x - lhs.x) < 1E-6 && Math.abs(rhs.y - lhs.y) < 1E-6);

  return points;
}

/** Check in the cross and then main axis for center locations based on segment intersection with the room polygon. */
export function getPossibleSolutions(edges, main, cross, name) {
  const solutions = [];

  // Finding intersections using a segment that cuts across the cross axis
  const crossIntersects = findIntersections(edges, cross);
  if (crossIntersects.length % 2) {
    console.info('Room cross-cutting should give even number of intersections.', crossIntersects, name);
    return solutions;
  }

  for (let i = 0; i < crossIntersects.length; i += 2) {
    const prev = crossIntersects[i];
    const next = crossIntersects[i + 1];

    solutions.push({
      pt: _tmpVec2.copy(prev).lerp(next, 0.5).clone(), // mid-point of the 2 intersections.
      space: Math.sqrt(next.distanceSq) - Math.sqrt(prev.distanceSq), // available space.
      main: []
    });
  }

  // For each possible location, get interesting location on the main axis at the cross point.
  const crossMid = cross.from.clone().lerp(cross.to, 0.5);
  const shiftedAxis = {
    axis: main.axis,
    from: main.from.clone(),
    to: main.to.clone()
  };
  for (const solution of solutions) {
    // Main axis is placed in the middle of the room, we need to move it to cut at the point of solution.
    const shift = _tmpVec2.copy(solution.pt).sub(crossMid);
    shiftedAxis.from.copy(main.from).add(shift);
    shiftedAxis.to.copy(main.to).add(shift);

    // For every potential solution on the cross axis, find solutions in main axis.
    const mainIntersects = findIntersections(edges, shiftedAxis);
    if (mainIntersects.length % 2) {
      console.info('Room main-cutting should give even number of intersections.', mainIntersects, name);
      return [];
    }

    for (let i = 0; i < mainIntersects.length; i += 2) {
      const prev = mainIntersects[i];
      const next = mainIntersects[i + 1];

      solution.main.push({
        space: Math.sqrt(next.distanceSq) - Math.sqrt(prev.distanceSq), // available space.
        pt: _tmpVec2.copy(prev).lerp(next, 0.5).clone() // mid-point of the 2 intersections.
      });
    }
  }

  return solutions;
}

/** Removes duplicates in sorted array. */
function makeSortedUnique(arr, cmp) {
  let i = 1;
  while (i < arr.length) {
    if (cmp(arr[i], arr[i - 1])) {
      arr.splice(i, 1);
    } else {
      i++;
    }
  }
  return arr;
}

const vec2 = new THREE.Vector2();
/** Determines the main axis by finding the segment orientation with cumulative longest distance. */
function getMainAxis(edges) {
  const counts = {};
  let mode = new THREE.Vector2();
  let modeCounts = -1;

  function tally(vec, weight) {
    // round to 1000ths when making key, gives us an approx. equal.
    const key = Math.round(Math.atan2(vec.y, vec.x) * 1000);
    const newCount = (counts[key] ?? 0) + weight;
    if (newCount > modeCounts) {
      modeCounts = newCount;
      mode.copy(vec).normalize();
    }
    counts[key] = newCount;
  }

  for (const { v1, v2 } of edges) {
    vec2.copy(v2).sub(v1);
    // Force vectors representing lines into their right quadrant equivalent as we will receive both line direction
    // when iterating edges between one and the other side of a room. We want both to contribute to the same
    // "orientation" and make main & cross go from left to right for consistency.
    const isRightQuadrant = vec2.x > 0 || vec2.x === 0 && vec2.y >= 0;
    if (!isRightQuadrant) vec2.negate();
    tally(vec2, vec2.lengthSq());
  }

  return modeCounts === -1 ? null : mode;
}