import vertexTextureQuad from "../post/quad.vert.wgsl";
import { getSaoShader } from "./SAOShader.js";
import saoMipShader from "./SAOMipShader.wgsl";
import saoBlurShader from "./SAOBlurShader.wgsl";
import { SAOUniforms } from "./SAOUniforms";
import { SAOBlurUniforms } from "./SAOBlurUniforms";

function makeTextureBinding() {let binding = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;let visibility = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : GPUShaderStage.FRAGMENT;let sampleType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "unfilterable-float";
  return {
    binding,
    visibility,
    texture: {
      sampleType
    }
  };
}

function makeUniformBinding() {let binding = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;let visibility = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : GPUShaderStage.FRAGMENT;
  return {
    binding,
    visibility,
    buffer: {}
  };
}

const TrianglePrimitive = {
  topology: 'triangle-list',
  cullMode: 'back'
};

const MAX_MIP_LEVEL = 5;

export function SAOPass(renderer) {

  let _renderer = renderer;
  let _device;
  let _ssaoTarget;
  let _saoUniforms;
  let _w, _h;

  let _saoBindGroup, _saoBindLayout, _saoPipeline, _saoPassDescriptor;

  let _saoBlurUniforms, _saoBlurLayout, _saoBlurBindGroup1, _saoBlurBindGroup2, _saoBlurPipelineX, _saoBlurPipelineY;

  let _saoMipLayout, _saoMipBindGroups, _saoMipPipeline, _saoMipTarget, _saoMipView, _saoMipViews;

  let _ssaoTargetView, _post0TargetView;

  this.init = function () {
    _device = renderer.getDevice();

    _saoUniforms = new SAOUniforms(_device);
    _saoUniforms.setAOOptions(8.0, 0.01, 1.0);

    _saoBlurUniforms = new SAOBlurUniforms(_device);
    _saoBlurUniforms.setRadius(8.0);

    const vertexQuad = _device.createShaderModule({ code: vertexTextureQuad });

    const mipShader = _device.createShaderModule({
      label: 'sao mip shader',
      code: saoMipShader
    });

    const blurShader = _device.createShaderModule({ code: saoBlurShader });

    const targetFormat = _renderer.getRenderTargets().getPreferredFormat();
    if (targetFormat !== "bgra8unorm" && targetFormat !== "rgba8unorm") {
      console.warn("post-processing render target format", targetFormat, "may not work with SSAO");
    }

    // NOTE: We assume that there are always 5 mip levels.
    // If this is not the case, this layout and the shader need to be updated.
    // The bind group using this layout is created in 'resize' and might have less than 5 mip textures
    // if either the width or height are less than 32. This would currently cause an error.
    _saoBindLayout = _device.createBindGroupLayout({
      label: 'sao bind group layout',
      entries: [
      makeTextureBinding(0),
      makeTextureBinding(1),
      makeUniformBinding(2),
      makeTextureBinding(3)]

    });

    _saoMipLayout = _device.createBindGroupLayout({
      label: 'sao mip bind group layout',
      entries: [
      makeTextureBinding(0)]

    });

    _saoBlurLayout = _device.createBindGroupLayout({
      entries: [
      makeTextureBinding(0),
      makeUniformBinding(1)]

    });

    _saoPipeline = _device.createRenderPipeline({
      layout: _device.createPipelineLayout({
        bindGroupLayouts: [_saoBindLayout]
      }),
      vertex: {
        module: vertexQuad,
        entryPoint: "mainFlipY"
      },
      fragment: {
        module: _device.createShaderModule({ code: getSaoShader() }),
        entryPoint: "psmain",
        targets: [
        {
          format: targetFormat
        }]

      },
      primitive: TrianglePrimitive
    });

    _saoMipPipeline = _device.createRenderPipeline({
      label: 'sao mip pipeline',
      layout: _device.createPipelineLayout({
        bindGroupLayouts: [_saoMipLayout]
      }),
      vertex: {
        module: vertexQuad,
        entryPoint: "mainFlipY"
      },
      fragment: {
        module: mipShader,
        entryPoint: "psmain",
        targets: [
        {
          format: 'rgb10a2unorm'
        }]

      },
      primitive: TrianglePrimitive
    });

    _saoBlurPipelineX = _device.createRenderPipeline({
      layout: _device.createPipelineLayout({
        bindGroupLayouts: [_saoBlurLayout]
      }),
      vertex: {
        module: vertexQuad,
        entryPoint: "mainFlipY"
      },
      fragment: {
        module: blurShader,
        entryPoint: "psmain",
        targets: [
        {
          format: targetFormat
        }],

        constants: {
          0: 1
        }
      },
      primitive: TrianglePrimitive
    });

    _saoBlurPipelineY = _device.createRenderPipeline({
      layout: _device.createPipelineLayout({
        bindGroupLayouts: [_saoBlurLayout]
      }),
      vertex: {
        module: vertexQuad,
        entryPoint: "mainFlipY"
      },
      fragment: {
        module: blurShader,
        entryPoint: "psmain",
        targets: [
        {
          format: targetFormat
        }],

        constants: {
          0: 0
        }
      },
      primitive: TrianglePrimitive
    });

    _saoPassDescriptor = {
      colorAttachments: [
      {
        // view is acquired and set in render loop.
        view: undefined,

        clearValue: { r: 0.0, g: 0.0, b: 0.0, a: 1.0 },
        loadOp: 'clear',
        storeOp: 'store'
      }]

    };
  };

  this.cleanup = function () {
    _ssaoTarget?.destroy();
  };

  this.setAOOptions = function (radius, bias, intensity) {
    _saoUniforms.setAOOptions(radius, bias, intensity);
    _saoBlurUniforms.setRadius(radius);
  };

  this.setUnitScale = function (scaleFactor) {






    /*
    //Correct world space sensitive uniforms when world space units change
    _saoPass.uniforms[ 'radius' ].value *= scaleFactor;
    _saoPass.uniforms[ 'bias' ].value *= scaleFactor;
    _saoBlurPass.uniforms[ 'radius' ].value *= scaleFactor;
    */};this.resize = function (w, h) {_w = w;_h = h;

    const rt = _renderer.getRenderTargets();

    if (_ssaoTarget) {
      _ssaoTarget.destroy();
    }

    if (_saoMipTarget) {
      _saoMipTarget.destroy();
    }

    _ssaoTarget = _device.createTexture({
      size: [w, h],
      format: rt.getPreferredFormat(),
      usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING
    });

    _ssaoTargetView = _ssaoTarget.createView();
    _post0TargetView = rt.getPostTarget(0).createView();

    const viewDepthTargetView = rt.getViewDepthTarget().createView();

    // SSAO depth mip maps.
    _saoMipView = null;
    _saoMipViews = [];
    _saoMipBindGroups = [];

    const numMipLevels = Math.min(MAX_MIP_LEVEL, Math.min(Math.floor(Math.log2(w)), Math.floor(Math.log2(h))));
    const mipWidth = 0 | w / 2;
    const mipHeight = 0 | h / 2;
    const mipValid = mipWidth >= 1 && mipHeight >= 1;
    if (mipValid) {
      _saoMipTarget = _device.createTexture({
        label: 'saoMipTarget',
        size: [mipWidth, mipHeight],
        format: 'rgb10a2unorm',
        usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING,
        mipLevelCount: numMipLevels
      });

      _saoMipView = _saoMipTarget.createView({
        baseMipLevel: 0,
        mipLevelCount: numMipLevels
      });
    }

    for (let i = 0; i < numMipLevels; i++) {
      const mipView = _saoMipTarget.createView({
        baseMipLevel: i,
        mipLevelCount: 1
      });
      _saoMipViews.push(mipView);

      // The bind group for rendering to the mipmap target
      _saoMipBindGroups.push(_device.createBindGroup({
        label: 'saoMipBindGroup ' + i,
        layout: _saoMipLayout,
        entries: [
        {
          binding: 0,
          resource: i === 0 ? viewDepthTargetView : _saoMipViews[i - 1]
        }]

      }));
    }

    //Bind group for main SAO computation pass.
    //Takes in the normals and depth targets from the main pass
    _saoBindGroup = _device.createBindGroup({
      label: 'sao bind group',
      layout: _saoBindLayout,
      entries: [
      {
        binding: 0,
        resource: rt.getNormalsTarget().createView()
      },
      {
        binding: 1,
        resource: viewDepthTargetView
      },
      {
        binding: 2,
        resource: {
          buffer: _saoUniforms.getBuffer()
        }
      },
      {
        binding: 3,
        resource: _saoMipView
      }]

    });

    //Gaussian blur x-axis
    _saoBlurBindGroup1 = _device.createBindGroup({
      layout: _saoBlurLayout,
      entries: [
      {
        binding: 0,
        resource: _ssaoTargetView
      },
      {
        binding: 1,
        resource: {
          buffer: _saoBlurUniforms.getBuffer()
        }
      }]

    });

    //Gaussian blur y-axis
    _saoBlurBindGroup2 = _device.createBindGroup({
      layout: _saoBlurLayout,
      entries: [
      {
        binding: 0,
        resource: _post0TargetView
      },
      {
        binding: 1,
        resource: {
          buffer: _saoBlurUniforms.getBuffer()
        }
      }]

    });
  };

  this.getTargetView = function () {
    return _ssaoTarget.createView();
  };

  this.run = function (camera) {

    if (!_device) return;

    _saoUniforms.setCamera(camera, _w, _h);
    _saoUniforms.upload();

    const commandEncoder = _device.createCommandEncoder();
    let pass;

    // Generate mip maps for the depth target
    if (_saoMipTarget && _saoMipViews.length) {
      for (let i = 0; i < _saoMipViews.length; i++) {
        _saoPassDescriptor.colorAttachments[0].view = _saoMipViews[i];
        pass = commandEncoder.beginRenderPass(_saoPassDescriptor);
        pass.setPipeline(_saoMipPipeline);
        pass.setBindGroup(0, _saoMipBindGroups[i]);
        pass.draw(3);
        pass.end();
      }
    }

    //Run the main SAO calculation pass into the _ssaoTarget
    _saoPassDescriptor.colorAttachments[0].view = _ssaoTargetView;
    pass = commandEncoder.beginRenderPass(_saoPassDescriptor);
    pass.setPipeline(_saoPipeline);
    pass.setBindGroup(0, _saoBindGroup);
    pass.draw(3);
    pass.end();

    //Do the separable blur, horizontal and vertical
    //First from _ssaoTarget into post0 target

    _saoPassDescriptor.colorAttachments[0].view = _post0TargetView;
    pass = commandEncoder.beginRenderPass(_saoPassDescriptor);
    pass.setPipeline(_saoBlurPipelineX);
    pass.setBindGroup(0, _saoBlurBindGroup1);
    pass.draw(3);
    pass.end();

    //Do the separable blur, horizontal and vertical
    //First from _ssaoTarget into post0 target

    //then from post0 target back into _ssaoTarget
    _saoPassDescriptor.colorAttachments[0].view = _ssaoTargetView;
    pass = commandEncoder.beginRenderPass(_saoPassDescriptor);
    pass.setPipeline(_saoBlurPipelineY);
    pass.setBindGroup(0, _saoBlurBindGroup2);
    pass.draw(3);
    pass.end();

    _device.queue.submit([commandEncoder.finish()]);
  };

}