import { getGlobal } from "../../compat";


// This is a very simple initial version of a screenshot function for WebGPU.
// The original function used with WebGL is pretty complex and allows to render an image of the scene in the
// background, with many optional customizations, e.g. resolution, camera position, a subset of models, custom
// render options, etc. None of this is used in Tandem, which only uses the screenshot feature to generate thumbnails.
// So we keep it simple for now and just grab the canvas image content. Most parameters are ignored, but kept for API
// 'compatibility'.
/**
 * Get a screenshot of the viewer canvas.
 * @param {Number} w The width of the screenshot. Ignored.
 * @param {Number} h The height of the screenshot. Ignored.
 * @param {Function} onFinished A callback that is invoked with the screenshot encoded as a blob URL.
 * @param {Object} options Options to customize the screenshot. Ignored.
 * @param {Viewer3DImpl} viewerImpl The viewer implementation class instance. (TODO: Why is this exposed in a public API?)
 */
function getScreenShot(w, h, onFinished, options, viewerImpl) {
  // Tandem-specific work-around:
  // Loading a facility without a thumbnail will invoke this function on the 'geom loaded' event.
  // The event is fired before the final image is rendered in some cases, especially for smaller models.
  // This can lead to incomplete or even empty (background) thumbnails in Tandem. So we defer this screenshot
  // just enough to run into the next animation frame first. If there's work to do, we wait for the render to
  // finish and otherwise take the screenshot right away. The delay is hardly noticeable for user-initiated
  // screenshots.
  setTimeout(() => {
    viewerImpl.waitForRendererIdle().then(() => {

      // I don't fully understand why, but without this line, screenshots sometimes contain the previous frame.
      viewerImpl.renderer().presentBuffer();

      viewerImpl.canvas.toBlob(function (blob) {
        const newBlobURL = getGlobal().URL.createObjectURL(blob);
        onFinished(newBlobURL);
      });
    });
  }, 67); // 67 ms for a progressive target of 15 fps
}

export const ScreenShot = {
  getScreenShot
};