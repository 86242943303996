export let MeshFlags = {
  // FragmentList flags
  //visibility/highlight bitmask flags

  //Byte 0

  //NOTE: This is confusing and it should be fixed, but when the MESH_VISIBLE bit is off, the mesh
  //will draw in ghosted mode. To completely skip drawing a mesh, set the HIDE flag.
  MESH_VISIBLE: 1,
  MESH_HIGHLIGHTED: 2,
  MESH_HIDE: 4,
  MESH_ISLINE: 8,
  MESH_MOVED: 0x10, // indicates if an animation matrix is set
  MESH_RENDERFLAG: 0x20,
  MESH_ISPOINT: 0x40, // indicates that the mesh is vertex-only
  //TODO: Two bits are enough to hold ISLINE, ISWIDELINE and ISPOINT, we don't need to waste three,
  MESH_ISWIDELINE: 0x80 // indicates that the mesh is wide line
};