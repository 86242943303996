/** Partitions the [0,1] space in nMajorTicks, optionally not including first and last. */
function getScaledTicks(nMajorTicks, hideFirstAndLast) {
  const nSpaces = Math.max(nMajorTicks, 2) - 1;
  const ticks = [];
  for (let s = 0; s <= nSpaces; s++) {
    if (hideFirstAndLast && (s === 0 || s === nSpaces)) {
      continue;
    }
    ticks.push(s / nSpaces);
  }
  return ticks;
}

/** A legend for a continuous scale presented on a color gradient. */
export class ContinuousScale {
  /**
   * A legend for a continuous scale presented on a color gradient.
   *
   * @param {tickLabelCb} tickLabelCb A callback to provide label for ticks.
   * @param {ColorStop[]} colorStops A series of color and their location on the 0-1 space.
   * @param {int} nMajorTicks Number of major tick, min 2
   * @param {boolean} hideFirstAndLast Are first and last ticks showing
   * @param {int} width Width of the generated SVG viewbox
   */
  constructor()



  {let tickLabelCb = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : () => '';let colorStops = arguments.length > 1 ? arguments[1] : undefined;let nMajorTicks = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 5;let hideFirstAndLast = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;let width = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 200;
    this.ticks = getScaledTicks(nMajorTicks, hideFirstAndLast);
    this.width = width;

    const gradientStops = this.createStops(colorStops);
    // Gradient is backed with a white rect to respect the stop's opacity even in dark mode.
    const gradientRect = `
            <rect x="0" y="0" width="${width}" height="12" fill="white" />
            <rect x="0" y="0" width="${width}" height="12" fill="url('#heatmap-gradient')" />`;
    const ticks = this.createTicks(tickLabelCb, '');

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute("viewBox", `0 0 ${width} 29`);
    svg.innerHTML = `<defs><linearGradient id="heatmap-gradient">${gradientStops}</linearGradient></defs>` +
    `${gradientRect}` +
    `<g id="heatmap-ticks">${ticks}</g>`;
    svg.classList.add('continuous-scale');
    this.svg = svg;
  }

  /**
   * Update color stops
   * @param {ColorStop} colorStops
   */
  updateGradients(colorStops) {
    let el = this.svg.getElementById('heatmap-gradient');
    while (el.firstChild) el.removeChild(el.firstChild);

    el.innerHTML = this.createStops(colorStops);
  }

  /**
   * Update tick marks
   * @param {tickLabelCb} tickLabelCb
   * @param {tickUnit} 
   */
  updateTicks(tickLabelCb, tickUnit) {
    let el = this.svg.getElementById('heatmap-ticks');
    while (el.firstChild) el.removeChild(el.firstChild);

    el.innerHTML = this.createTicks(tickLabelCb, tickUnit);
  }

  /** @private */
  createStops(colorStops) {
    let stops = '';
    for (let st of colorStops) {
      stops += `<stop offset="${st.offset * 100}%" stop-color="${st.color}" stop-opacity="${st.opacity}" />`;
    }
    return stops;
  }

  /** @private */
  createTicks(tickLabelCb, tickUnit) {
    let tickUI = '';
    for (let tick of this.ticks) {
      tickUI += `<g transform="translate(${tick * this.width} 0)">` +
      `<rect width="1" height="12" fill="white"></rect>` +
      `<text class="heatmap-gradient-text" fill="grey" y="27" text-anchor="middle" font-size="0.6em">${tickLabelCb(tick)}</text>` +
      `</g>`;
    }
    tickUI += `<g transform="translate(${this.width} 0)">` +
    `<text class="heatmap-gradient-text heatmap-gradient-unit" fill="grey" y="27" font-size="0.6em"> ${tickUnit} </text>` +
    `</g>`;
    return tickUI;
  }
}

/** Creates a SVG color gradient */
export function createColorBar(id, colorStops) {let width = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 200;
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute("viewBox", `0 0 ${width} 20`);
  svg.style.width = width + 'px';

  // Gradient is backed with a white rect to respect the stop's opacity even in dark mode.
  svg.innerHTML = `<defs>
				<linearGradient id="colorbar-${id}">
					${colorStops.map((st) => `<stop offset="${st.offset * 100}%" stop-color="${st.color}" stop-opacity="${st.opacity}" />`).join("")}
				</linearGradient>
			</defs>
            <rect x="0" y="0" width="${width}" height="20" fill="white" />
            <rect x="0" y="0" width="${width}" height="20" fill="url('#colorbar-${id}')" />`;

  return svg;
}

/**
 * Returns the tick label given a location on the 0 to 1 space.
 *
 * @callback tickLabelCb
 * @param {number} loc Location on 0-1 space.
 * @return {string} The label for the tick
 */

/**
 * A color stop, part of defining a gradient
 * @typedef {Object} ColorStop
 * @property {number} offset Position of this color on the 0-1 space
 * @property {string} color Hex representation of the color
 * @property {number} opacity Color opacity from 0 (transparent) to 1 (opaque)
 */