import { LmvMatrix4 as Matrix4 } from '../../wgs/scene/LmvMatrix4';
import { LmvBox3 as Box3 } from '../../wgs/scene/LmvBox3';
import { logger } from "../../logger/Logger";
import { TextureLoader } from "./TextureLoader";
import { NodeArray } from "../../wgs/scene/BVHBuilder";
import { pathToURL, ViewingService } from "../../net/Xhr";
import { DtPackage } from "./DtVizLoad";
import * as et from "../../application/EventTypes";
import { isMobileDevice } from "../../compat";
import { blobToJson } from "../encoding/utf8";
import { InstanceTreeAccess } from "../../wgs/scene/InstanceTreeStorage";
import { InstanceTree } from "../../wgs/scene/InstanceTree";
import { VisibilityManager } from "../../tools/VisibilityManager";
import { MESH_RECEIVE_EVENT, MESH_FAILED_EVENT, MATERIAL_RECEIVE_EVENT, MATERIAL_FAILED_EVENT } from "./DtResourceCache";
import { GeometryList } from "../../wgs/scene/GeometryList";
//import { createWireframe } from "../../wgs/scene/DeriveTopology;

const WORKER_LOAD_PROPERTYDB = "LOAD_PROPERTYDB";
const WORKER_DT_LOAD_BVH = "LOAD_BVH";

// Returns the surface area of a THREE.Box3.
function getBoxSurfaceArea(box) {
  var dx = box.max.x - box.min.x;
  var dy = box.max.y - box.min.y;
  var dz = box.max.z - box.min.z;
  return 2.0 * (dx * dy + dy * dz + dz * dx);
}

let _tmpBox = new Box3();

export function computeFragImportanceModelSpace(model, fragId) {

  // get geom and bbox of this fragment
  let frags = model.getFragmentList();
  frags.getWorldBounds(fragId, _tmpBox);

  let sarea = getBoxSurfaceArea(_tmpBox);
  let pcount = frags.fragments.polygonCounts[fragId] || 8;

  return sarea / pcount;
}


/** @constructor */
export function DtLoader(parent) {
  this.viewer3DImpl = parent;
  this.loading = false;
  this.tmpMatrix = new Matrix4();

  this.logger = logger;

  this.pendingMaterials = new Map();

  this.pendingMeshes = new Map();

  this.nextBvhMessage = 1;
  this.lastBvhMessage = 0;

  this.trackFragmentProgress = true;
}


DtLoader.prototype.dtor = function () {
  // Cancel all potential process on loading a file.

  // 1. load model root (aka. svf) can be cancelled.
  //

  if (this.svf) {

    if (!this.svf.loadDone)
    console.log("stopping load before it was complete");

    this.svf.abort();

    //Stop instance tree load operation if any
    if (this.inProgress) {

      console.log("Stopping in progress instance tree load");

      // If loading is in progress, make sure that no callbacks are triggered anymore
      let loadStarted = this.inProgress;
      let loadEnded = this.instanceTree || this.model.propertyDbError;
      if (loadStarted && !loadEnded) {

        // Some code outside may be waiting for getObjectTree() to fail or succeed.
        // Since we disconnected the worker callbacks, no events will be dispatached anymore.
        // So, we dispatch one right now to avoid getObjectTree() from hanging forever.

        // Note that this.model.propertyDBError is used by getObjectTree() to distinguish between
        // success and failure. So, we have to set it before triggering the event.
        this.model.propertyDbError = {
          // Indicates that propDb was unloaded while waiting for getObjectTree()
          propDbWasUnloaded: true
        };

        this.viewer3DImpl.api.dispatchEvent({
          type: et.OBJECT_TREE_UNAVAILABLE_EVENT,
          svf: this.model.getData(),
          model: this.model,
          target: this
        });
      }

      this.model.disposePropertyWorker();
    }
  }


  // 5. Cancel all running requests in shared geometry worker
  //
  if (this.viewer3DImpl.geomCache() && this.model) {
    if (this.loading && this.svf?.geomMetadata) {
      this.viewer3DImpl.geomCache().cancelRequests(this.svf.geomMetadata.hashToIndex);
      this.viewer3DImpl.geomCache().cancelRequests(this.svf.materialHashes.hashToIndex);
    }

    this.removeMeshReceiveListener();
  }

  // and clear metadata.
  if (!this.options.keepLoaderAlive) {
    this.viewer3DImpl = null;
    this.model = null;
    this.svf = null;
    this.logger = null;
    this.tmpMatrix = null;
  }

  this.loading = false;
  this.loadTime = 0;
};

DtLoader.prototype.isValid = function () {
  return this.viewer3DImpl != null;
};

// Stop listening to mesh receive events
DtLoader.prototype.removeMeshReceiveListener = function () {
  if (this.meshReceiveListener) {
    let gc = this.viewer3DImpl.geomCache();
    gc.updateMruTimestamps();
    gc.removeEventListener(MESH_RECEIVE_EVENT, this.meshReceiveListener);
    gc.removeEventListener(MESH_FAILED_EVENT, this.meshReceiveListener);
    gc.removeEventListener(MATERIAL_RECEIVE_EVENT, this.materialReceiveListener);
    gc.removeEventListener(MATERIAL_FAILED_EVENT, this.materialReceiveListener);
    this.meshReceiveListener = null;
  }
};

DtLoader.prototype.initRoomMaterial = function () {

  const color = 0xddeeff;

  const material = new THREE.MeshPhongMaterial({
    color,
    specular: 0x080808,
    ambient: 0,
    opacity: 0.001, // below alpha cut off -> effectively not rendered except for edges
    transparent: true
  });

  material.packedNormals = true;
  material.depthWrite = false;
  material.depthTest = true;
  material.isRoomMaterial = true;

  let matId = this.model.id + ":rooms";
  this.viewer3DImpl.matman().addMaterial(matId, material);
  this.roomMaterial = material;
};

DtLoader.prototype.loadFile = function (path, options, onDone, onWorkerStart) {
  if (!this.viewer3DImpl) {
    logger.log("DT loader was already destructed. So no longer usable.");
    return false;
  }

  if (this.loading) {
    logger.log("Loading of DT already in progress. Ignoring new request.");
    return false;
  }

  // Mark it as loading now.
  this.loading = true;

  this.currentLoadPath = path;
  let basePath = "";
  let lastSlash = this.currentLoadPath.lastIndexOf("/");
  if (lastSlash !== -1)
  basePath = this.currentLoadPath.substr(0, lastSlash + 1);

  let modelIdIdx = basePath.lastIndexOf(":");
  let modelId = basePath.slice(modelIdIdx + 1, lastSlash);

  this.basePath = basePath;

  this.options = options;
  this.queryParams = options.queryParams;

  this.model = options.modelObj;

  this.initRoomMaterial();

  let loadContext = {
    ...this.model.loadContext,
    modelUrn: options.modelUrn,
    modelId: modelId,
    basePath: basePath,
    delayGeomLoad: options.delayGeomLoad,
    globalOffset: options.globalOffset,
    fragmentTransformsDouble: options.fragmentTransformsDouble,
    placementTransform: options.placementTransform,
    applyRefPoint: options.applyRefPoint,
    bvhOptions: options.bvhOptions || { isWeakDevice: isMobileDevice() },
    applyScaling: options.applyScaling,
    queryParams: options.queryParams,
    overrideRefPointTransform: options.overrideRefPointTransform,
    mustUsePrimary: Date.now() - this.model.lastChangeTime < 60000
  };

  // The request failure parameters received by onFailureCallback (e.g. httpStatusCode) cannot just be forwarded to onDone().
  // Instead, we have to pass them through ViewingService.defaultFailureCallback, which converts them to error parameters
  // and calls loadContext.raiseError with them.
  loadContext.raiseError = function (code, msg, args) {
    let error = { "code": code, "msg": msg, "args": args };
    onDone && onDone(error);
  };
  loadContext.onFailureCallback = ViewingService.defaultFailureCallback.bind(loadContext);

  this.loadContext = loadContext;
  this.onModelRootDoneCallback = onDone;

  let svf = this.svf = new DtPackage(loadContext, this);
  this.model.myData = svf;
  //TODO: modelId and modelUrn need to be reconciled eventually (do we want the urn prefix or not?)
  svf.modelUrn = loadContext.modelUrn;
  svf.basePath = loadContext.basePath;
  svf.basePathCdnGeom = svf.makeSharedResourcePath(loadContext.otg_cdn, "geometry", "");
  svf.basePathCdnMat = svf.makeSharedResourcePath(loadContext.otg_cdn, "materials", "");


  //There are three parts to loading a model that are done in different order depending on the initial visibility
  //of a model.
  // Scenario 1: Model is fully visible.
  //      1. metadata json and fragment list are requested first, in parallel, in svf.beginLoad()
  //      2. instance tree request is kicked off after the fragment list is fully loaded
  // Scenario 2: Model is partially visible (because of a saved view filter)
  //      1. instance tree and metadata json are requested first, and then optionally any fragment list data
  //      2. once instance tree is fully loaded, it initializes an empty fragment list and
  //         we call loadFragmentList(). See implementation of loadInstanceTree() for details

  //If the model will be initially hidden from view, then kick off by loading the object tree
  //used to populate the facets and the model metadata JSON, but delay loading of any actual graphical information
  if (this.options.loadAsHidden || this.options.delayGeomLoad) {
    this.loadInstanceTree(true).catch(() => {
    });
  } else {
    this.svf.beginLoad(pathToURL(this.currentLoadPath), this.options.loadAsHidden, loadContext.delayGeomLoad ? [] : null);
  }

  //Set up event handlers for mesh and material receiving. Will also kick off opening of geometry cache's web sockets
  setTimeout(() => this.initResourceCallbacks(), 0);

  //We don't use a worker for OTG root load, so we call this back immediately
  //We will use the worker for heavy tasks like BVH compute after we get the model root file.
  onWorkerStart && onWorkerStart();

  return true;
};


DtLoader.prototype.initResourceCallbacks = function () {

  // init shared cache on first use
  let geomCache = this.viewer3DImpl.geomCache();

  // Let the geom cache prepare the worker (e.g. open web socket connection or cache).
  // This needs to be called for every model in case a previous facility unload closed the web sockets,
  // or because we use a separate cache per model.
  // The modelId is provided to potentially open a cache eagerly. We don't provide it for the default model,
  // to avoid creating many empty cache buckets. The cache will still work if we actually load data for the default
  // model, it will just not be opened eagerly.
  geomCache.initWorker(this.model.isDefault() ? undefined : this.loadContext.modelId);

  this.meshReceiveListener = (data) => {
    if (data.error && data.error.args) {
      this.onMeshError(data);
    } else {
      this.onMeshReceived(data.geom);
    }
  };

  geomCache.addEventListener(MESH_RECEIVE_EVENT, this.meshReceiveListener);
  geomCache.addEventListener(MESH_FAILED_EVENT, this.meshReceiveListener);

  this.materialReceiveListener = (data) => {
    if (data.error || !data.material || !data.material.length) {
      this.onMaterialLoaded(null, data.hash);
    } else {
      this.onMaterialLoaded(blobToJson(data.material), data.hash);
    }
  };

  geomCache.addEventListener(MATERIAL_RECEIVE_EVENT, this.materialReceiveListener);
  geomCache.addEventListener(MATERIAL_FAILED_EVENT, this.materialReceiveListener);
};

DtLoader.prototype.makeBVHInWorker = function () {

  const onOtgWorkerEvent = (data) => {
    if (data.bvh) {

      if (!data.messageId || data.messageId <= this.lastBvhMessage) {
        console.warn("received stale BVH");
        return;
      }
      this.lastBvhMessage = data.messageId;

      //console.log("Received BVH from worker", this.model.fileName());

      let bvh = data.bvh;
      if (this.model) {

        this.svf.bvh = bvh;
        this.model.setBVH(new NodeArray(bvh.nodes, bvh.useLeanNodes), bvh.primitives, this.options.bvhOptions);

        if (this.viewer3DImpl) {

          // Refresh viewer if model is visible.
          if (this.viewer3DImpl.modelVisible(this.model.id)) {
            this.viewer3DImpl.invalidate(false, true);
          }
        }
      }
    }
  };

  //We can kick off the request for the fragments-extra file, needed
  //for the BVH build as soon as we have the metadata (i.e. placement transform)
  //Do this on the worker thread, because the BVH build can take a while.
  let workerContext = {
    ...this.loadContext,

    operation: WORKER_DT_LOAD_BVH,
    raiseError: null,
    onFailureCallback: null,
    onLoaderEvent: null,

    dt_boxes: this.svf.fragments.boxes,
    dt_polygonCounts: this.svf.fragments.polygonCounts,
    dt_flags: this.svf.fragments.visibilityFlags,

    messageId: this.nextBvhMessage++
  };


  this.model.asyncPropertyOperation(workerContext, onOtgWorkerEvent, onOtgWorkerEvent);

};

//Attempts to turn on display of a received fragment.
//If the geometry or material is missing, issue requests for those
//and delay the activation. Once the material or mesh comes in, they
//will attempt this function again.
DtLoader.prototype.tryToActivateFragment = function (fragId, whichCaller) {

  let rm = this.model;

  //Was loading canceled?
  if (!rm)
  return true;

  let fl = rm.getFragmentList();
  let existingMat = fl.getMaterial(fragId);
  let existingGeom = fl.getGeometry(fragId);
  let svf = this.svf;

  //Early out in case the fragment already has known material and geometry.
  //This of course assumes the material and geometry aren't going to change
  //for a fragment, once it's loaded.
  if (existingMat && existingGeom) {
    this.trackGeomLoadProgress(svf, fragId, false);
    return true;
  }

  //TODO: The logic below can probably be simplified a bit given the early out above. Step by step...

  let fragments = svf.fragments;

  let skipLoad = !fragments.isMeshLoaded(fragId);

  if (skipLoad) {
    this.trackGeomLoadProgress(svf, fragId, false);
    return true;
  }

  let haveToWait = false;

  //The tryToActivate function can be called up to three times, until all the
  //needed parts are received.
  // Before we can safely consider a fragment as finished, we must make sure that there are no pending
  // tryToActivate(fragId, "material") or "geometry" calls that will come later.

  //1. Check if the material is done

  let materialIsAlreadySet = false;

  let materialId = fragments.materials[fragId];
  let matHash = svf.getMaterialHash(materialId);
  let materialIsLoaded = false;

  if (existingMat && (existingMat.hash === matHash || existingMat === this.roomMaterial)) {
    materialIsAlreadySet = true;
  } else {
    materialIsLoaded = this.findOrLoadMaterial(rm, matHash, materialId);
    if (!materialIsLoaded) {

      if (whichCaller === "fragment") {
        //Material is not yet available, so we will delay turning on the fragment until it arrives
        this.pendingMaterials.get(matHash).push(fragId);
      }

      if (whichCaller !== "material") {
        haveToWait = true;
      } else {

        //it means the material failed to load, so we won't wait for it.
      }}
  }

  //2. Check if the mesh is done

  // Note that otg translation may assign geomIndex 0 to some fragments by design.
  // This happens when the source fragment geometry was degenerated.
  // Therefore, we do not report any warning or error for this case.
  //don't block overall progress because of this -- mark the fragment as success.
  let geomId = fragments.geomDataIndexes[fragId];
  if (geomId === 0) {
    if (materialIsLoaded || whichCaller === "material") {
      // A fragment with null-geom may still have a material. If so, we wait for the material before we consider it as finished.
      // This makes sure that we don't count this fragment twice. Note that we cannot just check whichCaller==="fragment" here:
      // This would still cause errors if the material comes in later after onGeomLoadDone().
      this.trackGeomLoadProgress(svf, fragId, false);
      return true;
    }
    return false;
  }

  let geom = rm.getGeometryList().getGeometry(geomId);
  if (!geom) {
    //See if the geometry is already in the resource cache -- this can happen
    //when a saved view was previously loaded, and now we are switching back to it again
    //In that case the geometry is removed from the GeometryList but remains in the resource cache.
    //The check here just bypasses all the overhead of loadGeometry(), which achieving the same
    //TODO: We can also avoid executing some of the fragment setup code below (setupMesh etc)
    let geomCache = this.viewer3DImpl.geomCache();
    let geomHash = svf.getGeometryHash(geomId);
    geom = geomCache.getGeometry(geomHash);
    if (geom) {
      rm.getGeometryList().addGeometry(geom, 1, geomId);
    } else {
      if (whichCaller === "fragment") {
        //Mesh is not yet available, so we will request it and
        //delay turning on the fragment until it arrives
        this.loadGeometry(geomHash, geomId, fragId);
      }
      haveToWait = true;
    }
  }

  if (haveToWait)
  return false;

  let geomIsAlreadyThere = existingGeom === geom;

  //The fragment may be already loaded and is now being turned on again
  //In such case, we do not need to go through the process of setting its
  //material and mesh again, we just need to have its flags set correctly (done elsewhere, e.g. FacetsManager.updateIsolation)
  if (!geomIsAlreadyThere || !materialIsAlreadySet) {
    //if (this.options.createWireframe)
    //    createWireframe(geom, this.tmpMatrix);

    //We get the matrix from the fragments and we pass it back into setupMesh
    //with the activateFragment call, but this is to maintain the
    //ability to add a plain THREE.Mesh -- otherwise it could be simpler
    fl.getOriginalWorldMatrix(fragId, this.tmpMatrix);

    let m = this.viewer3DImpl.setupMesh(rm, geom, matHash, this.tmpMatrix);

    // provide correct geometry id. (see GeometryList.setMesh). Note that we cannot use
    // geom.svfid, because geomIds are model-specific and geometries may be shared.
    m.geomId = geomId;

    //If there is a placement transform, we tell activateFragment to also recompute the
    //world space bounding box of the fragment from the raw geometry model box, for a tighter
    //fit compared to what we get when loading the fragment list initially.
    rm.activateFragment(fragId, m, !!svf.placementTransform);

    //Special handling for room geometry
    let isRoom = fragments.isRoom(fragId);

    if (isRoom) {
      fl.setMaterial(fragId, this.roomMaterial);
    }

  }

  this.restoreSelectionHighlight(fragId);
  this.trackGeomLoadProgress(svf, fragId, false);

  return true;
};

DtLoader.prototype.notifyModelRootDone = function () {

  if (!this.svf) {
    console.error("load callback called after load was aborted");
    return;
  }

  this.onModelRootLoadDone(this.svf);


  //If fragment list loading is delayed, signal model root loading done already
  //TODO: Not sure we want to fire this here -- currently users of that event may expect
  //the fragment list to be done, which is not the case here. We may need another event type
  //if (this.options.loadAsHidden) {
  //    setTimeout(() => this.viewer3DImpl.api.fireEvent({type:et.MODEL_ROOT_LOADED_EVENT, svf:this.svf, model:this.model}), 0);
  //}
};

DtLoader.prototype.notifyFragmentDone = function (fragId) {

  if (!this.svf) {
    console.error("load callback called after load was aborted");
    return true;
  }

  //TODO: Don't think we really need to set hide flag here.
  //It's only ever off for rooms, and those are either locked in hidden mode
  //or shown in showRoomFragment().
  //Get the initial fragment flags from the loader and set them into the scene FragmentList
  //let newHidden = (this.svf.fragments.visibilityFlags[fragId] & MeshFlags.MESH_HIDE) !== 0;
  //this.model.getFragmentList().setFragOff(fragId, newHidden);

  return this.tryToActivateFragment(fragId, "fragment");
};

DtLoader.prototype.notifyAllFragmentsDone = function (rowIds) {

  if (!this.svf) {
    console.error("load callback called after load was aborted");
    return;
  }

  //TODO: This stuff doesn't work well with partial fragment list loading

  this.fragmentsLoaded = true;

  if (rowIds?.keys && !rowIds.keys.length) {

    //No new fragments were loaded, no need to rebuild bvh
  } else {this.makeBVHInWorker();
  }

  //If instance tree was not loaded up front, do it now
  if (!this.svf.instanceTree) {
    this.loadInstanceTree(false).catch(() => {
    });
  } else {
    this.svf.loadDone = true;
  }

  //NOTE: If we enable firing this event earlier (see handling of "otg_root" message above)
  //we need to move this firing here to happen inside the if (!scope.svf.instanceTree) check above.
  this.viewer3DImpl.api.fireEvent({ type: et.MODEL_ROOT_LOADED_EVENT, svf: this.svf, model: this.model });
};


DtLoader.prototype.onModelRootLoadDone = function (svf) {

  // Mark svf as Oscar-file. (which uses sharable materials and geometry)
  svf.isOTG = true;

  svf.failedFrags = {};
  svf.failedMeshes = {};
  svf.failedMaterials = {};

  svf.geomMemory = 0;
  svf.gpuNumMeshes = 0;
  svf.gpuMeshMemory = 0;

  svf.nextRepaintPolys = 0;
  svf.numRepaints = 0;

  svf.urn = this.svfUrn;

  svf.basePath = this.basePath;

  svf.loadOptions = this.options;

  svf.loadDone = false;

  // Create the API Model object and its render proxy
  let model = this.model;
  model.setData(svf);
  model.loader = this;

  let gl = new GeometryList(svf.numGeoms || 0, this.viewer3DImpl.memTracker(), false, false, svf.isOTG);
  model.initialize(gl);

  this.onModelRootDoneCallback?.(null, this.model);
};

// Called whenever a geom load request is finished or or has failed.
DtLoader.prototype.trackGeomLoadProgress = function (svf, fragId, failed) {

  if (!this.trackFragmentProgress) {
    return;
  }

  if (failed) {
    //TODO: failedFrags can be removed, once we make sure
    //that we are not calling this function with the same fragment twice.
    if (svf.failedFrags[fragId]) {
      console.log("Double fail", fragId);
      return;
    }

    svf.failedFrags[fragId] = 1;
  }

  // Inform the geom cache about the progress (for the progress bar)
  this.svf._updateProgress(1);

  //repaint every once in a while -- more initially, less as the load drags on.
  let geomList = this.model.getGeometryList();
  if (geomList.memTracker.geomPolyCount > svf.nextRepaintPolys) {
    //logger.log("num loaded " + numLoaded);
    svf.numRepaints++;
    svf.nextRepaintPolys += 100000 * Math.pow(1.5, svf.numRepaints);

    // refresh viewer if model is visible
    if (this.viewer3DImpl.modelVisible(this.model.id)) {
      //console.log("Repaint");

      this.viewer3DImpl.invalidate(false, true);
    }
  }

};

DtLoader.prototype.onMaterialLoaded = async function (matObj, matHash, matId) {

  if (!this.loading) {
    // This can only happen if dtor was called while a loadMaterial call is in progress.
    // In this case, we can just ignore the callback.
    return;
  }

  // get fragIds that need this material
  let fragments = this.pendingMaterials.get(matHash);

  // Note that onMaterialLoaded is triggered by an EvenListener on geomCache. So, we may also receive calls
  // for materials that we are not wait for, just because other loaders have requested them in parallel.
  // In this case, we must ignore the event.
  if (!fragments) {
    return;
  }

  let matman = this.viewer3DImpl.matman();

  if (matObj) {

    matObj.hash = matHash;
    let surfaceMat = await matman.convertSharedMaterial(this.model, matObj, matHash);
    surfaceMat.modelId = this.model.id;

    TextureLoader.loadMaterialTextures(this.model, surfaceMat, this.viewer3DImpl);

    if (matman.hasTwoSidedMaterials()) {
      this.viewer3DImpl.renderer().toggleTwoSided(true);
    }

  } else {

    this.svf.failedMaterials[matHash] = 1;

  }

  for (let i = 0; i < fragments.length; i++) {
    this.tryToActivateFragment(fragments[i], "material");
  }

  this.pendingMaterials.delete(matHash);
};

DtLoader.prototype.findOrLoadMaterial = function (model, matHash, matId) {

  //check if it's already requested, but the request is not complete
  //
  // NOTE: If another OTG loader adds this material during loading, matman.findMaterial(..) may actually succeed already - even if we have a pending request.
  //       However, we consider the material as missing until the request is finished. In this way, only 2 cases are possible:
  //        a) A material was already loaded on first need => No material requests
  //        b) We requested the material => Once the request is finished, tryToActivate() will be triggered
  //           for ALL fragments using the material - no matter whether the material was added meanwhile by someone else or not.
  //
  //       If we would allow to get materials earlier, it would get very confusing to find out when a fragment is actually finished:
  //       Some fragments would be notified when the load request is done, but some would not - depending on timing.
  if (this.pendingMaterials.has(matHash)) {
    return false;
  }

  //Check if it's already in the material manager
  let matman = this.viewer3DImpl.matman();


  if (matId === 0) {
    return matman.defaultMaterial;
  }

  if (!matHash) {
    console.error("Unknown hash for matId", matId);
    return matman.defaultMaterial;
  }

  let mat = matman.findMaterial(model, matHash);

  if (mat)
  return true;

  //If it's not even requested yet, kick off the request
  this.pendingMaterials.set(matHash, []);

  // load geometry or get it from cache
  let geomCache = this.viewer3DImpl.geomCache();
  geomCache.requestMaterial(this.svf.basePathCdnMat, matHash, matId, this.queryParams);

  return false;
};

DtLoader.prototype.loadGeometry = function (geomHash, geomIdx, fragId) {

  if (!geomHash) {
    console.error("Unknown hash");
  }

  //check if it's already requested, but the request is not complete
  if (this.pendingMeshes.has(geomHash)) {
    this.pendingMeshes.get(geomHash).push(fragId);
    return false;
  }

  //If it's not even requested yet, kick off the request
  this.pendingMeshes.set(geomHash, [fragId]);

  //svf.geomMetadata.hashToIndex.set(geomHash, geomIdx);
  let importance = computeFragImportanceModelSpace(this.model, fragId);

  // load geometry or get it from cache
  let geomCache = this.viewer3DImpl.geomCache();
  geomCache.requestGeometry(this.svf.basePathCdnGeom, geomHash, geomIdx, this.queryParams, importance);
};

DtLoader.prototype.onMeshError = function (mdata) {

  let geomHash = mdata.error.args.hash;

  this.svf.failedMeshes[geomHash] = 1;

  let frags = this.pendingMeshes.get(geomHash);

  if (!frags) {
    // The failed mesh has been requested by other loaders, but not by this one.
    return;
  }

  for (let i = 0; i < frags.length; i++) {
    this.trackGeomLoadProgress(this.svf, frags[i], true);
  }

  //this.svf.geomMetadata.hashToIndex.delete(geomHash);
  this.pendingMeshes.delete(geomHash);
};

DtLoader.prototype.onMeshReceived = function (geom) {

  let frags = this.pendingMeshes.get(geom.hash);

  //It's possible we receive an event from another loader and we use this geometry but no fragments that need it are loaded
  if (!frags) {
    return;
  }

  let rm = this.model;

  if (!rm && !this.options.keepLoaderAlive) {
    console.warn("Received geometry after loader was done. Possibly leaked event listener?", geom.hash);
    return;
  }

  let gl = rm.getGeometryList();

  let geomId = this.svf.geomMetadata.hashToIndex.get(geom.hash);

  //It's possible this fragment list does not use this geometry
  if (geomId === undefined)
  return;

  let geomAlreadyAdded = gl.getGeometry(geomId);

  //TODO: The instance count implied by frags.length is not necessarily correct
  //because the fragment list is loaded progressively and the mesh could be received
  //before all the fragments that reference it. Here we don't need absolute correctness.
  if (!geomAlreadyAdded)
  gl.addGeometry(geom, frags && frags.length || 1, geomId);else

  return; //geometry was already received, possibly due to sharing with the request done by another model loader in parallel

  if (this.svf.loadDone && !this.options.keepLoaderAlive) {
    console.error("Geometry received after load was done");
  }

  for (let i = 0; i < frags.length; i++) {
    this.tryToActivateFragment(frags[i], "geom");
  }

  //this.svf.geomMetadata.hashToIndex.delete(geom.hash);
  this.pendingMeshes.delete(geom.hash);
};

DtLoader.prototype.restoreSelectionHighlight = function (fragId) {

  //if the fragment was selected while its mesh was not available,
  //notify the selection manager to also create the highlight
  if (this.model.selector) {
    let dbId = this.model.getFragmentList().getDbIds(fragId);
    if (this.model.selector.isSelected(dbId)) {
      //This check assumes overlay highlighting is used (default for Dt stuff)
      //See Viewer3DImpl.highlightFragment for details
      let highlightId = this.model.id + ":" + fragId;
      if (!this.viewer3DImpl.selectionMeshes[highlightId]) {
        this.viewer3DImpl.highlightFragment(this.model, fragId, true, false);
      }
    }
  }
};

DtLoader.prototype.loadInstanceTree = async function (initFragmentList) {

  this.inProgress = true;

  let svf = this.model.getData();

  let dataSource = this.model.modelProps.dataSource;

  let xfer = {
    ...this.model.loadContext, //TODO: which parts of this are really needed for this operation?
    operation: WORKER_LOAD_PROPERTYDB,
    fragToDbId: initFragmentList ? null : svf.fragments.fragId2dbId, //the 1:1 mapping of fragment to dbId we got from the fragment list loading
    autoDbIds: initFragmentList ? null : svf.autoDbIds,
    queryParams: this.model.getQueryParams(),
    schemaVersion: dataSource.schemaVersion,
    importerVersion: dataSource.importerVersion
  };

  let result;
  let svfMetadata;
  try {

    let p1 = this.model.asyncPropertyOperation(xfer);
    let p2 = initFragmentList ? this.svf.loadMetadata(pathToURL(this.currentLoadPath), true) : Promise.resolve();

    [result, svfMetadata] = await Promise.all([p1, p2]);

  } catch (error) {

    this.inProgress = false;

    this.model.propertyDbError = error;

    this.viewer3DImpl.api.dispatchEvent({ type: et.OBJECT_TREE_UNAVAILABLE_EVENT, svf: svf, model: this.model });

    return;
  }

  this.inProgress = false;

  if (initFragmentList) {
    //If we loaded the instance tree first, get the fragment information that was derived
    //from the instance tree response and use it to initialize the empty fragment list
    this.loadContext.fragId2dbId = result.fragId2dbId;
    this.loadContext.autoDbIds = result.autoDbIds;
    this.svf.initEmptyLists();
    this.notifyModelRootDone();
  } else {
    this.svf.loadDone = true;
  }

  //Wire up the received instance tree with the model instance
  let nodeAccess = new InstanceTreeAccess(result.instanceTreeStorage, result.rootId);

  this.instanceTree = new InstanceTree(nodeAccess, result.maxTreeDepth);

  //For backwards compatibility, svf.instanceTree has to be set also
  svf.instanceTree = this.instanceTree;

  // If nodeBoxes are not precomputed, we set the fragBoxes, so that instanceTree can compute nodeBoxes on-the-fly
  this.instanceTree.setFragmentList(this.model.getFragmentList());

  if (!this.model.visibilityManager) {
    this.model.visibilityManager = new VisibilityManager(this.viewer3DImpl, this.model);
  }

  if (initFragmentList) {
    //If we loaded the instance tree before the fragment list, kick off the fragment list
    this.svf.loadFragmentList(this.loadContext.delayGeomLoad ? [] : null);
  }

  const facetInfo = result.facetInfo;
  if (facetInfo) {
    this.model.updateFacetInfo(facetInfo);
  }

  this.viewer3DImpl.api.dispatchEvent({ type: et.OBJECT_TREE_CREATED_EVENT, svf: svf, model: this.model });

};

DtLoader.prototype.getObjectTree = function (onSuccess, onError) {

  if (this.instanceTree) {
    onSuccess(this.instanceTree);
  } else if (this.model.propertyDbError) {
    onError && onError(this.model.propertyDbError);
  } else {
    // Property Db has been requested; waiting for worker to complete //
    const listener = () => {
      this.viewer3DImpl.api.removeEventListener(et.OBJECT_TREE_CREATED_EVENT, listener);
      this.viewer3DImpl.api.removeEventListener(et.OBJECT_TREE_UNAVAILABLE_EVENT, listener);
      this.getObjectTree(onSuccess, onError);
    };
    this.viewer3DImpl.api.addEventListener(et.OBJECT_TREE_CREATED_EVENT, listener);
    this.viewer3DImpl.api.addEventListener(et.OBJECT_TREE_UNAVAILABLE_EVENT, listener);
  }
};

DtLoader.prototype.unloadFragment = function (fragId) {

  let fl = this.model.getFragmentList();
  let gl = this.model.getGeometryList();

  let wasLoaded = this.svf.fragments.isMeshLoaded(fragId);
  this.svf.fragments.setMeshLoaded(fragId, false);

  //TODO: do we also want to control the hide flag here?
  //fl.setFlagFragment(fragId, MeshFlags.MESH_HIDE, true);

  if (wasLoaded) {
    gl.removeGeometry(fl.getGeometryId(fragId));
  }
};

DtLoader.prototype.loadFragmentsForElements = async function (dbIds) {

  let fragIds;

  if (dbIds && dbIds.length) {
    fragIds = new Set();
    let it = this.instanceTree;
    for (let i = 0; i < dbIds.length; i++) {
      it.enumNodeFragments(dbIds[i], (fragId) => {
        fragIds.add(fragId);
      }, true);
    }
  }

  //Make sure the metadata JSON is already here or on the way
  if (!this.svf.metadata && !this.svf.metadataInProgress) {
    this.svf.loadMetadata(pathToURL(this.currentLoadPath));
  }

  //TODO: are we guaranteed that those are always leaf dbIds?
  //If not we need to create a new list based on the fragIds,
  //or just work with fragIds directly in the loadFragmentList function
  await this.svf.loadFragmentList(dbIds);

  return fragIds;
};

DtLoader.prototype.unloadFragmentsForElements = function (dbIds) {

  let fragIds;

  if (dbIds && dbIds.length) {
    fragIds = new Set();
    let it = this.instanceTree;
    for (let i = 0; i < dbIds.length; i++) {
      it.enumNodeFragments(dbIds[i], (fragId) => {
        fragIds.add(fragId);
      }, true);
    }
  }

  if (fragIds) {
    for (let fragId of fragIds) {
      this.unloadFragment(fragId);
    }
  } else {
    //TODO: Also nuke the FragmentList itself?
    let fl = this.model.getFragmentList();
    for (let i = 0; i < fl.getCount(); i++) {
      this.unloadFragment(i);
    }
  }

};

DtLoader.prototype.is3d = function () {
  return true;
};