import { EventUtils } from "../../../../application/EventUtils";
import { Label3D } from '../../../../gui/Label3D';
import { HUDLabel, LABEL_ANCHORING } from '../../HudLabel';

const INVALID_POS3D = { x: Infinity, y: Infinity, z: Infinity };

export class LevelLabel {

  constructor(level, name, hud) {
    this.level = level;
    this.name = name;
    this.label = this.#makeLabel(hud);
    this.#setAnchor(LABEL_ANCHORING.END);
  }

  dispose() {
    if (this.boundOnClick) this.label.removeEventListener(Label3D.Events.CLICK, this.boundOnClick);
    if (this.boundOnDblClick) this.label.removeEventListener(Label3D.Events.DBL_CLICK, this.boundOnDblClick);
    if (this.boundOnMouseOver) this.label.removeEventListener(Label3D.Events.ON_MOUSE_OVER, this.boundOnMouseOver);
    if (this.boundOnMouseOut) this.label.removeEventListener(Label3D.Events.ON_MOUSE_OUT, this.boundOnMouseOut);
    this.label.dtor();
  }

  getPosition3D() {
    return this.label.pos3D;
  }

  isVisible() {
    return this.label.visible;
  }

  setSelectHandler(handler) {
    if (this.boundOnClick) {
      this.label.removeEventListener(Label3D.Events.CLICK, this.boundOnClick);
    }
    if (handler) {
      this.boundOnClick = (_ref) => {let { event } = _ref;handler(event, this.level, Boolean(event.shiftKey));};
      this.label.addEventListener(Label3D.Events.CLICK, this.boundOnClick);
    }
  }

  setMouseOverHandler(handler) {
    if (this.boundOnMouseOver) {
      this.label.removeEventListener(Label3D.Events.ON_MOUSE_OVER, this.boundOnMouseOver);
    }
    if (handler) {
      this.boundOnMouseOver = (_ref2) => {let { event } = _ref2;handler(event, this.level);};
      this.label.addEventListener(Label3D.Events.ON_MOUSE_OVER, this.boundOnMouseOver);
    }
  }

  setMouseOutHandler(handler) {
    if (this.boundOnMouseOut) {
      this.label.removeEventListener(Label3D.Events.ON_MOUSE_OUT, this.boundOnMouseOut);
    }
    if (handler) {
      this.boundOnMouseOut = (_ref3) => {let { event } = _ref3;handler(event, this.level);};
      this.label.addEventListener(Label3D.Events.ON_MOUSE_OUT, this.boundOnMouseOut);
    }
  }

  setDblClickHandler(handler) {
    if (this.boundOnDblClick) {
      this.label.removeEventListener(Label3D.Events.DBL_CLICK, this.boundOnDblClick);
    }
    if (handler) {
      this.boundOnDblClick = (_ref4) => {let { event } = _ref4;handler(event, this.level);};
      this.label.addEventListener(Label3D.Events.DBL_CLICK, this.boundOnDblClick);
    }
  }

  setVisible(visible) {
    this.label.setVisible(visible);
  }

  updatePlacement(pos3D, anchor) {
    if (anchor) this.#setAnchor(anchor);
    this.label.pos3D = pos3D;
    this.label.update();
  }

  #makeLabel(hud) {
    // In the case of these elements that require advanced placement, we don't want a valid position where
    // the label could flash visible. Let's wait until we know the right one.
    const label = new HUDLabel(hud.viewer, INVALID_POS3D, this.level.id, this.level.id, { noDefaultStyle: true });
    label.setVisible(false);
    label.textDiv.classList.add('hud-level');
    label.setText(this.name);
    return label;
  }

  #setAnchor(anchor) {
    let margin = null;
    this.label.labelAnchor = anchor;
    // Creates some separation with the position next to the facility.
    switch (this.label.labelAnchor) {
      case LABEL_ANCHORING.START:
        margin = '0 0 0 10px';
        break;
      case LABEL_ANCHORING.END:
        margin = '0 10px 0 0';
        break;
    }
    this.label.textDiv.style.margin = margin;
  }
}