import { logger } from "../../logger/Logger";

//Implements a generic progressive reader for OTG fixed record size binary streams,
//including the material and geometry hash lists and the fragment list.
export function ProgressiveReadContext2(itemCB, defaultByteStride) {

  let currentRow = 0;
  let stride;
  let byteStride;
  let version;
  let bdata;
  let fdata;
  let idata;

  let pendingChunks = [];
  let haveLength = 0;
  let currentChunk;
  let currentOffset;
  let skipBytes;
  let needNewBytes;


  function consumeBytes(dst, sz) {

    if (haveLength < sz) {
      return false;
    }

    if (!currentChunk) {
      if (pendingChunks.length) {
        currentChunk = pendingChunks.shift();
        currentOffset = 0;
      } else {
        return false;
      }
    }

    let numRead = 0;
    let bytesToRead;

    while (true) {

      bytesToRead = Math.min(sz - numRead, currentChunk.length - currentOffset);
      if (bytesToRead > 0) {
        dst.set(currentChunk.subarray(currentOffset, currentOffset + bytesToRead), numRead);
        numRead += bytesToRead;
        currentOffset += bytesToRead;
      }

      if (numRead === sz) {
        break;
      }

      currentOffset = 0;
      currentChunk = pendingChunks.shift();

      if (!currentChunk) {
        break;
      }
    }

    haveLength -= numRead;

    return numRead;
  }


  function readHeader() {

    let headerBytes = new Uint8Array(4);

    consumeBytes(headerBytes, 4);

    byteStride = headerBytes[1] << 8 | headerBytes[0];

    if (!byteStride)
    byteStride = defaultByteStride || 0;

    if (!byteStride)
    logger.error("Unknown byte stride.");

    if (byteStride % 4)
    logger.error("Expected byte size to be multiple of 4, but got " + byteStride);

    version = headerBytes[3] << 8 | headerBytes[2];
    stride = byteStride / 4;

    bdata = new Uint8Array(byteStride);
    fdata = new Float32Array(bdata.buffer);
    idata = new Uint32Array(bdata.buffer);

    if (version > 0) {


      //currently unused
      //var flags = idata[offset+3];
    }skipBytes = byteStride - 4;
  }


  this.onData = function (chunk, finalCall) {

    if (chunk) {
      pendingChunks.push(chunk);
      haveLength += chunk.length;
    }

    //On the first progress event, read the header
    if (!currentRow) {
      if (haveLength >= 4) {
        readHeader();
        currentRow++;
        needNewBytes = true;
      } else {
        return false;
      }
    }

    //We may need to skip past a few bytes after the header (to the first multiple of byteStride)
    //to get to the first real data
    if (skipBytes > 0) {
      let numRead = consumeBytes(bdata, skipBytes);
      skipBytes -= numRead;
      if (skipBytes > 0) {
        return false;
      }
    }

    for (;;) {

      //If data row processing was blocked on the previous run,
      //we will not consume new bytes from the input data
      if (needNewBytes) {
        let numRead = consumeBytes(bdata, byteStride);

        if (numRead < byteStride) {
          if (numRead > 0) {
            console.warn("unexpected extra bytes at end of stream");
          }
          return finalCall;
        }
      }

      //The callback will return true if it was able
      //to process the item at this time. If not, we will
      //call it later with the same item, until it accepts it.
      if (itemCB(this, currentRow)) {
        currentRow++;
        needNewBytes = true;
      } else {
        needNewBytes = false; //will repeat this row when possible.
        return false;
      }
    }
  };

  this.onEnd = function () {

    return this.onData(null, true);

  };

  this.flush = function () {

    let isDoneProcessing = this.onData(null, true);

    if (!isDoneProcessing) {
      logger.warn("unfinished stream processing -- something is wrong");
    }

    return currentRow - 1;
  };

  this.idata = function () {
    return idata;
  };
  this.fdata = function () {
    return fdata;
  };
  this.bdata = function () {
    return bdata;
  };
  this.version = function () {
    return version;
  };
  this.byteStride = function () {
    return byteStride;
  };

}