


export const DepthFormat = 'depth24plus';
export const NumIdTargets = 2;

export const NormalBlend = {
  color: {
    operation: "add",
    srcFactor: "src-alpha",
    dstFactor: "one-minus-src-alpha"
  },
  alpha: {
    operation: "add",
    srcFactor: "one",
    dstFactor: "one-minus-src-alpha"
  }
};


export class CommonRenderTargets {

  #renderer;
  #device;

  #colorTarget;#depthTarget;#normalsTarget;#viewDepthTarget;#overlayTarget;#idTargets;#postTargets;

  #colorTargetView;

  #size = [0, 0];

  #presentationFormat;
  #colorTargetFormat;

  #readBuffer;
  #cachedWholeBuffers;
  #cachedBuffersDirty = true;
  #cacheInProgress = false;

  #targetsList;#targetsListEdge;#targetsListOverlay;

  constructor(renderer) {
    this.#renderer = renderer;

  }

  init(useHdrTarget) {
    this.#device = this.#renderer.getDevice();
    this.#presentationFormat = navigator.gpu.getPreferredCanvasFormat();
    if (useHdrTarget) {
      this.#colorTargetFormat = "rgba16float";
    } else {
      this.#colorTargetFormat = this.#presentationFormat;
    }

  }

  cleanup() {
    this.#depthTarget?.destroy();
    this.#colorTarget?.destroy();
    this.#overlayTarget?.destroy();
    this.#normalsTarget?.destroy();
    this.#viewDepthTarget?.destroy();
    this.#idTargets?.forEach((t) => t?.destroy());
    this.#postTargets?.forEach((t) => t?.destroy());

    this.#depthTarget = null;
    this.#colorTarget = null;
    this.#overlayTarget = null;
    this.#normalsTarget = null;
    this.#idTargets = [];
    this.#postTargets = [];
  }

  resize(w, h) {

    this.#targetsList = null;
    this.#targetsListEdge = null;
    this.#targetsListOverlay = null;

    this.#size[0] = w;
    this.#size[1] = h;

    this.#cachedWholeBuffers = null;

    this.cleanup();

    if (w === 0 || h === 0) {
      return;
    }

    this.#colorTarget = this.#device.createTexture({
      size: [w, h],
      format: this.#colorTargetFormat, //TODO: always use half float for better HDR? they take the same space as 8unorm
      usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_SRC
    });

    this.#colorTargetView = this.#colorTarget.createView();

    this.#depthTarget = this.#device.createTexture({
      size: [w, h],
      format: DepthFormat,
      usage: GPUTextureUsage.RENDER_ATTACHMENT
    });

    this.#overlayTarget = this.#device.createTexture({
      size: [w, h],
      format: this.#presentationFormat,
      usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING
    });

    this.#normalsTarget = this.#device.createTexture({
      size: [w, h],
      format: 'rgb10a2unorm',
      usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING
    });

    this.#viewDepthTarget = this.#device.createTexture({
      size: [w, h],
      format: 'rgb10a2unorm',
      usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING
    });

    this.#idTargets = new Array(NumIdTargets);
    for (let i = 0; i < this.#idTargets.length; i++) {
      let idt = this.#device.createTexture({
        size: [w, h],
        format: "rgba8uint",
        usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_SRC
      });
      this.#idTargets[i] = idt;
    }

    this.#postTargets = [null, null];
    for (let i = 0; i < this.#postTargets.length; i++) {
      let idt = this.#device.createTexture({
        size: [w, h],
        format: this.#presentationFormat,
        usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING
      });
      this.#postTargets[i] = idt;
    }
  }

  getPreferredFormat() {
    return this.#presentationFormat;
  }

  getColorTarget() {
    return this.#colorTarget;
  }

  getNormalsTarget() {
    return this.#normalsTarget;
  }

  getDepthTarget() {
    return this.#depthTarget;
  }

  getViewDepthTarget() {
    return this.#viewDepthTarget;
  }

  getOverlayTarget() {
    return this.#overlayTarget;
  }

  getIdTarget(index) {
    return this.#idTargets[index];
  }

  getPostTarget(index) {
    return this.#postTargets[index];
  }

  getTargetViewsForBlend() {
    let res = [
    this.#colorTarget.createView(),
    this.#overlayTarget.createView()];


    for (let i = 0; i < NumIdTargets; i++) {
      res.push(this.#idTargets[i].createView());
    }

    return res;
  }

  getColorTargetView() {
    return this.#colorTargetView;
  }

  getTargetSize() {
    return this.#size;
  }

  getTargetsListMainPass() {

    if (!this.#targetsList) {

      this.#targetsList = [
      {
        format: this.#colorTarget.format,
        blend: NormalBlend
      },
      {
        format: this.#normalsTarget.format,
        blend: NormalBlend
      },
      {
        format: this.#viewDepthTarget.format,
        blend: NormalBlend
      }];


      for (let i = 0; i < NumIdTargets; i++) {
        this.#targetsList.push({
          format: this.#idTargets[i].format
        });
      }
    }

    return this.#targetsList;
  }

  getTargetsListEdgePass() {

    if (!this.#targetsListEdge) {
      this.#targetsListEdge = [
      {
        format: this.#colorTarget.format,
        blend: NormalBlend
      }];

    }

    return this.#targetsListEdge;
  }

  getOverlayTargetsList() {

    if (!this.#targetsListOverlay) {
      this.#targetsListOverlay = [
      {
        format: this.#colorTarget.format,
        blend: NormalBlend
      }];

    }

    return this.#targetsListOverlay;
  }


  //Async reading from render targets. This is a better approach than the simulated
  //synchronous reading in readIdTargetPixelsSyncOrFail, and we need to transition
  //APIs like idAtPixel() to use this approach in the long term. This will require
  //adjusting tools that use mouse rollover to use Promises rather than expecting
  //the result directly.
  async readIdTargetPixelsAsync(x, y, width, height, bufs) {
    //TODO: This is just a PoC, for a robust implementation we will
    //need to read chunks of data from the target, in e.g. tiles
    //so that we optimize the number of reads (done on mouse move)
    //compared to amount of data transfer and duplicate memory usage

    function copyBuffers(dst, src, width, height, srcStride) {

      const buf1 = dst[0];
      const buf2 = dst[1];
      const targetDataOffset = srcStride * height;

      for (let j = 0; j < height; j++) {

        let hOffSrc = srcStride * j;
        let hOffSrc2 = hOffSrc + targetDataOffset;
        let hOffDst = width * j;

        for (let i = 0; i < width; i++) {
          buf1[hOffDst] = src[hOffSrc++];
          buf2[hOffDst] = src[hOffSrc2++];
          hOffDst++;
        }
      }
    }


    if (this.#readBuffer?.mapState === "pending") {
      return;
    }

    let bytesPerRow = width * 4;
    let remainder = bytesPerRow % 256;
    if (remainder > 0) {
      bytesPerRow += 256 - remainder;
    }

    let bufferSize = bytesPerRow * height;
    if (!this.#readBuffer || this.#readBuffer.size < bufferSize * 2) {
      this.#readBuffer?.destroy();

      this.#readBuffer = this.#device.createBuffer({
        size: bufferSize * 2,
        usage: GPUBufferUsage.COPY_DST | GPUBufferUsage.MAP_READ
      });
    }

    let commandEncoder = this.#device.createCommandEncoder();

    commandEncoder.copyTextureToBuffer(
      { texture: this.#idTargets[0], origin: [x, y] },
      { buffer: this.#readBuffer, offset: 0, bytesPerRow: bytesPerRow },
      [width, height]
    );

    commandEncoder.copyTextureToBuffer(
      { texture: this.#idTargets[1], origin: [x, y] },
      { buffer: this.#readBuffer, offset: bufferSize, bytesPerRow: bytesPerRow },
      [width, height]
    );

    this.#device.queue.submit([commandEncoder.finish()]);

    return this.#readBuffer.mapAsync(GPUMapMode.READ, 0).then(() => {
      let range = new Uint8Array(this.#readBuffer.getMappedRange());
      copyBuffers(bufs, range, width * 4, height, bytesPerRow);

      //let test = new Int32Array(bufs[0].buffer, bufs[0].byteOffset);
      //let dbId = test[0];
      //console.log(dbId);
      this.#readBuffer.unmap();
    });
  }

  async #cacheWholeIdTargets() {

    //console.log("read whole targets");

    this.#cacheInProgress = true;

    let [width, height] = this.#size;

    let bytesPerRow = width * 4;
    let remainder = bytesPerRow % 256;
    if (remainder > 0) {
      bytesPerRow += 256 - remainder;
    }

    let bufferSize = bytesPerRow * height;
    if (!this.#readBuffer || this.#readBuffer.size < bufferSize * 2) {
      this.#readBuffer?.destroy();

      this.#readBuffer = this.#device.createBuffer({
        size: bufferSize * 2, //one for dbIds and one for modelIds buffers
        usage: GPUBufferUsage.COPY_DST | GPUBufferUsage.MAP_READ
      });
    }

    let commandEncoder = this.#device.createCommandEncoder();

    commandEncoder.copyTextureToBuffer(
      { texture: this.#idTargets[0], origin: [0, 0] },
      { buffer: this.#readBuffer, offset: 0, bytesPerRow: bytesPerRow },
      [width, height]
    );

    commandEncoder.copyTextureToBuffer(
      { texture: this.#idTargets[1], origin: [0, 0] },
      { buffer: this.#readBuffer, offset: bufferSize, bytesPerRow: bytesPerRow },
      [width, height]
    );

    this.#device.queue.submit([commandEncoder.finish()]);

    return this.#readBuffer.mapAsync(GPUMapMode.READ, 0).then(() => {

      let range = new Uint8Array(this.#readBuffer.getMappedRange());

      if (!this.#cachedWholeBuffers) {
        this.#cachedWholeBuffers = new Uint8Array(range.byteLength);
      }

      this.#cachedWholeBuffers.set(range);

      this.#readBuffer.unmap();

      this.#cachedBuffersDirty = false;
      this.#cacheInProgress = false;
    });
  }

  //Hacky implementation of reading from a render target that is meant to work
  //with the synchronous idAtPixel() API that is used for mouse rollover.
  //Initial attempt will trigger an operation to read the entire ID buffer
  //to CPU memory, and subsequent attempts will return result immediately
  //Ideally we will transition all code that needs to read back buffers to use async/Promise
  //way
  readIdTargetPixelsSyncOrFail(x, y, width, height, bufs) {

    const copyBuffers = (dst, src, width, height, srcStride, srcX, srcY) => {

      const buf1 = dst[0];
      const buf2 = dst[1];
      const targetDataOffset = srcStride * this.#size[1];
      const startX = srcX || 0;
      const startY = srcY || 0;

      for (let j = 0; j < height; j++) {

        let hOffSrc = srcStride * (j + startY) + startX * 4;
        let hOffSrc2 = hOffSrc + targetDataOffset;
        let hOffDst = width * j;

        for (let i = 0; i < width; i++) {
          buf1[hOffDst] = src[hOffSrc++];
          buf2[hOffDst] = src[hOffSrc2++];
          hOffDst++;
        }
      }
    };

    function setZero() {
      for (let i = 0; i < bufs.length; i++) {
        bufs[i].fill(0);
      }
    }

    if (this.#readBuffer?.mapState === "pending") {
      setZero();
      return;
    }

    if (this.#cacheInProgress) {
      setZero();
      return;
    }

    if (this.#cachedBuffersDirty) {
      setZero();
      this.#cacheWholeIdTargets();
      return;
    }

    let bytesPerRow = this.#size[0] * 4;
    let remainder = bytesPerRow % 256;
    if (remainder > 0) {
      bytesPerRow += 256 - remainder;
    }

    copyBuffers(bufs, this.#cachedWholeBuffers, width * 4, height, bytesPerRow, x, y);
  }


  setTargetsDirty() {
    this.#cachedBuffersDirty = true;
  }

}