import { initCubeMap } from "../Texture";
import vertexTextureQuad from "../post/quad.vert.wgsl";
import { getEnvMapShader } from "./EnvMapShader";
import { UniformBuffer } from "../UniformBuffer";
import { LmvVector3 } from "../../scene/LmvVector3";

let _tmp = new LmvVector3();

class EnvMapUniforms extends UniformBuffer {

  constructor(device) {
    super(device, 14, true, false);
  }

  update(camera, size, clearAlpha, envMapExposure, exposureBias, tonemapMethod) {

    let uCamDir = camera.worldUpTransform ? camera.getWorldDirection(_tmp).applyMatrix4(camera.worldUpTransform) : camera.getWorldDirection(_tmp);
    this.setVector3(0, uCamDir);

    let uCamUp = camera.worldUpTransform ? camera.up.clone().applyMatrix4(camera.worldUpTransform) : camera.up;
    this.setVector3(4, uCamUp);

    this.setFloat(8, size[0]);
    this.setFloat(9, size[1]);

    this.setFloat(10, Math.tan(THREE.Math.degToRad(camera.fov * 0.5)));
    this.setFloat(11, envMapExposure);
    this.setFloat(12, exposureBias);
    this.setInt(13, tonemapMethod);
  }

}


export function EnvMapPass(renderer) {

  let _renderer = renderer;
  let _device;

  let _cubeMap;
  let _exposureBias = 1.0;
  let _envMapExposure = 1.0;
  let _tonemapMethod = 0;
  let _uniforms;

  let _presentPipeline;
  let _presentBindGroupLayout;
  let _presentBindGroup;
  let _presentPassDescriptor;

  this.init = function () {
    _device = _renderer.getDevice();
    _uniforms = new EnvMapUniforms(_device);

    if (!_presentBindGroupLayout) {
      _presentBindGroupLayout = _device.createBindGroupLayout({
        entries: [
        {
          binding: 0,
          visibility: GPUShaderStage.FRAGMENT,
          texture: {
            sampleType: 'float',
            viewDimension: "cube"
          }
        },
        {
          binding: 1,
          visibility: GPUShaderStage.FRAGMENT,
          sampler: {}
        }]

      });
    }

    if (!_presentPipeline) {

      //let frag = getEnvMapShader(true, 2);
      //console.log(frag);

      _presentPipeline = _device.createRenderPipeline({
        layout: _device.createPipelineLayout({
          bindGroupLayouts: [_presentBindGroupLayout, _uniforms.getLayout()]
        }),
        vertex: {
          module: _device.createShaderModule({ code: vertexTextureQuad }),
          entryPoint: "main"
        },
        fragment: {
          module: _device.createShaderModule({ code: getEnvMapShader(true, 2) }),
          entryPoint: "main",
          targets: [
          {
            format: _renderer.getRenderTargets().getColorTarget().format
          }]

        },
        primitive: {
          topology: 'triangle-list',
          cullMode: 'back'
        }
      });
    }

    if (!_presentPassDescriptor) {
      _presentPassDescriptor = {
        colorAttachments: [
        {
          // view is acquired and set in render loop.
          view: undefined,

          clearValue: { r: 0.0, g: 0.0, b: 0.0, a: 1.0 },
          loadOp: 'clear',
          storeOp: 'store'
        }]


      };
    }

  };



  this.setCubeMap = function (map) {
    _cubeMap?.dispose();
    _cubeMap = map;
    //TODO: re-compile shader if RGBM vs. Gamma encoding changes on input map
  };

  this.getCubeMap = function () {
    return _cubeMap;
  };

  this.hasCubeMap = function () {
    return !!_cubeMap;
  };

  this.setEnvExposure = function (exposure) {

    const newValue = Math.pow(2.0, exposure);

    if (newValue !== _envMapExposure) {
      _envMapExposure = newValue;
    }
  };

  this.setExposureBias = function (bias) {
    let newValue = Math.pow(2.0, bias);

    if (newValue !== _exposureBias) {
      _exposureBias = newValue;
    }
  };

  this.setTonemapMethod = function (value) {
    if (_tonemapMethod !== value) {
      _tonemapMethod = value;
      //TODO: re-compile shader
    }
  };

  this.setEnvRotation = function (rotation) {

    //TODO:
  };
  this.setCamera = function (camera, clearAlpha) {

    if (!_device) {
      return;
    }

    let size = _renderer.getRenderTargets().getTargetSize();
    _uniforms.update(camera, size, clearAlpha, _envMapExposure, _exposureBias, _tonemapMethod);

  };

  this.run = function () {

    if (!_device) {
      return;
    }

    if (_cubeMap.needsUpdate || !_presentBindGroup) {
      initCubeMap(_device, _cubeMap);

      //This needs to be recreated when render targets change
      _presentBindGroup = _device.createBindGroup({
        layout: _presentBindGroupLayout,
        entries: [
        {
          binding: 0,
          resource: _cubeMap.__gpuTextureCube.createView({
            dimension: "cube"
          })
        },
        {
          binding: 1,
          resource: _cubeMap.__gpuSampler
        }]

      });

    }

    _uniforms.upload();

    let commandEncoder = _device.createCommandEncoder();

    _presentPassDescriptor.colorAttachments[0].view = _renderer.getRenderTargets().getColorTargetView();

    let pass = commandEncoder.beginRenderPass(_presentPassDescriptor);

    pass.setPipeline(_presentPipeline);
    pass.setBindGroup(0, _presentBindGroup);
    pass.setBindGroup(1, _uniforms.getBindGroup());
    pass.draw(3);
    pass.end();

    _device.queue.submit([commandEncoder.finish()]);
  };

}