import { ComplexPolygon } from '../../../extensions/CompGeom/complex-polygon';
import { BBOX_SIZE, FRAG_SIZE, serializeFragment, serializeBoundingBox, base64EncArr } from '../../wgs/scene/FragmentData';
import { ElementFlags, QC } from '../schema/dt-schema';

function getLevelHeight(facility, shape) {
  const level = shape.customData.level;
  if (!level) {
    throw new Error("Missing level");
  }

  const levels = shape.customData.model.getMetadata('aec', 'levels', []);

  // first, try to compute level's height based on the next level elevation
  const levelIdx = levels.findIndex((l) => l.name === level.originalName);
  if (levelIdx !== -1) {
    const nextLevel = levels[levelIdx + 1];
    if (nextLevel !== undefined) {
      return nextLevel.elevation - level.elev;
    }
  }

  // use computed height
  const floorCache = facility.facetsManager.facetsEffects._floorHighlightCache;
  const levelBBox = floorCache[level.name]?.mesh.geometry.boundingBox;
  if (levelBBox) {
    const min = levelBBox.min;
    const max = levelBBox.max;
    const height = Math.abs(max.z - min.z);
    return height;
  }

  const dUnit = shape.customData.model.getMetadata('distance unit');
  if (dUnit === undefined || dUnit.value === 'foot') {
    return 8.2021;
  }

  return 2.5;
}

export async function createRoom(facility, viewer, shape) {
  let vertices = shape.points.map((v) => v);

  if (!shape.isCCW()) {
    vertices = vertices.reverse();
  }

  let indices = shape.points.map((v, i) => i);
  indices.push(0); // close polygon

  let polygon = ComplexPolygon.createNewPolygon(vertices, null, shape.getBBox());
  polygon.addContour(indices);
  polygon.triangulate();

  if (polygon.triangulationFailed) {
    throw new Error("Failed to triangulate sketched geometry");
  }

  const height = getLevelHeight(facility, shape);
  console.log('Computed level height: ', height);

  const geom = polygon.toExtrudedMesh(height);
  if (geom) {
    let defaultModel = facility.getDefaultModel();
    // 'toExtrudedMesh()' above extrudes from maxZ=0 to minZ=-thickness
    // so here we add z value we got on mouse hit plus level height
    let res = await defaultModel.createGeometry(geom);
    res.transform[14] += shape.z + height;

    const fragmentData = serializeFragment(0, res.hash, null, res.transform);
    const lmvFragment = base64EncArr(fragmentData, 0, FRAG_SIZE);

    const boundingBoxData = serializeBoundingBox(geom.boundingBox);
    const lmvBBox = base64EncArr(boundingBoxData, 0, BBOX_SIZE);

    let elemData = {
      [QC.Name]: "sketched_geometry",
      [QC.ElementFlags]: ElementFlags.Room,
      [QC.LmvFragment]: lmvFragment,
      [QC.LmvBoundingBox]: lmvBBox
    };

    await facility.addLevelFromElement(elemData, shape.customData.model, shape.customData.level.dbId);

    defaultModel.createElementsWithData([elemData], 'Create room');
  }
}