import { RenderBatch } from "../scene/RenderBatch";
import THREE from "three";


//These are shared globals, because they are only transiently
//used in the render loop
let opaqueObjects = [];
let transparentObjects = [];
let _vector3 = new THREE.Vector3();
let _frustum = new THREE.Frustum();
let _projScreenMatrix;
let _is2d = false;

// Sorting

// This method is for transparency
function painterSortStable(a, b) {

  // first see if there's a render order set - if so, this takes precedence
  if (a.renderOrder !== b.renderOrder) {

    return a.renderOrder - b.renderOrder;

    // If render order are the same, then use z distance.
    // We want to render from farthest to nearest.
  } else if (a.z !== b.z) {

    return a.z - b.z;

    // if z distances match, then use id, for a consistent result
  } else {

    return a.id - b.id;

  }

}

// This method is for opaque objects
function reversePainterSortStable(a, b) {

  // first see if there's a render order set - if so, this takes precedence
  if (a.renderOrder !== b.renderOrder) {

    return a.renderOrder - b.renderOrder;

    // Next, sort by material, for efficiency, to avoid state changes.
    // (Note this is not done for transparency, as back to front order is more significant.)
  } else if (a.material.id !== b.material.id) {

    return a.material.id - b.material.id;

    // If render order and material are the same, then use z distance.
    // To minimize processing fragments, we render roughly from nearest to farthest.
    // In this way, the closer objects cover pixels and so hide more distance objects.
  }if (a.z !== b.z) {

    return b.z - a.z;

    // if z distances match, then use id, for a consistent sorted result
  } else {

    return a.id - b.id;

  }

}


function projectObject(object, sortObjects, forceVisible) {

  if (!forceVisible && object.visible === false)
  return;

  if (object instanceof THREE.Scene || object instanceof THREE.Group) {



    // skip
  } else if (object instanceof RenderBatch) {




    //TODO: Not sure if we need RenderBatch-in-Scene support, it's something used
    //by the 2D rendering and perhaps consolidation?
    //object.forEach(sortObjects ? renderBatchIterSort : renderBatchIterNoSort, 0, false, true);
  } else {//initObject( object );
    if (object instanceof THREE.Light) {



      //lights.push( object );
    } else if (object instanceof THREE.Mesh || object instanceof THREE.Line) {
      //This has to be THREE.Mesh (or compatible, e.g. THREE.Line)

      if (object.frustumCulled === false || _frustum.intersectsObject(object) === true) {

        const material = object.material;

        if (material) {
          if (material.transparent) {
            transparentObjects.push(object);
          } else {
            opaqueObjects.push(object);
          }

          if (material.is2d) {
            _is2d = true;
          }
        }

        if (sortObjects === true) {

          _vector3.setFromMatrixPosition(object.matrixWorld);
          _vector3.applyProjection(_projScreenMatrix);
          object.z = _vector3.z;

        }
      }
    }
  }

  if (object.children) {
    for (let i = 0, len = object.children.length; i < len; i++) {
      projectObject(object.children[i], sortObjects, forceVisible);
    }
  }

}


class RenderBatchShim {

  //Wraps a minimal RenderBatch interface implementation around the flattened THREE.Scene
  //so that the Renderer can consistently iterate
  forEachWGPU(startIndex, batchSize, cb) {

    let loopLimit = batchSize || -1;
    let i = startIndex;
    let endIndex = 0;
    let oLength = opaqueObjects.length;

    //loop over opaque objects first
    if (i < oLength) {
      let end = oLength;
      if (loopLimit > 0) {
        if (end - i > loopLimit) {
          end = i + loopLimit;
          endIndex = end;
        } else {
          loopLimit = Math.max(0, loopLimit - (end - i));
        }
      }

      for (; i < end; i++) {
        const m = opaqueObjects[i];
        cb(m);
      }
    }

    //If the batch ended somewhere in the middle of the opaque objects
    if (endIndex) {
      return endIndex;
    }

    //If the batch ended exactly on the end of the opaque objects array,
    //return its length as the endIndex
    if (loopLimit === 0) {
      return oLength;
    }

    //loop over transparent objects
    i -= oLength;
    let end = transparentObjects.length;
    if (loopLimit > 0) {
      if (end - i > loopLimit) {
        end = i + loopLimit;
        endIndex = end + oLength;
      }
    }

    for (; i < end; i++) {
      const m = transparentObjects[i];
      cb(m);
    }

    return endIndex;
  }

  is2d() {
    return _is2d;
  }
}

let _rb = new RenderBatchShim();

//Compatibility API to convert THREE.Scene to a render batch usable by the WebGPU renderer
export function sceneToBatch(scene, projScreenMatrix) {

  if (projScreenMatrix) {
    _projScreenMatrix = projScreenMatrix;
    _frustum.setFromProjectionMatrix(_projScreenMatrix);
  } else {
    _projScreenMatrix = null;
  }

  if (scene.autoUpdate === true) scene.updateMatrixWorld();

  opaqueObjects.length = 0;
  transparentObjects.length = 0;
  _is2d = false;

  projectObject(scene, scene.sortObjects === true, scene.forceVisible === true);

  // note: the following flag is never set in WebGLRenderer; this may change in the future
  if (scene.sortObjects === true) {
    opaqueObjects.sort(reversePainterSortStable);
    transparentObjects.sort(painterSortStable);
  }

  //We use a singleton render batch -- so while it can be drawn multiple times
  //it is not usable once a new scene is processed. So one cannot hold on to the result
  //of the sceneToBatch conversion, it needs to be used dynamically.
  return _rb;
}