
import { getGlobal } from "../compat";
const debounce = require("../dt/streams/timed-debounce");

export function StatusBar(container) {

  let _document = container.ownerDocument || getGlobal().document;
  this.status = _document.createElement("div");
  this.status.classList.add("status-line");

  this.text = _document.createElement("div");
  this.text.classList.add("status-text-bg");

  this.status.appendChild(this.text);
  container.appendChild(this.status);

  this.debouncedSetText = debounce(this._setText.bind(this), 17);
}

StatusBar.prototype.dtor = function () {
  this.debouncedSetText.cancel();
  this.debouncedSetText = null;
  this.status.parentNode.removeChild(this.status);
  this.status = null;
  this.text = null;
};

StatusBar.prototype.toggleVisibility = function (on) {
  if (on) {
    this.status.style.display = "block";
  } else {
    this.status.style.display = "none";
  }
};

StatusBar.prototype._setText = function (text) {
  this.text.innerText = text;
  if (!text) {
    this.text.style.display = "none";
  } else {
    this.text.style.display = "inline-block";
  }
};

StatusBar.prototype.setText = function (text) {
  this.debouncedSetText(text);
};