import { AttributeType, AttributeName, MeshFlags } from "./OtgGeomDecoder";
import { Buffer } from "buffer";

function rotate(tri) {
  let tmp = tri[0];
  tri[0] = tri[1];
  tri[1] = tri[2];
  tri[2] = tmp;
}

function deltaEncodeIndexBuffer3(ib) {

  let triangles = [];

  for (let i = 0; i < ib.length; i += 3) {
    triangles.push(
      [ib[i], ib[i + 1], ib[i + 2]]
    );
  }

  //Sort the indices for each triangle so that
  //the first one is smallest
  for (let i = 0; i < triangles.length; i++) {
    let t = triangles[i];

    while (t[0] > t[1] || t[0] > t[2]) {
      rotate(t);
    }
  }

  //Sort triangles by ascending first index
  triangles.sort(function (a, b) {
    return a[0] - b[0];
  });

  //Delta encode the indices
  let t = triangles[0];
  let j = 0;
  ib[j] = t[0];
  ib[j + 1] = t[1] - t[0];
  ib[j + 2] = t[2] - t[0];
  j += 3;

  for (let i = 1; i < triangles.length; i++, j += 3) {
    t = triangles[i];

    ib[j] = t[0] - triangles[i - 1][0];
    ib[j + 1] = t[1] - t[0];
    ib[j + 2] = t[2] - t[0];
  }

}

function deltaEncodeIndexBuffer2(ib) {

  let lines = [];

  for (let i = 0; i < ib.length; i += 2) {
    lines.push(
      [ib[i], ib[i + 1]]
    );
  }

  //Sort the indices for each triangle so that
  //the first one is smallest
  for (let i = 0; i < lines.length; i++) {
    let t = lines[i];

    if (t[0] > t[1]) {
      let tmp = t[0];
      t[0] = t[1];
      t[1] = tmp;
    }
  }

  //Sort lines by ascending first index
  lines.sort(function (a, b) {
    return a[0] - b[0];
  });

  //Delta encode the indices
  let t = lines[0];
  let j = 0;
  ib[j] = t[0];
  ib[j + 1] = t[1] - t[0];
  j += 2;

  for (let i = 1; i < lines.length; i++, j += 2) {
    t = lines[i];

    ib[j] = t[0] - lines[i - 1][0];
    ib[j + 1] = t[1] - t[0];
  }

}


function attrTypeMapper(attr) {

  let type = AttributeType.FLOAT;

  let itemWidth = attr.bytesPerItem || 4;
  if (itemWidth === 1) {
    type = attr.normalized ? AttributeType.UBYTE_NORM : AttributeType.UBYTE;
  } else if (itemWidth === 2) {
    type = attr.normalized ? AttributeType.USHORT_NORM : AttributeType.USHORT;
  }

  return type << 4 | attr.itemSize & 0xf;
}

function indexTypeMapper(attr) {
  let type = AttributeType.USHORT;

  let itemWidth = attr.bytesPerItem || 2;
  if (itemWidth === 1) {
    type = AttributeType.UBYTE;
  } else if (itemWidth === 2) {
    type = AttributeType.USHORT;
  } else if (itemWidth === 4) {
    type = AttributeType.UINT;
  }

  return type << 4 | attr.itemSize & 0xf;
}

const LMV2OTGAttr = {
  "position": AttributeName.Position,
  "normal": AttributeName.Normal,
  "index": AttributeName.Index,
  "indexlines": AttributeName.IndexEdges,
  "color": AttributeName.Color
};

function attrNameMapper(attributeName) {

  let name = LMV2OTGAttr[attributeName];
  if (typeof name !== "undefined")
  return name;

  if (attributeName.indexOf("uv") === 0) {
    return AttributeName.TextureUV;
  }

  console.warn("Unknown attribute name");
  return AttributeName.TextureUV;
}


function OtgGeomEncoder() {
}


OtgGeomEncoder.prototype.beginHeader = function (meshFlag, numAttributes, dataStreamLengths) {
  let headerSize = 8;

  let numBuffers = dataStreamLengths.length;
  headerSize += (numBuffers - 1) * 4;

  headerSize += numAttributes * 5;

  while (headerSize % 4 !== 0) {
    headerSize++;
  }

  let totalDataSize = 0;
  for (let i = 0; i < dataStreamLengths.length; i++)
  totalDataSize += dataStreamLengths[i];

  this.buffer = Buffer.alloc(headerSize + totalDataSize);
  this.writeOffset = 0;

  //Write the 4 byte magic prefix
  const MAGIC = "OTG0";
  for (let i = 0; i < 4; i++) {
    this.writeOffset = this.buffer.writeUInt8(MAGIC.charCodeAt(i), this.writeOffset);
  }

  //TODO: line width if wide lines and pointSize if points

  this.writeOffset = this.buffer.writeUInt16LE(meshFlag, this.writeOffset);

  this.writeOffset = this.buffer.writeUInt8(numBuffers, this.writeOffset);

  this.writeOffset = this.buffer.writeUInt8(numAttributes, this.writeOffset);

  //write buffer offsets from the beginning of the binary data block
  //Skip the first buffer as its at offset zero
  let offset = dataStreamLengths[0];
  for (let i = 1; i < dataStreamLengths.length; i++) {
    this.writeOffset = this.buffer.writeUInt32LE(offset, this.writeOffset);
    offset += dataStreamLengths[i];
  }
};

OtgGeomEncoder.prototype.addAttribute = function (attrName, attr, stride, bufferIndex) {
  this.writeOffset = this.buffer.writeUInt8(attrName, this.writeOffset);

  if (attrName === AttributeName.Index || attrName === AttributeName.IndexEdges) {

    this.writeOffset = this.buffer.writeUInt8(indexTypeMapper(attr), this.writeOffset);

    this.writeOffset = this.buffer.writeUInt8((attr.itemOffset || 0) * 4, this.writeOffset); //itemOffset
    this.writeOffset = this.buffer.writeUInt8((stride || 0) * 4, this.writeOffset); //itemStride

    this.writeOffset = this.buffer.writeUInt8(bufferIndex, this.writeOffset); //buffer index
  } else {
    this.writeOffset = this.buffer.writeUInt8(attrTypeMapper(attr), this.writeOffset);

    this.writeOffset = this.buffer.writeUInt8((attr.itemOffset || 0) * 4, this.writeOffset); //itemOffset (LMV stores in multiples of 4)
    this.writeOffset = this.buffer.writeUInt8((stride || 0) * 4, this.writeOffset); //itemStride (LMV stores in multiples of 4)

    this.writeOffset = this.buffer.writeUInt8(bufferIndex, this.writeOffset); //buffer index
  }
};


OtgGeomEncoder.prototype.endHeader = function () {
  //Padding so that buffers are written at multiple of 4
  while (this.writeOffset % 4 !== 0) {
    this.writeOffset = this.buffer.writeUInt8(0, this.writeOffset);
  }
};

OtgGeomEncoder.prototype.addBuffer = function (buffer) {
  buffer.copy(this.buffer, this.writeOffset);
  this.writeOffset += buffer.length;
};


OtgGeomEncoder.prototype.end = function () {
  if (this.writeOffset !== this.buffer.length) {
    console.error("Incorrect encoding buffer size");
  }

  return this.buffer;
};


export function serializeLmvBufferGeom(geom) {

  let otgEncoder = new OtgGeomEncoder();

  //Check for interleaved buffer. For now
  //this is the only one we support
  let bufSizes = [];
  if (!geom.vb) {
    console.error("Unexpected non-interleaved vertex buffer");
    return null;
  } else {
    bufSizes = [geom.vb.byteLength, geom.ib.byteLength];

    if (geom.iblines) {
      bufSizes.push(geom.iblines.byteLength);
    }
  }

  let attrKeys = Object.keys(geom.attributes);

  let meshFlag = 0;
  if (geom.isLines)
  meshFlag = meshFlag | MeshFlags.LINES;
  if (geom.isWideLines)
  meshFlag = meshFlag | MeshFlags.WIDE_LINES;
  if (geom.isPoints)
  meshFlag = meshFlag | MeshFlags.POINTS;

  otgEncoder.beginHeader(meshFlag, attrKeys.length, bufSizes);

  //Write the attributes
  for (let i = 0; i < attrKeys.length; i++) {
    let attr = geom.attributes[attrKeys[i]];
    let attrName = attrNameMapper(attrKeys[i]);

    if (attrKeys[i] === "index") {
      otgEncoder.addAttribute(attrName, attr, 0, 1);
    } else if (attrKeys[i] === "indexlines") {
      otgEncoder.addAttribute(attrName, attr, 0, 2);
    } else {
      otgEncoder.addAttribute(attrName, attr, geom.vbstride, 0);
    }

  }

  otgEncoder.endHeader();

  //Write the buffers

  //Buffer 0
  var tmp = Buffer.from(geom.vb.buffer, geom.vb.byteOffset, geom.vb.byteLength);
  otgEncoder.addBuffer(tmp);

  //Buffer 1
  if (geom.isLines)
  deltaEncodeIndexBuffer2(geom.ib);else

  deltaEncodeIndexBuffer3(geom.ib);

  tmp = Buffer.from(geom.ib.buffer, geom.ib.byteOffset, geom.ib.byteLength);
  otgEncoder.addBuffer(tmp);

  //Buffer 2
  if (geom.iblines) {
    deltaEncodeIndexBuffer2(geom.iblines);

    tmp = Buffer.from(geom.iblines.buffer, geom.iblines.byteOffset, geom.iblines.byteLength);
    otgEncoder.addBuffer(tmp);
  }

  let buf = otgEncoder.end();

  return buf;
}