const { isNodeJS } = require('../compat');
var getResourceUrl = require('../globals').getResourceUrl;

if (isNodeJS()) {

  (function () {
    //Node.js case -- the web worker is a "fake" worker
    //running on the main thread.
    var MainWorker = require(BUILD_FLAG__DTWORKER_NODE).MainWorker;

    function createWorker() {
      return new MainWorker();
    }

    function initDtWorkerScript(successCB, errorCB) {
      if (successCB)
      successCB();
    }

    module.exports = {
      createWorker,
      initDtWorkerScript
    };

  })();

} else {

  (function () {

    var avp = Autodesk.Viewing.Private;

    //Those are globals -- set by the build system.
    var DT_WORKER_URL = BUILD_FLAG__DT_WORKER_FILE || "src/dt/workers/MainWorker-web.js";


    // A cache of entire worker script as data URL.
    var WORKER_DATA_URL = null;
    var WORKER_FETCHING_SCRIPT = false;
    var WORKER_FETCHING_CALLBACKS = [];

    function initDtWorkerScript(successCB, errorCB) {

      if (avp.ENABLE_INLINE_WORKER && !WORKER_DATA_URL) {

        WORKER_FETCHING_CALLBACKS.push({ successCB });

        if (!WORKER_FETCHING_SCRIPT) {
          let xhr = new XMLHttpRequest();
          var scriptURL = DT_WORKER_URL;

          // We need to request the same version of the library for this worker.  Take the original
          // script url, which will already have the version string (if provided).
          //
          var originalScriptURL = getResourceUrl(DT_WORKER_URL);

          if (originalScriptURL) {
            scriptURL = originalScriptURL;
          }

          xhr.open("GET", scriptURL, true);
          xhr.withCredentials = false;

          xhr.onload = function () {

            // Set up global cached worker script.
            WORKER_FETCHING_SCRIPT = false;

            WORKER_DATA_URL = URL.createObjectURL(new Blob([xhr.responseText], { type: 'application/javascript' }));

            let callbacks = WORKER_FETCHING_CALLBACKS.concat(); // Shallow copy
            WORKER_FETCHING_CALLBACKS = [];
            for (let i = 0; i < callbacks.length; ++i) {
              callbacks[i].successCB && callbacks[i].successCB();
            }
          };

          xhr.onerror = errorCB;

          WORKER_FETCHING_SCRIPT = true;
          xhr.send();
        }

        // Return a token that can be used to cancel the async call result.
        let token = {};
        token.cancel = function () {
          let idx = -1;
          if (WORKER_FETCHING_CALLBACKS.some(function (cb, i) {
            if (cb.successCB === successCB) {
              idx = i;
              return true;
            }
            return false;
          })) {
            WORKER_FETCHING_CALLBACKS.splice(idx, 1);
            return true;
          }

          return false;
        };

        return token;
      } else {
        if (successCB)
        successCB();
      }

      return null;
    }

    // Create a web worker.
    function createWorker(name) {

      let w;

      // When we are not at release mode, create web worker directly from URL.
      if (avp.ENABLE_INLINE_WORKER) {
        w = new Worker(WORKER_DATA_URL, { name });
      } else {
        w = new Worker(getResourceUrl(DT_WORKER_URL), { name });
      }

      w.doOperation = w.postMessage;

      return w;
    }


    module.exports = {
      createWorker,
      initDtWorkerScript
    };


  })();
}