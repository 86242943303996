import { endpoint } from "../net/endpoints";
import { isNodeJS, getGlobal, isMobileDevice } from "../compat";
import { fetchJson } from "../net/fetch";
import { EventDispatcher } from "../application/EventDispatcher";
import { DtFacility } from "./DtFacility";
import * as dte from "./DtEventTypes";
import { DtPropWorker } from "./DtPropWorkerInstance";
import { DtTeam } from "./DtTeam";
import { fixTwinUrn } from "./encoding/urn";
import { v4 as uuidV4 } from "uuid";
import { DtMsgWs } from "./DtMsgSocket";
import { DtModel } from "./DtModel";
import { DtViews } from "./DtViews";
import { DtConstants } from "./schema/DtConstants";

const NUM_WORKERS = isMobileDevice() ? 2 : 4;

/**
 * High level object for Tandem application.
 *
 * @alias Autodesk.Tandem.DtApp
 */
export class DtApp extends EventDispatcher {
  /**
   * @constructor
   *
   * @param {Object} options - Application configuration options.
   */
  constructor(options) {
    super();

    this.global = getGlobal();

    this.sessionId = uuidV4();
    this.sdkVersion = this.global.LMV_VIEWER_VERSION;
    endpoint.HTTP_REQUEST_HEADERS["Session-Id"] = this.sessionId;
    endpoint.HTTP_REQUEST_HEADERS["x-dt-sdk-version"] = this.sdkVersion;

    this.loadContext = endpoint.initLoadContext(options);
    this.loadContext.sessionId = this.sessionId;
    this.loadContext.sdkVersion = this.sdkVersion;

    // "User Facilities" are facilities that the current user has only direct access to,
    // i.e. they belong to groups/accounts said user is not a member of. They show up in
    // the Tandem client in the "Shared with me" tab
    this.userFacilities = null;

    this.activeTeamUrn = null;

    if (!isNodeJS()) {
      this._onOnlineBound = this._onOnline.bind(this);
      this._onFocusBound = this._onFocus.bind(this);
      this.global.addEventListener('visibilitychange', this._onFocusBound);
      this.global.addEventListener('online', this._onOnlineBound);
      this.global.addEventListener('offline', this._onOnlineBound);
    }

    this.workers = null;

    //Start the main message web socket connection
    this.msgWs = new DtMsgWs(this.loadContext, console.error, this.sessionId, () => this.signalMsgWsReconnected());
    this.msgWs.startSession(this.loadContext);

    /**
     * Provides access to current facility.
     *
     * @member {DtFacility}
     * @public
     *
     * @alias Autodesk.Tandem.DtApp#currentFacility
     */
    this.currentFacility = null;
    this.facilityCache = {};

    /**
     * Provides access to available views.
     *
     * @type {DtViews}
     * @public
     *
     * @alias Autodesk.Tandem.DtApp#views
     */
    this.views = new DtViews(this.loadContext);

    this.previousFacilityUrn = undefined;

    this.sampleFacilityUrn = undefined;
  }

  getWorker() {let seqNo = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    //TODO: Need a way to shut down the worker when the App is shut down
    if (!this.workers) {
      this.workers = [];
    }

    let whichWorker = seqNo % NUM_WORKERS;

    if (!this.workers[whichWorker]) {
      this.workers[whichWorker] = new DtPropWorker(this);
    }

    return this.workers[whichWorker];
  }

  subscribeToEvents(target) {

    if (target instanceof DtModel) {
      //Model data changes need to be sent to the appropriate property worker
      //to process instance tree recalculations, etc.
      target.onEvent = (change) => {
        let loadContext = { ...this.loadContext };
        loadContext.modelId = target.urn();
        loadContext.change = change;

        target.asyncPropertyOperation({ change, operation: "HANDLE_MODEL_CHANGE" });
      };
    } else if (target instanceof DtFacility) {
      //Facility and other global changes don't need to be sent to a worker,
      //we handle them directly here in DtApp
      target.onEvent = (change) => {
        target.onFacilityChanged(change);
      };
    } else if (target instanceof DtTeam) {
      target.onEvent = (change) => {
        target.onTeamChanged(change);
      };
    } else {
      console.error('unrecognized event target', target);
    }

    this.msgWs.subscribeForChanges(target.urn(), target.onEvent);
  }

  unsubscribeFromEvents(target) {
    this.msgWs.unsubscribeFromChanges(target.urn());
    target.onEvent = null;
  }

  // called on connectivity change
  async _onOnline() {
    if (isNodeJS()) {
      return;
    }

    const isConnected = navigator.onLine;

    if (isConnected) {
      this.msgWs.reconnect(true);
      return;
    }
    console.log(`Internet connection lost`);
  }

  // called on window focus event
  async _onFocus() {
    if (isNodeJS()) {
      return;
    }
    const isVisible = !document.hidden;

    if (isVisible) {
      // maybe act on visibility change
      return;
    }
  }

  async loadUserFacilities() {let forceRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    return !forceRefresh && this.loadUserFacilitiesPromise || (this.loadUserFacilitiesPromise = this._loadUserFacilities());
  }

  async _loadUserFacilities() {
    const twins = await fetchJson(this.loadContext, `/users/@me/twins`);

    this.userFacilities = [];
    for (let twinID in twins) {
      const settings = twins[twinID];
      this.userFacilities.push(
        this.getOrCreateFacility(twinID, settings)
      );
    }

    this.dispatchEvent({
      type: dte.DT_USER_FACILITIES_CHANGED_EVENT
    });
  }

  /**
   * Returns facilities for currently active team.
   *
   * @returns {Promise<Array.<DtFacility>>} List of facilities.
   *
   * @alias Autodesk.Tandem.DtApp#getCurrentTeamsFacilities
   */
  async getCurrentTeamsFacilities() {
    const activeTeam = await this.getActiveTeam();
    return activeTeam?.getFacilities();
  }

  /**
   * Returns facilities for the current user.
   *
   * @param {boolean} [forceReload] - If true, forces a reload of the user's facilities.
   * @returns {Promise<Array.<DtFacility>>} List of facilities.
   *
   * @alias Autodesk.Tandem.DtApp#getUsersFacilities
   */
  async getUsersFacilities(forceReload) {
    await this.loadUserFacilities(forceReload);
    return this.userFacilities;
  }

  getOrCreateFacility(twinId, initialSettings) {

    twinId = fixTwinUrn(twinId);

    let cached = this.facilityCache[twinId];
    if (!cached) {
      this.facilityCache[twinId] = cached = new DtFacility(twinId, this, initialSettings);
    }

    return cached;
  }

  /**
   * Returns facility by its URN.
   *
   * @param {string} urn
   * @returns {Promise<DtFacility>}
   */
  async getFacility(urn) {
    const facility = this.getOrCreateFacility(urn);
    try {
      await facility.load();
      return facility;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  async createFacility(settings) {
    const activeTeam = await this.getActiveTeam();
    return activeTeam.createFacility(settings);
  }

  findFacility(twinId) {
    return this.facilityCache[fixTwinUrn(twinId)];
  }

  removeCachedFacility(twinId) {
    delete this.facilityCache[fixTwinUrn(twinId)];
  }

  async createSampleFacility() {let useV2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    if (useV2) {
      const path = `/users/${this.userProfile.userId}/make-sample`;
      const { groupID } = await fetchJson(this.loadContext, path, "POST", null, null, useV2);

      if (!this._teams.find((t) => t.id === groupID)) {
        // create a new DtTeam instance and append it to this._teams list
        const teamJSON = await this.getTeamById(groupID);
        const team = new DtTeam(this, teamJSON);
        this._teams.push(team);
      }

      return groupID;
    }
    const activeTeam = await this.getActiveTeam();
    return activeTeam.createSampleFacility();
  }


  async deleteFacility(urn) {
    const activeTeam = await this.getActiveTeam();
    return activeTeam.deleteFacility(urn);
  }

  /**
   * Displays facility in the viewer.
   *
   * @param {DtFacility} facility
   * @param {Set<string>} visibleModelsForView
   * @param {Autodesk.Viewing.Viewer3D} viewer
   * @param {boolean} [forceReload]
   * @returns {Promise<void>}
   *
   * @alias Autodesk.Tandem.DtApp#displayFacility
   */
  async displayFacility(facility, visibleModelsForView, viewer) {let forceReload = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    if (this.currentFacility && (this.currentFacility.urn() !== facility.urn() || forceReload)) {
      this.previousFacilityUrn = this.currentFacility.urn();
      this.currentFacility.unloadModels();
      this.currentFacility = null;
    }

    facility.setViewer(viewer);

    if (!this.currentFacility) {
      this.currentFacility = facility;
      await this.currentFacility.load(this.previousFacilityUrn && this.previousFacilityUrn !== facility.urn());
      this.currentFacility.loadModels(visibleModelsForView); // fire and forget

      // Initialize managers after models load queue is populated
      facility.getStreamManager().init(viewer);
      facility.systemsManager.init();
    }

    return this.currentFacility;
  }

  /**
   * Returns team by its ID.
   *
   * @param {string} groupId
   * @returns {Promise<DtTeam>}
   *
   * @alias Autodesk.Tandem.DtApp#getTeamById
   */
  getTeamById(groupId) {
    return fetchJson(this.loadContext, `/groups/${groupId}`);
  }

  /**
   * Returns list of teams for current user.
   *
   * @returns {Promise<Array.<DtTeam>>}
   *
   * @alias Autodesk.Tandem.DtApp#getTeams
   */
  async getTeams() {
    if (!this._teamsPromise) {
      this._teamsPromise = (async () => {
        const res = await fetchJson(this.loadContext, "/groups");
        this._teams = res.map((team) => new DtTeam(this, team));
        this.getActiveTeam(); // fire and forget, triggers DT_ACTIVE_TEAM_CHANGED_EVENT if necessary
      })();
    }

    await this._teamsPromise;
    return this._teams;
  }

  /**
   * Determines the active team in a best effort manner.
   *
   * @returns {Promise<DtTeam>}
   *
   * @alias Autodesk.Tandem.DtApp#getActiveTeam
   */
  async getActiveTeam() {
    const teams = await this.getTeams();

    const activeTeam = teams.find((t) => t.urn() === this.activeTeamUrn) ||
    teams.find((t) => t.isOwner()) ||
    teams.find((t) => t.canManage()) ||
    teams[0];

    this.setAsActiveTeam(activeTeam);

    return activeTeam;
  }

  async getOrCreateSample() {
    let sampleFacility = await this.getSampleFacility();
    if (sampleFacility) {
      this.sampleFacilityUrn = sampleFacility.urn();
      return sampleFacility;
    }

    const waitForClone = async () => {
      console.log('start to wait for clone');

      let retryCount = 100;

      const isCloned = async () => {
        const res = await this.getSampleFacility();

        console.log('wait for clone: try to get sample facility...');

        if (res) {
          // await for updating model ACL, need to be improved. Probably get all models and see if there is main model found for this twin, retry one more time if no main model found.
          await new Promise((r) => setTimeout(r, 5000));
          console.info('Sample clone is done');
          return res;
        } else {
          if (retryCount-- > 0) {
            await new Promise((r) => setTimeout(r, 5000));
            return await isCloned();
          } else {
            throw new Error('Sample clone timed out');
          }
        }
      };

      return isCloned();
    };

    const useV2 = true;
    await this.createSampleFacility(useV2);

    sampleFacility = await waitForClone();
    this.sampleFacilityUrn = sampleFacility.urn();
    return this.sampleFacilityUrn;
  }

  async getSampleFacility() {
    const teams = await this.getTeams();

    const personalAccount = teams.find((t) => t.owner === this.userProfile.userId && t.accountSettings?.type === 'free');

    if (!personalAccount) {
      return null;
    }

    const facilities = await personalAccount.getFacilities(true);

    return facilities.find((facility) => facility.isSampleFacility());
  }

  setAsActiveTeam(dtTeam) {
    // Effecting change on the cached version to not cause a refetch.
    const cachedTeam = this._teams.find((t) => t.urn() === this.activeTeamUrn);

    if (!dtTeam) {
      if (cachedTeam?.onEvent) this.unsubscribeFromEvents(cachedTeam);
      this.setActiveTeamUrn(undefined);
      return;
    }

    const hasChanged = cachedTeam?.urn() !== dtTeam.urn();
    if (hasChanged && cachedTeam?.onEvent) {
      this.unsubscribeFromEvents(cachedTeam);
    }
    this.setActiveTeamUrn(dtTeam.urn());
    if (hasChanged || !dtTeam.onEvent) {
      this.subscribeToEvents(dtTeam);
    }
  }

  setActiveTeamUrn(urn) {
    if (this.activeTeamUrn !== urn) {
      this.activeTeamUrn = urn;
      this.dispatchEvent({ type: dte.DT_ACTIVE_TEAM_CHANGED_EVENT, urn });
      return true;
    }
    return false;
  }

  handleChangeNotification(data) {
    const change = data?.change;

    if (!change) {
      console.error("unknown event", data);
      return;
    }

    console.log('App dispatching event', change.ctype, change.description);

    const facility = this.findFacility(data.twinId);
    if (!facility) {
      console.warn("unknown facility", data.twinId);
      return;
    }

    //Is it a facility-only event?
    if (!change.modelId) {
      //This code path should not be hit
      console.warn("deprecated handling of twin change notification");
      facility.onFacilityChanged(change);
      return;
    }

    const model = facility.getModels()?.find((m) => m.urn() === change.modelId);
    if (!model) {
      console.warn("unknown model", change.modelId);
      return;
    }

    facility.onModelChanged(model, change);
  }

  /**
   * @param {String} eventName
   * @param {Object} [filter]
   * @param {DtConstants.ChangeTypes} [filter.ctype]
   * @param {String} [filter.twinId]
   * @param {String} [filter.modelId]
   * @param {Number} [timeout]
   * @returns a Promise that waits for a specific change event to occur (locally or via remote notification),
   * up to a given timeout. If the wait times out, the Promise is rejected.
   *
   * @ignore
   */
  async waitForEvent(eventName) {let filter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;let timeout = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

    return new Promise(async (resolve, reject) => {

      let timer;
      if (timeout) {
        timer = setTimeout(() => {
          this.removeEventListener(eventName, listener);
          reject({ error: true, msg: "timeout" });
        }, timeout);
      }

      const listener = (e) => {

        let passFilter = true;

        if (filter) {
          if (filter.ctype) {
            if (e?.change?.ctype !== filter.ctype) {
              passFilter = false;
            }
          }
          if (filter.description) {
            // For testing purposes.
            if (e?.change?.description !== filter.description) {
              passFilter = false;
            }
          }
          if (filter.isOwn && !e?.change?.isOwn) {
            passFilter = false;
          }
          if (filter.modelId) {
            if (e?.model.urn() !== filter.modelId) {
              passFilter = false;
            }
          }
          if (filter.twinId) {
            if (e?.facility.urn() !== filter.twinId) {
              passFilter = false;
            }
          }
        }

        if (passFilter) {
          timer && clearTimeout(timer);
          this.removeEventListener(eventName, listener);
          resolve(e);
        }
      };

      this.addEventListener(eventName, listener);
    });
  }

  signalMsgWsReconnected() {
    this.dispatchEvent({
      type: dte.DT_MSG_WS_RECONNECTED
    });
  }

  async triggerReconnectReplay() {
    this.msgWs.replayOnReconnect();
  }
}

DtApp.prototype.constructor = DtApp;