import { wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";

import pack_depth from "../chunks/pack_depth.wgsl";

import { getIBLDeclaration } from "../main/IBL";
import { getCameraUniformsDeclaration } from "../main/CameraUniforms";
import { getObjectUniformsDeclaration } from "../main/ObjectUniforms";
import { getTextureDeclaration } from "./GroundShadowTextures";

export function getShader(iblBindingIndex, cameraBindingIndex, objectUniformBindingIndex) {
  return wgsl /*wgsl*/`

	// We don't need IBL here, but this is where cut planes are currently implemented
	${getIBLDeclaration(iblBindingIndex)}
	${getCameraUniformsDeclaration(cameraBindingIndex)}
	${getObjectUniformsDeclaration(objectUniformBindingIndex)}
	${pack_depth}

	struct VertexOutput {
		@builtin(position) position: vec4f,
		@location(0) vWorldPosition: vec3f,
		//@location(1) @interpolate(flat) doNotCut: u32
	}

	@vertex fn vsmain(
		@location(0) position: vec3f,
		@builtin(instance_index) instance: u32
	) -> VertexOutput {

		var objectUniforms = getObjectUniforms(instance);

		var output: VertexOutput;

		var pos4 = vec4f(position.x, position.y, position.z, 1.0);
		var mvMatrix = uniforms.viewMatrix * objectUniforms.modelMatrix;
		var mvPosition = mvMatrix * pos4;

		output.position = uniforms.projectionMatrix * mvPosition;
		output.vWorldPosition = (objectUniforms.modelMatrix * pos4).xyz;

		return output;
	}

	@fragment fn psmain(
		in: VertexOutput
	) -> @location(0) vec4f {
		// if (in.doNotCut == 0) {
			checkCutPlanes(in.vWorldPosition);
		// }

		var depth = 1.0 - in.position.z / in.position.w;

		return packDepth(depth);
	}
	`;
}

export function getBlurFragmentShader(horizontal, textureBindingIndex) {
  let getUvFn;
  if (horizontal) {
    getUvFn = wgsl /*wgsl*/`
		fn getUv(vUv: vec2f, x: f32) -> vec2f {
			return vec2f(vUv.x + KERNEL_SCALE * x, vUv.y);
		}
		`;
  } else {
    getUvFn = wgsl /*wgsl*/`
		fn getUv(vUv: vec2f, y: f32) -> vec2f {
			return vec2f(vUv.x, vUv.y + KERNEL_SCALE * y);
		}
		`;
  }

  return wgsl /*wgsl*/`

	${pack_depth}
	${getTextureDeclaration(textureBindingIndex)}

	const PI: f32 = 3.14159265358979;
	@id(0) override KERNEL_SCALE: f32;
	@id(1) override KERNEL_RADIUS: f32;
	override SIGMA: f32 = (2.0 * KERNEL_RADIUS + 1.0) / 6.0;
	override SIGMASQ2: f32 = 2.0 * SIGMA * SIGMA;
	override QUOTIENT: f32 = sqrt(PI * SIGMASQ2);

	${getUvFn}

	fn kernelVal(x: f32) -> f32 {
		return (1.0 / QUOTIENT) * exp(-x * x / SIGMASQ2);
	}

	@fragment
	fn psmain(
		@builtin(position) coord: vec4f,
		@location(0) @interpolate(linear) vUv: vec2f
	) -> @location(0) vec4f {
		var depthVal: f32 = 0.0;
		var sum: f32 = 0.0;
		var kVal: f32;

		for (var x = -KERNEL_RADIUS; x <= KERNEL_RADIUS; x += 1.0) {
			kVal = kernelVal(x);
			depthVal += unpackDepth(textureSample(map, smpl, getUv(vUv, x))) * kVal;
			sum += kVal;
		}

		return packDepth(depthVal / sum);
	}
	`;
}

export function getColorShader(cameraBindingIndex, textureBindingIndex) {
  return wgsl /*wgsl*/`

	${getCameraUniformsDeclaration(cameraBindingIndex)}
	${getTextureDeclaration(textureBindingIndex)}
	${pack_depth}

	struct VertexOutput {
		@builtin(position) position: vec4f,
		@location(0) vUv: vec2f
	}

	@vertex fn vsmain(
		@location(0) position: vec3f,
		@location(1) uv: vec2f,
		@builtin(instance_index) instance: u32
	) -> VertexOutput {

		var output: VertexOutput;

		var pos4 = vec4f(position.x, position.y, position.z, 1.0);
		var mvPosition = uniforms.viewMatrix * pos4;

		output.position = uniforms.projectionMatrix * mvPosition;
		output.vUv = uv;

		return output;
	}

	@fragment fn psmain(
		in: VertexOutput
	) -> @location(0) vec4f {
		var depthVal = unpackDepth(textureSample(map, smpl, in.vUv));
		var shadowColor = vec4f(0, 0, 0, 1);

		return vec4f(shadowColor.rgb, shadowColor.a * depthVal);
	}
	`;
}