import { wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";
import { UniformBuffer } from "../UniformBuffer";


export function getBlendSettingsDeclaration(bindGroup) {
  return wgsl /*wgsl*/`
	struct BlendUniforms {
		highlightColor: vec4f, //0
		selectionColor: vec4f, //4

		objectId: i32,      //8
		modelId: i32,       //9
		glowFlagInt: i32,   //10
		glowCompFunc: i32,  //11
		highlightIntensity: f32, //12
		useAO: i32,         //13
		encodeLuma: i32,     //14
		aoBias: f32,         //15
		edgeObjectId: i32,   //16
		edgeModelId: i32,    //17
		useOverlay: i32      //18 //TODO: combine with useAO into single bitmask uniform

		//Enable these if the forward pass renders in HDR-linear target and the Blend shader is doing the tone mapping
		//exposureBias: f32,
		//toneMapMethod: i32,
	}
	@group(${bindGroup || 0}) @binding(0) var<uniform> u : BlendUniforms;
	`;
}

export class BlendSettings extends UniformBuffer {

  constructor() {
    super(null, 32, true, false);
  }

  init(device) {
    this.setDevice(device);
  }

  setAOEnabled(on) {
    this.setInt(13, on ? 1 : 0);
  }

  setFXAAEnabled(on) {
    let value = on ? 1 : 0;
    this.setInt(14, value);
  }

  setHighlightObjectId(dbId) {
    this.setInt(8, dbId);
  }

  setHighlightModelId(modelId) {
    this.setInt(9, modelId);
  }

  setHighlightIntensity(value) {
    this.setFloat(12, value);
  }

  getHighlightIntensity() {
    return this.getFloat(12);
  }

  setRolloverHighlightColor(color) {
    this.setColor(0, color, 1.0); //TODO: this changes so rarely that it would be better off as constant
  }

  setEdgeHighlightObjectId(dbId, modelId) {
    this.setInt(16, dbId);
    this.setInt(17, modelId);
  }

  setGlowFlag(flag) {
    this.setInt(10, flag);
  }

  setGlowOptions(color, compFunc) {
    //TODO: glow color uniform
    this.setInt(11, compFunc);
  }

  setAOBias(blendBias) {
    this.setFloat(15, blendBias);
  }

  setSelectionColor(color) {
    this.setColor(4, color, 1.0);
  }

  setUseOverlay(value) {
    this.setInt(18, value ? 1 : 0);
  }
}