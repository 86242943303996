/**
 * Events for Tandem, target DtApp (DtFacility.eventTarget)
 */

/**
 * Fired when a loaded DtFacility has some updates, made by concurrent client
 * @event Autodesk.Viewing.Private#DT_MODEL_CHANGED_EVENT
 */
export const DT_FACILITY_CHANGED_EVENT = 'dtFacilityChanged';

/**
 * Fired when a loaded DTModel has some updates, made by concurrent client
 * @event Autodesk.Viewing.Private#DT_MODEL_CHANGED_EVENT
 */
export const DT_MODEL_CHANGED_EVENT = 'dtModelChanged';
/**
 * Fired when a loaded DTModel has some metadata updates, made by concurrent client
 * @event Autodesk.Viewing.Private#DT_MODEL_METADATA_CHANGED_EVENT
 */
export const DT_MODEL_METADATA_CHANGED_EVENT = 'dtModelMetaDataChanged';

/**
 * Fired when the facets changed (based on model updates or filtering)
 * @event Autodesk.Viewing.Private#DT_FACETS_UPDATED
 */
export const DT_FACETS_UPDATED = 'dtFacetsUpdated';

/**
 * Fired when the facets manager has loaded all the facets
 * @event Autodesk.Viewing.Private#DT_FACETS_LOADE
 */
export const DT_FACETS_LOADED = 'dtFacetsLoaded';

/**
 * Fired when the facets are reset
 * @event Autodesk.Viewing.Private#DT_FACETS_RESET
 */
export const DT_FACETS_RESET = 'dtFacetsReset';

/**
 * Fired when the currently marked active team changed
 * @event Autodesk.Viewing.Private#DT_ACTIVE_TEAM_CHANGED_EVENT
 */
export const DT_ACTIVE_TEAM_CHANGED_EVENT = 'dtActiveTeamChanged';
/**
 * Fired when the team list has changed
 * @event Autodesk.Viewing.Private#DT_TEAMS_CHANGED_EVENT
 */
export const DT_TEAMS_CHANGED_EVENT = 'dtTeamsChanged';
/**
 * Fired when the list of users in a particular team has changed
 * @event Autodesk.Viewing.Private#DT_TEAM_USERS_CHANGED_EVENT
 */
export const DT_TEAM_USERS_CHANGED_EVENT = 'dtTeamUsersChanged';
/**
 * Fired when the list of facilities in a particular team has changed
 * @event Autodesk.Viewing.Private#DT_TEAM_FACILITIES_CHANGED_EVENT
 */
export const DT_TEAM_FACILITIES_CHANGED_EVENT = 'dtTeamFacilitiesChanged';
/**
 * Fired when the list of facilities shared with the current user has changed
 * @event Autodesk.Viewing.Private#DT_USER_FACILITIES_CHANGED_EVENT
 */
export const DT_USER_FACILITIES_CHANGED_EVENT = 'dtUserFacilitiesChanged';
/**
 * Fired when a team property has changed
 * @event Autodesk.Viewing.Private#DT_TEAM_CHANGED_EVENT
 */
export const DT_TEAM_CHANGED_EVENT = 'dtTeamChanged';

/**
 * Fired when the list of documents in a facility has changed
 * @event Autodesk.Viewing.Private#DT_DOCUMENTS_CHANGED_EVENT
 */
export const DT_DOCUMENTS_CHANGED_EVENT = 'dtDocumentsChanged';

/**
 * Fired when the primary model's metadata is loaded and the 3d model
 * space transformations have been derived from that model
 */
export const DT_PRIMARY_MODEL_LOADED = 'dtPrimaryModelLoaded';

/**
 * Fired when message websocket connection is restored
 */
export const DT_MSG_WS_RECONNECTED = 'dtMsgWsReconnected';

/**
 * Fired when a stream marker is clicked
 */
export const DT_STREAM_MARKER_CLICKED = 'dtStreamMarkerClicked';

/**
 * Fired when the cursor enters a stream marker
 */
export const DT_STREAM_MARKER_MOUSE_OVER = 'dtStreamMarkerMouseOver';

/**
 * Fired when the cursor exits a stream marker
 */
export const DT_STREAM_MARKER_MOUSE_OUT = 'dtStreamMarkerMouseOut';

/** Stream markers visibility or configuration changed */
export const DT_STREAM_MARKER_CHANGED_EVENT = 'dtStreamMarkerChanged';

/**
 * Fired when stream info changed
 */
export const DT_STREAMS_INFO_CHANGED_EVENT = 'dtStreamsInfoChanged';

/**
 * Fired when stream last readings changed
 */
export const DT_STREAMS_LAST_READINGS_CHANGED_EVENT = 'dtStreamsLastReadingsChanged';

/**
 * Floor plan has been generated
 */
export const DT_FLOOR_PLAN_DONE_EVENT = 'dtFloorPlanDone';

/**
 * Fired when systems info changed
 */
export const DT_SYSTEMS_CHANGED_EVENT = 'dtSystemsChanged';

/**
 * Fired when a system's connections changed
 */
export const DT_SYSTEM_CONNECTIONS_CHANGED_EVENT = 'dtSystemConnectionsChanged';

/** Heatmap visibility or configuration changed */
export const DT_HEATMAP_CHANGED_EVENT = 'dtHeatmapChanged';

/**
 * Fired when the current view changed
 */
export const DT_CURRENT_VIEW_CHANGED_EVENT = 'dtCurrentViewChanged';
/**
 * Fired when the list of views of a facility changes
 */
export const DT_FACILITY_VIEWS_CHANGED_EVENT = 'dtFacilityViewChanged';

/** Timeline configuration or underlying data changed. */
export const DT_TELEMETRY_CHANGED_EVENT = 'dtTelemetryChangedEvt';

/** Centralized "ticker" for synchronizing telemetry view observers. */
export const DT_TELEMETRY_TICK_EVENT = 'dtTelemetryTickEvt';

/** Fired when a facility user changed */
export const DT_FACILITY_USER_CHANGED_EVENT = 'dtFacilityUserChanged';