
import * as et from "../application/EventTypes";
import { Prefs } from '../application/PreferenceNames';
import { Viewer3D } from "../application/Viewer3D";
import { getGlobal, isFullscreenEnabled, isMobileDevice } from "../compat";
import { ErrorCodes, errorCodeString } from "../net/ErrorCodes";
import { logger } from "../logger/Logger";
import { HotGestureTool } from "../tools/HotGestureTool";
import { AlertBox } from "./AlertBox";
import { Button } from "./controls/Button";
import { ErrorHandler } from "./ErrorHandler";
import { SETTINGS_PANEL_CREATED_EVENT, TOOLBAR_CREATED_EVENT } from "./GuiViewerToolbarConst";
import { HudMessage } from "./HudMessage";
import { ProgressBar } from "./ProgressBar";
import { RenderOptionsPanel } from "./RenderOptionsPanel";
import { SettingsPanel } from "./SettingsPanel";
import { ViewerToolBar } from "./toolbar/ViewerToolBar";
import { ViewerSettingsPanel } from "./ViewerSettingsPanel";
import { NavToolsExtension } from "./NavTools";
import { StatusBar } from "./StatusBar";


/**
 * Viewer component based on {@link Autodesk.Viewing.Viewer3D} with added UI.
 *
 * @class
 * @param {HTMLElement} container - The viewer container.
 * @param {object} config - The initial settings object. See base class for details.
 * @alias Autodesk.Viewing.GuiViewer3D
 * @augments Autodesk.Viewing.Viewer3D
 */
export function GuiViewer3D(container, config) {
  if (!config) config = {};

  // Explicitly set startOnInitialize = false, as we want to finish some initialization
  // before starting the main loop.
  //
  config.startOnInitialize = false;

  Viewer3D.call(this, container, config);

  this.toolbar = null;

  // Container for the UI docking panels
  this.dockingPanels = [];

  this.onFullScreenModeEvent = this.onFullScreenModeEvent.bind(this);
  this.onProgressBarUpdate = this.onProgressBarUpdate.bind(this);

}

GuiViewer3D.prototype = Object.create(Viewer3D.prototype);
GuiViewer3D.prototype.constructor = GuiViewer3D;

GuiViewer3D.prototype.initialize = function (initOptions) {
  var viewerErrorCode = Viewer3D.prototype.initialize.call(this, initOptions);

  if (viewerErrorCode > 0) // ErrorCode was returned.
    {
      ErrorHandler.reportError(this.container, viewerErrorCode); // Show UI dialog
      this.localize();
      return viewerErrorCode;
    }

  var viewer = this;

  // Add padding to bottom to account for toolbar, when calling fitToView()
  // TODO: Use pixel size for setting these.
  //---this.navigation.FIT_TO_VIEW_VERTICAL_OFFSET = 0.03;
  //---this.navigation.FIT_TO_VIEW_VERTICAL_MARGIN = 0.0;

  if (this.toolController) {
    var hottouch = new HotGestureTool(this);

    this.toolController.registerTool(hottouch);

    this.toolController.activateTool(hottouch.getName());
  }

  this.addEventListener(et.FULLSCREEN_MODE_EVENT, this.onFullScreenModeEvent);

  // Context menu
  if (!this.contextMenu) {
    this.setDefaultContextMenu();
  }

  // Create a progress bar. Shows streaming.
  //
  this.progressbar = new ProgressBar(this.container);
  this.addEventListener(et.PROGRESS_UPDATE_EVENT, this.onProgressBarUpdate);

  this.statusbar = new StatusBar(this.container);
  this.onStatusBarPrefChanged = (value) => {
    this.statusbar.toggleVisibility(value);
  };
  this.prefs.addListeners(Prefs.DISPLAY_OBJECT_INFO, this.onStatusBarPrefChanged, null);

  this.addEventListener(et.VIEWER_RESIZE_EVENT, function (event) {
    viewer.resizePanels();
    viewer.toolbar?.updateToolbarButtons(event.width, event.height);
  });

  this.addEventListener(et.NAVIGATION_MODE_CHANGED_EVENT, function () {
    viewer.toolbar?.updateToolbarButtons(viewer.container.clientWidth, viewer.container.clientHeight);
  });

  this.initEscapeHandlers();

  // Now that all the ui is created, localize it.
  this.localize();

  this.addEventListener(et.WEBGL_CONTEXT_LOST_EVENT, function () {
    // Hide all divs
    var div = this.container;
    var divCount = div.childElementCount;
    for (var i = 0; i < divCount; ++i) {
      div.children[i].classList.add('hide-while-context-lost');
    }
    ErrorHandler.reportError(this.container, ErrorCodes.WEBGL_LOST_CONTEXT);
    this.localize();
  }.bind(this));

  this.addEventListener(et.WEBGL_CONTEXT_RESTORED_EVENT, function () {
    // Show all divs again
    var div = this.container;
    var divCount = div.childElementCount;
    for (var i = 0; i < divCount; ++i) {
      div.children[i].classList.remove('hide-while-context-lost');
    }
    ErrorHandler.dismissError(ErrorCodes.WEBGL_CONTEXT_LOST_EVENT);
  }.bind(this));

  this.addEventListener(et.WEBGPU_INIT_FAILED_EVENT, function () {
    // Hide all divs
    var div = this.container;
    var divCount = div.childElementCount;
    for (var i = 0; i < divCount; ++i) {
      div.children[i].classList.add('hide-while-context-lost');
    }
    ErrorHandler.reportError(this.container, ErrorCodes.WEBGPU_INIT_FAILED);
    this.localize();
  }.bind(this));

  // Now that all of our initialization is done, start the main loop.
  //
  this.run();

  return 0; // No errors initializing.
};

GuiViewer3D.prototype.uninitialize = function () {

  if (this.viewerSettingsPanel) {
    this.viewerSettingsPanel.uninitialize();
    this.viewerSettingsPanel = null;
  }

  if (this.renderoptions) {
    this.renderoptions.uninitialize();
    this.renderoptions = null;
  }

  if (this.viewerOptionButton) {

    this.viewerOptionButton = null;
  }

  this.removeEventListener(et.FULLSCREEN_MODE_EVENT, this.onFullScreenModeEvent);
  this.removeEventListener(et.PROGRESS_UPDATE_EVENT, this.onProgressBarUpdate);

  this.progressbar = null;

  this.debugMenu = null;
  this.modelStats = null;

  // Toolbar
  this.toolbar = null;

  Viewer3D.prototype.uninitialize.call(this);
};

GuiViewer3D.prototype.setUp = function (config) {
  if (!config) config = {};

  // Explicitly set startOnInitialize = false, as we want to finish some initialization
  // before starting the main loop.
  //
  config.startOnInitialize = false;

  Viewer3D.prototype.setUp.call(this, config);
};

GuiViewer3D.prototype.tearDown = function () {

  //TODO: this is unorthodox order of destruction, but we
  //need to call the super first so it unloads the extensions,
  //which need the GUI. We need to resolve this somehow.
  Viewer3D.prototype.tearDown.call(this);

  if (this.navToolsExt) {
    this.navToolsExt.unload();
    this.navToolsExt = null;
  }

  if (this.toolbar) {
    this.toolbar.container.parentNode.removeChild(this.toolbar.container);
    this.toolbar = null;
  }

  if (this.statusbar) {
    this.prefs.removeListeners(Prefs.DISPLAY_OBJECT_INFO, this.onStatusBarPrefChanged, null);
    this.statusbar.dtor();
    this.statusbar = null;
  }

  if (this.viewerSettingsPanel) {
    this.setSettingsPanel(null);
  }

  if (this.renderoptions) {
    this.removePanel(this.renderoptions);
    this.renderoptions.uninitialize();
    this.renderoptions = null;
  }
};

/**
 * Invokes extension's `onToolbarCreated` before `EXTENSION_LOADED_EVENT` gets fired.
 *
 * @param extension
 * @private
 */
GuiViewer3D.prototype.onPostExtensionLoad = function (extension) {
  var toolbar = this.getToolbar();
  if (toolbar && extension.onToolbarCreated) {
    extension.onToolbarCreated(toolbar);
  }

  this.toolbar?.updateToolbarButtons(this.container.clientWidth, this.container.clientHeight);
};

GuiViewer3D.prototype.loadModel = function (url, options, onSuccessCallback, onErrorCallback, initAfterWorker) {

  var viewer = this;

  /**
   * @param model
   * @private
   */
  function createUI() {
    if (!viewer.running) {
      logger.error("createUI expects the viewer to be running.", errorCodeString(ErrorCodes.VIEWER_INTERNAL_ERROR));
      return;
    }
    viewer.createUI(true);
  }

  /**
   * @param model
   * @private
   */
  function onSuccessChained(model) {

    //TODO: The exact timeout needs to be tuned for best
    //CPU utilization and shortest frame length during startup.
    setTimeout(function () {
      // Create UI when model is ready (except for headless and background loading)
      let skipCreateUI = options && (options.loadAsHidden || options.headlessViewer);

      //If this is not the first model loaded, skip UI creation,
      //because it was already done.
      if (viewer.impl.modelQueue().getModels().length > 1) {
        skipCreateUI = true;
      }

      if (!skipCreateUI) {
        createUI();
      }

      if (onSuccessCallback)
      onSuccessCallback.call(onSuccessCallback, model);
    }, 1);
  }

  /**
   * @param errorCode
   * @private
   */
  function onFailureChained(errorCode) {
    if (errorCode !== ErrorCodes.LOAD_CANCELED) {
      ErrorHandler.reportError(viewer.container, errorCode); // Show UI dialog
      this.localize();
    } else {
      logger.warn('A load was canceled');
    }
    onErrorCallback && onErrorCallback.apply(onErrorCallback, arguments);
  }

  var res = Viewer3D.prototype.loadModel.call(this, url, options, onSuccessChained, onFailureChained, initAfterWorker);

  return res;
};

GuiViewer3D.prototype.createUI = function (is3D) {

  var viewer = this;
  const newToolbarCreated = this._createToolbar();

  var disabledExtensions = this.config.disabledExtensions || {};

  this.initModelTools();

  if (newToolbarCreated) {
    // Dispatch a toolbar created event
    this.dispatchEvent({ type: TOOLBAR_CREATED_EVENT });
    // Notify extensions
    this.forEachExtension((ext) => {
      ext.onToolbarCreated && ext.onToolbarCreated(this.toolbar);
    });
  }

  // Load or update default navtools extension
  if (!this.navToolsExt) {
    this.navToolsExt = new NavToolsExtension(viewer, viewer.config);
    this.navToolsExt.load();
    this.navToolsExt.updateUI(is3D);
  }
  // If already loaded, just make sure that it is properly configured
  this.navToolsExt.updateUI(is3D);

  this.resize();

  // Make orbit a default navigation tool.
  if (this.getDefaultNavigationToolName().indexOf("orbit") === -1)
  this.setDefaultNavigationTool("orbit");

  //Load relevant extensions (on the next frame, since creating the UI is already too slow)
  setTimeout(function () {

    const ext3d = {
      viewcube: 'Autodesk.ViewCubeUi',
      bimwalk: 'Autodesk.BimWalk',
      measure: 'Autodesk.Measure',
      section: 'Autodesk.Section'
    };

    // Do not load BoxSelection on mobile devices.
    if (!isMobileDevice()) {
      ext3d.boxSelection = 'Autodesk.BoxSelection';
    }

    for (let key in ext3d) {
      const extId = ext3d[key];
      if (!disabledExtensions[key]) {
        viewer.loadExtension(extId, viewer.config);
      }
    }

  }, 0);
};

GuiViewer3D.prototype.onFullScreenModeEvent = function (event) {
  this.resizePanels();
  this.toolbar.updateFullscreenButton(event.mode);
};

GuiViewer3D.prototype.onProgressBarUpdate = function (event) {
  if (event.percent >= 0) {
    this.progressbar.setPercent(event.percent, event.state);
  }
};

GuiViewer3D.prototype.showViewer3dOptions = function (show) {
  var settingsPanel = this.getSettingsPanel(true);
  if (show && settingsPanel.isVisible()) {
    settingsPanel.setVisible(false);
  }
  settingsPanel.setVisible(show);
};

GuiViewer3D.prototype.showRenderingOptions = function (show) {
  if (show) {
    this._createRenderingOptionsPanel();
  }
  this.renderoptions && this.renderoptions.setVisible(show);
};

/**
 * @private
 */
GuiViewer3D.prototype._createRenderingOptionsPanel = function () {

  if (this.renderoptions || this.model.is2d())
  return;

  // panel
  this.renderoptions = new RenderOptionsPanel(this);
  this.addPanel(this.renderoptions);

  // toolbar button
  this.toolbar.initRenderOptionsButton();
};

/**
 * Sets the viewer's settings panel.
 *
 * @param {Autodesk.Viewing.UI.SettingsPanel} settingsPanel - The settings panel to use, or null.
 * @returns {boolean} True if the panel or null was set successfully, and false otherwise.
 */
GuiViewer3D.prototype.setSettingsPanel = function (settingsPanel) {
  var self = this;
  if (settingsPanel instanceof SettingsPanel || !settingsPanel) {
    if (this.viewerSettingsPanel) {
      this.viewerSettingsPanel.setVisible(false);
      this.removePanel(this.viewerSettingsPanel);
      this.viewerSettingsPanel.uninitialize();
    }

    this.viewerSettingsPanel = settingsPanel;
    if (settingsPanel) {
      this.addPanel(settingsPanel);

      settingsPanel.addVisibilityListener(function (visible) {
        if (visible) {
          self.onPanelVisible(settingsPanel, self);
        }
        self.toolbar?.viewerOptionButton.setState(visible ? Button.State.ACTIVE : Button.State.INACTIVE);
      });
    }
    return true;
  }
  return false;
};

GuiViewer3D.prototype.getSettingsPanel = function (createDefault, model) {
  if (!this.viewerSettingsPanel && createDefault) {
    this.createSettingsPanel(model || this.model);
  }
  return this.viewerSettingsPanel;
};

GuiViewer3D.prototype.createSettingsPanel = function () {
  var settingsPanel = new ViewerSettingsPanel(this, true);
  this.setSettingsPanel(settingsPanel);
  settingsPanel.syncUI();


  this.toolbar.initSettingsOptionsButton();


  this.dispatchEvent({ type: SETTINGS_PANEL_CREATED_EVENT });
};

GuiViewer3D.prototype.initModelTools = function () {
  // New viewer options' panel
  this.createSettingsPanel();

  if (getGlobal().ENABLE_DEBUG) {
    this._createRenderingOptionsPanel();
  }

  // LMV-5562 do not show the full screen button if document.fullscreenEnabled is set to false.
  if (this.canChangeScreenMode() && isFullscreenEnabled(this.getDocument())) {
    this.toolbar.initModelTools();
    this.toolbar.updateFullscreenButton(this.getScreenMode());
  }
};

/**
 * @param onSelect
 * @private
 * @deprecated
 */
GuiViewer3D.prototype.setPropertiesOnSelect = function (onSelect) {
  logger.warn('viewer.setPropertiesOnSelect() is now handled by viewer.prefs.set("openPropertiesOnSelect", <boolean>) and will be removed in version 8.0.0.');
  this.prefs.set(Prefs.OPEN_PROPERTIES_ON_SELECT, onSelect);
};

GuiViewer3D.prototype.initEscapeHandlers = function () {
  var viewer = this;

  this.addEventListener(et.ESCAPE_EVENT, function () {
    if (viewer.contextMenu && viewer.contextMenu.hide()) {
      return;
    }

    // Render options isn't enabled in release, so don't try to manipulate it
    if (viewer.renderoptions) {
      // Close render settings panel
      if (viewer.renderoptions.isVisible()) {
        viewer.renderoptions.setVisible(false);
        return;
      }
    }

    // TODO: stop any active animation

    // Deselect
    if (viewer.impl.selector.hasSelection()) {
      viewer.clearSelection();
      return;
    }

    // Reset default navigation mode:
    if (viewer.getActiveNavigationTool() !== viewer.getDefaultNavigationToolName()) {
      // Force unlock active tool:
      if (viewer.toolController)
      viewer.toolController.setIsLocked(false);

      viewer.setActiveNavigationTool();
      HudMessage.dismiss();
      return;
    }

    // Show all if anything is hidden
    if (!viewer.areAllVisible()) {
      viewer.showAll();
      return;
    }

    // Close open alert windows
    if (AlertBox.dismiss()) {
      return;
    }

    // Close open windows
    for (var i = 0; i < viewer.dockingPanels.length; ++i) {
      var panel = viewer.dockingPanels[i];
      if (panel.container.style.display !== "none" && panel.container.style.display !== "") {
        panel.setVisible(false);
        return;
      }
    }

    if (viewer.escapeScreenMode()) {
      return;
    }
  });
};

GuiViewer3D.prototype.getStatusBar = function () {
  return this.statusbar;
};

/**
 * Returns a toolbar.
 *
 * @returns {Autodesk.Viewing.UI.ToolBar} Returns the toolbar.
 */
GuiViewer3D.prototype.getToolbar = function () {
  return this.toolbar;
};

GuiViewer3D.prototype._createToolbar = function () {

  if (this.toolbar)
  return false;

  const viewer = this;
  this.toolbar = new ViewerToolBar('guiviewer3d-toolbar', {
    globalManager: this.globalManager,
    navigation: this.navigation,
    screenModeDelegate: this.getScreenModeDelegate(),
    onClickFullScreen: viewer.nextScreenMode.bind(viewer),
    onClickRenderOptions: () => {
      var isVisible = this.renderoptions && this.renderoptions.isVisible();
      this.renderoptions.setVisible(!isVisible);
    },
    onClickViewerOption: () => {

      var panel = viewer.getSettingsPanel(true);
      if (!panel.isVisible()) {
        viewer.showViewer3dOptions(true);
      } else {
        viewer.showViewer3dOptions(false);
      }
    }
  });

  this.container.appendChild(this.toolbar.container);

  this.toolbar.updateToolbarButtons(this.container.clientWidth, this.container.clientHeight, this.navigation);

  return true;
};

GuiViewer3D.prototype.onPanelVisible = function (panel) {

  // Shift this window to the top of the list, so that it will be closed first
  //
  this.dockingPanels.splice(this.dockingPanels.indexOf(panel), 1);
  this.dockingPanels.splice(0, 0, panel);
};

GuiViewer3D.prototype.localize = function () {
  ErrorHandler.localize();
  Viewer3D.prototype.localize.call(this);
};


/**
 * Adds a panel to the viewer. The panel will be moved and resized if the viewer
 * is resized and the panel falls outside of the bounds of the viewer.
 *
 * @param {Autodesk.Viewing.UI.PropertyPanel} panel - The panel to add.
 * @returns {boolean} True if panel was successfully added.
 *
 */
GuiViewer3D.prototype.addPanel = function (panel) {
  var index = this.dockingPanels.indexOf(panel);
  if (index === -1) {
    this.dockingPanels.push(panel);
    return true;
  }
  return false;
};

/**
 * Removes a panel from the viewer. The panel will no longer be moved and
 * resized if the viewer is resized.
 *
 * @param {Autodesk.Viewing.UI.PropertyPanel} panel - The panel to remove.
 * @returns {boolean} True if panel was successfully removed.
 */
GuiViewer3D.prototype.removePanel = function (panel) {
  var index = this.dockingPanels.indexOf(panel);
  if (index > -1) {
    this.dockingPanels.splice(index, 1);
    return true;
  }
  return false;
};

/**
 * Resizes the panels currently held by the viewer.
 *
 * @param {object} [options] - An optional dictionary of options.
 * @param {Array} [options.dockingPanels=all] - A list of panels to resize.
 * @param {object} [options.dimensions] - The area for the panels to occupy.
 * @param {number} options.dimensions.width - Width.
 * @param {number} options.dimensions.height - Height.
 */
GuiViewer3D.prototype.resizePanels = function (options) {

  options = options || {};

  var toolbarHeight = this.toolbar ? this.toolbar.getDimensions().height : 0;
  var dimensions = this.getDimensions();
  var maxHeight = dimensions.height;

  if (options.dimensions && options.dimensions.height) {
    maxHeight = options.dimensions.height;
  } else
  {
    options.dimensions = {
      height: dimensions.height,
      width: dimensions.width
    };
  }

  options.dimensions.height = maxHeight - toolbarHeight;

  var viewer = this;

  var dockingPanels = options ? options.dockingPanels : null;
  if (!dockingPanels) {
    dockingPanels = viewer.dockingPanels;
  }

  var viewerRect = viewer.container.getBoundingClientRect(),
    vt = viewerRect.top,
    vb = viewerRect.bottom,
    vl = viewerRect.left,
    vr = viewerRect.right,
    vw,vh;

  if (options && options.dimensions) {
    vw = options.dimensions.width;
    vh = options.dimensions.height;
    vb = vt + vh;
  } else {
    vw = viewerRect.width;
    vh = viewerRect.height;
  }

  for (var i = 0; i < dockingPanels.length; ++i) {
    dockingPanels[i].onViewerResize(vt, vb, vl, vr, vw, vh);
  }

};

/**
 * Register the function called after updateToolbarButtons. This allows the developer to customize the toolbar layout if needed.
 * The callback will be called with the parameters (viewer_object, panel_width, panel_height). Its return type can be undefied and is ignored.
 *
 * @param {Function} callbackFunction - Callback
 */
GuiViewer3D.prototype.registerCustomizeToolbarCB = function (callbackFunction) {
  this.toolbar?.registerCustomizeToolbarCB(callbackFunction.bind(null, this));
  this.toolbar?.updateToolbarButtons(this.container.clientWidth, this.container.clientHeight);
};

/**
 * Returns a promise resolving when the toolbar is present.
 *
 * @returns {Promise} Resolved when toolbar is available.
 */
GuiViewer3D.prototype.waitForToolbar = async function () {
  if (this.getToolbar()) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    const onToolbarCreated = () => {
      this.removeEventListener(TOOLBAR_CREATED_EVENT, onToolbarCreated);
      resolve();
    };
    this.addEventListener(TOOLBAR_CREATED_EVENT, onToolbarCreated);
  });
};

Object.defineProperty(GuiViewer3D.prototype, 'navTools', {
  get() {return this.toolbar?.navTools;}
});
Object.defineProperty(GuiViewer3D.prototype, 'modelTools', {
  get() {return this.toolbar?.modelTools;}
});
Object.defineProperty(GuiViewer3D.prototype, 'settingsTools', {
  get() {return this.toolbar?.settingsTools;}
});
Object.defineProperty(GuiViewer3D.prototype, 'updateFullscreenButton', {
  get() {return this.toolbar?.updateFullscreenButton.bind(this);}
});