import { wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";
import { getCameraUniformsDeclaration } from "./CameraUniforms";
import { getObjectUniformsDeclaration } from "./ObjectUniforms";
import { getIBLDeclaration } from "./IBL";

export function getLine3DShader() {

  return wgsl /*wgsl*/`

	${getIBLDeclaration(0)}
	${getCameraUniformsDeclaration(1)}
	${getObjectUniformsDeclaration(2)}

	struct VertexOutput {
		@builtin(position) Position : vec4f,
		@location(0) @interpolate(flat) instance: u32,
		@location(4) vWorldPosition: vec3f
	}

	@vertex
	fn vsmain(
		@location(0) position : vec3f,
		@builtin(instance_index) instance : u32
	) -> VertexOutput {

		var objectUniforms = getObjectUniforms(instance);

		var output : VertexOutput;
		var pos4 = vec4f(position.x, position.y, position.z, 1.0);

		var mvMatrix = uniforms.viewMatrix * objectUniforms.modelMatrix;
		var mvPosition = mvMatrix * pos4;

		output.Position = uniforms.projectionMatrix * mvPosition;
		output.vWorldPosition = (objectUniforms.modelMatrix * pos4).xyz;

		output.instance = instance;

		//TODO: world position for cutplanes

		return output;
	}

	struct PixelOutput {
		@location(0) color: vec4f,
		@location(1) normal: vec4f,
		@location(2) viewDepth: vec4f,
		@location(3) dbId: vec4u,
		@location(4) modelId: vec4u
		//@location(4) objectFlags: vec4u,
	}

	@fragment
	fn psmain(
		in: VertexOutput,
	) -> PixelOutput {

		var output: PixelOutput;

		var objectUniforms = getObjectUniforms(in.instance);
		var materialUniforms = getMaterialUniforms(objectUniforms.materialIndex);

		//TODO: vertex color
		var diffuse = intToVecf(materialUniforms.diffuse);

		//check doNotCut flag
		if (commonMaterialUniforms.doNotCutOverride + (materialUniforms.renderFlags & 2) == 0) {
			checkCutPlanes(in.vWorldPosition);
		}

		//For line geometry, we just return the color as is
		output.color = diffuse;
		output.normal = vec4f(0.0);
		output.viewDepth = vec4f(0.0);
		output.dbId = intToVec(objectUniforms.dbId);
		output.modelId = intToVec(objectUniforms.modelId);

		return output;
	}
	`;

}