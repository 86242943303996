import { binToPackedString, hexToBin } from "./hex-strings";
import { v4 as uuidV4 } from "uuid";

/*\
|*|
|*|  Base64 / binary data / UTF-8 strings utilities (#1)
|*|
|*|  https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
|*|
|*|  Author: madmurphy
|*|
\*/

/* Array of bytes to base64 string decoding */

function b64ToUint6Gen(nChr) {

  return nChr > 64 && nChr < 91 ?
  nChr - 65 :
  nChr > 96 && nChr < 123 ?
  nChr - 71 :
  nChr > 47 && nChr < 58 ?
  nChr + 4 :
  nChr === 43 || nChr === 45 ?
  62 :
  nChr === 47 || nChr === 95 ?
  63 :

  0;

}

let b64ToUint6 = new Uint8Array(128);
for (let i = 0; i < 128; i++) {
  b64ToUint6[i] = b64ToUint6Gen(i);
}

export function base64DecodedLen(nInLen) {
  return nInLen * 3 + 1 >>> 2;
}

export function base64DecToArr(sBase64, dst, startOffset) {
  let start = startOffset || 0;
  let sB64Enc = sBase64;
  let nInLen = sB64Enc.length;
  let nOutLen = base64DecodedLen(nInLen);

  if (!dst || dst.length < start + nOutLen) {
    return -1;
  }

  for (var nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
    nMod4 = nInIdx & 3;
    nUint24 |= b64ToUint6[sB64Enc.charCodeAt(nInIdx)] << 18 - 6 * nMod4;
    if (nMod4 === 3 || nInLen - nInIdx === 1) {
      for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
        dst[start + nOutIdx] = nUint24 >>> (16 >>> nMod3 & 24) & 255;
      }
      nUint24 = 0;
    }
  }

  return nOutLen;
}

export function base64DecArrToArr(aBase64, off, len, dst) {
  let aB64Enc = aBase64;
  let nInLen = len;
  let nOutLen = base64DecodedLen(nInLen);

  if (!dst || dst.length < nOutLen) {
    return -1;
  }

  for (let nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
    nMod4 = nInIdx & 3;
    nUint24 |= b64ToUint6[aB64Enc[off + nInIdx]] << 18 - 6 * nMod4;
    if (nMod4 === 3 || nInLen - nInIdx === 1) {
      for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
        dst[nOutIdx] = nUint24 >>> (16 >>> nMod3 & 24) & 255;
      }
      nUint24 = 0;
    }
  }

  return nOutLen;
}


/* Base64 string to array encoding */
/*
function uint6ToB64 (nUint6) {

	return nUint6 < 26 ?
			nUint6 + 65
		: nUint6 < 52 ?
			nUint6 + 71
		: nUint6 < 62 ?
			nUint6 - 4
		: nUint6 === 62 ?
			43
		: nUint6 === 63 ?
			47
		:
			65;

}
*/

//Generates this base64 alphabet: const encodeURL = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_"
function uint6ToB64WebsafeGen(nUint6) {

  return nUint6 < 26 ?
  nUint6 + 65 :
  nUint6 < 52 ?
  nUint6 + 71 :
  nUint6 < 62 ?
  nUint6 - 4 :
  nUint6 === 62 ?
  45 :
  nUint6 === 63 ?
  95 :

  65;
}

let uint6ToB64Websafe = new Uint8Array(64);
for (let i = 0; i < 64; i++) {
  uint6ToB64Websafe[i] = uint6ToB64WebsafeGen(i);
}

let arr22 = new Array(22);
let arr27 = new Array(27);
let arr32 = new Array(32);

//from https://cs.opensource.google/go/go/+/refs/tags/go1.18.2:src/encoding/base64/base64.go;l=125
export function base64EncArr(src, offset, length) {

  let baseOffset = offset || 0;
  let nLen = length || src.length;

  //let paddingLen = (3 - (nLen % 3)) % 3; //padding length that we will ignore for web safe encoding
  let encodedLenNoPadding = (nLen * 8 + 5) / 6 | 0;

  let dst;
  switch (encodedLenNoPadding) {
    case 22:
      dst = arr22;
      break; //for 16 byte input
    case 27:
      dst = arr27;
      break; //for 20 byte input
    case 32:
      dst = arr32;
      break; //for 24 byte input
    default:
      dst = new Array(encodedLenNoPadding);
      break;
  }

  let di = 0,si = baseOffset;
  let n = (nLen / 3 | 0) * 3 + baseOffset;
  while (si < n) {
    // Convert 3x 8bit source bytes into 4 bytes
    let val = src[si] << 16 | src[si + 1] << 8 | src[si + 2];

    dst[di] = uint6ToB64Websafe[val >> 18 & 0x3F];
    dst[di + 1] = uint6ToB64Websafe[val >> 12 & 0x3F];
    dst[di + 2] = uint6ToB64Websafe[val >> 6 & 0x3F];
    dst[di + 3] = uint6ToB64Websafe[val & 0x3F];

    si += 3;
    di += 4;
  }

  let remain = nLen - si + baseOffset;
  if (remain === 0) {
    return String.fromCharCode.apply(null, dst);
  }
  // Add the remaining small block
  let val = src[si] << 16;
  if (remain === 2) {
    val |= src[si + 1] << 8;
  }

  dst[di] = uint6ToB64Websafe[val >> 18 & 0x3F];
  dst[di + 1] = uint6ToB64Websafe[val >> 12 & 0x3F];

  switch (remain) {
    case 2:
      dst[di + 2] = uint6ToB64Websafe[val >> 6 & 0x3F];
    //no padding
    case 1:
    //no padding
    //no padding
  }

  return String.fromCharCode.apply(null, dst);
}


let _tmpBuf24 = new Uint8Array(24);

export function b64ToBinaryString(s) {
  let len = base64DecToArr(s, _tmpBuf24);
  return binToPackedString(_tmpBuf24, 0, len);
}

export function b64ArrToBinaryString(buf, off, len) {
  let rlen = base64DecArrToArr(buf, off, len, _tmpBuf24);
  return binToPackedString(_tmpBuf24, 0, rlen);
}

export function makeNewEncodedElementID(keyFlag) {

  //Big-Endian flags
  _tmpBuf24[0] = keyFlag >> 24 & 0xff;
  _tmpBuf24[1] = keyFlag >> 16 & 0xff;
  _tmpBuf24[2] = keyFlag >> 8 & 0xff;
  _tmpBuf24[3] = keyFlag & 0xff;

  // generate binary uuid into buffer at offset 4
  uuidV4({}, _tmpBuf24, 4);

  // fill remaining bytes (>=20) with 0
  _tmpBuf24.fill(0, 20);

  return base64EncArr(_tmpBuf24);
}

const _tmpBuf16 = new Uint16Array(16);

export function guidToBase64(uuid) {
  hexToBin(uuid.replace(/-/g, ''), _tmpBuf16, 0);
  return base64EncArr(_tmpBuf16);
}