import { Prefs, Prefs2D, Prefs3D, VIEW_TYPES } from './PreferenceNames';
import { logger } from "../logger/Logger";

import { SelectionMode } from '../wgs/scene/SelectionMode';
import { isMobileDevice, getGlobal } from '../compat';
import { DefaultLightPreset } from './LightPresets';

import { displayUnitsEnum, displayUnitsPrecisionEnum } from '../measurement/DisplayUnits';
import { LocalStorage } from "./LocalStorage";
import { EnumType } from "./EnumType";

/**
 * Object used to apply the preferences by a Profile
 * @typedef {Object} Settings
 * @property {boolean} viewCube - Sets the visibility of the viewcube.
 * @property {boolean} viewCubeCompass - Sets the visibility of the viewcube compass. The compass will only be displayed if model contains orientation data.
 * @property {number} viewType - Sets the view to default (0), orthographic (1), perspective (2) or perspective with ortho faces (3).
 * @property {boolean} alwaysUsePivot - Orbit controls always orbit around the currently set pivot point.
 * @property {boolean} zoomTowardsPivot - default direction for camera dolly (zoom) operations to be towards the camera pivot point.
 * @property {boolean} reverseHorizontalLookDirection - Sets a view navigation option to reverse the default direction for horizontal look operations.
 * @property {boolean} reverseVerticalLookDirection - Sets a view navigation option to reverse the default direction for vertical look operations.
 * @property {boolean} orbitPastWorldPoles - Set a view navigation option to allow the orbit controls to move the camera beyond the north and south poles (world up/down direction).
 * @property {boolean} clickToSetCOI - Modify the default click behavior for the viewer.
 * @property {boolean} ghosting - Toggles ghosting during search and isolate.
 * @property {boolean} optimizeNavigation - Toggles whether the navigation should be optimized for performance.
 * @property {boolean} ambientShadows - Enables or disables ambient shadows.
 * @property {boolean} antialiasing - Enables or disables antialiasing.
 * @property {boolean} groundShadow - Toggles ground shadow.
 * @property {boolean} groundReflection - Toggles ground reflection.
 * @property {boolean} lineRendering - Hides all lines in the scene.
 * @property {boolean} edgeRendering - Turns edge topology display on/off (where available).
 * @property {number|string} lightPreset - Sets the Light Presets (Environments) for the Viewer.
 * @property {boolean} firstPersonToolPopup - Toggles first person tool popup.
 * @property {boolean} bimWalkToolPopup - Toggles the bimwalk tool popup.
 * @property {boolean} grayscale - Overrides line colors in 2D models to render in shades of gray.
 * @property {boolean} swapBlackAndWhite - Will switch to white lines on a black background.
 * @property {boolean} progressiveRendering - Toggles whether progressive rendering is used.
 * @property {boolean} openPropertiesOnSelect - Open property panel when selecting an object (Only for GuiViewer3D).
 * @property {boolean} pointRendering - Hides all points in the scene.
 * @property {*} backgroundColorPreset - Sets a color to the background.
 * @property {boolean} reverseMouseZoomDir - Reverse the default direction for camera dolly (zoom) operations.
 * @property {boolean} leftHandedMouseSetup - Reverse mouse buttons from their default assignment (i.e. Left mouse operation becomes right mouse and vice versa).
 * @property {boolean} fusionOrbit - Sets the orbit to fusion orbit.
 * @property {boolean} fusionOrbitConstrained - Sets the the orbit to the contrained fusion orbit.
 * @property {boolean} wheelSetsPivot - Sets wheel-zoom action to automatically reset the orbit pivot to the location under the cursor.
 * @property {boolean} selectionSetsPivot - Sets selection / un-selection action to automatically reset the orbit pivot to be the center of the multiple selection.
 * @property {string} bimWalkNavigatorType - Sets the BimWalk tool navigator.
 * @property {boolean} bimWalkGravity - Toggles the BimWalk tool's gravity.
 * @property {string} defaultNavigationTool3D - Sets which navigation tool will be used by the viewer. (ie: 'extractor_defined' || 'bimwalk')
 * @property {string} explodeStrategy - Sets which algorithm is used when exploding a model. Supported values are 'hierarchy' (default) and 'radial'. Other values are treated as 'radial'.
 * @property {boolean} forcePDFCalibration - Force PDF calibration before measuring.
 * @property {boolean} forceLeafletCalibration - Force Leaflet calibration before measuring.
 * @property {boolean} restoreMeasurements - When opening the measure tool restore any existing measurements that where created during the session.
 * @property {boolean} forceDoubleSided - Force the render to use double sided materials.
 * @property {boolean} keyMapCmd - Force mapping CMD key to Ctrl in Mac.
 * @property {boolean} displaySectionHatches - Display the hatch pattern for planes in the section tool. This does not apply to the section box.
 */

const defaultSettings = {};
defaultSettings[Prefs3D.AMBIENT_SHADOWS] = true;
defaultSettings[Prefs3D.ANTIALIASING] = !isMobileDevice();
defaultSettings[Prefs3D.GROUND_SHADOW] = true;
defaultSettings[Prefs3D.GROUND_REFLECTION] = false;
defaultSettings[Prefs3D.GHOSTING] = true;
defaultSettings[Prefs3D.VIEW_CUBE] = !isMobileDevice();
defaultSettings[Prefs3D.VIEW_CUBE_COMPASS] = false;
defaultSettings[Prefs3D.VIEW_TYPE] = VIEW_TYPES.DEFAULT;
defaultSettings[Prefs3D.LINE_RENDERING] = true;
defaultSettings[Prefs3D.LIGHT_PRESET] = DefaultLightPreset;
defaultSettings[Prefs3D.EDGE_RENDERING] = false;
defaultSettings[Prefs3D.REVERSE_HORIZONTAL_LOOK_DIRECTION] = false;
defaultSettings[Prefs3D.REVERSE_VERTICAL_LOOK_DIRECTION] = false;
defaultSettings[Prefs3D.ALWAYS_USE_PIVOT] = false;
defaultSettings[Prefs3D.ZOOM_TOWARDS_PIVOT] = false;
defaultSettings[Prefs3D.ORBIT_PAST_WORLD_POLES] = true;
defaultSettings[Prefs3D.CLICK_TO_SET_COI] = false;
defaultSettings[Prefs3D.OPTIMIZE_NAVIGATION] = isMobileDevice();
defaultSettings[Prefs3D.FIRST_PERSON_TOOL_POPUP] = true;
defaultSettings[Prefs3D.BIM_WALK_TOOL_POPUP] = true;
defaultSettings[Prefs3D.BIM_WALK_NAVIGATOR_TYPE] = 'default';
defaultSettings[Prefs3D.BIM_WALK_GRAVITY] = true;
defaultSettings[Prefs3D.DEFAULT_NAVIGATION_TOOL_3D] = 'default';
defaultSettings[Prefs3D.SELECTION_MODE] = SelectionMode.LEAF_OBJECT;
defaultSettings[Prefs3D.ENABLE_CUSTOM_ORBIT_TOOL_CURSOR] = true;
defaultSettings[Prefs3D.EXPLODE_STRATEGY] = 'hierarchy';
defaultSettings[Prefs3D.SELECTION_SETS_PIVOT] = false;
defaultSettings[Prefs3D.FORCE_DOUBLE_SIDED] = false;
defaultSettings[Prefs3D.DISPLAY_SECTION_HATCHES] = true;

// Settings for 2D
defaultSettings[Prefs2D.GRAYSCALE] = false;
defaultSettings[Prefs2D.SWAP_BLACK_AND_WHITE] = false;
defaultSettings[Prefs2D.FORCE_PDF_CALIBRATION] = false;
defaultSettings[Prefs2D.FORCE_LEAFLET_CALIBRATION] = true;
defaultSettings[Prefs2D.DISABLE_PDF_HIGHLIGHT] = false;

// Settings that are shared between 2D and 3D
defaultSettings[Prefs.PROGRESSIVE_RENDERING] = true;
defaultSettings[Prefs.WEBGPU_ENABLE] = false;
defaultSettings[Prefs.OPEN_PROPERTIES_ON_SELECT] = false;
defaultSettings[Prefs.POINT_RENDERING] = true;
defaultSettings[Prefs.BACKGROUND_COLOR_PRESET] = null;
defaultSettings[Prefs.REVERSE_MOUSE_ZOOM_DIR] = false;
defaultSettings[Prefs.LEFT_HANDED_MOUSE_SETUP] = false;
defaultSettings[Prefs.FUSION_ORBIT] = true;
defaultSettings[Prefs.FUSION_ORBIT_CONSTRAINED] = true;
defaultSettings[Prefs.WHEEL_SETS_PIVOT] = false;
defaultSettings[Prefs.RESTORE_SESSION_MEASUREMENTS] = true;
defaultSettings[Prefs.DISPLAY_UNITS] = new EnumType(displayUnitsEnum);
defaultSettings[Prefs.DISPLAY_UNITS_PRECISION] = new EnumType(displayUnitsPrecisionEnum);
defaultSettings[Prefs.KEY_MAP_CMD] = true;
defaultSettings[Prefs.DISABLE_INDEXED_DB] = false;
defaultSettings[Prefs.DISPLAY_OBJECT_INFO] = false;

/**
 * Default settings of the viewer.
 * For more information about each setting, please reference the {@link Settings}.
 * @typedef {Settings} DefaultSettings
 * @property {boolean} viewCube - Default Value: true. Sets the visibility of the viewcube. Set to false for mobile devices.
 * @property {boolean} alwaysUsePivot - Default Value: false. Orbit controls always orbit around the currently set pivot point.
 * @property {boolean} zoomTowardsPivot - Default Value: false. default direction for camera dolly (zoom) operations to be towards the camera pivot point.
 * @property {boolean} reverseHorizontalLookDirection - Default Value: false. Sets a view navigation option to reverse the default direction for horizontal look operations.
 * @property {boolean} reverseVerticalLookDirection - Default Value: false. Sets a view navigation option to reverse the default direction for vertical look operations.
 * @property {boolean} orbitPastWorldPoles - Default Value: true. Set a view navigation option to allow the orbit controls to move the camera beyond the north and south poles (world up/down direction).
 * @property {boolean} clickToSetCOI - Default Value: false. Modify the default click behavior for the viewer.
 * @property {boolean} ghosting - Default Value: true. Toggles ghosting during search and isolate.
 * @property {boolean} optimizeNavigation - Default Value: false. Toggles whether the navigation should be optimized for performance. Set to true for mobile devices.
 * @property {boolean} ambientShadows - Default Value: true. Enables or disables ambient shadows.
 * @property {boolean} antialiasing - Default Value: true. Enables or disables antialiasing. Set to false for mobile devices.
 * @property {boolean} groundShadow - Default Value: true. Toggles ground shadow.
 * @property {boolean} groundReflection - Default Value: false. Toggles ground reflection.
 * @property {boolean} lineRendering - Default Value: true. Hides all lines in the scene.
 * @property {boolean} edgeRendering - Default Value: false. Turns edge topology display on/off (where available).
 * @property {number} lightPreset - Default Value: 1. Sets the Light Presets (Environments) for the Viewer.
 * @property {boolean} firstPersonToolPopup - Default Value: true. Toggles first person tool popup.
 * @property {boolean} bimWalkToolPopup - Default Value: true. Toggles the bimwalk tool popup.
 * @property {boolean} grayscale - Default Value: false. Overrides line colors in 2D models to render in shades of gray.
 * @property {boolean} swapBlackAndWhite - Default Value: false. Will switch to white lines on a black background for 2D models.
 * @property {boolean} progressiveRendering - Default Value: true. Toggles whether progressive rendering is used.
 * @property {boolean} openPropertiesOnSelect - Default Value: false. Open property panel when selecting an object (Only for GuiViewer3D).
 * @property {boolean} pointRendering - Default Value: true. Hides all points in the scene.
 * @property {*} backgroundColorPreset - Default Value: null. Sets a color to the background.
 * @property {boolean} reverseMouseZoomDir - Default Value: false. Reverse the default direction for camera dolly (zoom) operations.
 * @property {boolean} leftHandedMouseSetup - Default Value: false. Reverse mouse buttons from their default assignment (i.e. Left mouse operation becomes right mouse and vice versa).
 * @property {boolean} fusionOrbit - Default Value: true. Sets the orbit to fusion orbit.
 * @property {boolean} fusionOrbitConstrained - Default Value: true. Sets the the orbit to the contrained fusion orbit.
 * @property {boolean} wheelSetsPivot - Default Value: false. Sets wheel-zoom action to automatically reset the orbit pivot to the location under the cursor.
 * @property {boolean} selectionSetsPivot - Default Value: false. Sets selection / un-selection action to automatically reset the orbit pivot to be the center of the multiple selection.
 * @property {string} bimWalkNavigatorType - Default Value: 'default'. Sets the BimWalk tool navigator.
 * @property {string} defaultNavigationTool3D - Default Value: 'default'. Sets which navigation tool will be used by the viewer. (ie: 'extractor_defined' || 'bimwalk')
 * @constant
 * @type {ProfileSettings}
 * @memberof Autodesk.Viewing
 * @default
 */
export const DefaultSettings = defaultSettings;

// Contains Profile Settings that can be used to initialize Profiles.

/**
 * Object used for setting a viewer profile.
 * @typedef {Object} ProfileSettings
 * @property {string} name - Name of the profile settings.
 * @property {string} [label] - Optional. An end-user string to use instead of the name.
 * @property {string} [description] - Optional. A description about the profile.
 * @property {Settings} settings - Used by the Profile to apply to the viewer preferences.
 * @property {String[]} persistent - An array of setting ids to keep in localStorage.
 */

const defaults = {};

// Settings for 3D
defaults.name = 'Default';
defaults.label = 'Manufacturing (Default)';
defaults.description = 'Default Viewer settings';

defaults.settings = defaultSettings;

// Stores the preference (settings) values in localStorage
defaults.persistent = [
// 3D
Prefs3D.ALWAYS_USE_PIVOT,
Prefs3D.ZOOM_TOWARDS_PIVOT,
Prefs3D.REVERSE_HORIZONTAL_LOOK_DIRECTION,
Prefs3D.REVERSE_VERTICAL_LOOK_DIRECTION,
Prefs3D.ORBIT_PAST_WORLD_POLES,
Prefs3D.CLICK_TO_SET_COI,
Prefs3D.GHOSTING,
Prefs3D.OPTIMIZE_NAVIGATION,
Prefs3D.AMBIENT_SHADOWS,
Prefs3D.ANTIALIASING,
Prefs3D.GROUND_SHADOW,
Prefs3D.GROUND_REFLECTION,
Prefs3D.FIRST_PERSON_TOOL_POPUP,
Prefs3D.BIM_WALK_TOOL_POPUP,
Prefs3D.BIM_WALK_GRAVITY,
Prefs3D.VIEW_TYPE,
Prefs3D.SELECTION_MODE,
Prefs3D.LINE_RENDERING,
Prefs3D.LIGHT_PRESET,
Prefs3D.EDGE_RENDERING,
// 2D
Prefs2D.SWAP_BLACK_AND_WHITE,
// 3D and 2D
Prefs.OPEN_PROPERTIES_ON_SELECT,
Prefs.REVERSE_MOUSE_ZOOM_DIR,
Prefs.LEFT_HANDED_MOUSE_SETUP,
Prefs.WHEEL_SETS_PIVOT,
Prefs.KEY_MAP_CMD,
Prefs.DISPLAY_UNITS,
Prefs.DISPLAY_UNITS_PRECISION,
Prefs.DISABLE_INDEXED_DB,
Prefs.DISPLAY_OBJECT_INFO,
Prefs.WEBGPU_ENABLE];


/******************* AEC Profile Settings *******************/

const aec = clone(defaults);
aec.name = 'AEC';
aec.label = 'Construction (AEC)';
aec.description = 'A common set of preferences designed for the Construction industry';
aec.settings[Prefs.REVERSE_MOUSE_ZOOM_DIR] = true;
aec.settings[Prefs.FUSION_ORBIT] = false;
aec.settings[Prefs.FUSION_ORBIT_CONSTRAINED] = false;
aec.settings[Prefs3D.EDGE_RENDERING] = !isMobileDevice();
aec.settings[Prefs3D.LIGHT_PRESET] = getGlobal().DefaultLightPresetAec || "Boardwalk";
aec.settings[Prefs3D.VIEW_CUBE_COMPASS] = true;
aec.settings[Prefs3D.SELECTION_SETS_PIVOT] = true;

/******************* Fluent Profile Settings *******************/

const fluent = clone(aec);
fluent.name = 'Fluent';
fluent.label = 'Design Collaboration'; // this one gets displayed and localized.
fluent.description = 'User experience that matches Design Collaboration';
fluent.settings[Prefs3D.GROUND_SHADOW] = false;
fluent.settings[Prefs.WHEEL_SETS_PIVOT] = true;
fluent.settings[Prefs.RESTORE_SESSION_MEASUREMENTS] = false;
fluent.settings[Prefs2D.FORCE_PDF_CALIBRATION] = true;
fluent.settings[Prefs3D.ALWAYS_USE_PIVOT] = true;
fluent.settings[Prefs3D.ENABLE_CUSTOM_ORBIT_TOOL_CURSOR] = false;

fluent.persistent.splice(fluent.persistent.indexOf(Prefs3D.VIEW_TYPE), 1);


/******************* Navis Profile Settings *******************/

// Cloned from the AEC Profile Settings
const navis = clone(aec);
navis.name = 'Navis';
navis.label = 'Navisworks';
navis.description = 'Provides a user experience similar to Autodesk Navisworks desktop application';
navis.settings[Prefs3D.BIM_WALK_TOOL_POPUP] = false;
navis.settings[Prefs3D.BIM_WALK_NAVIGATOR_TYPE] = 'aec';
navis.settings[Prefs3D.DEFAULT_NAVIGATION_TOOL_3D] = 'extractor_defined';


/******************* Helper Functions *******************/

/**
 * Copies a profile settings object.
 * @param {ProfileSettings} [profileSettings] - profile settings to copy, otherwise uses the Autodesk.Viewing.ProfileSettings.Default
 *
 * @returns {ProfileSettings} - profile settings object.
 *
 * @private
 */
function clone(profileSettings) {
  if (!profileSettings) {
    logger.log("ProfileSettings.clone: missing profileSettings, using DefaultProfileSettings...");
    profileSettings = defaults;
  }
  const newPS = {};
  newPS.settings = Object.assign({}, profileSettings.settings);
  newPS.persistent = profileSettings.persistent.slice();

  return newPS;
}


/**
 * Profiles encapsulate viewer settings.
 *
 * The `profileSettings.settings` parameter will override the existing  {@link Autodesk.Viewing.Private.Preferences|preferences} upon calling the {@link Autodesk.Viewing.Profile#apply|apply} method.
 * Make sure to set the profile by using the {@link Autodesk.Viewing.Viewer3D#setProfile} method.
 *
 * @example
 * const profileSettings = {
 *    name: "mySettings",
 *    description: "My personal settings.",
 *    settings: {
 *        ambientShadows: false,
 *        groundShadows: true
 *    }
 *    persistent: ['ambientShadows'],
 * };
 * const profile = new Autodesk.Viewing.Profile(profileSettings);
 * @constructor

 * @param {ProfileSettings} profileSettings - the profile settings.
 * @alias Autodesk.Viewing.Profile
 */
export function Profile(profileSettings) {
  if (!profileSettings) return;
  const parentProfileSettings = defaults;
  let prefsToOverride = [];

  // Use "Custom" as the profile name if a name is not passed in with the settings object
  this.name = profileSettings.name || 'Custom';
  this.label = profileSettings.label;
  this.description = profileSettings.description;

  // The format version of the data stored locally.
  this.storageVersion = '2.0';

  // Check which preferences we want to store.
  this.persistent = Array.isArray(profileSettings.persistent) ?
  profileSettings.persistent :
  parentProfileSettings.persistent;

  // Assign the default profile
  this.settings = Object.assign({}, parentProfileSettings.settings);

  if (profileSettings.settings) {
    const settings = profileSettings.settings;
    prefsToOverride = Object.keys(settings);
    // merge the passed in profile with the default profile
    this.settings = Object.assign(this.settings, settings);
  }

  /**
   * Applies the profile's settings to the viewer preferences.
   * To make the viewer react to the updated preferences please reference {@link Autodesk.Viewing.Viewer3D#setProfile}.
   * @param {Autodesk.Viewing.Private.Preferences} prefs - preferences instance.
   * @param {boolean} [override=true] - Override all existing preferences with the profile's preferences.
   * @alias Autodesk.Viewing.Profile#apply
   */
  this.apply = function (prefs) {let override = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    if (!prefs) {
      return false;
    }

    // Set the current storage format version.
    // If the format changes, knowing the format of saved data could be usefull
    // to convert the old data to the newer format.
    const currentSorageVerKey = prefs.getLocalStoragePrefix() + 'StorageVersion';
    LocalStorage.setItem(currentSorageVerKey, this.storageVersion);

    // Set the current profile in local storage.
    const currentProfileKey = prefs.getLocalStoragePrefix() + 'CurrentProfile';
    LocalStorage.setItem(currentProfileKey, this.name);

    prefs.setWebStorageKey(this.name);

    const settings = this.settings;
    const viewerDefined = [defaults.name, aec.name, fluent.name];
    const prefs3d = Object.values(Prefs3D);
    const prefs2d = Object.values(Prefs2D);
    for (let name in settings) {
      const value = settings[name];
      // Ignore metadata if the profile is a custom one (not the ProfileSettings.AEC or the DefaultProfile Settings.)
      const tags =
      prefsToOverride.indexOf(name) !== -1 && viewerDefined.indexOf(this.name) === -1 ?
      ['ignore-producer'] :
      [];
      if (prefs3d.indexOf(name) !== -1) {
        tags.push('3d');
      } else if (prefs2d.indexOf(name) !== -1) {
        tags.push('2d');
      } else {
        tags.push('2d');
        tags.push('3d');
      }

      // If the preference is not in the persistent array then add the no-storage tag.
      if (this.persistent.indexOf(name) === -1) {
        tags.push('no-storage');
      }

      const storedValue = prefs.webStorage(name);
      const cachedValue = prefs.get(name);

      // If a value was stored, honor it no matter what.
      if (storedValue !== undefined) {
        if (storedValue != cachedValue) {
          prefs.add(name, storedValue, tags, true);
          prefs.setDefault(name, value); // Configuring default value and tags for reset functionality.
        }
        continue;
      }

      if (cachedValue !== undefined) {
        // Add tags to the preference even if the value did not change
        prefs.addTags(name, tags);
        // LMV-5591: override the set preferences with the profile's preferences.
        // Fire an event if the preference value is being changed by the profile
        if (cachedValue !== value && override) {
          prefs.set(name, value);
        }
        continue;
      }

      // Add the preference and fire the event.
      prefs.add(name, value, tags, true);
    }

    return true;
  };
}




// The default profile will be used for everything else.
export let PROFILE_DEFAULT = new Profile(defaults);

// The AEC profile will be used for rvt and ifc file types.
export let PROFILE_AEC = new Profile(aec);

// The Navis profile will be used for nwc and nwd file types.
export let PROFILE_NAVIS = new Profile(navis);

// Design Collaboration profile. The extension doesn't matter here.
export let PROFILE_FLUENT = new Profile(fluent);