import i18n from "i18next";

import * as et from '../../../application/EventTypes';
import * as dte from "../../DtEventTypes";
import { Button } from "../../../gui/controls/Button";
import { SubMenu } from "../../../gui/controls/SubMenu";
import { TOOLBAR } from "../../../gui/GuiViewerToolbarConst";
import { LabelIcon } from "./icons";
import {
  BUTTON_CONFIG_BY_LAYER,
  HudLayer,
  HudLayerCtx,
  HUD_LAYER,
  LAYERS_CONTENT_CHANGED_EVENT,
  LAYERS_VISIBILITY_CHANGED_EVENT } from
'./HudLayer';

import { AssetsLayer } from './assets/AssetsLayer';
import { LevelsLayer } from './levels/LevelsLayer';
import { SpacesLayer } from './spaces/SpacesLayer';

const TOOLBAR_CONTROL_ID = 'toolbar-layers';

export class HudLayers {
  #facility;
  #layerCtx;
  #viewer;
  #prefsView;
  #prefsOverrides;
  #mounted;

  layers;
  layerById;
  controlsById;

  constructor(viewer, facility, hud, flags) {
    this.#viewer = viewer;
    this.#facility = facility;
    this.#layerCtx = new HudLayerCtx(this);
    this.#prefsView = {};
    this.#prefsOverrides = {}; // Keeps track of layers states during transition (ex: clustering)

    this.layers = [
    new LevelsLayer(facility, hud, viewer),
    new SpacesLayer(facility, hud, viewer)];

    if (flags?.dynamicLabels) {
      this.layers.push(new AssetsLayer(facility, hud, viewer));
    }
    this.layers.sort(HudLayer.byPriority);

    this.controlsById = {};
    this.layerById = {};
    for (const layer of this.layers) {
      this.layerById[layer.layerID] = layer;
      this.controlsById[layer.layerID] = this.#createLayerButton(layer);
    }

    this.toolbarButton = this.#createToolbarButton();

    this.boundOnIsolationChanged = this.#onIsolationChanged.bind(this);
    this.boundOnFacetsUpdated = this.#onFacetsUpdated.bind(this);
    this.boundOnHiddenChanged = this.#onHiddenChanged.bind(this);
    this.boundOnModelChanged = this.#onModelChanged.bind(this);
    this.boundOnLayerVisibilityChanged = this.#onLayerVisibilityChanged.bind(this);
    this.boundCameraChangedEvent = this.#onCameraChanged.bind(this);
    this.boundOnViewChanged = this.#onViewChanged.bind(this);

    // Temporary
    this.boundOnClusterTransition = this.#onClusterTransition.bind(this);
  }

  /** Stays listening but clear cached state and content */
  clear() {
    this.layers.forEach((l) => l.clear?.());
  }

  dispose() {
    this.#viewer.removeEventListener(et.AGGREGATE_ISOLATION_CHANGED_EVENT, this.boundOnIsolationChanged);
    this.#viewer.removeEventListener(et.AGGREGATE_HIDDEN_CHANGED_EVENT, this.boundOnHiddenChanged);
    this.#viewer.removeEventListener(et.CAMERA_CHANGE_EVENT, this.boundCameraChangedEvent);
    this.#viewer.removeEventListener(LAYERS_VISIBILITY_CHANGED_EVENT, this.boundOnLayerVisibilityChanged);
    this.#facility.eventTarget.removeEventListener(dte.DT_FACETS_UPDATED, this.boundOnFacetsUpdated);
    this.#facility.eventTarget.removeEventListener(dte.DT_MODEL_CHANGED_EVENT, this.boundOnModelChanged);
    this.#facility.eventTarget.views.removeEventListener(dte.DT_CURRENT_VIEW_CHANGED_EVENT, this.boundOnViewChanged);

    // TEMPORARY hide layers while clustering.
    this.#viewer.removeEventListener(et.TRANSITION_STARTED, this.boundOnClusterTransition);
    this.#viewer.removeEventListener(et.TRANSITION_ENDED, this.boundOnClusterTransition);

    this.layers.forEach((l) => l.dispose());

    this.#viewer.getToolbar()?.
    getControl(TOOLBAR.MODELTOOLSID).
    removeControl(this.toolbarButton);

    this.#prefsView = {};
    this.#prefsOverrides = {};
    this.#mounted = false;
  }

  init(viewer) {
    this.#viewer = viewer;
    this.#viewer.addEventListener(et.AGGREGATE_ISOLATION_CHANGED_EVENT, this.boundOnIsolationChanged);
    this.#viewer.addEventListener(et.AGGREGATE_HIDDEN_CHANGED_EVENT, this.boundOnHiddenChanged);
    this.#viewer.addEventListener(et.CAMERA_CHANGE_EVENT, this.boundCameraChangedEvent);
    this.#viewer.addEventListener(LAYERS_VISIBILITY_CHANGED_EVENT, this.boundOnLayerVisibilityChanged);
    this.#facility.eventTarget.addEventListener(dte.DT_FACETS_UPDATED, this.boundOnFacetsUpdated);
    this.#facility.eventTarget.addEventListener(dte.DT_MODEL_CHANGED_EVENT, this.boundOnModelChanged);
    this.#facility.eventTarget.views.addEventListener(dte.DT_CURRENT_VIEW_CHANGED_EVENT, this.boundOnViewChanged);

    // TEMPORARY hide layers while clustering.
    this.#viewer.addEventListener(et.TRANSITION_STARTED, this.boundOnClusterTransition);
    this.#viewer.addEventListener(et.TRANSITION_ENDED, this.boundOnClusterTransition);

    this.layers.forEach((l) => l.init());

    this.#mounted = true;
    this.#viewer.waitForToolbar().then(() => this.#mounted && this.#addToolbarButton());

    return this;
  }

  /** Force invalidate all layers */
  invalidate() {
    this.layers.forEach((l) => l.invalidate());
  }

  setLayerVisibility(ids, visible) {
    ids = Array.isArray(ids) ? ids : [ids];

    let change = {};
    for (const id of ids) {
      const layer = this.layerById[id];
      if (layer?.isVisible() !== visible) {
        layer.setVisible(visible);
        change[id] = visible;
      }
    }

    this.#viewer.dispatchEvent({
      type: LAYERS_VISIBILITY_CHANGED_EVENT,
      change
    });
  }

  update(ts) {
    let ctx = undefined;
    for (const layer of this.layers) {
      if (!layer.isDirty) continue;

      ctx = ctx ?? this.#layerCtx.reset(this.layers, this.#viewer);
      layer.isDirty = Boolean(layer.update(ts, ctx));
    }

    if (!ctx) {
      // No layer updated.
      return;
    }

    const changedLayers = ctx.changedLayers();
    if (changedLayers.length === 0) {
      return;
    }

    // TODO implement label overlap cleanup.
    //this.#priorityBasedCleanup();

    this.#viewer.dispatchEvent({
      type: LAYERS_CONTENT_CHANGED_EVENT, // Might be better if HUD was an event target.
      changed: changedLayers
    });
  }

  #addToolbarButton() {
    const tools = this.#viewer.getToolbar().
    getControl(TOOLBAR.MODELTOOLSID);

    if (!tools.getControl(TOOLBAR_CONTROL_ID)) {
      const wasAdded = tools.addControl(this.toolbarButton);
      console.assert(wasAdded, 'Unable to add labels toolbar control');
    }
  }

  #createToolbarButton() {
    const menu = new SubMenu(TOOLBAR_CONTROL_ID);
    menu.icon.innerHTML = LabelIcon;
    menu.setState(Button.State.ACTIVE);
    menu.setToolTip(i18n.t('Labels toggle'));

    for (const id in this.controlsById) {
      const control = this.controlsById[id];
      menu.addControl(control, { index: control.index });
    }

    return menu;
  }

  #createLayerButton(layer) {
    let config = BUTTON_CONFIG_BY_LAYER[layer.layerID];
    if (!config) return;

    const button = new Button(config.id);
    button.icon.innerHTML = config.icon;
    button.index = config.index;
    button.setIcon(config.iconId);
    button.setToolTip(config.tooltip());
    button.setState(layer.isVisible() ? Button.State.ACTIVE : Button.State.INACTIVE);
    button.onClick = () => {
      this.setLayerVisibility(layer.layerID, !layer.isVisible());
      this.#prefsOverrides[layer.layerID] ??= {};
      this.#prefsOverrides[layer.layerID].enabled = layer.isVisible();
    };

    return button;
  }

  #onClusterTransition(_ref) {let { type } = _ref;
    switch (type) {
      case et.TRANSITION_STARTED:
        this.setLayerVisibility(Object.keys(this.layerById), false);
        break;
      case et.TRANSITION_ENDED:
        this.layers.forEach((l) => {
          const vizPrefOverride = l.onClusterTransition?.(!!this.#facility.facetsManager.currentClusterFacet);
          this.setLayerVisibility(l.layerID, vizPrefOverride ?? this.getLayerPreference(l.layerID).enabled);
        });
        break;
    }
  }

  #onIsolationChanged(_ref2) {let { isolation } = _ref2;
    this.layers.forEach((l) => l.onIsolationChanged?.(isolation));
  }

  #onHiddenChanged(_ref3) {let { hidden } = _ref3;
    this.layers.forEach((l) => l.onHiddenChanged?.(hidden));
  }

  #onFacetsUpdated(event) {
    this.layers.forEach((l) => l.onFacetsUpdated?.(event));
  }

  #onModelChanged(event) {
    this.layers.forEach((l) => l.onModelChanged?.(event));
  }

  #onCameraChanged(_ref4) {let { camera } = _ref4;
    this.layers.forEach((l) => {
      if (l.isVisible()) l.onCameraChanged?.(camera);
    });
  }

  #onLayerVisibilityChanged(_ref5) {let { change } = _ref5;
    for (const id in change) {
      const visible = change[id];
      this.controlsById[id].setState(visible ? Button.State.ACTIVE : Button.State.INACTIVE);
    }
  }

  #onViewChanged(_ref6) {let { detail: { view } } = _ref6;
    this.#prefsView = view?.hud?.layers || {};
    this.#prefsOverrides = {};

    if (view?.facets.clusteredFacetId) {
      this.layers.forEach((l) => l.onClusterTransition?.(true));
      // Clustered view, delay layer visibility until TRANSITION_ENDED
      this.setLayerVisibility(Object.keys(this.layerById), false);
    } else {
      this.layers.forEach((l) => {
        l.onClusterTransition?.(false);
        this.setLayerVisibility(l.layerID, this.getLayerPreference(l.layerID).enabled);
      });
    }
  }

  getLayerPreference(layerID) {
    return this.#prefsOverrides[layerID] || this.#prefsView[layerID] || HUD_LAYER[layerID].defaultPrefs;
  }

  getStateForView() {
    const state = this.layers.reduce((res, l) => {
      res[l.layerID] = { enabled: l.isVisible() };
      return res;
    }, {});

    // Temporary until more settled.
    delete state[HUD_LAYER.ASSETS.id];

    return state;
  }
}