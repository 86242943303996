
import THREE from "three";

import { Math2D } from './Math2D.js';
import { Svg } from './Svg.js';
import { computeLoopContainment } from './LoopContainment.js';

let nextShapeId = 1;

const av = Autodesk.Viewing;

const toColor = (r, g, b) => {
  return "rgb(" + r + "," + g + "," + b + ")";
};

const cloneVectorArray = (src) => {
  return src.map((p) => {return { x: p.x, y: p.y };});
};

// Default arc tessellation params that we use for area computations. (see Bezier.js)
// We use smaller min segment length than for drawing, because the DefaultTessParams would cause too inaccurate measurements.
// TODO: Replace by more accurate and faster analytic computation to replace brute-force tesselation completely.
const AreaTessParam = {
  numIterations: 100,
  minSegLenFraction: 0.01
};

const tmpVec3 = new THREE.Vector3();
const tmpVec3_2 = new THREE.Vector3();
const tmpBox2 = new THREE.Box2();
const tmpVec2 = new THREE.Vector2();

export class Style {

  /**
   * Creates a new Style for the Edit 2D tools.
   * @param {object} [params]           - various style values to overwrite the default style.
   * @param {string} [params.color]     - sets the color for the line and fill area
   * @param {number} [params.alpha]     - sets the alpha value for the line and fill area
   * @param {string} [params.lineColor] - sets the color for the line
   * @param {number} [params.lineAlpha] - sets the alpha value for the line
   * @param {number} [params.lineWidth] - sets the line width for the line.
   * @param {number} [params.lineStyle] - sets the style of the line
   * @param {string} [params.fillColor] - sets the color for the fill area
   * @param {number} [params.fillAlpha] - sets the alpha value for the fill area
   */
  constructor() {let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    this.lineColor = params.lineColor || params.color || "rgb(0,0,128)";
    this.lineAlpha = params.lineAlpha !== undefined ? params.lineAlpha : params.alpha !== undefined ? params.alpha : 1.0;
    this.lineWidth = params.lineWidth !== undefined ? params.lineWidth : 3.0;

    this.fillColor = params.fillColor || params.color || "rgb(0,0,128)";
    this.fillAlpha = params.fillAlpha !== undefined ? params.fillAlpha : params.alpha !== undefined ? params.alpha : 0.2;

    // lineStyle is an index into a list of dash/dot patterns defined in See LineStyleDef.js.
    // Examples:
    //   0:  Solid line:    ______________
    //   10: Dashes long:   __ __ __ __ __
    //   11: Dashes short:  _ _ _ _ _ _ _
    //   12: Dashes longer: ___ ___ ___ ___
    //   16: Dots:          . . . . . . .
    //   17: Dots dense:    ..............
    //   18: Dots sparse:   .  .  .  .  .
    this.lineStyle = params.lineStyle || 0;

    // By default, we interpret line widths in screen-space
    this.isScreenSpace = params.isScreenSpace !== undefined ? params.isScreenSpace : true;
    this.compositeOperation = 'source-over';
  }

  // Components r,b,g are in [0,255]
  setFillColor(r, g, b) {
    this.fillColor = toColor(r, g, b);
  }

  setLineColor(r, g, b) {
    this.lineColor = toColor(r, g, b);
  }

  clone() {
    return new Style().copy(this);
  }

  copy(from) {
    this.lineColor = from.lineColor;
    this.lineAlpha = from.lineAlpha;
    this.lineWidth = from.lineWidth;
    this.fillColor = from.fillColor;
    this.fillAlpha = from.fillAlpha;
    this.lineStyle = from.lineStyle;
    this.isScreenSpace = from.isScreenSpace;
    this.compositeOperation = from.compositeOperation;
    return this;
  }
}

Style.toColor = toColor;

const DefaultStyle = new Style();

// Add all points to given bbox.
const addPointsToBBox = (points, dstBox) => {
  for (let i = 0; i < points.length; i++) {
    dstBox.expandByPoint(points[i]);
  }
};

export class Shape extends av.EventDispatcher {
  constructor() {let style = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DefaultStyle.clone();
    super();

    this.style = style;

    // assign unique id
    this.id = nextShapeId++;

    this.bbox = new THREE.Box2();
    this.bboxDirty = true;

    // If false, it is skipped by EditLayer traversals
    this.visible = true;

    // whether users can move this shape by clicking and dragging.
    this.movable = true;

    // whether users can select this shape. If false, clicks on this shape will not select it, and by consequence it
    // won't be editable either.
    this.selectable = true;

    // Should be set by creator by something more descriptive.
    this.name = this.id.toString();

    this.cdata = null;
  }

  // Must be provided by derivaties
  draw( /*ctx, overrideStyle*/) {}
  hitTest( /*x, y, hitRadius*/) {} // hitRadius is a distance in layer-coords used for line feature hit-tests.

  move( /*dx, dy*/) {return this;}

  // Apply a transform to each point. (assuming z=0)
  // @param {Matrix4}
  applyMatrix4(matrix) {return this;}

  clone() {
    return new Shape().copy(this);
  }

  copy(from) {
    this.style = from.style.clone();
    return this;
  }

  computeBBox() {
    console.error("Must be implemented by derived class.");
  }

  modified() {
    this.bboxDirty = true;
    this.fireEvent({ type: Shape.Events.MODIFIED });
  }

  updateBBox() {
    if (this.bboxDirty) {
      this.computeBBox();
      this.bboxDirty = false;
    }
  }

  // Return bbox while making sure that it's up-to-date.
  getBBox() {
    this.updateBBox();
    return this.bbox;
  }

  // @param {string}  svg - e.g. '<path d="M 13,4 L 14,4"/>'
  static fromSVG(svg) {
    return Svg.fromSvg(svg);
  }

  // Convert to SVG style string, e.g., '<path d="M 13,4 L 14,4"/>'
  // See Svg.toSvg() comment for options.
  //
  // Note: The digits param is deprecated and only exists for legacy reasons.
  //       Set digits via options.digits instead.
  toSVG(options, digits) {
    return Svg.toSvg(this, options, digits);
  }

  // Converts shape into a DOM element (usually a <path>).
  //  @param {Object}
  //  @param {bool}   [options.exportStyle=true]
  createSvgShape(options) {
    return Svg.toSvgElement(this, options);
  }

  setVisible(visible) {
    this.visible = visible;
  }

  //  @param {Object} data - Any custom data needed by the clients / higher level logic
  set customData(data) {
    this.cdata = data;
  }

  get customData() {
    return this.cdata;
  }
}

Shape.Events = {
  MODIFIED: 'modified'
};

av.GlobalManagerMixin.call(Shape.prototype);

export const LoopType = {
  Empty: 0, // Loop is empty or does not exist
  Inner: 1,
  Outer: 2,
  Overlapping: 3 // Loop is intersecting itself or other loops
};

// Common base class for Polygons and Polylines
export class PolyBase extends Shape {

  constructor() {let points = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;let style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DefaultStyle.clone();
    super(style);

    // Array of Array of points, each represented as an object {x, y}
    // By default, we start with a single loop/chain
    this._loops = points ? [points] : [];

    // Set by derived classes
    this.isClosed = undefined;

    // Computed on-demand: Provides extra information about how loops are nested.
    this._loopInfos = null;

    // Height of the drawing plane for sketching in 3D
    this._z = 0;
  }

  get z() {
    return this._z;
  }

  set z(v) {
    this._z = v;
  }

  // For backward compatibility
  get points() {
    // Create empty loop 0 if needed
    return this._loops[0] || (this._loops[0] = []);
  }

  get loopCount() {
    return this._loops.length;
  }

  // acquire a number of additional points in the given loop. Each has initial coords (0,0)
  allocPoints(numPoints) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    for (let i = 0; i < numPoints; ++i) {
      this.addPoint(0, 0, loopIndex);
    }
    return this;
  }

  isPolygon() {return this.isClosed;}
  isPolyline() {return !this.isClosed;}

  isPath() {
    return this instanceof Path;
  }

  addPoint(x, y) {let loopIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    // get or create loop
    const loop = this._loops[loopIndex] || (this._loops[loopIndex] = []);

    // add point to loop
    const point = { x, y };
    loop.push(point);
    this.modified();
    return point;
  }

  getPoint(index) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;let target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new THREE.Vector2();

    // Legacy fallback (deprecated): This can be removed as soon as no code
    // is passing a target vector without a loop index
    if (typeof loopIndex === 'object') {
      target = loopIndex;
      loopIndex = 0;
    }

    return target.copy(this._loops[loopIndex][index]);
  }

  removePoint(index) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    this._loops[loopIndex].splice(index, 1);
  }

  updatePoint(index, x, y) {let loopIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    let p = this._loops[loopIndex][index];
    p.x = x;
    p.y = y;
    this.modified();
  }

  insertPoint(index, p) {let loopIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    this._loops[loopIndex].splice(index, 0, p);
  }

  get length() {
    console.warn('poly.length is deprecated and will be removed. Please use poly.vertexCount property instead.');
    return this.points.length;
  }

  // for backwards compatibility
  get vertexCount() {
    return this.points.length;
  }

  // Returns 0 if a loop is empty or does not exist.
  getVertexCount() {let loopIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    // Array may not exist yet if no vertices were added to the loop yet.
    const loop = this._loops[loopIndex];
    return loop ? loop.length : 0;
  }

  // Reset back to a single empty loop
  clear() {
    this._loops = [];
    this.modified();
  }

  // Enumerate all edges (a,b).
  //  @param {function(a, b, ai, bi)} cb - For each edge, we trigger cb(a, b, ai, bi), where (a,b) are the points and (ai, bi) the indices of the edge.
  //                                       If cb() returns true, the traversal stops.
  enumEdges(cb) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    // get edge count
    const edgeCount = this.getEdgeCount(loopIndex);

    // check for each edge whether p is close to it.
    for (let i = 0; i < edgeCount; i++) {
      // get indices
      const ai = i;
      const bi = this.nextIndex(i, loopIndex);

      // get points
      const a = this.getPoint(ai, loopIndex);
      const b = this.getPoint(bi, loopIndex);

      // pass all to cb
      const stop = cb(a, b, ai, bi);

      // allow early out
      if (stop) {
        return;
      }
    }
  }

  // Given a polyline or polygon, it checks if the position is close to any edge of the shape.
  // If so, it returns the index of that edge, otherwise -1.
  // All values are in layer coords.
  findEdgeIndex(p, precision) {let loopIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

    let edgeIndex = -1;

    // Callback to find edge containing p
    const findEdgeCb = (a, b, ai) => {

      // If edge contains p, store its edge index
      const containsP = Math2D.isPointOnEdge(p, a, b, precision);
      if (containsP) {
        edgeIndex = ai;
      }

      // Stop on success
      return containsP;
    };
    this.enumEdges(findEdgeCb, loopIndex);
    return edgeIndex;
  }

  moveLoop(dx, dy, loopIndex) {
    const points = this._loops[loopIndex];
    for (let i = 0; i < points.length; i++) {
      points[i].x += dx;
      points[i].y += dy;
    }
    this.modified();
  }

  move(dx, dy) {
    for (let l = 0; l < this.loopCount; l++) {
      this.moveLoop(dx, dy, l);
    }
    return this;
  }

  // Note: Ellipse arcs only support simple transforms (translation, rotation, uniform scaling)
  // @param {THREE.Matrix4}
  applyMatrix4(matrix) {

    for (let l = 0; l < this.loopCount; l++) {
      const points = this._loops[l];

      for (let i = 0; i < points.length; i++) {
        const p = points[i];

        // set target to (x,y) * matrix
        const transformPoint = (x, y, target) => {
          // convert to vec3, transform, and write back to target
          const vec3 = tmpVec3.set(x, y, 0).applyMatrix4(matrix);
          target.x = vec3.x;
          target.y = vec3.y;
          return target;
        };

        transformPoint(p.x, p.y, p);

        // transform Bezier control points
        if (this.isBezierArc(i, l)) {
          let cp = transformPoint(p.cp1x, p.cp1y, tmpVec3);
          p.cp1x = cp.x;
          p.cp1y = cp.y;

          cp = transformPoint(p.cp2x, p.cp2y, tmpVec3);
          p.cp2x = cp.x;
          p.cp2y = cp.y;
        }

        // Transform ellipse arcs
        // Note: Currently, this only works for simple transforms (translate, rotate, uniform scale)
        if (this.isEllipseArc(i, l)) {
          p.ellipseArcParams.applyMatrix4(matrix);
        }
      }
    }
    this.modified();
    return this;
  }

  // Copy a single loop from src poly and adds it to this one
  //  @param {PolyBase} srcPoly
  //  @param {number}   srcLoopIndex - must be a valid loopIndex of src
  //  @param {number} [dstLoopIndex] Optional: index where to insert the new loop. By default, we use the first free loopIndex.
  addLoop(srcPoly) {let srcLoopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;let dstLoopIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;
    // copy loop points
    const srcLoop = srcPoly._loops[srcLoopIndex];
    const newLoop = cloneVectorArray(srcLoop);

    // insert new loop
    let newIndex;
    if (dstLoopIndex === -1) {
      // find a free loop index to store the new loop
      newIndex = this.nextFreeLoop();
      this._loops[newIndex] = newLoop;
    } else {
      // insert new loop at given index
      newIndex = dstLoopIndex;
      this._loops.splice(newIndex, 0, newLoop);
    }
    this.modified();

    return newIndex;
  }

  copyGeometry(srcPoly) {
    this.isClosed = srcPoly.isClosed;

    // copy loops
    this._loops = [];
    for (let i = 0; i < srcPoly.loopCount; i++) {
      this.addLoop(srcPoly, i);
    }
    return this;
  }

  copy(srcPoly) {
    super.copy(srcPoly);
    return this.copyGeometry(srcPoly);
  }

  computeBBox() {
    this.bbox.makeEmpty();
    for (let i = 0; i < this.loopCount; i++) {
      const loop = this._loops[i];
      if (loop) {
        addPointsToBBox(loop, this.bbox);
      }
    }
    return this.bbox;
  }

  indexValid(index) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return index >= 0 && index < this.getVertexCount(loopIndex);
  }

  // Returns -1 if there is no next Index
  nextIndex(index) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    // Return -1 for invalid input
    if (!this.indexValid(index, loopIndex)) {
      return -1;
    }

    // Handle last vertex
    const isLast = index === this.getVertexCount(loopIndex) - 1;
    if (isLast) {
      // If closed, restart. Otherwise, there is no next index.
      return this.isClosed ? 0 : -1;
    }

    return index + 1;
  }

  // Returns -1 if there is no previous vertex index
  prevIndex(index) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    // Return -1 for invalid input
    if (!this.indexValid(index, loopIndex)) {
      return -1;
    }

    // Handle first vertex
    if (index === 0) {
      // if closed, continue at end. Otherwise, there is no previous index.
      const vertexCount = this.getVertexCount(loopIndex);
      return this.isClosed ? vertexCount - 1 : -1;
    }

    return index - 1;
  }

  // Returns index of the edge ending at the given vertex or -1 if it does not exist.
  edgeBeforeVertex(index) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return this.prevIndex(index, loopIndex);
  }

  // Returns index of the edge starting at the given vertex.
  // Returns -1 if index is the end vertex of a polyline.
  edgeAfterVertex(index) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return this.edgeIndexValid(index, loopIndex) ? index : -1;
  }

  // Returns -1 if there is no previous edge.
  nextEdgeIndex(edgeIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    // Check edgeIndex validity
    if (!this.edgeIndexValid(edgeIndex, loopIndex)) {
      return -1;
    }

    // Return -1 for last polyline edge
    if (!this.isClosed && edgeIndex === this.getEdgeCount(loopIndex) - 1) {
      return -1;
    }

    return this.nextIndex(edgeIndex, loopIndex);
  }

  prevEdgeIndex(edgeIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    // Check edgeindex validity
    if (!this.edgeIndexValid(edgeIndex, loopIndex)) {
      return -1;
    }

    // Return -1 for first polyline edge
    if (!this.isClosed && edgeIndex === 0) {
      return -1;
    }

    return this.prevIndex(edgeIndex, loopIndex);
  }

  edgeIndexValid(edgeIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    const edgeCount = this.getEdgeCount(loopIndex);
    return edgeIndex >= 0 && edgeIndex < edgeCount;
  }

  prevEdgeExists(edgeIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    const vertexCount = this.getVertexCount(loopIndex);
    return this.edgeIndexValid(edgeIndex, loopIndex) && vertexCount > 2 && (edgeIndex > 0 || this.isClosed);
  }

  nextEdgeExists(edgeIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    const vertexCount = this.getVertexCount(loopIndex);
    const isLastEdge = edgeIndex === vertexCount - 2;
    return this.edgeIndexValid(edgeIndex, loopIndex) && vertexCount > 2 && (!isLastEdge || this.isClosed);
  }

  // Copy start/end of an edge into outA, outB out params (Vector2).
  // edgeIndex must be valid.
  getEdge(edgeIndex, outA, outB) {let loopIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    const ia = edgeIndex;
    const ib = this.nextIndex(edgeIndex, loopIndex);
    this.getPoint(ia, loopIndex, outA);
    this.getPoint(ib, loopIndex, outB);
  }

  getEdgeDirection(edgeIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;let target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new THREE.Vector2();
    const ia = edgeIndex;
    const ib = this.nextIndex(edgeIndex, loopIndex);
    const loop = this._loops[loopIndex];
    return Math2D.getEdgeDirection(loop[ia], loop[ib], target);
  }

  getEdgeLength(edgeIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    const ia = edgeIndex;
    const ib = this.nextIndex(edgeIndex, loopIndex);
    const loop = this._loops[loopIndex];
    const a = loop[ia];
    const b = loop[ib];
    return Math2D.getEdgeLength(a, b);
  }

  getEdgeCount() {let loopIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    const vertexCount = this.getVertexCount(loopIndex);
    return this.isClosed ? vertexCount : vertexCount - 1;
  }

  // Return the summed edge length for Polygons and Polylines.
  //
  //  @param {MeasureTransform} [measureTransform] - Optional: To allow doing calculation in another coordinate space
  getLength(measureTransform) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    const a = new THREE.Vector2();
    const b = new THREE.Vector2();
    let sum = 0.0;
    for (let i = 0; i < this.getEdgeCount(loopIndex); i++) {
      this.getEdge(i, a, b, loopIndex);

      // apply optional measure transform
      if (measureTransform) {
        measureTransform.apply(a);
        measureTransform.apply(b);
      }

      sum += a.distanceTo(b);
    }
    return sum;
  }

  // Set vertices from THREE.Box2
  fromBox2(box) {
    this.addPoint(box.min.x, box.min.y);
    this.addPoint(box.max.x, box.min.y);
    this.addPoint(box.max.x, box.max.y);
    this.addPoint(box.min.x, box.max.y);
    return this;
  }

  // Returns a point along an edge. Note that the edge may be an arc for Paths.
  //  @param {number} edgeIndex   - A valid edgeIndex
  //  @param {number} t           - in [0,1]. t=0: startPoint, t=1: endPoint
  //  @param {number} [loopIndex]
  //  @param {Vector2} [target]
  getPointOnEdge(edgeIndex, t) {let loopIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;let target = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : new THREE.Vector2();

    const loop = this._loops[loopIndex];
    const p0 = loop[edgeIndex];
    const p1 = loop[this.nextIndex(edgeIndex, loopIndex)];
    return target.lerpVectors(p0, p1, t);
  }

  // Checks if outer loop is counterclockwise. For polylines that doesn't form a loop,
  // we assume an additional edge from end to start.
  // @returns {bool}
  isCCW() {let loopIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    return Autodesk.Extensions.CompGeom.polygonArea(this._loops[loopIndex]) > 0;
  }

  // Return 2D edge normal
  getLeftEdgeNormal(edgeIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;let target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new THREE.Vector2();

    const points = this._loops[loopIndex];

    // get start/end point of the edge
    const vi1 = edgeIndex;
    const vi2 = (edgeIndex + 1) % points.length;
    const v1 = points[vi1];
    const v2 = points[vi2];

    // get edge direction
    target.subVectors(v2, v1).normalize();

    // rotate by 90 degrees
    const tmp = target.x;
    target.x = -target.y;
    target.y = tmp;

    return target;
  }

  // Get edge normal facing outside wrt. to the loop containing the edge. If the contour is not closed, we
  // assume an additional connection between endpoint and startpoint to defined "outside".
  getOuterNormal(edgeIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;let target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new THREE.Vector2();
    const normal = this.getLeftEdgeNormal(edgeIndex, loopIndex, target);
    return this.isCCW(loopIndex) ? normal.multiplyScalar(-1) : normal;
  }

  // Returns the first loopIndex >=0 that doesn't contain any points yet.
  //  @param {number}
  nextFreeLoop() {
    const isFree = (l) => !l || !l.length;
    const index = this._loops.findIndex(isFree);
    return index >= 0 ? index : this.loopCount;
  }

  // Seaches all loops to find a vertex for which cb(vertexIndex, loopIndex) returns true.
  //  @{function(vertexIndex, loopIndex)=>bool} searchFilter
  //  @returns {Object|null}                    A {vertexIndex, loopIndex} pair on success. Otherwise null.
  findVertex(searchFilter) {
    for (let l = 0; l < this.loopCount; l++) {
      const len = this.getVertexCount(l);
      for (let i = 0; i < len; i++) {
        if (searchFilter(i, l)) {
          return {
            vertexIndex: i,
            loopIndex: l
          };
        }
      }
    }
    return null;
  }

  // Returns true if poly does not contain any (non-empty loops)
  empty() {
    return !this._loops.some((loop) => loop && loop.length > 0);
  }

  modified() {
    super.modified();

    // Loop containment may have changed
    this._loopInfos = null;
  }

  // Returns true if the shape has overlapping loops
  isSelfIntersecting() {

    // Todo: Currently, we only detect overlaps between different loops. We also
    //       have to track self-intersections within a single loop.


    // Check if we have multiple overlapping loops
    const loopInfos = this._getLoopInfos();
    return loopInfos && loopInfos.some((l) => l.error);
  }

  _getLoopInfos() {
    // Loop infos are only needed for closed paths with 2 or more loops
    if (!this.isClosed || this.loopCount < 1) {
      return undefined;
    }

    // Reuse if already available
    if (!this._loopInfos) {
      this._loopInfos = computeLoopContainment(this);
    }
    return this._loopInfos;
  }

  // Only works for closed loops.
  getLoopType(loopIndex) {

    if (!this.isClosed) {
      return undefined;
    }

    if (!this.getVertexCount(loopIndex)) {
      return LoopType.Empty;
    }

    // LoopInfo should always exist for closed non-empty loops
    const infos = this._getLoopInfos();
    const info = infos[loopIndex];

    if (info.error) {
      return LoopType.Overlapping;
    }

    // Even-odd-rule: Loops with even rank are outer ones.
    return info.rank & 1 ? LoopType.Inner : LoopType.Outer;
  }

  // Get all loops (directly or indirectly) enclosed by the given one
  getChildLoops(loopIndex) {
    const infos = this._getLoopInfos();
    const info = infos && infos[loopIndex];
    return info ? info.containedLoops.slice() : [];
  }

  // Eliminiate all empty loops, so that loopCount matches the number of non-empty loops
  cleanupLoops() {
    this._loops = this._loops.filter((l) => l && l.length >= 0);
  }

  // Returns all loops that are not enclosed by any other one. Only for closed shapes.
  getMainLoops() {
    const infos = this._getLoopInfos();
    if (!infos) {
      return [];
    }

    // Collect all rank-0 loops
    const loops = [];
    for (let i = 0; i < infos.length; i++) {
      const info = infos[i];

      // Skip empty or invalid loops
      const type = this.getLoopType(i);
      if (type !== LoopType.Outer) {
        continue;
      }

      if (info.rank === 0) {
        loops.push(i);
      }
    }
    return loops;
  }

  // Remove loop. Remaining loop indices are shifted back by one
  removeLoop(loopIndex) {
    this._loops.splice(loopIndex, 1);
    this.modified();
    return this;
  }

  // Remove multiple loop indices
  // @param {number[]} 
  removeLoops(loops) {
    this._loops = this._loops.filter((l, i) => !loops.includes(i));
  }

  // Returns true if a point contains valid (i.e. finite) numbers.
  isPointFinite(vertex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    if (!this.indexValid(vertex, loopIndex)) {
      return false;
    }

    const points = this._loops[loopIndex];
    let p = points[vertex];
    return isFinite(p.x) && isFinite(p.y);
  }

  isLoopFinite(loopIndex) {
    const points = this._loops[loopIndex];
    const count = points ? points.length : 0;
    for (let i = 0; i < count; i++) {
      if (!this.isPointFinite(i, loopIndex)) {
        return false;
      }
    }
    return true;
  }
}

// Helper class to address a single vertex within a loop of a PolyBase.
// Can also be used to address edges (by indexing its start vertex).
export class PolyIndex {
  constructor(_ref) {let { vertex = 0, loop = 0 } = _ref;
    this.vertex = vertex;
    this.loop = loop;
  }
  equals(v) {
    return v && this.vertex === v.vertex && this.loop === v.loop;
  }
}

export class Polygon extends PolyBase {

  constructor() {let points = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];let style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DefaultStyle.clone();
    super(points, style);
    this.isClosed = true;
  }

  // Draw Polygon into LmvCanvasContext
  draw(ctx, overrideStyle) {
    drawPath(ctx, this, overrideStyle);
  }

  // We use even-odd rule if a polygon has multiple loops: A point is considered inside if it
  // is enclosed by an odd number of loops.
  hitTest(x, y) {
    if (!this.vertexCount) {
      return false;
    }

    // Compute number of loops that enclose (x,y)
    let rank = 0;
    for (let l = 0; l < this.loopCount; l++) {
      const loop = this._loops[l];
      if (!loop) {
        continue;
      }

      // set current loop as points
      const cp = new Autodesk.Extensions.CompGeom.ComplexPolygon(loop);

      // create dummy contour
      // TODO: Consider generalizing pointInCountour() to make it usable for non-indexed polygons
      var contour = [];
      for (let i = 0; i < loop.length; i++) {
        contour.push(i);
      }

      if (cp.pointInContour(x, y, contour)) {
        rank++;
      }
    }

    // Apply even-odd-rule
    return Boolean(rank & 1);
  }

  clone() {
    return new Polygon().copy(this);
  }

  //  @param {MeasureTransform} [measureTransform] - Optional: To allow doing calculation in another coordinate space
  getArea(measureTransform) {

    if (!this.isClosed) {
      return undefined;
    }

    // If there are multiple loops, we need loopInfos to distinguish inner and outer loops
    const loopInfos = this._getLoopInfos();

    let sumArea = 0;
    for (let loopIndex = 0; loopIndex < this.loopCount; loopIndex++) {

      // Skip degenerate loops
      if (this.points.length < 3) {
        continue;
      }

      // determine loop rank (number of other loops containing it)
      // Note that loopInfos are null for single loops where we don't need them.
      const loopInfo = loopInfos ? loopInfos[loopIndex] : null;
      const rank = loopInfo ? loopInfo.rank : 0;

      // Even-odd rule: Loops with odd rank are holes and contribute negatively
      const sign = rank & 1 ? -1 : 1;

      let loopArea = 0.0;
      this.enumEdges((a, b) => {
        // apply optional transform
        measureTransform && measureTransform.apply(a);
        measureTransform && measureTransform.apply(b);

        // sum up signed areas
        loopArea += a.x * b.y - b.x * a.y;
      }, loopIndex);
      sumArea += sign * Math.abs(0.5 * loopArea);
    }
    return sumArea;
  }
}

export class Polyline extends PolyBase {

  constructor() {let points = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];let style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DefaultStyle.clone();
    super(points, style);
    this.isClosed = false;
  }

  makeLine() {let x0 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;let y0 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;let x1 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;let y1 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    if (this.vertexCount !== 2) {
      this.clear();
      this.addPoint(x0, y0);
      this.addPoint(x1, y1);
    } else {
      this.updatePoint(0, x0, y0);
      this.updatePoint(1, x1, y1);
    }
    return this;
  }

  // Draw Polyline into LmvCanvasContext
  draw(ctx, overrideStyle) {
    drawPath(ctx, this, overrideStyle);
  }

  clone() {
    return new Polyline().copy(this);
  }

  // hitRadius is in layer-coords
  hitTest(x, y, hitRadius) {
    const edgeIndex = this.findEdgeIndex({ x, y }, hitRadius);
    return edgeIndex !== -1;
  }
}

export const EdgeType = {
  Line: 0, // Simple line segment
  Bezier: 1, // Cubic Bezier Arc
  Ellipse: 2 // Ellipse Arc
};


// Tmp objct for Ellipse Arcs. We need delayed initialization,
// because Autodesk.Extensions.CompGeom might not be available yet at compile time.
let _tmpArc = null;
let getTmpArc = () => {
  _tmpArc = _tmpArc || new Autodesk.Extensions.CompGeom.EllipseArc();
  return _tmpArc;
};

let tmpVec = new THREE.Vector2();

// Helper function to run moveTo/lineTo/arcTo/closePath calls for a single loop of a path on a given context object.
//  @param {Path2d|LmvCanvasContext|Object} ctx       - Receives the callback calls, i.e. moveTo, lineTo, bezierCurveTo, closePath etc. (see Path2D)
//  @param {Polyline|Polygon|Path}          path
//  @param {number}                         loopIndex - must be a valid loop index in path
const runLoop = (ctx, path, loopIndex) => {

  const points = path._loops[loopIndex];
  if (!points || !points.length) {
    return;
  }

  // Trying to fill paths with NaN or infinite numbers may cause hangs in clipper. So, we prevent those here.
  if (!path.isLoopFinite(loopIndex)) {
    console.warn(`Skipped loop, because it contains Inf or NaN values. Shape ID: ${path.id}. LoopIndex: ${loopIndex}`);
    return;
  }

  ctx.moveTo(points[0].x, points[0].y);

  const processSegment = (pStart, pEnd, edgeIndex) => {
    switch (pStart.arcType) {
      case EdgeType.Line:break;

      case EdgeType.Bezier:{
          ctx.bezierCurveTo(pStart.cp1x, pStart.cp1y, pStart.cp2x, pStart.cp2y, pEnd.x, pEnd.y);
          return;
        }

      case EdgeType.Ellipse:{
          const params = pStart.ellipseArcParams;
          const arc = path.exportEllipseArc(edgeIndex, loopIndex, getTmpArc());

          // ignore arcs with NaN values
          if (!arc.isValid()) {
            break;
          }

          if (ctx.ellipseArcTo) {
            // Support SolidDef Path2D
            ctx.ellipseArcTo(params.rx, params.ry, THREE.Math.degToRad(params.rotation), params.largeArcFlag, params.sweepFlag, pEnd.x, pEnd.y);
          } else {
            // For Autodesk.CompGeom (Path2D and LmvCanvasContext). Also compatible to CanvasContext and Path2D in HTML5.
            ctx.ellipse(arc.cx, arc.cy, arc.rx, arc.ry, arc.rotation, arc.startAngle, arc.endAngle, arc.ccw);
          }

          return;
        }
    }
    ctx.lineTo(pEnd.x, pEnd.y);
  };

  for (let i = 1; i < points.length; i += 1) {
    // The segment start point defines the type (line or arc)
    const prev = points[i - 1];
    const p = points[i];

    processSegment(prev, p, i - 1);
  }

  if (path.isClosed) {
    // add closing segment
    const pLast = points[points.length - 1];
    const pFirst = points[0];
    processSegment(pLast, pFirst, points.length - 1);

    ctx.closePath();
  }
};

// Helper function to run moveTo/lineTo/arcTo/closePath calls on a given context object.
//  @param {Path2d|LmvCanvasContext|Object} ctx    - Receives the callback calls, i.e. moveTo, lineTo, bezierCurveTo, closePath etc. (see Path2D)
//  @param {Polyline|Polygon|Path}          path
export const runPath = (ctx, path) => {
  for (let i = 0; i < path.loopCount; i++) {
    runLoop(ctx, path, i);
  }
};

// Draw Path to CanvasContext. Unified implementation for Path, Polyline, and Polygon
//  @param {LmvCanvasContext}      ctx
//  @param {Polyline|Polygon|Path} path
//  @param {Style}                 [overrideStyle]
const drawPath = (ctx, path, overrideStyle) => {

  if (!path.vertexCount) {
    return;
  }

  let style = overrideStyle || path.style;
  const c = ctx.canvasContext;
  ctx.dbId = path.id;
  ctx.lineStyle = style.lineStyle;
  ctx.isScreenSpace = style.isScreenSpace;

  const currentGlobalCompositeOp = c.globalCompositeOperation;
  if (style.compositeOperation) {
    c.globalCompositeOperation = style.compositeOperation;
    // Make sure any previous shapes with a different blending are flushed first
    ctx.flushBuffer(0, true);
  }

  ctx.beginPath();

  // Run moveTo/lineTo/... commands on context
  runPath(ctx, path);

  // Draw fill for closed paths
  if (path.isClosed) {
    c.fillStyle = style.fillColor;
    // Creates a gradient fill style.
    if (style.fillColor.hasOwnProperty('colorStops')) {
      const gradientData = ctx.createGradientData(style.fillColor);
      const fillStyle = gradientData.getFillStyle(c);
      c.fillStyle = fillStyle;
    }
    c.globalAlpha = style.fillAlpha;

    ctx.fill();
  }

  // draw lines
  c.strokeStyle = style.lineColor;
  c.globalAlpha = style.lineAlpha;
  c.lineWidth = style.lineWidth;

  // Adjust lineWidth so that specified 1px widths will be drawn as 3px on screens with devicePixelRatio == 3.
  // For human eyes the line width is then the same width.
  if (style.isScreenSpace) c.lineWidth *= window.devicePixelRatio;

  ctx.stroke();

  // restore default values
  ctx.dbId = -1;
  ctx.lineStyle = 0;
  ctx.isScreenSpace = false;
  c.globalCompositeOperation = currentGlobalCompositeOp;
};

// Extra params for cubic Bezier arc edges.
class BezierArcParams {
  constructor() {
    // control point 1 that defines start tangent
    this.cp1x = 0;
    this.cp1y = 0;

    // control point 2 that defines end tangent
    this.cp2x = 0;
    this.cp2y = 0;
  }

  copy(src) {
    this.cp1x = src.cp1x;
    this.cp1y = src.cp1y;
    this.cp2x = src.cp2x;
    this.cp2y = src.cp2y;
    return this;
  }

  clone() {
    return new BezierArcParams().copy(this);
  }
}

// SVG compatible ellipse arc params
// see https://www.w3.org/TR/svg-paths/#PathDataEllipticalArcCommands
export class EllipseArcParams {

  constructor() {
    // {number} Radius along x-axis
    this.rx = 0;

    // {number} Radius along y-axis
    this.ry = 0;

    // {number} ccw rotation of x/y-axes in degrees
    this.rotation = 0;

    // {bool} whether to use shorter or longer path around ellipse.
    this.largeArcFlag = false;

    // {bool} Whether to go ccw (true) or cw (false) from startAngle. See SVG docs link above for details.
    this.sweepFlag = false;
  }

  copy(src) {
    this.rx = src.rx;
    this.ry = src.ry;
    this.rotation = src.rotation;
    this.largeArcFlag = src.largeArcFlag;
    this.sweepFlag = src.sweepFlag;
    return this;
  }
  clone() {
    return new EllipseArcParams().copy(this);
  }

  // @param {number} angle - counterclockwise in degrees
  rotate(angle) {

    this.rotation += angle;

    // Normalize angle to keep within [0,360]
    this.rotation -= Math.trunc(this.rotation / 360) * 360;
  }

  scale(factor) {
    this.rx *= factor;
    this.ry *= factor;
  }

  // updates arc params according to a given transform.
  // Note: Transforming ellipse arcs is currently only supported for
  //       simple transforms like translation, rotation, and uniform scaling.
  applyMatrix4(matrix) {

    // apply transform to x-axis direction
    tmpVec3.set(1, 0, 0).applyMatrix4(matrix);
    tmpVec3_2.set(0, 0, 0).applyMatrix4(matrix);
    const axis = tmpVec3.sub(tmpVec3_2);

    // obtain rotation angle and scale (assuming uniform scaling)
    const rotAngle = THREE.Math.radToDeg(Math.atan2(axis.y, axis.x));
    const scale = axis.length();

    // update ellipse params
    this.rotate(rotAngle);
    this.scale(scale);

    // If a transform changes the orientation, we have to invert sweepFlag and rotation param
    if (Math2D.changesOrientation(matrix)) {
      this.sweepFlag = !this.sweepFlag;
      this.rotation = 360.0 - this.rotation;
    }
  }
}

export class Path extends PolyBase {

  constructor(points) {let isClosed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;let style = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DefaultStyle.clone();
    super(points, style);

    // If true, the path is automatically closed and can be filled.
    this.isClosed = isClosed;
  }

  // Updates ellipse arc of an edge if vertices of the edges are going to be modified
  // @param {number} edgeIndex   - must be valid. Edge vertices must be in state _before_ modification.
  // @param {number} loopIndex   - must be valid.
  // @param {Vector2} newA, newB - edge vertices after modification
  _updateEllipseArcParams(edgeIndex, loopIndex, newA, newB) {

    const params = this._loops[loopIndex][edgeIndex].ellipseArcParams;

    // compute angle by which the edge was rotated
    const oldDir = this.getEdgeDirection(edgeIndex, loopIndex);
    const newDir = Math2D.getEdgeDirection(newA, newB);
    const dAngle = Math2D.angleBetweenDirections(newDir, oldDir);

    params.rotate(THREE.Math.radToDeg(dAngle));

    // get scale factor applied to the edge
    const oldLength = this.getEdgeLength(edgeIndex, loopIndex);
    const newLength = Math2D.distance2D(newA, newB); // also works for simple {x,y} pairs
    const scale = newLength / oldLength;

    // scale ellipse radii (if scaling is valid)
    const scaleValid = isFinite(scale) && scale > 0; // zero-radii do not work
    if (scaleValid) {
      params.scale(scale);
    }
  }

  updatePoint(index, x, y) {let loopIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

    const points = this._loops[loopIndex];
    let p = points[index];

    let pNew = tmpVec.set(x, y);

    // If p is adjacent to a BezierArc segment, the tangent should keep the same after changing the position
    // Therefore, we change the corresponding control points as well
    const dx = x - p.x;
    const dy = y - p.y;

    // Control point for the start tangent of the arc segment starting at p
    if (this.isBezierArc(index, loopIndex)) {
      p.cp1x += dx;
      p.cp1y += dy;
    }

    // Update ellipse arc starting at p
    if (this.isEllipseArc(index, loopIndex)) {
      // next point must exist if index is a valid ellipse-arc edge.
      const nextIndex = this.nextIndex(index, loopIndex);
      const pNext = points[nextIndex];
      this._updateEllipseArcParams(index, loopIndex, pNew, pNext);
    }

    // Update arc params of segment ending at p
    // Note: For polylines, this edge does not exist for index==0
    const prevEdge = this.edgeBeforeVertex(index, loopIndex);
    if (this.edgeIndexValid(prevEdge, loopIndex)) {

      // get previous vertex
      const pPrev = points[prevEdge];

      // Update bezier control point
      if (this.isBezierArc(prevEdge, loopIndex)) {
        pPrev.cp2x += dx;
        pPrev.cp2y += dy;
      }

      // Update ellipse arc
      if (this.isEllipseArc(prevEdge, loopIndex)) {
        this._updateEllipseArcParams(prevEdge, loopIndex, pPrev, pNew);
      }
    }

    p.x = x;
    p.y = y;
    this.modified();
  }

  getEdgeType(segmentIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    const type = this._loops[loopIndex][segmentIndex].arcType;
    return type ? type : EdgeType.Line;
  }

  // Change segment into a cubic Bezier arc.
  // First and last control point are already given by the vertex positions.
  //
  //  @param {number}             segmentIndex - must be in [0, this.getEdgeCount(loopIndex)]
  //  @param {BezierArcParams}    params
  //  @param {number} [loopIndex]
  setBezierArc(segmentIndex, params) {let loopIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

    // Legacy support:
    // If cp1x, cp2x etc. are enlisted individually, reshape params to expected form.
    // It's a pain that JS doesn't have function overloads.
    const isParamObj = typeof params === 'object';
    if (!isParamObj) {
      params = {
        cp1x: params,
        cp1y: loopIndex,
        cp2x: arguments.length <= 3 ? undefined : arguments[3],
        cp2y: arguments.length <= 4 ? undefined : arguments[4]
      };
      loopIndex = (arguments.length <= 5 ? undefined : arguments[5]) || 0;
    }

    const p = this._loops[loopIndex][segmentIndex];
    p.arcType = EdgeType.Bezier;
    BezierArcParams.prototype.copy.call(p, params);

    this.modified();
  }

  getBezierArcParams(segmentIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;let target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new BezierArcParams();
    // Find params
    const p = this._loops[loopIndex][segmentIndex];
    const srcParams = p && p.arcType === EdgeType.Bezier && p;

    // return a copy if found, otherwise undefined
    return srcParams && target.copy(srcParams);
  }

  // Set ellipse arc segment. Parameters are the same as for SVG ellipse arcs.
  // see https://www.w3.org/TR/svg-paths/#PathDataEllipticalArcCommands
  //
  //  @param {number}   segmentIndex - must be in [0, this.getEdgeCount()]
  //  @param {EllipseArcParams} arcParams
  setEllipseArc(segmentIndex, arcParams) {let loopIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

    const p = this._loops[loopIndex][segmentIndex];

    p.arcType = EdgeType.Ellipse;
    p.ellipseArcParams = arcParams.clone();

    this.modified();
  }

  // @param {number}           segmentIndex - must be a valid ellipse-arc edge
  // @param {EllipseArcParams} target
  // @returns {EllipseArcParams}
  getEllipseArcParams(segmentIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;let target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new EllipseArcParams();

    // Find params
    const p = this._loops[loopIndex][segmentIndex];
    const srcParams = p && p.arcType === EdgeType.Ellipse && p.ellipseArcParams;

    // Return a copy if found, otherwise undefined.
    return srcParams && target.copy(srcParams);
  }

  // Configures an EllipseArc curve to match with an ellipse-arc edge. This allows for sampling the arc.
  //  @param {number}     edgeIndex     - must be an ellipse arc
  //  @param {number}     [loopIndex=0] - loopIndex
  //  @param {EllipseArc} [target]      - optional
  //  @returns {EllipseArc}
  exportEllipseArc(edgeIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;let target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new EllipseArc();

    const points = this._loops[loopIndex];

    // get start/end points
    const nextIndex = this.nextIndex(edgeIndex, loopIndex);
    const pStart = points[edgeIndex];
    const pEnd = points[nextIndex];
    const params = pStart.ellipseArcParams;

    target.setFromSvgArc(
      params.rx,
      params.ry,
      params.rotation,
      params.largeArcFlag,
      params.sweepFlag,
      pStart,
      pEnd
    );
    return target;
  }

  isBezierArc(segmentIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return this.edgeIndexValid(segmentIndex, loopIndex) && this._loops[loopIndex][segmentIndex].arcType === EdgeType.Bezier;
  }

  isEllipseArc(segmentIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return this.edgeIndexValid(segmentIndex, loopIndex) && this._loops[loopIndex][segmentIndex].arcType === EdgeType.Ellipse;
  }

  isArc(segmentIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return this.isBezierArc(segmentIndex, loopIndex) || this.isEllipseArc(segmentIndex, loopIndex);
  }

  // Get tangent vector pointing from start vertex to control point 1 of an arc segment.
  // Only allowed for Bezier arcs. Result is not normalized.
  //  @param {number} segmentIndex - must be a valid index of an arc segment.
  //  @returns {Vector2}
  getStartTangent(segmentIndex) {let outTarget = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    const target = outTarget || new THREE.Vector2();
    const p = this.points[segmentIndex];
    target.x = p.cp1x - p.x;
    target.y = p.cp1y - p.y;
    return target;
  }

  // Get tangent vector pointing from end vertex to control point 2 of an arc segment.
  //  @param {number} segmentIndex - must be a valid index of an arc segment.
  //  @returns {Vector2}
  getEndTangent(segmentIndex) {let outTarget = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    const target = outTarget || new THREE.Vector2();
    const endVertex = this.nextIndex(segmentIndex);

    // get start/end point of the segment
    const pStart = this.points[segmentIndex];
    const pEnd = this.points[endVertex];
    target.x = pStart.cp2x - pEnd.x;
    target.y = pStart.cp2y - pEnd.y;
    return target;
  }

  //  @param {number} segmentIndex - must be a valid index of an arc segment.
  //  @param {Vector2} tangent
  setStartTangent(segmentIndex, tangent) {
    const p = this.points[segmentIndex];
    p.cp1x = p.x + tangent.x;
    p.cp1y = p.y + tangent.y;
    this.modified();
  }

  //  @param {number} segmentIndex - must be a valid index of an arc segment.
  //  @param {Vector2} tangent
  setEndTangent(segmentIndex, tangent) {
    const p = this.points[segmentIndex];
    const pEnd = this.points[this.nextIndex(segmentIndex)];
    p.cp2x = pEnd.x + tangent.x;
    p.cp2y = pEnd.y + tangent.y;
    this.modified();
  }

  // Change Bezier or Ellipse arc back to simple line segment
  removeArc(segmentIndex) {let loopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    const p = this._loops[loopIndex][segmentIndex];

    if (p.arcType === EdgeType.Bezier) {
      p.cp1x = undefined;
      p.cp1y = undefined;
      p.cp2x = undefined;
      p.cp2y = undefined;
    }

    if (p.ellipseArcParams) p.ellipseArcParams = undefined;

    // reset type
    p.arcType = EdgeType.Line;

    this.modified();
  }

  // Return ctrl point of Bezier Arc. Only allowed if isBezierArc(segmentIndex) is true
  // @param {number} segmentIndex
  // @param {number} ctrlPointIndex - Must be 1 or 2. Note that ctrlPoints 0 and 3 are defined by
  //                                  current vertex position
  // @param {Vector2} [target]
  // @param {number}  [loopIndex]
  getControlPoint(segmentIndex, ctrlPointIndex) {let loopIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;let target = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : new THREE.Vector2();
    const p = this._loops[loopIndex][segmentIndex];

    if (ctrlPointIndex === 1) {
      target.x = p.cp1x;
      target.y = p.cp1y;
    } else {
      target.x = p.cp2x;
      target.y = p.cp2y;
    }
    return target;
  }

  // Return ctrl point of Bezier Arc. Only allowed if isBezierArc(segmentIndex) is true
  // @param {number} segmentIndex
  // @param {number} ctrlPointIndex - Must be 1 or 2. Note that ctrlPoints 0 and 3 are defined by
  //                                  current vertex position
  updateControlPoint(segmentIndex, ctrlPoint, x, y) {let loopIndex = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
    let p = this._loops[loopIndex][segmentIndex];
    if (ctrlPoint === 1) {
      p.cp1x = x;
      p.cp1y = y;
    } else {
      p.cp2x = x;
      p.cp2y = y;
    }
    this.modified();
  }

  // Draw Polygon into LmvCanvasContext
  draw(ctx, overrideStyle) {
    drawPath(ctx, this, overrideStyle);
  }

  // Sample path into a Polygon or Polyline.
  //  @returns {Polygon|Polyline}
  toPoly() {let tessParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Autodesk.Extensions.CompGeom.DefaultTessParams;

    const poly = this.isClosed ? new Polygon() : new Polyline();
    for (let l = 0; l < this.loopCount; l++) {

      // Build up a polygon from path commands
      const ctx = {
        moveTo: (x, y) => poly.addPoint(x, y, l),
        lineTo: (x, y) => poly.addPoint(x, y, l),
        bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y) {

          // get last added point. Note that it always exists, because runLoop() always starts with moveTo.
          // so we know for sure that >=1 points were already added to this polygon loop.
          const points = poly._loops[l];
          const last = points[points.length - 1];

          // compute bbox of the arc - which we use as an estimate for required accuracy
          const arcBox = tmpBox2.makeEmpty();
          arcBox.expandByPoint(last);
          arcBox.expandByPoint(tmpVec2.set(x, y));
          arcBox.expandByPoint(tmpVec2.set(cp1x, cp1y));
          arcBox.expandByPoint(tmpVec2.set(cp2x, cp2y));
          const sz = arcBox.size(tmpVec).length();

          // sample arc into lineTo() segments
          Autodesk.Extensions.CompGeom.TesselateCubic(ctx, last.x, last.y, cp1x, cp1y, cp2x, cp2y, x, y, sz, tessParams);
        },
        ellipse(cx, cy, rx, ry, rotation, startAngle, endAngle, ccw) {

          // use ellipse maxRadius a reference for required accuracy
          const sz = Math.max(rx, ry);

          // determine tesselation params
          const maxSegments = tessParams.numIterations;
          const minSegmentLength = tessParams.minSegLenFraction * sz;

          // tesselate arc
          const arc = getTmpArc().set(cx, cy, rx, ry, rotation, startAngle, endAngle, ccw);
          arc.tesselate(ctx, maxSegments, minSegmentLength);
        },
        closePath: () => {} // Polygon is closed anyway.
      };
      runLoop(ctx, this, l);
    }
    return poly;
  }

  computeBBox() {
    // Compute bbox of all vertices
    super.computeBBox();

    // Consider Bezier arcs: By definition, Bezier curves are always bounded by the convex hull of their control
    // points. Therefore, we can simply add the control points to the bbox.
    //
    // Note: The bboxes obtained by this simple approach are only guaranteed to contain the curve. But, they are not guaranteed to be minimal.
    //       This is not a big issue for most uses (hitTest, drawing etc.). In case it becomes a problem anywhere, we need a better solution here, e.g.
    //       https://stackoverflow.com/questions/24809978/calculating-the-bounding-box-of-cubic-bezier-curve
    const cp = new THREE.Vector2();
    for (let l = 0; l < this.loopCount; l++) {
      for (let i = 0; i < this.getVertexCount(l); i++) {

        if (this.isBezierArc(i, l)) {
          // add control point 1
          this.getControlPoint(i, 1, l, cp);
          this.bbox.expandByPoint(cp);

          // add control point 2
          this.getControlPoint(i, 2, l, cp);
          this.bbox.expandByPoint(cp);
        } else
        if (this.isEllipseArc(i, l)) {
          const arc = this.exportEllipseArc(i, l, getTmpArc());
          this.bbox.union(arc.computeBBox(tmpBox2));
        }
      }
    }
  }

  hitTest(x, y, hitRadius) {
    const poly = this.toPoly();
    return poly.hitTest(x, y, hitRadius);
  }

  clone() {
    return new Path().copy(this);
  }

  // @param {Path} srcPath
  // @param {number} srcLoopIndex loop in srcPath to copy
  // @param {number} [dstLoopIndex] Optional: index where to insert the new loop. By default, we use the first free loopIndex.
  addLoop(srcPath) {let srcLoopIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;let dstLoopIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;

    // If dstLoop is not set, it will be chosen by the super.addLoop().
    dstLoopIndex = super.addLoop(srcPath, srcLoopIndex, dstLoopIndex);

    const srcPoints = srcPath._loops[srcLoopIndex];
    const dstPoints = this._loops[dstLoopIndex];

    // Copy extra information for arcs
    for (let i = 0; i < srcPoints.length; i++) {
      const type = srcPath.getEdgeType(i, srcLoopIndex);

      // Line segments are fully handled by the base class already
      if (type === EdgeType.Line) {
        continue;
      }

      const src = srcPoints[i];
      const dst = dstPoints[i];

      dst.arcType = src.arcType;

      switch (type) {
        case EdgeType.Bezier:{
            // copy control points
            dst.cp1x = src.cp1x;
            dst.cp1y = src.cp1y;
            dst.cp2x = src.cp2x;
            dst.cp2y = src.cp2y;
            break;
          }
        case EdgeType.Ellipse:{
            // copy arc params
            dst.ellipseArcParams = src.ellipseArcParams && src.ellipseArcParams.clone();
            break;
          }
      }
    }

    this.modified();
    return this;
  }

  moveLoop(dx, dy, loopIndex) {
    super.moveLoop(dx, dy, loopIndex);

    // Move affected control points as well
    const points = this._loops[loopIndex];
    for (let i = 0; i < points.length; i++) {
      if (!this.isBezierArc(i, loopIndex)) {
        continue;
      }

      const p = points[i];
      p.cp1x += dx;
      p.cp1y += dy;
      p.cp2x += dx;
      p.cp2y += dy;
    }

    // Note that for Ellipse arcs, it is sufficient to move start/end like for line segments.
  }

  getArea(measureTransform) {
    if (!this.isClosed) {
      return undefined;
    }

    // Todo: If performance becomes an issue, this can be optimized by a less brute-force way.
    const poly = this.toPoly(AreaTessParam);

    // Since poly is just temporary, we can just share the loop infos to prevent poly.getArea()
    // from computing them again.
    poly._loopInfos = this._getLoopInfos();

    return poly.getArea(measureTransform);
  }

  getLength(measureTransform) {
    const poly = this.toPoly();
    return poly.getLength(measureTransform);
  }

  // Get point on segment. This refines the implementation
  // of PolyBase by supporting arc segments.
  getPointOnEdge(segmentIndex, t) {let loopIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;let target = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : new THREE.Vector2();

    const points = this._loops[loopIndex];
    const type = this.getEdgeType(segmentIndex, loopIndex);
    switch (type) {
      case EdgeType.Line:break;
      case EdgeType.Bezier:{
          // get segment start/end
          const a = points[segmentIndex];
          const b = points[this.nextIndex(segmentIndex)];

          return Autodesk.Extensions.CompGeom.getCubeBezierPoint(t, a.x, a.y, a.cp1x, a.cp1y, a.cp2x, a.cp2y, b.x, b.y, target);
        }
      case EdgeType.Ellipse:{
          const arc = this.exportEllipseArc(segmentIndex, loopIndex, getTmpArc());

          // ignore arcs with NaN values
          if (!arc.isValid()) {
            break;
          }
          return arc.getPoint(t, target);
        }
      default:avp.logger.error('unexpected edge type');
    }

    return super.getPointOnEdge(segmentIndex, t, loopIndex, target);
  }

  // Run moveTo/lineTo/arcTo/closePath calls on a given context object.
  //  @param {Path2d|LmvCanvasContext|Object} ctx    - Receives the callback calls, i.e. moveTo, lineTo, bezierCurveTo, closePath etc. (see Path2D)
  runPathCommands(ctx) {
    runPath(ctx, this);
  }

  // Returns false if all edges of all loops are line segments.
  hasArcs() {
    const filter = (vertexIndex, loopIndex) => this.isArc(vertexIndex, loopIndex);
    return Boolean(this.findVertex(filter));
  }
}

// Alias that can be used for Polyline/Polygon paths. Use only if you don't intend to change the isClosed prop during lifetime.
export class PolygonPath extends Path {
  constructor(points, style) {
    super(points, true, style);
  }
};

export class PolylinePath extends Path {
  constructor(points, style) {
    super(points, false, style);
  }
};

export class Circle extends Shape {

  // Note: The tessSegments parameter will be removed later when the implementation uses arcs from LineShader directly.
  constructor() {let centerX = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0.0;let centerY = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.0;let radius = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1.0;let style = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : DefaultStyle.clone();let tessSegments = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 20;
    super(style);

    this.polygon = new Polygon([], style);

    // Force polygon.id to be the same, so that its geometry is associated with this Circle.
    // This is a bit hacky, but can be removed as soon as we use native arcs for circle rendering.
    this.polygon.id = this.id;

    this.centerX = centerX;
    this.centerY = centerY;
    this.radius = radius;
    this.tessSegments = tessSegments;

    this.needsUpdate = true;
  }

  draw(ctx, overrideStyle) {

    this.polygon.points.length = 0;

    // angle delta in degrees
    const stepSize = 360 / this.tessSegments;
    for (let angle = 0; angle < 360; angle += stepSize) {

      let a = angle * Math.PI / 180;
      let x = this.radius * Math.cos(a);
      let y = this.radius * Math.sin(a);

      this.polygon.addPoint(this.centerX + x, this.centerY + y);
    }

    this.needsUpdate = false;

    this.polygon.draw(ctx, overrideStyle);
  }

  setCenter(x, y) {
    this.centerX = x;
    this.centerY = y;
    this.modified();
  }

  move(dx, dy) {
    this.centerX += dx;
    this.centerY += dy;
    this.modified();
    return this;
  }

  hitTest(x, y) {
    const dx = x - this.centerX;
    const dy = y - this.centerY;
    return dx * dx + dy * dy < this.radius * this.radius;
  }

  clone() {
    return new Circle().copy(this);
  }

  copy(from) {
    super.copy(from);
    this.polygon = from.polygon.clone();
    this.centerX = from.centerX;
    this.centerY = from.centerY;
    this.radius = from.radius;
    this.tessSegments = from.tessSegments;
    this.modified();
    return this;
  }

  computeBBox() {
    this.bbox.min.set(this.centerX - this.radius, this.centerY - this.radius);
    this.bbox.max.set(this.centerX + this.radius, this.centerY + this.radius);
  }
}

export class ShapeWrapper extends Shape {

  // @param {Shape} shape - must not be null
  constructor(shape) {
    super();
    this.shape = shape;

    Object.defineProperty(this, 'bbox', {
      get: () => this.shape.bbox,
      set: (bbox) => {this.shape.bbox = bbox;}
    });

    Object.defineProperty(this, 'id', {
      get: () => this.shape.id,
      set: (id) => {this.shape.id = id;}
    });

    Object.defineProperty(this, 'bboxDirty', {
      get: () => this.shape.bboxDirty,
      set: (dirty) => {this.shape.bboxDirty = dirty;}
    });

    Object.defineProperty(this, 'name', {
      get: () => this.shape.name,
      set: (name) => {this.shape.name = name;}
    });
  }

  draw() {return this.shape.draw(...arguments);}
  hitTest() {return this.shape.hitTest(...arguments);}
  move() {return this.shape.move(...arguments);}
  modified() {return this.shape.modified(...arguments);}
  computeBBox() {return this.shape.computeBBox(...arguments);}
  updateBBox() {return this.shape.updateBBox(...arguments);}

  clone() {
    return new ShapeWrapper(this.shape.clone());
  }

  copy(from) {
    this.shape.copy(from.shape);
  }
}