import { forceSimulation, forceManyBody } from 'd3-force';
import { quadtree } from 'd3-quadtree';

import * as et from '../../application/EventTypes';
import { HUDLabel, HUD_LABEL_TRANSFORM_EVENT } from './HudLabel';
import { HUDPinLabel } from './HudPinLabel';
import { HudLayers } from './layers/HudLayers';
import debounce from '../streams/timed-debounce';
import { LmvMatrix4 } from '../../wgs/scene/LmvMatrix4';
import { LmvBox3 } from '../../wgs/scene/LmvBox3';

const boxArr = new Array(6);
const _tmpBox = new LmvBox3();
const _tmpMatrix = new LmvMatrix4();

/*function drawTextShadow(ctx, text, x, y, r) {

	ctx.fillStyle = "rgba(0,0,0,1.0)";

	ctx.fillText(text, x - r, y - r);
	ctx.fillText(text, x - r, y);
	ctx.fillText(text, x - r, y + r);

	ctx.fillText(text, x + r, y - r);
	ctx.fillText(text, x + r, y);
	ctx.fillText(text, x + r, y + r);

	ctx.fillText(text, x, y + r);
	ctx.fillText(text, x, y - r);
}*/

const HUD_LABEL_PADDING = 5;
const DOM_LABELS_OPACITY_MIN = 0.55;
const HUD_TOOL_NAME = 'dt_hud';

function makeLabelId(model, dbId, isPin) {
  return `${model.urn()}:${dbId}${isPin ? ':pin' : ''}`;
}

export class HUD {

  /**
   * @param {Viewer3D} viewer
   * @param {DtFacility} twin
   */
  constructor(viewer, twin) {
    this.viewer = viewer;
    this.twin = twin;
    this.dirty = false;

    this.labels = {};
    this.layers = new HudLayers(viewer, twin, this, twin?.app?.flags);

    // Upper limit of items to draw. Draw loop could be limited by
    //   items_to_render = Math.min(this.items_possibly_sorted_by_priority, this.numberOfVisibleItems);
    this.numberOfVisibleItems = 100;

    this.simulation = null;

    this.drawQueue = [];
    this.animQueue = [];

    this.drawBox = false;
    this.drawLeadLine = false;
  }

  setNumberOfVisibleItems(n) {
    this.numberOfVisibleItems = n;
  }

  #init() {
    this.layers?.init(this.viewer);

    this.resizeListener = this.resize.bind(this);
    this.viewer.addEventListener(et.VIEWER_RESIZE_EVENT, this.resizeListener);

    this.cameraListener = () => {
      this.invalidate();
      this.debounceCameraChange();
    };
    this.viewer.addEventListener(et.CAMERA_CHANGE_EVENT, this.cameraListener);

    this.selectionListener = this.initFromSelection.bind(this);
    this.viewer.addEventListener(et.AGGREGATE_SELECTION_CHANGED_EVENT, this.selectionListener);

    this.debounceCameraChange = debounce(this.onCameraChangeEnded.bind(this), 100);

    // Only needed if we're drawing screenbox with leadline
    if (this.drawLeadLine) {
      const redraw = () => {this.invalidate();this.update();};
      this.labelsListener = redraw.bind(this);
      this.viewer.addEventListener(HUD_LABEL_TRANSFORM_EVENT, this.labelsListener);
    }

    const document = this.viewer.container.ownerDocument;

    this.canvas = document.createElement("canvas");
    this._initCanvas(this.canvas);

    this.animCanvas = document.createElement("canvas");
    this._initCanvas(this.animCanvas);

    this.resize();
  }

  _initCanvas(canvas) {
    canvas.style.position = "absolute";
    canvas.style.width = "100%";
    canvas.style.height = "100%";
    canvas.style.left = "0";
    canvas.style.top = "0";
    canvas.style.pointerEvents = "none";
    canvas.style.backgroundColor = "rgba(0,0,0,0)";
    this.viewer.canvasWrap.appendChild(canvas);
  }

  /** @see ToolController */
  activate(_toolName, viewer) {
    this.viewer = viewer;
    this.#init();
  }

  /** @see ToolController */
  deactivate( /* toolName */) {
    this.#dtor();
  }

  #dtor() {
    this.layers?.dispose();

    if (this.resizeListener) {
      this.viewer.removeEventListener(et.VIEWER_RESIZE_EVENT, this.resizeListener);
      this.resizeListener = null;
    }

    if (this.cameraListener) {
      this.viewer.removeEventListener(et.CAMERA_CHANGE_EVENT, this.cameraListener);
      this.cameraListener = null;
    }

    if (this.selectionListener) {
      this.viewer.removeEventListener(et.AGGREGATE_SELECTION_CHANGED_EVENT, this.selectionListener);
      this.selectionListener = null;
    }

    if (this.debounceCameraChange) {
      this.debounceCameraChange.cancel();
    }

    if (this.labelsListener) {
      this.viewer.removeEventListener(HUD_LABEL_TRANSFORM_EVENT, this.labelsListener);
      this.labelsListener = null;
    }

    if (this.canvas) {
      this.viewer.canvasWrap.removeChild(this.canvas);
      this.canvas = null;
    }

    if (this.animCanvas) {
      this.viewer.canvasWrap.removeChild(this.animCanvas);
      this.animCanvas = null;
    }
  }

  invalidate() {
    this.dirty = true;
  }

  resize() {
    this.dpr = window.devicePixelRatio;
    this.width = this.viewer.canvasWrap.clientWidth * this.dpr;
    this.height = this.viewer.canvasWrap.clientHeight * this.dpr;

    this.canvas.width = this.width;
    this.canvas.height = this.height;

    this.animCanvas.width = this.width;
    this.animCanvas.height = this.height;

    this.ctx = this.canvas.getContext("2d");
    this.animCtx = this.animCanvas.getContext("2d");
  }

  initFromSelection(e) {
    return;

    this.clear();

    for (let i = 0; i < e.selections.length; i++) {
      let s = e.selections[i];

      for (let j = 0; j < s.dbIdArray.length; j++) {
        this.addLabel(s.model, s.dbIdArray[j]);
      }
    }

    this.invalidate();
    this.update();

    this.doRepellingSimulation();
  }

  getLabels() {
    return this.labels;
  }

  addLabel(model, dbId, isPin, options) {
    const labelId = makeLabelId(model, dbId, isPin);

    let label = this.getLabel(labelId);
    if (label) {
      // don't replace existing label
      return label;
    }

    const it = model.getInstanceTree();

    it.getNodeBox(dbId, boxArr);
    _tmpBox.min.set(boxArr[0], boxArr[1], boxArr[2]);
    _tmpBox.max.set(boxArr[3], boxArr[4], boxArr[5]);

    const elementName = it.getNodeName(dbId, false);
    const LabelClass = isPin ? HUDPinLabel : HUDLabel;
    label = new LabelClass(this.viewer, _tmpBox.getCenter(), elementName, labelId, options);
    this.labels[labelId] = label;

    if (this.drawBox || this.drawLeadLine) {
      label.setWorldBox(_tmpBox.clone());
    }

    this.updateDOMLabelsByDepth();
    return label;
  }

  addLabelForStream(position, name, dbId) {
    let label = this.getLabel(dbId);
    if (label) {
      return label;
    }

    label = new HUDLabel(this.viewer, position, name, dbId);
    this.labels[dbId] = label;

    this.updateDOMLabelsByDepth();
    return label;
  }

  getLabel(labelId) {
    return this.labels[labelId];
  }

  /** @see ToolController */
  getName() {
    return HUD_TOOL_NAME;
  }

  /** @see ToolController */
  getNames() {
    return [HUD_TOOL_NAME];
  }

  deleteLabel(labelId) {
    this.labels[labelId]?.dispose();
    delete this.labels[labelId];

    this.invalidate();
  }

  resetDrawQueue() {
    this.drawQueue.length = 0;
  }

  resetAnimQueue() {
    this.animQueue.length = 0;
  }

  addDrawStep(drawFunc) {
    this.drawQueue.push(drawFunc);
  }

  addAnimStep(animStep) {
    // One step is split into update + draw functions
    this.animQueue.push(animStep);
  }

  clear() {
    for (const labelId in this.labels) {
      this.labels[labelId].dispose();
    }
    this.labels = {};
  }

  update(ts) {
    this.layers?.update(ts);

    if (this.dirty) {
      const camera = this.viewer.impl.camera;
      _tmpMatrix.copy(camera.matrixWorldInverse);
      _tmpMatrix.multiplyMatrices(camera.projectionMatrix, _tmpMatrix);

      this.ctx.clearRect(0, 0, this.width, this.height);
      this.animCtx.clearRect(0, 0, this.width, this.height);

      this.updateDOMLabelsByDepth();

      if (this.drawBox || this.drawLeadLine) {
        // Always draw HUDLabels (line & box) first
        for (const labelId in this.labels) {
          const label = this.labels[labelId];
          label.draw(this, _tmpMatrix, this.drawBox, this.drawLeadLine);
        }
      }

      // Draw static elements
      this.drawQueue.sort((a, b) => a[0] - b[0]);

      for (const [_, drawFunc] of this.drawQueue) {
        drawFunc(this, _tmpMatrix);
      }

      // Setup animations
      this.animQueue.sort((a, b) => a[0] - b[0]);

      for (const [_, { init, update, draw }] of this.animQueue) {
        // initialize animation and redraw immediately since the animation canvas was cleared
        init(this, _tmpMatrix);
        update(ts);
        draw(this);
      }

      this.dirty = false;
      return;
    }

    if (this.animQueue.length) {

      let animDirty = false;

      // Call animation updates
      for (const [_, { update }] of this.animQueue) {
        animDirty = update(ts) || animDirty;
      }

      // Repaint if updated
      if (animDirty) {
        this.animCtx.clearRect(0, 0, this.width, this.height);
        for (const [_, { draw }] of this.animQueue) {
          draw(this);
        }
      }
    }

    return false;
  }

  updateDOMLabelsByDepth() {
    const camera = this.viewer.impl.camera;
    const depthByLabel = [];

    for (const labelId in this.labels) {
      const label = this.labels[labelId];
      const depth = camera.position.distanceToSquared(label.pos3D);

      depthByLabel.push([label, depth]);
    }

    const count = depthByLabel.length;
    if (count <= 1) return; // No sorting needed

    depthByLabel.sort((a, b) => b[1] - a[1]);

    const minDepth = depthByLabel[count - 1][1];
    const maxDepth = depthByLabel[0][1];
    const range = maxDepth - minDepth;

    if (range === 0) return; // No distance delta

    const opacityScale = (1 - DOM_LABELS_OPACITY_MIN) / range;

    for (let i = 0; i < count; i++) {
      const label = depthByLabel[i][0];
      const depth = depthByLabel[i][1];

      // z-index is equal to distance-to-camera based ordering,
      // plus the base value of the container (measure-length CSS style)
      label.baseZIndex = i + 2;

      // Opacity is determined using the distance delta between the nearest/farthest labels to the camera
      // and setting a corresponding opacity for a given label depending on where it falls within that
      // delta. The farthest will have minimum opacity and nearest will be fully opaque (1).
      const opacity = 1 - (depth - minDepth) * opacityScale;
      label.baseOpacity = opacity;
    }
  }

  // This method is not intended to be called on every frame but at the end of some action,
  // e.g. "mouse up" or "final frame rendered"
  //
  // For getting simulation results on every frame, "simulation ontick" callback should be called
  // Some updates to the animation "label animation callback" might be required as well
  doRepellingSimulation() {
    const canRepel = Object.values(this.labels).some((_ref) => {let { isRepellable } = _ref;return isRepellable;});
    if (!canRepel) {
      return;
    }

    this.simulation?.stop();

    let nodes = [];

    let maxTextLength = 0;

    for (let key in this.labels) {
      const label = this.labels[key];
      if (!label.visible || !label.isRepellable) continue;

      const { x, y } = label.getCanvasPosition();

      const textLengthInPixel = label.getTextWidthInPixel();
      const textHeightInPixel = label.getTextHeightInPixel();

      if (textLengthInPixel > maxTextLength) {
        maxTextLength = textLengthInPixel;
      }

      const nodeInfo = {
        startX: x,
        startY: y,
        width: textLengthInPixel,
        height: textHeightInPixel,
        x: x, // will be updated by the simulation
        y: y, // will be updated by the simulation
        label: this.labels[key]
      };

      nodes.push(nodeInfo);
    }

    if (nodes.length) {
      this.simulation = forceSimulation(nodes);
      this.simulation.
      force('charge', forceManyBody().distanceMax(maxTextLength)).
      force('collision', forceCollideRect()).
      alpha(1).
      alphaMin(0.1).
      alphaDecay(0.1).
      on('end', () => {
        for (let node of nodes) {
          const dx = node.x - node.startX;
          const dy = node.y - node.startY;
          node.label.setOffset({ x: dx, y: dy }, true);
        }

        // Erase previous labels' HUD when transforms start
        this.invalidate();
        this.update();
      });
    }
  }

  /** Called after the last of the debounced camera change event. */
  onCameraChangeEnded() {
    this.doRepellingSimulation();
  }

  getStateForView() {
    const hudState = {};

    if (this.layers) {
      hudState.layers = this.layers.getStateForView();
    }

    return hudState;
  }
}

/**
 * Custom d3 rectangular collision function
 * Taken from https://lvngd.com/blog/rectangular-collision-detection-d3-force-layouts/
 * and https://observablehq.com/@roblallier/rectangle-collision-force
 */
function forceCollideRect() {
  let nodes;

  function force() {
    const quadTree = quadtree(nodes, (node) => node.x, (node) => node.y);

    for (const node of nodes) {
      quadTree.visit((quad) => {
        let updated = false;

        if (quad.data && quad.data !== node) {
          let x = node.x - quad.data.x;
          let y = node.y - quad.data.y;

          const absX = Math.abs(x);
          const absY = Math.abs(y);
          const xSize = HUD_LABEL_PADDING + (node.width + quad.data.width) / 2;
          const ySize = HUD_LABEL_PADDING + (node.height + quad.data.height) / 2;

          if (absX < xSize && absY < ySize) {
            const dist = Math.sqrt(x * x + y * y);

            let xDist = (absX - xSize) / dist;
            let yDist = (absY - ySize) / dist;

            // Find primary overlap axis to move the other
            if (Math.abs(xDist) < Math.abs(yDist)) {
              yDist = 0;
            } else {
              xDist = 0;
            }

            node.vx -= x *= xDist;
            node.vy -= y *= yDist;
            quad.data.vx += x;
            quad.data.vy += y;

            updated = true;
          }
        }
        return updated;
      });
    }
  }

  force.initialize = (_) => nodes = _;

  return force;
}