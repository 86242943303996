import * as THREE from 'three';

import i18n from "i18next";
import * as et from "../../../../application/EventTypes";
import { FacetTypes } from '../../../facets/Facets';
import { HUD_LAYER, HudLayer } from '../HudLayer';
import LayerBackButton from "../LayerBackButton";
import { LevelLabel } from './LevelLabel';
import { guessPlacement, LevelLabelPlacer } from './LevelPlacer';
import { registerLvlsCxtMenuCb, unregisterLvlsCxtMenuCb } from "./LevelsMenuActions";
import RC from "../../../resources/cats_enum.json";
import debounce from "../../../streams/timed-debounce";

/** Level labels, seen when looking from the side, and shifted away from the actual level. */
export class LevelsLayer extends HudLayer {

  /** @type {Map<string, LevelLabel>} Label by level facet ID (its common name). */
  labelById = new Map();

  /** @type {Map<string, { left, right }>} Label by level facet ID (its common name). */
  placementById = new Map();

  /** @type {Array} List of filtered level facets */
  filteredLevels = [];

  /** @type {?string} A level ID if only a single level is in the filters. */
  isFocusedOn;

  constructor(facility, hud, viewer) {
    super(HUD_LAYER.LEVELS.id, facility, hud, viewer);

    this.backBtn = new LayerBackButton(this, viewer.container.ownerDocument, FacetTypes.levels);
    this.boundOnGeomLoaded = this.#onGeomLoaded.bind(this);
    this.debouncedInvalidatePlacement = debounce(this.#invalidatePlacement.bind(this), 67 /* 4 frames at 60fps */);
  }

  clear() {
    this.labelById.forEach((l) => l.dispose());
    this.labelById.clear();
    this.placementById.clear();
    this.filteredLevels = [];
  }

  dispose() {
    this.clear();
    this.backBtn.attachTo(null);
    unregisterLvlsCxtMenuCb(this.viewer);
    this.viewer.removeEventListener(et.GEOMETRY_LOADED_EVENT, this.boundOnGeomLoaded);
    this.debouncedInvalidatePlacement.cancel();
  }

  init() {
    registerLvlsCxtMenuCb(this.viewer, this.facility);
    this.viewer.addEventListener(et.GEOMETRY_LOADED_EVENT, this.boundOnGeomLoaded);
    return this;
  }

  /** Level label placement is derived from the camera's perspective. */
  onCameraChanged( /* camera */) {
    // This is not using the debounced update as it uses the cached version.
    this.#invalidatePlacement(true);
  }

  onFacetsUpdated() {
    this.#verifyConfiguration(this.facility.facetsManager.getFacetDefs());
    this.#invalidateFilteredLevels();
  }

  onIsolationChanged( /* isolation */) {this.#invalidateFilteredLevels();}

  onHiddenChanged( /* hidden */) {this.#invalidateFilteredLevels();}

  onLayerVisibilityChanged(visible) {
    if (visible) {
      // If we were off, we don't know about our latest placement.
      this.#invalidatePlacement();
    } else {
      this.invalidate();
    }
  }

  onClusterTransition(clustered) {
    if (clustered) {
      // Don't re-enable levels layer when clustered
      return false;
    }
  }

  onModelChanged(_ref) {let { change } = _ref;
    const { modelId, dbIds } = change;
    if (!modelId || !dbIds) return;

    for (const { idsSets } of this.filteredLevels) {
      if (modelId in idsSets && dbIds.some((id) => idsSets[modelId].has(id))) {
        this.#invalidateFilteredLevels();
        return;
      }
    }
  }

  onSelect(event, level, isFilter) {

    if (isFilter) {
      // Set consolidated level as single filter.
      const filterMap = this.facility.facetsManager.getFilterMap();
      const levelIdFilter = new Set([level.id]);
      this.facility.facetsManager.setFilterMap({
        ...filterMap,
        levels: levelIdFilter
      });

    } else {
      const selections = [];
      for (const model of this.facility.getModels()) {
        const levels = model.getLevels();
        if (!levels) continue;

        const selection = { model, selection: [] };

        for (const dbId in levels) {
          if (levels[dbId].name === level.id) {
            selection.selection.push(dbId);
          }
        }

        selections.push(selection);
      }
      this.viewer.setAggregateSelection(selections);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  onMouseOver(event, level) {
    this.facility.facetsManager.facetsEffects.addFloorHighlight(level);

    event.preventDefault();
    event.stopPropagation();
  }

  onMouseOut(event) {
    this.facility.facetsManager.facetsEffects.clearFacetHighlight();

    event.preventDefault();
    event.stopPropagation();
  }

  onDblClick(event, level) {
    const bounds = getLevelBounds(this.facility, level);
    this.facility.viewer.navigation.fitBounds(false, bounds, false, false);

    event.preventDefault();
    event.stopPropagation();
  }

  update(ts, _ref2) {let { cameraLookAtPitch, layerChanged } = _ref2;
    // Invalidated filtered levels not ready, skip this update but keep layer dirty
    if (this.filteredLevels === undefined) return true;

    let hasChanged = false;
    const unseen = new Set(this.labelById.keys());

    const hasCorrectPitch = cameraLookAtPitch > 0.21 && cameraLookAtPitch < 0.376;
    const isLayerVisible = this.isVisible();

    const placer = new LevelLabelPlacer(this);
    const levels = this.filteredLevels;
    for (const level of levels) {
      unseen.delete(level.id);

      if (!hasCorrectPitch && !this.isFocusedOn || !isLayerVisible) {
        hasChanged |= this.#updateLabel(level, false);
        continue;
      }

      const placement = this.placementById.get(level.id);
      if (!placement) {
        hasChanged |= this.#updateLabel(level, false);
        continue;
      }

      // Must also consider anchor in update as it depends on camera.
      const [pos3D, anchor] = placer.tryToPlace(placement, level.id);
      if (!pos3D) {
        // means we couldn't fit it.
        hasChanged |= this.#updateLabel(level, false);
        continue;
      }

      hasChanged |= this.#updateLabel(level, true, pos3D, anchor);
    }

    for (const levelId of unseen) {
      const label = this.labelById.get(levelId);
      hasChanged |= label.isVisible();
      label.setVisible(false);
    }

    // Update back navigation
    const focusedCtrl = this.isFocusedOn && this.labelById.get(this.isFocusedOn);
    this.backBtn.attachTo(focusedCtrl);

    layerChanged[this.layerID] = hasChanged;
  }

  /**
   * 3D world position of the label to be projected in screen space. (Computes it if missing)
   *
   * Meant for external users, this method is meant to work even if the levels layer is turned off, but relies on
   * `invalidateFilteredLevels` being called even when the layer is off.
   *
   * Also contains the position of the label, if one is visible.
   */
  getPlacement(model, dbId, useCacheOnly) {
    const level = model.getLevels()?.[dbId];
    if (!level) return;

    let placement = this.placementById.get(level.name);
    if (!placement) {
      if (useCacheOnly || !this.filteredLevels) return;

      const consolidatedLevel = this.filteredLevels.filter((_ref3) => {let { id } = _ref3;return id === level.name;});
      const placementById = guessPlacement(this.facility, consolidatedLevel, this.modelRoomsPerLevel);
      placement = placementById?.get(level.name);
      if (!placement) return;
    }

    const result = { ...placement };

    const label = this.labelById.get(level.name);
    if (label?.isVisible()) {
      // Adds position as a bonus, to match label if actively showing. Left placement should be favoured and
      // will be present in all cases where a placement could be derived.
      result.position = label.getPosition3D();
    }

    return result;
  }

  getCachedPlacement(model, dbId) {
    const level = model.getLevels()?.[dbId];
    if (!level) return;

    const placement = this.placementById.get(level.name);
    if (!placement) return;

    const label = this.labelById.get(level.name);
    const position = label?.label?.pos3D;

    return { ...placement, position };
  }

  #getFilteredLevels() {
    const levels = getVisibleFacetNodes(this.facility.facetsManager, FacetTypes.levels);
    if (levels.length === 0) return levels;

    // Caches dictionary of rooms per model per level to support fallback placement based on rooms.
    // TODO only do it when we know we will use it.
    this.modelRoomsPerLevel = this.#buildRoomCache();

    const filtered = [];
    for (const level of levels) {
      if (!level.hasVisibleLeaf) continue;
      if (level.isUnassignedId) continue;
      filtered.push(level);
    }

    // Sort by count of element, to iterate from most to least important level.
    return filtered.sort((l, r) => r.count - l.count);
  }

  #buildRoomCache() {
    const modelRoomsPerLevel = new Map();
    for (const model of this.facility.getModels()) {
      const lvlClassifier = model.facetsClassifiers?.levels;
      const roomMap = model.getRooms();
      if (!roomMap || !lvlClassifier) continue;

      const modelUrn = model.urn();
      for (const key in roomMap) {
        // Rooms are there twice, keeping keys by dbId and skipping external ids.
        if (key.length >= 20) continue;

        const lvlNode = lvlClassifier(key);
        if (lvlNode.id === '(Unassigned)') continue;

        let modelRooms = modelRoomsPerLevel.get(lvlNode.id);
        if (!modelRooms) {
          modelRooms = {};
          modelRoomsPerLevel.set(lvlNode.id, modelRooms);
        }

        const rooms = modelRooms[modelUrn] ?? (modelRooms[modelUrn] = []);
        rooms.push(key);
      }
    }

    return modelRoomsPerLevel;
  }

  #invalidateFilteredLevels() {
    this.filteredLevels = this.#getFilteredLevels();
    this.debouncedInvalidatePlacement();
  }

  #invalidatePlacement(useCacheOnly) {
    if (!this.filteredLevels || !this.isVisible()) return;

    if (this.filteredLevels.length === 0) {
      this.placementById.clear();
    } else {
      this.placementById = guessPlacement(this.facility, this.filteredLevels, this.modelRoomsPerLevel, useCacheOnly);
    }

    this.invalidate();
  }

  #makeLabel(level) {
    const label = new LevelLabel(level, level.id, this.hud);

    label.setSelectHandler(this.onSelect.bind(this));
    label.setMouseOverHandler(this.onMouseOver.bind(this));
    label.setMouseOutHandler(this.onMouseOut.bind(this));
    label.setDblClickHandler(this.onDblClick.bind(this));

    this.labelById.set(level.id, label);
    return label;
  }

  #onGeomLoaded() {
    // On final frame is needed here as invalidate placement relies on a floor cached mesh which can change
    // as relevant geometries are loaded. This allows on camera move to instead used the cached version since this
    // geom loaded will take care of requesting a non-cached re-computation when needed.
    this.debouncedInvalidatePlacement();
  }

  #updateLabel(level, isVisible, pos3D, anchor) {
    let label = this.labelById.get(level.id);

    if (isVisible) {
      label ??= this.#makeLabel(level);
      label.updatePlacement(pos3D, anchor);
      label.setVisible(true);
      return true;
    }
    if (!label) return false;

    const wasVisible = label.isVisible();
    label.setVisible(false);
    return wasVisible;
  }

  #verifyConfiguration(facetDefs) {
    const levelFacet = facetDefs.find((_ref4) => {let { id } = _ref4;return id === FacetTypes.levels;});

    // Take note of only filter to add guidance if that's the case.
    if (levelFacet?.filter.size === 1) {
      const [head] = levelFacet.filter;
      this.isFocusedOn = head;
    } else {
      this.isFocusedOn = null;
    }

    if (!levelFacet) {
      this.setErrorMsg(i18n.t("Missing required filter Standard > Levels"));
      return;
    }

    // Reset error message.
    this.setErrorMsg();
  }
}

const _lvlBounds = new THREE.Box3();
const _bounds = new THREE.Box3();
export function getLevelBounds(facility, level) {

  const cachedMesh = facility.facetsManager.facetsEffects.getFloorMesh(level);
  if (cachedMesh?.geometry) {
    return cachedMesh.geometry.boundingBox.clone().applyMatrix4(cachedMesh.matrix);
  }

  // Slow code path, bounds from everything inside the level.
  _lvlBounds.makeEmpty();
  for (const urn in level.idsSets) {
    const model = facility.getModelByUrn(urn);
    for (const dbId of level.idsSets[urn]) {
      model.getElementBounds(dbId, _bounds);
      _lvlBounds.union(_bounds);
    }
  }

  return _lvlBounds;
}

function getVisibleFacetNodes(facetsManager, facetId) {
  const idx = facetsManager.getFacetDefs().findIndex((_ref5) => {let { id } = _ref5;return id === facetId;});
  if (idx === -1) return [];

  const nodes = facetsManager.getFacets()[idx];
  const selected = nodes.filter((_ref6) => {let { selected } = _ref6;return selected;});

  return selected.length > 0 ? selected : nodes;
}