import { Circle, PolyBase, Path, EdgeType, EllipseArcParams } from './EditShapes.js';
import { Math2D } from './Math2D.js';

let domParser = null;

// SVG (de)serialization for EditShapes

// Url of XML-Namespace for SVG
const SvgNs = "http://www.w3.org/2000/svg";

const exp4 = Math.pow(10, 4);
const limitDigits = function (value) {let digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  if (!value || digits === null) {
    return value;
  }
  // like value.toFixed(), but removing trailing zeros
  const exp = digits === 4 ? exp4 : Math.pow(10, digits);
  return Math.round(value * exp) / exp;
};

const domToCircle = (circleNode) => {

  if (!circleNode.hasAttributes || !circleNode.hasAttributes()) {
    throw 'No attributes available on the <circle/> node';
  }
  const circle = new Circle();
  for (let i = circleNode.attributes.length - 1; i >= 0; i--) {
    let attr = circleNode.attributes[i];
    switch (attr.name) {
      case 'cx':
        circle.centerX = parseFloat(attr.value);
        break;
      case 'cy':
        circle.centerY = parseFloat(attr.value);
        break;
      case 'r':
        circle.radius = parseFloat(attr.value);
        break;
    }
  }
  return circle;
};

const circleToSvg = function (circle) {let digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;let stylePostFix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  return `<circle cx="${limitDigits(circle.centerX, digits)}" cy="${limitDigits(circle.centerY, digits)}" r="${limitDigits(circle.radius, digits)}${stylePostFix}"/>`;
};

const domToPath = (pathNode) => {

  const d = pathNode.getAttribute('d');
  if (!d) {
    return;
  }

  return parsePath(d);
};

// For closed paths, remove last point of each loop if it is just a repetition of the start vertex.
const removeDuplicateLoopEndPoints = (path) => {

  // Bezier applied to the last point will create an additional point which is equal to the endpoint to close the path
  // see Path.toSVGPath(). Path is always closed, therefore we can remove the duplicate end point.
  if (path.isClosed) {
    let pStart = new THREE.Vector2();
    let pEnd = new THREE.Vector2();
    const loopCount = path.loopCount;
    for (let l = 0; l < loopCount; l++) {

      // skip invalid loops
      const lastIndex = path.getVertexCount(l) - 1;
      if (lastIndex < 1) {
        continue;
      }

      // get start/end
      pStart = path.getPoint(0, l, pStart);
      pEnd = path.getPoint(lastIndex, l, pEnd);

      const delta = Math2D.pointDelta(pStart, pEnd, 0);
      if (!delta) {
        path.removePoint(lastIndex, l);
      }

    }
  }
};

const parsePath = (svgPath) => {
  // split at all chars but keep the char using positive look ahead
  // sample payload for path d = M 13.882,4.8592 L 14.6757,4.738 L 13.9668,4.4896 L 14.005,4.4896 C 15.3211,5.4567,14.79,3.1599,14.6624,4.155 L 13.9189,3.8945 L 13.9189,3.8 L 14.6234,3.7516 Z
  // results into list with glyph with position array:
  // ['M 13.882,4.8592', 'L 14.6757,4.738', 'L 13.9668,4.4896', 'L 14.005,4.4896', 'C 15.3211,5.4567,14.79,3.1599,14.6624,4.155', 'L 13.9189,3.8945', 'L 13.9189,3.8', 'L 14.6234,3.7516', 'Z']
  const pointStrings = svgPath.split(/ (?=[a-zA-Z])/gi);
  const validChars = "MLHVCZA";

  // Reused per cycle
  const ellipseParams = new EllipseArcParams();
  const nextPoint = new THREE.Vector2();

  // the current loop that we are adding edges to
  let loopIndex = 0;

  const path = new Path();

  for (let i = 0; i < pointStrings.length; i++) {

    // Check for unexpected characters
    const pointString = pointStrings[i];
    if (validChars.indexOf(pointString[0]) === -1) {
      throw `\"${pointString[0]}\" is not a supported or invalid glyph: ${pointString}`;
    }

    const segmentIndex = path.getVertexCount(loopIndex) - 1;

    // Determine edge type, position, and extra params for arcs
    let value = pointString.substring(1);
    switch (pointString[0]) {
      case 'M':
        // start new loop
        loopIndex = path.nextFreeLoop();
      case 'L':
        let coords = value.split(',');
        nextPoint.x = parseFloat(coords[0]);
        nextPoint.y = parseFloat(coords[1]);
        break;
      case 'H':
        nextPoint.x = parseFloat(value);
        break;
      case 'V':
        nextPoint.y = parseFloat(value);
        break;
      case 'C':

        // tokenize params
        const bezierCoords = value.split(',');

        // get position
        nextPoint.x = parseFloat(bezierCoords[4]);
        nextPoint.y = parseFloat(bezierCoords[5]);

        // get bezier params                
        const cp1x = parseFloat(bezierCoords[0]);
        const cp1y = parseFloat(bezierCoords[1]);
        const cp2x = parseFloat(bezierCoords[2]);
        const cp2y = parseFloat(bezierCoords[3]);

        if (segmentIndex >= 0) {
          path.setBezierArc(segmentIndex, cp1x, cp1y, cp2x, cp2y, loopIndex);
        } else {
          console.error('SVG Parse error: Path is not expected to start with "C" command');
        }
        break;

      case 'A':
        // tokenize params
        const src = value.split(',');

        // get position
        nextPoint.x = parseFloat(src[5]);
        nextPoint.y = parseFloat(src[6]);

        // read ellipse params.                
        ellipseParams.rx = parseFloat(src[0]);
        ellipseParams.ry = parseFloat(src[1]);
        ellipseParams.rotation = parseFloat(src[2]);
        ellipseParams.largeArcFlag = Boolean(parseFloat(src[3]));
        ellipseParams.sweepFlag = Boolean(parseFloat(src[4]));

        if (segmentIndex >= 0) {
          path.setEllipseArc(segmentIndex, ellipseParams, loopIndex);
        } else {
          console.error('SVG Parse error: Path is not expected to start with "A" command');
        }
        break;
      case 'Z':
        // we are done
        path.isClosed = true;
        continue;
    }

    // add next point
    path.addPoint(nextPoint.x, nextPoint.y, loopIndex);
  }

  removeDuplicateLoopEndPoints(path);

  return path;
};

const pathToSvgPath = function (path) {let precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;let digits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  if (!path.vertexCount) {
    return [];
  }

  // Shortcut for restricting number of digits
  const ld = (num) => limitDigits(num, digits);

  let svgPath = [];

  // reused per loop cycle
  let p = new THREE.Vector2();
  let pPrev = new THREE.Vector2();
  let params = new EllipseArcParams();
  let cp1 = new THREE.Vector2();
  let cp2 = new THREE.Vector2();

  const loopCount = path.loopCount;
  for (let l = 0; l < loopCount; l++) {

    // Add M command for first vertex
    const edgeCount = path.getEdgeCount(l);
    if (edgeCount > 0) {
      p = path.getPoint(0, l, p);
      svgPath.push(`M ${ld(p.x)},${ld(p.y)}`);
    }

    for (let i = 0; i < edgeCount; i++) {

      // Vertex i is the end point of segment i-1, which defines edge type and arc params
      const segmentIndex = i;
      const edgeType = path.isPath() ? path.getEdgeType(segmentIndex, l) : EdgeType.Line;

      // get next point. Note: If the path is closed, p will be the start vertex of the loop again.
      const edgeEndVertex = path.nextIndex(segmentIndex, l);
      p = path.getPoint(edgeEndVertex, l, p);

      let value = '';
      switch (edgeType) {

        case EdgeType.Ellipse:
          params = path.getEllipseArcParams(segmentIndex, l, params);

          // Convert boolean flags to 1/0
          const largeArc = params.largeArcFlag ? 1 : 0;
          const sweep = params.sweepFlag ? 1 : 0;

          value = `A ${params.rx},${params.ry},${params.rotation},${largeArc},${sweep},${p.x},${p.y}`;
          break;

        case EdgeType.Bezier:
          cp1 = path.getControlPoint(segmentIndex, 1, l, cp1);
          cp2 = path.getControlPoint(segmentIndex, 2, l, cp2);
          value = `C ${ld(cp1.x, digits)},${ld(cp1.y, digits)},${ld(cp2.x, digits)},${ld(cp2.y, digits)},${ld(p.x, digits)},${ld(p.y, digits)}`;
          break;

        default:
          {
            // skip empty/duplicate points
            pPrev = path.getPoint(i, l, pPrev);
            let delta = Math2D.pointDelta(pPrev, p, digits);
            if (!delta) {
              continue;
            }

            if (Math.abs(delta.x) <= precision) {
              value = `V ${ld(p.y)}`;
            } else if (Math.abs(delta.y) <= precision) {
              value = `H ${ld(p.x)}`;
            } else {
              value = `L ${ld(p.x)},${ld(p.y)}`;
            }
          }
      }

      // For closed paths, the edge end vertex will be 0. Repeating the start vertex is only necessary if the
      // last segment is an arc: Without repeating the start vertex at the loop end, we could not store the arc parameters in SVG.
      const isRepeatedStartVertex = edgeEndVertex === 0;
      if (isRepeatedStartVertex && edgeType === EdgeType.Line) {
        // Last segment of the loop is a line. We don't need to repeat the start vertex in this case.
        continue;
      }

      svgPath.push(value);
    }

    // For a closed path, repeat the end vertex. This is necessary to preserve arc params
    if (path.isClosed) {
      // close loop
      svgPath.push('Z');
    }
  }

  return svgPath;
};

// Extract dictionary of SVG style attributes from Edit2D style
//  @param {Style} style
//  @returns {Object}
const getSvgStyleAttributes = (style) => {

  const target = {};
  target["stroke"] = style.lineColor;
  target["fill"] = style.fillColor;
  target["stroke-width"] = style.lineWidth;

  // omit opacities if they are 1 (default)
  if (style.lineAlpha != 1) target["stroke-opacity"] = style.lineAlpha;
  if (style.fillAlpha != 1) target["fill-opacity"] = style.fillAlpha;

  return target;
};

// Converts the result of getSvgStyleAttributes to a string postfix. Since we append it
// to other attributes, it contains a preceding space. 
// Example: ' stroke="rgb(0,0,0)" fill="rgb(0,0,255)" ... '
const getAttributePostFix = (attribs) => {
  let str = '';
  for (let key in attribs) {
    str += ` ${key}="${attribs[key]}"`;
  }
  return str;
};

// Apply all given attributes to a Dom Element
const applyAttributes = (domElement, attribs) => {
  for (let key in attribs) {
    domElement.setAttribute(key, attribs[key]);
  }
};

// Converts a shape into a string describing an SVG path. Note that (for legacy reasons),
// style attributes are only exported if specified in options.
//
// @param {Shape} shape
// @param {Object} [options]
// @param {number} [options.precision=0]       - accuracy for unifying duplicate points 
// @param {number} [options.digis=0]           - number of digits for number strings 
// @param {bool}   [options.exportStyle=false] - If true, we also export style attributes
//
// Note: We currently have to use function syntax, otherwise we cannot use 'arguments' for the legacy fallback below.
const toSvg = function (shape, options) {

  let precision = options && options.precision || 0;
  let digits = options && options.digits || null; // means no limitation of digits
  let exportStyle = options && options.exportStyle || false;

  // add style params if wanted
  let stylePostfix = '';
  if (exportStyle) {
    const attribs = getSvgStyleAttributes(shape.style);
    stylePostfix = getAttributePostFix(attribs);
  }

  // Legacy: Keep old code using that still passes precision/digits separately
  // Todo: Remove this when checked with clients.
  if (typeof options !== 'object') {
    if (typeof arguments[1] == 'number') precision = arguments[1];
    if (typeof arguments[2] == 'number') digits = arguments[2];
  }

  if (shape instanceof Circle) {
    return circleToSvg(shape, digits, stylePostfix);
  }

  if (!(shape instanceof PolyBase)) {
    console.error('SVG serialization not supported for this shape: ', shape);
    return;
  }

  // PolyBase and Path are translated to SVG Path
  let path = pathToSvgPath(shape, precision, digits);

  return `<path d="${path.join(' ')}"${stylePostfix}/>`;
};

// see toSvg for options.
const createSvgShape = (shape, options) => {
  const precision = options && options.precision || 0;
  const digits = options && options.digits || 0;
  const exportStyle = options && options.exportStyle || true;

  // Todo: support circles and other shapes if anyone needs it.
  if (!(shape instanceof PolyBase)) {
    console.error('SVG serialization not supported for this shape: ', shape);
    return;
  }

  const path = document.createElementNS(SvgNs, 'path');
  path.setAttribute('d', pathToSvgPath(shape, precision, digits));

  if (exportStyle) {
    const attribs = getSvgStyleAttributes(shape.style);
    applyAttributes(path, attribs);
  }

  return path;
};

// @param {string}  svg - e.g. '<path d="M 13.882,4.8592 L 14.6757,4.738"/>'
const fromSvg = (svg) => {
  if (!svg) {
    return;
  }

  // init on first use
  domParser = domParser || new DOMParser();

  const dom = domParser.parseFromString(svg, 'application/xml');

  if (dom.childNodes.length !== 1) {
    throw 'Function does only support svg with a single element: path, circle';
  }
  const node = dom.firstChild;
  if (node.nodeName === 'circle') {
    return domToCircle(node);
  } else
  if (node.nodeName === 'path') {
    return domToPath(node);
  }

  throw `Unsupported svg node type: ${node.nodeName}`;
};

// Converts several shapes to an SVG element.
//  @param {Object} [options]
//  @param {Box2} [dstBox] - If specified, shapes can be rescaled to fit into a destination box for the given SVG.
//  @param {Box2} [srcBox] - By default, srcBox is the union of all shapeBoxes. Shapes are uniformly rescaled from srcBox into dstBox.
// see toSvg() for other options
const createSvgElement = function (shapes) {let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  // get summed box of all shapes
  let sumBox = new THREE.Box2();
  shapes.forEach((shape) => sumBox.union(shape.getBBox()));

  let srcBox = options.srcBox || sumBox;
  let dstBox = options.dstBox || sumBox;

  // Note that we always have to swap y-axis, because the y-axis direction is flipped when 
  // converting 2D world coords in LMV to SVG.
  const scaleOptions = {
    preserveAspect: true,
    flipY: true
  };
  const tf = Math2D.getFitToBoxTransform(srcBox, dstBox, scaleOptions);

  // get required size of svg
  let width = dstBox.max.x - dstBox.min.x;
  let height = dstBox.max.y - dstBox.min.y;

  // create svg root element      
  const svg = document.createElementNS(SvgNs, 'svg');
  svg.setAttribute('height', width);
  svg.setAttribute('width', height);

  // rescale & convert each shape
  shapes.forEach((shape) => {
    const scaledShape = shape.clone().applyMatrix4(tf);
    const path = createSvgShape(scaledShape, options);
    svg.appendChild(path);
  });

  return svg;
};

export const Svg = {
  toSvg: toSvg,
  fromSvg: fromSvg,
  createSvgShape: createSvgShape,
  createSvgElement: createSvgElement
};