// Viewer3D offers public methods for developers to use.
// Viewer3DImpl is the implementation file for Viewer3D and is only used by Viewer3D.js
//
// Viewer3D does things like parameter validation.
// Viewer3DImpl does the actual work, by interfacing with other internal components, such as the MaterialManager.

import { getGlobal, isNodeJS, isMobileDevice } from "../compat";
import { ScreenShot } from "./ScreenShot";
import { ScreenShot as ScreenShotWebGPU } from "../wgs/wgpu/ScreenShot";
import { ProgressState } from "./ProgressState";
import { RenderScene } from "../wgs/scene/RenderScene";
import {
  EDGE_COLOR_DARK,
  EDGE_COLOR_DARK_GHOSTED,
  EDGE_COLOR_LIGHT_GHOSTED,
  RenderContext } from
"../wgs/render/RenderContext";
import { MaterialManager } from "../wgs/render/MaterialManager";
import { MultiModelSelector } from "../tools/Selector";
import { MultiModelVisibilityManager } from "../tools/VisibilityManager";
import { LightPresets, DefaultLightPreset, DefaultLightPreset2d, BackgroundPresets } from "./LightPresets";
import { GroundShadow } from "../wgs/render/GroundShadow";
import { logger } from "../logger/Logger";
import { TextureLoader } from "../dt/loader/TextureLoader";
import { WebGLRenderer } from "../wgs/render/WebGLRenderer";
import { RenderFlags } from "../wgs/scene/RenderFlags";
import * as shadow from "../wgs/render/ShadowMap";
import { GroundReflection } from "../wgs/render/GroundReflection";
import { FragmentPointer } from "../wgs/scene/FragmentList";
import { CreateCubeMapFromColors } from "../wgs/render/DecodeEnvMap";
import { getResourceUrl } from "../globals";
import { SAOShader } from "../wgs/render/SAOShader";
import { VBIntersector } from "../wgs/scene/VBIntersector";
import * as THREE from "three";
import { UnifiedCamera } from "../tools/UnifiedCamera";
import * as et from "./EventTypes";
import { Navigation } from "../tools/Navigation";
import { SelectionType } from "../tools/SelectionType";
import { SceneMath } from "../wgs/scene/SceneMath";
import { GlobalManagerMixin } from './GlobalManagerMixin';
import { Prefs, Prefs3D } from "./PreferenceNames";
import { CMD_ALWAYS_DO, CMD_DO_AFTER, ENABLE_DEBUG, RenderCommandSystem } from "../wgs/scene/RenderCommandSystem";
import { fitToView } from "../tools/FitToViewUtil";
import { DtResourceCache } from "../dt/loader/DtResourceCache";
import { GPUMemoryTracker } from "../wgs/scene/GPUMemoryTracker";
import { BlendShader } from "../wgs/render/BlendShader";
import { Renderer } from "../wgs/wgpu/Renderer";
import { RenderContextWebGPU } from "../wgs/wgpu/RenderContextWebGPU";


//default parameters for WebGL initialization
export let InitParametersSetting = {
  canvas: null,
  antialias: false,
  alpha: false,
  premultipliedAlpha: false,
  preserveDrawingBuffer: false,
  stencil: false,
  depth: false
};

// Create a webgl renderer
export function createRenderer(canvas, useWebGPU) {let webglInitParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var params = Object.assign({}, InitParametersSetting, webglInitParams);
  params.canvas = canvas;

  var renderer;
  if (useWebGPU) {
    renderer = new Renderer(params);
  } else {
    renderer = new WebGLRenderer(params);
  }

  // Simplify debugging
  renderer.name = 'MainRenderer';

  if (!renderer.context)
  return null;

  renderer.autoClear = false;

  //Turn off scene sorting by THREE -- this is ok if we
  //do progressive draw in an order that makes sense
  //transparency-wise. If we start drawing using a frustum culling
  //r-tree or there are problems with transparency we'd have to turn on sorting.
  renderer.sortObjects = false;

  return renderer;
}

/**
* @constructor
* @private
* */
export function Viewer3DImpl(thecanvas, theapi) {
  var _this = this;
  this.setGlobalManager(theapi.globalManager);

  var _currentLightPreset = -1;
  var _oldLightPreset = -1;
  var _3dLightPreset = -1; // LMV-5655: Keeps track of the last 3d light preset
  var _oldCallback = null;

  var _worldUp;
  var _worldUpName = "y";

  var _reqid,_timeoutid = null,_needsResize,_newWidth,_newHeight,_materials;
  var _webglrender, _renderer;

  var _shadowMaps;

  // Default direction in world-space from which we get the most light from. Needed for shadow casting.
  // The default is only used if no direction is specified by light preset or model.
  var _shadowLightDirDefault = null; // {THREE.Vector3}
  var _shadowLightDir = null; //

  var _lightDirDefault = null;

  var _needsClear = false,
    _needsRender = false,
    _overlayDirty = false;
  //var _spectorDump = false;

  var _progressEvent = { type: et.PROGRESS_UPDATE_EVENT, state: ProgressState.LOADING, percent: 0 };

  var _sceneDirty = false;

  // A "silent render" means to do a full, but interruptible, render in the background. Display the result on completion.
  // The idea is to make a good-quality render after a progressive render occurs, or after some new content has been loaded,
  // or some other situation where we don't want to "lose progress," that is, we don't want to do a progressive render but
  // rather want to add to or modify an existing render on the screen.
  var _deferredSilentRender = false;
  var _immediateSilentRender = false;

  var _cameraUpdated;

  var _isLoading = true; // turned off in onLoadComplete()

  var _groundShadow, _groundReflection;

  var _envMapBackground = false;

  var _modelQueue;

  var _lightsInitialized = false;
  var _defaultLightIntensity = 1.0;
  var _defaultDirLightColor = null; // {THREE.Color}
  var _defaultAmbientColor = null; //

  var _window = getGlobal();

  // apply separate edge color/opacity
  this.edgeColorMain = EDGE_COLOR_DARK;
  this.edgeColorGhosted = EDGE_COLOR_LIGHT_GHOSTED;

  // render command system
  var _rcs;

  //OTG geom cache
  var _geomCache;
  var _memTracker;

  var _onModelRootLoaded = (e) => e.model.modelRootLoaded = true;

  // keys: name of cutplane set. values: array of cutplanes
  var _cutPlaneSets = {};

  // 2D rendering onto a cutplane can only be adjusted for a single cutplane. This key defines for which one:
  // The first cutplane in this cutPlaneSet.
  var _cutPlaneSetFor2DRendering = "";

  // we assume the program starts in a "doing work" state
  var _workPreviousTick = true;
  var _workThisTick;

  var _useWebGPU = false;
  this._skipRenderLoop = false;

  this.api = theapi;
  this.canvas = thecanvas;
  this.loader = null;
  this.canvasBoundingclientRectDirty = true;

  this.nearRadius = 0;

  //Slower initialization pieces can be delayed until after
  //we start loading data, so they are separated out here.
  this.initialize = function () {let initOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    _worldUp = new THREE.Vector3(0, 1, 0);
    _modelQueue = new RenderScene();

    this.headless = isNodeJS() || initOptions.headless;

    //Check whether WebGPU renderer is to be used. This reads the local storage key directly
    //because the initialization happens too early in the startup sequenece, before the viewer Preferences
    //object is initialized.
    let webgpuEnable = this.api.prefs.webStorage("webgpuEnable");
    webgpuEnable = initOptions.webgpuEnable || webgpuEnable;
    _useWebGPU = webgpuEnable && !!navigator?.gpu;

    if (_useWebGPU) {
      // TODO: Pretty hacky. We should look into a smarter way to solve these cases if they start aggregating.
      Autodesk.Viewing.ScreenShot.getScreenShot = ScreenShot.getScreenShot = ScreenShotWebGPU.getScreenShot;
    }

    _webglrender = initOptions.glrenderer || (this.headless ? null : createRenderer(thecanvas, _useWebGPU, initOptions.webglInitParams));

    _memTracker = new GPUMemoryTracker(false, false);

    if (!_webglrender && !this.headless) {
      return;
    }

    if (_webglrender) {
      if (!_useWebGPU) {
        this.onGraphicsContextLost = this.onGraphicsContextLost.bind(this);
        this.onWebGLcontextRestored = this.onWebGLcontextRestored.bind(this);
        _webglrender.addEventListener(WebGLRenderer.Events.WEBGL_CONTEXT_LOST, this.onGraphicsContextLost);
        _webglrender.refCount++;

        // Optional: Allow viewer to resurrect after webgl context restore
        if (initOptions.enableContextRestore) {
          _webglrender.enableContextRestore = true;
          _webglrender.addEventListener(WebGLRenderer.Events.WEBGL_CONTEXT_RESTORED, this.onWebGLcontextRestored);
        }
      } else {
        this.onGraphicsContextLost = this.onGraphicsContextLost.bind(this);
        _webglrender.addEventListener(Renderer.Events.WEBGPU_DEVICE_LOST, this.onGraphicsContextLost);
        _webglrender.addEventListener(Renderer.Events.WEBGPU_INIT_FAILED, this.onWebGPUInitFailed.bind(this));
        _webglrender.addEventListener(Renderer.Events.WEBGPU_RENDER_DONE, () => {_this._skipRenderLoop = false;});

        _webglrender.addEventListener(Renderer.Events.WEBGPU_INIT_DONE, () => {
          _materials.initLineStyleTexture();
        });
      }
    }

    _renderer = initOptions.renderer || (_useWebGPU ? new RenderContextWebGPU() : new RenderContext());
    _renderer.init(_webglrender, thecanvas ? thecanvas.clientWidth : 0, thecanvas ? thecanvas.clientHeight : 0, initOptions);

    _materials = initOptions.materialManager || new MaterialManager(_webglrender, _renderer);

    _materials.refCount++;

    this.camera = new UnifiedCamera(thecanvas ? thecanvas.clientWidth : 512, thecanvas ? thecanvas.clientHeight : 512);
    this.lightsOn = false;
    // we'll fill this in later, in initLights.
    this.lights = [];
    // pass in when lightsOn is false;
    this.no_lights = [];

    _defaultDirLightColor = new THREE.Color().setRGB(1, 1, 1);
    _defaultAmbientColor = new THREE.Color().setRGB(1, 1, 1);

    // this.camera = this.unicam.getOrthographicCamera();
    this.cameraChangedEvent = { type: et.CAMERA_CHANGE_EVENT, camera: this.camera };
    this.finalFrameEventYes = { type: et.FINAL_FRAME_RENDERED_CHANGED_EVENT, value: { finalFrame: true } };
    this.finalFrameEventNo = { type: et.FINAL_FRAME_RENDERED_CHANGED_EVENT, value: { finalFrame: false } };


    _shadowLightDirDefault = new THREE.Vector3(1, 1, 1); // which does not match the _lightDirDefault
    _shadowLightDir = new THREE.Vector3().copy(_shadowLightDirDefault);
    _lightDirDefault = new THREE.Vector3(-1, 0, 1); // a horizontal light, which is not a good default shadowd direction

    //This scene will just hold the camera and lights, while
    //we keep groups of progressively rendered geometry in
    //separate geometry scenes.
    this.scene = new THREE.Scene();
    this.sceneAfter = new THREE.Scene();
    this.sceneAfter.sortObjects = false;

    this.overlayScenes = {};

    setupSelectionHighlight();

    // No override materials for the scene for selected transparent objects, instead we overwrite the material for the duplicated geometry in this.highlightFragment()
    // this.selectionMaterialBase is the visible base highlight.
    // this.selectionMaterialTop draws over everything.
    this.createOverlayScene("selection", this.selectionMaterialBase, this.selectionMaterialTop);

    // no override materials for the scene for selected point clouds, because it overwrites the point size setting.
    // instead we overwrite the material for the duplicated geometry in this.highlightFragment()
    this.createOverlayScene("selection_points", null, null);

    this.selectionMeshes = {};

    //NOTE: Negative opacity flags the material as the ghosting material. This flag is used as a boolean by the shader
    //to decide whether to perform color theming.
    this.fadeMaterial = new THREE.MeshPhongMaterial({ color: 0xffffff, opacity: -0.1, reflectivity: 0, transparent: true, depthWrite: false });
    this.fadeMaterial.packedNormals = true;
    _materials.addMaterial("__fadeMaterial__", this.fadeMaterial, true);

    this.setSelectionColor(0x6699ff);

    //Polygon offset is always used.
    _materials.togglePolygonOffset(true);

    //Settings exposed to GUI:
    this.progressiveRender = true;
    this.swapBlackAndWhite = false;

    this.controls = {
      update: function () {
        this.camera.lookAt(this.camera.target);
        this.camera.updateProjectionMatrix();
        this.camera.dirty = false;
        return false;
      },
      handleResize: function () {},
      recordHomeView: function () {},
      uninitialize: function () {},
      isToolActivated: function () {return false;}
    };

    this.selector = new MultiModelSelector(this);

    this.visibilityManager = new MultiModelVisibilityManager(this);

    this.showGhosting = true;
    this.showOverlaysWhileMoving = true;
    this.skipAOWhenMoving = false;

    this.zoomBoundsChanged = true;

    var cc = LightPresets[DefaultLightPreset].bgColorGradient;
    this.setClearColors(cc[0], cc[1], cc[2], cc[3], cc[4], cc[5]);

    if (_useWebGPU) {
      _groundShadow = _webglrender.getGroundShadowPass();
    } else {
      _groundShadow = new GroundShadow(_webglrender);
    }
    _groundShadow.enabled = true;

    _rcs = new RenderCommandSystem(_this);

    // TODO_NOP: hack register materials for cutplanes
    if (!_useWebGPU) {
      _materials.addMaterialNonHDR("groundShadowDepthMaterial", _groundShadow.getDepthMaterial());
      _materials.addOverrideMaterial("normalsMaterial", _renderer.getDepthMaterial());
      _materials.addOverrideMaterial("edgeMaterial", _renderer.getEdgeMaterial());
    }

    //just meant to do an initial clear to the background color we want.
    _renderer.beginScene(this.scene, this.camera, this.noLights, true);

    _renderer.composeFinalFrame(true, false);
    this.api.addEventListener(et.MODEL_ROOT_LOADED_EVENT, _onModelRootLoaded);
  };

  this.get2DModels = function () {
    return _modelQueue.getModels().filter((m) => m.is2d());
  };

  this.get3DModels = function () {
    return _modelQueue.getModels().filter((m) => m.is3d());
  };

  function updateCanvasSize(noEvent) {
    if (_needsResize) {
      _this.canvasBoundingclientRectDirty = true;
      _this.camera.aspect = _newWidth / _newHeight;
      _this.camera.clientWidth = _newWidth;
      _this.camera.clientHeight = _newHeight;
      _renderer.setSize(_newWidth, _newHeight);
      _this.controls.handleResize();
      if (_groundReflection)
      _groundReflection.setSize(_newWidth, _newHeight);
      _this.invalidate(true, true, true);
      _needsResize = false;
      if (!noEvent) {
        _this.api.dispatchEvent({
          type: et.VIEWER_RESIZE_EVENT,
          width: _newWidth,
          height: _newHeight
        });
      }
    }
  }

  this.renderGroundShadow = function (target) {

    // If shadow maps are active, we don't use _groundShadow for the ground. Instead, the ground is
    // rendered using the shadow map as well.
    if (_shadowMaps) {
      if (_shadowMaps.state == shadow.SHADOWMAP_VALID) {
        _shadowMaps.renderGroundShadow(_this.camera, target || _renderer.getColorTarget());
      }
    } else {
      _groundShadow.renderShadow(_this.camera, target || _renderer.getColorTarget());
    }
  };

  // Set any information needed for the ground plane reflection, drop shadow, or shadow map projection
  function updateGroundTransform() {
    // if we're not using the ground shadow or reflection, or it's a 2D drawing, return
    if (!_groundShadow.enabled && !_groundReflection || _this.is2d)
    return;

    // Get the box of all the scene's data
    var groundBox;
    if (_this.model && !_this.model.isLoadDone()) {
      groundBox = _this.model.getData().bbox;
    } else
    {
      groundBox = _this.getVisibleBounds(true, false);
    }
    // If there's nothing to see, return
    if (!groundBox)
    return;

    var camera = _this.camera;
    var bbox = groundBox.clone();

    var rightAxis = new THREE.Vector3(1, 0, 0);

    var shadowDir = _shadowLightDir.clone();

    // Transform bbox, rightAxis, and shadowDir using worldUpTransform. For the resulting box, we
    // can safely assume that y is the up-direction
    if (camera.worldUpTransform) {
      bbox.applyMatrix4(camera.worldUpTransform);
      rightAxis.applyMatrix4(camera.worldUpTransform);
      shadowDir.applyMatrix4(camera.worldUpTransform);
    }

    // expand the box downwards by 0.5%. The effect of this is just that the
    // ground plane does not touch the world box, but is slightly below it
    bbox.min.y -= 0.005 * (bbox.max.y - bbox.min.y);

    if (_shadowMaps) {
      _shadowMaps.expandByGroundShadow(bbox, shadowDir);
    }

    // get size and center
    var bsize = bbox.getSize(new THREE.Vector3());
    var bcenter = bbox.getCenter(new THREE.Vector3());

    // apply some adjustments specific for drop-shadow
    if (!_shadowMaps) {
      // add some horizontal margin so that blurring is not clipped at the boundaries
      bsize.x *= 1.25;
      bsize.z *= 1.25;

      // expand to square, because the texture is squared as well
      bsize.x = bsize.z = Math.max(bsize.x, bsize.z);
    }

    // Rotate center back to world-coords.
    if (camera.worldUpTransform) {
      var worldUpInverse = camera.worldUpTransform.clone().invert();
      bcenter.applyMatrix4(worldUpInverse);

      // Note that we leave size vector as it is. I.e., only the center is transformed back to world-coords.
      // The size vector keeps as it is, i.e. the bbox defined by (center, size) is still aligned with
      // the rotated axes. In other worlds
      //  - size.x is the extent along worldUpTransform * (1,0,0) = rightAxis
      //  - size.y is the extent along worldUpTransform * (0,1,0) = camera.worldUp
      //  - size.z is the extent along worldUpTransform * (0,0,1)
    }

    _groundShadow.setTransform(
      bcenter,
      bsize,
      camera.worldup,
      rightAxis
    );

    if (_groundReflection) {
      var groundPos = new THREE.Vector3().subVectors(bcenter, camera.worldup.clone().multiplyScalar(bsize.y / 2));
      _groundReflection.setTransform(groundPos, camera.worldup, bsize);
    }

    if (_shadowMaps) {
      _shadowMaps.setGroundShadowTransform(bcenter, bsize, camera.worldup, rightAxis);
    }
  }

  function updateScene() {
    if (_sceneDirty) {
      // If the model had changed, the ground-plane, etc., may have changed, so recompute
      updateGroundTransform();
      _groundShadow.setDirty();
      _sceneDirty = false;
      return true;
    } else {
      return false;
    }
  }

  function updateOverlays() {

    //Update the selection set cloned meshes
    for (var id in _this.selectionMeshes) {

      var m = _this.selectionMeshes[id];
      if (m.model) {
        var fragList = m.model.getFragmentList();

        // If the proxy uses original geometry of the fragment, update its matrix.
        // If the geometry does not match, it is a consolidated or instanced mesh.
        // For these, the matrix is already baked into vertex buffer or
        // index buffer. We don't support animation for these.
        if (m.geometry === fragList.getGeometry(m.fragId)) {
          fragList.getWorldMatrix(m.fragId, m.matrix);
        }
      }
    }

  }

  function invalidateShadowMap() {
    if (_shadowMaps) {
      _shadowMaps.state = shadow.SHADOWMAP_NEEDS_UPDATE;
    }
  }


  //Main animation loop -- update camera,
  //advance animations, render if needed.
  this.tick = function (highResTimeStamp) {
    // tick() does three main operations:
    // 1. Determine if anything has changed that would trigger a new render.
    // 2. If a new render of any sort is needed, set the command system to do it.
    // 3. Check if there is a command set to run, and if so, run it.

    // We wait for the first progressive frame to be done before we continue rendering.
    if (this._skipRenderLoop) {
      return;
    }

    /**
     * We're using performance.now instead of the provided time stamp, because the time stamp that is passed into
     * animation frame (AF) callbacks is NOT the current time. According to the spec, it's the time at the
     * beginning of the browser's AF. AF callbacks (e.g. this method) may be invoked at ANY time during that AF
     * (usually at least a few ms later than the provided time stamp). So using the current time is more accurate.
     * In addition to that, the provided time stamp will always be a multiple of the AF time, e.g. a multiple of
     * 16.6 ms if requestAnimationFrame would trigger 60 times per second. So we would always get deltas of 16.6,
     * 33.3, 50 ms, etc. when using the provided time stamp to determine the time between two invocations of this
     * function, while the actual time might be different.
     * For example, this method could be invoked at the beginning of AF 0 (time stamp = now = 0) and run for 20 ms
     * (so it would take the entire time of AF 0 and 3.4 ms in AF 1). It's possible that 'tick' gets invoked again
     * in AF 1 if the previous call finished early enough during that AF. So the second tick could start at a
     * performance.now value of 30 (while the time stamp would be 16.6). Using the time stamp as the 'execution
     * started' value would result in wrong execution time deltas.
     * Note: When using the original highResTimeStamp value for some calculations in the future, remember that it
     * might be zero sometimes, so use as highResTimeStamp || 0.
    **/
    highResTimeStamp = performance.now();

    _rcs.highResTimeStamp = highResTimeStamp;
    _webglrender.updateTimestamp(highResTimeStamp);

    ///////////////////////////////////////////////
    // Determine if anything has changed that would cause a new render to be performed

    // Texture uploads of newly received textures;
    // Any texture change causes a full redraw.
    let res = _materials.updateMaterials();
    _this.invalidate(res.needsClear, res.needsRender, res.overlayDirty);

    // update controls (e.g. view cube, home button, orbit, key press) and see if that has affected the view
    let controlsMoved = _this.controls.update(highResTimeStamp);

    // see if more data was loaded.
    let sceneChanged = _modelQueue && _modelQueue.update(highResTimeStamp);

    let moved = controlsMoved || _cameraUpdated || sceneChanged;
    // reset and record state of this frame
    _cameraUpdated = false;

    // Did the window resize since last tick?
    let cameraChanged = moved || _needsResize;

    // checks _needsResize to see if an update is needed.
    updateCanvasSize();

    _needsClear = _needsClear || moved;
    _overlayDirty = _overlayDirty || moved;
    //var needsPresent = false;

    let rollover = false;
    let highlightIntensityChanged = _renderer.overlayUpdate();

    if (_overlayDirty) {
      // Update the selection set cloned meshes (does no rendering, yet)
      updateOverlays(highResTimeStamp);
    } else {
      // If the overlay is not dirty, fade in the overlay update over time (rollover highlighting becomes stronger).
      // If the value changes, the _blendPass needs to be redone - the overlay itself did not change, so
      // does not need to be re-rendered.
      if (highlightIntensityChanged && !_overlayDirty) {
        // special case where all that is needed is the rollover highlight blend pass
        _overlayDirty = rollover = true;
      }
      //needsPresent = _renderer.overlayUpdate();
    }

    let loadingIsIdle = !_geomCache || _geomCache.isIdle();
    _rcs.signalProgressByRendering = loadingIsIdle;

    // Has the geometry changed since the last frame?
    // Note this is not the same as just the camera moving, it indicates
    // that meshes have changed position, e.g., during explode, animation, etc.
    // The main effect is that the ground plane and shadow bounds may have changed, so adjust their location and bounds.
    if (updateScene()) {
      // if the scene was indeed dirty, we'll need to render from the start
      _needsClear = true;
    }

    // If _needsClear is false at this point, nothing changed from outside. However, we might still
    // have to set _needsClear to true if the previous frame cannot be resumed. This happens when
    // when we rendered some transparent shapes before all opaque ones were rendered.
    let isFreshFrame = _needsClear;
    let lastFrameValid = _modelQueue.frameResumePossible();
    _needsClear = _needsClear || !lastFrameValid;

    ///////////////////////////////////////////////
    // If a new render of any sort is needed, set the command system to do it.
    //
    // Store parameters that should not change on successive ticks, but rather control function.
    //
    // Add Command related params:
    // CMD_ALWAYS_DO - always do, no matter what. Executed every tick.
    // CMD_DO_AFTER - used in the command loop; if a command times out, any commands immediately after the timeout will be
    //              executed. This then makes progressive rendering possible: we render, timeout, and the next command(s) such as blend and present will be done.
    //              If executed, it will be executed again later when we get the next tick.
    // CMD_NORMAL_SEQUENCE - execute until done, don't come back to it once it's fully executed in the command list.

    // Is there anything at all that triggers a rerender?
    // if this is an immediate silent render, go do it. Else, check if we're still rendering; if not, then a deferred silent render can launch.
    _immediateSilentRender = _immediateSilentRender || _deferredSilentRender && !_rcs.cmdListActive;
    if (_needsClear || _needsRender || _overlayDirty || _immediateSilentRender) {

      // For rendering purposes, rcs.drawOverlay is set true whenever any (new) overlay dirty is noticed during progressive rendering.
      // We also need to redraw the overlays if anything triggers a main scene rendering as changed depth values have to be taken into account in the overlays.
      _rcs.drawOverlay = _overlayDirty || _needsClear || _needsRender;

      // uncomment all code with _spectorDump in order to have Spector.js dump a frame when "u" (update) is clicked
      /*
      // This version is for Chrome and Firefox's extension.
      if ( _spectorDump ) {
          _spectorDump = false;
          if ( spector ) {
              spector.clearMarker();
              spector.captureNextFrame(_this.canvas);
          }
      }
      */
      /*
      // This version is for Internet Explorer, which does not support an extension. You must also uncomment the Spector code in Viewer3D.js.
      if (_spectorDump) {
          _spectorDump = false;
          /*
          // use this and put a break on the jsonInString to grab the capture as text, for compare
          // (this is a bug in Spector that should be fixed someday - right now IE doesn't allow storing the session)
          window.spector.onCapture.add(function(capture) {
              var jsonInString = JSON.stringify(capture);
              // optional, doesn't really work: console.log(jsonInString);
          });
          window.spector.startCapture(_this.canvas);
      }
      */

      // restart rendering?
      if (_needsClear || _needsRender || _immediateSilentRender) {

        // Option to skip automatic camera update - can be used when a specific camera near-far values are desired.
        if (!_this.skipCameraUpdate) {
          _this.updateCameraMatrices();
        }

        if (_useWebGPU && _this.progressiveRender && !_immediateSilentRender) {
          // When rendering the first progressive frame with WebGPU, we wait for the frame to be done before
          // re-entering the render loop. This avoids input lag while navigating.
          this._skipRenderLoop = true;
        }

        _rcs.setUpFrame(_needsClear, _needsRender, _this.progressiveRender);

        if (_immediateSilentRender) {
          _needsClear = true;
        }
        if (_needsClear) {
          // Looks like silentRender flag should only be reset when a clear happened
          _deferredSilentRender = _immediateSilentRender = false;
        }

        if (ENABLE_DEBUG) {console.log(" COMMAND CREATION: clearing: " + _needsClear + ", rendering: " + _needsRender);}

        // Set up commands for the entire sequence of possible render states. The most important thing here is to not overthink it [Oh, the irony -- TS].
        // Each command gets executed. If it runs out of time, it returns "true". On the next tick command processing will continue
        // at the same command (it's up to the command itself to keep track of where it left off). The tricky part is if a command
        // needs to be run after renders every tick, "CMD_DO_AFTER", e.g. draw overlays and present when progressive rendering is on.

        // Otherwise, just lay out the worst-case scenario for drawing the scene, "if this didn't finish here, early on, do the rest
        // later". This happens with ground reflections, for example. There's some logic in the commands themselves that check if it's
        // the first tick, for example, or if it's a progressive tick or a full-render tick.

        // Ground shadow is computed separately, if needed, so check if the feature is in use at all.
        // It is if the flag is on, it's not 2D, and we're not loading (if we are loading, the ground shadow will change
        // anyway, so we don't render it then).
        let useGroundShadow = _groundShadow.enabled && !_this.is2d && !_isLoading;
        let useGroundReflection = !!_groundReflection && !_this.is2d && !_isLoading;

        // build a list to do the main full pass

        // Smooth Navigation: if it's on, and "moved" is happening, and AO is on, AO is temporarily turned off in the renderer.
        // We also note this status, and use a special CMD_DO_AFTER command to turn AO back on at the end of every command execution
        // (i.e., tick that this command set runs). This avoids headaches with some other system turning off AO in between ticks -
        // it can now safely do so, without the tick() turning it back on when execution is completed or aborted.
        let suppressAO = moved && _this.skipAOWhenMoving && _renderer.getAOEnabled();

        // -----------------------------------------------------------------------------
        // Start creation of a set of commands to execute over this and following ticks.
        _rcs.beginCommandSet();

        // Highlighting from the model browser needed?
        _rcs.addCommand(_rcs.cmdBeginScene);
        _rcs.setParam("BeginScene.clear", _needsClear);

        // for Smooth Navigation - turned on later by cmdRestoreAO as an CMD_ALWAYS_DO.
        // We let the clear above clear the SAO buffer, since if we're using smooth navigation
        // we know the SAO there will be invalid. This avoids the case where we're in a long
        // smooth-navigation render which gets interrupted by a "needs present" render (a rollover
        // highlight) which stops the full render we signalled for from completing.
        if (suppressAO) {
          _rcs.addCommand(_rcs.cmdSuppressAO, CMD_ALWAYS_DO);
        }

        // is there any geometry to render?
        if (_modelQueue) {

          // is shadow map needed? Generate only if not progressive.
          if (_shadowMaps && _shadowMaps.state !== shadow.SHADOWMAP_VALID) {
            _rcs.addCommand(_rcs.cmdUpdateShadowMap);
          }

          // is ground shadow computed at this point? If not, and this is a full
          // render, or this is a progressive render and it looks likely to finish,
          // draw it.
          if (useGroundShadow) {
            _rcs.addCommand(_rcs.cmdGenerateGroundShadow);
            _rcs.setParam("GenerateGroundShadow.afterBeauty", false);
            _rcs.setParam("GenerateGroundShadow.signalRedraw", false);
          }

          // if doing ground reflection, generate it now
          if (useGroundReflection) {
            // tell reflection system it needs to start from scratch once the commands start
            _groundReflection.setDirty();

            _rcs.addCommand(_rcs.cmdGenerateGroundReflection);
            _rcs.setParam("GenerateGroundReflection.afterBeauty", false);
          }
          // Blit ground shadow first, if in use and ground reflection not in use.
          // If ground reflection is in use, the shadow is composited with its target instead.
          // If we are truly not clearing, then don't blit ground shadow, as it was already
          // displayed in the previous frame (possibly incorrect for this frame, but the user
          // asked to have no clear, so...). See LMV-2571
          else if (useGroundShadow && _needsClear) {
            _rcs.addCommand(_rcs.cmdBlitGroundShadow);
          }

          // pass to render any objects that use simple highlighting mode (not normally used nowadays)
          if (_modelQueue.hasHighlighted()) {

            _rcs.scheduleMainPass(RenderFlags.RENDER_HIGHLIGHTED);

          }

          // main scene draw pass
          {
            _rcs.scheduleMainPass(RenderFlags.RENDER_NORMAL);
          }

          // ghosting pass is done after the ground reflection is generated and merged, as it
          // draws transparent atop all.
          if (!_modelQueue.areAllVisible() && _this.showGhosting) {

            // if we are progressive rendering, and are generating ground reflections, we do ghosting
            // after the ground reflection is done. Else, do it now, as part of the full render, since
            // we know everything's done.
            // TODO I can imagine changing this logic - seems like we should have just one "ghosting
            // after everything" bit of code insertion. The reason there is a split is that for full
            // rendering we know the ground reflection is done at this point and can simply render atop,
            // directly. For progressive rendering we need to wait for the reflection to finish, blend it
            // in under, then ghost.
            if (!useGroundReflection || !_rcs.isProgressiveRender) {
              // show ghosting - highly transparent, so must be rendered last, atop everything else
              _rcs.scheduleMainPass(RenderFlags.RENDER_HIDDEN);
            }
          }

          // Render sceneAfter (sectioning, other permanent non-cosmetic overlays, etc),
          // TODO for progressive rendering, it seems like we should do this *after* any Present(), if
          // the buffers are not needed immediately. This command also notes rendering is done.
          _rcs.addCommand(_rcs.cmdSceneAfterRender);

          _rcs.addCommand(_rcs.cmdSignalProcessingDone);
        }

        // Overlay is always rendered. In this way if we *do* get an overlay dirty while progressive rendering,
        // the overlay will get updated.
        // This must be done after the passes above, because our global rule is "draw if z-depth matches"
        // and the z-depths must be established before the highlighted objects get drawn.
        // render them. Always do this for progressive rendering, even if we stop early, since these are important.
        _rcs.addCommand(_rcs.cmdRenderOverlays, CMD_DO_AFTER);

        // We always need a present, since we know we're doing something. Also antialiasing and whatever blending is needed.
        // Always do this for progressive rendering.
        _rcs.addCommand(_rcs.cmdPostAndPresent, CMD_DO_AFTER);
        _rcs.setParam("PostAndPresent.performAO", _renderer.getAOEnabled() && !suppressAO);
        _rcs.setParam("PostAndPresent.waitForDone", this._skipRenderLoop);

        // If this is a progressive render, make the last thing to happen the ground shadow, which if not done by now will trigger
        // a rerender once it is fully created.
        if (_rcs.isProgressiveRender && _modelQueue) {

          if (_shadowMaps && _shadowMaps.state !== shadow.SHADOWMAP_VALID) {
            // start shadow map generation from beginning
            _rcs.addCommand(_rcs.cmdResetShadowMap);
            _rcs.addCommand(_rcs.cmdUpdateShadowMap);
          }

          // Ground shadows are an entirely separate render, happening concurrently with the main renderer, and
          // done after the progressive render is performed, if not completed by then. The full render does it
          // as part of its rerender.

          // If we are done with progressive and the ground shadow is not done, do them now.
          if (useGroundShadow) {
            _rcs.addCommand(_rcs.cmdGenerateGroundShadow);
            _rcs.setParam("GenerateGroundShadow.afterBeauty", true);
            // don't signal a redraw if the ground reflection is about to be finished and merged, too.
            _rcs.setParam("GenerateGroundShadow.signalRedraw", !useGroundReflection);
            // TODO really need to fix progress meter, but at least we should show 100% done
            _rcs.addCommand(_rcs.cmdSignalProcessingDone);
          }

          // if the ground shadows and reflection are not done, do them now.
          if (useGroundReflection) {
            _rcs.groundShadowInPost = false;

            // Note that ground shadow is guaranteed to be done at this point, so will be merged in correctly.
            _rcs.addCommand(_rcs.cmdGenerateGroundReflection);
            _rcs.setParam("GenerateGroundReflection.afterBeauty", true);

            // ghosting is done after the ground reflection is generated and merged, as it
            // draws transparent atop all. Note that sectioning is already done.
            if (!_modelQueue.areAllVisible() && _this.showGhosting) {
              // show ghosting - highly transparent, so must be rendered last, atop everything else
              // TODO note that we don't do cmdSceneAfterRender here, though it might be nice to
              // show sectioning. I don't really understand, but if we do add it here, the ghosted objects
              // are drawn normally. I guess these objects need to be drawn again for sectioning?
              _rcs.scheduleMainPass(RenderFlags.RENDER_HIDDEN);
            }

            // if it's done, perform a present
            _rcs.addCommand(_rcs.cmdFinishAllRendering);
            _rcs.addCommand(_rcs.cmdPostAndPresent);
            _rcs.setParam("PostAndPresent.performAO", _renderer.getAOEnabled() && !suppressAO);
            _rcs.addCommand(_rcs.cmdSignalProcessingDone);
          }
        }

        // Smooth Navigation - if on, then we need to always turn the renderer back to AO at the end of any tick;
        // it will get turned back off the next tick by the renderer.
        if (suppressAO) {
          _rcs.addCommand(_rcs.cmdRestoreAO, CMD_ALWAYS_DO);
          // If we get to this command, we've done all we can during smooth navigation and should now signal for a full redraw
          // without smooth navigation. This works because "moved" should be false on the next tick (unless of course the
          // user moved the view) and so a full or progressive render will occurs with smooth navigation off.
          _rcs.addCommand(_rcs.cmdSignalRedraw);
        }

        _rcs.addCommand(_rcs.cmdFinishedFullRender);

        _rcs.endCommandSet();

        // if we reenter, by turning these off, we then will not rebuild the command list
        _needsClear = false;
        _needsRender = false;

      }
      ////////////////////////////////////////////////////////////////////////////

      // only case left is that overlay is dirty
      else {

        _rcs.scheduleOverlayOnlyUpdate(rollover);

      }
    }

    // Avoid having updateOverlays() called every tick during a progressive rendering by turning off the overlay dirty flag.
    // If we get a later overlayDirty, this will trigger updateOverlays() at the start of tick(), and will als cause the
    // cmdRenderOverlays to trigger during a progressive render.
    // Note that if we get an overlayDirty and rendering is occurring, _overlayDirty won't get cleared, which is good:
    // we want the command system to detect this and turn on overlay rendering at that point.
    _overlayDirty = false;

    //Fire the camera change event if needed, before doing the rendering (why before? no real reason that I know of)
    if (cameraChanged) {
      _this.api.dispatchEvent(_this.cameraChangedEvent);
    }

    ///////////////////////////////////////////////
    // Run the command list, if any. Note whether there's any work to do, so we can see if this state has changed and send an event.
    _workThisTick = _rcs.cmdListActive;

    _rcs.executeCommandList();

    ///////////////////////////////////////////////
    // Keep it simple: this tick either did rendering, or it did not. If this differs from last frame's state, signal.
    if (_workThisTick !== _workPreviousTick) {
      _this.api.dispatchEvent(_workThisTick ? this.finalFrameEventNo : this.finalFrameEventYes);
      // we're at the end of things, so the current state now becomes the "previous tick" state for testing next time.
      _workPreviousTick = _workThisTick;
    }

    /*
            if (_workThisTick) {
                console.log(this.fps());
            }
    */
  };

  this.run = function () {
    //Begin the render loop (but delay first repaint until the following frame, so that
    //data load gets kicked off as soon as possible
    _reqid = 0;
    _timeoutid = setTimeout(function () {
      _timeoutid = null;
      (function animloop(highResTimeStamp) {
        _reqid = _window.requestAnimationFrame(animloop);
        _this.tick(highResTimeStamp);
      })();
    }, 1);
  };

  this.stop = function () {
    if (_timeoutid !== null) {
      clearTimeout(_timeoutid);
    }
    _window.cancelAnimationFrame?.(_reqid);
  };

  this.waitForRendererIdle = async function () {

    if (!_workThisTick) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {

      let listener = (e) => {
        console.log(e);
        if (e.value.finalFrame) {
          this.api.removeEventListener(et.FINAL_FRAME_RENDERED_CHANGED_EVENT, listener);
          resolve();
        }
      };

      this.api.addEventListener(et.FINAL_FRAME_RENDERED_CHANGED_EVENT, listener);
    });
  };

  this.waitForLoaderIdle = async function () {

    if (_geomCache?.isIdle()) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {

      let listener = () => {
        this.api.removeEventListener(et.GEOMETRY_LOADED_EVENT, listener);
        resolve();
      };

      this.api.addEventListener(et.GEOMETRY_LOADED_EVENT, listener);
    });

  };

  this.toggleProgressive = function (value) {
    this.progressiveRender = value;
    _needsClear = true;
  };

  this.trackFrameBudget = function (value) {
    _rcs.trackFrameBudget = value;
  };

  // Apply current clear colors to renderer while considering swapBlackAndWhite flag when in 2D
  this.updateClearColors = function () {
    var clearColor = this.clearColorTop;

    // apply black/white swap to clear color if wanted
    if (this.is2d && this.swapBlackAndWhite) {
      var isWhite = clearColor.x === 1 && clearColor.y === 1 && clearColor.z === 1;
      var isBlack = clearColor.x === 0 && clearColor.y === 0 && clearColor.z === 0;
      if (isWhite) {
        clearColor = new THREE.Color(0, 0, 0);
      } else if (isBlack) {
        clearColor = new THREE.Color(1, 1, 1);
      }
    }

    _renderer.setClearColors(clearColor, this.clearColorBottom);
  };

  this.toggleSwapBlackAndWhite = function (value) {
    this.swapBlackAndWhite = value;
    this.updateClearColors();
    _needsClear = true;
  };

  this.toggleGrayscale = function (value) {
    _materials.setGrayscale(value);
    _needsClear = true;
  };

  this.toggleGhosting = function (value) {
    this.showGhosting = value;
    _needsClear = true;
  };

  this.toggleOverlaysWhileMoving = function (value) {
    this.showOverlaysWhileMoving = value;
  };

  this.togglePostProcess = function (useSAO, useFXAA) {
    _renderer.initPostPipeline(useSAO, useFXAA);
    this.fireRenderOptionChanged();
    _needsClear = true;
  };

  this.toggleCmdMapping = function (value, controller) {
    controller.keyMapCmd = value;
  };

  this.toggleGroundShadow = function (value) {
    if (_groundShadow.enabled === value)
    return;

    _groundShadow.enabled = value;
    _groundShadow.clear();
    if (value) {
      _groundShadow.setDirty();
    }
    // if we're turning on the ground shadow, we need to set up the ground plane
    updateGroundTransform();
    this.fireRenderOptionChanged();
    this.invalidate(true, false, false);
  };


  /**
   * Check if the ground shadows are enabled
   * @private
   */
  this.isGroundShadowEnabled = function () {
    return _groundShadow && _groundShadow.enabled;
  };

  this.groundShadow = function () {
    return _groundShadow;
  };

  this.setGroundShadowColor = function (color) {
    if (!_groundShadow.enabled) return;

    _groundShadow.setColor(color);
    this.invalidate(true, false, false);
  };

  this.setGroundShadowAlpha = function (alpha) {
    if (!_groundShadow.enabled) return;

    _groundShadow.setAlpha(alpha);
    this.invalidate(true, false, false);
  };

  this.groundReflection = function () {
    return _groundReflection;
  };

  this.toggleGroundReflection = function (enable) {

    //TODO: WEBGPU
    if (_useWebGPU) {
      _groundReflection = null;
      return;
    }

    if (enable && !!_groundReflection ||
    !enable && !_groundReflection)
    return;

    if (enable) {
      _groundReflection = new GroundReflection(_webglrender, this.canvas.clientWidth, this.canvas.clientHeight, { clearPass: _renderer.getClearPass() });
      _groundReflection.setClearColors(this.clearColorTop, this.clearColorBottom, isMobileDevice());
      _groundReflection.toggleEnvMapBackground(_envMapBackground);
      _groundReflection.setEnvRotation(_renderer.getEnvRotation());
      // if we're turning on the ground reflection, we need to set up the ground plane
      updateGroundTransform();
    } else
    {
      _groundReflection.cleanup();
      _groundReflection = undefined;
    }

    this.fireRenderOptionChanged();
    this.invalidate(true, false, false);
  };

  this.setGroundReflectionColor = function (color) {
    if (!_groundReflection) return;

    _groundReflection.setColor(color);
    this.invalidate(true, false, false);
  };

  this.setGroundReflectionAlpha = function (alpha) {
    if (!_groundReflection) return;

    _groundReflection.setAlpha(alpha);
    this.invalidate(true, false, false);
  };

  this.toggleEnvMapBackground = function (value) {
    _envMapBackground = value;
    _renderer.toggleEnvMapBackground(value);

    if (_groundReflection) {
      _groundReflection.toggleEnvMapBackground(value);
    }
    this.invalidate(true, true, false);
  };

  this.isEnvMapBackground = function () {
    return _envMapBackground;
  };

  this.setOptimizeNavigation = function (value) {
    this.skipAOWhenMoving = value;
  };

  this.renderOverlays = function () {

    //The overlays (selection, pivot, etc) get lighted using
    //the default lights, even if IBL is on
    var lightsOn = this.lightsOn;
    if (!lightsOn)
    this.toggleLights(true, true);

    var oldIntensity;
    if (this.dir_light1) {
      oldIntensity = this.dir_light1.intensity;
      this.dir_light1.intensity = 1;
    }

    _renderer.renderOverlays(this.overlayScenes, this.lightsOn ? this.lights : this.no_lights);

    if (!lightsOn)
    this.toggleLights(false, true);

    if (this.dir_light1)
    this.dir_light1.intensity = oldIntensity;
  };

  this.setNearRadius = function (radius, redraw) {
    if (this.nearRadius !== radius) {
      this.nearRadius = radius;
      if (redraw) {
        this.invalidate(true);
      }
    }
  };

  this.getNearRadius = function () {
    return this.nearRadius;
  };

  // Find model's bounds, including ground plane, if needed.
  // Fit near and far planes to the model.
  this.updateNearFarValues = function () {

    var tmpCameraMatrix;
    var tmpViewMatrix;
    var tmpBox;

    function init_three() {
      tmpCameraMatrix = new THREE.Matrix4();
      tmpViewMatrix = new THREE.Matrix4();
      tmpBox = new THREE.Box3();
    }

    return function (camera, worldBox) {

      if (worldBox.isEmpty()) {
        //logger.warn('Calculating near-far values based on empty worldBox (infinity) will result in incorrect values - Better to keep previous values instead.');
        return;
      }

      if (!tmpBox)
      init_three();

      //NOTE: This is not computing the same matrix as what we use for rendering,
      //in cases where we are in ORTHO mode and the camera is inside the model,
      //which would result in negative near plane. For the purposes of computing
      //the near/far planes, we have to skip the logic that adjusts the view matrix
      //based on the near/far planes. See UnifiedCamera.updateMatrix for the related
      //adjustment to the view matrix.
      tmpCameraMatrix.compose(camera.position, camera.quaternion, camera.scale);
      tmpViewMatrix.copy(tmpCameraMatrix).invert();

      tmpBox.copy(worldBox);

      //If reflection is on, then we need to double the worldBox size in the Y
      //direction, the reflection direction, otherwise the reflected view can be
      //clipped.
      if (_groundReflection) {
        // Increase bounding box to include ground reflection geometry. The idea
        // here is to extend the bounding box in the direction of reflection, based
        // on the "up" vector.
        var tmpVecReflect = new THREE.Vector3();
        tmpVecReflect.multiplyVectors(tmpBox.max, camera.worldup);
        var tmpVecMin = new THREE.Vector3();
        tmpVecMin.multiplyVectors(tmpBox.min, camera.worldup);
        tmpVecReflect.sub(tmpVecMin);
        // tmpVecReflect holds how much to increase the bounding box.
        // Negative values means the "up" vector is upside down along that axis,
        // so we increase the maximum bounds of the bounding box in this case.
        if (tmpVecReflect.x >= 0.0) {
          tmpBox.min.x -= tmpVecReflect.x;
        } else {
          tmpBox.max.x -= tmpVecReflect.x;
        }
        if (tmpVecReflect.y >= 0.0) {
          tmpBox.min.y -= tmpVecReflect.y;
        } else {
          tmpBox.max.y -= tmpVecReflect.y;
        }
        if (tmpVecReflect.z >= 0.0) {
          tmpBox.min.z -= tmpVecReflect.z;
        } else {
          tmpBox.max.z -= tmpVecReflect.z;
        }
      }

      // Expand the bbox based on ground shadow. Note that the horizontal extent of the ground shadow
      // may be significantly larger for flat shadow light directions.
      if (_shadowMaps && _shadowMaps.groundShapeBox) {
        tmpBox.union(_shadowMaps.groundShapeBox);
      }

      //Transform the world bounds to camera space
      //to estimate the near/far planes we need for this frame
      tmpBox.applyMatrix4(tmpViewMatrix);

      //Expand the range by a small amount to avoid clipping when
      //the object is perfectly aligned with the axes and has faces at its boundaries.
      var sz = 1e-5 * (tmpBox.max.z - tmpBox.min.z);

      //TODO: expand for ground shadow. This just matches what the
      //ground shadow needs, but we need a better way to take into account
      //the ground shadow scene's bounds
      var expand = (tmpBox.max.y - tmpBox.min.y) * 0.5;

      var dMin = -(tmpBox.max.z + sz) - expand;
      var dMax = -(tmpBox.min.z - sz) + expand;

      //Camera is inside the model?
      if (camera.isPerspective) {
        // If dMax / dMin is too large then we get z buffer fighting, which we would
        // like to avoid. In this case there are two alterniatives that we can do -
        // we can move the near plane away from the camera, or we can move the far
        // place toward the camera. If this.nearRadius is > 0, then we want to move
        // the far plane toward the camera.
        if (this.nearRadius > 0) {
          // dMin might be OK, or might be negative or close to 0. If it is so small
          // that depth precision will be bad, then we need to move it away from 0
          // but no further than this.nearRadius.
          dMin = Math.max(dMin, Math.min(this.nearRadius, Math.abs(dMax - dMin) * 1e-4));

          // If the max is still too far away, then move it closer
          dMax = Math.min(dMax, dMin * 1e4);
        } else {
          // dMin might be OK, or might be negative. If it's negative,
          // give it a value of 1/10,000 of the entire scene's size relative to this view direction,
          // or 1, whichever is *smaller*. It's just a heuristic.
          dMin = Math.max(dMin, Math.min(1, Math.abs(dMax - dMin) * 1e-4));

          if (dMax < 0) {
            // near and far planes should always be positive numbers for perspective
            dMax = 1e-4;
          }
          // One more attempt to improve the near plane: make it 1/100,000 of the distance of the
          // far plane, if that's higher.
          // See https://wiki.autodesk.com/display/LMVCORE/Z-Buffer+Fighting for reasoning.
          // 1e-4 is generally good below, but inside Silver Cross we get a lot of near clipping. So, 1e-5.
          dMin = Math.max(dMin, dMax * 1e-5);
        }

        // Correct near plane so it won't be farther than the actual distance to the world bounds.
        const boxDist = Math.sqrt(SceneMath.pointToBoxDistance2(camera.position, worldBox));
        // In case the that the camera is inside the bounds, boxDist will be 0. In that case let's set the minimum distance to 1.
        const minDistToBox = Math.max(1, boxDist);
        dMin = Math.min(dMin, minDistToBox);
      } else {






        //TODO:
        //Do nothing in case of ortho. While this "fixes" near plane clipping too aggressively,
        //it effectively disallows moving through walls to go inside the object.
        //So we may need some heuristic based on how big we want the object to be
        //on screen before we let it clip out.
        //dMin = Math.max(dMin, 0);
      } //The whole thing is behind us -- nothing will display anyway?
      dMax = Math.max(dMax, dMin);camera.near = dMin;camera.far = dMax;camera.updateProjectionMatrix();
      camera.updateMatrixWorld();
    };
  }();

  this.getPixelsPerUnit = function (camera, worldBox) {let model = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.model;
    var deviceHeight = _renderer.settings.deviceHeight; // = canvas height * pixelRatio

    //If there is a cutting plane, get a point on that plane
    //for by the pixel scale computation. (only used for 3D)
    var cutPlanes = _materials.getCutPlanesRaw();
    var cutPlane = cutPlanes[0];

    var modelBox = model ? model.getBoundingBox() : worldBox;

    return SceneMath.getPixelsPerUnit(camera, this.is2d, worldBox, deviceHeight, cutPlane, modelBox);
  };

  this.updateCameraMatrices = function () {
    const camera = this.camera;

    //TODO: Would be nice if this got called by the world up tool instead,
    //so that we don't have to update it every frame.
    if (camera.worldup)
    this.setWorldUp(camera.worldup);

    // Update near & far values according to the unified bounds of all the models in modelQueue.
    // There is a special case for underlay raster, where `this.model` is not set, but there is a temp model inside modelQueue - this
    // is why we update these values even if `this.model` is not set.
    const worldBox = this.getVisibleBounds(true, _overlayDirty);

    this.updateNearFarValues(camera, worldBox);

    // Update the line width scale with the new pixels per unit scale.
    const pixelsPerUnit = this.getPixelsPerUnit(camera, worldBox);
    const width = _renderer.settings.deviceWidth;
    const height = _renderer.settings.deviceHeight;
    if (this.is2d) {
      //If we want to take into account devicePixelRatio for line weights (so that lines are not too thin)
      //we can do this here, but it's less esthetically pleasing:
      //pixelsPerUnit /= _webglrenderer.getPixelRatio();

      // AutoCAD drawings are commonly displayed with white lines on a black background. Setting reverse swaps (just)
      // these two colors.
      _materials.updateSwapBlackAndWhite(this.swapBlackAndWhite);
    }

    _materials.updatePixelScale(pixelsPerUnit, width, height, camera);
    _useWebGPU && _webglrender.getMainPass().updatePixelScale(pixelsPerUnit, camera);

    // Set pixelsPerUnit according to each sheet in 3D space. In general, it is set according to the modelQueue's bounds
    // which is not related to how we want to present the sheet (i.e. the line thickness will vary when selecting a
    // floor as a result, because of the changing viewing volume)
    // Note: Previously this was done only when in 3D mode, but it's also needed in 2D in case a transform with
    // scaling is set.
    const models = this.get2DModels();
    models.forEach((model) => {
      const transform = model.getModelToViewerTransform();
      const scaling = transform ? transform.getMaxScaleOnAxis() : 1;
      if (!this.is2d || scaling !== 1) {
        const bounds = model.getVisibleBounds();
        // Sending is2d:true here because we want the calculation path done for 2D sheets
        const pixelsPerUnit = SceneMath.getPixelsPerUnit(camera, true, bounds, height, null, bounds);

        _materials.updatePixelScaleForModel(model, pixelsPerUnit, width, height, scaling, camera);
      }
    });
  };

  this.initLights = function () {
    if (_lightsInitialized) {
      return;
    }

    this.dir_light1 = new THREE.DirectionalLight(_defaultDirLightColor, _defaultLightIntensity);
    this.dir_light1.position.copy(_lightDirDefault);

    //Note this color will be overridden by various light presets
    this.amb_light = new THREE.AmbientLight(_defaultAmbientColor);

    // Set this list only once, so that we're not constantly creating and deleting arrays each frame.
    // See https://www.scirra.com/blog/76/how-to-write-low-garbage-real-time-javascript for why.
    // use this.no_lights empty array if no lights are needed.
    this.lights = [this.dir_light1, this.amb_light];

    //We do not add the lights to any scene, because we need to use them
    //in multiple scenes during progressive render.
    //this.scene.add(this.amb_light);

    // Attach the light to the camera, so that the light direction is applied in view-space.
    // Note:
    //
    //  1. For directional lights, the direction where the light comes from is determined by
    //     lightPosition - targetPosition, both in in world-space.
    //  2. The default target of dir lights is the world origin.
    //  3. Transforming the light object only affects the light position, but has no effect on the target.
    //
    // The goal is to rotate the lightDir with the camera, but keep it independent
    // of the camera position. Due to 3. above, we must also attach the light's target object to the camera.
    // Otherwise, the camera position would incorrectly be added to the light direction.
    this.camera.add(this.dir_light1);
    this.camera.add(this.dir_light1.target);

    _lightsInitialized = true;
  };

  var setLights = function (amb_light, dir_light1, state, isForOverlay) {
    //Update the light colors based on the current preset
    var preset = LightPresets[_currentLightPreset];
    var ac = preset && preset.ambientColor;
    var dc = preset && preset.directLightColor;

    ac = ac || _defaultAmbientColor.toArray();
    dc = dc || _defaultDirLightColor.toArray();

    if (state) {
      if (isForOverlay && amb_light)
      amb_light.color.setRGB(dc[0] * 0.5, dc[1] * 0.5, dc[2] * 0.5);else
      if (amb_light) {
        amb_light.color.setRGB(ac[0], ac[1], ac[2]);
      }

      if (dir_light1) {
        dir_light1.color.setRGB(dc[0], dc[1], dc[2]);
      }
    } else
    {
      //Restores the ambient for the main scene after drawing overlays
      if (amb_light && isForOverlay)
      amb_light.color.setRGB(ac[0], ac[1], ac[2]);
    }
  };


  this.toggleLights = function (state, isForOverlay) {

    //This can happen during initial construction
    if (!this.amb_light)
    return;

    // Don't create or remove arrays, as that's bad to do during rendering.
    // Instead, later use lightsOn to decide which array to use.
    this.lightsOn = state;

    setLights(this.amb_light, this.dir_light1, state, isForOverlay);
  };

  //Forces the view controller to update when the camera
  //changes programmatically (instead of via mouse events).
  this.syncCamera = function (syncWorldUp) {
    this.camera.updateCameraMatrices();

    if (syncWorldUp)
    this.setWorldUp(this.api.navigation.getWorldUpVector());

    _cameraUpdated = true;
  };

  /**
   * Get the model's initial camera
   * @param {Model} model
   * @returns camera
   */
  this.getModelCamera = function (model) {
    if (!model) return;
    let camera;
    const defaultCamera = model.getDefaultCamera();
    if (defaultCamera) {
      camera = defaultCamera;
    } else {
      //Model has no default view. Make one up based on the bounding box.
      camera = UnifiedCamera.getViewParamsFromBox(
        model.getBoundingBox(),
        model.is2d(),
        this.camera.aspect,
        this.camera.up,
        this.camera.fov
      );
    }
    return camera;
  };


  this.setViewFromFile = function (model, skipTransition) {

    if (!model) {
      return;
    }

    var camera = this.getModelCamera(model);

    this.setViewFromCamera(camera, skipTransition, false);
  };

  //Camera is expected to have the properties of a THREE.Camera.
  this.adjustOrthoCamera = function (camera) {
    var bbox = this.getVisibleBounds();
    UnifiedCamera.adjustOrthoCamera(camera, bbox);
  };

  /**
   * Switches to a new view based on a given camera. If the current orbiting mode is constrained,
   * the up vector may be adjusted.
   *
   * @param {THREE.Camera} camera Input camera.
   * @param {boolean} skipTransition Switch to the view immediately instead of transitioning.
   * @param {boolean} useExactCamera -- whether any up vector adjustment is to be done (to keep head up view)
   */
  this.setViewFromCamera = function (camera, skipTransition, useExactCamera) {
    this.adjustOrthoCamera(camera);

    // Choose the first up-vector we get from the main model.
    // We assume all models have identical up vectors - otherwise, the aggregated model would be weird anyway.
    var upVectorArray = this.model ? this.model.getUpVector() : null;

    var worldUp;
    if (upVectorArray) {
      worldUp = new THREE.Vector3().fromArray(upVectorArray);
    } else {
      worldUp = useExactCamera ? camera.up.clone() : Navigation.snapToAxis(camera.up.clone());
    }

    if (!useExactCamera) {
      camera.up = worldUp;
    }

    var navapi = this.api.navigation;
    if (navapi) {

      var tc = this.camera;

      if (!skipTransition) {
        tc.isPerspective = camera.isPerspective;

        if (!camera.isPerspective) {
          tc.saveFov = camera.fov; // Stash original fov
          camera.fov = UnifiedCamera.ORTHO_FOV;
        }

        if (useExactCamera) {
          navapi.setRequestTransitionWithUp(true, camera.position, camera.target, camera.fov, camera.up, worldUp);
        } else {

          // Fix camera's target if it is not inside the scene's bounding box.
          var bbox = this.getVisibleBounds();
          if (!bbox.containsPoint(camera.target)) {
            var distanceFromCenter = bbox.getCenter(new THREE.Vector3()).distanceTo(camera.position);
            var direction = camera.target.clone().sub(camera.position).normalize().multiplyScalar(distanceFromCenter);
            camera.target.copy(camera.position.clone().add(direction));
          }

          var up = navapi.computeOrthogonalUp(camera.position, camera.target);
          navapi.setRequestTransitionWithUp(true, camera.position, camera.target, camera.fov, up, worldUp);
        }
      } else {
        //This code path used during initial load -- it sets the view directly
        //without doing a transition. Transitions require that the camera is set explicitly

        tc.up.copy(camera.up);
        tc.position.copy(camera.position);
        tc.target.copy(camera.target);
        if (camera.isPerspective) {
          tc.fov = camera.fov;
        } else
        {
          tc.saveFov = camera.fov; // Stash original fov
          tc.fov = UnifiedCamera.ORTHO_FOV;
        }
        tc.isPerspective = camera.isPerspective;
        tc.orthoScale = camera.orthoScale;
        tc.dirty = true;

        navapi.setWorldUpVector(useExactCamera ? worldUp : tc.up);
        navapi.setView(tc.position, tc.target);
        navapi.setPivotPoint(tc.target);

        this.syncCamera(true);
      }
    }
    _cameraUpdated = true;
  };

  this.setWorldUp = function (upVector) {

    if (_worldUp.equals(upVector))
    return;

    _worldUp.copy(upVector);

    // get the (max) up axis and sign
    var maxVal = Math.abs(upVector.x);
    _worldUpName = "x";
    if (Math.abs(upVector.y) > maxVal) {
      _worldUpName = "y";
      maxVal = Math.abs(upVector.y);
    }
    if (Math.abs(upVector.z) > maxVal) {
      _worldUpName = "z";
    }

    var getRotation = function (vFrom, vTo) {
      var rotAxis = new THREE.Vector3().crossVectors(vTo, vFrom).normalize(); // not sure why this is backwards
      var rotAngle = Math.acos(vFrom.dot(vTo));
      return new THREE.Matrix4().makeRotationAxis(rotAxis, rotAngle);
    };

    var identityUp = new THREE.Vector3(0, 1, 0);
    _this.camera.worldUpTransform = getRotation(identityUp, upVector);

    this.sceneUpdated(false);
  };

  this.addModel = function (model, preserveTools) {
    if (!model)
    return;

    //Is it the first model being loaded into the scene?
    var isOverlay = !!this.model;

    if (!this.model) {
      this.model = model;

      _renderer.setUnitScale(model.getUnitScale());
    }

    //Create a render list for progressive rendering of the
    //scene fragments
    _modelQueue.addModel(model);
    this.selector.addModel(model);
    this.visibilityManager.addModel(model);
    _webglrender?.addModel(model);

    this._setModelPreferences(model);

    if (_3dLightPreset >= 0) {
      // LMV-5655: Keep track of the last 3d light preset.
      // There was an issue when switching from 3d -> 2d -> 2d -> 3d.
      // For this case the _oldLightPreset would be overridden with environment 0.
      _oldLightPreset = _3dLightPreset;
    }

    // Make sure that swapBlackAndWhite toggle is only considered as long as we are in 2d
    this.updateClearColors();

    this.setupLighting(model);

    this.fireRenderOptionChanged();
    this.invalidate(false, true);

    // Fire an event for the addition of a model into the _modelQueue
    this.api.fireEvent({ type: et.MODEL_ADDED_EVENT, model, preserveTools, isOverlay });
  };

  this._setModelPreferences = function (model) {

    // This is the place to load from preferences when a new model is added

    // Apply current renderLines/renderPoints settings
    model.hideLines(!this.api.prefs.get('lineRendering'));
    model.hidePoints(!this.api.prefs.get('pointRendering'));

    // selection mode
    model.selector.setSelectionMode(this.api.prefs.get(Prefs3D.SELECTION_MODE));

  };

  this.setupLighting = function (model) {

    model = model || this.model;

    if (this.headless || !model || model.is2d()) {
      return;
    }

    //When switching from a 2D sheet back to a 3D view,
    //we restore the environment map that was used for the
    //last 3D view displayed. The operation is delayed until here
    //so that switching between 2D sheets does not incur this unnecessary overhead.
    if (_oldLightPreset >= 0) {
      this.setLightPreset(_oldLightPreset, true, _oldCallback);
      _oldLightPreset = -1;
      _oldCallback = null;
    } else {
      this.setLightPreset(_currentLightPreset, false);
    }

  };

  this.getMaterials = function () {return _materials;};


  //Creates a THREE.Mesh representation of a fragment. Currently this is only
  //used as vehicle for activating a fragment instance for rendering once its geometry is received
  //or changing the fragment data (matrix, material). So, it's mostly vestigial.
  this.setupMesh = function (model, threegeom, materialId, matrix) {

    var m = {
      geometry: threegeom,
      matrix: matrix,
      isLine: threegeom.isLines,
      isWideLine: threegeom.isWideLines,
      isPoint: threegeom.isPoints,
      is2d: threegeom.is2d
    };

    if (materialId)
    m.material = this.matman().setupMaterial(model, threegeom, materialId);

    return m;
  };

  //Called when the geometry loader goes idle (is done loading whatever geometry was requested by any of the
  //multiple visible models)
  this.onGeomLoadComplete = function () {
    //Unblocks shadow and reflection generation during initial viewer startup
    _isLoading = false;

    //Marks the shadow and reflection as needing regeneration
    if (_groundShadow && _groundShadow.enabled || _groundReflection) {
      this.sceneUpdated(true, true);
    }
    //this.invalidate(false, true);
    this.requestSilentRender();

    // Fire the event so we know the geometry is done loading.
    //TODO: This is a legacy event that we need to phase out -- we no longer have a deterministic
    //way to tell that a model is completely done loading and no loading will happen again.
    this.api.dispatchEvent({
      type: et.GEOMETRY_LOADED_EVENT
    });
  };


  this.onTextureLoadComplete = function (model) {
    // Fire the event so we know the textures for a model are done loading.
    this.api.dispatchEvent({
      type: et.TEXTURES_LOADED_EVENT,
      model: model
    });

    // Once all the texture are loaded, we need to trigger an extra silent Render in Next Frame
    // It will fix the missing texture and avoid loading-flashing if we clear the color target everytime
    // LMV-4577 for more information
    this.requestSilentRender();
  };

  this.signalProgress = function (percent, progressState, model) {
    if (_progressEvent.percent === percent &&
    _progressEvent.state === progressState &&
    model && _progressEvent.model && _progressEvent.model.id === model.id) {
      return;
    }

    _progressEvent.percent = percent;
    _progressEvent.state = progressState;

    if (model) {
      _progressEvent.model = model;
    }

    this.api.dispatchEvent(_progressEvent);
  };

  this.resize = function (w, h, immediateUpdate) {

    _needsResize = true;
    _newWidth = w;
    _newHeight = h;

    if (immediateUpdate) {
      updateCanvasSize(true);
    }
  };

  this.unloadModel = function (model, keepResources) {

    // If model was visible, remove it.
    // If it was hidden, it has already been removed from viewer and we just have to remove it from
    // the hiddenModels list in RenderScene.
    if (!this.removeModel(model) && !_modelQueue.removeHiddenModel(model)) {
      // If neither of this works, this model is unknown.
      return;
    }

    if (!keepResources) {
      // Note that this just discards the GPU resources, not the model itself.
      model.dtor(this.glrenderer());
      _materials.cleanup(model);

      if (model.loader) {
        model.loader.dtor();
        model.loader = null;
        _this._removeLoadingFile(model.loader);
      }
    }

    this.api.dispatchEvent({ type: et.MODEL_UNLOADED_EVENT, model: model });
  };

  this._reserveLoadingFile = function () {
    if (!this.loaders) {
      this.loaders = [];
    }
    // The reservation is an object with a dtor function, in case
    // the load gets canceled before loader instance is created.
    var reservation = { dtor: function () {} };
    this.loaders.push(reservation);
    return reservation;
  };

  this._hasLoadingFile = function () {
    return this.loaders && this.loaders.length > 0;
  };

  this._addLoadingFile = function (reservation, svfLoader) {
    if (this.loaders) {
      var index = this.loaders.indexOf(reservation);
      if (index >= 0)
      this.loaders[index] = svfLoader;
    }
  };

  this._removeLoadingFile = function (svfLoader) {
    if (this.loaders) {
      var idx = this.loaders.indexOf(svfLoader);
      if (idx >= 0) {
        this.loaders.splice(idx, 1);
      }
    }
  };


  /** Removes a model from this viewer, but (unlike unload) keeps the RenderModel usable,
   *  so that it can be added to this or other viewers later.
   *   @param {RenderModel}
   *   @returns {boolean} True if the model was known and has been successfully removed.
   */
  this.removeModel = function (model) {

    if (!_modelQueue.removeModel(model)) {
      return false;
    }

    this.selector.removeModel(model);
    this.visibilityManager.removeModel(model);
    _webglrender?.removeModel(model);

    if (model === this.model) {
      this.model = null;

      if (!_modelQueue.isEmpty())
      this.model = _modelQueue.getModels()[0];
    }

    this.invalidate(true, true, true);

    this.api.fireEvent({ type: et.MODEL_REMOVED_EVENT, model: model });

    return true;
  };

  /**
   * Stops loading for a model url for which the RenderModel is not in memory yet.
   * TODO: This should be unified with unloadModel to a single API function, but we need a unique way first
   *       to address the model in both cases.
   *
   *  @param {string} url - Must exactly match the url used for loading
   */
  this.cancelLoad = function (url) {

    if (!this.loaders) {
      return;
    }

    // Find loader that is loading this url
    for (var i = 0; i < this.loaders.length; i++) {
      // TODO: currentLoadPath is only defined for SVF/OTG models. It would be better to have a unified way
      //       to cancel model loading.
      var loader = this.loaders[i];
      if (loader.currentLoadPath === url) {
        // Loader found - stop it
        loader.dtor();
        this.loaders.splice(i, 1);
        break;
      }
    }
  };

  /**
   * Removes loaded models and models that are getting loaded.
   * Method can be invoked while still loading the initial model.
   */
  this.unloadCurrentModel = function () {

    if (this.model) {
      //Before loading a new model, restore states back to what they
      //need to be when loading a new model. This means restoring transient
      //changes to the render state made when entering 2d mode,
      //like light preset, antialias and SAO settings,
      //and freeing GL objects specific to the old model.

      _oldLightPreset = _currentLightPreset;

      if (this.model.is3d()) {
        // LMV-5655: Keep track of the 3d light preset
        _3dLightPreset = _currentLightPreset;
      }

      _renderer.beginScene(this.scene, this.camera, this.lightsOn ? this.lights : this.no_lights, true);
      _renderer.composeFinalFrame(true);
    }

    // Destruct any ongoing loaders, in case the loading starts, but the model root hasn't created yet.
    if (this.loaders) {
      this.loaders.forEach(function (loader) {
        loader.dtor();
      });
      this.loaders = [];
    }

    var models = _modelQueue.getAllModels();
    for (var i = models.length - 1; i >= 0; i--)
    this.unloadModel(models[i]);

    this.model = null;
  };

  var createSelectionScene = function (name, materialPre, materialPost) {
    materialPre.depthWrite = false;
    materialPre.depthTest = true;
    materialPre.side = THREE.DoubleSide;

    materialPost.depthWrite = false;
    materialPost.depthTest = true;
    materialPost.side = THREE.DoubleSide;

    _this.createOverlayScene(name, materialPre, materialPost);
  };

  var setupSelectionHighlight = function () {

    _this.selectionMaterialBase = new THREE.MeshPhongMaterial({ specular: 0x080808, ambient: 0, opacity: 1.0, transparent: false, reflectivity: 0 });
    _this.selectionMaterialTop = new THREE.MeshPhongMaterial({ specular: 0x080808, ambient: 0, opacity: 0.15, transparent: true, reflectivity: 0 });
    _this.selectionMaterialTop.packedNormals = true;
    _this.selectionMaterialBase.packedNormals = true;
    createSelectionScene("selection", _this.selectionMaterialBase, _this.selectionMaterialTop);

    _this.highlightMaterial = new THREE.MeshPhongMaterial({ specular: 0x080808, ambient: 0, opacity: 1.0, transparent: false });
    _this.highlightMaterial.packedNormals = true;
    _materials.addMaterial("__highlightMaterial__", _this.highlightMaterial, true);

  };

  this.createOverlayScene = function (name, materialPre, materialPost, camera) {
    if (materialPre) {
      _materials.addOverrideMaterial(name + "_pre", materialPre);
    }

    if (materialPost) {
      _materials.addOverrideMaterial(name + "_post", materialPost);
    }

    var s = new THREE.Scene();
    s.__lights = this.scene.__lights;
    return this.overlayScenes[name] = {
      scene: s,
      camera: camera,
      materialName: name,
      materialPre: materialPre,
      materialPost: materialPost
    };
  };

  this.removeOverlayScene = function (name) {

    var overlay = this.overlayScenes[name];
    if (overlay) {
      var scene = this.overlayScenes[name];
      scene.materialPre && _materials.removeMaterial(scene.materialName + "_pre");
      scene.materialPost && _materials.removeMaterial(scene.materialName + "_post");
      delete this.overlayScenes[name];
      this.invalidate(false, false, true);
    }
  };

  this.addOverlay = function (overlayName, mesh) {
    if (this.overlayScenes[overlayName]) {
      this.overlayScenes[overlayName].scene.add(mesh);
      this.invalidate(false, false, true);
    }
  };

  this.addMultipleOverlays = function (overlayName, meshes) {
    for (var i in meshes) {
      this.addOverlay(overlayName, meshes[i]);
    }
  };

  this.removeOverlay = function (overlayName, mesh) {
    if (this.overlayScenes[overlayName]) {
      this.overlayScenes[overlayName].scene.remove(mesh);
      this.invalidate(false, false, true);
    }
  };

  this.removeMultipleOverlays = function (overlayName, meshes) {
    for (var i in meshes) {
      this.removeOverlay(overlayName, meshes[i]);
    }
  };

  /**
   * Removes objects from a given overlay
   * @param {String} overlayName
   * @param {Function} [filterCB] If specified, returns true for any object that is to be removed from the overlay
   */
  this.clearOverlay = function (overlayName, filterCB) {

    if (!this.overlayScenes[overlayName])
    return;

    var scene = this.overlayScenes[overlayName].scene;
    var obj, i;
    for (i = scene.children.length - 1; i >= 0; --i) {
      obj = scene.children[i];
      if (obj) {
        if (!filterCB || filterCB(obj)) {
          scene.remove(obj);
        }
      }
    }

    this.invalidate(false, false, true);
  };

  this.setClearColors = function (r, g, b, r2, g2, b2) {
    this.clearColorTop = new THREE.Vector3(r / 255.0, g / 255.0, b / 255.0);
    this.clearColorBottom = new THREE.Vector3(r2 / 255.0, g2 / 255.0, b2 / 255.0);

    //If we are using the background color as environment also,
    //create an environment map texture from the new colors
    //This is too magical and should not be necessary here -- it's done when calling setLightPreset with a light preset
    //that does not use explicit cube map.
    /*
    if (!_materials._reflectionMap || _materials._reflectionMap.isBgColor) { // TODO: don't access internal members of matman
        var cubeMap = this.loadCubeMapFromColors(this.clearColorTop, this.clearColorBottom);
        _renderer.setCubeMap(cubeMap);
        _renderer.toggleEnvMapBackground(_envMapBackground);
        this.invalidate(true);
    }
    */

    this.updateClearColors();
    if (_groundReflection)
    _groundReflection.setClearColors(this.clearColorTop, this.clearColorBottom, isMobileDevice());
    _needsClear = true;
    this.fireRenderOptionChanged();
  };

  this.setClearAlpha = function (alpha) {
    _renderer.setClearAlpha(alpha);
  };

  //Similar to THREE.Box3.setFromObject, but uses the precomputed bboxes of the
  //objects instead of doing it per vertex.
  var _box3 = new THREE.Box3();
  function computeObjectBounds(dst, object, bboxFilter) {

    object.updateMatrixWorld(true);

    object.traverse(function (node) {

      var geometry = node.geometry;

      // Special-handling for selection proxies. Why needed?:
      //  - selection proxies share model geometry
      //  - A model BufferGeometry does not contain a BoundingBoxes (to save memory)
      //  - A model geometry uses interleaved buffers, which is not supported by computeBoundingBox() anyway.
      // So, the standard handling below does not work here and would just waste memory by attaching wrong bboxes to model geometry.
      const isModelGeom = node.model && typeof node.fragId === 'number';
      if (isModelGeom && !geometry.boundingBox) {

        const fragList = isModelGeom && node.model.getFragmentList();
        if (fragList) {
          fragList.getWorldBounds(node.fragId, _box3);

          if (!bboxFilter || bboxFilter(_box3)) {
            dst.union(_box3);
          }
        }

        // TODO: In case any overlay contains interleaved geometry from anywhere else, this is still not
        //       handled properly here.
        return;
      }

      if (geometry !== undefined && node.visible) {

        if (!geometry.boundingBox)
        geometry.computeBoundingBox();

        _box3.copy(geometry.boundingBox);
        _box3.applyMatrix4(node.matrixWorld);

        if (!bboxFilter || bboxFilter(_box3)) {
          dst.union(_box3);
        }
      }

    });
  }

  var _bounds = new THREE.Box3();
  function getOverlayBounds(bboxFilter) {

    _bounds.makeEmpty();

    var overlays = _this.overlayScenes;

    for (var key in overlays) {
      computeObjectBounds(_bounds, overlays[key].scene, bboxFilter);
    }

    //Also add the root scenes -- people add overlays there too
    computeObjectBounds(_bounds, _this.scene, bboxFilter);
    computeObjectBounds(_bounds, _this.sceneAfter, bboxFilter);

    return _bounds;
  }

  this.getVisibleBounds = function (includeGhosted, includeOverlays, bboxFilter, excludeShadow) {
    var result = new THREE.Box3();
    if (!_modelQueue.isEmpty()) {
      computeObjectBounds(result, this.scene, bboxFilter);
      result = _modelQueue.getVisibleBounds(includeGhosted, bboxFilter, excludeShadow).union(result);

      if (includeOverlays) {
        result = getOverlayBounds(bboxFilter).union(result);
      }
    }
    return result;
  };

  this.getRenderProxy = function (model, fragId) {
    //currently there is a single model so the mapping
    //of fragId to render mesh is 1:1.
    return model.getFragmentList()?.getVizmesh(fragId);
  };

  this.getFragmentProxy = function (model, fragId) {
    return new FragmentPointer(model.getFragmentList(), fragId);
  };

  this.isWholeModelVisible = function () {
    return _modelQueue ? _modelQueue.areAllVisible() : true;
  };

  this.isNodeVisible = function (nodeId, model) {
    return this.visibilityManager.isNodeVisible(model, nodeId); // swapped arguments
  };

  this.highlightObjectNode = function (model, dbId, value, simpleHighlight) {

    dbId = model.reverseMapDbIdFor2D(dbId);

    if (model.is2d()) {
      _materials.highlightObject2D(dbId, value, model.id); //update the 2d object id texture
      this.invalidate(false, false, true);
    }

    this.renderer().setDbIdForEdgeDetection(value && !simpleHighlight ? dbId : 0, value ? model.id : 0);

    var scope = this;
    var instanceTree = model.getData().instanceTree;

    //TODO: There can be instance tree in the case of 2D drawing, but
    //we do not currently populate the node tree with the virtual fragment ids
    //that map 2d objects to 2d consolidated meshes, hence the use of dbId2fragId in the else condition
    if (instanceTree && !model.is2d()) {
      // set model to useIdBufferSelection and model needs to have its dbId2fragId map
      if (model.useIdBufferSelection) {
        var fragId = model.getData().fragments.dbId2fragId[dbId];
        scope.highlightFragment(model, fragId, value, simpleHighlight);
      } else {
        instanceTree.enumNodeFragments(dbId, function (fragId) {
          scope.highlightFragment(model, fragId, value, simpleHighlight);
        }, false);
      }
    } else {
      let fragId = dbId;

      if (model.is2d() && model.getData().fragments)
      fragId = model.getData().fragments.dbId2fragId[dbId];

      if (Array.isArray(fragId))
      for (var i = 0; i < fragId.length; i++)
      scope.highlightFragment(model, fragId[i], value, simpleHighlight);else

      scope.highlightFragment(model, fragId, value, simpleHighlight);

    }

  };

  this.highlightFragment = function (model, fragId, value, simpleHighlight) {

    var mesh = this.getRenderProxy(model, fragId);

    if (!mesh)
    return;

    // And also add a mesh to the overlays in case we need that.
    // For 2D that is always the case, while for 3D it's done
    // for "fancy" single-selection where we draw an outline for the object as post-processing step.
    // Overlay is only used for 2D, Point cloud, transparent and themeing colored objects.
    var useOverlay = !simpleHighlight || mesh.is2d || mesh.isPoint || mesh.themingColor;

    var highlightId = model.id + ":" + fragId;

    if (useOverlay) {
      var overlayName = "selection";
      if (mesh.isPoint) overlayName += "_points";

      if (value) {
        if (mesh.is2d && this.selectionMeshes[highlightId]) {
          // 2d has multiple dbids in a single fragment. As each dbid
          // is highlighted we count the number so we know when the mesh
          // is no longer needed.
          ++this.selectionMeshes[highlightId]._lmv_highlightCount;
        } else {
          var selectionProxy;

          // Make sure it all worked
          if (!mesh || !mesh.geometry)
          return;

          if (mesh.isPoint) {
            // using an override material would overwrite the point size for
            // each point cloud, so we apply the selection colour to the
            // duplicated geometry here instead by copying the material
            var selectionMaterial = mesh.material.clone();
            selectionMaterial.color = this.selectionMaterialBase.color;
            selectionMaterial.needsUpdate = true;
            if (selectionMaterial.defines && mesh.geometry.attributes["pointScale"]) {
              selectionMaterial.defines["PARTICLE_FLAGS"] = 1;
            }

            selectionProxy = new THREE.Mesh(mesh.geometry, selectionMaterial);
          } else {
            selectionProxy = new THREE.Mesh(mesh.geometry, mesh.material);
          }

          selectionProxy.matrix.copy(mesh.matrixWorld);
          selectionProxy.matrixAutoUpdate = false;
          selectionProxy.matrixWorldNeedsUpdate = true;

          selectionProxy.frustumCulled = false;
          selectionProxy.model = model;
          selectionProxy.fragId = fragId;
          selectionProxy.modelId = mesh.modelId;
          selectionProxy.dbId = mesh.dbId;
          selectionProxy._lmv_highlightCount = 1;

          this.addOverlay(overlayName, selectionProxy);

          this.selectionMeshes[highlightId] = selectionProxy;
        }
      } else
      if (this.selectionMeshes[highlightId]) {
        var proxy = this.selectionMeshes[highlightId];
        if (--proxy._lmv_highlightCount <= 0) {
          this.removeOverlay(overlayName, proxy);
          delete this.selectionMeshes[highlightId];
        }
      }
    }

    if (!useOverlay || !value) {
      //Case where highlighting was done directly in the primary render queue
      //and we need to repaint to clear it. This happens when multiple
      //nodes are highlighted using e.g. right click in the tree view
      if (model.setHighlighted(fragId, value)) //or update the vizflags in the render queue for 3D objects
        this.invalidate(true);
    }
  };

  /* simple function to set the brightness of the ghosting.
   * Simply sets another colour that is better for brighter environments
   */
  this.setGhostingBrightness = function (darkerFade) {
    var color = new THREE.Color(darkerFade ? 0x101010 : 0xffffff);
    function setColor(mat) {
      mat.color = color;
    }

    setColor(this.fadeMaterial);
    this.fadeMaterial.variants && this.fadeMaterial.variants.forEach(setColor);

    if (darkerFade) {
      this.edgeColorGhosted = EDGE_COLOR_DARK_GHOSTED;
    } else {
      this.edgeColorGhosted = EDGE_COLOR_LIGHT_GHOSTED;
    }

    _useWebGPU && _webglrender.getMainPass().setGhostingBrightness(darkerFade);
  };


  this.loadCubeMapFromColors = function (ctop, cbot) {
    var texture = CreateCubeMapFromColors(ctop, cbot);
    texture.isBgColor = true;
    _materials.setReflectionMap(texture);
    return texture;
  };

  this.loadCubeMap = function (path, exposure) {

    this._reflectionMapPath = path;

    var mapDecodeDone = function (map) {

      //If setCubeMap was called twice quickly, it's possible that
      //a texture map that is no longer desired loads after the one that was
      //set last. In such case, just make the undesirable disappear into the void.
      if (path !== _this._reflectionMapPath)
      return;

      // It is possible for this load to complete after the model has been canceled
      if (!_materials)
      return;

      _materials.setReflectionMap(map);
      _useWebGPU && _webglrender.getIBL().setReflectionMap(map);
      _this.invalidate(true);

      if (!map) {
        _this.loadCubeMapFromColors(_this.clearColorTop, _this.clearColorBottom);
      } else if (!LightPresets[_currentLightPreset].useIrradianceAsBackground) {
        _renderer.setCubeMap(map);
      }
    };

    return TextureLoader.loadCubeMap(path, exposure, mapDecodeDone, _useWebGPU);
  };


  this.loadIrradianceMap = function (path, exposure) {

    this._irradianceMapPath = path;

    var mapDecodeDone = function (map) {

      //If setCubeMap was called twice quickly, it's possible that
      //a texture map that is no longer desired loads after the one that was
      //set last. In such case, just make the undesirable disappear into the void.
      if (path !== _this._irradianceMapPath)
      return;

      // It is possible for this load to complete after the model has been canceled
      if (!_materials)
      return;

      _materials.setIrradianceMap(map);
      _useWebGPU && _webglrender.getIBL().setIrradianceMap(map);
      _this.invalidate(true);

      if (LightPresets[_currentLightPreset].useIrradianceAsBackground) {
        _renderer.setCubeMap(map);
      }
    };

    return TextureLoader.loadCubeMap(path, exposure, mapDecodeDone, _useWebGPU);

  };

  this.setCurrentLightPreset = function (index) {
    _currentLightPreset = index;
  };

  this.setLightPreset = function (index, force, callback) {
    //We do not have the ability to load the environment map textures on node.js yet,
    //because they use plain XHR that needs to be converted to use TextureLoader.
    //So we override the environment to zero, which does not use external environment maps.
    if (this.headless)
    index = 0;

    // make sure that lights are created
    this.initLights();

    if (_currentLightPreset === index && !force) {
      callback && callback();
      return;
    }

    // Reset index in cases the index out of range.
    // This could happen, if we update the light preset list and user
    // has a local web storage which stores the last accessed preset index which is potentially
    // out of range with respect to the new preset list.
    if (index < 0 || LightPresets.length <= index) {
      index = DefaultLightPreset;
    }
    _currentLightPreset = index;

    // If we don't have any models, then we save the light preset
    // so it is set when a model is added. This is to stop unnecessary
    // loading of environment maps for 2D models.
    if (_modelQueue.isEmpty()) {
      _oldLightPreset = _currentLightPreset;
      _oldCallback = callback;
      return;
    }

    var preset = LightPresets[index];

    //if the light preset has a specific background color, set that
    //This has to be done first, because the encironment map may use
    //the background colors in case no environment map is explicitly given.
    var c = preset.bgColorGradient;
    if (!c)
    c = BackgroundPresets["Custom"];
    this.setClearColors(c[0], c[1], c[2], c[3], c[4], c[5]);

    //If allowed, display the environment as background (most likely the irradiance map will be used
    //by the AEC presets, so it will be almost like a color gradient)
    if (preset.useIrradianceAsBackground !== undefined) {
      this.toggleEnvMapBackground(preset.useIrradianceAsBackground);
    }

    if (preset.path) {

      var pathPrefix = "res/environments/" + preset.path;
      var reflPath = getResourceUrl(pathPrefix + "_mipdrop." + (preset.type || "") + ".dds");
      var irrPath = getResourceUrl(pathPrefix + "_irr." + (preset.type || "") + ".dds");

      this.loadIrradianceMap(irrPath, preset.E_bias);
      this.loadCubeMap(reflPath, preset.E_bias);

      //Set exposure that the environment was baked with.
      //This has to be known at baking time and is applied
      //by the shader.
      _materials.setEnvExposure(-preset.E_bias);
      _renderer.setEnvExposure(-preset.E_bias);
      _useWebGPU && _webglrender.getIBL().setEnvExposure(-preset.E_bias);

      this.setTonemapExposureBias((preset.E_bias || 0) + (preset.E_correction || 0));
      this.setTonemapMethod(preset.tonemap);

      this.setGhostingBrightness(preset.darkerFade);
    } else
    {
      var cubeMap = this.loadCubeMapFromColors(this.clearColorTop, this.clearColorBottom);
      _renderer.setCubeMap(cubeMap);
      _materials.setIrradianceMap(null);
      //_materials.setReflectionMap(cubeMap); //will be set by the loadCubeMapFromColors call

      //Set exposure that the environment was baked with.
      //This has to be known at baking time and is applied
      //by the shader.
      _materials.setEnvExposure(-preset.E_bias || 0);
      _renderer.setEnvExposure(-preset.E_bias || 0);
      _useWebGPU && _webglrender.getIBL().setEnvExposure(-preset.E_bias || 0);

      this.setTonemapExposureBias((preset.E_bias || 0) + (preset.E_correction || 0));
      this.setTonemapMethod(preset.tonemap || 0);

      this.setGhostingBrightness(preset.darkerFade);

      _renderer.toggleEnvMapBackground(_envMapBackground);


      this.invalidate(true);
    }


    //Leaving those undefined means that the renderer will preserve the current setting
    //and only change the ones that are explicitly overridden in the light preset.
    //When changing light presets, we want to reset SAO to its default, in case
    //the previous preset customized the settings to other values.
    var saoRadius = SAOShader.uniforms.radius.value;
    var saoIntensity = SAOShader.uniforms.intensity.value;
    var saoBias = BlendShader.uniforms.aoBias.value;

    //Check if the preset overrides the SAO settings
    if (typeof preset.saoRadius === "number")
    saoRadius = preset.saoRadius;
    if (typeof preset.saoIntensity === "number")
    saoIntensity = preset.saoIntensity;
    if (typeof preset.saoBias === "number")
    saoBias = preset.saoBias;

    _renderer.setAOOptions(saoRadius, saoIntensity, saoBias);

    var lightIntensity = _defaultLightIntensity;
    if (preset.lightMultiplier !== null && preset.lightMultiplier !== undefined) {
      lightIntensity = preset.lightMultiplier;
    }

    // init primary light direction used for shadows
    _shadowLightDir.copy(_shadowLightDirDefault);
    if (preset.lightDirection) {
      // The presets describe the direction away from the light, while _shadowLightDir
      // is the direction pointing to the light.
      _shadowLightDir.fromArray(preset.lightDirection).negate();
    }

    // changing the shadow light direction invalidates the shadow-map
    if (_shadowMaps) {
      invalidateShadowMap();
    }

    if (this.dir_light1) {
      this.dir_light1.intensity = lightIntensity;

      if (preset.lightDirection) {
        this.dir_light1.position.set(-preset.lightDirection[0], -preset.lightDirection[1], -preset.lightDirection[2]);
      } else {
        // set to default, otherwise the environment will inherit the direction from whatever previous environment was chosen
        this.dir_light1.position.copy(_lightDirDefault);
      }

    }

    _materials.setEnvRotation(preset.rotation || 0.0);
    _renderer.setEnvRotation(preset.rotation || 0.0);

    if (_groundReflection) _groundReflection.setEnvRotation(preset.rotation || 0.0);

    // toggle lights on/off based on lightMultiplier
    this.toggleLights(lightIntensity !== 0.0);

    this.invalidate(true, false, true);

    this.fireRenderOptionChanged();

    // Call the callback
    callback && callback();
  };


  this.setTonemapMethod = function (index) {

    if (index == _renderer.getToneMapMethod())
    return;

    _renderer.setTonemapMethod(index);
    _materials.setTonemapMethod(index);
    _useWebGPU && _webglrender.getIBL().setTonemapMethod(index);

    this.fireRenderOptionChanged();
    this.invalidate(true);
  };

  this.setTonemapExposureBias = function (bias) {

    if (bias == _renderer.getExposureBias())
    return;

    _renderer.setTonemapExposureBias(bias);
    _materials.setTonemapExposureBias(bias);
    _useWebGPU && _webglrender.getIBL().setExposureBias(bias);

    this.fireRenderOptionChanged();
    this.invalidate(true);
  };

  this.setRenderingPrefsFor2D = function (is2D) {

    if (!this.headless) {
      var value = is2D ? false : !!this.api.prefs.get('envMapBackground');
      this.toggleEnvMapBackground(value);
    }
  };


  /**
   * Unloads model, frees memory, as much as possible.
   */
  this.dtor = function () {
    this.stop();
    this.api.removeEventListener(et.MODEL_ROOT_LOADED_EVENT, _onModelRootLoaded);

    this.unloadCurrentModel();

    // this.controls is uninitialized by Viewer3D, since it was initialized there
    this.controls = null;
    this.canvas = null;

    this.loader = null;

    this.selector.dtor();
    this.selector = null;

    this.model = null;
    this.visibilityManager = null;

    if (_geomCache) {
      _geomCache.removeViewer(this.api);
      _geomCache = null;
    }

    _modelQueue = null;
    _renderer = null;

    _materials.refCount--;

    if (_materials.refCount === 0) {
      _materials.dtor();
    }

    _materials = null;

    if (_webglrender) {
      _webglrender.refCount--;

      if (_webglrender.refCount === 0) {
        _webglrender.domElement = null;
        _webglrender.context = null;
      }

      if (!_useWebGPU) {
        _webglrender.removeEventListener(WebGLRenderer.Events.WEBGL_CONTEXT_LOST, this.onGraphicsContextLost);
      } else {
        _webglrender.removeEventListener(Renderer.Events.WEBGPU_DEVICE_LOST, this.onGraphicsContextLost);
      }

      _webglrender.removeEventListener(WebGLRenderer.Events.WEBGL_CONTEXT_RESTORED, this.onWebGLcontextRestored);

      _webglrender = null;
    }
  };

  this.hideLines = function (hide) {
    if (_modelQueue && !_modelQueue.isEmpty()) {
      _modelQueue.hideLines(hide);
      this.sceneUpdated(true);
    }
  };

  this.hidePoints = function (hide) {
    if (_modelQueue && !_modelQueue.isEmpty()) {
      _modelQueue.hidePoints(hide);
      this.sceneUpdated(true);
    }
  };

  this.setDisplayEdges = function (show) {

    _renderer.toggleEdges(show);

    this.invalidate(true);
  };

  /**
   * Sets surface materials to double sided or single sided.
   * @param {boolean} enable - sets materials to double sided if set to true.
   * @param {Autodesk.Viewing.Model} model - model instance
   * @param {boolean} [update=true] - Updates the scene
   */
  this.setDoubleSided = function (enable, model) {let update = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    model = model || this.model;

    // Double sided materials will only be set to 3d models.
    if (model.is2d()) {
      return;
    }

    const modelData = model.getData();

    // Do not apply if the model data is not available.
    if (!modelData) {
      return;
    }

    // Sets surface materials to either double sided or single sided
    this.matman().setDoubleSided(enable, model);
    update && this.sceneUpdated();
  };

  this.getAllCutPlanes = function () {
    // create array of the planes by combining all cut plane sets
    var allPlanes = undefined;
    for (var key in _cutPlaneSets) {
      var cps = _cutPlaneSets[key];
      if (cps && cps.length) {
        if (!allPlanes) {
          allPlanes = cps;
        } else if (key === _cutPlaneSetFor2DRendering) {
          // UnitsPerPixel only consider the first cutplane. So, this one must go first.
          allPlanes = cps.concat(allPlanes);
        } else {
          // append cutplanes
          allPlanes = allPlanes.concat(cps);
        }
      }
    }
    return allPlanes;
  };

  // Set cutplane array by combining the cutplanes specified by different tools
  this.updateCutPlanes = function () {
    var allPlanes = this.getAllCutPlanes();
    this.setCutPlanes(allPlanes);
  };


  /**
   * A cutplane set is an array of cutplanes that can be controlled individually by a single tool
   * without affecting other tools' cutplanes.
   *  @param {string} cutPlaneSetName
   *  @param {Vector4[]|null} [planes]
   *  @param {Boolean} [fireEvent] - if set to false the av.CUTPLANES_CHANGE_EVENT event will not be fired.
   */
  this.setCutPlaneSet = function (cutPlaneSetName, planes) {let fireEvent = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    // store copy of plane array
    _cutPlaneSets[cutPlaneSetName] = planes ? planes.slice() : undefined;
    if (fireEvent) {
      this.updateCutPlanes();
    } else {
      var allPlanes = this.getAllCutPlanes();
      this.setCutPlanesInScene(allPlanes);
    }
  };

  /** Defines which cutplane is used to adjust 2D rendering. This is used by SectionTool
   * to make sure that 2D rendering resolution is properly adjusted for its cutplane.
   *  @param {string] cutPlaneSetName */
  this.setCutPlaneSetFor2DRendering = function (cutPlaneSetName) {
    _cutPlaneSetFor2DRendering = cutPlaneSetName;
    this.updateCutPlanes();
  };

  this.getCutPlaneSet = function (cutPlaneSetName) {
    return _cutPlaneSets[cutPlaneSetName] || [];
  };

  /* @returns {string[]} names - names of all active (non-empty) cutplane sets. */
  this.getCutPlaneSets = function () {
    var result = [];
    for (var key in _cutPlaneSets) {
      var cp = _cutPlaneSets[key];
      if (cp && cp.length) {
        result.push(key);
      }
    }
    return result;
  };

  this.getCutPlanes = function () {
    return _materials.getCutPlanes();
  };

  /**
   * Sets the material cutplanes and updates the scene.
   * This function does not fire the Autodesk.Viewing.CUTPLANES_CHANGE_EVENT
   * @see Viewer3DImpl#setCutPlanes
   */
  this.setCutPlanesInScene = function (planes) {
    _renderer.toggleTwoSided(_materials.setCutPlanes(planes));
    _useWebGPU && _webglrender.getIBL().setCutPlanes(planes);
    this.sceneUpdated();
  };

  this.setCutPlanes = function (planes) {
    this.setCutPlanesInScene(planes);
    this.api.dispatchEvent({ type: et.CUTPLANES_CHANGE_EVENT, planes: planes });
  };

  this.fireRenderOptionChanged = function () {

    //If SAO is changing and we are using multiple
    //render targets in the main material pass, we have
    //to update the materials accordingly.
    _materials.toggleMRTSetting(_renderer.mrtFlags());

    this.api.dispatchEvent({ type: et.RENDER_OPTION_CHANGED_EVENT });
  };

  this.viewportToRay = function (vpVec, ray) {let camera = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.camera;
    return camera.viewportToRay(vpVec, ray);
  };

  // Add "meshes" parameter, after we get meshes of the object using id buffer,
  // then we just need to ray intersect this object instead of all objects of the model.
  this.rayIntersect = function (ray, ignoreTransparent, dbIds, modelIds, intersections, options) {

    const getDbIdAtPointFor2D = (point) => {
      const vpVec = new THREE.Vector3().copy(point);
      vpVec.project(this.camera);
      const res = [];
      _renderer.idAtPixel(vpVec.x, vpVec.y, res);

      return res;
    };

    var result = _modelQueue.rayIntersect(ray.origin, ray.direction, ignoreTransparent, dbIds, modelIds, intersections, getDbIdAtPointFor2D, options);

    var extraScenes = [this.scene, this.sceneAfter];
    const tmpSize = new THREE.Vector3();

    for (let i = 0; i < extraScenes.length; i++) {
      let scene = extraScenes[i];
      if (scene.children.length) {
        var raycaster = new THREE.Raycaster(ray.origin, ray.direction, this.camera.near, this.camera.far);

        computeObjectBounds(_bounds, scene);
        //TODO: This math approximately matches the heuristic in RenderScene.recomputeLinePrecision
        //but it applies per scene. It might be good to unify the two once we know this works well.
        raycaster.params.Line.threshold = Math.min(1.0, _bounds.getSize(tmpSize).length() * 0.5 * 0.001);

        var intersects = intersections || [];
        VBIntersector.intersectObject(scene, raycaster, intersects, true);

        if (intersects.length) {
          if (!result || intersects[0].distance < result.distance) {
            result = intersects[0];
          }
        }
      }
    }

    if (!result) {
      return null;
    }

    if (result.dbId === undefined && result.fragId === undefined && result.model === undefined) {
      // we hit some geoemtry which was added to the scene but is not part of the model, e.g. ground plane of the clustering extension
      // ignore it
      return null;
    }

    if (result.dbId === undefined && result.fragId !== undefined /* 0 is a valid fragId */) {

      result.dbId = result.model.getFragmentList().getDbIds(result.fragId);

      if (!result.model.getData().instanceTree) {
        //Case where there is no dbid to fragment id map. Create a 'virtual' node
        //with node Id = fragment Id, so that selection works like
        //each scene fragment is a scene node by itself.
        result.dbId = result.fragId;
      }
    }

    result.intersectPoint = result.point; // Backwards compatibility

    return result;
  };

  this.castRayViewport = function () {

    var _ray;

    // Add "meshes" parameter, after we get meshes of the object using id buffer,
    // then we just need to ray intersect this object instead of all objects of the model.
    return function (vpVec, ignoreTransparent, dbIds, modelIds, intersections, options) {

      _ray = _ray || new THREE.Ray();

      if (!_modelQueue) {
        return {};
      }

      this.viewportToRay(vpVec, _ray);

      return this.rayIntersect(_ray, ignoreTransparent, dbIds, modelIds, intersections, options);
    };

  }();

  this.getCanvasBoundingClientRect = function () {
    if (this.canvasBoundingclientRectDirty) {
      this.canvasBoundingclientRectDirty = false;
      this.boundingClientRect = this.canvas.getBoundingClientRect();
    }
    return this.boundingClientRect;
  };

  this.clientToViewport = function (clientX, clientY) {
    var rect = this.getCanvasBoundingClientRect();
    return new THREE.Vector3(
      (clientX + 0.5) / rect.width * 2 - 1,
      -((clientY + 0.5) / rect.height) * 2 + 1, 1);
  };

  this.viewportToClient = function (viewportX, viewportY) {
    var rect = this.getCanvasBoundingClientRect();
    return new THREE.Vector3(
      (viewportX + 1) * 0.5 * rect.width - 0.5,
      (viewportY - 1) * -0.5 * rect.height - 0.5, 0);
  };

  this.castRay = function (clientX, clientY, ignoreTransparent, options) {
    // Use the offsets based on the client rectangle, which is relative to the browser's client
    // rectangle, unlike offsetLeft and offsetTop, which are relative to a parent element.
    //
    return this.castRayViewport(this.clientToViewport(clientX, clientY), ignoreTransparent, undefined, undefined, undefined, options);
  };

  // Note: The camera world matrix must be up-to-date
  this.intersectGroundViewport = function (vpVec) {

    var worldUp = "z";

    //In 2D mode, the roll tool can be used to change the orientation
    //of the sheet, which will also set the world up vector to the new orientation.
    //However, this is not what we want in case of a 2d sheet -- its ground plane is always Z.
    //TODO: It's not clear if checking here or in setWorldUp is better. Also I don't see
    //a way to generalize the math in a way to make it work without such check (e.g. by using camera up only).
    if (!this.is2d) {
      worldUp = _worldUpName;
    }

    var modelBox = this.model && this.model.getBoundingBox();
    return SceneMath.intersectGroundViewport(vpVec, this.camera, worldUp, modelBox);
  };

  this.intersectGround = function (clientX, clientY) {
    return this.intersectGroundViewport(this.clientToViewport(clientX, clientY));
  };

  this._2dHitTestViewport = function (vpVec, searchRadius, minPixelId) {
    const _idRes = [0, 0]; // idAtPixels will write the result into this array.
    const pixelId = _renderer.idAtPixels(vpVec.x, vpVec.y, searchRadius, _idRes);
    if (pixelId < minPixelId)
    return null;

    const model = _modelQueue.findModel(_idRes[1]) || this.model;
    if (!model)
    return null;

    //Note this function will destructively modify vpVec,
    //so it's unusable after that.
    const point = this.intersectGroundViewport(vpVec);

    // get fragment ID if there is a fragment list
    const fragments = model.getData().fragments;
    const fragId = fragments ? fragments.dbId2fragId[pixelId] : -1;

    return {
      intersectPoint: point,
      dbId: model.remapDbIdFor2D(pixelId),
      fragId: fragId,
      model: model
    };
  };

  // Filter to exclude 3D line hit tests if they are outside the given searchRadius
  const getSearchRadiusFilter = (searchRadius) => {

    return (hit) => {

      // We only care for line hits. Note that we exclude wideLines here as well, because
      // (unlike regular ones), they have a true world-space width.
      const isLine = hit && hit.object && hit.object.isLine;
      if (!isLine) {
        return true;
      }

      // Exclude its if projected distance from ray is beyond search radius
      const unitsPerPixel = 1.0 / _this.camera.pixelsPerUnitAtDistance(hit.distance);
      const maxWorldDist = searchRadius * unitsPerPixel;
      return hit.distanceToRay < maxWorldDist;
    };
  };

  this.hitTestViewport = function (vpVec, ignoreTransparent, dbIds, modelIds, intersections) {
    let result;

    if (this.is2d) {
      const searchRadius = isMobileDevice() ? 45 : 5;
      result = this._2dHitTestViewport(vpVec, searchRadius, 1);
    } else
    {
      result = this.castRayViewport(vpVec, ignoreTransparent, dbIds, modelIds, intersections);
    }

    return result;
  };

  this.hitTest = function (clientX, clientY, ignoreTransparent, dbIds, modelIds) {

    return _this.hitTestViewport(this.clientToViewport(clientX, clientY), ignoreTransparent, dbIds, modelIds);
  };

  this.snappingHitTestViewport = function (vpVec, ignoreTransparent) {
    let result;

    //Notice: The amount of pixels per line should correspond to pixelSize in setDetectRadius of Snapper.js,
    //the shape of detection area is square in idAtPixels, but circle in snapper, should make their areas match roughly.
    const searchRadius = isMobileDevice() ? 45 : 17;

    if (this.is2d) {
      result = this._2dHitTestViewport(vpVec, searchRadius, 0);
    } else {// Is 3d
      const res = [];
      const dbId = _renderer.idAtPixels(vpVec.x, vpVec.y, searchRadius, res);

      // Adjust vp position according to hit.
      if (res[2] && res[3]) {
        vpVec.setX(res[2]);
        vpVec.setY(res[3]);
      }

      const options = {
        filter: getSearchRadiusFilter(searchRadius)
      };
      result = this.castRayViewport(vpVec, ignoreTransparent, dbId > 0 ? [dbId] : null, undefined, undefined, options);
    }

    return result;
  };

  // Used for snapping
  // firstly, find the intersect object using pre-computed ID buffer
  // secondly, find the intersect point and face using intersection test
  this.snappingHitTest = function (clientX, clientY, ignoreTransparent) {

    return this.snappingHitTestViewport(this.clientToViewport(clientX, clientY), ignoreTransparent);
  };

  /**
   * Clears the current highlighted object.
   */
  this.clearHighlight = function () {
    _renderer.rolloverObjectId(-1);
    this.invalidate(false, false, true);
  };

  //Used for rollover highlighting using pre-computed ID buffer
  this.rolloverObjectViewport = function (vpVec) {
    _renderer.rolloverObjectViewport(vpVec.x, vpVec.y);
  };

  this.rolloverObject = function (clientX, clientY) {
    if (!this.selector.highlightPaused && !this.selector.highlightDisabled)
    this.rolloverObjectViewport(this.clientToViewport(clientX, clientY));
  };

  //This method is intended to be used by Tools
  this.pauseHighlight = function (disable) {

    this.selector.highlightPaused = disable;
    if (disable) {
      this.clearHighlight();
    }
  };

  this.disableHighlight = function (disable) {

    this.selector.highlightDisabled = disable;
    if (disable) {
      this.clearHighlight();
    }
  };

  this.disableSelection = function (disable) {

    this.selector.selectionDisabled = disable;
  };

  // See ScreenShot.js for documentation
  this.getScreenShotProgressive = function (w, h, onFinished, options) {
    return ScreenShot.getScreenShot(w, h, onFinished, options, this);
  };

  //This accessor is only used for debugging purposes a.t.m.
  this.modelQueue = function () {return _modelQueue;};

  this.glrenderer = function () {return _webglrender;};

  this.renderer = function () {return _renderer;};

  this.geomCache = function () {
    if (!_geomCache) {
      _geomCache = new DtResourceCache();
      _geomCache.addViewer(_this.api);
    }

    return _geomCache;
  };

  this.memTracker = function () {
    return _memTracker;
  };

  // only for debugging purposes
  this.shadowMaps = function () {return _shadowMaps;};

  this.worldUp = function () {return _worldUp;};
  this.worldUpName = function () {return _worldUpName;};

  this.setUserRenderContext = function (ctx, isInitialized) {
    _renderer = ctx ? ctx : new RenderContext();
    if (!isInitialized) {
      _renderer.init(_webglrender, this.canvas.clientWidth, this.canvas.clientHeight);
      _renderer.setClearColors(this.clearColorTop, this.clearColorBottom);
    }
    this.invalidate(true);
    this.sceneUpdated(false); //to reset world boxes needed by new RenderContext for shadows, etc
  };

  this.setUserGroundShadow = function (groundShadow) {
    var replaced = _groundShadow;
    _groundShadow = groundShadow;
    return replaced; // Return GroundShadow object that we replaced.
  };

  this.invalidate = function (needsClear, needsRender, overlayDirty) {
    _needsClear = needsClear || _needsClear;
    _needsRender = needsRender || _needsRender;
    _overlayDirty = overlayDirty || _overlayDirty;
  };

  this.sceneUpdated = function (objectsMoved, skipRepaint) {

    this.invalidate(!skipRepaint, false, !skipRepaint);

    // Mark the scene bounds for update
    if (_modelQueue && objectsMoved) {
      _modelQueue.invalidateVisibleBounds();
      this.zoomBoundsChanged = true;
    }

    _sceneDirty = true;

    invalidateShadowMap();
  };

  // immediately restart rendering, make it interruptible like progressive, displaying only when done
  this.requestSilentRender = function () {
    _deferredSilentRender = _immediateSilentRender = true;
  };

  // restart rendering only when the previous render is done, make it interruptible like progressive, itself displaying only when done
  this.requestDeferredSilentRender = function () {
    _deferredSilentRender = true; // but not immediate
  };

  this.currentLightPreset = function () {return _currentLightPreset;};

  /**
   * @private
   */
  this.saveLightPreset = function () {
    _oldLightPreset = _currentLightPreset;
  };

  this.matman = function () {return _materials;};

  this.fps = function () {return _rcs.fps();};

  this.setFPSTargets = function (min, target, max) {
    _rcs.setFPSTargets(min, target, max);
  };

  //========================================================================


  // Record fragments transformation in explode mode for RaaS rendering
  //this.fragTransformConfig = [];

  this.track = function (event) {
    logger.track(event);
  };

  this.worldToClient = function (point) {let camera = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.camera;
    var p = new THREE.Vector4(point.x, point.y, point.z, 1);
    p.applyMatrix4(camera.matrixWorldInverse);
    p.applyMatrix4(camera.projectionMatrix);

    // Don't want to mirror values with negative z (behind camera)
    if (p.w > 0) {
      p.x /= p.w;
      p.y /= p.w;
      p.z /= p.w;
    }

    return this.viewportToClient(p.x, p.y);
  };

  this.clientToWorld = function (clientX, clientY, ignoreTransparent, ignore2dModelBounds) {

    var result = null;
    var model = this.model;
    var modelData = model.getData();

    if (model.is2d()) {

      var collision = this.intersectGround(clientX, clientY);
      if (collision) {
        collision.z = 0;
        var bbox = modelData.bbox;
        if (ignore2dModelBounds || modelData.hidePaper || bbox.containsPoint(collision)) {
          result = {
            point: collision,
            model: model
          };
        }
      } else if (ignore2dModelBounds) {
        // clientToWorld() should usually never return null for 2d and ignore2dModelBounds=true,
        // particularly because the view direction is normally supposed to be orthogonal to the sheet plane.
        //
        // This section is only entered in the rare edge case that the view direction is orthogonal to the plane.
        // To avoid an exception for that case (https://sentry.io/organizations/fluent-y0/issues/2392259972/?referrer=slack)
        // we just fall back by using camera-position x/y, which corresponds to projecting the camera position to the sheetplane z=0.
        result = { point: this.camera.position.clone(), model };
      }
    } else {

      // hitTest handles multiple scenes
      result = this.hitTest(clientX, clientY, ignoreTransparent);
      if (result) {
        result.point = result.intersectPoint; // API expects attribute point to have the return value too.
      }
    }

    return result;
  };

  /**
   * Sets selection highlight color and opacity for 2D models
   * @param {THREE.Color} color
   * @param {number} opacity
   */
  this.set2dSelectionColor = function (color, opacity) {
    this.matman().set2dSelectionColor(color, opacity);
    this.invalidate(false, false, true /* overlay */);
  };

  /**
   * Sets selection highlight color for 3D models
   * @param {THREE.Color} color
   * @param {number} selectionType
   */
  this.setSelectionColor = function (color, selectionType) {
    selectionType = selectionType || SelectionType.MIXED;
    var emissive = new THREE.Color(color);
    emissive.multiplyScalar(0.5);

    var setColors = function (material) {
      material.color.set(color);
      material.emissive.set(emissive);
      material.variants && material.variants.forEach(setColors);
    };

    switch (selectionType) {
      default:
      case SelectionType.MIXED:
        setColors(this.selectionMaterialBase);
        setColors(this.selectionMaterialTop);
        _renderer.setSelectionColor(color);
        setColors(this.highlightMaterial);
        this.invalidate(true);
        break;
      case SelectionType.REGULAR:
        setColors(this.highlightMaterial);
        this.invalidate(true);
        break;
      case SelectionType.OVERLAYED:
        setColors(this.selectionMaterialBase);
        setColors(this.selectionMaterialTop);
        _renderer.setSelectionColor(color);
        this.invalidate(false, false, true);
        break;
    }
  };

  // Update the viewport Id for the first selection in 2d measure
  this.updateViewportId = function (vpId) {
    _materials.updateViewportId(vpId);
    this.invalidate(true);
  };

  /**
   * Find model based on modelId, BubbleNode, or filter function.
   *  @param {number|av.BubbleNode|function(av.Model)} value
   *  @param {boolean}[includeHidden] - By default, we only consider visible models for search
   *  @returns {RenderModel|null}
   */
  this.findModel = function (value, includeHidden) {

    // define filter function
    let filter;
    if (typeof value == 'number') filter = (m) => m.id == value;else
    filter = value; // value must be a filter function already

    // Search visible models
    let model = _modelQueue.getModels().find(filter);

    // Optional: Search hidden models
    if (includeHidden && !model) {
      model = _modelQueue.getHiddenModels().find(filter);
    }

    return model;
  };

  /**
   *  get frame rate for progressive rendering, i.e, how many ticks go by before an update occurs
   *  @returns {number}
   */
  this.getFrameRate = function () {
    return this.frameDisplayRate;
  };

  /**
   * set frame rate for progressive rendering, i.e, how many ticks go by before an update occurs
   *  @param   {number} rate
   */
  this.setFrameRate = function (rate) {
    // don't let rate < 1, just in case user sets 0.
    this.frameDisplayRate = rate < 1 ? 1 : rate;
  };

  /**
   *  For shadow casting, we assume a single directional light. Shadow light direction is the direction
   *  that this light comes from, i.e., shadows are casted to the opposite direction.
   *  This function changes the direction and triggers a shadow update.
   *
   *  Note that the directional light source is only assumed for shadow casting. The actual lighting usually comes from
   *  several directions when using environment lighting, but we need a fixed direction for shadow mapping.
   *
   *   @param {THREE.Vector3} lightDir - direction in world space
   */
  this.setShadowLightDirection = function (lightDir) {
    _shadowLightDir.copy(lightDir);
    invalidateShadowMap();
    this.invalidate(true, false, false);

    // update ground transform to make sure that the ground shape is large enough
    // to make the whole shadow visible.
    updateGroundTransform();
  };

  /**
   *  The result is the internal vector, modifying it will change the shadow direction
   *  @returns {THREE.Vector3} Either target object or new Vector3 instance.
   */
  this.getShadowLightDirection = function () {
    return _shadowLightDir;
  };

  /**
   * @param {boolean} enable
   * Note that viewer must be initialized first.
   */
  this.toggleShadows = function (enable) {
    if (!!_shadowMaps == !!enable) {
      // no change
      return;
    }

    if (enable) {
      _shadowMaps = new shadow.ShadowMaps(_webglrender);
    } else {
      _shadowMaps.cleanup(_materials);
      _shadowMaps = null;
    }

    // Adjust ground plane box if the shadows are getting turned on.
    updateGroundTransform();

    this.invalidate(true, true, false);
  };

  this.showTransparencyWhenMoving = function (enabled) {
    _modelQueue.enableNonResumableFrames = enabled;
  };


  this.fitToView = function (aggregateSelection, immediate) {
    console.warn("Deprecated, use Autodesk.Viewing.Private.fitToView directly instead.");
    return fitToView(this.api, aggregateSelection, immediate);
  };

  // Must be triggered if the transform of a model has changed while viewing it.
  this.onModelTransformChanged = function (model) {

    // Needed in order to invalidate scene and shadow map.
    this.sceneUpdated();

    this.invalidate(true, true, true);

    this.api.fireEvent({ type: et.MODEL_TRANSFORM_CHANGED_EVENT, model, matrix: model.getModelTransform() });
  };

  /**
   * Change the placement matrix of the model. This overrides the placement transform applied at loadTime.
   *  @param {LmvMatrix4} matrix         - Note that you need 64-Bit precision for large values.
   *  @param {Vector3}    [globalOffset] - Optionally, the globalOffset can be reset in the same step.
   */
  this.setPlacementTransform = function (model, matrix) {
    model.setPlacementTransform(matrix);

    this.api.fireEvent({ type: et.MODEL_PLACEMENT_CHANGED_EVENT, model, matrix: model.getPlacementTransform() });

    this.onModelTransformChanged(model);
  };

  /**
   * Invoked when WebGL loses the rendering context.
   * Only happens during an unrecoverable error.
   */
  this.onGraphicsContextLost = function () {
    this.stop();
    this.api.fireEvent({ type: et.WEBGL_CONTEXT_LOST_EVENT });
  };

  /**
   * Invoked when the WebGPU renderer fails to initialize.
   */
  this.onWebGPUInitFailed = function () {
    // Disable WebGPU in the settings.
    // We have to delay this a little bit, because the component of the Tandem app that persist preference changes
    // needs to be updated after the viewer has been initialized to actually receive this change.
    setTimeout(() => {
      this.api.prefs.set(Prefs.WEBGPU_ENABLE, false, true);
    }, 250);
    this.stop();
    this.api.fireEvent({ type: et.WEBGPU_INIT_FAILED_EVENT });
  };

  this.onWebGLcontextRestored = function () {

    // Clear overlays, because old overlay target renderings cannot be used anymore.
    _renderer.clearAllOverlays();

    // shadow and reflection targets must be re-rendered before next use
    _groundShadow.setDirty();
    _groundReflection && _groundReflection.setDirty();

    // Bring image back
    this.invalidate(true, true, true);

    this.run();

    this.api.fireEvent({ type: et.WEBGL_CONTEXT_RESTORED_EVENT });
  };

  /** @returns {bool} Check if the model is in the array of visible ones */
  this.modelVisible = function (modelId) {
    var model = _modelQueue.findModel(modelId);
    return !!model;
  };

  /**
   * Used by Loaders to indicate that the model loading process has began
   * and no geometry is available yet.
   * Works only when there are no models in the scene.
  */
  this._signalNoMeshes = function () {
    if (_modelQueue.isEmpty()) {
      this._geometryAvailable = 0;
    }
  };

  /**
   * Fires an event signaling that model data is available for rendering.
   * Called repeatedly whenever new geometry data is available for rendering,
   * but only a single event will get fired.
   */
  this._signalMeshAvailable = function () {
    if (this._geometryAvailable === 0) {
      this._geometryAvailable = 1;
      this.api.fireEvent({ type: et.RENDER_FIRST_PIXEL });
    }
  };

  /**
   * Whether any models have been loaded already.
   * A model is considered loaded as soon as the Model instance has
   * been added to RenderScene.
   */
  this.hasModels = function () {
    return !_modelQueue.isEmpty();
  };

  this.setDoNotCut = function (model, doNotCut) {
    model.setDoNotCut(_materials, doNotCut);
  };

  this.setViewportBounds = function (model, bounds) {
    model.setViewportBounds(_materials, bounds);
    this.invalidate(true);

    this.api.fireEvent({ type: et.MODEL_VIEWPORT_BOUNDS_CHANGED_EVENT, model, bounds });
  };

  // Only for debugging with Spector browser extension: Capture the next frame.
  // this.startSpectorCapture = function() {
  //     _spectorDump = true;
  // };
}

Viewer3DImpl.prototype.constructor = Viewer3DImpl;
GlobalManagerMixin.call(Viewer3DImpl.prototype);