function isAlphaNumeric(code) {
  return code && // code 0 is not alphanumeric
  code > 47 && code < 58 || // 0-9
  code > 64 && code < 91 || // A-Z
  code > 96 && code < 123; // a-z
}

/**
 * Classification is hierarchical.
 * This checks if `a` is `b` or a child of `b`
 * NOTE: This function assumes numbers have 2 digits and are not ( 00) if Masterformat-like
 */
function matchClassification(a, b) {
  // remove tuples of trailing zeros to handle
  // masterformat(' 00)- and omniclass('.00')-like hierarchy
  let bLength = b.length;
  while (b[bLength - 1] == '0' && b[bLength - 2] === '0') {
    const c = b[bLength - 3];
    if (c === ' ' || c === '.') bLength -= 3;else
    break;
  }

  // 'startsWith' ignoring non-alphanumeric.
  let
    ai = 0,ac = a.charCodeAt(ai),
    bi = 0,bc = b.charCodeAt(bi);

  while (ai < a.length && bi < bLength) {
    if (ac !== bc) return false;

    ai += 1;
    while (ai < a.length && !isAlphaNumeric(ac = a.charCodeAt(ai))) ai += 1;
    bi += 1;
    while (bi < bLength && !isAlphaNumeric(bc = b.charCodeAt(bi))) bi += 1;
  }
  return bi === bLength;
}

const ERROR_TYPE = {
  missingCode: 'Missing Code',
  missingDescription: 'Missing Description',
  missingLevel: 'Missing Level',
  invalidLevel: 'Invalid Level',
  invalidHierarchy: 'Invalid hierarchy',
  conflictingCode: 'Conflicting Code'
};

function checkClassificationSystem(rows) {let rowOffset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  const errors = {};
  let stack = ['ROOT'];
  const codes = new Set();
  let siblingCodes = [];
  let previousLevel;

  function logError(type, idx, row) {
    console.error(type, row);
    // previous row might affect later row hierarchy, so only log first error row of invalid hierarchy
    if (type === ERROR_TYPE.invalidHierarchy) {
      if (errors[type]) return;
    }
    // row number will be the row from spreadsheet
    (errors[type] = errors[type] || []).push(idx + rowOffset);
  }

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    let [code, description, level] = row;

    // Code, Description and Level are required
    if (!code) logError(ERROR_TYPE.missingCode, i, row);
    if (!description) logError(ERROR_TYPE.missingDescription, i, row);
    if (!level) logError(ERROR_TYPE.missingLevel, i, row);

    // Level must be valid number
    if (level && isNaN(parseInt(level))) logError(ERROR_TYPE.invalidLevel, i, row);
    level = parseInt(level);

    // Code must be hierarchical
    stack[level] = code;
    if (level > 1) {
      if (!stack[level - 1] || !matchClassification(stack[level], stack[level - 1])) {
        logError(ERROR_TYPE.invalidHierarchy, i, row);
      }
    }

    // check conflicts (code must be unique, sibling code can't be prefix of eath other)
    if (previousLevel !== level) siblingCodes = [];
    const conflicts = siblingCodes.filter((existing) => matchClassification(code, existing) && !stack.includes(existing));
    if (conflicts.length > 0 || codes.has(code)) {
      logError(ERROR_TYPE.conflictingCode, i, row);
    }
    codes.add(code);
    siblingCodes.push(code);
    previousLevel = level;
  }
  ;
  return errors;
}

module.exports = {
  matchClassification,
  checkClassificationSystem
};