import { DtConstants } from "../schema/DtConstants";
import { FocusedTime, TimeInterval } from './timeline/TelemetryView';

const { StreamStates, StreamStatePriority } = DtConstants;

export const OFFLINE_AGE = 15 * 60 * 1000;

/** This method assumes the value is the last read value from the entire history, not in a time range */
function evaluateState(thresholds, value, ts, skipOffline) {
  if (thresholds == null) return null;
  if (value == null) return StreamStates.NoData;
  if (ts && !skipOffline && ts < Date.now() - OFFLINE_AGE) return StreamStates.Offline;

  return evaluateSimpleState(thresholds, value, value);
}



/** Experimental. Could likely be made more general and integrated back. */
function evaluateAggregateState(thresholds, rollup) {
  if (thresholds == null) return null;
  if (rollup == null) return StreamStates.NoData;

  const [,, min, max] = rollup;
  return evaluateSimpleState(thresholds, min, max);
}

function evaluateSimpleState(_ref, min, max) {let { lower, upper } = _ref;
  // Alerts
  if (Number.isFinite(lower?.alert) && min <= lower.alert) return StreamStates.Alert;
  if (Number.isFinite(upper?.alert) && max >= upper.alert) return StreamStates.Alert;
  // Warnings
  if (Number.isFinite(lower?.warn) && min <= lower.warn) return StreamStates.Warning;
  if (Number.isFinite(upper?.warn) && max >= upper.warn) return StreamStates.Warning;
  // Else
  return StreamStates.Normal;
}

/**
 * 
 * @param {Object} stream
 * @param {Object} readings object that contains readings of all streams (aka streamsLastReadings)
 * @returns {boolean}
 */
function isOnline(stream, readings) {
  const lastUpdate = getLastUpdate(stream, readings);

  if (lastUpdate === null) return false;

  return Date.now() - lastUpdate < OFFLINE_AGE;
}

/**
 * 
 * @param {Object} stream
 * @param {Object} readings object that contains readings of all streams (aka streamsLastReadings)
 * @returns { string } One of StreamStates
 */
function getStatus(stream, readings) {
  const streamReadings = getStreamReadings(stream, readings);

  if (!streamReadings) return StreamStates.NoData;

  return stream.streamAttrs.reduce((currentStatus, attr) => {
    // TODO: why is it sometimes value and sometimes val? we should be able to agree on one property name
    const lastReading = streamReadings[attr.id]?.value ?? streamReadings[attr.id]?.val;
    const status = evaluateState(attr.allowedValues?.thresholds, lastReading);

    if (StreamStatePriority[status] > StreamStatePriority[currentStatus]) {
      return status;
    }

    return currentStatus;
  }, StreamStates.NoData);
}

/**
 * 
 * @param {Object} stream
 * @param {Object} readings object that contains readings of all streams (aka streamsLastReadings)
 * @returns { number | null }
 */
function getLastUpdate(stream, readings) {
  const streamReadings = getStreamReadings(stream, readings);

  if (!streamReadings) return null;

  return Object.keys(streamReadings).reduce((lts, attrId) => {
    const ts = streamReadings[attrId]?.time || streamReadings[attrId]?.ts || 0;
    return ts > lts ? ts : lts;
  }, 0);
}

function getStreamReadings(stream, readings) {
  if (!readings || !stream) return null;
  const streamReadings = readings[stream.dbId] || readings[stream.fullId];

  return streamReadings && Object.keys(streamReadings).length > 0 ? streamReadings : null;
}

function createStreamToReadingMap(streams, readings) {
  return streams.reduce((acc, stream, i) => {
    return { ...acc, [stream.fullId]: readings[i] };
  }, {});
}

export const StreamUtils = {
  evaluateState,
  evaluateAggregateState,
  isOnline,
  getStatus,
  getLastUpdate,
  createStreamToReadingMap,
  // Telemetry specifics
  FocusedTime,
  TimeInterval
};