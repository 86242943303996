import vertexTextureQuad from "../post/quad.vert.wgsl";

import { getBlurFragmentShader } from "./GroundShadowShaders";

export class GroundShadowBlurPipeline {

  #renderer;
  #device;

  #pipeline;

  #horizontal;
  #kernelScale;#kernelRadius;

  // Defines at which location the different bind groups are to be bound.
  // Everything that relies on this order is set up automatically, including the shader code.
  #bindGroupOrder = {
    texture: 0
  };
  #bindGroupLayouts = [];
  #targets = [];

  constructor(renderer, textureLayout, targetFormat, horizontal, kernelScale, kernelRadius) {
    this.#renderer = renderer;
    this.#device = renderer.getDevice();

    this.#horizontal = horizontal;

    this.#kernelScale = kernelScale;
    this.#kernelRadius = kernelRadius;

    for (let key in this.#bindGroupOrder) {
      switch (key) {
        case 'texture':
          this.#bindGroupLayouts[this.#bindGroupOrder[key]] = textureLayout;
          break;
      }
    }

    this.#targets.push({
      format: targetFormat
    });

    const vShader = this.#device.createShaderModule({
      label: 'ground shadow blur vertex shader',
      code: vertexTextureQuad
    });

    const fShader = this.#device.createShaderModule({
      label: 'ground shadow blur fragment shader',
      code: getBlurFragmentShader(this.#horizontal, this.#bindGroupOrder.texture)
    });

    this.#pipeline = this.#device.createRenderPipeline({
      label: 'ground shadow blur pipeline',
      layout: this.#device.createPipelineLayout({
        bindGroupLayouts: this.#bindGroupLayouts
      }),
      vertex: {
        module: vShader,
        entryPoint: 'mainFlipY'
      },
      fragment: {
        module: fShader,
        entryPoint: 'psmain',
        targets: this.#targets,
        constants: {
          0: this.#kernelScale,
          1: this.#kernelRadius
        }
      },
      primitive: {
        cullMode: 'back',
        topology: 'triangle-list'
      }
    });
  }

  getPipeline() {
    return this.#pipeline;
  }

  getBindGroupOrder() {
    return this.#bindGroupOrder;
  }
}