import * as THREE from 'three';

import i18n from "i18next";
import { FacetSpaces, FacetTypes } from "../../../facets/Facets";
import { HUD_LAYER, HudLayer } from '../HudLayer';
import LayerBackButton from "../LayerBackButton";
import { getFirstRoomHitBeforePt } from '../LayerUtils';
import RC from "../../../resources/cats_enum.json";
import { SpaceLabel } from './SpaceLabel';
import { registerSpacesCxtMenuCb, unregisterSpacesCxtMenuCb } from "./SpacesMenuActions";
import { guessPlacement } from './SpacesPlacement';
import { MESH_FAILED_EVENT } from '../../../loader/DtResourceCache';
import { MODEL_UNLOADED_EVENT } from '../../../../application/EventTypes';

/** Minimum pitch angle of the look at WRT scene up in turns, for space label to be visible. */
const MIN_CAM_PITCH = 0.376; // Just above pitch of clicking on view cube's edge.

// Maximum number of labels to update per frame
const BATCH_UPDATE_SIZE = 50;

/** Layer of see through labels, made to be seen top down, with a computed position */
export class SpacesLayer extends HudLayer {
  /** @type {?string} A space external ID if only a single space is in the filters. */
  isFocusedOn;

  #cachedPlaceByEid;
  #cancelHandle;
  #labelByEid;
  #models;
  #spaces;
  #skipRayIntersect;

  // Batched update cursors
  #updateCursor;

  #model2loadPromise;

  constructor(facility, hud, viewer) {
    super(HUD_LAYER.SPACES.id, facility, hud, viewer);

    this.#cachedPlaceByEid = {};
    this.#cancelHandle = null;
    this.#labelByEid = {};
    this.#models = [];
    this.#spaces = [];
    this.#updateCursor = 0;

    this.#model2loadPromise = {};
    this.backBtn = new LayerBackButton(this, viewer.container.ownerDocument, FacetTypes.spaces);
  }

  clear() {
    for (let eid in this.#labelByEid) {
      // Optional label here as we cache failure to create labels.
      this.#labelByEid[eid]?.dispose();
    }
    this.#labelByEid = {};
    this.#cachedPlaceByEid = {};
    this.#updateCursor = 0;

    this.#model2loadPromise = {};
  }

  dispose() {
    this.clear();
    this.backBtn.attachTo(null);
    unregisterSpacesCxtMenuCb(this.viewer);
    if (this.#cancelHandle) {
      this.#cancelHandle.isCancelled = true;
      this.#cancelHandle = null;
    }
  }

  init() {
    registerSpacesCxtMenuCb(this.viewer, this.facility);
    return this;
  }

  onCameraChanged(camera) {
    this.invalidate();
  }

  onClick(event, space) {
    if (!space) return;

    const { dbId, model } = space;
    if (Boolean(event.shiftKey)) {
      // Filters to the given space
      const filterMap = this.facility.facetsManager.getFilterMap();
      const spaceIdFilter = new Set([FacetSpaces.makeId(space)]);
      this.facility.facetsManager.setFilterMap({
        ...filterMap,
        spaces: spaceIdFilter
      });

    } else {
      this.viewer.select(dbId, model);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  onIsolationChanged(isolation) {
    this.#verifyConfiguration(this.facility.facetsManager.getFacetDefs());

    this.#models.length = 0;
    const spacesByEid = {};
    const addEntry = (_ref) => {let { model, rooms } = _ref;
      this.#models.push(model);
      rooms.forEach((room) => spacesByEid[room.externalId] = room);
    };

    if (isolation.length) {
      fromIsolation(isolation, addEntry);
    } else {
      fromFacility(this.facility, addEntry);
    }

    this.#setFromSpaces(spacesByEid);
  }

  onHiddenChanged( /*hidden*/) {
    this.invalidate();
  }

  onFacetsUpdated() {
    this.#verifyConfiguration(this.facility.facetsManager.getFacetDefs());
  }

  onModelChanged(_ref2) {let { change } = _ref2;
    const { modelId, dbIds } = change;
    const changed = new Set(dbIds);
    for (const space of this.#spaces) {
      const { dbId, externalId, model } = space;
      if (model.urn() === modelId && changed.has(dbId)) {
        // Room name is the only relevant property that could change for the moment.
        this.#labelByEid[externalId]?.updateRoomName();
      }
    }
  }

  onClusterTransition(clustered) {
    // skip ray intersection if clustered
    this.#skipRayIntersect = clustered;

    // Apply animation transforms
    for (const id in this.#labelByEid) {
      const label = this.#labelByEid[id];
      label?.updateWorldBox();
      label?.updateLabelPosition();
    }
  }

  onLayerVisibilityChanged(visible) {
    this.invalidate();
  }

  onMouseOver(event, space) {
    this.facility.facetsManager.facetsEffects.addSpaceHighlight(space);

    event.preventDefault();
    event.stopPropagation();
  }

  onMouseOut(event) {
    this.facility.facetsManager.facetsEffects.clearFacetHighlight();

    event.preventDefault();
    event.stopPropagation();
  }

  invalidate() {
    super.invalidate();
    this.#updateCursor = 0;
  }

  defineLabelVisibility(space, label, drawRect) {
    let shouldBeVisible = label.isSpaceVisible() &&
    label.hasEnoughSpace(drawRect) && (
    this.#skipRayIntersect || this.#isVisibleByRay(space, label));

    if (shouldBeVisible && !label.isInDOM()) {
      label.setVisible(true); // Create DOM label for accurate dimensions
      shouldBeVisible = label.hasEnoughSpace(drawRect);
    }

    // Don't show label if it creates overlap with previously shown labels
    if (shouldBeVisible) {
      label.updateViewBox();

      for (let j = 0; j < this.#updateCursor; j++) {
        const prevSpace = this.#spaces[j];
        const prevLabel = this.#labelByEid[prevSpace.externalId];

        if (!prevLabel?.isVisible()) continue;

        // TODO: Simplify labels intersection by reusing LevelLabelPlacer
        if (label.viewBox.intersectsBox(prevLabel.viewBox)) {
          shouldBeVisible = false;
          break;
        }
      }
    }

    return shouldBeVisible;
  }

  update(ts, ctx) {
    if (this.#cancelHandle || !this.#hasAnyCachedPlacement()) {
      return true; // Keep dirty while loading.
    }

    // Check if the room is in an odd angle wrt to the camera, in which we want no label.
    const correctPitch = ctx.cameraLookAtPitch >= MIN_CAM_PITCH;
    const isLayerVisible = this.isVisible();
    const drawRect = this.viewer.canvasWrap.getBoundingClientRect();
    const labelsOn = correctPitch && isLayerVisible;

    let hasChanged = false;

    // Update everything if it's quick (all off)
    const batchSize = labelsOn ? BATCH_UPDATE_SIZE : this.#spaces.length;

    for (let i = 0; i < batchSize; i++, this.#updateCursor++) {

      // Stop and reset cursor once all labels have been updated
      if (this.#updateCursor === this.#spaces.length) {
        this.#updateCursor = 0;
        break;
      }

      const space = this.#spaces[this.#updateCursor];
      const label = this.#getOrMakeLabel(space);

      if (label) {
        const oldIsVisible = label.isVisible();
        const shouldBeVisible = labelsOn && this.defineLabelVisibility(space, label, drawRect);

        if (shouldBeVisible) {
          label.refreshMaxWidth();
        }

        if (label.isVisible() !== shouldBeVisible) {
          label.setVisible(shouldBeVisible);
        }

        hasChanged ||= shouldBeVisible !== oldIsVisible;
      }
    }

    // Update back navigation
    const focusedCtrl = this.isFocusedOn && this.#labelByEid[this.isFocusedOn];
    this.backBtn.attachTo(focusedCtrl);

    ctx.layerChanged[HUD_LAYER.SPACES] = hasChanged;

    // Layer is no longer dirty if cursor is reset
    return this.#updateCursor !== 0;
  }

  /** Ray from screen to label hits labelled room first. */
  #isVisibleByRay(space, label) {
    if (!label.placement) {
      return false;
    }

    const first = getFirstRoomHitBeforePt(this.viewer.impl, label.placement.position, this.#models);
    if (first === undefined) return true; // no hits
    if (first === null) return false; // unable to ascertain hit.

    // You got to be first
    return first.dbId === space.dbId && first.model === space.model;
  }

  #hasAnyCachedPlacement() {
    for (let eid in this.#cachedPlaceByEid) return true;
    return false;
  }

  #getOrMakeLabel(space) {
    let label = this.#labelByEid[space.externalId];
    if (label !== undefined) {
      return label; // Null label means we cache our inability to produce a label.
    }

    const placement = this.#cachedPlaceByEid[space.externalId];
    if (space && placement?.position) {
      label = new SpaceLabel(space, placement, this.hud);

      // Label has a "clickSelects" property we are not using to override the toggle select behaviour.
      label.setClickHandler(this.onClick.bind(this));
      label.setMouseOverHandler(this.onMouseOver.bind(this));
      label.setMouseOutHandler(this.onMouseOut.bind(this));
    } else {
      // console.debug('unable to create a label for incorrect configuration', space, placement);
      label = null;
    }

    return this.#labelByEid[space.externalId] = label;
  }

  /** Initialize from a set of spaces in view */
  #setFromSpaces(spacesByEid) {
    if (this.#cancelHandle) {
      // Prevent ongoing computation from writing its results.
      this.#cancelHandle.isCancelled = true;
      this.#cancelHandle = null;
    }
    // Set new cancellable handle
    const cancelHandle = { isCancelled: false };
    this.#cancelHandle = cancelHandle;

    const newSpaces = Object.values(spacesByEid);

    this.#waitForModelBvh().then(() => {
      if (cancelHandle.isCancelled) {
        // console.info('Placement update was cancelled by concurrent isolation change event.');
        return;
      }

      // Only recompute the placement of spaces we haven't computed yet (now that we have an un-animated position)
      const unknowns = newSpaces.filter((_ref3) => {let { externalId } = _ref3;return !(externalId in this.#cachedPlaceByEid);});
      const placementByEid = guessPlacement(this.viewer, unknowns);

      // Hide outdated labels.
      for (const old of this.#spaces) {
        if (!spacesByEid[old.externalId]) {
          this.#labelByEid[old.externalId]?.setVisible(false);
        }
      }
      this.#spaces = [];

      const tmpBox = new THREE.Box3();
      for (const s of newSpaces) {
        s.model.getElementBounds(s.dbId, tmpBox);

        this.#spaces.push({
          ...s, // Avoid polluting model.room with space sorting heuristic
          sizeEstimate: tmpBox.min.distanceToSquared(tmpBox.max)
        });
      }

      this.#spaces.sort(byVisibilityPriority);

      // Always add to the cache.
      this.#cachedPlaceByEid = Object.assign(this.#cachedPlaceByEid, placementByEid);

      this.#cancelHandle = null;
      this.invalidate();
    }).
    catch((err) => console.warn('Unable to guess space label placement', err));
  }

  #verifyConfiguration(facetDefs) {
    let spaceFacet;
    let seesRooms = true;
    for (const facet of facetDefs) {
      const { filter, id } = facet;

      if (id === FacetTypes.spaces) {
        spaceFacet = facet;
      } else if (id === FacetTypes.categories) {
        seesRooms = filter.size === 0 || filter.has(RC.Rooms) || filter.has(RC.MEPSpaces);
      }
    }

    if (spaceFacet?.filter.size === 1) {
      const [head] = spaceFacet.filter;
      // The filter is formatted as modelUrn:externalId as seen in Facet.js
      this.isFocusedOn = head.substring(head.lastIndexOf(':') + 1);
    } else {
      this.isFocusedOn = null;
    }

    if (!spaceFacet) {
      this.setErrorMsg(i18n.t("Missing required filter Standard > Spaces"));
      return;
    }

    if (!seesRooms) {
      this.setErrorMsg(i18n.t("The Revit categories for rooms are filtered out"));
      return;
    }

    // Reset error message.
    this.setErrorMsg();
  }

  // waits for the rooms geometry of all relevant models and the "consolidated bvh"
  async #waitForModelBvh() {

    let promises = this.#models.map((model) => {
      if (!this.#model2loadPromise[model.urn()]) {
        this.#model2loadPromise[model.urn()] = waitForRoomsAndBvh(this.facility.viewer, model);
      }
      return this.#model2loadPromise[model.urn()];
    });

    return Promise.all(promises);
  }

  getCachedPlacement(model, dbId) {
    const room = model.getData()?.roomMap[dbId];
    if (!room) return;

    const placement = this.#cachedPlaceByEid[room.externalId];
    if (!placement) return;

    const label = this.#labelByEid[room.externalId];
    const position = label?.label?.pos3D;

    return { position: position ?? placement.position };
  }
}

async function waitForRoomsAndBvh(viewer, model) {
  await model.waitForLoad(true, true); // TODO: given that we received the model via isolation event, this is probably not required / a no op

  const roomFragIds = await model.loadRoomFragments();
  if (roomFragIds) {
    await waitForGeometries(viewer, model, roomFragIds);
  }

  model.buildRoomBvh();
}

function waitForGeometries(viewer, model, fragIds) {

  const fl = model.getFragmentList();
  const failedFrags = model.getData().failedFrags;

  // narrow down the list of given fragment ids to those
  // that neither have a geometry nor are in failed state
  for (let fragId of fragIds) {
    const geom = fl.getGeometry(fragId);
    if (geom) {
      fragIds.delete(fragId);
    } else if (failedFrags[fragId]) {
      fragIds.delete(fragId);
    }
  }

  return new Promise((resolve, reject) => {
    if (fragIds.size == 0) {
      resolve();
    }

    const geomCache = viewer.impl.geomCache();

    function checkIfDone() {
      if (fragIds.size == 0) {
        cleanup();
        resolve();
      }
    }

    function oneMeshSet(fragId) {
      fragIds.delete(fragId);
      checkIfDone();
    }

    function onMeshFailed() {
      // we don't know whether the failed mesh belongs to a fragment we
      // are waiting for, so we we loop over all of them and see if some
      // ended up in the failedFrags map.
      for (let fragId of fragIds) {
        if (failedFrags[fragId]) {
          fragIds.delete(fragId);
        }
      }
      checkIfDone();
    }

    function onModelRemoved(e) {
      if (e.model === model) {
        cleanup();
        reject();
      }
    }

    function cleanup() {
      fl.removeMeshSetCallback(oneMeshSet);
      geomCache.removeEventListener(MESH_FAILED_EVENT, onMeshFailed);
      viewer.removeEventListener(MODEL_UNLOADED_EVENT, onModelRemoved);
    }

    fl.registerMeshSetCallback(oneMeshSet);
    geomCache.addEventListener(MESH_FAILED_EVENT, onMeshFailed);
    viewer.addEventListener(MODEL_UNLOADED_EVENT, onModelRemoved);
  });
}

// From Non-MEP to MEP, then from larger to smaller spaces
function byVisibilityPriority(a, b) {
  if (a.isMEPSpace !== b.isMEPSpace) {
    return a.isMEPSpace ? 1 : -1;
  }

  return b.sizeEstimate - a.sizeEstimate;
}

function fromIsolation(isolation, addEntry) {
  for (const { model, ids } of isolation) {
    const roomMap = model.getRooms();
    if (!roomMap) continue;

    const rooms = [];
    for (const dbId of ids) {
      const room = roomMap[dbId];
      if (room) {
        rooms.push(room);
      }
    }

    addEntry({ model, rooms });
  }
}

function fromFacility(facility, addEntry) {
  for (const model of facility.getModels()) {
    const roomMap = model.getRooms();
    if (!roomMap) continue;

    const rooms = [];
    for (const key in roomMap) {
      // Rooms are there twice, keeping keys by dbId and skipping external ids.
      if (key.length >= 20) {
        continue;
      }

      rooms.push(roomMap[key]);
    }

    addEntry({ model, rooms });
  }
}