import { fuzzy } from 'fast-fuzzy';

export function MakeStringMatcher(searchTerm) {
  return function (text, returnMatchData) {
    let result = fuzzy(searchTerm, text, { useSeparatedUnicode: true, returnMatchData });
    if (returnMatchData) {
      return result.score > 0.9 ? result.match : null;
    } else {
      return result > 0.9;
    }
  };
}