import i18n from "i18next";

import { Button } from '../../../src/gui/controls/Button';
import { ControlGroup } from '../../../src/gui/controls/ControlGroup';
import { ToolBar } from '../../../src/gui/toolbar/ToolBar';

import { ExtrudeShapeAction } from './ExtrudeShapeAction';
import { createRoom } from './CreateRoom';

const av = Autodesk.Viewing;

const PolygonIcon = `
	<svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fill-rule="nonzero">
			<circle fill="#039BE5" cx="20.5" cy="3.5" r="1.5"></circle>
			<circle fill="#039BE5" cx="20.5" cy="20.5" r="1.5"></circle>
			<circle fill="#039BE5" cx="3.5" cy="20.5" r="1.5"></circle>
			<circle fill="#039BE5" cx="3.5" cy="3.5" r="1.5"></circle>
			<path fill="currentColor" d="M6 3h12v1H6zM21 6v12h-1V6zM6 20h12v1H6zM4 6v12H3V6z"></path>
		</g>
	</svg>
`;

export class SketchToolsToolbar {
  constructor(facility, viewer, edit2dExt, placementXform) {
    this.facility = facility;
    this.viewer = viewer;
    this.edit2dExt = edit2dExt;

    this.selectedShape = null;

    this.#addToolbar();
    this.#setupEditingTools(placementXform);
  }

  dtor() {
    this.selectedShape = null;

    let ctx = this.edit2dExt.defaultContext;
    ctx.undoStack.removeEventListener(Autodesk.Edit2D.UndoStack.BEFORE_ACTION, this.boundOnBeforeAction);
    ctx.undoStack.removeEventListener(Autodesk.Edit2D.UndoStack.AFTER_ACTION, this.boundOnAfterAction);
    ctx.selection.removeEventListener(Autodesk.Edit2D.Selection.Events.SELECTION_CHANGED, this.boundOnToolSelectionChanged);
    ctx.selection.removeEventListener(Autodesk.Edit2D.Selection.Events.SELECTION_HOVER_CHANGED, this.boundOnToolHoverSelectionChanged);

    this.edit2dExt.unregisterDefaultTools();

    this.viewer.container.removeChild(this.sketchingToolbar.container);

    this.tools.removeControl(this.addShapeButton);
    this.tools.removeControl(this.editShapeButton);
    this.tools.removeControl(this.saveButton);
    this.addShapeButton = null;
    this.editShapeButton = null;
    this.saveButton = null;

    this.sketchingToolbar.removeControl(this.tools);
    this.tools = null;
    this.sketchingToolbar = null;
  }

  #addShape(shape) {
    let ctx = this.edit2dExt.defaultContext;
    ctx.selection.setSelection([shape]);

    this.#activateEditingTool();

    this.selectedShape = shape;

    const BtnStates = av.UI.Button.State;
    this.addShapeButton.setState(BtnStates.INACTIVE);
    this.editShapeButton.setState(BtnStates.ACTIVE);
    this.saveButton.setState(BtnStates.INACTIVE);
  }

  #removeShape(shape) {
    this.selectedShape = null;

    const BtnStates = av.UI.Button.State;
    this.editShapeButton.setState(BtnStates.DISABLED);
    this.saveButton.setState(BtnStates.DISABLED);
  }

  #selectShape(selection) {
    if (selection.hoveredId !== 0) {
      this.selectedShape = Object.values(selection.isSelected)[0];
    } else {
      this.selectedShape = null;
    }

    const BtnStates = av.UI.Button.State;
    this.saveButton.setState(this.selectedShape ? BtnStates.INACTIVE : BtnStates.DISABLED);
  }

  #addToolbar() {
    this.addShapeButton = new Button("sketching-toolbar-addShapeButton");
    this.addShapeButton.setToolTip(i18n.t("Add Space Polygon"));
    this.addShapeButton.addClass("sketching-toolbar-tooltip");
    this.addShapeButton.icon.innerHTML = PolygonIcon;

    const BtnStates = av.UI.Button.State;

    this.addShapeButton.setState(BtnStates.ACTIVE);
    this.addShapeButton.onClick = () => {
      let ctx = this.edit2dExt.defaultContext;
      ctx.selection.setSelection(null);

      const state = this.addShapeButton.getState();
      if (state === BtnStates.INACTIVE) {
        this.addShapeButton.setState(BtnStates.ACTIVE);
        this.#activateDrawingTool();
      } else if (state === BtnStates.ACTIVE) {
        this.addShapeButton.setState(BtnStates.INACTIVE);
        this.#deactivateAll();
      }

      this.editShapeButton.setState(BtnStates.INACTIVE);
      this.saveButton.setState(BtnStates.DISABLED);
    };

    this.editShapeButton = new Button("sketching-toolbar-editShapeButton");
    this.editShapeButton.setToolTip(i18n.t("Edit Space Geometry"));
    this.editShapeButton.addClass("sketching-toolbar-tooltip");
    this.editShapeButton.addClass("adsk-label-button");
    this.editShapeButton.icon.classList.remove("adsk-button-icon");
    this.editShapeButton.icon.classList.add("sketching-toolbar-button-icon");
    this.editShapeButton.icon.innerHTML = i18n.t("Edit");

    this.editShapeButton.setState(BtnStates.DISABLED);
    this.editShapeButton.onClick = () => {
      const state = this.editShapeButton.getState();
      if (state === BtnStates.INACTIVE) {
        this.editShapeButton.setState(BtnStates.ACTIVE);
        this.#activateEditingTool();
      } else if (state === BtnStates.ACTIVE) {
        this.editShapeButton.setState(BtnStates.INACTIVE);
        this.#deactivateAll();
      }

      this.addShapeButton.setState(BtnStates.INACTIVE);
    };

    this.saveButton = new Button("sketching-toolbar-saveButton");
    this.saveButton.setToolTip(i18n.t("Save Space Geometry"));
    this.saveButton.addClass("sketching-toolbar-tooltip");
    this.saveButton.addClass("adsk-label-button");
    this.saveButton.icon.classList.remove("adsk-button-icon");
    this.saveButton.icon.classList.add("sketching-toolbar-button-icon");
    this.saveButton.icon.innerHTML = i18n.t("Save");

    this.saveButton.setState(BtnStates.DISABLED);
    this.saveButton.onClick = () => {
      if (!this.selectedShape) {
        return;
      }

      createRoom(this.facility, this.viewer, this.selectedShape);
    };

    this.tools = new ControlGroup("sketching-toolbar-buttons");
    this.tools.addControl(this.addShapeButton);
    this.tools.addControl(this.editShapeButton);
    this.tools.addControl(this.saveButton);

    this.sketchingToolbar = new ToolBar("sketching-toolbar");
    this.sketchingToolbar.addClass('sketching-toolbar');
    this.sketchingToolbar.addControl(this.tools);

    this.viewer.container.appendChild(this.sketchingToolbar.container);
  }

  #setupEditingTools(placementXform) {
    this.boundOnBeforeAction = this.#onBeforeAction.bind(this);
    this.boundOnAfterAction = this.#onAfterAction.bind(this);
    this.boundOnToolSelectionChanged = this.#onToolSelectionChanged.bind(this);
    this.boundOnToolHoverSelectionChanged = this.#onToolHoverSelectionChanged.bind(this);

    /*
    this.boundActionCallback = this.#onCreateActionCallback.bind(this);
    this.ctxMenuOptions = {
    	menuItems: [{
    		title: i18n.t('Extrude Space Polygon'),
    		callback: this.boundActionCallback
    	}]
    };
    */

    this.edit2dExt.registerDefaultTools(this.ctxMenuOptions);

    let ctx = this.edit2dExt.defaultContext;
    ctx.setMatrix(placementXform);

    if (!ctx.undoStack.hasEventListener(Autodesk.Edit2D.UndoStack.BEFORE_ACTION, this.boundOnBeforeAction)) {
      ctx.undoStack.addEventListener(Autodesk.Edit2D.UndoStack.BEFORE_ACTION, this.boundOnBeforeAction);
    }
    if (!ctx.undoStack.hasEventListener(Autodesk.Edit2D.UndoStack.AFTER_ACTION, this.boundOnAfterAction)) {
      ctx.undoStack.addEventListener(Autodesk.Edit2D.UndoStack.AFTER_ACTION, this.boundOnAfterAction);
    }
    if (!ctx.selection.hasEventListener(Autodesk.Edit2D.Selection.Events.SELECTION_CHANGED, this.boundOnToolSelectionChanged)) {
      ctx.selection.addEventListener(Autodesk.Edit2D.Selection.Events.SELECTION_CHANGED, this.boundOnToolSelectionChanged);
    }
    if (!ctx.selection.hasEventListener(Autodesk.Edit2D.Selection.Events.SELECTION_HOVER_CHANGED, this.boundOnToolHoverSelectionChanged)) {
      ctx.selection.addEventListener(Autodesk.Edit2D.Selection.Events.SELECTION_HOVER_CHANGED, this.boundOnToolHoverSelectionChanged);
    }

    this.viewer.toolController.activateTool(this.edit2dExt.defaultTools.polygonTool.getName());
  }

  #activateDrawingTool() {
    let controller = this.viewer.toolController;
    controller.setIsLocked(false);
    controller.deactivateTool(this.edit2dExt.defaultTools.polygonEditTool.getName());
    controller.activateTool(this.edit2dExt.defaultTools.polygonTool.getName());
  }

  #activateEditingTool() {
    let controller = this.viewer.toolController;
    controller.setIsLocked(false);
    controller.deactivateTool(this.edit2dExt.defaultTools.polygonTool.getName());
    controller.activateTool(this.edit2dExt.defaultTools.polygonEditTool.getName());
    this.edit2dExt.defaultTools.polygonEditTool.onSelectionChanged();
  }

  #deactivateAll() {
    let controller = this.viewer.toolController;
    controller.setIsLocked(false);
    controller.deactivateTool(this.edit2dExt.defaultTools.polygonTool.getName());
    controller.deactivateTool(this.edit2dExt.defaultTools.polygonEditTool.getName());
  }

  #onBeforeAction() {

    //console.log(params);
  }
  #onAfterAction(_ref) {let { action } = _ref;
    if (action.constructor.name === "AddVertex" || action.constructor.name === "MoveVertex" || action.constructor.name === "RemoveVertex" ||
    action.constructor.name === "MoveEdge" || action.constructor.name === "MoveShapes") {
      return;
    }

    /*
    if (action instanceof ExtrudeShapeAction) {
    	return;
    }
    */

    if (action.constructor.name === "RemoveShape") {
      this.#removeShape(action.shape);
      return;
    }

    const canvasPos = action.layer.tmp_p0;

    let res = [];
    const dbId = this.viewer.impl.renderer().idAtPixel(canvasPos.x, canvasPos.y, res);
    console.log("DB ID: ", dbId);

    const modelId = res[1];
    if (modelId > 0) {
      const model = this.viewer.impl.modelQueue().findModel(modelId);

      const levelId = model.myData.dbId2levelId[dbId];
      console.log("Level ID: ", levelId);

      // create action has a property "shape" assigned to it, editing actions have "poly"
      let shape = action.shape || action.poly;
      if (!shape) {
        throw new Error("Missing polygon after editing action");
      }

      shape.customData = {
        dbId: dbId,
        model: model,
        level: model.myData.levelMap[levelId]
      };

      console.log("Level: ", model.myData.levelMap[levelId]);

      this.#addShape(shape);
    }
  }

  #onToolSelectionChanged(_ref2) {let { target } = _ref2;
    this.#selectShape(target);
  }

  #onToolHoverSelectionChanged() {

    //console.log(params);
  }
  /*
  #onCreateActionCallback(viewer, layer, shapeUnderMouse) {
  	// TODO:
  	//   Right now, it will execute the same "create room" action as on saving.
  	//   The idea is to use it for any (UI-)related extrusion tasks.
  	return new ExtrudeShapeAction(this.facility, viewer, layer, shapeUnderMouse);
  }
  */

};