import { base64DecToArr, base64EncArr } from "../../dt/encoding/base64";
import { LmvVector3 } from "./LmvVector3";
import { LmvMatrix4 } from "./LmvMatrix4";

import * as THREE from "three";

export { base64EncArr };

export const BBOX_SIZE = 28;
export const FRAG_SIZE = 84;

function isBinaryHash(v) {
  return !!v.buffer;
}

export function serializeFragment(dtClass, geometryHash, materialHash, transform) {
  const dtClassFlag = (dtClass | 0) << 24;

  // Create byte array
  let buffer = new ArrayBuffer(FRAG_SIZE); // we create only one fragment!

  // Geometry hash starts at offset 0 and is 20 bytes long
  let offset = 0;
  let bufferView = new Uint8Array(buffer, offset, 20);

  let encodedLength;

  if (isBinaryHash(geometryHash)) {
    bufferView.set(geometryHash);
    encodedLength = geometryHash.length;
  } else {
    encodedLength = base64DecToArr(geometryHash, bufferView, offset);
  }

  if (encodedLength != 20) {
    throw new Error('Encoded OTG hash length should be equal to 20');
  }

  // Material hash starts at offset 20 and is 20 bytes long
  // If it is not defined, the default material will be used
  offset += 20;
  if (materialHash) {
    bufferView = new Uint8Array(buffer, offset);

    if (isBinaryHash(materialHash)) {
      bufferView.set(materialHash);
      encodedLength = materialHash.length;
    } else {
      encodedLength = base64DecToArr(materialHash, bufferView);
    }

    if (encodedLength != 20) {
      throw new Error('Encoded Material hash length should be equal to 20');
    }
  }

  // 4 byte flags starting at offset 40, little endian
  offset += 20;
  bufferView = new DataView(buffer, offset, 4);
  bufferView.setUint32(0, dtClassFlag, true);

  // Transform matrix starts at offset 44 and is 40 bytes long
  offset += 4;
  bufferView = new Float32Array(buffer, offset, 10);

  let p = new LmvVector3();
  let s = new LmvVector3();
  let q = new THREE.Quaternion();
  let m = new LmvMatrix4(true).fromArray(transform);
  m.decompose(p, q, s);

  // position
  bufferView[0] = p.x;
  bufferView[1] = p.y;
  bufferView[2] = p.z;
  // quaternion
  bufferView[3] = q.x;
  bufferView[4] = q.y;
  bufferView[5] = q.z;
  bufferView[6] = q.w;
  // scale
  bufferView[7] = s.x;
  bufferView[8] = s.y;
  bufferView[9] = s.z;

  return new Uint8Array(buffer);
}

export function serializeBoundingBox(boundingBox) {
  const min = boundingBox.min;
  const max = boundingBox.max;

  let buffer = new ArrayBuffer(BBOX_SIZE);
  let bufferView = new Float32Array(buffer, 0, 6);

  bufferView[0] = min.x;
  bufferView[1] = min.y;
  bufferView[2] = min.z;

  bufferView[3] = max.x;
  bufferView[4] = max.y;
  bufferView[5] = max.z;

  return new Uint8Array(buffer);
}