import { isNodeJS } from "../../../compat";
import { InputStream } from "../../loader/InputStream";
import { LmvBox3 } from "../../../wgs/scene/LmvBox3";

/*
Integers encoded in *little endian*

Magic header: LMV0 (4 bytes)
Flags: 2 bytes (isLine, isPoint, isWideLine, etc.)
Num buffers: 1 byte
Num attributes: 1 byte (attributes are fixed size)
Buf Offsets (from beginning of data block, first buffer is always at 0, so is skipped): 4 bytes each
Attributes: {
	Name: 1 byte enum (Index, IndexEdges, Position, Normal, TextureUV, Color)
	itemSize: 1/2 byte low nibble (must be 1,2,3 or 4)
	itemType: 1/2 byte hi nibble (BYTE, SHORT, UBYTE, USHORT, FLOAT ...)
	itemOffset: 1 byte (in bytes)
	itemStride: 1 byte (stride in bytes)
	buffer Idx: 1 bytes
} (5 bytes each)

(padding bytes to make data stream offset a multiple of 4)

Data: binary, concatenated vertex and index streams
*/

export const AttributeName = {
  Index: 0,
  IndexEdges: 1,
  Position: 2,
  Normal: 3,
  TextureUV: 4,
  Color: 5
};

export const AttributeType = {
  BYTE: 0,
  SHORT: 1,
  UBYTE: 2,
  USHORT: 3,

  BYTE_NORM: 4,
  SHORT_NORM: 5,
  UBYTE_NORM: 6,
  USHORT_NORM: 7,

  FLOAT: 8,
  INT: 9,
  UINT: 10
  //DOUBLE: 11
};


export const MeshFlags = {
  //NOTE: Lower two bits are NOT A BITMASK!!!
  TRIANGLES: 0,
  LINES: 1,
  POINTS: 2,
  WIDE_LINES: 3


};

const OTG2LMVAttr = {};
OTG2LMVAttr[AttributeName.Position] = "position";
OTG2LMVAttr[AttributeName.Normal] = "normal";
OTG2LMVAttr[AttributeName.Index] = "index";
OTG2LMVAttr[AttributeName.IndexEdges] = "indexlines";
OTG2LMVAttr[AttributeName.Color] = "color";
OTG2LMVAttr[AttributeName.TextureUV] = "uv";


const AttributeTypeToSize = {};
AttributeTypeToSize[AttributeType.BYTE] = 1;
AttributeTypeToSize[AttributeType.SHORT] = 2;
AttributeTypeToSize[AttributeType.UBYTE] = 1;
AttributeTypeToSize[AttributeType.USHORT] = 2;
AttributeTypeToSize[AttributeType.BYTE_NORM] = 1;
AttributeTypeToSize[AttributeType.SHORT_NORM] = 2;
AttributeTypeToSize[AttributeType.UBYTE_NORM] = 1;
AttributeTypeToSize[AttributeType.USHORT_NORM] = 2;
AttributeTypeToSize[AttributeType.FLOAT] = 4;
AttributeTypeToSize[AttributeType.INT] = 4;
AttributeTypeToSize[AttributeType.UINT] = 4;

//DOUBLE: 11

function deltaDecodeIndexBuffer3(ib) {

  if (!ib.length)
  return;

  ib[1] += ib[0];
  ib[2] += ib[0];

  for (let i = 3; i < ib.length; i += 3) {
    ib[i] += ib[i - 3];
    ib[i + 1] += ib[i];
    ib[i + 2] += ib[i];
  }
}

function deltaDecodeIndexBuffer2(ib) {

  if (!ib.length)
  return;

  ib[1] += ib[0];

  for (let i = 2; i < ib.length; i += 2) {
    ib[i] += ib[i - 2];
    ib[i + 1] += ib[i];
  }
}

function attrNameToLMV(attrName) {

  let lmvAttr = OTG2LMVAttr[attrName];
  if (lmvAttr)
  return lmvAttr;

  console.error("Unknown vertex attribute");
  return AttributeName.TextureUV;
}


function OtgGeomDecoder(buf) {

  this.buffer = buf;
  this.readOffset = 0;

  this.meshFlag = 0;
  this.numBuffers = 0;
  this.numAttributes = 0;
  this.bufferOffsets = [];
  this.attributes = [];
  this.buffers = [];
}


OtgGeomDecoder.prototype.readNodeJS = function () {

  let magic = this.buffer.toString("ascii", 0, 4);
  if (magic !== "OTG0") {
    console.error("Invalid OTG header");
    return false;
  }

  this.readOffset = 4;

  this.meshFlag = this.buffer.readUInt16LE(this.readOffset);
  this.readOffset += 2;

  this.numBuffers = this.buffer.readUInt8(this.readOffset);
  this.readOffset++;

  this.numAttributes = this.buffer.readUInt8(this.readOffset);
  this.readOffset++;

  if (this.numBuffers) {
    this.bufferOffsets.push(0);

    for (let i = 1; i < this.numBuffers; i++) {
      let boff = this.buffer.readUInt32LE(this.readOffset);
      this.readOffset += 4;
      this.bufferOffsets.push(boff);
    }
  }

  for (let i = 0; i < this.numAttributes; i++) {
    let attr = {};

    attr.name = this.buffer.readUInt8(this.readOffset);
    this.readOffset++;

    let type = this.buffer.readUInt8(this.readOffset);
    this.readOffset++;

    attr.itemSize = type & 0xf;
    attr.type = type >> 4;

    attr.bytesPerItem = AttributeTypeToSize[attr.type];

    attr.normalized = attr.type === AttributeType.BYTE_NORM ||
    attr.type === AttributeType.SHORT_NORM ||
    attr.type === AttributeType.UBYTE_NORM ||
    attr.type === AttributeType.USHORT_NORM;


    attr.itemOffset = this.buffer.readUInt8(this.readOffset) / 4;
    this.readOffset++;

    attr.itemStride = this.buffer.readUInt8(this.readOffset) / 4;
    this.readOffset++;

    attr.bufferIndex = this.buffer.readUInt8(this.readOffset);
    this.readOffset++;

    this.attributes.push(attr);
  }

  //seek to the beginning of the buffer data
  while (this.readOffset % 4 !== 0)
  this.readOffset++;

  for (let i = 0; i < this.bufferOffsets.length; i++) {

    let startOffset = this.readOffset + this.bufferOffsets[i];
    let endOffset;

    if (i < this.bufferOffsets.length - 1) {
      endOffset = this.readOffset + this.bufferOffsets[i + 1];
    } else {
      endOffset = this.buffer.length;
    }

    this.buffers.push(this.buffer.slice(startOffset, endOffset));
  }

  return true;
};


OtgGeomDecoder.prototype.readWeb = function () {

  let stream = new InputStream(this.buffer);

  let magic = stream.getString(4);
  if (magic !== "OTG0") {
    console.error("Invalid OTG header");
    return false;
  }


  this.meshFlag = stream.getUint16();
  this.numBuffers = stream.getUint8();
  this.numAttributes = stream.getUint8();

  if (this.numBuffers) {
    this.bufferOffsets.push(0);

    for (let i = 1; i < this.numBuffers; i++) {
      let boff = stream.getUint32();
      this.bufferOffsets.push(boff);
    }
  }

  for (let i = 0; i < this.numAttributes; i++) {
    let attr = {};

    attr.name = stream.getUint8();

    let type = stream.getUint8();

    attr.itemSize = type & 0xf;
    attr.type = type >> 4;

    attr.bytesPerItem = AttributeTypeToSize[attr.type];

    attr.normalized = attr.type === AttributeType.BYTE_NORM ||
    attr.type === AttributeType.SHORT_NORM ||
    attr.type === AttributeType.UBYTE_NORM ||
    attr.type === AttributeType.USHORT_NORM;


    attr.itemOffset = stream.getUint8() / 4;

    attr.itemStride = stream.getUint8() / 4;

    attr.bufferIndex = stream.getUint8();

    this.attributes.push(attr);
  }

  //seek to the beginning of the buffer data
  while (stream.offset % 4 !== 0)
  stream.offset++;

  for (let i = 0; i < this.bufferOffsets.length; i++) {

    let startOffset = stream.offset + this.bufferOffsets[i];
    let endOffset;

    if (i < this.bufferOffsets.length - 1) {
      endOffset = stream.offset + this.bufferOffsets[i + 1];
    } else {
      endOffset = stream.byteLength;
    }

    this.buffers.push(this.buffer.subarray(startOffset, endOffset));
  }

  return true;
};


OtgGeomDecoder.prototype.read = function () {

  if (isNodeJS() && this.buffer instanceof Buffer) {
    return this.readNodeJS();
  } else {
    return this.readWeb();
  }
};


let unitBox = new LmvBox3();
unitBox.min.x = -0.5;
unitBox.min.y = -0.5;
unitBox.min.z = -0.5;
unitBox.max.x = 0.5;
unitBox.max.y = 0.5;
unitBox.max.z = 0.5;

//var unitSphere = new THREE.Sphere();
//unitSphere.radius = Math.sqrt(0.5 * 0.5 * 3);
var unitSphere = {
  center: { x: 0, y: 0, z: 0 },
  radius: Math.sqrt(0.5 * 0.5 * 3)
};

export function readLmvBufferGeom(buffer, skipEdges) {

  let dec = new OtgGeomDecoder(buffer);

  if (!dec.read()) {
    console.error("Failed to parse OTG geometry");
    return null;
  }

  //Assumes the interleaved buffer serialization we use by default
  //Maps the decoded data to the mdata/vblayout structures produced by
  //the LMV loader worker threads. It's slightly different from the LmvBufferGeometry fields
  let mesh = {
    vblayout: {},
    vb: new Float32Array(dec.buffers[0].buffer, dec.buffers[0].byteOffset, dec.buffers[0].byteLength / 4),
    isLines: (dec.meshFlag & 0x3) === MeshFlags.LINES,
    isWideLines: (dec.meshFlag & 0x3) === MeshFlags.WIDE_LINES,
    isPoints: (dec.meshFlag & 0x3) === MeshFlags.POINTS,
    boundingBox: unitBox,
    boundingSphere: unitSphere
  };

  //TODO: line width
  let uvCount = 0;

  for (let i = 0; i < dec.attributes.length; i++) {
    let attr = dec.attributes[i];

    if (attr.name === AttributeName.Index) {
      let ib = dec.buffers[1];
      if (attr.bytesPerItem === 1) {
        mesh.indices = ib;
      } else if (attr.bytesPerItem === 2) {
        mesh.indices = new Uint16Array(ib.buffer, ib.byteOffset, ib.byteLength / attr.bytesPerItem);
      } else if (attr.bytesPerItem === 4) {
        mesh.indices = new Uint32Array(ib.buffer, ib.byteOffset, ib.byteLength / attr.bytesPerItem);
      }

      if (mesh.isLines)
      deltaDecodeIndexBuffer2(mesh.indices);else

      deltaDecodeIndexBuffer3(mesh.indices);
    } else if (attr.name === AttributeName.IndexEdges) {
      if (!skipEdges) {
        let iblines = dec.buffers[2];
        if (attr.bytesPerItem === 1) {
          mesh.iblines = iblines;
        } else if (attr.bytesPerItem === 2) {
          mesh.iblines = new Uint16Array(iblines.buffer, iblines.byteOffset, iblines.byteLength / attr.bytesPerItem);
        } else if (attr.bytesPerItem === 4) {
          mesh.iblines = new Uint32Array(iblines.buffer, iblines.byteOffset, iblines.byteLength / attr.bytesPerItem);
        }

        deltaDecodeIndexBuffer2(mesh.iblines);
      }
    } else {
      let lmvAttr = attrNameToLMV(attr.name);

      if (lmvAttr === "uv") {
        uvCount++;
        if (uvCount > 1) {
          lmvAttr += uvCount.toString();
        }
      }

      if (!mesh.vbstride)
      mesh.vbstride = attr.itemStride;else
      {
        //We expect all vertex attributes to be packed into one VB
        if (mesh.vbstride !== attr.itemStride)
        console.error("Unexpected vertex buffer stride mismatch.");
      }

      if (attr.itemOffset >= attr.itemStride) {




        //Some old (pre- October 2018) meshes have an extra UV attribute defined even though
        //it's not physically in the vertex buffer data. We skip it here.
        //If the attribute offset is out of bounds, we just ignore it.
        //console.warn("Buggy OTG mesh. Ignoring out of bounds attribute");
      } else {mesh.vblayout[lmvAttr] = { bytesPerItem: attr.bytesPerItem, offset: attr.itemOffset, normalized: attr.normalized,
          itemSize: attr.itemSize
        };
      }
    }

  }

  let mdata = {
    mesh
  };

  return mdata;

}