
import { logger } from "../logger/Logger";
import * as et from "../application/EventTypes";
import { NODE_FLAG_HIDE } from "../wgs/scene/InstanceTree";

export function VisibilityManager(viewerImpl, model) {
  this.viewerImpl = viewerImpl;

  //Currently the visibility manager works on a single model only
  //so we make this explicit here.
  this.model = model;

  // Keep track of isolated nodes
  this.isolatedNodes = new Set();

  // Keeps track of hidden nodes. Only applies when there's no isolated node being tracked.
  this.hiddenNodes = new Set();
}

VisibilityManager.prototype.getInstanceTree = function () {
  if (this.model)
  return this.model.getData().instanceTree;else

  return null;
};

VisibilityManager.prototype.getIsolatedNodes = function () {
  return Array.from(this.isolatedNodes);
};

VisibilityManager.prototype.getHiddenNodes = function () {
  return Array.from(this.hiddenNodes);
};

/** @params {bool} - visible flag applied to all dbIds/fragments. */
VisibilityManager.prototype.setAllVisibility = function (visible) {

  var root = this.model ? this.model.getRootId() : null;
  if (root) {
    // if we have an instance tree, we call setVisible on the root node
    this.setVisibilityOnNode(root, visible);
  }

};

VisibilityManager.prototype.isNodeVisible = function (dbId) {
  var it = this.getInstanceTree();
  if (it) {
    // get visibility from instance tree
    return !it.isNodeHidden(dbId);
  } else {
    // If there is no instance tree, we have ids, but no hierarchy.
    // Therefore, an id is only hidden if it appears in hiddenNodes or
    // if there are isolated nodes and dbId is not among these.
    return !this.hiddenNodes.has(dbId) && (this.isolatedNodes.size === 0 || this.isolatedNodes.has(dbId));
  }
};

VisibilityManager.prototype.isolate = function (node, shallow) {
  var it = this.getInstanceTree();
  var rootId = it ? it.getRootId() : null;
  var isRoot = typeof node == "number" && node === rootId ||
  typeof node == "object" && node.dbId === rootId;

  if (node && !isRoot) {
    this.isolateMultiple(Array.isArray(node) ? node : [node], shallow);
  } else {
    this.isolateNone();
  }
};

VisibilityManager.prototype.isolateNone = function () {

  this.setAllVisibility(true);

  this.hiddenNodes.clear();
  this.isolatedNodes.clear();
  this.viewerImpl.invalidate(true);
};

//Makes the children of a given node visible and
//everything else not visible
VisibilityManager.prototype.isolateMultiple = function (nodeList, shallow) {

  //If given nodelist is null or is an empty array or contains the whole tree
  if (!nodeList || nodeList.length == 0) {
    this.isolateNone();
  } else
  {

    this.setAllVisibility(false);

    // Needs to happen after setVisibilityOnNode(root).
    this.isolatedNodes = new Set(nodeList);
    this.hiddenNodes = new Set();

    for (var i = 0; i < nodeList.length; i++) {
      this.setVisibilityOnNode(nodeList[i], true, true, shallow);
    }
  }
  //force a repaint and a clear
  this.viewerImpl.sceneUpdated(true);
};


//Makes the children of a given node visible and
//everything else not visible
VisibilityManager.prototype.hide = function (node) {let fireEvent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;let shallow = arguments.length > 2 ? arguments[2] : undefined;

  var event;

  if (Array.isArray(node)) {
    for (var i = 0; i < node.length; ++i) {
      this.setVisibilityOnNode(node[i], false, false, shallow);
    }

    if (node.length > 0) {
      event = { type: et.HIDE_EVENT, nodeIdArray: node, model: this.model };
    }
  } else {
    this.setVisibilityOnNode(node, false, false, shallow);
    event = { type: et.HIDE_EVENT, nodeIdArray: [node], model: this.model };
  }

  if (event && fireEvent)
  this.viewerImpl.api.dispatchEvent(event);
};

VisibilityManager.prototype.show = function (node, shallow) {

  var event;

  if (Array.isArray(node)) {
    for (var i = 0; i < node.length; ++i) {
      this.setVisibilityOnNode(node[i], true, false, shallow);
    }

    if (node.length > 0) {
      event = { type: et.SHOW_EVENT, nodeIdArray: node, model: this.model };
    }
  } else {
    this.setVisibilityOnNode(node, true, false, shallow);
    event = { type: et.SHOW_EVENT, nodeIdArray: [node], model: this.model };
  }

  if (event)
  this.viewerImpl.api.dispatchEvent(event);
};

VisibilityManager.prototype.setVisibilityOnNode = function (node, visible, skipInvalidation, shallow) {

  const viewer = this.viewerImpl;
  const model = this.model;
  const instanceTree = this.getInstanceTree();
  const fl = model.getFragmentList();
  const hidden = !visible;
  const isRoot = node === this.model.getRootId();
  let boundsDirty = false;

  if (!instanceTree) {
    //No instance tree, assume fragId = dbId
    fl.setVisibility(node, visible);
    model.invalidateBBoxes();
    return;
  }

  function processOneNode(dbId) {
    let visibleLocked = instanceTree.isNodeVisibleLocked(dbId);

    if (visibleLocked) {
      return;
    }

    instanceTree.setNodeHidden(dbId, hidden);

    instanceTree.enumNodeFragments(dbId, function (fragId) {
      if (fl.setVisibility(fragId, visible)) {
        boundsDirty = true;
      }
    }, false);
  }

  if (isRoot && !instanceTree.hasLockedVisibility()) {
    //Code path where we can skip some of the extra checks for locked visibility
    //and set the hide flag faster
    instanceTree.setFlagGlobal(NODE_FLAG_HIDE, hidden); //update all nodes in the instance tree
    model.setAllVisibility(visible); //update all fragments in the fragment list
  } else {
    if (shallow) {
      processOneNode(node);
    } else {
      //Recursively process the tree under the root (recursion is inclusive of the root)
      instanceTree.enumNodeChildren(node, processOneNode, true);
    }

    if (boundsDirty) {
      model.invalidateBBoxes();
    }

  }

  if (!skipInvalidation) {
    viewer.sceneUpdated(true);
  }

  this.updateNodeVisibilityTracking(node, visible);
};

VisibilityManager.prototype.updateNodeVisibilityTracking = function (node, visible) {

  // Update hidden tracking array.
  var toVisible = visible;
  if (this.isolatedNodes.size > 0) {
    let hasNode = this.isolatedNodes.has(node);
    if (toVisible && !hasNode) {
      this.isolatedNodes.add(node);
    } else
    if (!toVisible && hasNode) {
      this.isolatedNodes.delete(node);

      // When there are no more isolated nodes, it means that the whole model is now invisible
      if (this.isolatedNodes.size === 0) {
        this.hiddenNodes = new Set([this.model.getRootId()]);
      }
    }
  } else {
    let hasNode = this.hiddenNodes.has(node);
    if (!toVisible && !hasNode) {
      this.hiddenNodes.add(node);
    } else
    if (toVisible && hasNode) {
      this.hiddenNodes.delete(node);
    }
  }

  // When operating with the node, we can get simplify stuff.
  var instanceTree = this.getInstanceTree();
  if (instanceTree && instanceTree.getRootId() === node) {
    if (visible) {
      this.isolatedNodes.clear();
      this.hiddenNodes.clear();
    } else {
      this.isolatedNodes.clear();
      this.hiddenNodes = new Set([node]);
    }
  }
};

VisibilityManager.prototype.lockNodeVisible = function (node, locked, recursive) {

  if (Array.isArray(node)) {
    console.warn("Calling lockNodeVisible with array is no longer supported");
    return;
  }

  let instanceTree = this.getInstanceTree();

  if (!instanceTree) {
    return;
  }

  if (recursive) {
    //Recursively process the tree under the root (recursion is inclusive of the root)
    instanceTree.enumNodeChildren(node, function (dbId) {
      instanceTree.lockNodeVisible(dbId, locked);
    }, true);
  } else {
    instanceTree.lockNodeVisible(node, locked);
  }
};

VisibilityManager.prototype.isNodeVisibleLocked = function (node) {
  var instanceTree = this.getInstanceTree();
  return instanceTree && instanceTree.isNodeVisibleLocked(node);
};

VisibilityManager.prototype.setNodeOff = function (node, isOff, nodeChildren, nodeFragments) {
  var viewer = this.viewerImpl;
  var model = this.model;
  var instanceTree = this.getInstanceTree();

  if (this.isNodeVisibleLocked(node)) {
    return;
  }

  if (!instanceTree) {
    model.getFragmentList().setFragOff(node, isOff);
    viewer.sceneUpdated(true);
    return;
  }

  if (nodeChildren && nodeFragments) {
    let dbId, fragId;
    for (let i = 0; i < nodeChildren.length; ++i) {
      dbId = nodeChildren[i];
      instanceTree.setNodeOff(dbId, isOff);
    }
    for (let i = 0; i < nodeFragments.length; ++i) {
      fragId = nodeFragments[i];
      model.getFragmentList().setFragOff(fragId, isOff);
    }
  } else {
    //Recursively process the tree under the root (recursion is inclusive of the root)
    instanceTree.enumNodeChildren(node, function (dbId) {

      instanceTree.setNodeOff(dbId, isOff);

      instanceTree.enumNodeFragments(dbId, function (fragId) {
        model.getFragmentList().setFragOff(fragId, isOff);
      }, false);

    }, true);
  }

  viewer.sceneUpdated(true);
};


export function MultiModelVisibilityManager(viewer) {

  this.viewerImpl = viewer;
  this.models = [];

}

MultiModelVisibilityManager.prototype.addModel = function (model) {
  if (this.models.indexOf(model) === -1) {
    if (!model.visibilityManager) {
      model.visibilityManager = new VisibilityManager(this.viewerImpl, model);
    }
    this.models.push(model);
  }
};

MultiModelVisibilityManager.prototype.removeModel = function (model) {
  var idx = this.models.indexOf(model);

  // clear visibility states (revert all ghosting)
  model.visibilityManager.isolateNone();

  model.visibilityManager = null;
  this.models.splice(idx, 1);
};

MultiModelVisibilityManager.prototype.warn = function () {
  if (this.models.length > 1) {
    logger.warn("This selection call does not yet support multiple models.");
  }
};

/**
 * Get a list of all dbIds that are currently isolated, grouped by model.
 *
 * @returns {Array} Containing objects with `{ model: <instance>, ids: Number[] }`.
 * @private
 */
MultiModelVisibilityManager.prototype.getAggregateIsolatedNodes = function () {

  var res = [];
  var _models = this.models;
  for (var i = 0; i < _models.length; i++) {
    var nodes = _models[i].visibilityManager.getIsolatedNodes();
    if (nodes && nodes.length)
    res.push({ model: _models[i], ids: nodes });
  }
  return res;
};

MultiModelVisibilityManager.prototype.getIsolatedNodes = function (model) {
  if (!model) {
    this.warn();
    model = this.models[0];
  }
  return model.visibilityManager.getIsolatedNodes();
};

MultiModelVisibilityManager.prototype.getAggregateHiddenNodes = function () {
  var res = [];
  var _models = this.models;
  for (var i = 0; i < _models.length; i++) {
    var nodes = _models[i].visibilityManager.getHiddenNodes();
    if (nodes && nodes.length)
    res.push({ model: _models[i], ids: nodes });
  }
  return res;
};

MultiModelVisibilityManager.prototype.getHiddenNodes = function (model) {
  if (!model) {
    this.warn();
    model = this.models[0];
  }
  return model.visibilityManager.getHiddenNodes();
};

MultiModelVisibilityManager.prototype.isNodeVisible = function (model, dbId) {
  if (!model) {
    this.warn();
    model = this.models[0];
  }
  return model.visibilityManager.isNodeVisible(dbId);
};

MultiModelVisibilityManager.prototype.isolate = function (node, model) {
  if (!model) {
    this.warn();
    model = this.models[0];
  }
  model.visibilityManager.isolate(node);
  fireAggregateIsolationChangedEvent(this);
};

/**
 * Isolate nodes (dbids) associated with a model.
 * @param {object} isolation - isolation object. contains a model and dbids to isolate
 * @param {Autodesk.Viewing.Model} isolation.model - Model
 * @param {number[]} isolation.ids - dbids to hide
 * @param {object} [options] - custom options
 * @param {boolean} [options.hideLoadedModels] - if set to true the visibility of the other loaded models will not change.
 */
MultiModelVisibilityManager.prototype.aggregateIsolate = function (isolation, options) {
  if (!isolation || isolation.length === 0) {
    // all visible
    for (var i = 0; i < this.models.length; ++i) {
      this.models[i].visibilityManager.isolateNone();
    }
  } else {
    // Something's isolated
    var modelsCopy = this.models.concat();
    for (var i = 0; i < isolation.length; ++i) {
      var model = isolation[i].model;
      var ids = isolation[i].ids || isolation[i].selection;

      var index = modelsCopy.indexOf(model);
      modelsCopy.splice(index, 1);

      model.visibilityManager.isolate(ids);
    }

    var hideLoadedModels = options ?
    options.hideLoadedModels !== undefined && options.hideLoadedModels :
    true;

    while (modelsCopy.length && hideLoadedModels) {
      modelsCopy.pop().visibilityManager.setAllVisibility(false);
    }
  }
  fireAggregateIsolationChangedEvent(this);
};

//Makes the children of a given node visible and
//everything else not visible
MultiModelVisibilityManager.prototype.hide = function (node, model) {
  if (!model) {
    this.warn();
    model = this.models[0];
  }
  model.visibilityManager.hide(node);

};

/**
 * Hide the dbids associated in a model
 * Fires the Autodesk.Viewing.AGGREGATE_HIDDEN_CHANGED_EVENT.
 * @param {Object[]} hide - [ { model: Model, ids: [ Number, ... ] }, ... ]
 */
MultiModelVisibilityManager.prototype.aggregateHide = function (hide) {
  if (!hide || hide.length === 0) return;
  for (var i = 0; i < hide.length; ++i) {
    var model = hide[i].model;
    var ids = hide[i].ids || hide[i].selection;

    if (!model.visibilityManager) {
      model.visibilityManager = new VisibilityManager(this.viewerImpl, model);
    }

    // Fire the av.HIDE_EVENT only if there is one entry.
    model.visibilityManager.hide(ids, this.models.length === 1);
  }

  // Dispatch the aggregate hidden event
  var event = {
    type: et.AGGREGATE_HIDDEN_CHANGED_EVENT,
    hidden: hide.map((_ref) => {let { model, ids, selection } = _ref;return { model, ids: ids || selection };})
  };
  this.viewerImpl.api.dispatchEvent(event);
};

MultiModelVisibilityManager.prototype.show = function (node, model) {
  if (!model) {
    this.warn();
    model = this.models[0];
  }
  model.visibilityManager.show(node);
};

MultiModelVisibilityManager.prototype.setVisibilityOnNode = function (node, visible, model, shallow) {
  if (!model) {
    this.warn();
    model = this.models[0];
  }
  model.visibilityManager.setVisibilityOnNode(node, visible, shallow);
};

MultiModelVisibilityManager.prototype.toggleVisibleLocked = function (node, model) {
  if (!model) {
    this.warn();
    model = this.models[0];
  }
  model.visibilityManager.toggleVisibleLocked(node);
};

MultiModelVisibilityManager.prototype.lockNodeVisible = function (node, locked, model) {
  if (!model) {
    this.warn();
    model = this.models[0];
  }
  model.visibilityManager.lockNodeVisible(node, locked);
};

MultiModelVisibilityManager.prototype.isNodeVisibleLocked = function (node, model) {
  if (!model) {
    this.warn();
    model = this.models[0];
  }
  return model.visibilityManager.isNodeVisibleLocked(node, model);
};

MultiModelVisibilityManager.prototype.setNodeOff = function (node, isOff, model, nodeChildren, nodeFragments) {
  if (!model) {
    this.warn();
    model = this.models[0];
  }
  model.visibilityManager.setNodeOff(node, isOff, nodeChildren, nodeFragments);
};

function fireAggregateIsolationChangedEvent(_this) {

  var isolation = _this.getAggregateIsolatedNodes();

  // Legacy event
  if (_this.models.length === 1) {
    var event = {
      type: et.ISOLATE_EVENT,
      nodeIdArray: isolation.length ? isolation[0].ids : [],
      model: _this.models[0]
    };
    _this.viewerImpl.api.dispatchEvent(event);
  }

  // Always fire
  var event = {
    type: et.AGGREGATE_ISOLATION_CHANGED_EVENT,
    isolation: isolation
  };
  _this.viewerImpl.api.dispatchEvent(event);
};