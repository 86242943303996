export function getLevelElevationByName(facility) {

  // level name to elevations;
  const elevationsByName = {};
  for (const model of facility.getModels()) {
    if (!model.getData() || model.isDefault()) continue;

    // Uses the encoded elevation for this model and transform it to scene height.
    // `transformZ` is `transformPoint` but removing anything not relevant to Z.
    const { placementWithOffset } = model.getData();
    if (!placementWithOffset) continue;

    const transformZ = (z) => placementWithOffset.elements[10] * z + placementWithOffset.elements[14];
    const levels = model.getLevels();
    for (const dbId in levels) {
      const { name, elev } = levels[dbId];
      if (elev == null) continue;

      const elevations = elevationsByName[name] ?? (elevationsByName[name] = []);
      const transformed = transformZ(elev);
      elevations.push(transformed);
    }
  }

  // Reduce to averages.
  const avg = (arr) => arr?.length ? arr.reduce((sum, v) => sum + v, 0) / arr.length : 0;
  const avgByName = {};
  for (const name in elevationsByName) {
    avgByName[name] = avg(elevationsByName[name]);
  }
  return avgByName;
}