import i18n from "i18next";

import { DockingPanel } from "../../gui/DockingPanel";
import {
  OptionDropDown,
  OptionCheckbox,
  SimpleList,
  OptionRange, OptionValue } from
"../../gui/CommonWidgets";
import { Button } from "../../gui/controls/Button";
import { ContinuousScale, createColorBar } from "../../gui/ContinuousScale";
import GRADIENT_THEMES from "../resources/gradients.json";
import { GearIcon } from "./icons.js";
import { ColumnFamilies } from '../schema/dt-schema';
import { SENSED_ELEM_TYPE } from "./StreamHeatmap";
import { forgeUnitToSymbol, formatNumber } from "../../measurement/UnitFormatter";

const FULL_ID_PREFIX = ColumnFamilies.DtProperties + ':';

function gradientThemes() {
  return GRADIENT_THEMES.map((c) => {
    c.name = i18n.t(c.name);
    return c;
  });
}

/** Gets an array of unique stream attributes, sorted by name */
function getUniqSortedAttrByName(streamInfos) {
  let uniques = new Map();
  for (let stream of streamInfos) {
    for (let attr of stream.streamAttrs) {
      uniques.set(attr.id, attr);
    }
  }
  let attrs = Array.from(uniques.values());
  attrs.sort((lhs, rhs) => lhs.name.localeCompare(rhs.name));
  return attrs;
}

/** To improve readability */
class Collapsible {
  constructor(_document) {
    this.container = _document.createElement('div');
    this.container.style.display = 'none';
  }
  appendChild(el) {this.container.appendChild(el);}
  isCollapsed() {return this.container.style.display === 'none';}
  setCollapsed(collapsed) {this.container.style.display = collapsed ? 'none' : '';}
}

function makeUserPrefKey(attrUuid) {
  return 'heatmap_' + attrUuid;
}

const ERRORS = {
  NO_ROOM_ASSOC: 'This parameter is not configured for spatial heatmaps.',
  ROOMS_FILTERED_OUT: 'Rooms or spaces not shown in view. Update filters to include rooms and/or spaces Revit categories.',
  ROOMS_HIDDEN_CAT: 'Rooms or spaces not shown in view. Disable "Hide location categories" from filters.',
  NO_ROOM_IN_VIEW: 'Heatmap unavailable. Select another parameter or adjust filters.',
  NO_SYSTEM_ASSOC: 'This parameter is not configured for system heatmaps.',
  NO_SYSTEM_IN_VIEW: 'Heatmap unavailable. Select another parameter or adjust filters.',
  UNKNOWN_SENSOR_POS: 'The position of streams in this heatmap is unknown.',
  NO_DATA_FOR_ELS: 'No data for selected heatmap.',
  UNSET_UNKNOWN_PARAM: 'Unknown stream parameter was unset.'
};

const NO_HEATMAP_OPTION = { id: 'no-attrs', name: 'Select heatmap' };
const DEFAULT_THEME = 'pr_red';
const DEFAULT_HEATMAP_PREFERENCE = {
  appliesTo: SENSED_ELEM_TYPE.ROOM,
  isReversed: false,
  opacity: 0.9,
  theme: DEFAULT_THEME
};
const DEFAULT_RANGE_CONTROL = {
  min: -10000,
  max: 10000
};

/**
 * Floating widget to configure an active heatmap
 */
export class HeatmapConfigUi extends DockingPanel {
  constructor(viewer, visCtrl, heatmap, streamMgr) {
    super(viewer.container, 'heatmap-config-panel', '');

    this.visCtrl = visCtrl;
    this.heatmap = heatmap;
    this.streamMgr = streamMgr;
    this.userPrefs = viewer.prefs;
    this.viewer = viewer;

    this.setGlobalManager(viewer.globalManager);

    // series of names offset that are stacked based on position.
    this.offsets = new Map([
    ['bot', { name: 'bot', pos: 'bottom', px: 10 }],
    ['left', { name: 'left', pos: 'left', px: 15 }]]
    );

    // Gradient Legend
    this.scale = new ContinuousScale(() => '', [], 5);

    // Heatmap preferences saved in the current view.
    this.viewPrefs = new Map();
    // Overrides are unsaved changes WRT the view preferences (but saved in user prefs)
    this.prefsOverrides = new Map();

    this.isTransparent = true;

    this.container.style.height = 'auto';
    this.container.style.width = '330px';
    this.container.style.resize = 'none';
    this.container.classList.add('heatmap-config-panel');
    this.updateOffsets();

    this.createScrollContainer();
    this.scrollContainer.appendChild(this.createPanel(viewer.container));
    this.scrollContainer.classList.add('heatmap-config-panel-no-selection');
    this.scrollContainer.classList.add('heatmap-config-panel-scroll-container');
    this.scrollContainer.style.display = "block";

    this.createErrorSection();

    this.boundOnViewLoaded = this.onViewLoaded.bind(this);
    this.boundCheckValidity = this.checkHeatmapValidity.bind(this);
    this.viewer.addEventListener(Autodesk.Viewing.CAMERA_TRANSITION_COMPLETED, this.boundOnViewLoaded);

    this.isHovered = false;
    // remove widget transparency on hover and show expanded error message
    this.container.addEventListener("mouseenter", () => {
      this.isHovered = true;
      this.setTransparency(false);
    });

    // make widget transparent on leave by hiding everything but legend or attribute selection if not selected
    this.container.addEventListener("mouseleave", () => {
      this.isHovered = false;
      // if user has advanced menu open don't close it on hover out
      if (!this.isCollapsed()) {
        return;
      }

      this.setTransparency(true);
    });
  }

  dispose() {
    this.uninitialize();
    this.viewer.removeEventListener(Autodesk.Viewing.CAMERA_TRANSITION_COMPLETED, this.boundOnViewLoaded);
  }

  onViewLoaded() {
    // If a heatmap is showing, and an animation ended, update the heatmap's sensor locations
    if (this.heatmap && this.lastActiveAttr) {
      this.heatmap.show(this.lastActiveAttr.id, true, this.getPreference(this.lastActiveAttr), this.boundCheckValidity);
    }
  }

  /** Gets the view state to persist in a saved view. */
  getStateForView() {
    // Either they were previously in the view, or they were modified since loading the view.
    const prefs = {};
    if (this.idToAttrs) {
      for (let attr of this.idToAttrs.values()) {
        if (this.viewPrefs.has(attr.uuid) || this.prefsOverrides.has(attr.uuid)) {
          prefs[attr.uuid] = this.getPreference(attr);
        }
      }
    }

    const view = {};
    if (Object.keys(prefs).length) view.prefs = prefs;
    if (this.heatmap.attrIdFilter) view.attrId = this.heatmap.attrIdFilter;
    return view;
  }

  /**
   * Is the advanced configuration collapsed.
   * @return {boolean}
   */
  isCollapsed() {
    return this.expandGroup.isCollapsed();
  }

  /**
   * Sets attribute shown by the heatmap.
   * @param {string} attrId Attribute Filter ID as given by the StreamHeatmap
   */
  setActiveAttribute(attrId) {
    this.attrMenu.dropdownElement.value = attrId || NO_HEATMAP_OPTION.id;
    this.updateScale(attrId);
  }

  /**
   * Set whether the advanced configuration is collapsed.
   * @param {boolean} collapsed
   */
  setCollapsed(collapsed) {
    this.expandGroup.setCollapsed(collapsed);
    this.expandBtn.setState(collapsed ? Button.State.INACTIVE : Button.State.ACTIVE);
    this.updateOffsets();
    collapsed ? this.scrollContainer.classList.remove('heatmap-config-panel-expanded') : this.scrollContainer.classList.add('heatmap-config-panel-expanded');
  }

  /**
   * Make the UI transparent (mini) on hover and vice versa
   * @param {boolean} isTransparent
   */
  setTransparency(isTransparent) {
    this.isTransparent = isTransparent;

    if (isTransparent) {
      this.expandBtn.container.style.display = "none";

      if (this.heatmap.attrIdFilter) {
        this.attrSelector.style.visibility = "hidden";
        this.errorText.style.display = "none";
        this.scrollContainer.classList.remove('heatmap-config-panel-expanded');
      }

      if (!this.isCollapsed()) {
        // if advanced UI is open, close it on hover out
        this.setCollapsed(true);
      }

    } else {
      this.attrSelector.style.visibility = "visible";
      this.scrollContainer.classList.add('heatmap-config-panel-expanded');

      if (this.heatmap.attrIdFilter) {
        this.expandBtn.container.style.display = "block";
      }

      this.errorText.style.display = "block";
    }
  }

  /**
   * Updates the component's with new stream infos. (result of isolation | stream info changed)
   * @param streamInfos
   */
  setStreamInfos(streamInfos) {
    let attrs = getUniqSortedAttrByName(streamInfos);

    this.idToAttrs = new Map();
    for (let attr of attrs) {
      this.idToAttrs.set(attr.id, attr);

      const userPrefKey = makeUserPrefKey(attr.uuid);
      if (!this.userPrefs.get(userPrefKey)) {
        // Makes this key available from local storage, or default value.
        this.userPrefs.add(userPrefKey, DEFAULT_HEATMAP_PREFERENCE);
      }
    }

    this.updateAttributeOptions(this.attrMenu, attrs);

    if (this.pending) {
      // If a view was set, and a drawing was pending for stream info.
      this._show(this.pending, true).
      then(() => this.visCtrl.setHeatmapVisibility(true)).
      catch((err) => console.error('Unable to show heatmap ', err));
      delete this.pending;
    } else if (this.heatmap.attrIdFilter) {
      // Data for the shading changed, and heatmap is showing, possibly removed stream.
      this._show(this.heatmap.attrIdFilter, true).
      catch((err) => console.error('Unable to show heatmap ', err));
    }
  }

  /**
   * Adds an offset for panel positioning.
   * @param {NamedPositionOffset} offset Named offset to add
   */
  setOffset(offset) {
    this.offsets.set(offset.name, offset);
    this.updateOffsets();
  }

  /**
   * Overrides default behavior to turn heatmap shading off when closing widget.
   * @param show
   */
  setVisible(show) {
    if (!show) {
      super.setVisible(show);
      if (!this.heatmap) {
        return;
      }
      const last = this.lastActiveAttr;
      this._show(null).then(() => {
        // Hiding the control keeps the configured heatmap and UI properties.
        this.lastActiveAttr = last;
        this.setCollapsed(true);
      });
      return;
    }

    if (!this.heatmap) {
      console.warn('Setting visible a heatmap control without a stream heatmap');
      return;
    }

    // As we set our transparency, we need to set visibility after _show to not get flicker.
    this._show(this.lastActiveAttr?.id).
    then(() => super.setVisible(show));
  }

  /**
   * Removes an offset from panel positioning.
   * @param {string} name
   */
  deleteOffset(name) {
    this.offsets.delete(name);
    this.updateOffsets();
  }

  /** Sets the heatmap from a saved view. */
  setStateFromView(cfg) {
    // Resets overrides, clearing any unsaved changes, and loading as the view suggests.
    this.prefsOverrides = new Map();
    // Sets new view configurations
    this.viewPrefs = new Map();

    if (!cfg || !Object.keys(cfg).length) {
      // Reset if view has no saved heatmap.
      this._show(null).
      then(() => this.visCtrl.setHeatmapVisibility(false));
      return;
    }

    if (cfg.prefs) {
      for (const [uuid, pref] of Object.entries(cfg.prefs)) {
        this.viewPrefs.set(uuid, pref);
      }
    }

    const attrId = extractAttributeId(cfg);
    if (attrId) {
      if (this.idToAttrs) {
        this._show(attrId, true).
        then(() => this.visCtrl.setHeatmapVisibility(true));
      } else {
        this.pending = attrId;
      }
    } else {
      // Heatmap can have preferences but no active parameter.
      this._show(null).
      then(() => this.visCtrl.setHeatmapVisibility(false));
    }
  }

  /** @private */
  createAdvancedUi(_document) {
    const advUiDiv = _document.createElement('div');
    advUiDiv.classList.add("dt-heatmap-pref");

    // Creating the "Applies to System" control
    let elTypeToggle = _document.createElement('div');
    elTypeToggle.style.display = 'flex';
    const elTypeTBody = createTbody(_document, elTypeToggle);
    this.appliesToSystemCtrl = new OptionCheckbox('Apply heatmaps to Systems', elTypeTBody, false, undefined, this.globalManager, {});
    this.appliesToSystemCtrl.sliderRow.firstChild.classList.add('heatmap-eltype-toggle');
    this.addEventListener(this.appliesToSystemCtrl, 'change', (e) => {
      this.onChangedPreferences({
        'appliesTo': this.appliesToSystemCtrl.checked ? SENSED_ELEM_TYPE.SYSTEM : SENSED_ELEM_TYPE.ROOM
      });
    });
    advUiDiv.appendChild(elTypeToggle);

    // horizontal line separator
    let separator = _document.createElement('div');
    separator.classList.add('heatmap-sep');
    advUiDiv.appendChild(separator);

    let self = this;
    let heading = _document.createElement('div');
    heading.innerText = i18n.t('Color Theme*');
    heading.style.fontWeight = '600';
    advUiDiv.appendChild(heading);

    let themeCtrl = new SimpleList(advUiDiv, this.renderColorTheme.bind(this), function (idx) {
      self.reverseCtrl.setDisabled(!this._items[idx].isReversible);
      self.onChangedPreferences({ 'theme': this._items[idx].id });
      this._updateSelection(idx);
    });
    themeCtrl._myContainer.style.margin = '12px 0';
    themeCtrl._myContainer.style.paddingLeft = '15px';
    themeCtrl.setGlobalManager(this.globalManager);
    themeCtrl.setData(gradientThemes(), 0);
    this.themeCtrl = themeCtrl;

    const topLine = document.createElement('div');
    topLine.style.display = 'flex';
    const leftTbody = createTbody(_document, topLine);
    const rightTbody = createTbody(_document, topLine);
    advUiDiv.appendChild(topLine);

    const botLine = document.createElement('div');
    botLine.style.display = 'flex';
    const bottomTbody = createTbody(_document, botLine);
    advUiDiv.appendChild(botLine);

    this.opacityCtrl = new OptionValue('Opacity', 0, 100, leftTbody);
    this.addEventListener(this.opacityCtrl, 'change', (e) => this.onChangedPreferences({ 'opacity': this.opacityCtrl.value / 100 }));

    this.reverseCtrl = new OptionCheckbox('Reversed', rightTbody, false, undefined, this.globalManager, {});
    this.addEventListener(this.reverseCtrl, 'change', (e) => this.onChangedPreferences({ 'isReversed': this.reverseCtrl.getValue() }));

    this.rangeCtrl = new OptionRange('Values', DEFAULT_RANGE_CONTROL.min, DEFAULT_RANGE_CONTROL.max, bottomTbody);
    this.rangeCtrl.minElement.style.width = "60px";
    this.rangeCtrl.maxElement.style.width = "60px";
    this.rangeCtrl.minElement.step = "any";
    this.rangeCtrl.maxElement.step = "any";

    this.addEventListener(this.rangeCtrl, 'change', (e) => {
      let { min, max } = this.rangeCtrl.value;

      const minNum = parseFloat(min);
      min = Number.isNaN(minNum) ? undefined : minNum;
      const maxNum = parseFloat(max);
      max = Number.isNaN(maxNum) ? undefined : maxNum;

      if (min !== undefined) this.rangeCtrl.maxElement.min = min;
      if (max !== undefined) this.rangeCtrl.minElement.max = max;

      // only set preferences if the user inputs are valid
      if (this.rangeCtrl.minElement.checkValidity() && this.rangeCtrl.maxElement.checkValidity()) {
        this.onChangedPreferences({ 'range': { min, max } });
      }
    });

    return advUiDiv;
  }

  /** @private */
  createBaseUi(_document) {
    const baseUi = _document.createElement('div');
    // First base UI, what's shown even when collapsed.
    const topRow = _document.createElement('div');
    topRow.style.display = "flex";
    topRow.style.flexDirection = "row";

    // Select option for the stream attribute
    this.attrSelector = this.createAttributesSelect();
    topRow.appendChild(this.attrSelector);
    this.addEventListener(this.attrSelector, 'change', (e) => this.onAttributeChange(e));

    // Icon to expand advanced configuration
    this.expandBtn = this.createExpandButton();
    topRow.appendChild(this.expandBtn.container);

    baseUi.appendChild(topRow);

    this.baseui = baseUi;
    this.topRow = topRow;

    return baseUi;
  }

  /** @private */
  createExpandButton() {
    const btn = new Button('heatmap-expand', {});
    btn.setState(Button.State.DISABLED);
    btn.icon.innerHTML = GearIcon;
    btn.setToolTip(i18n.t('Click to expand'));
    btn.onClick = (e) => {
      this.setCollapsed(!this.isCollapsed());
      e.stopPropagation();
    };
    btn.container.style.display = "none";
    return btn;
  }

  /** @private */
  createPanel() {
    let _document = this.getDocument();
    const panel = _document.createElement('div');
    panel.style.padding = '0 5px';

    // First base UI, what's shown even when collapsed.
    const baseUi = this.createBaseUi(_document);
    panel.appendChild(baseUi);

    // Collapse Group
    this.expandGroup = new Collapsible(_document);
    this.expandGroup.appendChild(this.createAdvancedUi(_document));
    panel.appendChild(this.expandGroup.container);

    return panel;
  }

  /** @private */
  createAttributesSelect() {
    let _document = this.getDocument();
    const table = _document.createElement("table");
    table.classList.add("adsk-lmv-tftable");

    const tbody = _document.createElement("tbody");
    this.attrMenu = new OptionDropDown('Parameter', tbody, [""], 0, null, this.globalManager, {});
    this.attrMenu.dropdownElement.style.width = "100%";
    this.attrMenu.dropdownElement.style.boxSizing = "border-box";
    // Hides the caption for the OptionDropDown
    tbody.firstChild.firstChild.style.display = 'none';
    this.updateAttributeOptions(this.attrMenu, []);

    table.appendChild(tbody);
    return table;
  }

  /** private */
  createErrorSection() {
    let _document = this.getDocument();
    // placeholder for error message text when hovered
    this.errorContainer = _document.createElement('div');
    this.errorContainer.classList.add("heatmap-error-container");
    this.errorContainer.style.display = "none";
    this.baseui.appendChild(this.errorContainer);

    const infoIcon = _document.createElement('div');
    infoIcon.classList.add("adsk-icon-info-thick");
    this.errorContainer.appendChild(infoIcon);

    this.errorText = _document.createElement('div');
    this.errorText.classList.add("heatmap-error-text");
    this.errorText.setAttribute("data-i18n", "");
    this.errorText.textContent = i18n.t("");
    this.errorContainer.appendChild(this.errorText);
  }

  /** @private */
  initialize() {/* override to disable base behaviour */}

  /** @private */
  async onAttributeChange(_ref) {let { target: { value } } = _ref;
    const attrId = value === 'no-attrs' ? null : value;

    await this._show(attrId, true).
    catch((err) => console.error('Unable to show heatmap ', err));

    // show advanced setting button
    if (attrId) {
      this.expandBtn.container.style.display = "block";
    }

    // make UI transparent if the mouse has left since selecting the new attribute
    if (this.isTransparent) {
      this.setTransparency(true);
    }
  }

  /**
   * Gets the preference to use, at this moment, for this attribute.
   *
   * Logic is the preference should be:
   *   (1) the override, means a user is modifying it, but has unsaved changes.
   *   (2) the viewPrefs, means the current loaded view has a given preference for it.
   *   (3) the userPrefs, means the view did not have a preference, so we take the user's
   *   (4) a default to show something.
   * @private
   */
  getPreference(attr) {
    if (!attr || !attr.uuid) {
      return DEFAULT_HEATMAP_PREFERENCE;
    }

    const pref = this.prefsOverrides.get(attr.uuid) ||
    this.viewPrefs.get(attr.uuid) ||
    this.userPrefs.get(makeUserPrefKey(attr.uuid)) ||
    DEFAULT_HEATMAP_PREFERENCE;

    // As user might have saved views without the 'appliesTo'.
    pref.appliesTo = pref.appliesTo ?? SENSED_ELEM_TYPE.ROOM;
    return pref;
  }

  /** @private */
  onChangedPreferences(change) {
    if (!this.idToAttrs) {
      console.warn('Unable to change preference, missing stream parameters.');
      return;
    }
    const attr = this.idToAttrs.get(this.attrMenu.dropdownElement.value);

    const updated = { ...this.getPreference(attr), ...change };

    this.prefsOverrides.set(attr.uuid, updated);
    this.userPrefs.set(makeUserPrefKey(attr.uuid), updated);

    this.heatmap.show(attr.id, true, updated, this.boundCheckValidity).
    then(() => this.rangeCtrl.setPlaceholders(this.heatmap.range));
  }

  /** @private */
  async _show(attrId) {let forceReload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (!this.idToAttrs) {
      return;
    }

    const attr = attrId ? this.idToAttrs.get(attrId) : null;
    if (attrId && !attr) {
      console.warn('Unable to render heatmap, unknown stream parameter ' + attrId);
      this.updateErrorState(ERRORS.UNSET_UNKNOWN_PARAM);
      attrId = null;
    }

    const preference = this.getPreference(attr);
    await this.heatmap.show(attrId, forceReload, preference, this.boundCheckValidity);
    await this.updateUI(attr, preference);
    // This suggests defaults based on the shading computation.
    this.rangeCtrl.setPlaceholders(this.heatmap.range);
    this.lastActiveAttr = attr;
  }

  /** @private */
  renderColorTheme(cell, theme, _ref2) {let { domDocument: _document } = _ref2;
    cell.classList.add("dt-heatmap-theme-option");

    let { name, colors } = theme;

    let th = _document.createElement('div');
    let txt = _document.createElement('label');
    txt.innerText = name;
    th.appendChild(txt);

    let stops = colors.map((c, i) => ({
      offset: i / (colors.length - 1),
      color: c,
      opacity: 0.9
    }));
    th.appendChild(createColorBar(theme.id, stops, 200));

    cell.appendChild(th);
  }

  /** @private */
  updateUI(attr, preference) {
    if (attr) {
      this.scrollContainer.classList.remove('heatmap-config-panel-no-selection');

      if (!this.isCollapsed()) {
        this.setTransparency(false);
      } else {
        this.setTransparency(!this.isHovered);
      }

      if (this.expandBtn.getState() === Button.State.DISABLED) {
        this.expandBtn.setState(Button.State.INACTIVE);
      }
    } else {
      this.setTransparency(false);
      this.scrollContainer.classList.add('heatmap-config-panel-no-selection');
      this.expandGroup.setCollapsed(true);
      this.expandBtn.setState(Button.State.DISABLED);
    }

    const themes = gradientThemes();
    let idx = themes.findIndex((t) => t.id === preference.theme);
    this.themeCtrl.setData(themes, idx !== -1 ? idx : 0);
    this.opacityCtrl.setValue(preference.opacity * 100);
    this.reverseCtrl.setValue(preference.isReversed);
    this.rangeCtrl.setValue(preference.range);
    this.rangeCtrl.maxElement.min = DEFAULT_RANGE_CONTROL.min;
    this.rangeCtrl.minElement.max = DEFAULT_RANGE_CONTROL.max;
    this.appliesToSystemCtrl.setValue(preference.appliesTo === SENSED_ELEM_TYPE.SYSTEM);
  }

  updateScale(attrId) {
    const isScaleAttached = this.scale.svg.parentElement;
    if (!attrId) {
      if (isScaleAttached) {
        this.topRow.parentElement.removeChild(this.scale.svg);
      }
      return;
    }

    // gradient scale is always after the top row to ensure error container is below it
    if (!isScaleAttached) {
      this.topRow.parentNode.insertBefore(this.scale.svg, this.topRow.nextSibling);
    }

    const colorStops = this.heatmap.getColorStops();
    this.scale.updateGradients(colorStops);

    const range = this.heatmap.range || { min: 0, max: 1 };
    const attribute = this.idToAttrs?.get(attrId);
    const tickLabelCb = (pt) => {
      if (!attrId || !attribute) return '';
      const value = (range.max - range.min) * pt + range.min;

      if (attribute.forgeUnit === 'percentage') {
        return this.streamMgr.formatStreamValue(value, attribute);
      }

      let res = formatNumber(value, attribute.precision);

      // shorten long label by converting number to scientific notation
      if (`${res}`.length >= 10) {
        res = Number(res).toExponential(2);
      }
      return res;
    };

    this.scale.updateTicks(tickLabelCb, getDisplayForgeUnit(attribute?.forgeUnit === "percentage" ? "" : attribute?.forgeUnit));
  }

  /** @private */
  updateOffsets() {
    if (!this.container) {
      return;
    }

    let left = 0,bot = 0;
    for (const { pos, px } of this.offsets.values()) {
      if (pos === 'left') left += px;
      if (pos === 'bottom') bot += px;
    }
    const isCutoff = window.innerHeight - 52 - bot - 15 < this.container.offsetHeight; // consider header + initial padding of 30

    this.container.style.left = left + 'px';
    this.container.style.top = null;
    this.container.style.bottom = isCutoff ?
    window.innerHeight - 52 - this.container.offsetHeight - 15 + 'px' :
    bot + 'px';
  }

  /** @private */
  updateAttributeOptions(attrMenu, attrs) {
    const el = attrMenu.dropdownElement;
    while (el.firstChild) el.removeChild(el.firstChild);

    attrs.unshift(NO_HEATMAP_OPTION);
    for (let { id, name, forgeUnit } of attrs) {
      let displayUnit = getDisplayForgeUnit(forgeUnit);
      let item = document.createElement("option");
      item.value = id;
      item.setAttribute("data-i18n", name);
      item.textContent = i18n.t(name + (displayUnit ? ` (${displayUnit})` : ''));
      el.add(item);
    }
  }

  /** @private */
  updateErrorState(error) {
    // attach any error message to the UI
    if (!error) {
      this.errorContainer.style.display = "none";
      return;
    }

    this.errorContainer.style.display = "flex";
    this.errorText.setAttribute("data-i18n", error);
    this.errorText.textContent = i18n.t(error);
  }

  /**
   * Decides whether the heatmap is valid, should render, and updates the UI's error state.
   * @param {SensedSpace} sensedSpace Computed space of elements "sensed" by streams.
   * @param {HeatmapPref} preferences User preference for the heatmap configuration.
   * @param {Map} id2reading Readings for elements.
   * @return {boolean} should it render
   */
  checkHeatmapValidity(sensedSpace, preferences, id2reading) {
    const error = getHeatmapGuidanceError(sensedSpace, preferences, this.streamMgr.facility.facetsManager, id2reading);
    this.updateErrorState(error);
    return !Boolean(error);
  }
}

function extractAttributeId(_ref3) {let { attrId } = _ref3;
  if (attrId == null) {
    return null;
  }

  if (attrId.startsWith(FULL_ID_PREFIX)) {
    return attrId;
  }

  console.info('Correcting outdated heatmap configuration');
  return FULL_ID_PREFIX + attrId;
}

// CommonWidget are built to be in a lmv-table
const createTbody = (_document, parentDiv) => {
  const table = _document.createElement("table");
  table.className = "adsk-lmv-tftable";
  const tbody = _document.createElement("tbody");
  table.appendChild(tbody);
  parentDiv.appendChild(table);
  return tbody;
};

function getHeatmapGuidanceError(sensedSpace, preferences, facetsManager, id2reading) {
  function countSensors(elements) {
    const sensorIds = new Set(elements.flatMap((s) => s.streamDbIds));
    const tally = {
      notLoaded: 0,
      total: 0,
      unknown: 0,
      withData: 0
    };

    for (const sensorId of sensorIds) {
      tally.total += 1;

      const position = sensedSpace.getSensorPos(sensorId);
      if (!position) tally.unknown += 1;
      if (position === null) tally.notLoaded += 1;
      if (id2reading.has(sensorId)) tally.withData += 1;
    }

    return tally;
  }

  let visibleElements;
  switch (preferences.appliesTo) {
    case SENSED_ELEM_TYPE.ROOM:
      const spatialEls = sensedSpace.elements.filter((el) => el.type === SENSED_ELEM_TYPE.ROOM);
      // Need at least one configured room association.
      if (spatialEls.length === 0) {
        return ERRORS.NO_ROOM_ASSOC;
      }
      // Need rooms not to be hidden by "Hide location" checkmark.
      if (facetsManager?.hiddenCategories.length) {
        return ERRORS.ROOMS_HIDDEN_CAT;
      }
      // Need configured room elements to be visible.
      visibleElements = spatialEls.filter((e) => e.isVisible);
      if (visibleElements.length === 0) {
        return facetsManager.areRoomsVisible() ? ERRORS.NO_ROOM_IN_VIEW : ERRORS.ROOMS_FILTERED_OUT;
      }
      break;
    case SENSED_ELEM_TYPE.SYSTEM:
      const systemEls = sensedSpace.elements.filter((el) => el.type === SENSED_ELEM_TYPE.SYSTEM);
      // Need at least one configured system element.
      if (systemEls.length === 0) {
        return ERRORS.NO_SYSTEM_ASSOC;
      }
      // Need configured system elements to be visible.
      visibleElements = systemEls.filter((e) => e.isVisible);
      if (visibleElements.length === 0) {
        return ERRORS.NO_SYSTEM_IN_VIEW;
      }
      break;
    default:
      console.error('Invalid heatmap element type', preferences.appliesTo);
      return;
  }

  // Check if some relevant sensor position is invalid.
  const tally = countSensors(visibleElements);
  if (tally.unknown) {
    // Potentially overly sensitive, but we can switch to only block if unknown === total
    console.warn('Showing a heatmap with some unknown sensor position', tally);
    return ERRORS.UNKNOWN_SENSOR_POS;
  }
  // Needs some data points to show for visible elements.
  if (!tally.withData) {
    return ERRORS.NO_DATA_FOR_ELS;
  }
}

function getDisplayForgeUnit(forgeUnit) {
  const unit = forgeUnit && forgeUnitToSymbol('autodesk.unit.unit:' + forgeUnit);
  return unit && unit.text || forgeUnit;
}

/**
 * A named offset
 * @typedef {Object} NamedPositionOffset
 * @property {string} name Named used to refer to the offset
 * @property {string} pos {left, right, bottom, top}
 * @property {number} px Number of pixels to offset.
 */

/**
 * Heatmap preference
 * @typedef {Object} HeatmapPref
 * @property {string} appliesTo SENSED_ELEM_TYPE
 * @property {boolean} isReversed True reverses the gradient
 * @property {number} opacity Opacity of the heatmap material 0 -> 1
 * @property {{min?, max?}?} range Optional user range to bound the values.
 * @property {string} theme ID of a hardcoded heatmap theme.
 */