const TO_HEX = new Array(256);
for (let i = 0; i < 256; i++) {
  let s = i.toString(16);
  if (s.length === 1)
  s = "0" + s;
  TO_HEX[i] = s;
}

//Most common case is for SHA1 hashes, which are 20 bytes
var tmpArr20 = new Array(20);
var tmpArr10 = new Array(10);

//Converts the input byte array into a string of half the length
//by packing two bytes into each string character (JS strings are two bytes per char)
function binToPackedString(buffer, offset, length) {
  var res = length === 20 ? tmpArr10 : [];

  for (var i = 0; i < length; i += 2) {
    var b0 = buffer[offset + i];
    var b1 = buffer[offset + i + 1];
    res[i / 2] = b1 << 8 | b0;
  }

  return String.fromCharCode.apply(null, res);
}

//Converts from UCS16 packed string (two bytes per character) to
//regular ASCII string of 4x the length
function unpackHexString(s) {
  var res = s.length === 10 ? tmpArr20 : [];

  for (var i = 0; i < s.length; i++) {
    var bytes = s.charCodeAt(i);
    res[2 * i] = TO_HEX[bytes & 0xff];
    res[2 * i + 1] = TO_HEX[bytes >> 8 & 0xff];
  }

  return res.join("");
}


function hexToDec(code) {
  //0-9
  if (code >= 48 && code <= 57) {
    return code - 48;
  }
  //A-F
  if (code >= 65 && code <= 70) {
    return code - 55;
  }
  //a-f
  if (code >= 97 && code <= 102) {
    return code - 87;
  }

  return 0;
}

//Convert string in hex format, e.g. "3498572abc" to binary
function hexToBin(str, buf, offset) {

  let s = str.length === 10 ? unpackHexString(str) : str;

  let j = offset;
  for (let i = 0; i < s.length; i += 2) {
    let d1 = hexToDec(s.charCodeAt(i));
    let d2 = hexToDec(s.charCodeAt(i + 1));
    buf[j++] = d1 << 4 | d2;
  }
}

// Convert byte array to hex format
function binToHexString(buf) {
  return Array.from(buf, function (byte) {
    return ('0' + (byte & 0xFF).toString(16)).slice(-2);
  }).join('');
}

function packedToBin(str, buf, offset) {
  for (let i = 0; i < str.length; i++) {
    let bytes = str.charCodeAt(i);
    buf[offset + 2 * i] = bytes & 0xff;
    buf[offset + 2 * i + 1] = bytes >> 8 & 0xff;
  }
}

module.exports = {
  binToPackedString,
  binToHexString,
  unpackHexString,
  hexToBin,
  packedToBin
};