import THREE from "three";
import * as dte from "../DtEventTypes";
import GRADIENT_THEMES from "../resources/gradients.json";
import { ConnectivityUtils } from "../connectivity/ConnectivityUtils";
import {
  DT_STREAMS_LAST_READINGS_CHANGED_EVENT,
  DT_TELEMETRY_CHANGED_EVENT,
  DT_TELEMETRY_TICK_EVENT } from
"../DtEventTypes";

const CONNECTED_MATERIALS = [
THREE.MeshPhongMaterial,
THREE.MeshBasicMaterial];


const _tmpBox = new THREE.Box3();

/**
 * Returns all rooms the stream is either assigned to directly, or rooms assigned to its host element.
 * @param facility
 * @param defaultModel
 * @param stream
 * @return {{dbId, model}[]}
 */
function getRoomsForStream(facility, defaultModel, stream) {
  // Direct room assignment
  let rooms = facility.getRoomsOfElement(defaultModel, stream.dbId);
  if (rooms.length) return rooms;

  // Room assignment from host.
  const { model, hostId } = stream.hostElement;

  // If host is a room
  const hostAsRoom = model.getRooms()[hostId];
  if (hostAsRoom) {
    return [hostAsRoom];
  }

  // If host is in a room
  rooms = facility.getRoomsOfElement(model, hostId);
  if (rooms.length) return rooms;

  return [];
}

/**
 * Returns all elements part of the same sub-system as the stream host element.
 * @param facility
 * @param stream
 * @return {Promise<IterableIterator<{dbId, model}>>}
 */
async function getSystemElementsForStream(facility, stream) {
  const uniqueElements = new Map();
  const { model: hostModel, hostId } = stream.hostElement;
  const systems = await facility.systemsManager.getAll();
  for (const system of systems) {
    if (!system.hasCachedConnections()) {
      await facility.systemsManager.getConnections(system.id);
    }

    // Get host element as a "SystemElement" that's part of the current system.
    const systemEl = system.getElement(hostModel.urn(), hostId);
    if (!systemEl) {
      continue;
    }

    // Inclusive set of transitively connected elements
    const subSystem = ConnectivityUtils.getConnectedElements(systemEl, true);
    for (const element of subSystem) {
      uniqueElements.set(element.model.urn() + element.dbId, element);
    }
  }

  return uniqueElements.values();
}

/**
 * Loads fragments for streams of the heatmap
 * @param streams
 * @return {Promise<any>}
 */
async function loadHostElementFragments(streams) {
  const promises = [];
  const modelToElementId = new Map();

  for (const stream of streams) {
    const { model: hostModel, hostId } = stream.hostElement;

    const { loader, visibilityManager } = hostModel;
    if (!loader || !visibilityManager) continue;

    const modelUrn = hostModel.urn();
    if (!modelToElementId.has(modelUrn)) modelToElementId.set(modelUrn, { loader, elementIds: [] });

    modelToElementId.get(modelUrn).elementIds.push(hostId);
  }

  for (const { loader, elementIds } of modelToElementId.values()) {
    promises.push(loader.loadFragmentsForElements(elementIds));
  }


  await Promise.all(promises);
}

export const SENSED_ELEM_TYPE = Object.freeze({
  ROOM: 'room',
  SYSTEM: 'system'
});

/**
 * Represents a set of sensors and sensed elements.
 *
 * There are two named perspective, room and system based. As sensor readings for rooms describes how occupied spaces
 * in a facility are behaving, while system present an overview of how the mechanisms of a facility functions.
 */
class SensedSpace {
  /** @private */
  constructor() {let elements = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];let positionByStreamId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Map();
    this.elements = elements;
    this.positionByStreamId = positionByStreamId;
  }

  /**
   * A sensor position in 3D space.
   * @param {int} dbId Stream's dbId
   * @return {THREE.Vector3}
   */
  getSensorPos(dbId) {
    return this.positionByStreamId.get(dbId);
  }

  /**
   * Returns sensed elements of type ROOM or SYSTEM.
   * @return {{dbId: int, model: any, streamDbIds: int[], type: SENSED_ELEM_TYPE}[]}
   */
  allElements() {
    return this.elements;
  }

  /**
   * Gets the current rooms and system elements associated with streams that have the required parameter.
   *
   * @param facility
   * @param defaultModel
   * @param streams Stream elements
   * @param {string} attrId Stream attribute ID (parameter)
   * @param {string} typeFilter If given, will limit elements to this type.
   * @return {Promise<SensedSpace>}
   */
  static async fromStreams(facility, defaultModel, streams, attrId, typeFilter) {
    const positionByStreamId = new Map();
    const uniqueElements = new Map();
    const heatmapStreams = streams.filter((_ref) => {let { hostElement, streamAttrs } = _ref;
      const hasAttr = streamAttrs.some((attr) => attr.id === attrId);
      return hostElement && hasAttr;
    });

    const addElement = (dbId, model, stream, type, isVisible) => {
      const key = model.urn() + dbId;
      let stored = uniqueElements.get(key) ?? { dbId, model, streamDbIds: [], type, isVisible };
      stored.streamDbIds.push(stream.dbId);
      uniqueElements.set(key, stored);
    };

    await loadHostElementFragments(heatmapStreams);

    for (const stream of heatmapStreams) {
      const { model: hostModel, hostId } = stream.hostElement;
      const hostVisible = hostModel.visibilityManager?.isNodeVisible(hostId);
      hostModel.getElementBounds(hostId, _tmpBox);
      // As we load elements based on visibility, some host elements might be unloaded while their heatmap
      // would be visible. This means we do not have a stream position.
      const position = _tmpBox.isEmpty() ?
      hostVisible ? null : undefined // null = not yet loaded, undefined = likely won't load
      : _tmpBox.getCenter();
      positionByStreamId.set(stream.dbId, position);

      if (!typeFilter || typeFilter === SENSED_ELEM_TYPE.ROOM) {
        const rooms = getRoomsForStream(facility, defaultModel, stream);
        for (const { dbId, model } of rooms) {
          const isVisible = facility.isModelVisible(model) && model.visibilityManager?.isNodeVisible(dbId);
          addElement(dbId, model, stream, SENSED_ELEM_TYPE.ROOM, isVisible);
        }
      }

      if (!typeFilter || typeFilter === SENSED_ELEM_TYPE.SYSTEM) {
        const systems = await getSystemElementsForStream(facility, stream);
        for (const { dbId, model } of systems) {
          const isVisible = facility.isModelVisible(model) && model.visibilityManager?.isNodeVisible(dbId);
          addElement(dbId, model, stream, SENSED_ELEM_TYPE.SYSTEM, isVisible);
        }
      }
    }

    return new SensedSpace([...uniqueElements.values()], positionByStreamId);
  }
}

/** Presents a volumetric heatmap around elements "sensed" by streams. */
export class StreamHeatmap {

  constructor(streamManager, eventSource, dataSource) {
    this.streamManager = streamManager;
    this.eventSource = eventSource;
    this.dataSource = dataSource ?? new LastReadingsStreamSource(streamManager, eventSource);

    this.revertMatCbs = [];
    this.attrIdFilter = null;
    this.errorMessage = null;

    //this.eventSource.debugEvents(true);
  }

  init(viewer) {
    this.viewer = viewer;
  }

  dispose() {
    this.reset();
    this.dataSource.reset();
  }

  /** Get color gradient stops from the original shading element */
  getColorStops() {
    return this.stops.map((stop, i) => ({
      color: this.colors[i].getStyle(),
      offset: stop,
      opacity: this.opacity
    }));
  }

  /**
   * Finds elements "sensed" by a stream with this parameter and colors the elements based on streams' latest values.
   *
   * @param {string|null} attrId Stream attribute ID (parameter)
   * @param {boolean} forceReload Force reload the heatmap.
   * @param {HeatmapPref} preferences User preference for the heatmap configuration.
   * @param {function(SensedSpace):boolean} checkValidity Returns if the heatmap should draw.
   * @return {Promise<void>}
   */
  async show() {let attrId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;let forceReload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;let preferences = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};let checkValidity = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => true;
    if (attrId === this.attrIdFilter && !forceReload) {
      return;
    }

    if (!attrId) {
      // Turns heatmap off.
      this.reset();
      this.viewer.impl.invalidate(true);
      return;
    }

    // Resetting shading before creating new
    this.reset(true);

    const { defaultModel, facility } = this.streamManager;
    const streams = await this.streamManager.getAllStreamInfos();
    const { appliesTo, isReversed, opacity, range: userRange, theme } = preferences;
    const sensedSpc = await SensedSpace.fromStreams(facility, defaultModel, streams, attrId, appliesTo);

    this.attrIdFilter = attrId;
    this.setColorStops(theme, opacity, isReversed);

    const onData = (id2reading, range) => {
      if (!rangeEquals(this.range, range)) {
        this.range = range;
        this.eventSource.dispatchEvent({ type: dte.DT_HEATMAP_CHANGED_EVENT, change: { attrIdFilter: attrId, ctype: 'range' } });
      }

      if (checkValidity(sensedSpc, preferences, id2reading)) {
        this.render(sensedSpc, (dbId) => id2reading.get(dbId));
      } else {
        this.revertMaterials();
        this.viewer.impl.invalidate(true); /* force a redraw to apply the removal of the materials. */
      }
    };
    await this.dataSource.start(streams, attrId, userRange, onData);

    this.eventSource.dispatchEvent({
      type: dte.DT_HEATMAP_CHANGED_EVENT,
      change: { attrIdFilter: attrId, ctype: 'shading' }
    });
  }

  /** @private */
  render(sensedSpace, sensorValueCallback) {
    const viewerImpl = this.viewer.impl;
    const alpha = this.opacity;
    const stops = this.stops;
    const colors = this.colors;

    this.revertMaterials();
    const revertMatCbs = this.revertMatCbs;

    const heatmapStops = new Float32Array(stops);
    const heatmapColors = new Float32Array(3 * colors.length);
    colors.forEach((color, i) => color.toArray(heatmapColors, i * 3));

    let shadingValues,heatmapSensors,prevOffset = 0,curOffset = 0;
    if (viewerImpl.renderer().useWebGPU) {
      // This is the WebGPU case
      viewerImpl.glrenderer().getIBL().setHeatmaps(heatmapColors, heatmapStops, alpha);
      shadingValues = [];
    }

    for (const { dbId, model, streamDbIds, isVisible } of sensedSpace.allElements()) {
      if (!isVisible) continue;

      if (!viewerImpl.renderer().useWebGPU) {
        shadingValues = [];
      }
      for (const streamDbId of streamDbIds) {
        const value = sensorValueCallback(streamDbId);
        if (!Number.isFinite(value)) {
          continue;
        }
        const pos = sensedSpace.getSensorPos(streamDbId);
        if (pos) {
          shadingValues.push(pos.x, pos.y, pos.z, value);
          curOffset++;
        }
      }
      if (!viewerImpl.renderer().useWebGPU) {
        heatmapSensors = new Float32Array(shadingValues);
      }

      const fl = model.getFragmentList();
      const it = model.getData().instanceTree;
      it.enumNodeFragments(dbId, (fragId) => {
        const mat = fl.getMaterial(fragId);
        if (!mat || !CONNECTED_MATERIALS.some((allowed) => mat instanceof allowed)) {
          return;
        }

        const cloned = viewerImpl.matman().cloneMaterial(mat, model);
        cloned.needsUpdate = true;
        if (mat.isRoomMaterial) {
          // bump opacity at least above alpha cut off. otherwise the frag
          // shader might discard before even getting to the heatmap code.
          cloned.opacity = Math.max(cloned.opacity, 0.012);
          cloned.isRoomMaterial = true;
        }

        cloned.depthWrite = mat.depthWrite || alpha === 1;

        if (!viewerImpl.renderer().useWebGPU) {
          cloned.heatmapSensors = heatmapSensors;
          cloned.heatmapAlpha = alpha;
          cloned.heatmapColors = heatmapColors;
          cloned.heatmapStops = heatmapStops;

          cloned.polygonOffset = true;
          cloned.polygonOffsetFactor = 0.5;
          cloned.polygonOffsetUnits = 1;
        } else {
          cloned.heatmapSensorOffset = prevOffset;
          cloned.heatmapSensorCount = curOffset - prevOffset;
        }

        fl.setMaterial(fragId, cloned);
        revertMatCbs.push(() => {
          // Make sure to not leak any array buffers.
          if (!viewerImpl.renderer().useWebGPU) {
            cloned.heatmapSensors = undefined;
            cloned.heatmapStops = undefined;
            cloned.heatmapColors = undefined;
          }

          // Also invoke registered dispose handlers, e.g. to clean up GPU resources.
          cloned.dispose();

          fl.materialmap && fl.setMaterial(fragId, mat);
        });
      }, true);

      prevOffset = curOffset;
    }

    if (viewerImpl.renderer().useWebGPU && curOffset > 0) {
      viewerImpl.glrenderer().getIBL().setHeatmapSensors(shadingValues);
    }

    this.viewer.impl.invalidate(true);
  }

  /** @private */
  reset() {let preventDispatch = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    this.revertMaterials();
    this.dataSource.reset();
    this.attrIdFilter = null;

    if (!preventDispatch) {
      this.eventSource.dispatchEvent({
        type: dte.DT_HEATMAP_CHANGED_EVENT,
        change: { attrIdFilter: null, ctype: 'shading' }
      });
    }
  }

  /** @private */
  revertMaterials() {
    let revertCb;
    while (revertCb = this.revertMatCbs.pop()) {
      revertCb();
    }
  }

  /** @private */
  setColorStops(theme, opacity, isReversed) {
    /** Returns n equidistant points along the [0, 1] line with n > 0. */
    function createStops(n) {
      // ex: {n:2} => [0/1, 1/1], {n:3} => [0/2, 1/2, 2/2]
      const res = [];
      const inc = 1 / (n - 1);
      for (let i = 0; i < n; i++) {
        res.push(inc * i);
      }
      return res;
    }

    const colors = (GRADIENT_THEMES.find((t) => t.id === theme) || GRADIENT_THEMES[0]).colors;
    this.stops = createStops(colors.length);
    this.opacity = opacity ?? 0.9;
    this.colors = (isReversed ? [...colors].reverse() : colors).map((c) => new THREE.Color(c));
  }
}

class LastReadingsStreamSource {
  constructor(streamManager, eventSource) {
    this.streamManager = streamManager;
    this.eventSource = eventSource;
  }

  async start(streams, attrId, userRange, onData) {
    this.onDataChanged = async () => {
      const { id2reading, range } = await this.getScaledLastReadings(streams, attrId, userRange);
      onData(id2reading, range);
    };

    this.eventSource.addEventListener(DT_STREAMS_LAST_READINGS_CHANGED_EVENT, this.onDataChanged);

    await this.onDataChanged();
  }

  reset() {
    this.onDataChanged && this.eventSource.removeEventListener(DT_STREAMS_LAST_READINGS_CHANGED_EVENT, this.onDataChanged);
    this.onDataChanged = null;
  }

  /** @private */
  async getScaledLastReadings(streams, attrId, userRange) {
    const streamIds = streams.map((s) => s.dbId);
    const lastReadings = await this.streamManager.getLastReadings(streamIds);

    let min = Infinity,max = -Infinity;
    const validReadings = new Array(streamIds.length);
    for (let i = 0; i < lastReadings.length; i++) {

      const reading = lastReadings[i]?.[attrId];
      if (!reading) continue;

      const { val: value } = reading;
      min = Math.min(min, value);
      max = Math.max(max, value);

      validReadings[i] = value;
    }

    const dataRng = { min, max };
    const range = adjustRange(dataRng, userRange);

    const space = range.max - range.min;
    const scale = (val) => space ? (val - range.min) / space : 0.5;

    const id2reading = new Map();
    for (let i = 0; i < validReadings.length; i++) {
      if (validReadings[i] === undefined) continue;

      id2reading.set(streamIds[i], clamp(scale(validReadings[i]), 0, 1));
    }

    return { id2reading, range: { min, max } };
  }
}

class TelemetryStreamSource {
  constructor(streamManager) {
    this.streamManager = streamManager;
  }

  async start(streams, attrId, userRange, onData) {
    this.readingFilter = { attrIdSet: new Set([attrId]) };
    this.onDataChanged = () => {
      const readings = this.getScaledReadings(streams, attrId, userRange, this.streamManager.view);
      if (!readings) {
        // Data is not ready yet.
        return;
      }

      onData(readings.id2reading, readings.range);
    };

    this.streamManager.view.addEventListener(DT_TELEMETRY_CHANGED_EVENT, this.onDataChanged);
    this.streamManager.view.addEventListener(DT_TELEMETRY_TICK_EVENT, this.onDataChanged);

    await this.onDataChanged();
  }

  reset() {
    if (!this.onDataChanged) return;

    this.streamManager.view.removeEventListener(DT_TELEMETRY_CHANGED_EVENT, this.onDataChanged);
    this.streamManager.view.removeEventListener(DT_TELEMETRY_TICK_EVENT, this.onDataChanged);
    this.onDataChanged = null;
    this.readingFilter = null;
  }

  /** @private */
  getScaledReadings(streams, attrId, userRange, telemetryView) {
    const rawReadings = telemetryView.getInstantReadings(this.readingFilter);
    const dataRng = telemetryView.getAttrRange(this.readingFilter)[attrId] ?? { min: Infinity, max: -Infinity };
    const range = adjustRange(dataRng, userRange);

    const space = range.max - range.min;
    const scale = (val) => space ? (val - range.min) / space : 0.5;

    const id2reading = new Map();
    for (const stream of streams) {
      const idx = rawReadings?.fullIds[stream.fullId];
      if (!idx) continue;

      const reading = rawReadings.readings[idx];
      if (!reading) continue;

      id2reading.set(stream.dbId, clamp(scale(reading.v), 0, 1));
    }

    return { id2reading, range };
  }
}

function clamp(value, min, max) {
  return Math.max(Math.min(value, max), min);
}

function rangeEquals(lhs, rhs) {
  return lhs?.min === rhs?.min && lhs?.max === rhs?.max;
}

function adjustRange(dataRng, userRng) {
  let { min, max } = dataRng;

  // if the suggested min/max are out of the range given by user, "stretch" the observed range accordingly to fit the users input
  const clampUserRange = (min, max, umin, umax) => {
    const hasUserMin = Number.isFinite(umin),hasUserMax = Number.isFinite(umax);
    if (hasUserMin && hasUserMax)
    return [umin, umax];
    if (hasUserMin)
    return [umin, Math.max(umin, max)];
    if (hasUserMax)
    return [Math.min(min, umax), umax];
    return [min, max];
  };

  [min, max] = clampUserRange(min, max, userRng?.min, userRng?.max);

  // If all readings are the same, we artificially pad the min and max
  // this has edge case implication like going out of logical range.
  if (min === max) {
    min -= 1;
    max += 1;
  }

  return { min, max };
}