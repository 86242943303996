import { RenderFlags } from "./RenderFlags";
import { logger } from "../../logger/Logger";
import { getGlobal, isMobileDevice } from "../../compat";
import { ProgressState } from "../../application/ProgressState";
import * as et from "../../application/EventTypes";
import { GroundFlags } from "../render/GroundFlags";
import * as shadow from "../render/ShadowMap";

export const ENABLE_DEBUG = getGlobal().ENABLE_DEBUG;
export const ENABLE_DEBUG_RCS = getGlobal().ENABLE_DEBUG_RCS;

const DRAWN_NOTHING = 0;
const DRAWN_MODEL = 1;
const DRAWN_BACKGROUND = 2;
const DRAWN_OVERLAY = 4;
const DRAWN_REFLECTION = 8;
const DRAWN_ALL = 15;

// command states
export const CMD_NORMAL_SEQUENCE = 0;
export const CMD_DO_AFTER = 1;
export const CMD_ALWAYS_DO = 2;


function cmdBeginScene() {

  let viewer = this.viewer;

  this.waitForDone = true;

  if (this.signalProgressByRendering)
  viewer.signalProgress(0, ProgressState.RENDERING); //zero out the progress bar for when rendering begins

  this.updateFrameBudget();

  // clear the color and depth targets
  let clear = this.getParam("BeginScene.clear");
  viewer.renderer().beginScene(viewer.scene, viewer.camera, viewer.lightsOn ? viewer.lights : viewer.no_lights, clear);

  if (clear) {
    this.screenDrawn |= DRAWN_BACKGROUND;
  }

  return false;
}

function cmdBeginPhase() {

  let viewer = this.viewer;

  // If nothing is highlighted just skip the highlighted phase
  this.phase = this.getParam("BeginPhase.phase");
  if (ENABLE_DEBUG_RCS) {
    console.log("     render phase is now " + this.phase);
  }

  // Start rendering the scene by resetting the rendering queue.
  // This sets up the view frustum intersector and begins scene iteration.
  viewer.modelQueue().reset(viewer.camera, this.phase, viewer.matman().getCutPlanes());

  return false;
}

//Bridge between the render queue and render context
//For passing pieces of model to the renderer during
//timed progressive rendering, while also taking into account
//the current rendering mode of the viewer
function renderSomeCallback(scene) {

  //Ideally, here we only want the piece of the
  //render function that specifically renders geometries,
  //and none of the camera update stuff that we already do
  //once in beginProgressive() -- but this requires
  //some refactoring of THREE.WebGLRenderer.
  let phase = this.phase;
  let viewer = this.viewer;
  let renderer = viewer.renderer();

  if (phase === RenderFlags.RENDER_NORMAL) {

    // Set edge color/opacity differently for main rendering and ghosted shapes
    renderer.setEdgeColor(viewer.edgeColorMain);

    renderer.renderScenePart(scene, true, true, true);

  } else if (phase === RenderFlags.RENDER_HIDDEN) {

    // Set edge color/opacity differently for main rendering and ghosted shapes
    renderer.setEdgeColor(viewer.edgeColorGhosted);

    scene.overrideMaterial = viewer.fadeMaterial;
    scene.edgesOnly = true;

    renderer.renderScenePart(scene, true, false, false);

    scene.overrideMaterial = null;
    scene.edgesOnly = undefined;

  } else if (phase === RenderFlags.RENDER_HIGHLIGHTED) {

    // Set edge color/opacity differently for main rendering and ghosted shapes
    renderer.setEdgeColor(viewer.edgeColorMain);

    scene.overrideMaterial = viewer.highlightMaterial;

    renderer.renderScenePart(scene, true, false, true);

    scene.overrideMaterial = null;
  }
}


function cmdMainRender() {

  let viewer = this.viewer;
  let _modelQueue = viewer.modelQueue();

  if (!_modelQueue.isEmpty() && !_modelQueue.isDone()) {

    this.screenDrawn |= DRAWN_MODEL;

    //Render some geometry with the current render mode (highlighted, normal, or ghosted)
    this.frameRemaining = _modelQueue.renderSome(this.renderSomeCallback, this.frameRemaining);
    viewer.glrenderer().flushCommandQueue();

    // TODO - cmdMainRender gets used by a number of systems - what sort of progress should really happen here?
    if (this.signalProgressByRendering) {
      viewer.signalProgress(100.0 * _modelQueue.getRenderProgress(), ProgressState.RENDERING);
      if (ENABLE_DEBUG_RCS) {
        console.log("  %%% percent done " + 100 * _modelQueue.getRenderProgress());
      }
    }
  }
  // if there is time left, continue on (return false), else return true, which means "stop for now"
  return !_modelQueue.isDone();
}

// render sectioning, if any, and any additional buffers needed, such as ID.
function cmdSceneAfterRender() {

  let viewer = this.viewer;

  this.phase = RenderFlags.RENDER_FINISHED;
  viewer.renderer().renderScenePart(viewer.sceneAfter, true, true, true);

  // TODO: bad, renderScenePart does not return the amount of time used to render. It should, so we know the remaining budget.
  // TODO: to be honest, we should actually do a performance.now() at the beginning of any command list set, and
  // use *that* to track the time truly remaining. highResTimeStamp that is passed in is not trustworthy. But there's also the
  // "average batch time" that gets set, to minimize flicker. A creaky system that works, mostly.
  return false;
}


function cmdRenderOverlays() {

  let viewer = this.viewer;
  let _modelQueue = viewer.modelQueue();

  // Render selection highlight / pivot / HUD overlays and other overlay geometry
  // This is stuff that goes into the separate overlay render buffer. It does rely on the z-buffer being properly populated,
  // so is normally rendered after the beauty pass (or highlighting pass) is performed. As such, we need to rerender it on
  // every progressive pass.

  // draw if needed
  if (this.drawOverlay) {

    // If there is geometry, and we're done rendering it, OR we need to always render the overlay while moving, make the overlay
    if (!_modelQueue.isEmpty() && _modelQueue.isDone() || viewer.showOverlaysWhileMoving) {
      viewer.renderOverlays();
      this.screenDrawn |= DRAWN_OVERLAY;
    } else {
      // overlay update not needed (no geometry, or to be done only at end): clear once, and turn off drawing it since we need to clear only once.
      viewer.renderer().clearAllOverlays();
      this.drawOverlay = false;
    }
  }

  return false;
}

// if we're fading in the rollover highlight, we just need to compose the final frame.
// This command forces PostAndPresent to happen.
function cmdForcePresent() {
  this.screenDrawn |= DRAWN_ALL;
}


function cmdPostAndPresent() {

  let viewer = this.viewer;

  //Run post-processing and present to the front buffer
  if (this.screenDrawn && (
  // present if we're done rendering, or if progressive and this is a displayable frame
  this.phase === RenderFlags.RENDER_FINISHED || this.tickCount % this.frameDisplayRate === 0)) {
    // Skip AO if we clear the screen and nothing else was drawn, or if
    // it was disabled when we created the command list.
    let skipAO = !this.getParam("PostAndPresent.performAO") ||
    (this.screenDrawn & (DRAWN_BACKGROUND | DRAWN_MODEL)) === DRAWN_BACKGROUND;
    this.waitForDone = this.waitForDone === false ? false : !!this.getParam("PostAndPresent.waitForDone");
    // present image
    viewer.renderer().composeFinalFrame(skipAO, false, this.waitForDone);
    this.waitForDone = false;

    // reset
    this.screenDrawn = DRAWN_NOTHING;

    viewer.api.dispatchEvent({ type: et.RENDER_PRESENTED_EVENT });
  } else if (!this.screenDrawn) {
    // Sometimes, the viewer kicks of progressive rendering but we don't render anything.
    // In this case, the renderer won't inform the viewer that the frame was completed and the viewer gets stuck in
    // a state where it skips the render loop. So we need to handle this case here.
    viewer._skipRenderLoop = false;
  }

  return false; // TODO - could actually measure time at this point
}

function cmdFinishAllRendering() {
  // in case some system is querying the phase
  this.phase = RenderFlags.RENDER_FINISHED;

  return false;
}

// Smooth navigation overrides
// TODO - I don't really like toggle SAO off and on during a single tick, it is a little
// costly (how much?), but it's the safest option.
function cmdSuppressAO() {

  let viewer = this.viewer;

  if (ENABLE_DEBUG) {
    if (viewer.renderer().getAOEnabled() === false) {
      // AO should be on and we should be suppressing it.
      logger.error("AO should be on at this point!");
    }
  }
  viewer.renderer().setAOEnabled(false);
  return false;
}
function cmdRestoreAO() {

  let viewer = this.viewer;

  if (ENABLE_DEBUG) {
    if (viewer.renderer().getAOEnabled() === true) {
      // AO should be off and we should be restoring it.
      logger.error("AO should be off at this point!");
    }
  }
  viewer.renderer().setAOEnabled(true);
  return false;
}

// Here's the system:
// If ground shadow is done - well, that's easy, just blit it before beauty pass
// If not done
// 	If we are doing a full render
//     Render the whole shadow first (possibly tick limited), blit it to screen, then continue to beauty pass
//     Else we are doing progressive
//        if this is the first frame:
//           if the number of objects in the scene is low (10?),
//              render the drop shadow, figuring we can rendering the rest of the scene in a single frame
//           else
//              don't bother rendering anything in later ticks (we used to waste time rendering a few each tick)
//        if this is a later frame:
//          render just the beauty pass, until done. Don't bother with the shadow now, as it won't get used.
//        When we get to the end of progressive:
//           If needed, render the ground shadow until done. Once done, signal that a re-render is needed.
function cmdGenerateGroundShadow() {

  let viewer = this.viewer;

  // three cases: full render, quick out for progressive, continue as possible for progressive.
  if (this.isProgressiveRender) {
    if (this.getParam("GenerateGroundShadow.afterBeauty")) {
      // Rendering the ground shadow after all progressive rendering is done. Signal redraw if it finishes.
      this.frameRemaining = viewer.groundShadow().prepareGroundShadow(viewer.modelQueue(), 0, this.frameRemaining);
      // was this the call that rendered it fully?
      if (viewer.groundShadow().getStatus() === GroundFlags.GROUND_RENDERED) {
        // Do we need to rerender? This needs to happen if we're not using reflection insertion.
        // TODO: someday perhaps make ground shadows more "full featured" and merge behind, like ground reflections do?
        if (this.getParam("GenerateGroundShadow.signalRedraw")) {
          viewer.requestSilentRender();
          if (ENABLE_DEBUG_RCS) {
            console.log(" $$$$ SIGNAL FULL GROUND SHADOW REDRAW");
          }
          // don't need to continue, since we know we need to fully redraw on next tick
          return true; // TODO could signal abort rest of command stream, since we know we invalidate. It's just a bit inefficient otherwise.
        }
        // note for ground reflection, so it can decide on deferred silent rendering.
        this.groundShadowInPost = true;
      }
    } else {
      // If this is the first frame, try to render the drop shadow in a small amount of time.
      // Else, don't waste time on the drop shadow.
      if (this.tickCount === 0) {
        // render 10 objects TODO - expose 10 as some other number?
        //_rcs.frameRemaining =
        viewer.groundShadow().prepareGroundShadow(viewer.modelQueue(), 10);
        // TODO or this way, which does possibly give flicker:
        //this.frameRemaining = _groundShadow.prepareGroundShadow(viewer.modelQueue(), this.frameRemaining, this.INITIAL_GROUND_SHADOW);
        //var minRemaining = this.frameBudget * (1-this.INITIAL_GROUND_SHADOW);
        //if ( this.frameRemaining < minRemaining ) {
        //     this.frameRemaining = minRemaining;
        //}
      }
    }
  } else {
    // full render, just do it fully.
    this.frameRemaining = viewer.groundShadow().prepareGroundShadow(viewer.modelQueue(), 0, this.frameRemaining);
  }

  // rendering can continue if there's time left
  return this.frameRemaining < 0 && viewer.groundShadow().getStatus() === GroundFlags.GROUND_UNFINISHED;
}

function cmdBlitGroundShadow() {

  let viewer = this.viewer;

  //Render the ground shadow after screen clear
  if (viewer.groundShadow().getStatus() !== GroundFlags.GROUND_UNFINISHED)
  viewer.renderGroundShadow();
  return false;
}

function cmdGenerateGroundReflection() {

  let viewer = this.viewer;
  let _groundReflection = viewer.groundReflection();

  // three cases: full render, quick out for progressive, continue as possible for progressive.
  if (this.isProgressiveRender) {
    // is this pass happening after the beauty pass is completed?
    if (this.getParam("GenerateGroundReflection.afterBeauty")) {
      // Rendering the ground reflection after all progressive rendering is done.
      this.frameRemaining = _groundReflection.prepareGroundReflection(viewer.groundShadow(), viewer, false, 0, this.frameRemaining);
      // was this the call that rendered it fully?
      if (_groundReflection.getStatus() === GroundFlags.GROUND_RENDERED) {
        this.screenDrawn |= DRAWN_REFLECTION;
        // If we're done, we should also check to see if a silent render is needed for ground shadows.
        // If ground shadows were finished in these post-render passes (rare - only on animation or explode,
        // for complex scenes), transparent objects in the scene will not show the shadows properly through
        // their transparent objects, LMV-2508.
        // TODO - nicer still would be to see if the scene actually has any transparent objects. If not,
        // then we don't need this separate re-render.
        // TODO Also, note this isn't a perfect system: in practice you really want to have the ground reflection
        // entirely done before rendering atop it, so that what is seen through transparent objects is fully
        // folded in. However, this problem is much less obvious in the scenes tested - missing ground shadows
        // are more obvious.
        if (this.groundShadowInPost && viewer.matman().hasTransparentMaterial()) {
          viewer.requestDeferredSilentRender();
        }
      }
    } else {
      // If this is the first frame, try to render the reflection in a small amount of time.
      // Else, don't waste time on the reflection.
      if (this.tickCount === 0) {
        // render 10 objects TODO - expose 10 as some other number? Or use a budget? Or...?
        //_rcs.frameRemaining =
        _groundReflection.prepareGroundReflection(viewer.groundShadow(), viewer, true, 10);
        // TODO or this way, which does possibly give flicker:
        //this.frameRemaining = _groundReflection.prepareGroundReflection(viewer.modelQueue(), this.frameRemaining, this.INITIAL_GROUND_SHADOW);
        //var minRemaining = this.frameBudget * (1-this.INITIAL_GROUND_SHADOW);
        //if ( this.frameRemaining < minRemaining ) {
        //     this.frameRemaining = minRemaining;
        //}
      }
    }
  } else {
    // full render, just do it fully.
    this.frameRemaining = _groundReflection.prepareGroundReflection(viewer.groundShadow(), viewer, false, 0, this.frameRemaining);
  }

  // rendering can continue if there's time left, or if we actually finished display and should present, even though we're out of time.
  // TODO we could revise commands to be of "takes time" and "doesn't take time", so that we abort if and only if we're out of time
  // and hit a "takes time" command.
  return this.frameRemaining < 0 && _groundReflection.getStatus() === GroundFlags.GROUND_UNFINISHED;
}


/**
 * Progressive update of the shadow map:
 *
 *   a) For small models that can be rendered within a single frame, the shadow map will always be rendered first,
 *      so that shadows will not flicker on and off during animations, on scene changes, or when changing the light direction.
 *   b) For large models, seeing something is more important than shadows. Therefore, we render without shadows
 *      first and only do work on the shadow map if everything else is finished.
 *
 *  Whether we take a) or b) is determined on-the-fly: We use a) if we succeed updating the whole ShadowMap
 *  within a single frame time budget.
 */
function cmdUpdateShadowMap() {

  let viewer = this.viewer;
  let _shadowMaps = viewer.shadowMaps();

  // We are either starting an update of the shadow map, or are continuing to render it in this tick.

  // This section is always entered in the first frame if the shadow map is not available yet.
  if (_shadowMaps.state === shadow.SHADOWMAP_NEEDS_UPDATE) {

    // start shadow map update. This call may end in two ways:
    //  - In case a), the shadow map could already be finished within the startUpdate() call. Therefore, the
    //    shadow map will already be available and will be used in this frame.
    //    In this case, there is nothing more to do and all subsequent calls to updateShadowMap will
    //    do nothing.
    //  - in case b), the shadow map is not available. In this case, we first wait until the rendering
    //    without shadows is finished. (see next section)
    this.frameRemaining = _shadowMaps.startUpdate(viewer.modelQueue(), this.frameRemaining, viewer.camera, viewer.getShadowLightDirection(), viewer.matman());

  } else if (_shadowMaps.state === shadow.SHADOWMAP_INCOMPLETE) {

    // continue shadow map update as long as we have time
    this.frameRemaining = _shadowMaps.continueUpdate(viewer.modelQueue(), this.frameRemaining, viewer.matman());

    // if we're done and this is a progressive render, then this shadow generation is happening at the end.
    // In such a case we need to re-render (similar to ground shadows and reflections).
    if (_shadowMaps.state === shadow.SHADOWMAP_VALID) {

      // TODO - may wish to make this a deferred silent render, so that reflection etc. is completed, then shadows come in later.
      viewer.requestSilentRender();
      if (ENABLE_DEBUG_RCS) {
        console.log(" $$$$ SIGNAL FULL SHADOW MAP REDRAW");
      }
      // don't need to continue, since we know we need to fully redraw on next tick
      return true; // TODO could signal abort rest of command stream, since we know we invalidate. It's just a bit inefficient otherwise.
    }
  }
  return this.frameRemaining < 0.0 && _shadowMaps.state !== shadow.SHADOWMAP_VALID;
}

function cmdResetShadowMap() {
  let viewer = this.viewer;
  viewer.shadowMaps().state = shadow.SHADOWMAP_NEEDS_UPDATE;
}



function cmdSignalProcessingDone() {
  let viewer = this.viewer;
  if (this.signalProgressByRendering)
  viewer.signalProgress(100.0, ProgressState.RENDERING);
}

function cmdSignalRedraw() {
  let viewer = this.viewer;
  viewer.requestSilentRender();
  return false;
}

function cmdFinishedFullRender() {
  this.finishedFullRender = true;
  return false;
}




// The render command system is what actually does the render. The idea here is that each tick() checks if anything causes a new
// render. If so, then we make a new list of commands to perform, then start performing them. For a full render without interruptions,
// this is overkill - we could just lockstep execute them all. Where the command list comes into its own is that it can be continued.
// For progressive rendering we want
// Rather than pepper the rendering sequence of the code with lots of "if" statements that
export class RenderCommandSystem {

  constructor(viewerImpl) {

    this.viewer = viewerImpl;

    //Frame time cutoffs in milliseconds. We target the middle value,
    //but adjust the CPU-side work in the give min/max range
    //once we measure actual frame times (including async GPU work, system load, etc).
    //NOTE: These are doubled for mobile devices at construction time (end of this file).
    this.MAX_FRAME_BUDGET = 1000 / 15;
    this.TARGET_FRAME_TIME = 1000 / 30;
    this.MIN_FRAME_BUDGET = 1; // This is the minimum time we will spend drawing and
    // is only indirectly related to the fastest frame rate.

    this.targetFrameBudget = this.TARGET_FRAME_TIME;

    // How many ticks pass in between screen present updates.
    // 1 means that we display every frame
    this.frameDisplayRate = 1;

    if (isMobileDevice()) {
      this.MAX_FRAME_BUDGET *= 2; // Increase to match TARGET_FRAME_TIME
      this.TARGET_FRAME_TIME *= 2; // GPUs are slower on mobile use a longer target frame time
      this.targetFrameBudget /= 2; // Even though the target's doubled, start the budget smaller and have it work up to the target (ask Cleve)
    }
    // How much time between checks on a full frame for any interrupt signal.
    this.interruptBudget = 1e10;

    this.highResTimeStamp = -1;
    // did something get rendered that would change the screen (almost always true when rendering occurs)?
    this.screenDrawn = DRAWN_NOTHING;
    // how much time we are given to render things during each execution, in ms.
    this.frameBudget = 0;
    // how much time we have left to render stuff during this tick(), in ms.
    this.frameRemaining = 0;
    // what type of render is happening currently
    this.phase = RenderFlags.RENDER_NORMAL;
    // show the amount of the scene rendered. TODO this doesn't really work right with ghosting or shadow mapping on, as those also affect it.
    this.signalProgressByRendering = false;

    // How many ticks have executed the current command list. Good for knowing if we're on the first frame (tick 0).
    this.tickCount = 0;
    // average time spent rendering a tick() TODO - needs to be revisited and thought through: if a batch is not loaded, it displays really fast!
    this.beginFrameAvg = 0;
    // exactly what it says, the time stamp passed in on the previous tick().
    this.lastBeginFrameTimeStamp = 0;
    // Whether we adjust the progressive frame budget based on the actual time between two animation frames.
    this.trackFrameBudget = true;

    // type of rendering being done (progressive/interruptible or full render per frame).
    this.isProgressiveRender = false;

    // First frame budget
    // If it's progressive and the first frame, try to finish the ground shadow in the allocated time
    this.INITIAL_GROUND_SHADOW = 0.2;

    // Internal command iterator state
    // is there a command list to execute?
    this.cmdListActive = false;
    // what command we are executing
    this.cmdIndex = 0;
    // was execution terminated for this tick()?
    this.continueExecution = true;
    // are there "CMD_ALWAYS_DO" commands in the command list? If so, we need to traverse the whole command list each tick.
    this.encounteredAlwaysDo = false;
    // did the full render finish? If not, then ignore overlay and present updates until it has
    this.finishedFullRender = true;
    // did the ground shadow get computed in the post-process for deferred rendering?
    this.groundShadowInPost = false;
    // did any previous or current frame trigger the overlay to be drawn?
    this.drawOverlay = false;

    // true means parameters can be set on the command
    this.cmdIsOpen = false;

    // how long the array is (so that if new commands/params are needed, they get allocated first).
    this.allocArraySize = 0;
    // how many commands are in the active command list
    this.cmdListLength = 0;
    // the command and parameters set for the command
    this.cmdList = [];
    this.paramList = [];

    this.bindCommands();
  }

  bindCommands() {
    this.cmdBeginScene = cmdBeginScene.bind(this);
    this.cmdPostAndPresent = cmdPostAndPresent.bind(this);
    this.cmdBeginPhase = cmdBeginPhase.bind(this);
    this.cmdMainRender = cmdMainRender.bind(this);
    this.cmdRenderOverlays = cmdRenderOverlays.bind(this);
    this.cmdFinishAllRendering = cmdFinishAllRendering.bind(this);
    this.cmdForcePresent = cmdForcePresent.bind(this);
    this.cmdSuppressAO = cmdSuppressAO.bind(this);
    this.cmdRestoreAO = cmdRestoreAO.bind(this);
    this.cmdSceneAfterRender = cmdSceneAfterRender.bind(this);
    this.cmdGenerateGroundShadow = cmdGenerateGroundShadow.bind(this);
    this.cmdBlitGroundShadow = cmdBlitGroundShadow.bind(this);
    this.cmdSignalProcessingDone = cmdSignalProcessingDone.bind(this);
    this.cmdSignalRedraw = cmdSignalRedraw.bind(this);
    this.cmdUpdateShadowMap = cmdUpdateShadowMap.bind(this);
    this.cmdResetShadowMap = cmdResetShadowMap.bind(this);
    this.cmdFinishedFullRender = cmdFinishedFullRender.bind(this);
    this.cmdGenerateGroundReflection = cmdGenerateGroundReflection.bind(this);
    this.renderSomeCallback = renderSomeCallback.bind(this);
  }

  isActive() {
    return this.cmdListActive;
  }

  setUpFrame(needsClear, needsRender, useProgressive) {

    // There are three types of render:
    // 1) full render - not (currently) interruptible, runs until completion, "locks" application
    // 2) progressive render - show a bit more each tick, runs unless interrupted by a move, control, etc.
    // 3) silent render - a full render that is done unless interrupted. Display at end if not interrupted by any other render request.
    let frameBudget;
    if (needsClear || needsRender) {
      if (useProgressive) {
        this.isProgressiveRender = true;
        frameBudget = this.targetFrameBudget;
      } else {
        this.isProgressiveRender = false;
        // How much time to spend rendering the data; 1e10 is an arbitrarily large number of milliseconds, i.e., progressive is off
        frameBudget = this.interruptBudget;
      }
    } else {
      // Must be a silent render - really, it's the same as a full render, but has a time limit per tick
      this.isProgressiveRender = false;
      frameBudget = this.targetFrameBudget;
    }

    this.frameBudget = frameBudget;

    // set to true when the render is truly done
    this.finishedFullRender = false;
  }

  // signal the beginning of a new set of commands
  beginCommandSet() {
    this.cmdListActive = true;
    this.cmdIndex = 0;
    this.cmdListLength = 0;
    this.encounteredAlwaysDo = false;
    this.tickCount = 0;
    this.screenDrawn = DRAWN_NOTHING;
  }

  // signal the end
  endCommandSet() {
    if (this.cmdIsOpen) {
      this.cmdIsOpen = false;
      // close previous command - really, increment just to get the final count
      this.cmdListLength++;
    }
  }

  /**
   * @param func {Function}
   * @param executionLevel {Number}
   */
  addCommand(func) {let executionLevel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : CMD_NORMAL_SEQUENCE;

    //Do-after commands are treated as simple commands when there's no
    //progressive/interruptible rendering going on
    if (!this.isProgressiveRender && executionLevel === CMD_DO_AFTER) {
      executionLevel = CMD_NORMAL_SEQUENCE;
    }

    if (this.cmdIsOpen) {
      // close previous command
      this.cmdListLength++;
    }
    this.cmdIsOpen = true;
    while (this.allocArraySize <= this.cmdListLength) {
      this.cmdList[this.cmdListLength] = {};
      this.paramList[this.cmdListLength] = {};
      this.allocArraySize++;
    }
    this.cmdList[this.cmdListLength] = func;
    this.paramList[this.cmdListLength].executionLevel = executionLevel;
    this.encounteredAlwaysDo = this.encounteredAlwaysDo || executionLevel === CMD_ALWAYS_DO;

    // return value so if we want to jump to this command, we know where to go.
    return this.cmdListLength;
  }

  // note that we're a bit sloppy with parameter setting. Since the parameter set at an index location
  // gets reused, you may see parameters in the parameter object that have nothing to do with this
  // command, since this parameter set might have been used for another command at some other time.
  // Basically, if a command doesn't use the parameter, then ignore it.
  setParam(indexString, val) {
    if (this.cmdIsOpen) {
      this.paramList[this.cmdListLength][indexString] = val;
    } else {
      if (ENABLE_DEBUG) {
        logger.error("ERROR: cannot set param when no command is open!");
      }
    }
  }

  // This method is meant for use during execution of a command, so gets the parameter from the currently-active command.
  getParam(indexString) {
    if (ENABLE_DEBUG) {
      if (this.paramList[this.cmdIndex][indexString] === undefined) {
        logger.error("ERROR: parameter " + indexString + " was never set for this command! Go fix it.");
      }
    }
    return this.paramList[this.cmdIndex][indexString];
  }

  // return true if done running all commands.
  executeCommandList() {
    if (this.cmdListActive) {
      // go through command list, interrupting as needed.

      // If we do more than one tick for the rendering, then turn
      // off the average frame calculation in cmdBeginScene.
      if (this.tickCount > 0)
      this.lastBeginFrameTimeStamp = 0;

      // set frame budget
      this.frameRemaining = this.frameBudget;

      if (ENABLE_DEBUG) {
        // reality check
        if (this.cmdIsOpen) {
          logger.error("ERROR: should call endCommandSet before executing");
        }
      }
      this.continueExecution = true;
      let restartIdx;
      // not at end of command list? We always go through the whole command list, as there may be "always do"
      // commands, such as a Present().

      if (ENABLE_DEBUG_RCS) {
        if (this.tickCount === 0) {console.log("===================");}
        console.log("Running commands for " + (this.isProgressiveRender ? "progressive" : "full") +
        " render, for tick count " + this.tickCount);
      }

      // Are there any "always do" commands in this command set, that must be done before we continue our command sequence?
      // Currently needed by smooth navigation, to turn off AO during the render sequence.
      if (this.encounteredAlwaysDo) {
        let idx = 0;
        while (idx < this.cmdIndex) {
          // Is this a command we should always do?
          if (this.paramList[idx].executionLevel >= CMD_ALWAYS_DO) {
            // Commands we always do are assumed to never abort, so we don't check for failure.
            if (ENABLE_DEBUG_RCS) {console.log("  ALWAYS DO command " + idx + ": " + this.cmdList[idx].name);}
            this.cmdList[idx]();
          }
          idx++;
        }
      }

      while (this.cmdIndex < this.cmdListLength) {
        // if we are to continue execution, easy;
        // if not, then check if the next command is an "always do after", such as a Present().
        if (this.continueExecution ||
        this.paramList[this.cmdIndex].executionLevel >= CMD_DO_AFTER) {
          // we're supposed to execute this command, so do it and see what it says
          if (ENABLE_DEBUG_RCS) {console.log("  command " + this.cmdIndex + ": " + this.cmdList[this.cmdIndex].name + " and " + this.frameRemaining + " frame budget remaining");}

          if (this.cmdList[this.cmdIndex]()) {
            // true means stop executing, out of time (typically),
            // so restart execution at this command the next tick()
            if (ENABLE_DEBUG_RCS) {console.log("  >>> out of tick time with " + this.frameRemaining);}
            restartIdx = this.cmdIndex;
            // signal to not execute any "normal sequence" commands for the rest of the command list.
            this.continueExecution = false;
          }
        }
        // Go to next command until we hit the end of the list;
        // we always continue, since there could be "always do" or "do after"
        // commands in the list that need to be executed.
        this.cmdIndex++;
      }

      // out of time or aborted for some other reason? We'll be back later...
      if (this.continueExecution) {
        // did all commands, so we're done
        this.cmdListActive = false;
      } else {
        // set where to continue the work next tick()
        this.cmdIndex = restartIdx;
      }
      this.tickCount++;

      return !this.continueExecution;
    } else {
      // If we finish the rendering, then turn
      // off the average frame calculation in cmdBeginScene.
      this.lastBeginFrameTimeStamp = 0;

      // not active, so "done"
      return true;
    }
  }

  scheduleOverlayOnlyUpdate(rolloverOnly) {

    // Possibly draw the overlay, only.
    // Check if we've finished a render. If we are, we set up a short render to update the overlay.
    // We ignore overlay dirty if we're in the middle of a (more than one tick) render, since the render itself will update the overlay.
    if (this.finishedFullRender) {

      this.beginCommandSet();

      if (ENABLE_DEBUG_RCS) {console.log("=====\nOVERLAY DIRTY");}

      if (rolloverOnly) {
        // Do just the blend pass, having already adjusted the uniform for fading in.
        this.addCommand(this.cmdForcePresent);

      } else {
        // full overlay render and display

        // just the overlay needs to be re-rendered
        this.addCommand(this.cmdRenderOverlays, CMD_DO_AFTER);

      }

      // we always need a present, since we know we're doing something.
      this.addCommand(this.cmdPostAndPresent, CMD_DO_AFTER);
      // don't need to think about AO, since we are just fading in.
      this.setParam("PostAndPresent.performAO", this.viewer.renderer().getAOEnabled());

      this.addCommand(this.cmdSignalProcessingDone);

      this.endCommandSet();

    }
  }

  scheduleMainPass(renderPhase) {
    this.addCommand(this.cmdBeginPhase);
    this.setParam("BeginPhase.phase", renderPhase);
    this.addCommand(this.cmdMainRender);
  }

  updateFrameBudget() {

    //Measure actual frame time between two consecutive initial frames.
    //This is used to correct measured per-scene times to what they actually take
    //once the async processing of the graphics thread is taken into account.
    if (this.lastBeginFrameTimeStamp > 0) {
      let delta = this.highResTimeStamp - this.lastBeginFrameTimeStamp;
      this.beginFrameAvg = 0.8 * this.beginFrameAvg + 0.2 * delta;
    }
    this.lastBeginFrameTimeStamp = this.highResTimeStamp;

    if (!this.isProgressiveRender || !this.trackFrameBudget) {
      return;
    }

    //Adjust frame time allowance based on actual frame rate,
    //but stay within the given boundaries.
    if (this.beginFrameAvg < this.TARGET_FRAME_TIME && this.frameBudget < this.MAX_FRAME_BUDGET) {
      this.targetFrameBudget += 1;
      if (this.targetFrameBudget > this.MAX_FRAME_BUDGET) {
        this.targetFrameBudget = this.MAX_FRAME_BUDGET;
      }
    } else
    if (this.beginFrameAvg > this.TARGET_FRAME_TIME && this.frameBudget > this.MIN_FRAME_BUDGET) {
      this.targetFrameBudget *= 0.75 + 0.25 * this.TARGET_FRAME_TIME / this.beginFrameAvg;
      if (this.targetFrameBudget < this.MIN_FRAME_BUDGET) {
        this.targetFrameBudget = this.MIN_FRAME_BUDGET;
      }
    }

    // The current frame budget is set in setupFrame, which is called explicitly by the viewer when it restarts
    // rendering. This method is called in cmdBeginScene, so we're only updating the target frame budget after it
    // has already been set for the current list of commands.
    // We could fix this by moving the update call into the viewer, right before setupFrame, or just call it from
    // setupFrame. But simply setting the budget here again after it has been updated is less intrusive.
    this.frameBudget = this.targetFrameBudget;
  }

  setFPSTargets(min, target, max) {
    this.MAX_FRAME_BUDGET = 1000 / max;
    this.MIN_FRAME_BUDGET = 1;
    this.TARGET_FRAME_TIME = 1000 / target;
    // TODO mismatch! Why / 4 here, and / 2 below (search on targetFrameBudget)?
    this.targetFrameBudget = isMobileDevice() ? this.TARGET_FRAME_TIME / 4 : this.TARGET_FRAME_TIME;
  }

  //Frames per second, based on time between subsequent animation frames (for frames that rendered something to the main scene only)
  fps() {
    return 1000.0 / this.beginFrameAvg;
  }

}