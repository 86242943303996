import { DT_CURRENT_VIEW_CHANGED_EVENT, DT_FACILITY_VIEWS_CHANGED_EVENT } from "./DtEventTypes";
import { ViewingService } from "../net/Xhr";
import { DtHttpError } from "../net/fetch";

/*global Autodesk*/
const dt = Autodesk.Tandem;

/**
 * The object describes a view of the facility.
 *
 * @typedef {Object} View
 * @property {string} id - Internal view id.
 * @property {string} label - Label of the view is used for grouping views in the UI. Views with same label are grouped together.
 * @property {string} viewName - Name of the view.
 * @property {string} dashboardName - Name of the dashboard.
 * @property {string} dashboardSection
 * @property {boolean} default - Indicates if the view is default.
 * @property {Object|undefined|null} camera - Camera settings.
 * @property {Object|undefined|null} cutPlanes - Cut planes settings.
 * @property {Object|undefined|null} dashboard - Dashboard settings.
 * @property {string} createTime - Date & time when the view was created.
 * @property {Object|undefined} hud
 */

/** Current version of payload schema */
export const VIEW_PAYLOAD_VERSION = 2;

/**
 * @alias Autodesk.Tandem.DtViews
 */
export class DtViews extends EventTarget {

  /**
   * @type {View|null|undefined}
   */
  currentView;

  _fullViewPromises = {};
  _facilityViewsPromises = {};

  /**
   * @constructor
   * @param {object} loadContext
   */
  constructor(loadContext) {
    super();
    this.loadContext = loadContext;
  }

  /**
   * @param {DtFacility} facility
   * @param {boolean} [forceFetch]
   * @return {Promise<Array.<View>>} List of facility views.
   *
   * @alias Autodesk.Tandem.DtViews#fetchFacilityViews
   */
  async fetchFacilityViews(facility, forceFetch) {
    if (forceFetch) {
      return this._facilityViewsPromises[facility.urn()] = this._fetchFacilityViews(facility);
    }
    return (
      facility && (
      this._facilityViewsPromises[facility.urn()] || (
      this._facilityViewsPromises[facility.urn()] = this._fetchFacilityViews(facility))));

  }

  async _fetchFacilityViews(facility) {
    const facilityViews = await this.#fetchJson(
      `/twins/${facility.urn()}/views`
    );

    return facilityViews;
  }

  /**
   * @param {DtFacility} facility
   * @param {string} viewId
   * @param {boolean} [forceFetch]
   * @return {Promise<View>}
   *
   * @alias Autodesk.Tandem.DtViews#fetchSpecificView
   */
  async fetchSpecificView(facility, viewId, forceFetch) {
    if (forceFetch) {
      return this._fullViewPromises[viewId] = this._fetchSpecificView(facility, viewId);
    }
    return (
      this._fullViewPromises[viewId] || (
      this._fullViewPromises[viewId] = this._fetchSpecificView(facility, viewId)));

  }

  async _fetchSpecificView(facility, viewId) {
    const view = await this.#fetchJson(`/twins/${facility.urn()}/views/${viewId}`);
    return this.deserializeView(view);
  }

  async createView(facility, viewPayload) {
    viewPayload.createTime = new Date();

    const { id } = await this.#fetchJson(
      `/twins/${facility.urn()}/views`,
      'POST',
      this.serializeView(viewPayload)
    );

    const newView = { ...viewPayload, id };
    this._fullViewPromises[newView.id] = newView;

    const facilityViews = await this.fetchFacilityViews(facility, true);

    this.dispatchEvent(
      new CustomEvent(DT_FACILITY_VIEWS_CHANGED_EVENT, {
        detail: { facility, views: facilityViews }
      })
    );

    return newView;
  }

  async updateView(facility, viewId, patch, skipEvent) {
    const currentView = await this.fetchSpecificView(facility, viewId);
    const patchedView = { ...currentView, ...patch };

    await this.#fetchJson(
      `/twins/${facility.urn()}/views/${viewId}`,
      'PUT',
      this.serializeView(patchedView)
    );

    // break references to currently used view props
    this._fullViewPromises[viewId] = patchedView;

    const facilityViews = await this.fetchFacilityViews(facility, true);

    // if the incoming view was designated default view
    // make sure no other view is default too
    if (patchedView.default) {
      for (let view of facilityViews) {
        if (view.default && view.id !== viewId) {
          await this.updateView(facility, view.id, { default: false }, true);
        }
      }
    }

    if (!skipEvent) {
      this.dispatchEvent(
        new CustomEvent(DT_FACILITY_VIEWS_CHANGED_EVENT, {
          detail: { facility, views: facilityViews }
        })
      );
    }

    return patchedView;
  }

  async deleteView(facility, view) {

    try {
      await this.#fetchJson(
        `/twins/${facility.urn()}/views/${view.id}`,
        'DELETE'
      );
    } catch (error) {
      throw new Error(`Failed to delete view "${view.viewName}"`);
    }

    const facilityViews = await this.fetchFacilityViews(facility, true);
    delete this._fullViewPromises[view.id];

    this.dispatchEvent(
      new CustomEvent(DT_FACILITY_VIEWS_CHANGED_EVENT, {
        detail: { facility, views: facilityViews }
      })
    );

    if (this.currentView && this.currentView.id === view.id) {
      this._setCurrentView(null);
    }
  }

  /**
   * Return view payload of the current state of the app.
   * Used to create and save views
   */
  async getViewPayload(facility) {
    let view = await dt.DtViewerState.getCurrentView(facility);

    view.version = VIEW_PAYLOAD_VERSION;

    return view;
  }

  /**
   * Update facets manager, viewer camera, cut planes, inventory according to the specified view.
   *
   * @param {DtFacility} facility
   * @param {View|null|undefined} view
   * @return {Promise}
   *
   * @alias Autodesk.Tandem.DtViews#setCurrentView
   */
  async setCurrentView(facility, view) {
    // Navigate to default home view if no specific camera settings applied to the view:
    if (view && !view.camera) {
      facility.viewer.autocam.goHome();
    }

    this._setCurrentView(view);
    await dt.DtViewerState.setView(facility, view, this.currentView);
  }

  _setCurrentView(view) {
    this.currentView = view;
    const event = new CustomEvent(DT_CURRENT_VIEW_CHANGED_EVENT, { detail: { view } });
    this.dispatchEvent(event);
  }

  async updateViewThumbnail(facility, viewId, data) {
    const url = this.loadContext.endpoint + `/twins/${facility.urn()}/views/${viewId}/thumbnail`;
    await new Promise((resolve, reject) => {
      ViewingService.getItem(
        this.loadContext,
        url,
        resolve,
        (status) => reject(new DtHttpError(`Request to ${url} failed (status: ${status})`, status)),
        {
          responseType: 'json',
          method: 'PUT',
          postData: data,
          headers: {
            'Content-Type': data.type
          }
        }
      );
    });

    const facilityViews = await this.fetchFacilityViews(facility, true);

    this.dispatchEvent(
      new CustomEvent(DT_FACILITY_VIEWS_CHANGED_EVENT, {
        detail: { facility, views: facilityViews }
      })
    );
  }

  /**
   * @param {Object|undefined|null} obj
   * @return {View}
   *
   * @ignore
   */
  deserializeView(obj) {
    if (!obj) {
      return {};
    }

    const view = {
      version: obj.version || 1,
      id: obj.id,
      viewName: obj.viewName,
      dashboardName: obj.dashboardName,
      dashboardSection: obj.dashboardSection,
      label: obj.label,
      author: obj.author,
      default: obj.default,
      facets: obj.facets || { filters: {} },
      heatmap: obj.heatmap,
      dashboard: obj.dashboard,
      hiddenElements: obj.hiddenElements,
      thumbnailLastUpdate: obj.thumbnailLastUpdate
    };

    if (obj.createTime) {
      view.createTime = new Date(obj.createTime);
    }

    let { camera, cutPlanes, ...filters } = obj.facets?.filters || {};
    if (obj.facets?.filters) {
      for (let key in filters) {
        view.facets.filters[key] = new Set(filters[key] || []);
      }
    }

    view.facets.settings = obj.facets?.settings;

    view.camera = obj.camera;

    view.cutPlanes = obj.cutPlanes;

    view.hud = obj.hud;

    return view;
  }

  /**
   * @param {View} view
   * @return {string}
   *
   * @ignore
   */
  serializeView(view) {
    function replacer(key, value) {
      if (typeof value === 'object' && value instanceof Set) {
        return Array.from(value);
      }
      return value;
    }

    return JSON.stringify(view, replacer);
  }

  #fetchJson(url) {let method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'GET';let body = arguments.length > 2 ? arguments[2] : undefined;
    return new Promise((resolve, reject) => {
      ViewingService.getItem(
        this.loadContext,
        this.loadContext.endpoint + url,
        resolve,
        (status) => reject(new DtHttpError(`Request to ${this.loadContext.endpoint + url} failed (status: ${status})`, status)),
        {
          responseType: "json",
          method,
          postData: body,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
    });
  }

}