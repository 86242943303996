import THREE from "three";
import { getGlobal } from "../../compat";
import { cubicBezier } from "../../wgs/render/RenderContext";
import { FacetTypes, UNASSIGNED } from "./Facets";
import { ElementFlags } from "../schema/dt-schema";
import { AGGREGATE_SELECTION_CHANGED_EVENT } from "../../application/EventTypes";

import { createFloorFootprint, getFloorBoxFuzzy } from "./floor-footprint";
import { SketchToolsControls } from "../sketchtools/SketchToolsControls";

export const ROOMS_OVERLAY = "rooms";
export const HOVER_ROOMS_OVERLAY = "hover_rooms";
export const HOVER_FLOORS_OVERLAY = "hover_floors";
export const ELEMENT_CLONE_OVERLAY = "streams";
export const BACK_ELEMENT_CLONE_OVERLAY = "background_elements";
const SELECT_FLOORS_OVERLAY = "select_floors";

const _window = getGlobal();

function getOverlayMaterial(color, opacity, packedNormals) {
  const material = new THREE.MeshPhongMaterial({
    color,
    specular: 0x080808,
    ambient: 0,
    opacity,
    reflectivity: 0,
    transparent: true,
    alphaTest: 0.0
  });
  material.packedNormals = packedNormals;
  material.depthWrite = false;
  material.depthTest = true;
  material.opacityTarget = opacity; //custom prop to change opacity on the fly
  return material;
}

export function createOverlay(viewer, name, color, opacityTop, opacityBehind, packedNormals) {
  const materialTop = opacityTop ? getOverlayMaterial(color, opacityTop, packedNormals) : null;
  const materialBehind = opacityBehind ? getOverlayMaterial(color, opacityBehind, packedNormals) : null;

  const overlay = viewer.impl.createOverlayScene(name, materialTop, materialBehind);

  let c = new THREE.Color(color);

  viewer.impl.overlayScenes[name].edgeColor = new THREE.Vector4(c.r, c.g, c.b, 1);

  return overlay;
}

const GLOW_COLOR = new THREE.Color( /*"#6ac0e7"*/"#eecc22");

let _hoverFadeId, _hoverStartTimer;

export class FacetVizEffects {
  constructor(facility, options) {
    this.facility = facility;

    this.options = {
      easeSpeed: 0.004,
      easeCurve: [1, 0, 0.75, 1],
      ...options
    };

    this._floorHighlightCache = {};

    this.glowHighlight = "";

    this.boundSelectionChange = this.onSelectionChanged.bind(this);

    this.highlightingEnabled = true;

    this.sketchToolsControls = new SketchToolsControls(facility /*, {app: this.facility.eventTarget}*/);
  }

  setViewer(viewer) {
    this.viewer = viewer;
    this.roomsOverlay = createOverlay(viewer, ROOMS_OVERLAY, 0xff9966, 0.1, 0.1, true);
    this.hoverRoomsOverlay = createOverlay(viewer, HOVER_ROOMS_OVERLAY, 0x6ac0e7, 0.5, 0.25, true);
    this.hoverFloorsOverlay = createOverlay(viewer, HOVER_FLOORS_OVERLAY, 0x6ac0e7, 0.5, 0.25, false);
    this.elementsOverlay = createOverlay(viewer, ELEMENT_CLONE_OVERLAY, 0x00ffff, 0.5, 0.25, true);
    this.elementsOverlayBack = createOverlay(viewer, BACK_ELEMENT_CLONE_OVERLAY, 0xff8000, 0.5, 0.25, true);

    this.floorsOverlay = createOverlay(viewer, SELECT_FLOORS_OVERLAY, 0x7cb0fb, 0.5, 0.25, true);
    if (!this.viewer.hasEventListener(AGGREGATE_SELECTION_CHANGED_EVENT, this.boundSelectionChange)) {
      this.viewer.addEventListener(AGGREGATE_SELECTION_CHANGED_EVENT, this.boundSelectionChange);
    }

    if (this.options.enableSketchTools) {
      if (this.sketchToolsControls.viewer !== viewer) {
        this.sketchToolsControls.init(viewer);
      }
    }

    this.sectionedElements = [];
    this.clonedMaterials = {};
  }

  enableHighlighting(enable) {
    this.highlightingEnabled = enable;
  }

  /**
   * @private
   */
  addFloorMeshToOverlay(levelFacets, floorName) {

    //For combined levels, multiple selected level elements will map to
    //the same floor facet, and we have to avoid showing its geometry multiple times
    if (this.floorsOverlay.scene.getObjectByName(floorName)) {
      return;
    }

    const floorFacet = levelFacets.find((l) => l.id === floorName);
    const cachedFloorMesh = this.getFloorMesh(floorFacet);
    if (cachedFloorMesh) {
      // clone cached floor mesh since it is also used by the mouse hover.
      const cloneMesh = new THREE.Mesh(cachedFloorMesh.geometry, cachedFloorMesh.material);
      cloneMesh.matrix.copy(cachedFloorMesh.matrix);
      cloneMesh.matrixWorld.copy(cachedFloorMesh.matrixWorld);
      cloneMesh.matrixAutoUpdate = false;
      cloneMesh.matrixWorldNeedsUpdate = true;
      cloneMesh.name = floorName;

      this.viewer.impl.addOverlay(SELECT_FLOORS_OVERLAY, cloneMesh);
    }
  }

  /**
   * @private
   */
  onSelectionChanged(event) {
    const selection = event.selections;

    this.viewer.impl.clearOverlay(SELECT_FLOORS_OVERLAY);

    const facetIdx = this.facility.facetsManager.getFacetDefs().findIndex((f) => f.id === FacetTypes.levels);
    if (facetIdx === -1) {
      return;
    }

    const levelFacets = this.facility.facetsManager.getFacets()[facetIdx];

    // add the floors geometries to the floor selection overlay
    for (let s of selection) {
      let dbId2flags = s.model.getData().dbId2flags;

      for (let dbId of s.dbIdArray) {

        if (dbId2flags[dbId] !== ElementFlags.Level) {
          continue;
        }

        const it = s.model.getInstanceTree();
        const floorName = it.getNodeName(dbId, false);
        this.addFloorMeshToOverlay(levelFacets, floorName);

      }

    }
  }

  /**
   * @private
   */
  startHoverOverlayFade(overlayName, mesh) {

    const viewerImpl = this.viewer.impl;

    _hoverStartTimer = setTimeout(() => {
      let overlayScene = viewerImpl.overlayScenes[overlayName];
      let { easeCurve, easeSpeed } = this.options;
      let lastObjTime;
      let finalChange = false;

      // Add mesh to overlayScene but wait for invalidation during fadeloop
      overlayScene.scene.add(mesh);

      (function fadeLoop(highResTimeStamp) {

        if (lastObjTime === undefined) {
          lastObjTime = highResTimeStamp;
        }

        // Queue next overlay update if final state isn't reached
        if (!finalChange) {
          _hoverFadeId = _window.requestAnimationFrame(fadeLoop);
        }

        let t = (highResTimeStamp - lastObjTime) * easeSpeed;
        t = Math.min(t, 1.0);

        const opacityModifier = cubicBezier(easeCurve, t);
        overlayScene.materialPre.opacity = overlayScene.materialPre.opacityTarget * opacityModifier;
        overlayScene.materialPost.opacity = overlayScene.materialPost.opacityTarget * opacityModifier;
        overlayScene.edgeColor.setW(opacityModifier); // assuming final edge opacity is always 1

        overlayScene.materialPre.uniformsNeedUpdate = true;
        overlayScene.materialPost.uniformsNeedUpdate = true;

        finalChange = opacityModifier === 1;

        viewerImpl.invalidate(false, false, true);
      })();
    }, 20);
  }

  /**
   * Calculate a convex hull mesh from all the elements with wall/floor category
   * @param {MergedFacetNode} item Map of id sets by urn
   */
  getFloorMesh(item) {
    let cached = this._floorHighlightCache[item.id];
    const props = { elev: item.elev, count: item.count, order: item.order, isComplete: true };

    if (cached?.mesh) {
      // If there's a cached mesh, show it
      // It will be replaced on the fly if there are changes
      return cached?.mesh;
    }

    if (cached?.isComplete && cached.elev === props.elev && cached.count === props.count && cached.order === props.order) {
      return null;
    } else {
      // Update cached props for the current pass
      cached = this._floorHighlightCache[item.id] = { ...cached, ...props };
    }

    createFloorFootprint(this.facility, item, cached);

    return cached?.mesh;
  }

  /** Used to invalidate the cached floor footprints used by level highlights. */
  invalidateFloorHighlights() {
    this._floorHighlightCache = {};
  }

  /**
   * Highlight a floor
   * Calculate and show a convex hull from all the elements with wall/floor category
   * @param {MergedFacetNode} item Map of id sets by urn
   */
  addFloorHighlight(item) {
    const mesh = this.getFloorMesh(item);
    if (mesh) {
      this.startHoverOverlayFade(HOVER_FLOORS_OVERLAY, mesh);
    }
  }

  /**
   * Highlight a space `node` by adding to the "hovering" overlay scene
   * Add a facetVizEffects for each fragId that has getVizmesh mesh geometry
   * @param {MergedFacetNode} node - One of the Facet items
   */
  addSpaceHighlight(node) {
    this.addElementHighlight(node.roomSrcModel ?? node.model, node.dbId, HOVER_ROOMS_OVERLAY);
  }

  /**
   * @param {FacetDef} facetDef
   * @param {MergedFacetNode} node
   */
  highlightFacet(facetDef, node) {
    if (!this.highlightingEnabled) {
      return;
    }

    if (node.id === UNASSIGNED) {
      return;
    }

    if (facetDef.id === FacetTypes.spaces) {
      this.addSpaceHighlight(node);
    } else if (facetDef.id === FacetTypes.levels) {
      this.addFloorHighlight(node);
    } else if (facetDef.id === FacetTypes.categories && this.glowHighlight === facetDef.id) {
      this.highlightCategory(node.id);
    } else if (facetDef.id === FacetTypes.systemClasses && this.glowHighlight === facetDef.id) {
      this.highlightSystemClass(node.id);
    } else if (facetDef.id === FacetTypes.mepSystems && this.glowHighlight === facetDef.id) {
      this.highlightSystem(node.glowEffectId);
    }
  }

  clearFacetHighlight() {
    this.clearHoveringOverlay();
    this.highlightCategory(0);
  }

  /**
   * Changes all room materials to opaque, for better space visualization during color-by room
   * @param {Boolean} opaque -- opaque rendering on or off
   */
  makeRoomsOpaque(opaque) {
    this.viewer.impl.matman().forEach((mat) => {
      if (mat.isRoomMaterial) {
        mat.depthWrite = opaque;
        mat.opacity = opaque ? 1 : 0.001;
        mat.needsUpdate = mat.uniformsNeedUpdate = true;
      }
    });
  }

  makeRoomsVisible(visible) {
    this.viewer.impl.matman().forEach((mat) => {
      if (mat.isRoomMaterial && !mat.depthWrite) {
        // bump opacity to above alpha cut off unless opaque already
        mat.opacity = visible ? 0.012 : 0.001;
        mat.uniformsNeedUpdate = true;
      }
    });
  }

  /**
   * @param {FacetLevels} facetDef
   * @param {MergedFacetNode} mergedFacet
   */
  sectionVerticalElements(facetDef, mergedFacet) {

    let matman = this.facility.viewer.impl.matman();

    //Clear any existing material overrides
    for (let matId in this.clonedMaterials) {
      let clonedMat = this.clonedMaterials[matId];

      for (let i = 0; i < clonedMat.usedBy.length; i++) {
        let [model, fragId] = clonedMat.usedBy[i];

        model.getFragmentList().setMaterial(fragId, clonedMat.originalMat);
      }

      matman.removeMaterial(clonedMat.name);
    }

    this.clonedMaterials = {};

    if (!mergedFacet) {
      return;
    }

    let count = 0;
    let count2 = 0;

    let fbox = getFloorBoxFuzzy(this.facility, mergedFacet, 0.9, false);
    let cutplanes = [
    new THREE.Vector4(0, 0, -1, fbox.min.z),
    new THREE.Vector4(0, 0, 1, -fbox.max.z)];


    for (let modelId in mergedFacet.idsSets) {

      let model = this.facility.getModelByUrn(modelId);
      let fl = model.getFragmentList();
      let it = model.getInstanceTree();
      let dbId2levelId = model.getData().dbId2levelId;
      let dbId2topLevel = model.getData().dbId2topLevel;

      for (let dbId of mergedFacet.idsSets[modelId]) {
        let baseLevel = dbId2levelId[dbId];
        let topLevel = dbId2topLevel[dbId];
        if (topLevel) {
          it.enumNodeFragments(dbId, (fragId) => {
            let mat = fl.getMaterial(fragId);
            let matId = fl.getMaterialId(fragId);

            let cloned = this.clonedMaterials[matId];
            if (!cloned) {
              cloned = matman.cloneMaterial(mat, model);
              cloned.localCutplanes = cutplanes;
              cloned.needsUpdate = true;
              cloned.originalMatId = matId;
              cloned.originalMat = mat;

              this.clonedMaterials[matId] = cloned;
              cloned.usedBy = [];
              cloned.name = matman._getMaterialHash(model, mat.hash) + ":level-section";

              //The material needs to be added to the material manager so that
              //visualization settings changes are taken into account.
              matman.addMaterial(cloned.name, cloned, true);

              //Done after addMaterial so it doesn't cause turning the setting on globally
              cloned.side = THREE.DoubleSide;
            }

            fl.setMaterial(fragId, cloned);
            cloned.usedBy.push([model, fragId]);

          }, false);
          count++;
        } else if (topLevel === baseLevel) {
          count2++;
        }
      }
    }

  }

  /**
   * @param {DtModel} model
   * @param {Number} dbId
   * @param {string} overlayId
   */
  addElementHighlight(model, dbId, overlayId) {
    const meshes = model.getProxyMeshes(dbId);
    // highlight all host elements' fragments
    this.viewer.impl.addMultipleOverlays(overlayId || ELEMENT_CLONE_OVERLAY, meshes);
  }

  removeElementHighlight(model, dbId, overlayId) {
    let overlay = this.viewer.impl.overlayScenes[overlayId || ELEMENT_CLONE_OVERLAY];

    let scene = overlay.scene;
    for (let i = scene.children.length - 1; i >= 0; --i) {
      let obj = scene.children[i];
      if (obj.model === model && obj.dbId === dbId) {
        scene.remove(obj);
      }
    }
  }

  /**
   *  Clear the hovering overlay
   *  @deprecated Use clearFacetHighlight instead
   */
  clearHoveringOverlay() {
    // Stop overlayFades that already started
    if (_hoverFadeId) {
      _window.cancelAnimationFrame(_hoverFadeId);
      _hoverFadeId = null;
    }

    // Cancel overlayFades that haven't started yet
    if (_hoverStartTimer) {
      clearTimeout(_hoverStartTimer);
    }

    this.viewer.impl.clearOverlay(HOVER_ROOMS_OVERLAY);
    this.viewer.impl.clearOverlay(HOVER_FLOORS_OVERLAY);
  }

  //TODO: The stream manager should clear only host elements that it explicitly highlighted
  //instead of the whole overlay.
  clearStreamsOverlay() {
    this.viewer.impl.clearOverlay(ELEMENT_CLONE_OVERLAY);
  }

  /**
   * Clear the rooms overloay
   */
  clearRoomsOverlay() {
    this.viewer.impl.clearOverlay(ROOMS_OVERLAY);
  }

  /**
   * Remove the selection changed listener
   */
  removeSelectionEventListener() {
    this.viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.boundSelectionChange);
  }


  setupObjectFlagHighlight(cb) {

    let models = this.facility.getModels();

    for (let model of models) {
      if (cb) {
        model.getFragmentList()?.setObjectFlagsCB((dbId) => {
          return cb(model, dbId);
        });
      } else {
        model.getFragmentList()?.setObjectFlagsCB(null);
      }
    }
  }

  highlightByObjectFlag(flag, color) {let comparisonOp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

    this.viewer.impl.renderer().setGlowFlagAndColor(flag, color, comparisonOp);
    this.viewer.impl.invalidate(false, false, true);

  }

  setupHighlightByCategory() {
    this.glowHighlight = FacetTypes.categories;
    this.setupObjectFlagHighlight((model, dbId) => {
      let cat = model.getData()?.dbId2catId?.[dbId];
      if (!cat) {
        return 0;
      }
      return -2000000 - cat;
    });
  }

  highlightCategory(cat) {
    this.highlightByObjectFlag(!cat ? 0 : -2000000 - cat, GLOW_COLOR, 0);
  }

  setupHighlightBySystemClass() {
    this.glowHighlight = FacetTypes.systemClasses;
    this.setupObjectFlagHighlight((model, dbId) => {
      return model.getData()?.dbId2SystemClass?.[dbId];
    });
  }

  setupHighlightBySystem() {
    this.glowHighlight = FacetTypes.mepSystems;
    this.setupObjectFlagHighlight((model, dbId) => {
      return model.getData()?.dbId2SystemClass?.[dbId];
    });
  }

  highlightSystemClass(sc) {
    if (sc >= 0) {
      this.highlightByObjectFlag(1 << sc, GLOW_COLOR, 1);
    } else {
      this.highlightByObjectFlag(0, GLOW_COLOR, 1);
    }
  }

  highlightSystem(sc) {
    this.highlightByObjectFlag(sc >= 0 ? sc : 0, GLOW_COLOR, 1);
  }
}