import { ViewingService } from "./Xhr";

export class DtHttpError extends Error {
  constructor(message, status) {
    super(message);
    this.status = status;
    this.ctx = null;
  }

  setErrorCtx(ctx) {
    this.ctx = ctx;
  }
}

export function fetchJsonAndETag(loadContext, url) {let method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'GET';let body = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;let headers = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  return new Promise((resolve, reject) => {
    ViewingService.getItem(
      loadContext,
      loadContext.endpoint + url,
      (data, headers, status) => resolve({ data, etag: headers.etag, status }),
      (status) => reject(new DtHttpError(`Request to ${loadContext.endpoint + url} failed (status: ${status})`, status)),
      {
        responseType: "json",
        method,
        postData: body ? JSON.stringify(body) : null,
        headers,
        responseHeaders: ['etag']
      }
    );
  });
}


export function fetchJson(loadContext, url) {let method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'GET';let body = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;let headers = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;let useV2 = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
  let postData = null;
  if (body) {
    postData = ArrayBuffer.isView(body) ? body : JSON.stringify(body);
  }

  let endpoint = loadContext.endpoint;
  if (useV2) {
    endpoint = loadContext.endpoint.replace("v1", "v2");
  }

  return new Promise((resolve, reject) => {
    ViewingService.getItem(
      loadContext,
      endpoint + url,
      resolve,
      (status) => reject(new DtHttpError(`Request to ${endpoint + url} failed (status: ${status})`, status)),
      {
        responseType: "json",
        method,
        postData: postData,
        headers
      }
    );
  });
}

export function fetchFile(loadContext, url) {let method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'GET';let file = arguments.length > 3 ? arguments[3] : undefined;
  return new Promise((resolve, reject) => {
    ViewingService.getItem(
      loadContext,
      loadContext.endpoint + url,
      resolve,
      reject,
      {
        method,
        postData: file
      }
    );
  });
}