
import { wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";
import pack_normals from "../chunks/pack_normals.wgsl";
import pack_depth from "../chunks/pack_depth.wgsl";
import mat_inverse from "../chunks/mat-inverse.wgsl";
import { getIBLDeclaration } from "./IBL";
import { getEnvMapSampleChunk } from "../chunks/env_sample";
import tonemap from "../chunks/tonemap.wgsl";
import hatch from "../chunks/hatch_pattern.wgsl";
import { getHeatmapChunk, getHeatmapDeclChunk } from "../chunks/heatmap";
import { getCameraUniformsDeclaration } from "./CameraUniforms";
import { getObjectUniformsDeclaration } from "./ObjectUniforms";
import { getMaterialUniformsDeclaration } from "./MaterialUniforms";


export function getUberShader(material, hasUV, hasTextures, hasVertexColors, isUVW) {
  return wgsl /*wgsl*/`
	diagnostic(off, derivative_uniformity);

	${getIBLDeclaration(0)}
	${getCameraUniformsDeclaration(1)}
	${getObjectUniformsDeclaration(2)}
	${getMaterialUniformsDeclaration(3)}
	${pack_normals}
	${pack_depth}
	${mat_inverse}
	${tonemap}
	${getEnvMapSampleChunk(true, false, true)}
	${getHeatmapDeclChunk()}
	${hatch}

	const hatchTintColor = vec3f(0, 0, 0);


	struct VertexOutput {
		@builtin(position) Position : vec4f,
		@location(0) @interpolate(flat) instance: u32,
		@location(1) vViewNormal: vec3f,
		@location(2) vViewPosition: vec3f,
		@location(3) vWorldPosition: vec3f,
		@location(4) depth: f32,
#if ${isUVW}
		@location(5) uvw : vec3f,
		@location(6) uvwBump : vec3f,
		@location(7) uvwAlpha : vec3f,
#elif ${hasTextures}
		@location(5) uv : vec2f,
		@location(6) uvBump : vec2f,
		@location(7) uvAlpha : vec2f,
#endif
#if ${hasVertexColors}
		@location(8) color: vec3f
#endif
	}

	struct VertexInput {
		@location(0) position : vec3f,
		@location(1) normal : u32,
#if ${hasUV && hasVertexColors}
		@location(2) color: vec3f,
		@location(3) uv: vec2f,
#endif
#if ${hasUV && !hasVertexColors}
	#if ${isUVW}
		@location(2) uvw: vec3f,
	#else
		@location(2) uv: vec2f,
	#endif
#endif
#if ${!hasUV && hasVertexColors}
		@location(2) color: vec3f,
#endif
	}

	@vertex fn vsmain(
		in: VertexInput,
		@builtin(instance_index) instance : u32
	) -> VertexOutput {

		var objectUniforms = getObjectUniforms(instance);

		var output : VertexOutput;
		var pos4 = vec4f(in.position.x, in.position.y, in.position.z, 1.0);

		var mvMatrix = uniforms.viewMatrix * objectUniforms.modelMatrix;
		var mvPosition = mvMatrix * pos4;

		output.Position = uniforms.projectionMatrix * mvPosition;

		output.vViewPosition = -mvPosition.xyz;
		output.vViewNormal = getNormalMatrix(mvMatrix) * decodeNormalInt(in.normal);
		output.vWorldPosition = (objectUniforms.modelMatrix * pos4).xyz;

#if ${hasTextures}
	#if ${isUVW}
		output.uvw = textureUniforms.texMatrix * in.uvw;
		output.uvwBump = textureUniforms.texMatrixBump * in.uvw;
		output.uvwAlpha = textureUniforms.texMatrixAlpha * in.uvw;
	#else
		output.uv = (textureUniforms.texMatrix * vec3f(in.uv, 1.0)).xy;
		output.uvBump = (textureUniforms.texMatrixBump * vec3f(in.uv, 1.0)).xy;
		output.uvAlpha = (textureUniforms.texMatrixAlpha * vec3f(in.uv, 1.0)).xy;
	#endif
#else
	#if ${isUVW}
		output.uvw = in.uvw;
		output.uvwBump = in.uvw;
		output.uvwAlpha = in.uvw;
	#endif
#endif
		output.instance = instance;
		output.depth = mvPosition.z;
#if ${hasVertexColors}
		output.color = in.color;
#endif

		return output;
	}

	struct PixelOutput {
		@location(0) color: vec4f,
		@location(1) normal: vec4f,
		@location(2) viewDepth: vec4f,
		@location(3) dbId: vec4u,
		@location(4) modelId: vec4u,
		//@location(5) objectFlags: vec4u
	}


	//Samples normal map texture (will be treated as either bump map or normal map depending on flags)
	fn GET_BUMPMAP(uv: vec2f) -> vec4f {
		//TODO: this ignores invert and clamp settings, see getMapChunk in WebGLProgram.js for details
		return textureSample(mapNormal, smplNormal, uv);
	}

	// Derivative maps - bump mapping unparametrized surfaces by Morten Mikkelsen
	//  http://mmikkelsen3d.blogspot.sk/2011/07/derivative-maps.html
	// Evaluate the derivative of the height w.r.t. screen-space using forward differencing (listing 2)
	fn dHdxy_fwd(vUvBump: vec2f) -> vec2f {

		var dSTdx = dpdx( vUvBump );
		var dSTdy = dpdy( vUvBump );

		var Hll = textureUniforms.normalScale.x * GET_BUMPMAP(vUvBump).x;
		var dBx = textureUniforms.normalScale.x * GET_BUMPMAP(vUvBump + dSTdx).x - Hll;
		var dBy = textureUniforms.normalScale.x * GET_BUMPMAP(vUvBump + dSTdy).x - Hll;

		return vec2f( dBx, dBy );
	}

	fn perturbNormalArb( surf_pos: vec3f, surf_norm: vec3f, dHdxy: vec2f ) -> vec3f {

		var vSigmaX = dpdx( surf_pos );
		var vSigmaY = dpdy( surf_pos );
		var vN = surf_norm; // normalized

		var R1 = cross( vSigmaY, vN );
		var R2 = cross( vN, vSigmaX );

		var fDet = dot( vSigmaX, R1 );

		var vGrad = sign( fDet ) * ( dHdxy.x * R1 + dHdxy.y * R2 );
		return normalize( abs( fDet ) * surf_norm - vGrad );

	}

	// Per-Pixel Tangent Space Normal Mapping
	// http://hacksoflife.blogspot.ch/2009/11/per-pixel-tangent-space-normal-mapping.html
	fn perturbNormal2Arb( eye_pos: vec3f, surf_norm: vec3f, vUvBump: vec2f ) -> vec3f {

		var q0 = dpdx( eye_pos.xyz );
		var q1 = dpdy( eye_pos.xyz );
		var st0 = dpdx( vUvBump.xy );
		var st1 = dpdy( vUvBump.xy );

		var S = normalize(  q0 * st1.y - q1 * st0.y );
		var T = normalize( -q0 * st1.x + q1 * st0.x );
		var N = normalize( surf_norm );

		var mapN = GET_BUMPMAP(vUvBump).xyz * 2.0 - 1.0;
		mapN = vec3f(textureUniforms.normalScale * mapN.xy, mapN.z);
		var tsn = mat3x3f( S, T, N );
		return normalize( tsn * mapN );
	}


	fn toIntVec(v: vec4f) -> vec4u
	{
		return vec4u(v * 255.0);
	}

	fn Schlick_v3(v: vec3f, cosHV: f32) -> vec3f {
		var facing = max(1.0 - cosHV, 0.0);
		return v + (1.0 - v) * pow(facing, 5.0);
	}

	fn Schlick_f(v: f32, cosHV: f32) -> f32 {
		var facing = max(1.0 - cosHV, 0.0);
		return v + ( 1.0 - v ) * pow(facing, 5.0);
	}

	fn InputToLinear(v: vec3f) -> vec3f {
		return v*v;
	}

	const AlphaTest = 0.01;

	@fragment fn psmain(
		in: VertexOutput
	) -> PixelOutput
	{
#if ${hasTextures}
	#if ${isUVW}
		var vUv = in.uvw.xy;
		var vUvBump = in.uvwBump.xy;
		var vUvAlpha = in.uvwAlpha.xy;
	#else
		var vUv = in.uv;
		var vUvBump = in.uvBump;
		var vUvAlpha = in.uvAlpha;
	#endif
#else
	const vUv = vec2f(0.0,0.0);
	const vUvBump = vec2f(0.0,0.0);
	const vUvAlpha = vec2f(0.0,0.0);
#endif
		var output: PixelOutput;
		var objectUniforms = getObjectUniforms(in.instance);
		var materialUniforms = getMaterialUniforms(objectUniforms.materialIndex);

		//check doNotCut flag
		if (commonMaterialUniforms.doNotCutOverride + (materialUniforms.renderFlags & 2) == 0) {
			checkCutPlanes(in.vWorldPosition);
		}

		var normal = normalize(in.vViewNormal);

		//With ortho projection, the view direction needs to be
		//adjusted so that all view direction rays (for all pixels) are parallel
		//instead of going from the camera position directly to the vertex like
		//in perspective. In view space, this is kind of easy -- the view vector is along Z.
		//TODO: Actually the vViewPosition varying is the position of the camera wrt the vertex
		//so the naming of the variable can be clarified.
		var viewDirection: vec3f;
		if (uniforms.projectionMatrix[3][3] == 0.0) {
			viewDirection = normalize( in.vViewPosition );
		} else {
			viewDirection = vec3f(0.0, 0.0, 1.0);
		}
		normal = faceForward(normal, -viewDirection, normal);

		var geomNormal = normal;

#if ${hasTextures}
		if ((materialUniforms.renderFlags & (8 << 16)) != 0) {
			normal = perturbNormal2Arb( -in.vViewPosition, normal, vUvBump );
		} else if ((materialUniforms.renderFlags & (4 << 16)) != 0) {
			normal = perturbNormalArb( -in.vViewPosition, normal, dHdxy_fwd(vUvBump) );
		}
#endif

		//TODO: vertex color
#if ${hasVertexColors}
		var diffuse = vec4f(in.color, 1.0);
#else
		var diffuse = intToVecf(materialUniforms.diffuse);
		if ((materialUniforms.renderFlags & (1 << 16)) != 0) {
			diffuse *= textureSample(mapDiffuse, smplDiffuse, vUv);
		}
#endif
		var outAlpha = diffuse.a;

#if ${hasTextures}
		if ((materialUniforms.renderFlags & (16 << 16)) != 0) {
			var alphaTexel = textureSample(mapAlpha, smplAlpha, vUvAlpha);
			outAlpha *= alphaTexel.r;
		}
#endif

		//In the WebGL version, alpha test is a configurable parameter of each material,
		//However, we don't _really_ need to be that generic
		if ((materialUniforms.renderFlags & 4) != 0 && outAlpha < AlphaTest) {
			discard;
		}

		var specularR = intToVecf(materialUniforms.specular);
		var reflectivity = specularR.a;
		var specular = InputToLinear(specularR.xyz);

		//Diffuse from irradiance map
		var worldNormal = getMat3(uniforms.viewInverseEnv) * normal;
		var indirectDiffuse = sampleIrradianceMap(worldNormal, irrMap, irrSampler, ibl.envMapExposure);

		var outColor = InputToLinear(diffuse.xyz) * indirectDiffuse;

		//Reflection map
		var reflectVec = reflect( -viewDirection, normal );

		reflectVec = getMat3(uniforms.viewInverseEnv) * reflectVec;

		//If the reflection vector points into the ground, we will scale
		//down the reflection intensity, in order to fake interference with the
		//ground plane and avoid an eclipse-like light-dark line between the object
		//and its shadow.
		//The actual scaling is made up so that it gets very dark near the ground facing faces
		//float reflectScale = 1.0 - clamp(-reflectVec.y, 0.0, 1.0);
		//reflectScale *= (reflectScale * reflectScale);
		var reflectScale = 1.0;

		//The environment cube map is blurred with the assumption that
		//max shininess is 2048 and every mip drops that by a factor of 4
		//float MipmapIndex = log4(shininess / 2048.0) / log4(0.25);
		//The simplification below was given in the original source for this method.
		//It uses log base 10. We use log base 2 below, which changes the constant.
		//float MipmapIndex = max(0.0, -1.66096404744368 * logShiny + 5.5);
		var logShiny = log2(max(1.0+1e-10, materialUniforms.shininess));
		var reflMipIndex = max(0.0, 5.5 - 0.5 * logShiny);
		//If we get roughness map support, we'd have to sample the roughness map here to get a mip index.

		var cubeColor = sampleReflection(reflectVec, reflMap, reflSampler, reflMipIndex, ibl.envMapExposure);

		cubeColor *= reflectScale;

		var facing = dot( viewDirection, normal );

		//Argh. If facing is very much below the horizon, it's probably
		//a backwards facing polygon, so turn off Fresnel completely.
		//Otherwise, if it's just slightly below, it's probably some interpolation
		//artifact, so we treat it as almost oblique facing.
		// Bypassing Fresnel when reflectivity is set exactly to 0.0 is intentional and provided
		// as a way to turn off the effect
		if (facing < -1e-2  || reflectivity == 0.0) {
			facing = 1.0;
		} else {
			facing = max(1e-6, facing);
		}

#if ${hasTextures}
		//Gratuitous hack to make bump mapped materials less shiny at grazing angles.
		//This has the effect of reducing the Fresnel effect when bumpScale is large.
		//Assumption is that large bump scale = very rough surface.
		if ((materialUniforms.renderFlags & (4 << 16)) != 0) {
			facing = min(1.0, facing + textureUniforms.normalScale.x * 7.0);
		}
#endif

#if ${material.metal}

		//Metals do not generally have Fresnel reflection
		var schlickRefl = specular;

#else

		//Nonmetals reflect using Fresnel -- so they reflect a lot at grazing angles
		var schlickRefl = Schlick_v3(specular, facing);

		//Seems appropriate to also reduce transparency of the material as
		//the view angle is more oblique:
		//BOGUS: The scaling by reflectivity is not physical -- here
		//we use reflectivity as a scale to make transparent ghosted objects look good
		//while still retaining some physical Fresnel for glass materials.
		//For ghosted objects the reflectivity is 0 while for physical glass
		//it is a non-zero value.
		outAlpha = mix(outAlpha, Schlick_f(outAlpha, facing), reflectivity);

		//Scale down diffuse in order to keep energy conservation
		//at grazing angles, where specular takes over. The actual equation is
		//given here: http://www.cs.utah.edu/~shirley/papers/jgtbrdf.pdf.
		//For the environment map, N.V and N.R are equal so we can just square the one factor
		//instead of computing two and multiplying them.
		//TODO: check further on the exact normalization factors needed.
		//Also note that we drop a factor of PI (we drop that from the specular light as well,
		//where we use n/8 instead of n/8pi as normalization).
		var invSchlick = pow(1.0 - facing * 0.5, 5.0);

		//If contrast is too high, and RaaS complains, set this factor to 1.
		var norm_factor = (28.0 / 23.0) * (1.0 - invSchlick) * (1.0 - invSchlick);

		outColor *= norm_factor * (1.0 - specular);
#endif

		outColor += cubeColor.xyz * schlickRefl.xyz;

		if (ibl.tonemapMethod == 1) {
			outColor = toneMapCanonOGS_WithGamma_WithColorPerserving(ibl.exposureBias * outColor);
		} else if (ibl.tonemapMethod == 2) {
			outColor = toneMapCanonFilmic_WithGamma(ibl.exposureBias * outColor);
		}

		output.color = vec4f(outColor, outAlpha);

		//Store normal and depth information for SSAO (opaque objects only)
		var discardNormal = 1.0;
		if (outAlpha < 0.9) {
			discardNormal = 0.0;
		}

		output.normal = vec4f(packNormal10(geomNormal), discardNormal);
		var unitDepth = (-in.depth - uniforms.near) / (uniforms.far - uniforms.near);
		output.viewDepth = vec4f(packDepth10(unitDepth), discardNormal);

		//Store object identity information
		output.dbId = intToVec(objectUniforms.dbId);


		//Currently we don't have enough room to bind a third ID render target (32 byte limit on render attachments),
		//so we stash half of the object flags in the modelId
		//output.objectFlags = intToVec(objectUniforms.objectFlags);

		output.modelId = intToVec(objectUniforms.modelId | (objectUniforms.objectFlags << 16));

		//If there is theming color, apply it at the end
		//If we ever use the uber shader for drawing ghosted objects, which are not subject to theming
		//we will also need to check the ghosting flag here (materialUniforms.renderFlags & 1)
		//Currently our ghosting effect is edges only.
		if (objectUniforms.themeColor != 0) {
			var themingColor = intToVecf(objectUniforms.themeColor);
			output.color = mix(output.color, themingColor, themingColor.a);
		}

		// (See the comment on theming above). If this shader is ever used for ghosted objects,
		// the heatmap chunk needs to check for that.
		${getHeatmapChunk()}

		// cap mesh hatch pattern
		if ((materialUniforms.renderFlags & 8) != 0) {
			output.color = calculateHatchPattern(materialUniforms.hatchParams, in.Position.xy, output.color,
				hatchTintColor, materialUniforms.hatchTintIntensity);
		}

		return output;
	}
	`;
}