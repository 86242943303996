import i18n from 'i18next';

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export class DtUser {

  constructor(_ref) {let { id, name, email, accessLevel, group } = _ref;
    this.id = id;
    this.isPendingUser = EMAIL_REGEX.test(id);

    this.name = name;
    this.accessLevel = accessLevel;
    this.group = group;

    this.email = this.isPendingUser ? id : email;

    this.isService = !this.email;
  }

  displayName() {
    if (this.isService) {
      return `${this.name || i18n.t("Service")} (${this.id})`;
    }
    return this.name || this.email || this.id;
  }

  compare(other) {
    if (this.isService != other.isService) {
      return this.isService ? 1 : -1;
    }
    if (this.isPendingUser != other.isPendingUser) {
      return this.isPendingUser ? 1 : -1;
    }
    return this.displayName().localeCompare(other.displayName());
  }
}