import { VBIntersector } from './VBIntersector';
import { FrustumIntersector } from './FrustumIntersector';
import { MeshFlags } from "./MeshFlags";
import { RenderFlags } from "./RenderFlags";
import { logger } from "../../logger/Logger";
import { LmvBox3 as Box3 } from './LmvBox3';

//Inlined from MeshFlags, because code that uses them is performance sensitive.
const MESH_VISIBLE = 1;
const MESH_HIGHLIGHTED = 2;
const MESH_HIDE = 4;
const MESH_ISLINE = 8;
const MESH_MOVED = 0x10; // indicates if an animation matrix is set
const MESH_RENDERFLAG = 0x20;
const MESH_ISPOINT = 0x40; // indicates that the mesh is vertex-only
const MESH_ISWIDELINE = 0x80; // indicates that the mesh is wide line

//Inlined from RenderFlags
const RENDER_HIGHLIGHTED = 1;
const RENDER_HIDDEN = 2;

const _tmpBox = new Box3();
var _depths = null;

/**
 * Represents a subset of objects from a larger list, for e.g. a draw call batch
 * to send to the renderer. It's like a small view into an ordered FragmentList.
 *
 * frags     -- FragmentList of all available meshes (1:1 correspondance with LMV fragments)
 * fragOrder -- Array of indices, pointing into the array of fragments
 * start     -- start index in the array of indices
 * count     -- how many mesh indices (after start index) are contained in the subset.
 * @constructor
 */
export function RenderBatch(frags, fragOrder, start, count) {

  this.frags = frags;
  this.indices = fragOrder; // may be a typed array (usually, Int32Array) or generic Array containing
  // the actual typed array in index 0, see getIndices(). May be null, which means indices[i]==i.
  this.start = start;
  this.count = count;
  this.lastItem = start; // Defines the (exclusive) range end used in this.forEach(). If a batch is complete, i.e. all fragments are added,
  // we usually have this.lastItem = this.start + this.count. But it may be smaller if dynamic adding is being used.
  // The final value of this.lastItem is set from outside by the creator (see e.g., ModelIteratorLinear or ModelIteratorBVH)
  // NOTE: this.lastItem must be set before this.forEach() has any effect.

  //Compatibility with THREE.Scene. Optional override material (instanceof THREE.ShaderMaterial) temporarily used by renderers.
  this.overrideMaterial = null;

  //Whether sort by material ID has been done
  this.sortDone = false;
  this.numAdded = 0; // number of added batches since last material sort

  this.avgFrameTime = undefined; // Average time spent for rendering this batch. Maintained externally by RenderScene.renderSome()

  this.nodeIndex = undefined; // Optional: Unique index of this RenderBatch (used by modelIteratorBVH/ConsolidationIterator)

  // Summed worldBoxes
  // First 6 terms are the visible bounds, second 6 terms are the hidden bounds
  this.bboxes = new Array(12);
  this.bboxes[0] = this.bboxes[1] = this.bboxes[2] = Infinity;
  this.bboxes[3] = this.bboxes[4] = this.bboxes[5] = -Infinity;
  this.bboxes[6] = this.bboxes[7] = this.bboxes[8] = Infinity;
  this.bboxes[9] = this.bboxes[10] = this.bboxes[11] = -Infinity;


  //Tells the renderer whether to sort by Z before drawing.
  //We only set this for RenderBatches containing transparent objects.
  this.sortObjects = false;

  this.sortByShaderDone = false;
  this.sortByVBDone = false;

  this._fragOrderChangedCallbacks = [];

  //Tells the renderer whether to do per-mesh frustum culling.
  //In some cases when we know the whole batch is completely
  //contained in the viewing frustum, we turn this off.
  this.frustumCulled = true;

  //Used by ground shadow code path
  this.forceVisible = false;

  // FragmentList do not always contain THREE.Meshes for each shape. They may also just contain plain BufferGeometry
  // and THREE.ShaderMaterial. In this case, the renderer must handle the this batch using immediate mode rendering.
  // (see FragmentList.getVizmesh() and WebGLRenderer.render() for details)
  this.renderImmediate = !frags.useThreeMesh;

  //Set per frame during scene traversal
  this.renderImportance = 0.0;

  this.isComplete = false;
  this.useRenderBundles = false;
  this._renderBundles = [];
}

RenderBatch.prototype.clone = function () {
  const renderBatch = new RenderBatch(this.frags, this.indices, this.start, this.count);
  renderBatch.sortDone = this.sortDone;
  renderBatch.sortByShaderDone = this.sortByShaderDone;
  renderBatch.lastItem = this.lastItem;
  renderBatch.visibleStats = this.visibleStats;
  renderBatch.numAdded = this.numAdded;
  renderBatch.bboxes = this.bboxes.slice();

  return renderBatch;
};

RenderBatch.prototype.getIndices = function () {
  // Note that isArray returns false for typed arrays like Int32Array.
  // isArray() is used to here to check whether indices is
  //  a) a typed array itself or
  //  b) a generic array containing the actual typed array in index 0.
  return Array.isArray(this.indices) ? this.indices[0] : this.indices;
};

/**
 * Registers a callback that is invoked when the order in which fragments are rendered changes.
 * @param {function} callback The callback to invoke on the event. Called with the start index and count of the
 *  modified range.
 */
RenderBatch.prototype.registerFragOrderChangedCallback = function (callback) {
  this._fragOrderChangedCallbacks.push(callback);
};

/**
 * Deregisters a callback that has previously been registered via `registerFragOrderChangedCallback`.
 * @param {function} callback The callback to deregister.
 */
RenderBatch.prototype.removeFragOrderChangedCallback = function (callback) {
  const index = this._fragOrderChangedCallbacks.indexOf(callback);

  if (index !== -1) {
    this._fragOrderChangedCallbacks.splice(index, 1);
  }
};

// Sorts
RenderBatch.prototype.sortByMaterial = function () {

  //Render batch must be complete before we can sort it
  if (this.numAdded < this.count)
  return;

  var frags = this.frags;
  var indices = this.getIndices();

  if (!indices) {
    logger.warn("Only indexed RenderSubsets can be sorted.");
    return;
  }

  // apply sort only to the range used by this batch
  var tmp = indices.subarray(this.start, this.start + this.count);
  Array.prototype.sort.call(tmp, function (a, b) {
    var ma = frags.getMaterialId(a);
    var mb = frags.getMaterialId(b);

    if (ma === undefined)
    return mb ? 1 : 0;
    if (mb === undefined)
    return -1;

    return ma - mb;
  });

  //indices.set(tmp, this.start); // not needed because tmp already points to the same buffer

  // indicate that indices are sorted by material and no batches have beend added since then.
  this.numAdded = 0;
  this.sortDone = true;

  // Notify listeners about the new order.
  for (const callback of this._fragOrderChangedCallbacks) {
    callback(this.start, this.lastItem - this.start);
  }
};

//Sorts meshes in the render batch by shader ID, to avoid
//unnecessary shader switching in the renderer when looping over a batch.
//This can only be performed once the RenderBatch is full/complete and
//all shaders are known.
RenderBatch.prototype.sortByShader = function () {

  //Render batch must be complete before we can sort it
  if (!this.sortDone || this.sortByShaderDone)
  return;

  var frags = this.frags;
  var indices = this.getIndices();

  var tmp = indices.subarray(this.start, this.start + this.count);

  Array.prototype.sort.call(tmp, function (a, b) {
    var ma = frags.getMaterial(a);
    var mb = frags.getMaterial(b);

    var pd = ma.program.id - mb.program.id;
    if (pd)
    return pd;

    return ma.id - mb.id;
  });

  //indices.set(tmp, this.start);

  this.numAdded = 0;
  this.sortByShaderDone = true;
};

RenderBatch.prototype.sortByVertexBuffer = function (start, end) {

  //Render batch must be complete before we can sort it
  if (this.sortByVBDone)
  return;

  let frags = this.frags;
  let indices = this.getIndices();

  let tmp = indices.subarray(start, end);

  //TODO: sort by underlying GPUBuffer id, to minimize buffer switching
  Array.prototype.sort.call(tmp, function (a, b) {
    let ga = frags.getGeometry(a);
    let gb = frags.getGeometry(b);

    let vbida = ga ? ga.__gpuvb?.id : Number.MAX_SAFE_INTEGER;
    let vbidb = gb ? gb.__gpuvb?.id : Number.MAX_SAFE_INTEGER;

    if (vbida === undefined || vbidb === undefined) {
      console.log("sorting too early");
    }

    if (vbida < vbidb) {
      return -1;
    } else if (vbida > vbidb) {
      return 1;
    }

    let ibida = ga ? ga.__gpuib?.id : Number.MAX_SAFE_INTEGER;
    let ibidb = gb ? gb.__gpuib?.id : Number.MAX_SAFE_INTEGER;

    if (ibida < ibidb) {
      return -1;
    } else if (ibida > ibidb) {
      return 1;
    }

    let matIda = frags.getMaterial(a)?.id ?? Number.MAX_SAFE_INTEGER;
    let matIdb = frags.getMaterial(b)?.id ?? Number.MAX_SAFE_INTEGER;

    return matIda - matIdb;
    //TODO: we need to cache the WebGPU pipeline hash in order to use it here --
    //multiple materials can map to the same hash
    //return mata.__gpuPipelineHash - matb.__gpuPipelineHash;
  });

  //indices.set(tmp, this.start);

  this.numAdded = 0;
  this.sortByVBDone = true;
  this.sortDone = true;

  // Notify listeners about the new order.
  for (const callback of this._fragOrderChangedCallbacks) {
    callback(this.start, this.lastItem - this.start);
  }
};


// Sorts this.indices by increasing depth for the current view.
// Input: frustumIn instanceof FrustumIntersector
RenderBatch.prototype.sortByDepth = function (frustumIn) {

  var frags = this.frags;
  var indices = this.getIndices();
  var frustum = frustumIn;
  var bbox = _tmpBox;

  if (!indices) {
    logger.warn("Only indexed RenderSubsets can be sorted.");
    return;
  }

  // allocate this.depth to store a depth value for each fragment index in indicesView
  if (!_depths || _depths.length < this.count)
  _depths = new Float32Array(this.count);

  var depths = _depths;
  var start = this.start;

  // For each fragId indicesView[i], compute the depth and store it in depth[i]
  this.forEachNoMesh(
    (fragId, i) => {// use frustum to calculate depth per fragment
      if (!frags.getGeometry(fragId))
      depths[i] = -Infinity;else
      {
        frags.getWorldBounds(fragId, bbox);
        depths[i] = frustum.estimateDepth(bbox);
      }
    }
  );

  // Does not work, this call sorts on depths[indicesViews[i]], not depths[i],
  // where 'i' is an index into both the depths and indicesViews lists.
  //Array.prototype.sort.call(this.indicesView, sortCB);

  // Insertion sort appears to be about 7x or more faster
  // for lists of 64 or less objects vs. defining a sort() function.
  // Asking if there's a faster way. Traian mentioned quicksort > 8
  // objects; I might give this a try.
  var tempDepth, tempIndex;
  for (var j = 1; j < this.count; j++) {
    var k = j;
    while (k > 0 && depths[k - 1] < depths[k]) {

      // swap elem at position k one position backwards (for indices and depths)
      tempDepth = depths[k - 1];
      depths[k - 1] = depths[k];
      depths[k] = tempDepth;

      tempIndex = indices[start + k - 1];
      indices[start + k - 1] = indices[start + k];
      indices[start + k] = tempIndex;

      k--;
    }
  }

  //indices.set(this.indicesView, this.start); // Not needed because indicesView is already a view into this range

  // Notify listeners about the new order.
  for (const callback of this._fragOrderChangedCallbacks) {
    callback(this.start, this.lastItem - this.start);
  }
};

//Adds the given THREE.Box3 to the renderBatch bounding box or hidden object bounding box
RenderBatch.prototype.addToBox = function (box, hidden) {
  var offset = hidden ? 6 : 0;
  var bb = this.bboxes;
  bb[0 + offset] = Math.min(bb[0 + offset], box.min.x);
  bb[1 + offset] = Math.min(bb[1 + offset], box.min.y);
  bb[2 + offset] = Math.min(bb[2 + offset], box.min.z);

  bb[3 + offset] = Math.max(bb[3 + offset], box.max.x);
  bb[4 + offset] = Math.max(bb[4 + offset], box.max.y);
  bb[5 + offset] = Math.max(bb[5 + offset], box.max.z);
};

RenderBatch.prototype.getBoundingBox = function (dst) {
  dst = dst || _tmpBox;
  var bb = this.bboxes;
  dst.min.x = bb[0];
  dst.min.y = bb[1];
  dst.min.z = bb[2];

  dst.max.x = bb[3];
  dst.max.y = bb[4];
  dst.max.z = bb[5];

  return dst;
};

RenderBatch.prototype.getBoundingBoxHidden = function (dst) {
  dst = dst || _tmpBox;
  var bb = this.bboxes;
  var offset = 6;
  dst.min.x = bb[0 + offset];
  dst.min.y = bb[1 + offset];
  dst.min.z = bb[2 + offset];

  dst.max.x = bb[3 + offset];
  dst.max.y = bb[4 + offset];
  dst.max.z = bb[5 + offset];

  return dst;
};

//Use only for incremental adding to linearly ordered (non-BVH) scenes!
RenderBatch.prototype.onFragmentAdded = function (fragId) {
  // update bbox
  this.frags.getWorldBounds(fragId, _tmpBox);
  this.addToBox(_tmpBox, false);

  // mark
  this.sortDone = false;

  //NOTE: This only works with trivial fragment ordering (linear render queues).
  //Otherwise the item index does not necessarily match the fragId due to the
  //reordering jump table (this.indices).
  if (this.lastItem <= fragId) {
    this.lastItem = fragId + 1;
    if (this.visibleStats !== undefined)
    this.visibleStats = 0; // reset visibility, since a new fragment might change it
    this.numAdded++;
  }
};


/**
 * Iterates over fragments.
 * @param {function} callback - function(mesh, id) called for each fragment geometry.
 *      - mesh: instanceof THREE.Mesh (as obtained from FragmentList.getVizmesh)
 *      - id:   fragment id
 * @param {number} drawMode - Optional flag (see FragmentList.js), e.g., MESH_VISIBLE. If specified, we only traverse fragments for which this flag is set.
 * @param {bool} includeEmpty - Default: false, i.e. fragments are skipped if they have no mesh available via getVizmesh().
 */
RenderBatch.prototype.forEach = function (callback, drawMode, includeEmpty) {

  const indices = this.getIndices();
  const frags = this.frags;

  let sortByShaderPossible = !this.sortByShaderDone;

  //If the most likely rendering flags are true, use a shortened version of the for-loop.
  if (!drawMode && !includeEmpty && !sortByShaderPossible) {
    for (let i = this.start, iEnd = this.lastItem; i < iEnd; i++) {
      let idx = indices ? indices[i] : i;

      let m = frags.getVizmesh(idx);

      if (m?.geometry) {
        callback(m, idx);
      }
    }
  } else {
    for (let i = drawMode === MESH_RENDERFLAG && this.hasOwnProperty("drawStart") ? this.drawStart : this.start, iEnd = this.lastItem; i < iEnd; i++) {
      let idx = indices ? indices[i] : i;

      let m = frags.getVizmesh(idx);

      if (sortByShaderPossible && !m?.material?.program)
      sortByShaderPossible = false;

      // if drawMode is given, iterate vizflags that match
      if ((includeEmpty || m?.geometry) && (
      !drawMode || frags.isFlagSet(idx, drawMode))) {

        callback(m, idx);
      }
    }
  }

  //If all materials shaders are already available, we can sort by shader
  //to minimize shader switches during rendering.  This sort will only
  //execute once and changing materials later will break the sorted order again.
  if (sortByShaderPossible)
  this.sortByShader();
};

RenderBatch.prototype.is2d = function () {
  return false;
};

/**
 * Iterates over fragments.
 * @param {function} callback - function(mesh, id, idx) called for each fragment geometry.
 *      - mesh: instanceof THREE.Mesh (as obtained from FragmentList.getVizmesh)
 *      - id:   fragment id
 *      - idx:  fragment index in the RenderBatch
 * @param {Number} startIndex - Optional start iteration at a specific index (used for loop pause/continuation)
 * @param {Number} loopLimit - Optional maximum number of items to loop over before stopping*
 */
RenderBatch.prototype.forEachWGPU = function (startIndex, loopLimit, callback) {

  const drawMode = (this.forceVisible ? MESH_VISIBLE : MESH_RENDERFLAG) | 0;
  const indices = this.getIndices();
  const frags = this.frags;

  let isComplete = true;

  const start = startIndex || this.start;
  let iEnd = this.lastItem;
  if (loopLimit) {
    iEnd = Math.min(iEnd, start + loopLimit);
  }

  //Super ugly inlined version of FragmentList.getVizmesh()
  const geomsGeoms = frags.geoms.geoms;
  const vizflags = frags.vizflags;
  const geomids = frags.geomids;
  const geomDataIndices = frags.fragments.geomDataIndexes;
  const materialIdMap = frags.materialIdMap;
  const materialids = frags.materialids;

  let count = 0;
  let i;

  if (!this.isComplete) {
    for (i = start; i < iEnd; i++) {
      const fragId = indices[i] | 0;

      // init temp mesh object from geometry, material etc.
      const geometry = geomsGeoms[geomids[fragId]];

      if (!geometry) {
        const geomDataId = geomDataIndices[fragId];
        if (geomDataId !== 0) {
          // Some geometries will never arrive. If the geomDataId is 0, we know it doesn't exist.
          // Don't mark as incomplete in this case.
          isComplete = false;
        }
        continue;
      }

      if (!geometry.__gpuvb) {
        isComplete = false;
      }

      const flags = vizflags[fragId];

      if (!(flags & drawMode)) {
        continue;
      }

      callback(geometry, materialIdMap[materialids[fragId]], i);

      count++;
    }

    if (isComplete) {
      this.isComplete = true;
      this.useRenderBundles = !this.sortObjects;
    }
  } else {
    if (!this.sortByVBDone) {
      // This would ideally be done once in the isComplete block above.
      // But sorting the fragments after invoking the render callback messes with the
      // fragment -> uniform buffer location association. It's just a single check run once per batch though,
      // and the runtime should start to predict / skip it fairly quickly.
      this.sortByVertexBuffer(start, iEnd);
    }

    for (i = start; i < iEnd; i++) {

      const fragId = indices[i];

      const flags = vizflags[fragId];

      if (!(flags & drawMode)) {
        continue;
      }

      callback(geomsGeoms[geomids[fragId]], materialIdMap[materialids[fragId]], i);
      count++;
    }
  }

  return i === this.lastItem ? 0 : i;
};

RenderBatch.prototype.setRenderBundle = function (index, renderBundle) {
  this._renderBundles[index] = renderBundle;
};

RenderBatch.prototype.getRenderBundle = function (index) {
  return this._renderBundles[index];
};

RenderBatch.prototype.clearRenderBundles = function () {
  this._renderBundles.length = 0;
};

/**
 * Iterates over fragments. Like forEach(), but takes a different callback.
 * @param {function} callback - function(fragId, idx) called for each fragment geometry.
 *      - fragId:   fragment id
 *      - idx:      running index from 0 .. (lastItem-start)
 * @param {number} drawMode - Optional flag (see FragmentList.js), e.g., MESH_VISIBLE. If specified, we only traverse fragments for which this flag is set.
 * @param {bool} includeEmpty - Default: false, i.e. fragments are skipped if they have no mesh available via getVizmesh().
 */
RenderBatch.prototype.forEachNoMesh = function (callback, drawMode, includeEmpty) {

  const indices = this.getIndices();
  const frags = this.frags;

  for (let i = this.start, iEnd = this.lastItem; i < iEnd; i++) {
    let fragId = indices[i];

    // get geometry - in this case just to check if it is available
    let geometry;
    if (frags.useThreeMesh) {
      let m = frags.getVizmesh(fragId);
      geometry = m?.geometry;
    } else {
      geometry = frags.getGeometry(fragId);
    }

    // if drawMode is given, iterate vizflags that match
    if ((includeEmpty || geometry) && (
    !drawMode || frags.isFlagSet(fragId, drawMode))) {

      callback(fragId, i - this.start);
    }
  }
};

/**
 * Checks if given ray hits a bounding box of any of the fragments.
 * @param {THREE.RayCaster} raycaster
 * @param {Object[]}        intersects - An object array that contains intersection result objects.
 *                                       Each result r stores properties like r.point, r.fragId, r.dbId. (see VBIntersector.js for details)
 * @param {number[]}       [dbIdFilter] - Array of dbIds. If specieed, only fragments with dbIds inside the filter are checked.
 */
RenderBatch.prototype.raycast = function (raycaster, intersects, dbIdFilter) {

  //Assumes bounding box is up to date.
  if (raycaster.ray.intersectsBox(this.getBoundingBox()) === false)
  return;

  // traverse all visible meshes
  this.forEach((m, fragId) => {

    // Don't intersect hidden objects
    if (this.frags.isFlagSet(fragId, MeshFlags.MESH_HIDE))
    return;

    //Check the dbIds filter if given
    if (dbIdFilter && dbIdFilter.length) {
      //Theoretically this can return a list of IDs (for 2D meshes)
      //but this code will not be used for 2D geometry intersection.
      var dbId = 0 | this.frags.getDbIds(fragId);

      //dbIDs will almost always have just one integer in it, so
      //indexOf should be fast enough.
      if (dbIdFilter.indexOf(dbId) === -1)
      return;
    }

    // raycast worldBox first.
    this.frags.getWorldBounds(fragId, _tmpBox);

    // Expand bounding box a bit, to take into account axis aligned lines
    _tmpBox.expandByScalar(0.5);

    if (raycaster.ray.intersectsBox(_tmpBox)) {
      // worldbox was hit. do raycast with actucal geometry.
      VBIntersector.rayCast(m, raycaster, intersects);
    }
  }, MeshFlags.MESH_VISIBLE);
};

/**
 * Checks if a given FrustumIntersector hits the bounding box of any of the fragments. Calls the callback if it does.
 * @param {FrustumIntersector}  frustumIntersector
 * @param {Function}            callback - callback function to receive fragment IDs which intersect or are contained by the frustum
 * @param {Boolean}             [containmentKnown] - true if it's already known that the RenderBatch is fully contained by the frustum
 */
RenderBatch.prototype.intersectFrustum = function (frustumIntersector, callback, containmentKnown, includeGhosted) {

  if (!containmentKnown) {
    this.getBoundingBox(_tmpBox);
    if (includeGhosted) {
      const bboxHidden = this.getBoundingBoxHidden();
      _tmpBox.union(bboxHidden);
    }

    if (_tmpBox.isEmpty()) {
      // Don't do intersection if this batch's bbox is empty
      return;
    }

    let result = frustumIntersector.intersectsBox(_tmpBox);
    if (result === FrustumIntersector.OUTSIDE) {
      return;
    }
    if (result === FrustumIntersector.CONTAINS) {
      containmentKnown = true;
    }
  }

  // traverse all visible meshes
  this.forEach((m, fragId) => {

    // Don't intersect hidden objects
    if (this.frags.isFlagSet(fragId, MeshFlags.MESH_HIDE))
    return;

    if (containmentKnown) {
      callback(fragId, containmentKnown);
      return;
    }

    // raycast worldBox first.
    this.frags.getWorldBounds(fragId, _tmpBox);

    let result = frustumIntersector.intersectsBox(_tmpBox);
    if (result !== FrustumIntersector.OUTSIDE) {
      callback(fragId, result === FrustumIntersector.CONTAINS);
    }
  }, includeGhosted ? 0 : MeshFlags.MESH_VISIBLE);
};

/**
 * Computes/updates the bounding boxes of this batch.
 */
RenderBatch.prototype.calculateBounds = function () {
  // init boxes for visible and ghosted meshes
  let vminx = Infinity,vminy = Infinity,vminz = Infinity,
    vmaxx = -Infinity,vmaxy = -Infinity,vmaxz = -Infinity;

  let gminx = Infinity,gminy = Infinity,gminz = Infinity,
    gmaxx = -Infinity,gmaxy = -Infinity,gmaxz = -Infinity;

  const frags = this.frags;
  const vizflags = frags.vizflags;
  const indices = this.getIndices();

  // Why including null geometry?: If we would exclude fragments whose geometry is not loaded yet, we would need to refresh all bboxes permanently during loading.
  // Since we know bboxes earlier than geometry (for SVF at FragmentList construction time and for Otg as soon as BVH data is available), including empty meshes
  // ensures that the bbox result is not affected by geometry loading state for 3D.
  for (let i = this.start, iEnd = this.lastItem; i < iEnd; i++) {
    const fragId = indices[i];

    // adds box of a fragment to visible bounds or ghosted bounds, depending on its vizflags.
    frags.getWorldBounds(fragId, _tmpBox);

    const hidden = (vizflags[fragId] & 0x1 /*MESH_VISIBLE*/) === 0;

    if (hidden) {
      if (_tmpBox.min.x < gminx) gminx = _tmpBox.min.x;
      if (_tmpBox.min.y < gminy) gminy = _tmpBox.min.y;
      if (_tmpBox.min.z < gminz) gminz = _tmpBox.min.z;

      if (_tmpBox.max.x > gmaxx) gmaxx = _tmpBox.max.x;
      if (_tmpBox.max.y > gmaxy) gmaxy = _tmpBox.max.y;
      if (_tmpBox.max.z > gmaxz) gmaxz = _tmpBox.max.z;
    } else {
      if (_tmpBox.min.x < vminx) vminx = _tmpBox.min.x;
      if (_tmpBox.min.y < vminy) vminy = _tmpBox.min.y;
      if (_tmpBox.min.z < vminz) vminz = _tmpBox.min.z;

      if (_tmpBox.max.x > vmaxx) vmaxx = _tmpBox.max.x;
      if (_tmpBox.max.y > vmaxy) vmaxy = _tmpBox.max.y;
      if (_tmpBox.max.z > vmaxz) vmaxz = _tmpBox.max.z;
    }
  }

  this.bboxes[0] = vminx;this.bboxes[1] = vminy;this.bboxes[2] = vminz;
  this.bboxes[3] = vmaxx;this.bboxes[4] = vmaxy;this.bboxes[5] = vmaxz;

  this.bboxes[6] = gminx;this.bboxes[7] = gminy;this.bboxes[8] = gminz;
  this.bboxes[9] = gmaxx;this.bboxes[10] = gmaxy;this.bboxes[11] = gmaxz;

};

/**
 * Sets the MESH_RENDERFLAG for a single fragment, depeneding on the drawMode and the other flags of the fragment.
 * @param {number} drawMode - One of the modes defined in RenderFlags.js, e.g. RENDER_NORMAL
 * @param {number} vizflags - vizflags bitmask.
 * @param {number} idx - index into vizflags, for which we want to determine the MESH_RENDERFLAG.
 * @param {bool} hideLines
 * @param {bool} hidePoints
 * @returns {bool} Final, evaluated visibility.
 */
function evalVisibility(drawMode, vizflags, idx, hideLines, hidePoints) {

  var v;
  var vfin = vizflags[idx] & ~MESH_RENDERFLAG;
  switch (drawMode) {

    case RENDER_HIDDEN:
      v = !(vfin & MESH_VISIBLE); //visible (bit 0 on)
      break;
    case RENDER_HIGHLIGHTED:
      v = vfin & MESH_HIGHLIGHTED; //highlighted (bit 1 on)
      break;
    default:
      v = (vfin & (MESH_VISIBLE | MESH_HIGHLIGHTED | MESH_HIDE)) === 1; //visible but not highlighted, and not a hidden line (bit 0 on, bit 1 off, bit 2 off)
      break;
  }

  if (hideLines) {
    var isLine = vfin & (MESH_ISLINE | MESH_ISWIDELINE);
    v = v && !isLine;
  }

  if (hidePoints) {
    var isPoint = vfin & MESH_ISPOINT;
    v = v && !isPoint;
  }

  //Store evaluated visibility into bit 7 of the vizflags
  //to use for immediate rendering
  vizflags[idx] = vfin | (v ? MESH_RENDERFLAG : 0);

  return v;
}


/**
 * Checks if fragment is outside the frustum.
 * @param {bool} checkCull - indicates if culling is enabled. If false, return value is always false.
 * @param {FrustumIntersector} frustum
 * @param {FragmentList} frags
 * @param {number} idx - index into frags.
 * @param {bool} doNotCut - do not apply cutplanes
 * @returns {bool} True if the given fragment is outside the frustum and culling is enabled.
 */
function evalCulling(checkCull, frustum, frags, idx, doNotCut) {

  var culled = false;

  frags.getWorldBounds(idx, _tmpBox);
  if (checkCull && !frustum.intersectsBox(_tmpBox)) {
    culled = true;
  }

  // apply cutplane culling
  // TODO: We ignore checkCull, because checkCull is set to false if the RenderBatch is fully
  //       inside the frustum - which still tells nothing about the cutplanes.
  //       Ideally, we should a corresponding hierarchical check per cutplane too.
  if (!culled && !doNotCut && frustum.boxOutsideCutPlanes(_tmpBox)) {
    culled = true;
  }

  //This code path disabled because it was found to slow things down overall.
  /*
  else {
      // Check whether the projected area is smaller than a threshold,
      // if yes, do not render it.
      var area = frustum.projectedBoxArea(_tmpBox, !checkCull);
      area *= frustum.areaConv;
      if (area < frustum.areaCullThreshold) {
          culled = true;
      }
  }
  */

  return culled;
}


/**
 * Updates visibility for all fragments of this RenderBatch.
 * This means:
 *  1. It returns true if all meshes are hidden (false otherwise)
 *
 *  2. If the whole batch box is outside the frustum, nothing else is done.
 *     (using this.getBoundingBox() or this.getBoundingBoxHidden(), depending on drawMode)
 *
 *  3. For all each checked fragment with fragId fid and mesh m, the final visibility is stored...
 *      a) In the m.visible flag.
 *      b) In the MESH_RENDERFLAG of the vizflags[fid]
 *     This is only done for fragments with geometry.
 * @param {number} drawMode - One of the modes defined in RenderFlags.js, e.g. RENDER_NORMAL
 * @param {FrustumIntersector} frustum
 * @returns {bool} True if all meshes are hidden (false otherwise).
 */
RenderBatch.prototype.applyVisibility = function () {

  let frags, vizflags, frustum, drawMode, checkCull, allHidden, doNotCut, useRenderBundles;

  // Callback to apply visibility for a single fragment
  //
  // Input: Geometry and index of a fragment, i.e.
  //  m:   instanceof THREE.Mesh (see FragmentList.getVizmesh). May be null.
  //  idx: index of the fragment in the fragment list.
  //
  // What is does:
  //  1. bool m.visible is updated based on flags and frustum check (if m!=null)
  //  2. The MESH_RENDERFLAG flag is updated for this fragment, i.e., is true for meshes with m.visible==true
  //  3. If there is no geometry and there is a custom callback (checkCull)
  //  4. Set allHidden to false if any mesh passes as visible.
  function applyVisCB(m, idx) {

    // if there's no mesh or no geometry, just call the custom callback.
    // [HB:] I think it would be clearer to remove the frags.useThreeMesh condition here.
    //       It's not really intuitive that for (m==0) the callback is only called for frags.useThreeMesh.
    //       Probably the reason is just that this code section has just been implemented for the useThreeMesh
    //       case and the other one was irrelevant.
    if (!m && frags.useThreeMesh || !m.geometry) {
      return;
    }

    // apply frustum check for this fragment
    var culled = evalCulling(checkCull, frustum, frags, idx, doNotCut);

    // if outside, set m.visbile and the MESH_RENDERFLAG of the fragment to false
    if (culled) {
      if (m) {
        m.visible = false;
      } else {
        logger.warn("Unexpected null mesh");
      }
      // unset MESH_RENDERFLAG
      vizflags[idx] = vizflags[idx] & ~MESH_RENDERFLAG;

      return;
    }

    // frustum check passed. But it might still be invisible due to vizflags and/or drawMode.
    // Note that evalVisibility also updates the MESH_RENDERFLAG already.
    var v = evalVisibility(drawMode, vizflags, idx, frags.linesHidden, frags.pointsHidden);

    if (m)
    m.visible = !!v;

    // Set to false if any mesh passes as visible
    allHidden = allHidden && !v;
  }

  // Similar to applyVisCB above, but without geometry param, so that we don't set any m.visible property.
  function applyVisCBNoMesh(idx) {

    // No need to check if there is a geometry for the fragment.
    // forEachNoMesh is called with includeEmpty=false, so this callback won't be invoked if there is none.

    // We need to skip per fragment culling when using render bundles.
    // If bundles have already been recorded, this is just a performance optimization, because we don't render
    // individual fragments anyway (but just replay the previously recorded bundle).
    // If a bundle is currently recorded, we want to include all fragments of a batch, because they wouldn't show
    // if the bundle was recorded without them and they get into the frustum later.
    if (!useRenderBundles) {
      // apply frustum check for this fragment
      var culled = evalCulling(checkCull, frustum, frags, idx, doNotCut);

      // if culled, set visflags MESH_RENDERFLAG to false
      if (culled) {
        vizflags[idx] = vizflags[idx] & ~MESH_RENDERFLAG;
        return;
      }
    }

    // frustum check passed. But it might still be invisible due to vizflags and/or drawMode.
    // Note that evalVisibility also updates the MESH_RENDERFLAG already.
    var v = evalVisibility(drawMode, vizflags, idx, frags.linesHidden, frags.pointsHidden);

    // Set to false if any mesh passes as visible
    allHidden = allHidden && !v;
  }

  return function (drawModeIn, frustumIn) {

    //Used when parts of the same scene
    //have to draw in separate passes (e.g. during isolate).
    //Consider maintaining two render queues instead if the
    //use cases get too complex, because this approach
    //is not very scalable as currently done (it traverses
    //the entire scene twice, plus the flag flipping for each item).

    allHidden = true;
    frustum = frustumIn;
    drawMode = drawModeIn;

    const bbox = drawMode === RENDER_HIDDEN ? this.getBoundingBoxHidden() : this.getBoundingBox();

    //Check if the entire render batch is contained inside
    //the frustum. This will save per-object checks.
    const containment = frustum.intersectsBox(bbox);
    if (containment === FrustumIntersector.OUTSIDE)
    return allHidden; //nothing to draw

    doNotCut = this.frags.doNotCut;
    if (!doNotCut && frustumIn.boxOutsideCutPlanes(bbox)) {
      return allHidden;
    }

    vizflags = this.frags.vizflags;
    frags = this.frags;
    checkCull = containment !== FrustumIntersector.CONTAINS;
    useRenderBundles = this.useRenderBundles;

    // The main difference between applyVisCB and applyVisCBNoMesh is that applyVisCB also updates mesh.visible for each mesh.
    // This does only make sense when using THREE.Mesh. Otherwise, the mesh containers are volatile anyway (see FragmentList.getVizmesh)
    if (!frags.useThreeMesh) {
      // TODO: We should eventually be able to skip this loop entirely when using a valid render bundle.
      // Below is inlined version of this.forEachNoMesh(applyVisCBNoMesh, 0, false)
      const indices = this.getIndices();
      const frags = this.frags;
      const linesHidden = frags.linesHidden;
      const pointsHidden = frags.pointsHidden;

      // We need to skip per fragment culling when using render bundles.
      // If bundles have already been recorded, this is just a performance optimization, because we don't render
      // individual fragments anyway (but just replay the previously recorded bundle).
      // If a bundle is currently recorded, we want to include all fragments of a batch, because they wouldn't show
      // if the bundle was recorded without them and they get into the frustum later.
      if (useRenderBundles) {

        if (!linesHidden && !pointsHidden) {
          for (let i = this.start, iEnd = this.lastItem; i < iEnd; i++) {
            const fragId = indices[i];

            // get geometry - in this case just to check if it is available
            const geometry = frags.getGeometry(fragId);

            if (!geometry) continue;

            // frustum check passed. But it might still be invisible due to vizflags and/or drawMode.
            // Note that evalVisibility also updates the MESH_RENDERFLAG already.
            //let v = evalVisibility(0, vizflags, fragId, linesHidden, pointsHidden);

            const vfin = vizflags[fragId] & ~MESH_RENDERFLAG;
            let v;
            switch (drawMode) {

              case RENDER_HIDDEN:
                v = !(vfin & MESH_VISIBLE); //visible (bit 0 on)
                break;
              case RENDER_HIGHLIGHTED:
                v = vfin & MESH_HIGHLIGHTED; //highlighted (bit 1 on)
                break;
              default:
                v = (vfin & (MESH_VISIBLE | MESH_HIGHLIGHTED | MESH_HIDE)) === 1; //visible but not highlighted, and not a hidden line (bit 0 on, bit 1 off, bit 2 off)
                break;
            }

            //Store evaluated visibility into bit 7 of the vizflags
            //to use for immediate rendering
            vizflags[fragId] = vfin | (v ? MESH_RENDERFLAG : 0);

            // Set to false if any mesh passes as visible
            allHidden = allHidden && !v;
          }
        } else {
          for (let i = this.start, iEnd = this.lastItem; i < iEnd; i++) {
            const fragId = indices[i];

            // get geometry - in this case just to check if it is available
            const geometry = frags.getGeometry(fragId);

            if (!geometry) continue;

            // frustum check passed. But it might still be invisible due to vizflags and/or drawMode.
            // Note that evalVisibility also updates the MESH_RENDERFLAG already.
            //let v = evalVisibility(0, vizflags, fragId, linesHidden, pointsHidden);

            const vfin = vizflags[fragId] & ~MESH_RENDERFLAG;
            let v;
            switch (drawMode) {

              case RENDER_HIDDEN:
                v = !(vfin & MESH_VISIBLE); //visible (bit 0 on)
                break;
              case RENDER_HIGHLIGHTED:
                v = vfin & MESH_HIGHLIGHTED; //highlighted (bit 1 on)
                break;
              default:
                v = (vfin & (MESH_VISIBLE | MESH_HIGHLIGHTED | MESH_HIDE)) === 1; //visible but not highlighted, and not a hidden line (bit 0 on, bit 1 off, bit 2 off)
                break;
            }

            if (linesHidden) {
              const isLine = vfin & (MESH_ISLINE | MESH_ISWIDELINE);
              v = v && !isLine;
            }

            if (pointsHidden) {
              const isPoint = vfin & MESH_ISPOINT;
              v = v && !isPoint;
            }

            //Store evaluated visibility into bit 7 of the vizflags
            //to use for immediate rendering
            vizflags[fragId] = vfin | (v ? MESH_RENDERFLAG : 0);


            // Set to false if any mesh passes as visible
            allHidden = allHidden && !v;
          }
        }
      } else {
        for (let i = this.start, iEnd = this.lastItem; i < iEnd; i++) {
          const fragId = indices[i];

          // get geometry - in this case just to check if it is available
          const geometry = frags.getGeometry(fragId);

          if (!geometry) continue;

          // apply frustum check for this fragment
          const culled = evalCulling(checkCull, frustum, frags, fragId, doNotCut);

          // if culled, set visflags MESH_RENDERFLAG to false
          if (culled) {
            vizflags[fragId] = vizflags[fragId] & ~MESH_RENDERFLAG;
            continue;
          }

          // frustum check passed. But it might still be invisible due to vizflags and/or drawMode.
          // Note that evalVisibility also updates the MESH_RENDERFLAG already.
          const v = evalVisibility(drawMode, vizflags, fragId, linesHidden, pointsHidden);

          // Set to false if any mesh passes as visible
          allHidden = allHidden && !v;
        }
      }

    } else {
      // Use callback that also sets mesh.visible.
      // Skip fragments without geometry unless a custom callback is defined (fragIdCB)
      this.forEach(applyVisCB, null);
    }

    frags = null;

    return allHidden;
  };
}();