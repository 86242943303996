// Copyright 2023 Autodesk, Inc.
// All rights reserved.
// This computer source code and related instructions and comments are the
// unpublished confidential and proprietary information of Autodesk, Inc.
// and are protected under Federal copyright and state trade secret law.
// They may not be disclosed to, copied or used by any third party without
// the prior written consent of Autodesk, Inc.


import { DtConstants } from "../schema/DtConstants";
import { ElementFlags, KeyFlags } from "../schema/dt-schema";
import * as et from '../../application/EventTypes';
import * as dte from '../DtEventTypes';
import {
  PerModelFacet,
  MergedFacetNode,
  FacetSpaces,
  deserializeFacet,
  FacetTypes,
  getDefaultFacetList,
  FacetAttribute,
  FacetRegistry,
  FacetSystems } from
"./Facets";

import { FacetVizEffects, ROOMS_OVERLAY } from "./FacetVizEffects";
import { Prefs3D } from "../../application/PreferenceNames";
import { SelectionMode } from "../../wgs/scene/SelectionMode";
const RC = require("../resources/cats_enum.json");

const UNDEFINED = '(undefined)';
// Models is an immovable facet, and it is kept at this index.
const MODEL_FACET_DEFS_IDX = 0;

function smallArraysEqual(a, b) {
  //Definitely not a fast or robust implementation -- this only expects arrays with up to 3 elements
  //as input.
  if (!a) a = [];
  if (!b) b = [];

  return a.every((item) => b.includes(item)) && b.every((item) => a.includes(item));
}

/**
 * @param {FacetDef[]} facetDefs
 * @param {PerModelFacet[]} facetNodes
 * @param {Number} depth
 * @param {Object} mergedFacets
 * @param {String} modelAdded - optional urn of model if one was added
 * @param {Boolean} ignoreFilter - optional true to ignore facet filters
 * @param {Boolean[]} implicitSelectedFacets - optional Whether all nodes of facet at given depth are unselected.
 */
function mergeFacets(facetDefs, facetNodes, depth, mergedFacets, modelAdded, ignoreFilter) {let implicitSelectedFacets = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : [];
  if (depth >= facetDefs.length) {
    return;
  }
  const nextFacets = [];
  const filter = facetDefs[depth].filter;
  if (filter.has(UNDEFINED)) {
    filter.delete(UNDEFINED);
    filter.add(undefined);
  }
  const dest = mergedFacets[depth];
  const noFacetSelected = ignoreFilter || facetNodes.every((facet) => !filter.has(facet.id));
  implicitSelectedFacets[depth] = noFacetSelected;

  for (let fnode of facetNodes) {
    const fnodeId = fnode.id;

    let mergedNode = dest[fnodeId];
    if (!mergedNode) {
      if (depth !== 0 && !noFacetSelected && fnode.modelUrn === modelAdded) {
        // This marks newly added facet items as selected when a new model is turned on
        filter.add(fnodeId);
      }
      dest[fnodeId] = mergedNode = new MergedFacetNode(fnode, filter.has(fnodeId));
    }

    //Process leaf node children
    mergedNode.addFacetNode(fnode);

    //Process inner node children
    if (noFacetSelected || mergedNode.selected) {
      for (let cid in fnode.children) {
        nextFacets.push(fnode.children[cid]);
      }
    }
  }

  mergeFacets(facetDefs, nextFacets, depth + 1, mergedFacets, modelAdded, ignoreFilter, implicitSelectedFacets);
}

/** Recursively traverses merged facet node to assign `hasVisibleLeaf` property. */
function propagateLeafVisibility(facetDefs, facetNode, depth, mergedFacets, implicitSelectedFacets) {
  // We don't count to not deal with ids in multiple parent facets. Just whether any leaf is visible under.
  const mergedNode = mergedFacets[depth][facetNode.id];

  // If the facet node is not active/visible.
  if (!implicitSelectedFacets[depth] && !mergedNode.selected) return false;

  let anyDescendantLeafVisible;
  for (const cid in facetNode.children) {
    const hasVisible = propagateLeafVisibility(facetDefs, facetNode.children[cid], depth + 1, mergedFacets, implicitSelectedFacets);
    if (hasVisible) mergedNode.hasVisibleLeaf = true;
    anyDescendantLeafVisible = anyDescendantLeafVisible || hasVisible;
  }

  // We are a visible leaf.
  if (anyDescendantLeafVisible == null) return true;

  // Whether any descendant has visible leaf
  return anyDescendantLeafVisible;
}

/**
 * @param {MergedFacetNode[]} mergedLeafFacet
 */
function collectLeafIds(urn, mergedLeafFacet, partialFacets) {

  // traverse the facets (might be hierarchical) to determine whether
  // anything at all is selected.
  const noDescendantsSelected = (node) => {
    if (node.selected) return false;
    if (!node.children?.length) return true;

    return node.children.every((child) => noDescendantsSelected(child));
  };
  const nothingSelected = mergedLeafFacet.every((node) => noDescendantsSelected(node));

  // collect the ids of all elements to show. if nothing was filtered (nothingSelected),
  // the elements of every available leaf facet (i.e. those that passed prior stages/filters
  // outside of this method) are taken, otherwise only those which are explicitly selected.
  function collectIds(ids, mergedFacetNode) {
    if (!mergedFacetNode) {
      return ids;
    }

    if (nothingSelected || mergedFacetNode.selected) {
      let matchingIds = mergedFacetNode.collectMatchingIds(urn, partialFacets);
      for (let item of matchingIds) {
        ids.push(item);
      }
    }

    if (mergedFacetNode.children) {
      for (const child of mergedFacetNode.children) {
        collectIds(ids, child);
      }
    }

    return ids;
  }

  return mergedLeafFacet.reduce(collectIds, []);
}

export class FacetsManager {

  /**
   * @param {Object} options Application configuration options
   */
  constructor(facility) {let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};let wantClassificationFacet = arguments.length > 2 ? arguments[2] : undefined;
    this.facility = facility;
    this.models = [];

    this.options = options;
    //this.options.useTopLevel = true;

    this.facetsEffects = new FacetVizEffects(this.facility, this.options);

    this.facetDefs = getDefaultFacetList(wantClassificationFacet).map((ctor) => new ctor());
    this.facetDefs.forEach((fDef) => fDef.init({}, this.facility, this.options));

    this.partialFacets = {}; // key: facet id, value: object (key: urn, value: set of hidden ids)

    this.mergedFacets = this.facetDefs.map(() => []);

    this.hiddenCategories = []; //or optionally [RC.Rooms, RC.MEPSpaces, RC.Levels], set via the Edit Filters UI

    this.boundOnIsolationChanged = this.onIsolationChanged.bind(this);
    this.boundOnDocumentsChanged = this.onDocumentsChanged.bind(this);
    this.boundOnSystemsChanged = this.onSystemsChanged.bind(this);
    this.boundOnPreferenceChanged = this.onPreferenceChanged.bind(this);

    this.settings = this.facetDefs; //TODO: temporary assignment in order to not break the dt-client which uses this variable
    this.facetsNeedRecalculation = true;

    this.viewRestoreInProgress = false;

    this.setEventTarget(this.facility.eventTarget);
    this.eventTarget.addEventListener(dte.DT_DOCUMENTS_CHANGED_EVENT, this.boundOnDocumentsChanged);
    this.eventTarget.addEventListener(dte.DT_SYSTEMS_CHANGED_EVENT, this.boundOnSystemsChanged);

    // speed up facet creation by kicking off async fetching of revit categories (will be used by FacetRvtCategories)
    DtConstants.getRevitCategories();
    DtConstants.getClassificationsLibrary();
    this.viewId = undefined;

    this.lastFacetRecalcTime = 0;
    this.pendingRecalcModels = new Set();
    this.pendingAddModels = new Set();
  }

  /**
   * Update FacetsManager settings
   * @param {import("./Facets").FacetSettings[]} newSettings list of facet settings.
   */
  async setSettings(newSettings, hiddenCategories, useTopLevel) {
    if (!newSettings) {
      return;
    }

    // Create current facet definitions maps. This will be used to restore state of existing facetdef when updating settings
    const existingFacetDefsById = {};
    for (let i = 0; i < this.facetDefs.length; i++) {
      existingFacetDefsById[this.facetDefs[i].id] = this.facetDefs[i];
    }

    const newFacetDefs = [];

    for (let i = 0; i < newSettings.length; i++) {
      const settings = newSettings[i];

      // reuse existing facet definitions to keep current state or instantiate new one
      let facetDef = existingFacetDefsById[settings.id] || deserializeFacet(settings.id);
      if (!facetDef) {
        continue;
      }

      await facetDef.init(settings, this.facility, this.options);

      newFacetDefs.push(facetDef);
    }

    //Check if facet defs are changing
    //If not, we can bypass facet recalculation.
    //TODO: we can do even better by only recalculating facets that are being added
    let facetsAreChanging = false;
    if (newFacetDefs.length !== this.facetDefs.length) {
      facetsAreChanging = true;
    }
    if (!facetsAreChanging) {
      for (let i = 0; i < this.facetDefs.length; i++) {
        if (this.facetDefs[i] !== newFacetDefs[i]) {
          facetsAreChanging = true;
          break;
        }
      }
    }

    //Check if element flag/category filter is changing
    if (typeof hiddenCategories !== "undefined") {
      if (!smallArraysEqual(this.hiddenCategories, hiddenCategories)) {
        this.hiddenCategories = hiddenCategories;
        facetsAreChanging = true;
      }
    }

    //Note that the options object is held as pointer
    //by the FacetLevels (and all other Facets) so setting the
    //option here and then recalculating the facet will update the facet counts
    if (typeof useTopLevel !== "undefined") {
      if (!!this.options.useTopLevel !== !!useTopLevel) {
        this.options.useTopLevel = useTopLevel;
        facetsAreChanging = true;
      }
    }


    if (this.facetsNeedRecalculation || facetsAreChanging) {
      this.facetsNeedRecalculation = false;

      if (this.models.length) {
        await this.updateFacetsAttributes(newFacetDefs, this.models);
        return this.recalculateModelFacets(newFacetDefs, this.models, true, true);
      } else {
        //No models in the scene, probably we are getting called
        //by view restoring code, before any model is loaded/added yet.
        //Just remember the new settings and let the first call to addModel()
        //do the recalculation.
        this.facetDefs = newFacetDefs;
      }
    }
  }

  setViewer(viewer) {

    if (this.viewer) {
      this.viewer.removeEventListener(et.PREF_CHANGED_EVENT, this.boundOnPreferenceChanged);
    }

    this.viewer = viewer;
    this.facetsEffects.setViewer(viewer);

    this.viewer.addEventListener(et.PREF_CHANGED_EVENT, this.boundOnPreferenceChanged);
  }

  /**
   * Sets event target for Dt events
   * @param {EventDispatcher} eventTarget
   */
  setEventTarget(eventTarget) {
    this.eventTarget = eventTarget;
  }

  highlightRooms() {

    const spacesIdx = this.facetDefs.findIndex((facet) => facet instanceof FacetSpaces);
    if (spacesIdx === -1) {
      return;
    }

    this.facetsEffects.clearRoomsOverlay();

    for (let model of this.models) {

      let roomFacets = this.mergedFacets[spacesIdx];

      for (let i = 0; i < roomFacets.length; i++) {

        let facetProps = roomFacets[i];

        //Each room facet belongs to a certain model (since it's associated with room geometry in that model),
        //so we have to make sure the current room refers to the current model being processed.
        if (facetProps.roomSrcModel !== model) {
          continue;
        }

        // Add room overlays regardless of the model's visibility,
        // but skip selection for room elements of hidden models
        if (facetProps.selected) {
          this.facetsEffects.addElementHighlight(facetProps.roomSrcModel, facetProps.dbId, ROOMS_OVERLAY);
        }
      }
    }
  }

  skipElement(flags, catId) {

    if (this.hiddenCategories.includes(catId)) {
      return true;
    }

    if ((flags & KeyFlags.Logical) === 0) {
      return false;
    }

    //Don't skip elements whose flags are "unknown" -- sometimes the NWD importer will classify
    //elements that have geometry as "unknown" and we don't want to ignore those for filtering purposes
    if (flags === ElementFlags.Unknown) {
      return false;
    }

    //if Logical element, include streams, levels, and generic assets since we want to show their properties.
    return flags !== ElementFlags.Stream && flags !== ElementFlags.Level && flags !== ElementFlags.GenericAsset;
  }

  /**
   * Build facet tree and define facetsClassifiers for a given DtModel
   * @param facetDefs {FacetDef[]}
   * @param model {DtModel}
   */
  async buildFacetTreeForModel(facetDefs, model) {

    //Model could have been unloaded meanwhile
    if (!model.getData()) {
      return;
    }

    const urn = model.urn();

    // define all attributeFacets classifiers in a batch query
    const attributeHashes = facetDefs.reduce((hashes, facetDef) => {
      if (facetDef instanceof FacetAttribute) {
        hashes.push(facetDef.settings.attributeHash);
      }
      return hashes;
    }, []);
    const hash2CustomFacetPromise = model.getCustomFacets(attributeHashes);

    // define/cache or reuse facets classifiers
    await Promise.all(facetDefs.map(async (facetDef) => {
      if (!model.facetsClassifiers) {
        model.facetsClassifiers = {};
      }

      if (!model.facetsClassifiers[facetDef.id]) {
        let classifier = null;
        if (facetDef instanceof FacetAttribute) {
          classifier = await facetDef.getClassifier(model, hash2CustomFacetPromise);
        } else if (facetDef instanceof FacetSpaces || facetDef instanceof FacetSystems) {
          classifier = await facetDef.getClassifier(model, this.models);
        } else {
          classifier = await facetDef.getClassifier(model);
        }

        //Model could have been unloaded meanwhile
        if (model.facetsClassifiers) {
          model.facetsClassifiers[facetDef.id] = classifier;
        }
      }
    }));

    //Model could have been unloaded meanwhile
    if (!model.getData()) {
      return;
    }

    const modelFacets = new PerModelFacet({
      id: urn,
      label: model.displayName()
    }, urn);

    //let t0 = Date.now();

    const dbId2flags = model.getData().dbId2flags;
    const dbId2catId = model.getData().dbId2catId;
    const vm = model.visibilityManager;
    const it = model.getInstanceTree();
    const na = it.nodeAccess;
    const useLastObject = this.viewer.getSelectionMode() === SelectionMode.LAST_OBJECT;

    // looping over all elements once collecting
    // elements into hierarchical groups (facet tree)
    for (let dbId = 1; dbId < dbId2flags.length; ++dbId) {
      const flags = dbId2flags[dbId];
      const catId = dbId2catId[dbId];
      let dbIdForFacetCheck = dbId;
      if (useLastObject) {
        dbIdForFacetCheck = it.findNodeForSelection(dbId, SelectionMode.LAST_OBJECT);
      }

      const hiddenFromFilters = this.skipElement(flags, catId);
      const isLeafElement = na.getNumFragments(dbId) > 0;

      if (vm && isLeafElement) {
        vm.lockNodeVisible(dbId, false, false);
        vm.setNodeOff(dbId, hiddenFromFilters);
        if (hiddenFromFilters) {
          vm.lockNodeVisible(dbId, true, false);
        }
      }

      if (hiddenFromFilters) {
        continue;
      }

      // The loops below are handling the case where an element can be assigned to multiple facets for a certain facet definition.
      // There are two such cases currently - Rooms/Spaces and MEP systems where one element can belong to multiple rooms/spaces and systems
      let nextFacets = [modelFacets];
      for (let i = 1; i < facetDefs.length; i++) {
        const id2FacetId = model.facetsClassifiers[facetDefs[i].id];

        //If the leaf element doesn't have a specific facet classifier,
        //check whether its parent does (if current selection mode allows that)
        let facetForElement = id2FacetId(dbId);
        if (facetForElement.isUnassignedId && dbIdForFacetCheck !== dbId) {
          facetForElement = id2FacetId(dbIdForFacetCheck);
        }

        let nxt = [];
        for (let j = 0; j < nextFacets.length; j++) {
          if (Array.isArray(facetForElement)) {
            for (let k = 0; k < facetForElement.length; k++) {
              let nextFacet = nextFacets[j].findOrAddChild(dbId, facetForElement[k]);
              nextFacet.markAsAddedToMultipleFacets(dbId);
              nxt.push(nextFacet);
            }
          } else {
            nxt.push(nextFacets[j].findOrAddChild(dbId, facetForElement));
          }
        }

        nextFacets = nxt;
      }

      for (let i = 0; i < nextFacets.length; i++) {
        nextFacets[i].addLeaf(dbId, urn);
      }
    }

    //let t1 = Date.now();
    //console.log("facet tree", t1 - t0);

    return modelFacets;
  }

  async addModel(model, visibleInFilters) {
    await model.waitForLoad(visibleInFilters, true);

    this.models.push(model);
    if (visibleInFilters) {
      this.facetDefs[MODEL_FACET_DEFS_IDX].filter.add(model.urn());
    }

    await this.updateFacetsAttributes(this.facetDefs, [model]);

    if (this.inProgressRecalc) {
      await this.inProgressRecalc;
      this.inProgressRecalc = null;
    }

    let toUpdate = new Set([model]);
    //Also recalculate facets for models that have external references to the newly added model
    //Currently this matters for the rooms facet and systems facets
    for (let m of this.models) {
      if (!m.facetsClassifiers || m === model) {
        continue;
      }

      if (m.hasExternalRefs(model.urn())) {
        toUpdate.add(m);
        //Invalidate the classifier function for spaces, since it depends on the
        //list of models -- so adding a model makes it invalid.
        m.facetsClassifiers[FacetTypes.spaces] = null;
      }
      if (model.isDefault() && m.facetsClassifiers[FacetTypes.mepSystems]) {
        toUpdate.add(m);
        //Invalidate the classifier function for mep systems, since it depends on the
        //default model -- so adding this one makes it invalid.
        m.facetsClassifiers[FacetTypes.mepSystems] = null;
      }
    }

    if (this.facility.modelsToAbort.has(model)) {
      return;
    }

    let isLast = this.models.length === this.facility.getModels().length;
    await this.queueFacetRecalc(toUpdate, model, isLast);

    if (this.models.length === 1) {
      this.viewer.addEventListener(et.AGGREGATE_ISOLATION_CHANGED_EVENT, this.boundOnIsolationChanged);
      this.viewer.addEventListener(et.AGGREGATE_HIDDEN_CHANGED_EVENT, this.boundOnIsolationChanged);
    }

    if (isLast) {
      this.isLoaded = true;
      this.eventTarget.dispatchEvent({
        model,
        type: dte.DT_FACETS_LOADED
      });
    }
  }

  /**
   * Queue model facet recalculation, which happens lazily (if forced, 20% of all models are pending or
   * 5 seconds have passed since the last recalculation)
   * @param {Iterable.<DtModel>} toUpdate - models that require recalculation
   * @param {DtModel} addedModel - model that was added triggering this recalculation
   * @param {boolean} force
   */
  async queueFacetRecalc(toUpdate, addedModel, force) {

    this.pendingAddModels.add(addedModel);

    for (let model of toUpdate) {
      this.pendingRecalcModels.add(model);
    }

    let now = Date.now();
    let timeDelta = now - this.lastFacetRecalcTime;
    let tooMany = this.pendingAddModels.size / this.facility.getModels().length > 0.2;

    if (force || tooMany || timeDelta > 5000) {

      this.lastFacetRecalcTime = now;

      let toUpdate = Array.from(this.pendingRecalcModels);
      this.pendingRecalcModels.clear();

      let toAdd = Array.from(this.pendingAddModels);
      this.pendingAddModels.clear();

      //console.log("recalc facets", toUpdate.length, force, timeDelta);
      this.inProgressRecalc = this.recalculateModelFacets(this.facetDefs, toUpdate, this.viewRestoreInProgress, this.viewRestoreInProgress);

      await this.inProgressRecalc;
      this.inProgressRecalc = null;

      for (let model of toAdd) {

        //Model could have been unloaded meanwhile
        if (!model.getData()) {
          continue;
        }

        //Load any room geometries for this newly added model.
        //We want this to happen after recalculateModelFacets(), which can itself trigger loading
        //of element geometries, that are more important than rooms (since rooms come into play only later
        //if the user interacts with the rooms filter).
        model.loadRoomFragments();
      }
    }
  }

  removeModel(model, skipUpdate) {
    const idx = this.models.indexOf(model);
    if (idx !== -1) {
      model.modelFacets = null;
      model.facetsClassifiers = null;
      this.facetsEffects.invalidateFloorHighlights();
      this.models.splice(idx, 1);

      if (!skipUpdate) {
        this.updateFacets();
      }
    }

    if (this.models.length === 0) {
      this.viewer.removeEventListener(et.AGGREGATE_ISOLATION_CHANGED_EVENT, this.boundOnIsolationChanged);
      this.viewer.removeEventListener(et.AGGREGATE_HIDDEN_CHANGED_EVENT, this.boundOnIsolationChanged);
      this.facetsNeedRecalculation = true;
    }

    if (this.models.length < this.facility.getModels(true).length) {
      this.isLoaded = false;
    }
  }

  /**
   * @param nFacetDefs {FacetDef[]|null}
   * @param models {DtModel[]}
   */
  async recalculateModelFacets(nFacetDefs, models, skipFacetsUpdate, skipIsolationUpdate) {

    let foundModels;

    if (models === this.models) {
      foundModels = models;
    } else {
      foundModels = models.filter((m) => this.models.includes(m));
      if (foundModels.length === 0) {
        return;
      }
    }

    nFacetDefs = nFacetDefs || this.facetDefs;

    // prune attribute facets that point to an attribute that was hidden (e.g. due to a template update)
    nFacetDefs = nFacetDefs.filter((facetDef) => !facetDef.attribute || !facetDef.attribute.isHidden());

    //Recalculate per-model facet trees with the newly added facet definition
    let nModelFacetsProm = foundModels.map((model) => this.buildFacetTreeForModel(nFacetDefs, model));
    let nModelFacets = await Promise.all(nModelFacetsProm);

    //Now after the async model facets calculations are done, update the facets manager state to the new list of facets

    this.facetDefs = nFacetDefs;

    foundModels.forEach((model, i) => {
      model.modelFacets = nModelFacets[i];
    });

    if (!skipFacetsUpdate) {
      this.updateFacets(foundModels.length === 1 ? foundModels[0].urn() : undefined, skipIsolationUpdate, false);
    }

    // redo theming
    let idxCats = -1;
    let idxSystemClasses = -1;
    let idxMepSystems = -1;

    this.facetDefs.forEach((facetDef, idx) => {
      if (facetDef.id === FacetTypes.categories) {
        idxCats = idx;
      } else if (facetDef.id === FacetTypes.systemClasses) {
        idxSystemClasses = idx;
      } else if (facetDef.id === FacetTypes.mepSystems) {
        idxMepSystems = idx;
      }

      if (facetDef.theme && Object.keys(facetDef.theme).length > 0) {
        foundModels.forEach((model) => {
          this._applyThemeToModel(model, idx, facetDef.theme);
        });
      }
    });

    if (idxMepSystems >= 0) {
      this.facetsEffects.setupHighlightBySystem();
    } else if (idxSystemClasses >= 0) {
      this.facetsEffects.setupHighlightBySystemClass();
    } else if (idxCats >= 0) {
      this.facetsEffects.setupHighlightByCategory();
    }
  }

  /**
   *
   * @param {import("./Facets").FacetSettings} settings
   * @param {number} insertIndex
   * @returns
   */
  async addFacetDef(settings, insertIndex) {
    const nFacetDefs = this.facetDefs.slice();

    const facetDef = deserializeFacet(settings.id);
    if (!facetDef) {
      return nFacetDefs;
    }

    await facetDef.init(settings, this.facility, this.options);

    await this.updateFacetsAttributes([facetDef], this.models);

    if (typeof insertIndex !== "number" || insertIndex < 0 || insertIndex > this.facetDefs.length) {
      const sysIndex = nFacetDefs.findIndex((v) => {
        return v.id === FacetTypes.assemblyCode;
      });
      if (sysIndex !== -1) {
        insertIndex = sysIndex + 1;
      } else {
        insertIndex = nFacetDefs.length;
      }
    }

    nFacetDefs.splice(insertIndex, 0, facetDef);

    this.recalculateModelFacets(nFacetDefs, this.models);

    return nFacetDefs;
  }

  removeFacetDef(idx) {

    let nFacetsDefs = this.facetDefs.slice();

    const removedFacetDef = nFacetsDefs[idx];

    nFacetsDefs.splice(idx, 1);

    // cleanup
    if (removedFacetDef.theme && Object.keys(removedFacetDef.theme).length > 0) {
      this.clearTheme(idx);
    }

    this.recalculateModelFacets(nFacetsDefs, this.models);

    return nFacetsDefs;
  }

  findFacetDef(id) {
    return this.facetDefs.find((facetDef) => facetDef.id === id);
  }

  setFilterMap(filterMap) {
    this.partialFacets = {};
    this.facetDefs.forEach((facetDef) => facetDef.filter.clear());
    for (let f in filterMap) {
      const facetIdx = this.facetDefs.findIndex((facet) => facet.id === f);
      if (facetIdx === -1) {
        console.warn('Skipping unknown filter key', f);
        continue;
      }
      this.facetDefs[facetIdx].filter = new Set(filterMap[f]);
    }

    this.updateFacets();
  }

  getFilterMap() {
    return this.facetDefs.reduce((a, c) => {
      var new_set;
      new_set = new Set([]);
      for (const f of c.filter) {
        if (f === undefined) {
          new_set.add(UNDEFINED);
        } else {
          new_set.add(f);
        }
      }
      a[c.id] = new_set;
      return a;
    }, {});
  }

  /** Removes any filter applied for the facet passed in by ID and updates facets. */
  clearFilter(id) {
    this.partialFacets = {};
    for (const facet of this.facetDefs) {
      if (facet.id === id) {
        facet.filter.clear();
        break;
      }
    }

    this.updateFacets();
  }

  setVisibility(facetIdx, valueId, visible) {let skipUpdate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

    const f = this.facetDefs[facetIdx].filter;
    if (f.has(valueId) !== visible || this.partialFacets[valueId]) {
      if (visible) {
        f.add(valueId);
      } else {
        f.delete(valueId);
      }

      delete this.partialFacets[valueId];

      if (!skipUpdate) {
        const skipIsolationUpdate = false;
        const urn = facetIdx === MODEL_FACET_DEFS_IDX ? valueId : null;
        this.updateFacets(urn, skipIsolationUpdate, visible);
      }
    }
  }

  /**
   * Adjusts a model control's visibility.
   * @param urn Model urn
   * @param visible Visibility
   * @param skipUpdate Skips the facet updates.
   */
  setModelVisibility(urn, visible) {let skipUpdate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    this.setVisibility(MODEL_FACET_DEFS_IDX, urn, visible, skipUpdate);
  }

  isVisible(facetIdx, valueId) {
    const f = this.facetDefs[facetIdx].filter;
    return f.size == 0 || f.has(valueId);
  }

  resetVisibility() {
    this.partialFacets = {};
    this.facetDefs.forEach((facetDef) => facetDef.filter.clear());
    this.facetDefs[MODEL_FACET_DEFS_IDX].filter = new Set(this.facility.settings.links.filter((m) => m.on).map((m) => m.modelId));
    this.updateFacets();
    this.eventTarget.dispatchEvent({
      type: dte.DT_FACETS_RESET
    });
  }

  /**
   * Determine whether a model-only filter is applied
   * Eg. when every other facet is either all selected or all deselected
  */
  _modelOnlyFilter() {
    const facets = this.getFacets().slice(1);

    const allDescendantsSelected = (node) => {
      if (!node.selected) return false;
      if (!node.children?.length) return true;

      return node.children.every((child) => allDescendantsSelected(child));
    };

    return this.facetDefs.slice(1).every((facetDef, facetIdx) => {
      if (facetDef.filter.size === 0) return true;

      return facets[facetIdx].every(allDescendantsSelected);
    });
  }

  _hiddenIds(urn) {
    let result = [];
    for (let p in this.partialFacets) {
      const ids = this.partialFacets[p][urn];
      if (ids) {
        result.push(...ids);
      }
    }
    return result;
  }

  updateIsolation(urnHint) {
    // the primary model will never be unloaded but might need ot be loaded initially
    // for all other models, missing elements are on-demand loaded
    // and stay around until the entire model gets unchecked, which unloads
    // all its elements.

    const modelOnly = this._modelOnlyFilter();
    const modelFilter = this.facetDefs[MODEL_FACET_DEFS_IDX].filter;

    //let t0 = Date.now();
    const selections = [];

    for (let m of this.models) {
      const urn = m.urn();
      // because facet filters interact with each other
      // this optimization only works if there are no filters
      // below the model level defined
      if (modelOnly && urnHint && urn != urnHint) {
        continue;
      }

      if (!modelFilter.has(urn)) {
        if (this.facility.isModelVisible(m)) {
          m.selector.clearSelection();
          m.visibilityManager.isolateNone();
          this.viewer.hideModel(m);

          const spacesIdx = this.facetDefs.findIndex((facet) => facet instanceof FacetSpaces);
          const mergedSpacesFacets = spacesIdx === -1 ? [] : this.mergedFacets[spacesIdx];
          const hasSpacesInFacets = m.hasRooms() && mergedSpacesFacets.some((s) => s.roomSrcModel === m);

          // Check if current model has selectable spaces in the filters
          if (!m.isPrimaryModel() && !hasSpacesInFacets) {
            // Keep model elements for highlights/hovering overlays... hoverlays
            m.unloadAllElements();
          }
        }
        continue;
      }

      if (!this.viewer.impl.modelVisible(m.id)) {
        this.viewer.showModel(m);
      }

      if (modelOnly) {
        m.loadAllElements();
        m.visibilityManager.isolateNone();
        const hidden = this._hiddenIds(urn);
        if (hidden.length) {
          m.visibilityManager.hide(hidden, false);
        }

      } else {
        const sel = new Set(m.selector.getSelection());
        m.selector.clearSelection();
        const leafFacet = this.mergedFacets[this.mergedFacets.length - 1];
        const leafFacetDef = this.facetDefs[this.facetDefs.length - 1];
        const ids = collectLeafIds(urn, leafFacet, this.partialFacets);
        if (ids.length) {
          if (m.isPrimaryModel() && this.viewer.prefs.get(Prefs3D.GHOSTING)) {
            m.loadAllElements();
          } else {
            m.loadElements(ids);
          }
          m.visibilityManager.isolate(ids, true);
        } else {
          m.visibilityManager.setAllVisibility(false);
        }

        const selection = ids.filter((id) => sel.has(id));
        selections.push({ model: m, selection });
      }
    }

    if (selections.length) {
      this.viewer.impl.selector.setAggregateSelection(selections);
    }

    //let t1 = Date.now();
    //console.log("facet update", t1 - t0);

    this.highlightRooms();

    this.viewer.dispatchEvent({
      type: et.AGGREGATE_ISOLATION_CHANGED_EVENT,
      isolation: this.viewer.getAggregateIsolation(),
      source: this
    });
  }

  /**
   * Are elements of the Rooms or MEPSpaces categories visible.
   * @param {boolean} isOnlyVisibleCategory Are Rooms and MEPSpaces the only filter on categories.
   */
  areRoomsVisible() {let isOnlyVisibleCategory = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    for (let i = 0; i < this.facetDefs.length; i++) {
      let facetDef = this.facetDefs[i];
      if (facetDef.id === FacetTypes.categories) {
        let filter = facetDef.filter;
        if (isOnlyVisibleCategory) {
          if (filter.size === 1) {
            if (filter.has(RC.Rooms) || filter.has(RC.MEPSpaces)) {
              return true;
            }
          } else if (filter.size === 2) {
            if (filter.has(RC.Rooms) && filter.has(RC.MEPSpaces)) {
              return true;
            }
          }
        } else {
          return filter.size === 0 || filter.has(RC.Rooms) || filter.has(RC.MEPSpaces);
        }
      }
    }

    return false;
  }

  //Detects if the current filter is filtering for a specific Level
  getLevelFilter() {
    for (let i = 0; i < this.facetDefs.length; i++) {
      let facetDef = this.facetDefs[i];
      if (facetDef.id === FacetTypes.levels) {
        let filter = facetDef.filter;
        //For the moment we only know how to handle
        //single level filters
        if (filter.size === 1) {
          let filterName = Array.from(filter)[0];
          let filteredFacet = this.mergedFacets[i].find((f) => f.id === filterName);
          return { facetDef, mergedFacet: filteredFacet };
        }
      }
    }

    return {};
  }

  collectUnfilteredMergedFacets() {
    let mergedFacets = this.facetDefs.map(() => ({}));

    const facetNodes = this.models.map((m) => {
      return m.modelFacets;
    }).filter((i) => i);

    mergeFacets(this.facetDefs, facetNodes, 0, mergedFacets, null, true);
    for (let fIdx = 0; fIdx < this.facetDefs.length; ++fIdx) {
      mergedFacets[fIdx] = this.facetDefs[fIdx].formatMergedFacetNodes(Object.values(mergedFacets[fIdx]), this.partialFacets);
    }
    return mergedFacets;
  }

  updateFacets(urn, skipIsolationUpdate, visible) {

    let mergedFacets = this.facetDefs.map(() => ({}));

    const facetNodes = this.models.map((m) => {
      return m.modelFacets;
    }).filter((i) => i);

    //let t0 = Date.now();

    const addedModel = visible ? urn : undefined;
    const implicitSelectedFacets = [];
    mergeFacets(this.facetDefs, facetNodes, 0, mergedFacets, addedModel, false, implicitSelectedFacets);
    // Bubble up knowledge about which facet has a visible leaf under it.
    facetNodes.forEach((facetNode) => propagateLeafVisibility(this.facetDefs, facetNode, 0, mergedFacets, implicitSelectedFacets));

    for (let fIdx = 0; fIdx < this.facetDefs.length; ++fIdx) {
      mergedFacets[fIdx] = this.facetDefs[fIdx].formatMergedFacetNodes(Object.values(mergedFacets[fIdx]), this.partialFacets);
    }

    //let t1 = Date.now();
    //console.log("merge facets", t1 - t0);

    this.mergedFacets = mergedFacets;

    if (this.viewer) {
      if (!skipIsolationUpdate) {
        this.updateIsolation(urn);
      } else {
        this.highlightRooms();
      }

      this.facetsEffects.makeRoomsOpaque(this.areRoomsVisible(true));

      if (this.options.useTopLevel) {
        let { facetDef, mergedFacet } = this.getLevelFilter();
        this.facetsEffects.sectionVerticalElements(facetDef, mergedFacet);
      }

      this.eventTarget.dispatchEvent({
        type: dte.DT_FACETS_UPDATED
      });
    }

    return this.mergedFacets;
  }

  /**
   * Map attributes from their hashes
   */
  async updateFacetsAttributes(facetDefs, models) {
    let hash2Attrs; // cross-model attributes map
    for (let i = 0; i < facetDefs.length; i++) {
      const facetDef = facetDefs[i];
      if (facetDef.settings.attributeHash) {
        //Collect attributes from all models
        if (!hash2Attrs) {
          hash2Attrs = {};
          const modelsHash2Attrs = await Promise.all(models.map((m) => m.getHash2Attr()));

          for (let i = 0; i < modelsHash2Attrs.length; i++) {
            for (let hash in modelsHash2Attrs[i]) {
              let attr = modelsHash2Attrs[i][hash];
              hash2Attrs[hash] = attr;
            }
          }
        }

        let attribute = hash2Attrs[facetDef.settings.attributeHash];
        if (attribute) {
          facetDef.setAttribute(attribute);
        } else {
          //Attempt to match using old (incorrect) attribute hash that is of the form [psetUuid][paramUuid][dataType]
          //instead of [paramUuid][dataType]. This should only happen when trying to load old saved views with custom facets
          let firstBrace = facetDef.settings.attributeHash.indexOf("]");
          if (firstBrace > 0) {
            attribute = hash2Attrs[facetDef.settings.attributeHash.slice(firstBrace + 1)];
            if (attribute) {
              console.log("Matched parameter using old hash function", facetDef.settings.attributeHash);
              facetDef.setAttribute(attribute);
            }
          }

        }
      }
    }
  }

  getFacets() {
    return this.mergedFacets;
  }

  _setFacetDefs(newFacetDefs) {
    this.facetDefs = newFacetDefs;
  }

  getFacetDefs() {
    return this.facetDefs;
  }

  onIsolationChanged(event) {
    // skipping events we fired ourselves
    if (event.source === this) {
      return;
    }

    // note: we don't cope with arbitrarily changing isolation state here
    // but merely with what's possible through the UI, i.e. remove any filter ("show all")
    // or further restrict the current filter (isolate, hide)
    if (event.type === et.AGGREGATE_ISOLATION_CHANGED_EVENT) {
      const isolation = event.isolation;
      if (isolation.length === 0) {
        this.facetDefs.slice(1).forEach((facetDef) => facetDef.filter.clear());
        this.partialFacets = {};
        for (let m of this.models) {
          if (this.facility.isModelVisible(m)) {
            m.loadAllElements();
          }
        }
      } else {
        const leafFacet = this.mergedFacets[this.mergedFacets.length - 1];
        const leafFilter = this.facetDefs[this.facetDefs.length - 1].filter;
        const leafFacetId = this.facetDefs[this.facetDefs.length - 1].id;
        const modelFilter = this.facetDefs[MODEL_FACET_DEFS_IDX].filter;


        leafFilter.clear();
        isolation.forEach((_ref) => {let { model, ids } = _ref;
          const facetConfig = model.facetsClassifiers;
          const id2facetId = facetConfig[leafFacetId];
          if (id2facetId) {
            ids.forEach((id) => {
              const facet = id2facetId(id).id;
              leafFilter.add(facet);
            });
          }
        });

        const urn2idSet = isolation.reduce((a, c) => {
          a[c.model.urn()] = new Set(c.ids);
          return a;
        }, {});

        // check if we have partially selected facets
        for (let f of leafFacet) {
          if (leafFilter.has(f.id)) {
            if (f.ids === undefined) {
              const urn = isolation[0].model.urn();
              if (modelFilter.has(urn)) {
                const partial = this.partialFacets[f.id] || (this.partialFacets[f.id] = {});
                const hidden = partial[urn] || (partial[urn] = new Set());

                const mfNode = leafFacet.find((facet) => facet.id === f.id);
                const idsToHide = mfNode?.idsSets[urn];
                const isolatedIds = urn2idSet[urn];

                if (idsToHide && isolatedIds) {
                  idsToHide.forEach((id) => {
                    if (!isolatedIds.has(id)) {
                      hidden.add(id);
                    }
                  });
                }
              }
            } else {
              for (let urn in f.ids) {
                if (modelFilter.has(urn)) {
                  const partial = this.partialFacets[f.id] || (this.partialFacets[f.id] = {});
                  const hidden = partial[urn] || (partial[urn] = new Set());
                  const fids = f.ids[urn];
                  const isolatedIds = urn2idSet[urn];
                  if (isolatedIds) {
                    fids.forEach((id) => {
                      if (!isolatedIds.has(id)) {
                        hidden.add(id);
                      }
                    });
                  } else {
                    fids.forEach((id) => hidden.add(id));
                  }
                }
              }
            }
          }
        }

        // if all leafs are selected, clear the filter
        if (leafFacet.every((f) => leafFilter.has(f.id))) {
          leafFilter.clear();
        }
      }
    } else if (event.type === et.AGGREGATE_HIDDEN_CHANGED_EVENT) {
      this.addElementsToPartiallySelectedFacets(event.hidden);
    }

    this.updateFacets(null, true);
  }

  addElementsToPartiallySelectedFacets(hidden) {
    const leafFacetId = this.facetDefs[this.facetDefs.length - 1].id;

    hidden.forEach((_ref2) => {let { model, ids } = _ref2;
      if (!model.facetsClassifiers) {
        // Facets are not yet available, this will be called again on DtViewerState<onAllFacetsLoaded>
        return;
      }

      const urn = model.urn();
      const id2facetId = model.facetsClassifiers[leafFacetId];
      for (let id of ids) {
        const fid = id2facetId(id).id;
        const partialFacet = this.partialFacets[fid] || (this.partialFacets[fid] = {});
        const hiddenIds = partialFacet[urn] || (partialFacet[urn] = new Set());
        hiddenIds.add(id);
      }
    });
  }

  setHiddenElementsForView(hidden) {
    this.addElementsToPartiallySelectedFacets([hidden]);
    this.updateFacets(hidden.model.urn());
  }

  // viewer.getAggregateHiddenNodes() isn't usable for the following case:
  //   1) there is a defined isolation
  //   2) user hides some elements via "hide selected" additionally
  //
  // viewer.getAggregateHiddenNodes() returns that the whole model is hidden with enabled isolation
  getHiddenElementsByModel() {
    let hiddenByModel = {};

    const pfs = Object.keys(this.partialFacets);
    pfs.forEach((pf) => {
      const partial = this.partialFacets[pf];

      const models = Object.keys(partial);
      models.forEach((m) => {
        if (hiddenByModel[m]) {
          hiddenByModel[m] = [...Array.from(hiddenByModel[m]), ...Array.from(partial[m])];
        } else {
          hiddenByModel[m] = Array.from(partial[m]);
        }
      });
    });

    return hiddenByModel;
  }

  onDocumentsChanged(event) {
    if (event?.deletedDocumentId?.length) {
      // When adding a custom facet on documents references, we need to make sure that document deletion updates facet state as well.
      // For now we do it locally within a browser session. In general, it might be useful to send events from the server to update all interested clients, but it looks like too much of an effort for now, given that this is an exotic edge case.
      // only do it if deleted document would affect the actual facets
      for (const facet of this.mergedFacets) {
        const affectedDocument = facet.find((node) => node.id === event.deletedDocumentId);
        if (affectedDocument) {
          this.recalculateModelFacets(this.facetDefs.slice(), this.models);
          break;
        }
      }
    }
  }

  onSystemsChanged(event) {
    if (event.type === dte.DT_SYSTEMS_CHANGED_EVENT && event.change.ctype === DtConstants.ChangeTypes.DeleteSystems ||
    event.type === dte.DT_SYSTEMS_CHANGED_EVENT && event.change.ctype === DtConstants.ChangeTypes.UpdateSystems) {
      for (let m of this.models) {
        m.facetsClassifiers[FacetTypes.mepSystems] = null;
      }

      this.recalculateModelFacets(this.facetDefs.slice(), this.models);
    }
  }

  onPreferenceChanged(event) {

    if (event.name === Prefs3D.SELECTION_MODE) {
      this.recalculateModelFacets(null, this.models);
    }
  }

  async addFacetDefType(facetType, index) {
    if (this.facetDefs.some((fDef) => fDef.id === facetType)) {
      return;
    }
    const newFacetDefs = await this.addFacetDef(new FacetRegistry[facetType]().getSettings(), index);
    this._setFacetDefs(newFacetDefs);
  }

  async addFacetDefsForProgressDashboard() {
    await this.addFacetDefType(FacetTypes.classifications);
    await this.addFacetDefType(FacetTypes.status);
  }

}