
var externalExtensions = [

// First is the path to extension's entry point,
// Second (and all others) are the extension IDs.
{
  src: './extensions/ZoomWindow/ZoomWindow.js',
  ids: ['Autodesk.Viewing.ZoomWindow']
},
{
  src: './extensions/Debug/Debug.js',
  ids: ['Autodesk.Debug']
},
{
  src: './extensions/BimWalk/BimWalk.js',
  ids: ['Autodesk.BimWalk']
},
{
  src: './extensions/Section/Section.js',
  ids: ['Autodesk.Section']
},
{
  src: './extensions/CompGeom/index.js',
  ids: ['Autodesk.CompGeom']
},
{
  src: './extensions/Snapping/index.js',
  ids: ['Autodesk.Snapping']
},
{
  src: './extensions/FirstPerson/FirstPerson.js',
  ids: ['Autodesk.FirstPerson']
},
{
  src: './extensions/Edit2D/Edit2D.js',
  ids: ['Autodesk.Edit2D']
},
{
  src: './extensions/Edit2D/SegmentTreeWorker.js',
  key: 'SegmentTreeWorker',
  ids: ['Autodesk.SegmentTreeWorker']
},
{
  src: './extensions/Edit3D/Edit3D.js',
  ids: ['Autodesk.Edit3D']
},
{
  src: './extensions/VisualClusters/VisualClusters.js',
  ids: ['Autodesk.VisualClusters']
},
{
  src: './extensions/Measure/Measure.js',
  ids: ['Autodesk.Measure']
},
{
  src: './extensions/Geolocation/index.js',
  ids: ['Autodesk.Geolocation']
},
{
  src: './extensions/ViewCubeUi/ViewCubeUi.js',
  ids: ['Autodesk.ViewCubeUi']
},
{
  src: './extensions/BoxSelection/BoxSelectionExtension.js',
  ids: ['Autodesk.BoxSelection']
},
/*
{
    src: './extensions/ModelAlignment/index.js',
    ids: ['Autodesk.ModelAlignment', 'Autodesk.SheetAlignment'],
    dependencies: ['Autodesk.Edit3D']
},
*/
{
  src: './extensions/ModelAlignmentService/ModelAlignmentService.js',
  ids: ['Autodesk.ModelAlignmentService']
},
{
  src: './extensions/UIComponents/index.js',
  ids: ['Autodesk.UIComponents']
},
{
  src: './extensions/ModelEditor/index.js',
  ids: ['Autodesk.ModelEditor']
}];


function getExtensionEntryKey(ee) {
  if (ee.key) {
    return ee.key;
  } else {
    // Given ee.src == './extensions/Something/file.js'
    // then key == 'Something'
    let key = ee.src.split('/')[2];
    return key;
  }
}

module.exports = {
  externalExtensions,
  getExtensionEntryKey
};