import { DepthFormat } from "../CommonRenderTargets";
import { getBufferLayout, getPipelineHash } from "../Pipelines";

import { getShader } from "./GroundShadowShaders";

export class GroundShadowDepthPipeline {

  #renderer;
  #device;

  // Defines at which location the different bind groups are to be bound.
  // Everything that relies on this order is set up automatically, including the shader code.
  #bindGroupOrder = {
    ibl: 0,
    camera: 1,
    object: 2
  };
  #bindGroupLayouts = [];
  #targets = [];

  #pipelines = new Map();
  #activePipeline;
  #vb;

  // This is used as a placeholder in the pipeline helper functions.
  // They calculate a material key for the pipeline hash, but we don't need this,
  // because the entire scene is rendered with a single material.
  #dummyMaterial = {};

  constructor(renderer, iblUniformLayout, cameraUniformLayout, objectUniformLayout, targetFormat) {
    this.#renderer = renderer;
    this.#device = renderer.getDevice();

    for (let key in this.#bindGroupOrder) {
      switch (key) {
        case 'ibl':
          this.#bindGroupLayouts[this.#bindGroupOrder[key]] = iblUniformLayout;
          break;
        case 'camera':
          this.#bindGroupLayouts[this.#bindGroupOrder[key]] = cameraUniformLayout;
          break;
        case 'object':
          this.#bindGroupLayouts[this.#bindGroupOrder[key]] = objectUniformLayout;
          break;
      }
    }

    this.#targets.push({
      format: targetFormat
    });
  }

  getBindGroupOrder() {
    return this.#bindGroupOrder;
  }

  getPipeline(geometry) {
    const key = getPipelineHash(geometry, this.#dummyMaterial, false, false, false, false);

    let pipeline = this.#pipelines.get(key);

    if (pipeline) {
      return pipeline;
    }

    const shader = this.#device.createShaderModule({
      label: 'ground shadow shader',
      code: getShader(this.#bindGroupOrder.ibl, this.#bindGroupOrder.camera, this.#bindGroupOrder.object)
    });

    const pipelineConfig = {
      label: 'ground shadow pipeline',
      layout: this.#device.createPipelineLayout({
        bindGroupLayouts: this.#bindGroupLayouts
      }),
      vertex: {
        module: shader,
        entryPoint: 'vsmain',
        buffers: getBufferLayout(geometry, false, false, false)
      },
      fragment: {
        module: shader,
        entryPoint: 'psmain',
        targets: this.#targets
      },
      primitive: {
        cullMode: 'none'
      },
      depthStencil: {
        depthWriteEnabled: true,
        depthCompare: 'less',
        format: DepthFormat,
        depthBias: 1,
        depthBiasSlopeScale: 1
      }
    };

    if (geometry.isLines) {
      pipelineConfig.primitive.topology = 'line-list';
    } else {
      pipelineConfig.primitive.topology = 'triangle-list';
    }

    pipeline = this.#device.createRenderPipeline(pipelineConfig);

    this.#pipelines.set(key, pipeline);

    return pipeline;
  }

  reset() {
    this.#activePipeline = null;
    this.#vb = this.#renderer.getVB();
  }

  drawOne(passEncoder, objectIndex, geometry) {
    const pipeline = this.getPipeline(geometry);

    if (pipeline !== this.#activePipeline) {
      passEncoder.setPipeline(pipeline);
      this.#activePipeline = pipeline;
    }

    this.#vb.draw(passEncoder, geometry, objectIndex);
  }
}