import debounce from "./timed-debounce";
import THREE from "three";

import * as dte from "../DtEventTypes";
import * as et from "../../application/EventTypes";
import { Label3D } from "../../gui/Label3D";
import { PointMarker } from "../../gui/PointMarker";
import { hcluster } from "../../../thirdparty/clusterfck";
import { get3DSelectionBounds } from "../../tools/FitToViewUtil";
import { DtConstants } from "../schema/DtConstants";
import { StreamUtils } from "./StreamUtils";

const flattenCluster = function (c, cb) {let values = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  if (c.value) {
    values.push(c.value);
    cb(c.value);
  } else {
    flattenCluster(c.left, cb, values);
    flattenCluster(c.right, cb, values);
  }

  return values;
};

const _tmpV3 = new THREE.Vector3();
const _tmpM4 = new THREE.Matrix4();

const toCanvasCoords = (position, projectionMatrix, matrixWorldInverse, containerBounds) => {
  const p = _tmpV3.copy(position);
  _tmpM4.multiplyMatrices(projectionMatrix, matrixWorldInverse);

  p.applyProjection(_tmpM4);

  if (p.x < -1 || p.x > 1 || p.y < -1 || p.y > 1 || p.z < -1 || p.z > 1) {
    return;
  }

  return new THREE.Vector2(
    Math.round((p.x + 1) / 2 * containerBounds.width),
    Math.round((-p.y + 1) / 2 * containerBounds.height)
  );
};

const CIRCLE_RADIUS = 8;
const CLUSTER_CIRCLE_RADIUS = 12;
const CLUSTERING_THRESHOLD = Math.pow(CLUSTER_CIRCLE_RADIUS * 4, 2);
const STREAM_LABEL_ID_PREFIX = "stream-label";
const MARKER_TYPES_ENABLED_BY_DEFAULT = new Set(Object.values(DtConstants.StreamStates));

const getLabelIcon = function (labelId) {let clusterSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  const isCluster = clusterSize > 1;

  let circle = `<g id=${labelId}>
        <circle r="${isCluster ? CLUSTER_CIRCLE_RADIUS : CIRCLE_RADIUS}" cx="25" cy="27.5" fill="none" stroke-width="1.5" />
        <circle r="${isCluster ? CLUSTER_CIRCLE_RADIUS : CIRCLE_RADIUS}" cx="25" cy="27.5" fill="currentColor" opacity="0.55" stroke-width="0" />
    </g>`;

  if (isCluster) {
    circle += `<g><text x="${3 - clusterSize.toString().length * 0.6}em" y="1.1em">${clusterSize}</text></g>`;
  }

  return circle;
};

// Matches client theme colors
export const COLOR_SCHEME = {
  active: '#0696d7', // brand: COLORS.blue500,
  success: '#6a9728', // status-ok: COLOR.green600,
  warning: '#faa21b', // status-warning: COLORS.yellow500,
  offline: '#4d4d4d', // info: COLORS.charcoal80,
  error: '#eb5555' // status-error: COLORS.red500,
};

const DEFAULT_COLOR = 'rgba(0, 190, 0)';
const ACTIVE_COLOR = COLOR_SCHEME.active;

export class StreamMarkers {
  constructor(streamMgr, eventSource) {
    this.streamMgr = streamMgr;
    this.labels = {};
    this.matchHostVisibility = true;
    this.viewer = undefined;
    this.eventSource = eventSource;
    this.isActive = false;
    this.transitionDepth = 0;

    this.boundOnClick = this.onClick.bind(this);
    this.boundOnMouseOver = this.onMouseOver.bind(this);
    this.boundOnMouseOut = this.onMouseOut.bind(this);
    this.boundOnStreamsInfoChanged = this.onStreamsInfoChanged.bind(this);
    this.boundOnIsolationChanged = this.onIsolationChanged.bind(this);
    this.boundUpdate = this.update.bind(this);
    this.debouncedUpdate = debounce(this.boundUpdate, 20);
    this.boundLabelDistance = this.#labelsDistance.bind(this);

    this.boundOnTransitionStarted = this.#onTransitionStarted.bind(this);
    this.boundOnTransitionEnded = this.#onTransitionEnded.bind(this);

    this.enabledMarkerTypes = new Set(MARKER_TYPES_ENABLED_BY_DEFAULT);
  }

  init(viewer) {
    this.viewer = viewer;

    this.eventSource.addEventListener(dte.DT_STREAMS_INFO_CHANGED_EVENT, this.boundOnStreamsInfoChanged);
    this.viewer.addEventListener(et.AGGREGATE_ISOLATION_CHANGED_EVENT, this.boundOnIsolationChanged);

    this.viewer.addEventListener(et.TRANSITION_STARTED, this.boundOnTransitionStarted);
    this.viewer.addEventListener(et.TRANSITION_ENDED, this.boundOnTransitionEnded);
  }

  #isSelected(stream) {
    return this.streamMgr.defaultModel.selector?.isSelected(stream.dbId);
  }

  #labelsDistance(b1, b2) {
    if (b1.color !== b2.color) {
      return Infinity;
    } else if (b1.hostElement.hostId === b2.hostElement.hostId) {
      return this.#isSelected(b1) !== this.#isSelected(b2) ? Infinity : 0;
    } else if (this.#isSelected(b1) !== this.#isSelected(b2)) {
      return Infinity;
    }

    return b1.canvasPosition.distanceToSquared(b2.canvasPosition);
  }

  // ensure markers are hidden while a transition animation is running
  #onTransitionStarted() {
    // transition started/ended do no appear strictly alternating but apparently can be stacked/nested
    // here we keep track of the stack depth and only care about the outmost transition
    if (this.transitionDepth++ === 0) {
      this.activeBeforeTransition = this.isActive;
    }
    this.hide();
  }

  // restore marker visibility after a transition has ended
  #onTransitionEnded() {
    // clear the stream's cached world position (will be recomputed by next update)
    const streams = this.streamMgr.getAllStreamInfosFromCache();
    if (streams) {
      for (let stream of streams) {
        stream.position = null;
      }
    }
    if (--this.transitionDepth === 0 && this.activeBeforeTransition) {
      this.show();
    }
  }

  show(markerTypes) {
    const markersUpdated = this.#setEnabledMarkerTypes(markerTypes);

    if (this.isActive) {
      if (markersUpdated) {
        this.update();
        this.eventSource.dispatchEvent({
          type: dte.DT_STREAM_MARKER_CHANGED_EVENT,
          change: { isVisible: true, markerTypes: this.enabledMarkerTypes, ctype: 'visibility' }
        });
      }
      return;
    }

    this.isActive = true;
    this.eventSource.dispatchEvent({
      type: dte.DT_STREAM_MARKER_CHANGED_EVENT,
      change: { isVisible: true, markerTypes: this.enabledMarkerTypes, ctype: 'visibility' }
    });

    // Refresh markers when geometry loader goes idle, when camera moves, or when selections change
    this.viewer.addEventListener(et.GEOMETRY_LOADED_EVENT, this.boundUpdate);
    this.viewer.addEventListener(et.CAMERA_CHANGE_EVENT, this.debouncedUpdate);
    this.viewer.addEventListener(et.AGGREGATE_SELECTION_CHANGED_EVENT, this.boundUpdate);

    // Load non-cached streams and refresh markers if there are streams
    this.streamMgr.getAllStreamInfos().then((streams) => streams.length && this.onStreamsInfoChanged());
  }

  update() {
    this.#update();
    // check current facility state trigger the new update under the hood
    this.#doAnalysis();
  }

  // NOTE: called on every frame of camera move, so performance critical
  #update() {
    if (!this.isActive) return;

    const streams = this.streamMgr.getAllStreamInfosFromCache();

    // Sometimes the listener is not disposed of quick enough
    // this should catch those cases
    if (!streams?.length) {
      return;
    }

    // Ensure the camera matrices and viewport remain the same across streams
    const { projectionMatrix, matrixWorldInverse } = this.viewer.getCamera();
    const containerBounds = this.viewer.navigation.getScreenViewport();

    const visibleStreams = [];

    for (let stream of streams) {

      if (!stream.hostElement) {
        continue;
      }

      const { model, hostId } = stream.hostElement;

      if (this.#isMarkerVisible(model, hostId, stream)) {
        // Avoid resetting stream position to stabilize clusters
        if (!stream.position) {
          const bounds = get3DSelectionBounds(this.viewer, [
          { model, selection: [hostId] }]
          );
          stream.position = bounds.isEmpty() ? undefined : bounds.getCenter();
        }

        if (stream.position) {
          stream.canvasPosition = toCanvasCoords(
            stream.position,
            projectionMatrix,
            matrixWorldInverse,
            containerBounds
          );
        }
      } else {
        stream.canvasPosition = null;
      }

      if (stream.canvasPosition) {
        visibleStreams.push(stream);
      } else {
        const cachedLabel = this.labels[stream.dbId];
        if (cachedLabel?.visible) {
          cachedLabel.setVisible(false);
        }
      }
    }

    const clusters = hcluster(visibleStreams, this.boundLabelDistance, "average", CLUSTERING_THRESHOLD);

    for (let cluster of clusters) {
      // We need a consistent cluster position, take the stream's with the smallest dbId
      let minDbId = Infinity;
      const flCluster = flattenCluster(cluster, (stream) => minDbId = Math.min(minDbId, stream.dbId));

      for (let stream of flCluster) {
        const cachedLabel = this.labels[stream.dbId];
        // if we have a cluster of length > 1, we pick the stream with the lowest dbId as a cluster location
        // if we have a cluster of length === 1, it's a standalone(non-clustered) stream
        if (cachedLabel && stream.dbId === minDbId) {
          if (cachedLabel.cluster.length !== flCluster.length) {
            // Visibility or label changed, update is needed
            const streamIcon = getLabelIcon(cachedLabel.id, flCluster.length);
            cachedLabel.setIcon(streamIcon);
            // Icon changed, reassign listeners target
            cachedLabel.removeLabelListeners();
            cachedLabel.addListenersToIconComponent(cachedLabel.id);
          }
          if (!cachedLabel.visible) {
            cachedLabel.setVisible(true);
          }
          cachedLabel.cluster = flCluster;
          cachedLabel.updateColor();
        } else if (cachedLabel?.visible) {
          // non-first item in the cluster, hide it
          cachedLabel.setVisible(false);
        }

        if (!cachedLabel && stream.dbId === minDbId) {
          // we do not have cached label yet, so create it
          const label = this.createLabel(flCluster, stream);
          label.removeLabelListeners();
          label.addListenersToIconComponent(label.id);
          label.updateColor();
        }
      }
    }
  }

  async #doAnalysis() {
    const streams = await this.streamMgr.getAllStreamInfos();
    const streamIDs = streams.map((stream) => stream.dbId);
    let lastReadings = await this.streamMgr.getLastReadings(streamIDs);
    lastReadings = StreamUtils.createStreamToReadingMap(streams, lastReadings);


    let res = new Map();

    for (let stream of streams) {
      if (!StreamUtils.isOnline(stream, lastReadings)) {
        res.set(stream.dbId, DtConstants.StreamStates.Offline);
        continue;
      }

      res.set(stream.dbId, StreamUtils.getStatus(stream, lastReadings));
    }

    this.analysis = res;
    this.#update();
  }

  #isMarkerVisible(model, hostId, stream) {
    // a marker is visible if
    // - the stream element itself is "visible"
    // - its host element is visible (and matchHostVisibility is true)
    // - one of above condition is satisfied and marker group visible

    const isVisible = this.streamMgr.defaultModel.visibilityManager?.isNodeVisible(stream.dbId) ||
    this.matchHostVisibility && model.visibilityManager?.isNodeVisible(hostId);

    const isMarkerTypeEnabled = this.enabledMarkerTypes.has(this.analysis?.get(stream.dbId) || DtConstants.StreamStates.Normal);

    return isVisible && isMarkerTypeEnabled;
  }

  #setEnabledMarkerTypes(markerTypes) {
    const equalSets = (a, b) => {

      if (a.size !== b.size) {
        return false;
      }

      for (let v of a) {
        if (!b.has(v)) {
          return false;
        }
      }

      return true;
    };

    if (markerTypes) {
      if (!equalSets(markerTypes, this.enabledMarkerTypes)) {
        this.enabledMarkerTypes = markerTypes;
        return true;
      }

      return false;
    }

    // Reset to default only if current enabled marker types are different from the default
    if (!equalSets(MARKER_TYPES_ENABLED_BY_DEFAULT, this.enabledMarkerTypes)) {
      this.enabledMarkerTypes = new Set(MARKER_TYPES_ENABLED_BY_DEFAULT);
      return true;
    }

    return false;
  }

  createLabel(cluster, mainStream) {
    const labelId = STREAM_LABEL_ID_PREFIX + '-' + mainStream.fullId;
    const label = new PointMarker(
      this.viewer,
      mainStream.position,
      null,
      getLabelIcon(labelId, cluster.length)
    );

    label.id = labelId;
    label.cluster = cluster;

    // Could be creating a label after activating a cluster,
    // in that case we need to color the stream as active
    label.updateColor = () => {
      label.setColor(this.#isSelected(mainStream) ? ACTIVE_COLOR : this.#getLabelColor(label));
    };

    label.addEventListener(Label3D.Events.CLICK, this.boundOnClick);
    label.addEventListener(Label3D.Events.ON_MOUSE_OVER, this.boundOnMouseOver);
    label.addEventListener(Label3D.Events.ON_MOUSE_OUT, this.boundOnMouseOut);
    this.labels[mainStream.dbId] = label;

    return label;
  }

  #getLabelColor(label) {
    if (!label || !this.analysis) {
      return DEFAULT_COLOR;
    }

    const cluster = label.cluster;

    const isOffline = cluster.find((stream) => this.analysis?.get(stream.dbId) === DtConstants.StreamStates.Offline);
    if (isOffline) {
      return COLOR_SCHEME.offline;
    }
    const inAlert = cluster.find((stream) => this.analysis?.get(stream.dbId) === DtConstants.StreamStates.Alert);
    if (inAlert) {
      return COLOR_SCHEME.error;
    }
    const inWarning = cluster.find((stream) => this.analysis?.get(stream.dbId) === DtConstants.StreamStates.Warning);
    if (inWarning) {
      return COLOR_SCHEME.warning;
    }

    return COLOR_SCHEME.success;
  }

  #removeCachedLabel(dbId) {
    const label = this.labels[dbId];
    label.removeEventListener(Label3D.Events.CLICK, this.boundOnClick);
    label.removeEventListener(Label3D.Events.ON_MOUSE_OVER, this.boundOnMouseOver);
    label.removeEventListener(Label3D.Events.ON_MOUSE_OUT, this.boundOnMouseOut);
    label.dispose();

    delete this.labels[dbId];
  }

  #removeAllCachedLabels() {
    for (const dbId in this.labels) {
      this.#removeCachedLabel(dbId);
    }

    this.labels = {}; // sanity wipe
  }

  hide() {
    if (!this.isActive) {
      return;
    }

    this.isActive = false;
    this.#setEnabledMarkerTypes(new Set());
    this.eventSource.dispatchEvent({
      type: dte.DT_STREAM_MARKER_CHANGED_EVENT,
      change: { isVisible: false, markerTypes: this.enabledMarkerTypes, ctype: 'visibility' }
    });

    this.debouncedUpdate.cancel();
    for (const dbId in this.labels) {
      const label = this.labels[dbId];
      label.setVisible(false);
    }
    this.viewer.removeEventListener(et.GEOMETRY_LOADED_EVENT, this.boundUpdate);
    this.viewer.removeEventListener(et.CAMERA_CHANGE_EVENT, this.debouncedUpdate);
    this.viewer.removeEventListener(et.AGGREGATE_SELECTION_CHANGED_EVENT, this.boundUpdate);

    this.#removeAllCachedLabels();
  }

  dispose() {
    this.eventSource.removeEventListener(dte.DT_STREAMS_INFO_CHANGED_EVENT, this.boundOnStreamsInfoChanged);
    this.viewer.removeEventListener(et.AGGREGATE_ISOLATION_CHANGED_EVENT, this.boundOnIsolationChanged);
    this.viewer.removeEventListener(et.TRANSITION_STARTED, this.boundOnTransitionStarted);
    this.viewer.removeEventListener(et.TRANSITION_ENDED, this.boundOnTransitionEnded);
    this.hide();
    this.#removeAllCachedLabels();
  }

  onClick(_ref) {let { event, target: label } = _ref;
    event.preventDefault();
    event.stopPropagation();

    const defaultModel = this.streamMgr.defaultModel;
    const dbIds = label.cluster.map((stream) => stream.dbId);
    if (event.shiftKey) {
      this.viewer.toggleSelect(dbIds, defaultModel);
    } else {
      this.viewer.select(dbIds, defaultModel);
    }
  }

  onMouseOver(_ref2) {let { event, target: label } = _ref2;
    event.preventDefault();
    event.stopPropagation();
    label.setColor(ACTIVE_COLOR);
    this.viewer.dispatchEvent({
      type: dte.DT_STREAM_MARKER_MOUSE_OVER,
      target: label,
      cluster: label.cluster,
      event
    });
  }

  // noinspection JSUnusedGlobalSymbols
  onHighlightChange(_ref3) {let { stream, highlight } = _ref3;
    if (this.#isSelected(stream)) {
      // don't change highlight if marker is selected
      return;
    }

    let label = this.labels[stream.dbId];
    if (!label) {
      label = Object.values(this.labels).find((lbl) => lbl.cluster?.find((s) => s.dbId === stream.dbId));
    }
    label?.setColor(highlight ? ACTIVE_COLOR : this.#getLabelColor(label));
  }

  onMouseOut(_ref4) {let { event, target: label } = _ref4;
    event.preventDefault();
    event.stopPropagation();
    label.updateColor();
    // TODO: cluster property duplication can be deleted as soon client code will be adjusted
    this.viewer.dispatchEvent({ type: dte.DT_STREAM_MARKER_MOUSE_OUT, target: label, cluster: label.cluster, event });
  }

  onIsolationChanged() {
    const defaultModel = this.streamMgr.defaultModel;

    if (!defaultModel)
    return;

    if (!this.streamMgr.facility.isModelVisible(defaultModel)) {
      this.hide();
      return;
    }

    this.update();
  }

  onStreamsInfoChanged() {
    // Prevents clusters creation/update if markers are deactivated
    if (this.isActive) {
      // remove cached labels for streams that disappeared or have no host anymore
      const streamsWithHost = this.streamMgr.getAllStreamInfosFromCache().reduce((idSet, stream) => {
        if (stream.hostElement) {
          idSet.add(stream.dbId);
        }
        return idSet;
      }, new Set());

      for (let dbId in this.labels) {
        // The host existence check requires the dbId to be converted to a numeric type
        if (!streamsWithHost.has(Number(dbId))) {
          this.#removeCachedLabel(dbId);
        }
      }
      this.update();
    }
  }

  async setVisibility(isVisible, markerTypes) {
    if (isVisible) {
      this.show(markerTypes);
    } else {
      this.hide();
    }
  }
}