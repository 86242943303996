import { binToHexString, binToPackedString, packedToBin } from "./hex-strings";
import { b64ToBinaryString, base64DecToArr, base64EncArr } from "./base64";
import { ElementFlags, ElementFlagsSize, ElementIdSize, ElementIdWithFlagsSize } from "../schema/dt-schema";

let zeroHash = new Uint8Array(20);
let zeroString = binToPackedString(zeroHash, 0, 20);
let _buf = new Uint8Array(ElementIdSize);
let _buf24 = new Uint8Array(ElementIdWithFlagsSize);

//Holds mappings between local integer IDs (dbId) and database IDs (the long ones)
export class IdMapper {

  constructor(initialData) {
    if (initialData) {
      this.rootId = initialData.rootId;
      this.extIds = initialData.extIds;
      this.extIdToIndex = initialData.extIdToIndex;
      this.numLoaded = initialData.numLoaded;
    } else {

      this.extIds = [zeroString];
      this.extIdToIndex = new Map();
      this.numLoaded = 1;

      //Nasty way to get from a modelId to the corresponding
      //element ID of the row containing the document element
      _buf.fill(0);

      //TODO: Do we need to start using fully qualified IDs in the IdMapper (including the 4 flags bytes)?
      //Convert the element flag to corresponding key flag
      //let keyFlag = ElementFlags.DocumentRoot & 0xff000000;
      //Big-Endian flags
      //_buf[0] = (keyFlag >> 24) & 0xff;
      //_buf[1] = (keyFlag >> 16) & 0xff;
      //_buf[2] = (keyFlag >> 8) & 0xff;
      //_buf[3] = keyFlag & 0xff;

      //rootId should always equal 1, since it's the first call to addEntity
      this.rootId = this.addEntity(binToPackedString(_buf, 0, ElementIdSize));
    }

    if (this.rootId !== 1) {
      console.warn("unexpected root ID is not 1");
    }
  }

  addEntityFromBuf(buf, offset, len) {
    len = len || 20;

    return this.addEntity(binToPackedString(buf, offset, len));
  }

  addEntityFromB64(extId) {

    let binString = b64ToBinaryString(extId);

    //Make sure we use the non-qualified ID for consistency
    if (binString.length !== 10 /*ElementIdSize / 2*/) {
      if (binString.length === 12 /*ElementIdWithFlagsSize/2*/) {
        binString = binString.slice(2);
      } else {
        console.warn("unexpected element ID length:", extId);
      }
    }

    return this.addEntity(binString);
  }

  addEntityAndExtractFlagsFromB64(extId) {

    base64DecToArr(extId, _buf24);
    // drop element flags and get a hash
    extId = binToPackedString(_buf24, 4, ElementIdSize);

    const flags = _buf24[0] << 24 | _buf24[1] << 16 | _buf24[2] << 8 | _buf24[3];

    const dbId = this.addEntity(extId);
    return [dbId, flags];
  }


  addEntity(extId, isVirtual) {

    if (!extId) {
      return 0;
    }

    //TODO: Do we need to start using fully qualified IDs in the IdMapper (including the 4 flags bytes)?
    //If yes, this needs to be updated accordingly.
    //if (!isVirtual && extId.length !== ElementIdSize/2) {
    //	console.warn("Unexpected length of element ID", extId.length);
    //}

    let dbId = this.extIdToIndex.get(extId);
    if (dbId === undefined) {
      dbId = this.numLoaded++;
      this.extIdToIndex.set(extId, dbId);
      this.extIds[dbId] = extId;
    }

    return dbId;
  }

  getObjectCount() {
    return this.numLoaded;
  }

  getElementId(dbId) {
    return this.extIds[dbId];
  }

  getDbId(extId) {
    return this.extIdToIndex.get(extId);
  }

  getDbIdFromB64ExternalId(extId) {
    const len = base64DecToArr(extId, _buf24);
    // drop element flags and get a hash
    const res = binToPackedString(_buf24, 4, ElementIdSize);

    return this.extIdToIndex.get(res);
  }

  getDbIdFromDtElmId(extId) {
    let binString = b64ToBinaryString(extId);

    //Make sure we use the non-qualified ID for consistency
    if (binString.length !== 10 /*ElementIdSize / 2*/) {
      if (binString.length === 12 /*ElementIdWithFlagsSize/2*/) {
        binString = binString.slice(2);
      } else {
        console.warn("unexpected element ID length:", extId);
      }
    }

    return this.extIdToIndex.get(binString);
  }

  getEncodedElementIdWithFlags(dbId, flags) {

    if (dbId === this.rootId) {
      return "";
    }

    let buf = this.getElementIdWithFlags(dbId, flags);

    if (!buf) {
      return null;
    }

    return base64EncArr(buf);
  }

  // Write flags + elId (Bin[] of 24) that corresponds to the given dbId to the buffer.
  getElementIdWithFlags(dbId, flags) {let buf = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _buf24;let off = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

    if (dbId === this.rootId) {
      return null;
    }

    let eid = this.getElementId(dbId);
    if (!eid) {
      return null;
    }

    //Convert the element flag to corresponding key flag
    let keyFlag = flags & 0xff000000;

    //Big-Endian flags
    buf[off + 0] = keyFlag >> 24 & 0xff;
    buf[off + 1] = keyFlag >> 16 & 0xff;
    buf[off + 2] = keyFlag >> 8 & 0xff;
    buf[off + 3] = keyFlag & 0xff;

    packedToBin(eid, buf, off + ElementFlagsSize);

    return buf;
  }

  getEncodedElementId(dbId) {

    if (dbId === this.rootId) {
      return "";
    }

    let eid = this.getElementId(dbId);

    if (!eid) {
      return null;
    }

    packedToBin(eid, _buf, 0);

    return base64EncArr(_buf);
  }

  getEncodedElementGUID(dbId) {

    if (dbId === this.rootId) {
      return "";
    }

    let eid = this.getElementId(dbId);

    if (!eid) {
      return null;
    }

    packedToBin(eid, _buf, 0);

    const hex = binToHexString(_buf).split('');

    // A Revit GUID is formatted in groups of 8-4-4-4-12-8 hexadecimal characters
    const hexGroups = [8, 4, 4, 4, 12];

    let dashPosition = 0;
    for (let i = 0; i < hexGroups.length; i++) {
      dashPosition += hexGroups[i];
      hex.splice(dashPosition, 0, '-');
      dashPosition++;
    }

    return hex.join('');
  }

}