import { RenderModel } from "../wgs/scene/RenderModel";
import { EventDispatcher } from "../application/EventDispatcher";
import { FragmentPointer } from "../wgs/scene/FragmentList";

import { KeyFlags, ElementFlags, QC } from "./schema/dt-schema";
import * as et from "../application/EventTypes";
import { fetchJson } from "../net/fetch";
import { DtConstants } from "./schema/DtConstants";
import { fixModelUrn } from "./encoding/urn";
import { endpoint } from "../net/endpoints";
import { convertUnits, fixUnitString, getUnitData, ModelUnits } from "../measurement/UnitFormatter";
import { PermissionChecker } from "./PermissionChecker";
import { AttributeDef } from "./schema/Attribute";
import * as THREE from "three";
import { DtPackage } from "./loader/DtVizLoad";

// In theory, this operation could be done by the worker. However, subsequent one is to create fragment data
// which requires instantiation of THREE resources (Vector and Quaternion) which cannot be accessed by the worker since
// it is explicitly disabled via webpack config
import { serializeLmvBufferGeom } from "./worker/geom/OtgGeomEncoder";
import { makeNewEncodedElementID } from "./encoding/base64";
import { DT_MODEL_CHANGED_EVENT } from "./DtEventTypes";
import { FlatStringStorage } from "../wgs/scene/FlatStringStorage";
import { ConsolidatedBVH } from "../wgs/scene/ConsolidatedBVH";
import i18n from "i18next";

const WORKER_UNLOAD_PROPERTYDB = "UNLOAD_PROPERTYDB";
const WORKER_TABLE_SCAN = "TABLE_SCAN";
const WORKER_MUTATE = "MUTATE";
const WORKER_HISTORY = "HISTORY";
const WORKER_GET_ROOMS = "GET_ROOMS";
const WORKER_GET_HASH_ATTR = "GET_HASH_ATTR";
const WORKER_GET_ATTRIBUTES = "GET_ATTRIBUTES";
const WORKER_RELOAD_ATTRIBUTES = "RELOAD_ATTRIBUTES";
const WORKER_GET_ELEMENT_IDS_FROM_DB_IDS = "GET_ELEMENT_IDS_FROM_DB_IDS";
const WORKER_GET_DB_IDS_FROM_ELEMENT_IDS = "GET_DB_IDS_FROM_ELEMENT_IDS";
const WORKER_GET_PROPERTIES_DT = "GET_PROPERTIES_DT";
const WORKER_GET_CUSTOM_FACETS = "GET_CUSTOM_FACETS";
const WORKER_CREATE_ELEMENTS = "CREATE_ELEMENTS";
const WORKER_DELETE_ELEMENT = "DELETE_ELEMENT";
const WORKER_SEARCH_ELEMENTS = "SEARCH_ELEMENTS";
const WORKER_UPDATE_STREAM_HOST_XREF = "UPDATE_STREAM_HOST_XREF";
const WORKER_GET_DB_ID_TO_CONNS = "GET_DB_ID_TO_CONNS";
const WORKER_GET_NEW_MEPSYSTEM_KEY = "GET_NEW_MEPSYSTEM_KEY";
const WORKER_GET_MEPSYSTEMS = "GET_MEPSYSTEMS";
const WORKER_GET_ELEMENT_ROOMS_HISTORY = "GET_ELEMENT_ROOMS_HISTORY";
const WORKER_DO_REVERT_CHANGE = "REVERT_CHANGE";

const _tmpBox = new THREE.Box3();

/**
 * Represents a 3D model file from a DtFacility
 *
 * @constructor
 * @param {DtFacility} facility - the parent facility
 * @param {string} modelId - fully qualified model id
 * @memberof Autodesk.Tandem
 * @alias Autodesk.Tandem.DtModel
 */
export function DtModel(facility, modelId) {
  RenderModel.call(this);

  this.facility = facility;
  this._modelId = fixModelUrn(modelId);
  this.loadContext = facility.loadContext;
  this.propertyDbError = null;
  this.lastChangeTime = 0;
  this.loader = null;

  this.subscribed = false;
}


DtModel.prototype.initLoadPromise = function () {
  // if there's a promise that hasn't settled yet, we reuse it
  if (this._loadPromise && !this._loadPromise.settled) {
    return;
  }
  this._loadPromise = {
    settled: false
  };
  // TODO: this can be replaced by Promise.withResolver once supported by nodejs too
  this._loadPromise.promise = new Promise((resolve, reject) => {
    this._loadPromise.resolve = resolve;
    this._loadPromise.reject = reject;
  });
  this._loadPromise.promise.catch((e) => {
    console.info(`${this.urn}: ${e}`);
  });
};
DtModel.prototype.settleLoadPromise = function (success, reason) {
  if (!this._loadPromise) {
    console.warn(`Loading of model ${this.urn()} didn't start.`);
  }

  if (this._loadPromise.settled) {
    return;
  }

  if (success) {
    this._loadPromise.resolve();
  } else {
    this._loadPromise.reject(new Error(reason));
  }
  this._loadPromise.settled = true;
};

EventDispatcher.prototype.apply(DtModel.prototype);
PermissionChecker.prototype.apply(DtModel.prototype);
DtModel.prototype.constructor = DtModel;

/**
 * @param {boolean}[ignoreTransform] - Set to true to return the original bounding box in model space coordinates.
 * @param {boolean}[excludeShadow]    - Remove shadow geometry (if exists) from model bounds.
 * @returns {THREE.Box3} Bounding box of the model if available, otherwise null.
 */
DtModel.prototype.getBoundingBox = function () {
  const bbox = new THREE.Box3();

  return function (ignoreTransform, excludeShadow) {
    if (!this.myData) {
      return null;
    }

    // Prefer returning modelSpaceBBox, which is the original model's bounding box without placementTransform.
    // If for some reason it doesn't exist (missing in loader) - return bbox baked with placementTransform.
    let whichBox = this.myData.modelSpaceBBox || this.myData.bbox;

    if (!whichBox) {
      return null;
    }

    bbox.copy(whichBox);

    // If ignore transform is set, we are done.
    if (ignoreTransform) {
      return bbox;
    }

    // Apply placement transform
    const placementMatrix = this.getData().placementWithOffset;

    // Apply placement transform only if the modelSpace bounding box was used (should be always technically, unless it's missing in the loader).
    if (placementMatrix && this.myData.modelSpaceBBox) {
      bbox.applyMatrix4(placementMatrix);
    }

    // Apply dynamic model transform.
    const modelMatrix = this.getModelTransform();

    if (modelMatrix) {
      bbox.applyMatrix4(modelMatrix);
    }

    return bbox;
  };
}();


/**
 * @returns {InstanceTree} Instance tree of the model if available, otherwise null.
 */
DtModel.prototype.getInstanceTree = function () {
  if (this.myData)
  return this.myData.instanceTree;
  return null;
};

/**
 * @returns {boolean} Whether the model is 2D.
 */
DtModel.prototype.is2d = function () {
  return false;
};

/**
 * @returns {boolean} Whether the model is 3D.
 */
DtModel.prototype.is3d = function () {
  return true;
};

/**
 * @returns {boolean} true if the model is an OTG file - which supports sharing of materials and geometry.
 */
DtModel.prototype.isOTG = function () {
  return true;
};

/**
 * @returns {boolean} true if the model is created from a PDF file.
 */
DtModel.prototype.isPdf = function () {
  return false;
};


DtModel.prototype.getQueryParams = function () {
  // e.g. "until=<timestamp>"
  return "";
};

DtModel.prototype.urn = function () {
  return this._modelId;
};
/**
 * Default model is the one that allows user to create own elements and (potentially) geometries.
 * It has the same guid as the twin owning the model. Equality of guids is the only indication that model is "default".
 *
 * @returns {boolean} True if the model is the default model for the facility.
 *
 * @alias Autodesk.Tandem.DtModel#isDefault
 */
DtModel.prototype.isDefault = function () {
  const fGuid = this.facility.urn().slice(DtConstants.DT_TWIN_URN_PREFIX.length);
  const mGuid = this.urn().slice(DtConstants.DT_MODEL_URN_PREFIX.length);
  return fGuid === mGuid;
};

/**
 * Return parent facility of this model.
 *
 * @returns {DtFacility} The facility that owns this model.
 *
 * @alias Autodesk.Tandem.DtModel#getParentFacility
 */
DtModel.prototype.getParentFacility = function () {
  return this.facility;
};

/**
 * Checks if this model is primary model.
 *
 * @returns {boolean}
 *
 * @alias Autodesk.Tandem.DtModel#isPrimaryModel
 */
DtModel.prototype.isPrimaryModel = function () {
  return this.facility.getLinkForModel(this)?.main;
};

/**
 * Checks if this model is visible by default.
 *
 * @returns {boolean}
 *
 * @alias Autodesk.Tandem.DtModel#isVisibleByDefault
 */
DtModel.prototype.isVisibleByDefault = function () {
  return this.facility.getLinkForModel(this)?.on;
};

/**
 * Returns label for this model.
 *
 * @returns {string}
 *
 * @alias Auodesk.Tandem.DtModel#label
 */
DtModel.prototype.label = function () {
  return this.facility.getLinkForModel(this)?.label;
};

/**
 * Returns access level for this model for current user.
 *
 * @returns {string}
 *
 * @alias Autodesk.Tandem.DtModel#accessLevel
 */
DtModel.prototype.accessLevel = function () {
  return this.facility.getLinkForModel(this)?.accessLevel;
};

DtModel.prototype._getAccessLevel = function () {
  return this.accessLevel();
};

DtModel.prototype.updateFromFile = async function (fileName, urn, phaseOrViewName, correlationId) {
  let loadContext = { ...this.loadContext };
  loadContext.headers['x-dt-span-id'] = this.urn();

  return fetchJson(loadContext, `/twins/${this.facility.urn()}/import`, 'POST', {
    modelId: this.urn(),
    realFilename: fileName,
    urn: urn,
    phaseOrViewName: phaseOrViewName,
    roomAssignment: true,
    conversionMethod: "v3",
    correlationId: correlationId
  });
};

DtModel.prototype.setPrimaryModel = async function () {
  return this.facility.makePrimary(this);
};

DtModel.prototype.setVisibleByDefault = function (visibleByDefault) {
  return this.facility.updateLinkForModel(this, {
    ...this.facility.getLinkForModel(this),
    on: visibleByDefault
  });
};

DtModel.prototype.setLabel = function (label) {
  return this.facility.updateLinkForModel(this, {
    ...this.facility.getLinkForModel(this),
    label
  });
};

/**
 * Returns the geometry data.
 *
 * @memberof Autodesk.Viewing.Model
 * @alias Autodesk.Viewing.Model#getData
 */
DtModel.prototype.getData = function () {
  return this.myData;
};

DtModel.prototype.setData = function (svf) {
  this.myData = svf;
};

/**
 * Returns the root of the geometry node graph.
 * @returns {object} The root of the geometry node graph. Null if it doesn't exist.
 *
 * @memberof Autodesk.Viewing.Model
 * @alias Autodesk.Viewing.Model#getRoot
 */
DtModel.prototype.getRoot = function () {
  let data = this.getData();
  if (data && data.instanceTree)
  return data.instanceTree.root;
  return null;
};

/**
 * Returns the root of the geometry node graph.
 * @returns {number} The ID of the root or null if it doesn't exist.
 *
 * @memberof Autodesk.Viewing.Model
 * @alias Autodesk.Viewing.Model#getRootId
 */
DtModel.prototype.getRootId = function () {
  let data = this.getData();
  return data && data.instanceTree && data.instanceTree.getRootId() || 0;
};

/**
 * Returns an object that contains the standard unit string (unitString) and the scale value (unitScale).
 * @param {string} unit - Unit name from the metadata
 * @returns {object} this object contains the standardized unit string (unitString) and a unit scaling value (unitScale)
 *
 * @memberof Autodesk.Viewing.Model
 * @alias Autodesk.Viewing.Model#getUnitData
 */
DtModel.prototype.getUnitData = function (unit) {
  console.warn("Model.getUnitData is deprecated and will be removed in a future release, use Autodesk.Viewing.Private.getUnitData() instead.");
  return getUnitData(unit);
};

/**
 * Returns the scale factor of model's distance unit to meters.
 * @returns {number} The scale factor of the model's distance unit to meters or unity if the units aren't known.
 *
 * @memberof Autodesk.Viewing.Model
 * @alias Autodesk.Viewing.Model#getUnitScale
 */
DtModel.prototype.getUnitScale = function () {
  return convertUnits(this.getUnitString(), ModelUnits.METER, 1, 1);
};

/**
 * Returns a standard string representation of the model's distance unit.
 * @returns {string} Standard representation of model's unit distance or null if it is not known.
 *
 * @memberof Autodesk.Viewing.Model
 * @alias Autodesk.Viewing.Model#getUnitString
 */
DtModel.prototype.getUnitString = function () {
  let unit = this.getMetadata('distance unit', 'value', null);
  return fixUnitString(unit);
};

/**
 * Returns a standard string representation of the model's display unit.
 * @returns {string} Standard representation of model's display unit or null if it is not known.
 *
 * @memberof Autodesk.Viewing.Model
 * @alias Autodesk.Viewing.Model#getDisplayUnit
 */
DtModel.prototype.getDisplayUnit = function () {
  return this.getUnitString();
};

/**
 * Return metadata value.
 * @param {string} itemName - Metadata item name.
 * @param {string} [subitemName] - Metadata subitem name.
 * @param {*} [defaultValue] - Default value.
 * @returns {*} Metadata value, or defaultValue if no metadata or metadata item/subitem does not exist.
 *
 * @memberof Autodesk.Viewing.Model
 * @alias Autodesk.Viewing.Model#getMetadata
 */
DtModel.prototype.getMetadata = function (itemName, subitemName, defaultValue) {
  let data = this.getData();
  let item = data?.metadata?.[itemName];
  if (subitemName) {
    return item?.[subitemName] ?? defaultValue;
  }
  return item ?? defaultValue;
};

/**
 * Returns the default camera.
 *
 * @memberof Autodesk.Viewing.Model
 * @alias Autodesk.Viewing.Model#getDefaultCamera
 */
DtModel.prototype.getDefaultCamera = function () {

  let myData = this.getData();

  if (!myData)
  return null;

  let defaultCamera = null;
  let numCameras = myData.cameras ? myData.cameras.length : 0;
  if (0 < numCameras) {
    // Choose a camera.
    // Use the default camera if specified by metadata.
    //
    let defaultCameraIndex = this.getMetadata('default camera', 'index', null);
    if (defaultCameraIndex !== null && myData.cameras[defaultCameraIndex]) {
      defaultCamera = myData.cameras[defaultCameraIndex];

    } else {

      // No default camera. Choose a perspective camera, if any.
      //
      for (let i = 0; i < numCameras; i++) {
        let camera = myData.cameras[i];
        if (camera.isPerspective) {
          defaultCamera = camera;
          break;
        }
      }

      // No perspective cameras, either. Choose the first camera.
      //
      if (!defaultCamera) {
        defaultCamera = myData.cameras[0];
      }
    }
  }

  return defaultCamera;
};

/**
 * Returns up vector as an array of 3.
 *
 * @memberof Autodesk.Viewing.Model
 * @alias Autodesk.Viewing.Model#getUpVector
 */
DtModel.prototype.getUpVector = function () {
  return this.getMetadata('world up vector', 'XYZ', null);
};

/**
 * Returns true if the model with all its geometries has loaded.
 * @returns {boolean}
 *
 * @memberof Autodesk.Viewing.Model
 * @alias Autodesk.Viewing.Model#isLoadDone
 */
DtModel.prototype.isLoadDone = function () {
  let data = this.getData();
  return !!(data && data.loadDone);
};

/**
 * Asynchronous operation that gets a reference to the object tree.
 *
 * You can use the model object tree to get information about items in the model.  The tree is made up
 * of nodes, which correspond to model components such as assemblies or parts.
 *
 * @param {Function} [onSuccessCallback] - Success callback invoked once the object tree is available.
 * @param {Function} [onErrorCallback] - Error callback invoked when the object tree is not found available.
 *
 * @memberof Autodesk.Viewing.Model
 * @alias Autodesk.Viewing.Model#getObjectTree
 */
DtModel.prototype.getObjectTree = function (onSuccessCallback, onErrorCallback) {
  this.loader.getObjectTree(onSuccessCallback, onErrorCallback);
};


/**
 * See also {@link Autodesk.Viewing.Model#fetchTopology|fetchTopology()}.
 * @returns {boolean} true if topology data has been downloaded and is available in memory
 *
 * @memberof Autodesk.Viewing.Model
 * @alias Autodesk.Tandem.DtModel#hasTopology
 */
DtModel.prototype.hasTopology = function () {
  return false;
};

/**
 * Returns the FragmentPointer of the specified fragId in the model.
 * This method returns null if the fragId is not passed in.
 *
 * @param {number} fragId - fragment id in the model
 * @returns {Autodesk.Viewing.Private.FragmentPointer} returns the FragmentPointer
 *
 * @alias Autodesk.Viewing.Model#getFragmentPointer
 */
DtModel.prototype.getFragmentPointer = function (fragId) {
  if (!fragId) return null;
  return new FragmentPointer(this.getFragmentList(), fragId);
};

DtModel.prototype.updateFacetInfo = function (facetInfo) {

  let svf = this.getData();

  Object.assign(svf, facetInfo);

  svf.dbId2UfClass = new FlatStringStorage(svf.dbId2UfClass);
  svf.dbId2Class = new FlatStringStorage(svf.dbId2Class);

  //Build a reverse map of xref IDs, i.e. given an xref integer ID,
  //it returns the modelID + fully qualified database ID.
  //This is used by the facets manager.
  let xrefs = facetInfo.xrefs;
  let xrefId2fullId = {};
  if (xrefs) {
    for (let modelId in xrefs) {
      let fullId2xrefId = xrefs[modelId];
      for (let fullId in fullId2xrefId) {
        let xrefId = fullId2xrefId[fullId];
        xrefId2fullId[xrefId] = [modelId, fullId];
      }
    }
  }
  svf.xrefId2fullId = xrefId2fullId;

  //Post-process the rooms list into a map for easier use by the facet manager
  svf.roomMap = svf.rooms.reduce((acc, cur) => {
    acc[cur.dbId] = cur;
    acc[cur.externalId] = cur;

    cur.model = this;
    return acc;
  }, {});

  svf.levelMap = svf.levels.reduce((acc, cur) => {
    acc[cur.dbId] = cur;
    return acc;
  }, {});

  // remove facets classifiers if facets get updated
  this.facetsClassifiers = null;
};

DtModel.prototype.getBasePath = function () {
  return endpoint.getApiEndpoint() + "/modeldata/" + this.urn() + "/";
};

DtModel.prototype.asyncPropertyOperation = function (opArgs, success, fail) {

  let usePromise = success === undefined && fail === undefined;

  if (this.propertyDbError) {
    fail && fail(this.propertyDbError);
    return usePromise ? Promise.reject(this.propertyDbError) : undefined;
  }

  let xfer = { ...this.loadContext, ...opArgs };

  //Have to reset this which gets inherited by the per-facility loadContext, so that a fresh
  // cbId is automatically assigned for this specific operation.
  xfer.cbId = undefined;
  xfer.dbPath = this.getBasePath();

  xfer.modelId = this.urn();
  xfer.twinId = this.getParentFacility().urn(); //should not be needed after the initial PDB creation happens in load()
  xfer.queryParams = this.getQueryParams();

  xfer.headers = {
    ...this.loadContext.headers,
    ...opArgs.headers
  };

  let prom = this.getParentFacility().getWorker(this.id).asyncDatabaseOperation(xfer);

  if (usePromise) {
    return prom;
  } else {
    prom.then(success).catch(fail);
  }
};

DtModel.prototype.query = async function (query) {
  return this.asyncPropertyOperation({
    "operation": WORKER_TABLE_SCAN,
    "query": query,
    "sourceFileName": this.fileName()
  }
  );
};

DtModel.prototype.search = async function (search) {
  return this.asyncPropertyOperation({
    "operation": WORKER_SEARCH_ELEMENTS,
    "search": search,
    "sourceFileName": this.fileName()
  }
  );
};

DtModel.prototype._fillTimeSeriesValues = async function (props, dbIds) {
  const multiselect = dbIds.length > 1;
  if (multiselect) {
    // set all timeseries props to "*varies*"
    for (let prop of props.element.properties) {
      if (prop.streamData) {
        prop.varies = true;
      }
    }

    return props;
  }

  const dbId = dbIds[0];
  const streamManager = this.facility?.getStreamManager();
  if (streamManager) {
    // find DB ids to check time-series data for
    const flags = this.getData().dbId2flags;
    const fetchLastReadingsFor = flags?.[dbId] === ElementFlags.Stream ? dbId : undefined;

    if (fetchLastReadingsFor) {
      const lastReadings = await streamManager.getLastReadings([fetchLastReadingsFor]);

      if (lastReadings[0] && props?.element?.properties) {
        const timeSeriesData = lastReadings[0];
        for (let prop of props.element.properties) {
          const value = timeSeriesData[prop.internalName];
          if (value !== undefined) {
            const { ts, val } = value;
            prop.displayValue = val;
            prop.timestamp = Number.parseInt(ts);
          }
        }
      }
    }
  }

  return props;
};
DtModel.prototype.getPropertiesDt = async function (dbIds) {let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let res = await this.asyncPropertyOperation({
    "operation": WORKER_GET_PROPERTIES_DT,
    "dbIds": dbIds,
    "options": options
  });

  res.model = this;
  if (this.isDefault() && options.wantTimeSeries) {
    res = await this._fillTimeSeriesValues(res, Array.isArray(dbIds) ? dbIds : [dbIds]);
  }

  return res;
};

DtModel.prototype.mutate = async function (dbIds, muts, desc, rowIds) {let skipOverrideMapping = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;let correlationId = arguments.length > 5 ? arguments[5] : undefined;

  const event = this.facility.app.waitForEvent(DT_MODEL_CHANGED_EVENT, {
    modelId: this.urn(),
    ctype: DtConstants.ChangeTypes.Mutate,
    isOwn: true
  });
  const req = this.asyncPropertyOperation({
    "operation": WORKER_MUTATE,
    "dbIds": dbIds,
    "muts": muts,
    "desc": desc,
    "rowIds": rowIds,
    "skipOverrideMapping": skipOverrideMapping,
    "correlationId": correlationId
  });

  return Promise.all([req, event]).then((_ref) => {let [reqRes, eventRes] = _ref;return reqRes;});
};

/**
 * Undo the change that happened at the given time (only works for property data changes and references)
 * TODO: add support for undeleting elements (e.g. streams)
 *
 * @param {number[]} dbIds - list of dbIds to revert
 * @param {number} timestamp - change timestamp in miliseconds
 * @param {boolean} dryRun - if true, the operation will not be executed, but the result will be returned
 * @returns {object} returns the result of a undo operation
 *
 * @alias Autodesk.Viewing.Model#undoChange
 */
DtModel.prototype.undoChange = async function (dbIds, timestamp) {let dryRun = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;let allowNonLatest = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;let correlationId = arguments.length > 4 ? arguments[4] : undefined;
  return await this.asyncPropertyOperation({
    operation: WORKER_DO_REVERT_CHANGE,
    dbIds,
    timestamp,
    dryRun,
    allowNonLatest,
    correlationId
  });
};

/**
 *
 * @param {object[]} elementsData element data with QC.LmvDbId on each element
 * @param {string} desc context the update was made in
 * @returns {Promise<{ serverResponse: *, numUpdated: number }>}
 */
DtModel.prototype.updateElementsWithData = async function (elementsData, desc, correlationId) {

  let muts = [];
  let keys = [];

  for (let i = 0; i < elementsData.length; i++) {
    const elmData = elementsData[i];

    let dbId = elmData[QC.LmvDbId];
    if (!dbId || typeof dbId !== "number") {
      return Promise.reject("Missing element dbId");
    }

    for (let key in elmData) {
      if (key === QC.RowKey || key === QC.LmvDbId) {
        continue;
      }
      muts.push([key, elmData[key]]);
      keys.push(dbId);
    }
  }

  return this.mutate(keys, muts, desc, undefined, undefined, correlationId);
};

/**
 * Creates a new element in the model
 * Allows passing both Direct and Overwrite columns, QC.RowKey will be generated if not present
 * @param {object[]} elementsData element data
 * @param {string} desc context the update was made in
 * @returns {Promise<string[]>} new element ids
 */
DtModel.prototype.createElementsWithData = async function (elementsData, desc) {

  let muts = [];
  let keys = [];

  for (let i = 0; i < elementsData.length; i++) {
    const elmData = elementsData[i];

    let flags = elmData[QC.ElementFlags];

    if (typeof flags !== "number") {
      return Promise.reject("Element flags must be specified.");
    }

    let rowKey = elmData[QC.RowKey];

    if (rowKey && rowKey.length !== 32) {
      return Promise.reject("Element key must be 24 bytes (32 characters base64 encoded).");
    }

    if (!rowKey) {
      rowKey = makeNewEncodedElementID(flags & ElementFlags.AllLogicalMask);
    }

    for (let key in elmData) {
      if (key === QC.RowKey || key === QC.LmvDbId) {
        continue;
      }
      muts.push([key, elmData[key]]);
      keys.push(rowKey);
    }
  }

  const event = this.facility.app.waitForEvent(DT_MODEL_CHANGED_EVENT, {
    modelId: this.urn(),
    ctype: DtConstants.ChangeTypes.Mutate,
    isOwn: true
  });
  const req = this.asyncPropertyOperation({
    "operation": WORKER_CREATE_ELEMENTS,
    "muts": muts,
    "desc": desc,
    "rowIds": keys
  });

  const { newIds } = await Promise.all([req, event]).then((_ref2) => {let [reqRes, eventRes] = _ref2;return reqRes;});
  return newIds;
};

/**
 * @typedef ModelAlignment
 * @type {object}
 * @property {Array} transform - Array representation of the transformation matrix.
 * @property {string} checksum - Checksum of the alignment content.
 */

/**
 * Obtains requested model alignment, if present.
 * @returns {Promise<ModelAlignment|undefined>}
 */
DtModel.prototype.getAlignment = async function () {
  return (await this.getModelProperties()).dataSource?.alignment;
};
/**
 * Persists model alignment, set null to remove.
 * @param {ModelAlignment|null} alignment
 */
DtModel.prototype.saveAlignment = async function (alignment) {
  const curAlign = await this.getAlignment();
  try {
    const headers = { etag: curAlign?.checksum || '' };
    await fetchJson(this.loadContext, `/models/${this.urn()}/alignment`, 'PUT', alignment, headers);

    if (alignment) this.modelProps.dataSource.alignment = alignment;else
    delete this.modelProps.dataSource.alignment;

  } catch (e) {
    if (e.status === 412) {
      // etag was out of date, indicating our current alignment is outdated.
      await this._getModelProperties();
      throw new Error('outdated');
    }

    console.warn('Error while persisting model alignment', e, alignment);
    throw new Error(`Error while persisting model alignment for : ${this.urn()}`);
  }
};

DtModel.prototype.getHistory = async function (timestamps, min, max, includeChanges) {
  return this.asyncPropertyOperation({
    "operation": WORKER_HISTORY,
    "timestamps": timestamps,
    "max": max,
    "min": min,
    "includeChanges": includeChanges
  });
};

/**
 * Level is a logical element representing a floor.
 * @typedef {Object} Level
 * @property {Number} dbId Element DB ID
 * @property {Number} elev Level elevation, expressed in the model's distance unit
 * @property {String} externalId Element ID with flags
 * @property {DtModel} model Host model
 * @property {String} name Name
 * @property {Number} order Synthetic order based on element ID
 * @property {String} originalName Name as found in the original document
 */

/**
 * Levels by dbId.
 *
 * @alias Autodesk.Tandem.DtModel#getLevels
 * @returns {?Object.<Number, Level>} Levels by dbId.
 */
DtModel.prototype.getLevels = function () {
  return this.getData()?.levelMap;
};

/**
 * Returns rooms for this model.
 *
 * @returns {Object.<number, object>} Map of room objects by dbId.
 *
 * @alias Autodesk.Tandem.DtModel#getRooms
 */
DtModel.prototype.getRooms = function () {
  return this.getData()?.roomMap;
};

/**
 * Checks if given id is a room.
 *
 * @param {number} dbId
 *
 * @returns {boolean}
 *
 * @alias Autodesk.Tandem.DtModel#isRoom
 */
DtModel.prototype.isRoom = function (dbId) {
  return !!this.getData().roomMap?.[dbId];
};

/**
 * Checks if model has rooms.
 *
 * @returns {boolean}
 *
 * @alias Autodesk.Tandem.DtModel#hasRooms
 */
DtModel.prototype.hasRooms = function () {
  return !!this.getData()?.rooms?.length;
};


/**
 * Returns rooms which contain the given element.
 *
 * @param {number} elementId
 * @param {string} [refModelByUrn]
 *
 * @returns {Array.<object>}
 *
 * @alias Autodesk.Tandem.DtModel#getRoomsOfElement
 */
DtModel.prototype.getRoomsOfElement = function (elementId, refModelByUrn) {
  const pkg = this.getData();

  const result = new Set();

  // rooms within the same model
  let roomIds = pkg.dbId2roomIds[elementId];
  if (roomIds) {
    const rooms = this.getRooms();

    if (!(roomIds instanceof Array)) {
      roomIds = [roomIds];
    }
    for (const roomId of roomIds) {
      const room = rooms[roomId];
      if (!room) {
        //console.warn(`Room element ${roomId} not found. Skipping room reference.`);
        continue;
      }
      result.add(room);
    }
  }

  // xref'ed rooms
  let xrefs = pkg.dbId2xroomIds[elementId];
  if (xrefs) {
    let fullIds;
    if (xrefs instanceof Array) {
      fullIds = xrefs.map((xref) => pkg.xrefId2fullId[xref]);
    } else if (xrefs > 0) {
      fullIds = [pkg.xrefId2fullId[xrefs]];
    }

    for (const [urn, roomId] of fullIds) {
      if (!(urn in refModelByUrn)) {
        //console.warn(`Model '${urn}' not found. Skipping room reference.`);
        continue;
      }
      const m = refModelByUrn[urn];
      const rooms = m.getRooms();
      if (!rooms) {
        //console.warn(`Model '${urn}' not loaded. Skipping room reference.`);
        continue;
      }

      const room = rooms[roomId];
      if (!room) {
        console.warn(`Room element ${roomId} not found in '${urn}. Skipping room reference.`);
        continue;
      }
      result.add(rooms[roomId]);
    }
  }

  return Array.from(result);
};

DtModel.prototype.getElementRoomsHistory = function (dbId) {
  if (!!dbId && Number.isFinite(dbId)) {
    return this.asyncPropertyOperation({
      "operation": WORKER_GET_ELEMENT_ROOMS_HISTORY,
      "dbId": dbId
    });
  }
};

/**
 * Checks if this model has external references to a model with the given URN.
 *
 * @param {string} urn - URN of the model
 * @returns {boolean} True if this model has external references to a model with the given URN.
 *
 * @alias Autodesk.Tandem.DtModel#hasExternalRefs
 */
DtModel.prototype.hasExternalRefs = function (urn) {
  let xrefs = this.getData().xrefs;

  if (!xrefs) {
    return false;
  }

  return !!xrefs[urn];
};

DtModel.prototype.getHash2Attr = async function () {
  let res = await this.asyncPropertyOperation({
    "operation": WORKER_GET_HASH_ATTR
  });

  let res2 = {};

  //Restore AttributeDef object prototype after transfer through the web worker boundary
  for (let attrId in res) {
    let attrData = res[attrId];
    let withType = Object.create(AttributeDef.prototype);
    res2[attrId] = Object.assign(withType, attrData);
  }

  return res2;
};

DtModel.prototype.loadElements = function (ids) {
  return this.loader.loadFragmentsForElements(ids);

};

DtModel.prototype.loadAllElements = function () {
  this.loader.loadFragmentsForElements(null);
};

DtModel.prototype.unloadElements = function (ids) {
  this.loader.unloadFragmentsForElements(ids);
};

DtModel.prototype.unloadAllElements = function () {
  this.loader.unloadFragmentsForElements(null);
};

DtModel.prototype.getElementsForLevel = function (levelDbId) {

  let l2d = this.getData().dbId2levelId;
  let ids = [];

  for (let i = 0; i < l2d.length; i++) {
    if (l2d[i] === levelDbId) {
      ids.push(i);
    }
  }
  return ids;
};

DtModel.prototype.loadElementsForLevel = function (levelDbId) {

  let ids = this.getElementsForLevel(levelDbId);
  this.loadElements(ids);
};

DtModel.prototype.loadRoomFragments = function () {

  let roomDbIds = this.getData().rooms.map((cur) => cur.dbId);

  if (roomDbIds.length > 0) {
    return this.loadElements(roomDbIds);
  }
};

DtModel.prototype._getModelProperties = async function () {
  this.modelProps = await fetchJson(this.loadContext, `/models/${this.urn()}/props`);
  return this.modelProps;
};
/**
 * @returns Information about the model's source data file and a subset of the original design's properties
 */
DtModel.prototype.getModelProperties = async function () {
  return this.getModelPropertiesPromise || (this.getModelPropertiesPromise = this._getModelProperties());
};

DtModel.prototype.invalidateModelProperties = function () {
  this.getModelPropertiesPromise = this.modelProps = null;
};

DtModel.prototype.setDataSource = async function (_ref3) {let { fileName, urn, docsProjectId, docsAccountId, sourceAttributeMap } = _ref3;
  await fetchJson(this.loadContext, `/models/${this.urn()}/datasource`, 'PUT', {
    fileName,
    urn,
    docsProjectId,
    docsAccountId,
    sourceAttributeMap
  });

  this.invalidateModelProperties();
};

/**
 * Returns file name of this model.
 *
 * @returns {string}
 *
 * @alias Autodesk.Tandem.DtModel#fileName
 */
DtModel.prototype.fileName = function () {
  if (this.isDefault()) {
    return i18n.t("(Tandem hosted)");
  }
  return this.modelProps?.dataSource?.fileName;
};

/**
 * Returns user friendly name of this model.
 *
 * @returns {string}
 *
 * @alias Autodesk.Tandem.DtModel#displayName
 */
DtModel.prototype.displayName = function () {
  // We shouldn't use strings returned by the server directly in the UI (at some point we would have to translate it anyway),
  // therefore check the label here.
  // Looks like we do only have one such string for now. Is it worth to create something like "resources/server_strings.json" file
  // and put it there?
  if (this.isDefault()) {
    return i18n.t("(Tandem hosted)");
  }

  return this.label() || this.fileName();
};

/**
 * @param {boolean} waitForFragments
 * @param {boolean} waitForInstanceTree
 */
DtModel.prototype.waitForLoad = async function (waitForFragments, waitForInstanceTree) {
  const promises = [undefined, undefined];

  // wait until the loading queue kicked off loading of this model (root loaded)
  if (this._loadPromise) {
    await this._loadPromise.promise;
  } else {
    return Promise.reject(`${this.urn()} Failed to wait for model. Model loading was aborted or did never start.`);
  }

  // Loader.dtor could have been called by the time it gets to this promise
  if (!this.loader) {
    return Promise.reject(`${this.urn()} Failed to wait for model. Missing loader.`);
  }


  if (waitForInstanceTree) {
    promises[1] = new Promise((resolve, reject) => {
      this.loader.getObjectTree(resolve, reject);
    });
  }

  if (waitForFragments) {
    promises[0] = new Promise((resolve, reject) => {
      if (this.loader.fragmentsLoaded) {
        resolve();
      }

      const cb = (e) => {
        if (e.model !== this) {
          return;
        }
        this.loader?.viewer3DImpl.api.removeEventListener(et.MODEL_ROOT_LOADED_EVENT, cb);
        resolve();
      };

      this.loader.viewer3DImpl.api.addEventListener(et.MODEL_ROOT_LOADED_EVENT, cb);
    });
  }
  return Promise.all(promises);
};

DtModel.prototype.getCustomFacets = async function (attributeHashes) {
  return this.asyncPropertyOperation({
    "operation": WORKER_GET_CUSTOM_FACETS,
    "attributeHashes": attributeHashes
  });
};

DtModel.prototype.getAttributes = async function (options) {
  return this.asyncPropertyOperation({
    "operation": WORKER_GET_ATTRIBUTES,
    "options": options
  });
};

/**
 * Returns element ids from viewer dbIds.
 *
 * @param {Array.<number>} dbIds
 * @returns {Promise<Array.<string>>}
 *
 * @alias Autodesk.Tandem.DtModel#getElementIdsFromDbIds
 */
DtModel.prototype.getElementIdsFromDbIds = async function (dbIds) {
  return this.asyncPropertyOperation({
    "operation": WORKER_GET_ELEMENT_IDS_FROM_DB_IDS,
    "dbIds": dbIds
  });
};

/**
 * Returns viewer dbIds for the given element ids.
 *
 * @param {Array.<string>} elementIds
 * @returns {Promise<Array.<number>>}
 *
 * @alias Autodesk.Tandem.DtModel#getDbIdsFromElementIds
 */
DtModel.prototype.getDbIdsFromElementIds = async function (elementIds) {
  return this.asyncPropertyOperation({
    "operation": WORKER_GET_DB_IDS_FROM_ELEMENT_IDS,
    "elementIds": elementIds
  });
};

DtModel.prototype.canEdit = function () {
  switch (this.accessLevel()) {
    case 'ReadWrite':
    case 'Manage':
    case 'Owner':
      return true;
  }
  return false;
};

DtModel.prototype.getUsageMetrics = function (startDate, endDate) {

  if (!startDate) {
    return Promise.reject();
  }

  let query = `start=${startDate}`;
  if (endDate) {
    query += `&end=${endDate}`;
  }

  return fetchJson(this.loadContext, `/admin/models/${this.urn()}/metrics?${query}`);
};

DtModel.prototype.isLoaded = function () {
  return !!this.myData;
};

/**
 * Computes Bounding box of all fragments, but excluding outliers.
 * @param {Object} [options]
 * @param {float}  [options.quantil=0.75]       - in [0,1]. Relative amount of fragments that we consider computation.
 *                                                By default, we consider the 75% of fragments that are closest to the center.
 * @param {float}  [options.center]             - Center from which we collect the closest shapes. By default, we use the center of mass.
 * @param {Set<number>}   [options.allowlist]   - Optional: Fragments to include in fuzzybox, by index.
 * @param {boolean} [options.useOriginalBounds] - Optional: Use the original (as loaded) fragments world box. Such that changes
 *                                              - to object position like explode/animation or model matrix are not taken into account.
 * @returns {THREE.Box3}
 */
DtModel.prototype.getFuzzyBox = function () {let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  let frags = this.getFragmentList();
  const _tmpBox = new Float32Array(6);

  function getFragBounds(fragId, dstBox) {
    if (options.useOriginalBounds) {
      frags.getOriginalWorldBounds(fragId, _tmpBox);
      dstBox.min.set(_tmpBox[0], _tmpBox[1], _tmpBox[2]);
      dstBox.max.set(_tmpBox[3], _tmpBox[4], _tmpBox[5]);
    } else {
      frags.getWorldBounds(fragId, dstBox);
    }
  }

  function centerOfMass() {

    let box = new THREE.Box3();
    let center = new THREE.Vector3();
    let size = new THREE.Vector3();
    let total = new THREE.Vector3();
    let mass = 0;

    for (let i = 0; i < frags.getCount(); i++) {
      if (options.allowlist && !options.allowlist.has(i)) {
        continue;
      }

      // get bbox center
      getFragBounds(i, box);
      box.getCenter(center);

      // sum centers weighted by bbox size
      let weight = box.size(size).length();
      total.add(center.multiplyScalar(weight));

      mass += weight;
    }

    total.multiplyScalar(1 / mass);
    return total;
  }

  let center = options.center || centerOfMass();
  let quantil = options.quantil || 0.75;

  let fragBox = new THREE.Box3();
  let pt = new THREE.Vector3();

  // Compute distances of each frag bbox from center
  let fragInfos = [];
  for (let i = 0; i < frags.getCount(); i++) {
    if (options.allowlist && !options.allowlist.has(i)) {
      continue;
    }

    // Skip any empty boxes
    getFragBounds(i, fragBox);
    if (fragBox.isEmpty()) {
      continue;
    }

    // get fragBox->center distance
    let dist = fragBox.distanceToPoint(center);

    // If fragBox contains the center, use fragBox center.
    if (dist === 0) {
      dist = center.distanceTo(fragBox.getCenter(pt));
    }

    fragInfos.push({
      fragId: i,
      distance: dist
    });
  }

  // sort by increasing order
  fragInfos.sort(function (a, b) {
    return a.distance - b.distance;
  });

  // union of all fragBoxes, excluding the ones with largest distance to center
  let box = new THREE.Box3();
  for (let i = 0; i < fragInfos.length * quantil; i++) {
    let fi = fragInfos[i];
    getFragBounds(fi.fragId, fragBox);
    box.union(fragBox);
  }
  return box;
};

/**
 * Returns a list of ids of either all physical elements in this model (default)
 * or of a elements matching a specific ElementFlag (see worker/dt-schema.js line 80+)
 * @param {ElementFlags} [elementFlag = undefined]
 * @returns {Array<number>}
 */
DtModel.prototype.getElementIds = function () {let elementFlag = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  let ids = [];
  const dbId2flags = this.getData().dbId2flags;
  for (let id = 1; id < dbId2flags.length; ++id) {
    let flags = dbId2flags[id];
    if (elementFlag === undefined ? !(flags & KeyFlags.Logical) : flags === elementFlag) {
      ids.push(id);
    }
  }
  return ids;
};

/**
 * Returns a list of the dbIds of all currently visible elements
 */
DtModel.prototype.getVisibleDbIds = function () {
  const visibleDbIds = [];
  const it = this.getInstanceTree();
  const fl = this.getFragmentList();
  const count = fl.getCount();

  for (let fragId = 0; fragId < count; fragId++) {
    if (!fl.isFragOff(fragId) && fl.isFragVisible(fragId) && !!fl.getGeometry(fragId)) {
      const dbId = fl.getDbIds(fragId);
      if (!it.isNodeHidden(dbId) && !it.isNodeOff(dbId)) {
        visibleDbIds.push(dbId);
      }
    }
  }

  return visibleDbIds;
};

DtModel.prototype.subscribeToEvents = function () {
  if (!this.subscribed) {
    this.getParentFacility().app.subscribeToEvents(this);
    this.subscribed = true;
  }
};

DtModel.prototype.unsubscribeFromEvents = function () {
  if (this.subscribed) {
    this.getParentFacility().app.unsubscribeFromEvents(this);
    this.subscribed = false;
  }
};

DtModel.prototype.disposePropertyWorker = function () {

  this.unsubscribeFromEvents();

  this.asyncPropertyOperation({
    "operation": WORKER_UNLOAD_PROPERTYDB
  }).catch(() => {
  });
};

DtModel.prototype.dispose = function () {
  const instanceTree = this.getInstanceTree();
  instanceTree?.dtor();

  this.myData = null;

  this.disposePropertyWorker();
};

DtModel.prototype.reloadAttributes = async function () {
  return this.asyncPropertyOperation({
    "operation": WORKER_RELOAD_ATTRIBUTES
  });
};

DtModel.prototype.getTaggedAssets = async function () {
  const dbIds = this.getElementIds();
  return this.query({
    dbIds,
    includes: { type: true, applied: true },
    filter: {
      [DtConstants.ColumnFamilies.DtProperties]: {
        existsInRaw: true
      }
    }
  });
};

DtModel.prototype.getClassifiedAssets = async function () {
  const isUniformat = this.facility.settings.template.getClassificationID() === DtConstants.UNIFORMAT_UUID;
  const { dbId2Class, dbId2flags, dbId2UfClass } = this.myData;

  const dbIds = [];
  const classifiedDict = isUniformat ? dbId2UfClass : dbId2Class;
  for (let id = 1; id < dbId2flags.length; ++id) {
    if (!classifiedDict.hasById(id)) continue;

    const flags = dbId2flags[id];
    if ((flags & KeyFlags.Logical) === 0 || flags === ElementFlags.Stream || flags === ElementFlags.GenericAsset) {
      dbIds.push(id);
    }
  }

  const classColumn = isUniformat ? QC.UniformatClass : QC.Classification;
  return this.query({
    dbIds,
    includes: { type: true, applied: true },
    strict: true,
    filter: {
      [classColumn]: {
        notEmpty: true
      }
    }
  });
};

DtModel.prototype.isWorldBBoxSet = function () {
  return this.getData()?.metadata["world bounding box"].minXYZ != null;
};

DtModel.prototype.resetWorldBBoxForDefaultModel = async function () {
  if (!this.isDefault()) {
    return;
  }

  const mainModel = this.facility.getPrimaryModel();
  if (!mainModel) {
    throw new Error("Main model is missing");
  }

  let metadata = this.getData()?.metadata;
  if (!metadata) {
    throw new Error("Metadata for the default model is missing");
  }

  const worldBBox = mainModel.getData().metadata["world bounding box"];
  const distanceUnit = mainModel.getData().metadata["distance unit"];
  const customValues = mainModel.getData().metadata["custom values"];
  const georeference = mainModel.getData().metadata.georeference;

  metadata["world bounding box"] = worldBBox;
  metadata["distance unit"] = distanceUnit;
  metadata["custom values"] = customValues;
  metadata.georeference = georeference;

  await fetchJson(this.loadContext, `/modeldata/${this.urn()}/modelroot`, "POST", metadata);
};

DtModel.prototype.createGeometry = async function (geometry) {
  if (!this.isDefault()) {
    throw new Error("Creation of geometries is only supported on default models");
  }

  const modelId = this.urn();

  const otgGeom = serializeLmvBufferGeom(geometry);

  const res = await fetchJson(this.loadContext, `/modeldata/${modelId}/meshes`, 'POST', otgGeom);
  console.log(res);
  return res;
};

/**
 * @param {string[]}  - a list of element keys -- fullId with element flag
 */
DtModel.prototype.deleteElements = async function (elmKeys, desc) {
  return this.asyncPropertyOperation({
    "operation": WORKER_DELETE_ELEMENT,
    "data": elmKeys,
    desc
  });
};

DtModel.prototype.getElementCount = function () {
  return this.myData.dbId2flags.length;
};

DtModel.prototype.getElementUfClass = function (dbId) {
  return this.myData.dbId2UfClass.getById(dbId);
};

DtModel.prototype.getElementCustomClass = function (dbId) {
  return this.myData.dbId2Class.getById(dbId);
};

/**
 * Load the meta data from the specified model in the facility.
 * @param {DtModel} model the specified model object.
 */
DtModel.prototype.loadMetaData = async function () {
  return new Promise((resolve, reject) => {
    let modelRootUrl = endpoint.getApiEndpoint() + "/modeldata/" + this.urn() + "/model";
    DtPackage.loadAsyncResource(this.loadContext, modelRootUrl, "json", function (data) {
      resolve(data);
    }, reject);
  });
};

/**
 * Fetches the mapping of dbId to connections, both internally and externally
 * @param forceReload fetches data from the server bypassing cache
 * @returns {Array} the internal and external mappings in an array of format [dbId2XConns, resolvedRefs] where
 * dbId2XConns is of format {dbId: [urn, ref], ...} and resolvedRefs is {dbId: ref}
 */
DtModel.prototype.getDbIdToConns = async function (dbIds, systemID, forceReload) {
  if (!systemID || !systemID.length) {
    throw new Error("Missing System ID");
  }

  return await this.asyncPropertyOperation({
    "operation": WORKER_GET_DB_ID_TO_CONNS,
    "dbIds": dbIds || this.getElementIds(),
    systemID,
    forceReload
  });
};

DtModel.prototype.getAllSystemClasses = function () {
  const data = this.getData();
  if (!data) {
    return null;
  }

  return data.systemClasses;
};

/**
 * Returns a bounding box containing every fragment of this element.
 * @param {number} dbId Element dbId
 * @param {THREE.Box3} dstBounds Optional destination box for bounds.
 * @return {THREE.Box3} Element's Bounds
 */
DtModel.prototype.getElementBounds = function (dbId) {let dstBounds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new THREE.Box3();
  const it = this.getInstanceTree();
  const fragList = this.getFragmentList();

  dstBounds.makeEmpty();
  it.enumNodeFragments(
    dbId,
    (fragId) => {
      fragList.getWorldBounds(fragId, _tmpBox);
      dstBounds.union(_tmpBox);
    },
    true);

  return dstBounds;
};

/**
 * @throws Non default model
 * @returns {string} Next increment of a logical system element key
 */
DtModel.prototype.getNewSystemElementKey = async function () {

  if (!this.isDefault()) {
    throw new Error('Invalid operation: MEPSystems are only stored in the default model.');
  }

  return this.asyncPropertyOperation({ operation: WORKER_GET_NEW_MEPSYSTEM_KEY });
};

/**
 * @throws Non default model
 * @returns {*} Object containing all non-deleted logical system elements, keyed by SystemID
 */
DtModel.prototype.getLogicalSystemElements = async function () {

  if (!this.isDefault()) {
    throw new Error('Invalid operation: MEPSystems are only stored in the default model.');
  }

  return this.asyncPropertyOperation({ operation: WORKER_GET_MEPSYSTEMS });
};

/** Creates a set of proxy mesh for the node. Useful when adding an element to an overlay. */
DtModel.prototype.getProxyMeshes = function (dbId) {
  const tree = this.getInstanceTree();
  const fragments = this.getFragmentList();

  if (!tree || !fragments) {
    console.log('No meshes as model is not yet loaded');
    return [];
  }

  const meshes = [];
  tree.enumNodeFragments(dbId, (fragId) => {
    const mesh = fragments.getVizmesh(fragId, false);
    if (!mesh.geometry) {
      console.log('Geometry is missing when building proxy mesh.');
      return;
    }

    const highlightProxy = new THREE.Mesh(mesh.geometry, mesh.material);
    highlightProxy.matrix.copy(mesh.matrixWorld);
    highlightProxy.matrixAutoUpdate = false;
    highlightProxy.matrixWorldNeedsUpdate = true;
    highlightProxy.frustumCulled = false;
    highlightProxy.model = this;
    highlightProxy.fragId = fragId;
    highlightProxy.dbId = dbId;

    meshes.push(highlightProxy);
  });

  return meshes;
};

/** Creates a bounding volume hierarchy of rooms to accelerate ray-casting.
 *
 * Note: the caller needs to make sure room geometries are loaded before
 * calling this method.
*/

DtModel.prototype.buildRoomBvh = function () {
  if (this.cbvh) {
    return;
  }

  let boundingVolumeHierarchy = new ConsolidatedBVH(this);

  boundingVolumeHierarchy.build();

  this.cbvh = boundingVolumeHierarchy;
};

/**
 * Cached consolidated volume hierarchy of rooms.
 * @return {ConsolidatedBVH}
 */
DtModel.prototype.getConsolidatedBVH = function () {
  return this.cbvh;
};