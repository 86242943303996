import THREE from "three";

const tmpVec2d = new THREE.Vector2();
const tmpVec2d2 = new THREE.Vector2();

// Collection of simple helper functions for 2D math functions.

// Return normalized edge direction vector (b-a).normalized
const getEdgeDirection = (a, b, target) => {
  target = target || new THREE.Vector2();

  return target.copy(b).sub(a).normalize();
};

const getEdgeCenter = (a, b, target) => {
  target = target || new THREE.Vector2();

  return target.set(0.5 * (a.x + b.x), 0.5 * (a.y + b.y));
};

// Get edge length. (a, b) can just be {x, y} pairs, i.e., not required to be THREE.Vector2
const getEdgeLength = (a, b) => {
  const dx = b.x - a.x;
  const dy = b.y - a.y;
  return Math.sqrt(dx * dx + dy * dy);
};

// Rotates direction vector p 90 degrees to the left. (in-place)
const turnLeft = (p) => {
  const tmp = p.x;
  p.x = -p.y;
  p.y = tmp;
  return p;
};

// Projects a point p to a line. Works in-place
//  @param {Vector2} p
//  @param {Vector2} linePoint - point on the line
//  @param {Vector2} lineDir   - line direction. Must be normalized
const projectToLine = (p, linePoint, lineDir) => {

  // dp = dot(p-linePoint, lineDir)
  const dp = (p.x - linePoint.x) * lineDir.x + (p.y - linePoint.y) * lineDir.y;

  // return linePoint + lineDir * dp
  p.set(
    linePoint.x + dp * lineDir.x,
    linePoint.y + dp * lineDir.y
  );
};

// Get distance between the point p and a line given by point and direction.
//  @param {Vector2} p
//  @param {Vector2} linePoint - point on the line
//  @param {Vector2} lineDir   - line direction. Must be normalized
const pointLineDistance = function () {
  let pProj = new THREE.Vector2();
  return (p, linePoint, lineDir) => {
    projectToLine(pProj.copy(p), linePoint, lineDir);
    return p.distanceTo(pProj);
  };
}();

// Calculates the intersection point of both given lines
// assumes that the lines are not parallel
// see: http://www.paulbourke.net/geometry/pointlineplane/
const intersectLines = (linePoint1, lineDir1, linePoint2, lineDir2, outPoint) => {

  const denom = lineDir2.y * lineDir1.x - lineDir2.x * lineDir1.y;
  if (Math.abs(denom) < 1.0e-8) {return false;}

  // diff = linePoint1 - linePoint2
  const diffX = linePoint1.x - linePoint2.x;
  const diffY = linePoint1.y - linePoint2.y;

  const u = lineDir2.x * diffY - lineDir2.y * diffX;

  if (outPoint) {
    outPoint.x = linePoint1.x + u / denom * lineDir1.x;
    outPoint.y = linePoint1.y + u / denom * lineDir1.y;
  }
  return true;
};

// Rotate a vector p around origin or a given center. Works in-place.
//  @param {Vector2} p
//  @param {number}  angle in radians
//  @param [Vector2] center
const rotateAround = (p, angle, center) => {

  let c = Math.cos(angle);
  let s = Math.sin(angle);

  if (center) {
    p.sub(center);
  }

  let x = p.x;
  let y = p.y;

  p.x = x * c - y * s;
  p.y = x * s + y * c;

  if (center) {
    p.add(center);
  }
  return p;
};

//  @param {Vector2} dir1, dir2           - No normalization required.
//  @returns {number} result in [0, 2*Pi] - clockwise angle in radians that you have to apply to rotate dir2 into dir1.
const angleBetweenDirections = (dir1, dir2) => {

  // get angle formed with positive x-axis.
  // angle1/2 are in [-Pi, Pi]
  const angle1 = Math.atan2(dir1.y, dir1.x);
  const angle2 = Math.atan2(dir2.y, dir2.x);

  // Difference is in [-2*Pi, 2*Pi]
  let angle = angle1 - angle2;

  // Map result to [0, 2*Pi] range
  if (angle < 0) angle += 2 * Math.PI;

  return angle;
};

// see isPointOnEdge
const isPointOnLine = (p, a, b, precision) => {
  return isPointOnEdge(p, a, b, precision, false);
};

// Returns true if p lies close to the edge (p1, p2).
const isPointOnEdge = function (p, a, b, precision) {let checkInsideSegment = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;

  // Compute edge length
  const dx = b.x - a.x;
  const dy = b.y - a.y;
  const length = Math.sqrt(dx * dx + dy * dy);

  let e = {
    v1: a,
    dx: dx,
    dy: dy,
    length: length,
    length2: length * length
  };
  return Autodesk.Extensions.CompGeom.pointOnLine(p.x, p.y, e, checkInsideSegment, precision);
};

const pointDelta = function (a, b) {let digits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  let delta = { x: b.x - a.x, y: b.y - a.y };
  if (digits)
  {
    let exp = Math.pow(10, digits);
    delta.x = Math.round(delta.x * exp) / exp;
    delta.y = Math.round(delta.y * exp) / exp;
  }
  if (!delta.x && !delta.y) {
    return;
  }
  return delta;
};

const edgeIsDegenerated = function (a, b) {let eps2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1.0e-10;
  return a.distanceToSquared(b) < eps2;
};

// Compute target point resulting from mirroring point p
// on the given center point c.
const mirrorPointOnPoint = function (p, c) {let target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
  target = target || new THREE.Vector2();
  target.x = c.x - (p.x - c.x);
  target.y = c.y - (p.y - c.y);
  return target;
};

const fuzzyEqual = (a, b, precision) => {
  return Math.abs(a - b) < precision;
};

// Checks if two lines are collinear.
//  @param {Vector2} p1, dir1 - First line, given as point and normalized direction.
//  @param {Vector2} p2, dir2 - Second line
//  @param {number}  precision
//  @returns {bool}
const collinear = (p1, dir1, p2, dir2, precision) => {

  // Directions must be either equal or opposite
  const dirEqual = fuzzyEqual(dir1.x, dir2.x, precision) && fuzzyEqual(dir1.y, dir2.y, precision);
  const dirOpposite = fuzzyEqual(dir1.x, -dir2.x, precision) && fuzzyEqual(dir1.y, -dir2.y, precision);
  if (!dirEqual && !dirOpposite) {
    return false;
  }

  // Directions are equal or opposite => Lines are collinear if and only if p2 is on line (p1, dir1).
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  let dot = dx * dir1.x + dy * dir1.y;
  return Math.abs(dot) < precision;
};

// same as p1.distanceTo(p2), but working for any {x,y} object.
const distance2D = (p1, p2) => {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  return Math.sqrt(dx * dx + dy * dy);
};

// Checks if a matrix changes the orientation.
//  @param {Matrix4} matrix
const changesOrientation = (matrix) => {
  return matrix.determinant() < 0;
};

// Compute a matrix that transforms fromBox into toBox.
//
//  @param {Box2}    fromBox
//  @param {Box2}    toBox
//  @param {Object}  [options]
//  @param {bool}    [options.flipY]           - include y-axis flip
//  @param {bool}    [options.preserveAspect]  - force uniform scaling (m * fromBox might be smaller than toBox in one axis)
//  @param {Matrix4} [target]
const getFitToBoxTransform = function (fromBox, toBox) {let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};let target = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : new THREE.Matrix4();
  const fromSize = fromBox.size(tmpVec2d);
  const toSize = toBox.size(tmpVec2d2);

  // compute scale
  let sx = toSize.x / fromSize.x;
  let sy = toSize.y / fromSize.y;

  // preserveAspect
  if (options.preserveAspect) {
    sx = Math.min(sx, sy);
    sy = sx;
  }

  // anchor is the point of fromBox that will be mapped to toBox.min
  let anchorX = fromBox.min.x;
  let anchorY = fromBox.min.y;

  // apply optional y-flip
  if (options.flipY) {
    sy *= -1;
    anchorY = fromBox.max.y;
  }

  // compute translation: after scaling, anchor should move to toBox.min
  let tx = -sx * anchorX + toBox.min.x;
  let ty = -sy * anchorY + toBox.min.y;

  // Create (scale + translate)-matrix.
  const matrix = target.makeScale(sx, sy, 1.0);
  matrix.elements[12] = tx;
  matrix.elements[13] = ty;

  return matrix;
};

export const Math2D = {
  getEdgeDirection,
  projectToLine,
  pointLineDistance,
  intersectLines,
  rotateAround,
  angleBetweenDirections,
  getEdgeCenter,
  getEdgeLength,
  turnLeft,
  isPointOnEdge,
  isPointOnLine,
  pointDelta,
  edgeIsDegenerated,
  mirrorPointOnPoint,
  fuzzyEqual,
  collinear,
  distance2D,
  changesOrientation,
  getFitToBoxTransform
};