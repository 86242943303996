// Import al CSS files that bundle into `styles.css`

// Themes
import "./gui/themes/DarkTheme.css";
import "./gui/themes/DarkThemeHyperlink.css";
//DT import "./gui/themes/Fonts.css";
import "./gui/themes/LightTheme.css";
import "./gui/themes/LightThemeHyperlink.css";
//DT import "./gui/themes/BimTheme.css";
//DT import "./gui/themes/AcsTheme.css";

//DT import "./gui/icon-fonts/header.css";
//DT import "./gui/icon-fonts/codes.css";
import "./gui/icon-fonts/measure-icons.css";
import "./gui/icon-fonts/prooflab-embedded.css";
import "./gui/AlertBox.css";
import "./gui/HudMessage.css";
import "./gui/ContextMenu.css";
import "./gui/Panel.css";
import "./gui/ProgressBar.css";
import "./gui/DockingPanel.css";
import "./gui/SettingsPanel.css";
import "./gui/ViewerSettingsPanel.css";
import "./gui/toolbar/ToolBar.css";
import "./gui/controls/Control.css";
import "./gui/controls/ControlGroup.css";
import "./gui/controls/Button.css";
import "./gui/controls/ComboButton.css";
import "./gui/controls/Filterbox.css";
import "./application/Viewer3D.css";
import "./gui/GuiViewer3D.css";
import "./gui/CommonWidgets.css";
import "./gui/ContinuousScale.css";
import "./gui/StatusBar.css";

import "./dt/floorplans/floorplan.css";
import "./dt/DtViewerState.css";
import "./dt/hud/layers/levels/LevelsLayer.css";
import "./dt/hud/layers/spaces/SpacesLayer.css";
import "./dt/streams/streams.css";
import "./dt/streams/HeatmapConfigUi.css";
import "./dt/sketchtools/SketchToolsToolbar.css";