import { wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";

export function getTextureDeclaration(bindGroup) {
  return wgsl /*wgsl*/`

	// Contains either the H/V texture or the main/color target, depending on the pipeline
	@group(${bindGroup}) @binding(0) var map: texture_2d<f32>;
	@group(${bindGroup}) @binding(1) var smpl: sampler;
	`;
}

export function getTextureBindGroupLayout(device) {
  return device.createBindGroupLayout({
    label: 'ground shadow texture bind group layout',
    entries: [
    {
      binding: 0,
      visibility: GPUShaderStage.FRAGMENT,
      texture: {
        sampleType: 'float'
      }
    },
    {
      binding: 1,
      visibility: GPUShaderStage.FRAGMENT,
      sampler: {}
    }]

  });
}

export function getTextureBindGroup(device, layout, sampler1, view1) {let label = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'ground shadow texture bind group';
  return device.createBindGroup({
    label,
    layout,
    entries: [
    { binding: 0, resource: view1 },
    { binding: 1, resource: sampler1 }]

  });
}