
import THREE from "three";

import { EllipseArcParams, Path, PolygonPath, runPath } from './EditShapes.js';

import { Path2D, Coedge, GEOMETRY_TYPES } from '@adsk/solid-definition';

const tmpVec1 = new THREE.Vector3();
const tmpVec2 = new THREE.Vector3();

// Convert SolidDef float-array to THREE-Vector3
const toVec3 = function (ar) {let target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new THREE.Vector3();
  return target.set(ar[0], ar[1], ar[2]);
};

// Add (directed) edge segment to an Edit2D path. If path is non-empty, it is
// assumed that it already ends at the start point of the new edge
//  @param {Path}                     path         - Edit2D path
//  @param {SolidDef.Coedge|SolidDef} ce           - Directed edge from SolidDef
//  @param {number}                   loopIndex    - index of the loop in target path where we add the edge to
//  @param {bool}                     lastLoopEdge - For the last edge in a loop, we only copy the arc params, but don't add the end vertex.
//                                                   This is because Edit2D shapes do not repeat the start vertex at the end.
const addCoedge = function (path, ce, loopIndex) {let lastLoopEdge = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  const vertexCount = path.getVertexCount(loopIndex);

  // Add start vertex on first call
  if (vertexCount == 0) {
    const pStart = ce.getStartVertex().getPosition();
    path.addPoint(pStart[0], pStart[1], loopIndex);
  }

  // Add end point - unless this is the last edge of a loop.
  if (!lastLoopEdge) {
    const pEnd = ce.getEndVertex().getPosition();
    path.addPoint(pEnd[0], pEnd[1], loopIndex);
  }

  // This function works for Coedges as well as edges. If ce is already an edge, we just set e=ce.
  const isCoedge = ce instanceof Coedge;
  const e = isCoedge ? ce.getEdge() : ce;

  // Determine whether the curve direction is flipped. For coedges, this may happen up to twice, because
  // cedge as well as edge have their own isReversed flag.
  const ceReversed = isCoedge ? ce.isReversed() : false;
  const edgeReversed = e.isReversed();
  const isReversed = ceReversed !== edgeReversed; // logical XOR (true if exactly one of both is reversed)

  const type = e.getCurveType();

  // For line segments, we are done
  if (type === GEOMETRY_TYPES.LINE) {
    return;
  }

  // convert curve parameters for new edge
  const edgeIndex = Math.max(vertexCount - 1, 0);
  const curve = e.getCurve();

  // Bezier case
  if (type === GEOMETRY_TYPES.BCURVE) {

    // Note that we cannot simply copy the control points of the SolidDef bcurve. Reasons:
    //  1. Coedge or edge may be reversed (which may invert the order of the control points)
    //  2. The edge may not cover the whole curve, but only a part of it.
    //
    // Due to 2. we have to construct a new cubic Bezier here based start/end tangent of the edge.

    // get params at edge start/end
    const t0 = ce.t0();
    const t1 = ce.t1();

    // get start/end point
    const pStart = ce.evaluatePosition(t0);
    const pEnd = ce.evaluatePosition(t1);

    // get start/end tangent
    const tStart = ce.evaluateDt(t0);
    const tEnd = ce.evaluateDt(t1);

    // We are searching for a cubic Bezier B with the following properties:
    //  - Its parameter domain is [0,1], i.e. B(0)=start vertex, B(1) is end vertex
    //  - Tangents at start/end positions are the same as for the given edge.

    // Note that changing from [t0, t1] to a larger parameter domain [0,1] scales down the tangent vectors accordingly.
    const rangeScale = Math.abs(t1 - t0);
    tStart[0] *= rangeScale;
    tStart[1] *= rangeScale;
    tEnd[0] *= rangeScale;
    tEnd[1] *= rangeScale;

    // The start tangent of a cubic bezier is 3 * (cp1-cp0). So, we can obtain cp1 from the start tangent.
    // Same principle applies to cp2.
    const cp1x = pStart[0] + tStart[0] / 3;
    const cp1y = pStart[1] + tStart[1] / 3;
    const cp2x = pEnd[0] - tEnd[0] / 3;
    const cp2y = pEnd[1] - tEnd[1] / 3;

    path.setBezierArc(edgeIndex, cp1x, cp1y, cp2x, cp2y, loopIndex);
    return;
  }

  // Ellipse arcs
  if (type === GEOMETRY_TYPES.ELLIPSE) {

    const params = new EllipseArcParams();
    params.rx = curve.getMajorRadius();
    params.ry = curve.getMinorRadius();

    // compute axis rotation
    const xAxis = curve.getMajorAxis();
    const angle = Math.atan2(xAxis[1], xAxis[0]);
    params.rotation = THREE.Math.radToDeg(angle);

    // determine largeArc flag
    // Note that the edge may only use a subset of the curve.
    // So, it's essential to get the range from the directed edge.
    const range = ce.getRange();
    const startAngle = range[0];
    const endAngle = range[1];
    params.largeArcFlag = endAngle - startAngle > Math.PI; // Note that endAngle is always >startAngle

    // determine sweep flag: Ellipse curves in SolidDef always run ccw (sweepFlag = true).
    // But it may be inverted if the edge direction is flipped.
    params.sweepFlag = !isReversed;

    // Note: In Edit2D, the unrotated y-axis always points up. I.e. rotation=0 means xAxis = (1,0) and yAxis = (-1,0).
    //       In SolidDef, the axes are more flexible. As long as we don't transform the curves arbitrarily, the ellipse
    //       axes still form a 90 degree angle. However, the ellipse coordinate system may have opposite orientation.
    //       If this happens, we have to invert the ellipse orientation.
    const yAxis = curve.getMinorAxis();
    const xDir = toVec3(xAxis, tmpVec1);
    const yDir = toVec3(yAxis, tmpVec2);
    const needsExtraFlip = xDir.cross(yDir).z < 0;
    if (needsExtraFlip) {
      params.sweepFlag = !params.sweepFlag;
    }

    path.setEllipseArc(edgeIndex, params, loopIndex);
    return;
  }

  console.error("Unexpected curve type");
};

// Creates an Edit2D Path loop from a given SoliDef loop. The loop is added to the target path.
//  @param {SolidDef.Loop} loop
//  @param {Path}          [target]
//  @returns {Path}
const loopToShape = function (loop) {let target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new PolygonPath();

  // start new loop in target path
  const loopIndex = target.nextFreeLoop();

  // traverse coedges of the loop
  const coedges = loop.getCoedges();
  for (let i = 0; i < coedges.length; i++) {
    const ce = coedges[i];

    // for the last loopEdge, we only copy the arc param, but don't repeat the start vertex
    const lastLoopEdge = i == coedges.length - 1;

    // add next edge to target
    addCoedge(target, ce, loopIndex, lastLoopEdge);
  }

  return target;
};

// Convert SolidDef face into one or more Edit2D Paths.
// Todo: If we support multiple loops per Path, result will be a single Path
//  @param {SolidDef.Face} face
//  @param {Path}          [target]
//  @returns {Path}
const faceToShape = function (face) {let target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new PolygonPath();

  // convert loops and collect them into target path
  face.getLoops().forEach((l) => {
    target = loopToShape(l, target);
  });
  return target;
};

// Convert array of faces to array of shapes
const facesToShape = function (faces) {let target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new PolygonPath();

  faces.forEach((f) => {
    target = faceToShape(f, target);
  });
  return target;
};

// Convert SolidDef wire to an Edit2D Path.
//
// Note: This function only works for wires that don't branch and have already ordered edges.
//       This is true for wires that we get from SolidDef.Path.
//  @param {SolidDef.Wire} wire
const wireToShape = (wire) => {

  // create empty path
  const path = new Path();
  path.isClosed = wire.isClosed();

  // add edges
  const edges = wire.getOrderedEdges().edges;
  for (let i = 0; i < edges.length; i++) {
    const edge = edges[i];
    const lastLoopEdge = wire.isClosed() && i == edges.length - 1;
    addCoedge(path, edge, 0, lastLoopEdge);
  }
  return path;
};

// Convert SolidDef path to an array of shapes
const pathToShape = (path) => {
  const body = path.getWireBody();
  const wires = body.getWires();

  if (wires.length == 0) {
    return new Path();
  }

  const result = wireToShape(wires[0]);

  // convert additional loops (if any)
  for (let i = 1; i < wires.length; i++) {
    const wire = wires[i];
    const tmp = wireToShape(wire);
    result.addLoop(tmp, 0);
  }

  return result;
};

// Convert Edit2D Path to SolidDef Path2D
const toSolidDefPath = (path) => {
  const path2D = new Path2D();
  runPath(path2D, path);
  return path2D;
};

export const SolidDefConvert = {
  addCoedge,
  loopToShape,
  faceToShape,
  facesToShape,
  wireToShape,
  pathToShape,
  toSolidDefPath
};