import { getConsolidatedLevelName, getLevelOfElement } from "../hud/layers/LayerUtils";
import { flyToTopOfBox, get3DSelectionBounds } from "../../tools/FitToViewUtil";
import { FacetSpaces, FacetTypes } from "../facets/Facets";
import { KeyFlags } from "../schema/dt-schema";
import i18n from "i18next";
import * as THREE from "three";
import { getLevelBounds } from "../hud/layers/levels/LevelsLayer";
import { getLevelElevationByName } from "../hud/layers/levels/LevelUtils";

const CONTEXT_MENU_KEY = "AutoView";

export class AutoViewMenu {
  constructor(facility) {
    this.viewer = facility.viewer;
    this.facility = facility;
  }

  #onContextMenu(menu) {
    const selection = this.viewer.getAggregateSelection();
    if (!selection.length || this.facility.facetsManager.currentClusterFacet) return;

    const menuOptions = [];
    const addOptionIfPresent = (option) => option && menuOptions.push(option);

    addOptionIfPresent(makeFirstPersonViewOption(this.viewer, selection));
    addOptionIfPresent(makeLevelSectionViewOption(this.facility, selection));
    addOptionIfPresent(makeLevelViewOption(this.facility, selection));
    addOptionIfPresent(makeRoomViewOption(this.facility, selection));

    if (!menuOptions.length) return;

    menu.push({
      title: i18n.t("Auto view"),
      target: menuOptions,
      twin: true
    });
  }

  register() {
    this.viewer.registerContextMenuCallback(CONTEXT_MENU_KEY, this.#onContextMenu.bind(this));
  }

  unregister() {
    this.viewer.unregisterContextMenuCallback(CONTEXT_MENU_KEY);
  }
}

function makeFirstPersonViewOption(viewer, selection) {
  if (!isSingleSelection(selection)) return;
  const model = selection[0].model;
  const dbId = selection[0].selection[0];

  if (isLogicalElement(model, dbId) || model.isRoom(dbId)) return;

  return {
    title: i18n.t("First person view"),
    target: () => viewer.utilities.flyToObject(model, dbId)
  };
}

function makeRoomViewOption(facility, selection) {
  if (!hasFacet(facility, FacetTypes.spaces)) return;
  const visibleSelection = filterOutHiddenInSpacesFacet(selection);
  if (!visibleSelection.length) return;

  const rooms = getRoomsOfSelection(facility, visibleSelection);
  if (!rooms.length) return;

  return {
    title: i18n.t("Room view"),
    target: () => viewRooms(facility, rooms)
  };
}

function makeLevelViewOption(facility, selection) {
  if (!hasFacet(facility, FacetTypes.levels)) return;

  const levelName = getConsolidatedLevelName(selection);
  if (!levelName) return;

  return {
    title: i18n.t("Plan view"),
    target: () => {
      const viewer = facility.viewer;
      viewer.getExtension("Autodesk.BimWalk")?.deactivate();
      viewer.navigation.toOrthographic();

      let focusBounds;

      const roomBoundsOnLevel = getSelectionRoomBoundsOnLevel(facility, selection, levelName);
      if (!roomBoundsOnLevel.isEmpty()) {
        const selectionBounds = get3DSelectionBounds(viewer, selection);
        focusBounds = selectionBounds.union(roomBoundsOnLevel);
      }

      viewLevel(facility, levelName, focusBounds);
    }
  };
}

function getSelectionRoomBoundsOnLevel(facility, selection, levelName) {
  const roomBounds = new THREE.Box3();
  getRoomsOfSelection(facility, selection).forEach((r) => {
    if (getLevelOfElement(r.model, r.dbId)?.name === levelName) {
      roomBounds.union(r.model.getElementBounds(r.dbId));
    }
  });
  return roomBounds;
}

function makeLevelSectionViewOption(facility, selection) {
  if (!hasFacet(facility, FacetTypes.levels)) return;

  const levelName = getConsolidatedLevelName(selection);
  if (!levelName) return;

  return {
    title: i18n.t("Level section view"),
    target: () => {
      filterToLevel(facility, levelName);
      setLevelSectionPlane(facility, selection, levelName);
    }
  };
}

async function setLevelSectionPlane(facility, selection, levelName) {
  const viewer = facility.viewer;
  const sectionExt = await viewer.loadExtension("Autodesk.Section");
  if (!sectionExt) return;

  const selectionBounds = get3DSelectionBounds(viewer, selection);
  const roomBounds = getSelectionRoomBoundsOnLevel(facility, selection, levelName);

  // determine level bounds only if we will need it (might be slow)
  let levelBounds;
  if (selectionBounds.isEmpty() || roomBounds.isEmpty()) {
    const levelFacetNode = getLevelFacetNode(facility, levelName);
    levelBounds = getLevelBounds(facility, levelFacetNode);
  }

  let sectionPosition;

  if (!selectionBounds.isEmpty()) {
    sectionPosition = selectionBounds.getCenter();
    sectionPosition.z = selectionBounds.max.z;
  } else {
    const model = selection[0].model;
    const elevByName = getLevelElevationByName(facility);
    const elevation = elevByName[levelName] ?? levelBounds.min.z;

    // height of section cut above the level
    const sectionHeightInFeet = 4.5;
    const sectionHeightInModelUnit = Autodesk.Viewing.Private.convertUnits(
      "ft",
      model.getParentFacility().mainModelDistanceUnit,
      1,
      sectionHeightInFeet
    );

    sectionPosition = levelBounds.getCenter();
    sectionPosition.z = elevation + sectionHeightInModelUnit;
  }

  const normal = new THREE.Vector3(0, 0, 1);
  sectionExt.setSectionPlane(normal, sectionPosition, true, false);

  const focusBounds = roomBounds.isEmpty() ? levelBounds : selectionBounds.union(roomBounds);
  viewer.navigation.fitBounds(false, focusBounds);
}

function getLevelFacetNode(facility, levelName) {
  const lvlIdx = facility.facetsManager.getFacetDefs().findIndex((f) => f.id === FacetTypes.levels);
  const levelFacets = facility.facetsManager.getFacets()[lvlIdx];
  return levelFacets.find((f) => f.id === levelName);
}

function isSingleSelection(selection) {
  return selection.length === 1 && selection[0].selection.length === 1;
}

function filterOutHiddenInSpacesFacet(selection) {
  const visibleSelection = [];
  selection.forEach((_ref) => {let { model, selection: dbIds } = _ref;
    const dbId2catId = model.getData().dbId2catId;

    const visibleIds = dbIds.filter((dbId) => !FacetSpaces.isHiddenFacetCategory(dbId2catId[dbId]));
    if (visibleIds.length) {
      visibleSelection.push({ model, selection: visibleIds });
    }
  });
  return visibleSelection;
}

function hasFacet(facility, facetId) {
  return facility.facetsManager.getFacetDefs().some((f) => f.id === facetId);
}

function viewRooms(facility, rooms) {
  const spaceIdFilter = rooms.map((r) => FacetSpaces.makeId(r));
  const filterMap = facility.facetsManager.getFilterMap();
  facility.facetsManager.setFilterMap({
    ...filterMap,
    spaces: spaceIdFilter
  });

  const viewer = facility.viewer;
  const bounds = viewer.impl.getVisibleBounds(false, false);
  viewer.navigation.fitBounds(false, bounds);
}

function viewLevel(facility, levelName, focusBounds) {
  filterToLevel(facility, levelName);

  if (!focusBounds || focusBounds.isEmpty()) {
    focusBounds = facility.viewer.impl.getVisibleBounds(false, false);
  }

  flyToTopOfBox(facility, focusBounds, 1);
}

function filterToLevel(facility, levelName) {
  const filterMap = facility.facetsManager.getFilterMap();
  facility.facetsManager.setFilterMap({
    ...filterMap,
    levels: [levelName]
  });
}

function getRoomsOfSelection(facility, selection) {
  const rooms = new Set();
  selection.forEach((_ref2) => {let { model, selection: dbIds } = _ref2;
    dbIds.forEach((dbId) => {
      const roomsOfElement = facility.getRoomsOfElement(model, dbId);
      roomsOfElement.forEach((r) => rooms.add(r));
    });
  });

  return Array.from(rooms);
}

function isLogicalElement(model, dbId) {
  const dbId2flags = model.getData().dbId2flags;
  const flags = dbId2flags[dbId];
  return (flags & KeyFlags.Logical) !== 0;
}