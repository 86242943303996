import * as THREE from "three";

import { FacetTypes } from "../../../facets/Facets";
import { areSetsEqual, getConsolidatedLevelName } from "../LayerUtils";
import { flyToTopOfBox } from "../../../../tools/FitToViewUtil";

const CONTEXT_MENU_KEY = 'Levels';
const FLY_TO_SEC = 1.0;

export function registerLvlsCxtMenuCb(viewer, facility) {
  viewer.registerContextMenuCallback(CONTEXT_MENU_KEY, (menu) => {
    if (!facility.facetsManager.getFacetDefs().find((_ref) => {let { id } = _ref;return id === FacetTypes.levels;})) {
      // Every action require the level facet.
      return;
    }

    // Every action are solely about level, and works with an individual consolidated level.
    const levelName = getConsolidatedLevelName(viewer.getAggregateSelection(), true);
    if (!levelName) return;

    const filterMap = facility.facetsManager.getFilterMap();
    const levelIdFilter = new Set([levelName]);

    menu.push({
      title: 'Bird\'s-eye view',
      target: () => {
        goToFloorView(facility, filterMap, levelIdFilter).
        catch((err) => console.error('unable to go to floor view', levelName, err));
      }
    });

    if (!areSetsEqual(levelIdFilter, filterMap.levels)) {
      menu.push({
        title: 'Filter to this level',
        // Using the new ordering system, above of normal but leaving space above.
        order: 10,
        target: () => {
          facility.facetsManager.setFilterMap({
            ...filterMap,
            levels: levelIdFilter
          });
        }
      });
    }
  });
}

export function unregisterLvlsCxtMenuCb(viewer) {
  viewer.unregisterContextMenuCallback(CONTEXT_MENU_KEY);
}

/** Isolate a consolidated floor and move to a top view. */
async function goToFloorView(facility, filterMap, levelIdFilter) {
  const viewer = facility.viewer;

  // Filter to level, if needed.
  if (!areSetsEqual(levelIdFilter, filterMap.levels)) {
    facility.facetsManager.setFilterMap({
      ...filterMap,
      levels: levelIdFilter
    });
  }

  // Flies to a top view with up facing back of view cube.
  const bbox = getVisibleBounds(facility);
  await flyToTopOfBox(facility, bbox, FLY_TO_SEC);

  // Fit to view.
  const bounds = viewer.impl.getVisibleBounds(false, false);
  viewer.navigation.fitBounds(false, bounds, false, false);
}

const visibleBounds = new THREE.Box3();
function getVisibleBounds(facility) {
  visibleBounds.makeEmpty();

  for (const model of facility.getModels()) {
    if (!facility.isModelVisible(model)) {
      continue;
    }

    model.invalidateBBoxes();
    visibleBounds.union(model.getVisibleBounds());
  }

  return visibleBounds;
}