import { wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";
import { UniformBuffer } from "../UniformBuffer";
import { LmvMatrix4 } from "../../scene/LmvMatrix4";


export function getCameraUniformsDeclaration(bindGroup) {
  return wgsl /*wgsl*/`
	struct CameraUniforms {
		projectionMatrix : mat4x4f,
		viewMatrix: mat4x4f,
		viewMatrixInverse: mat4x4f,
		viewInverseEnv: mat4x4f,
		near: f32,
		far: f32
	}
	@group(${bindGroup || 0}) @binding(0) var<uniform> uniforms : CameraUniforms;
	`;
}

//GL style (-1,1) projection to (0,1) for depth value
const NO_2_ZO = new LmvMatrix4();
NO_2_ZO.elements[10] = 0.5;
NO_2_ZO.elements[14] = 0.5;

export class CameraUniforms extends UniformBuffer {

  #projScreenMatrix = new LmvMatrix4();
  #viewInverseEnv = new LmvMatrix4();
  #viewProjectionMatrix = new LmvMatrix4();

  constructor(device) {
    super(device, 80, true, true);
  }

  update(camera) {

    if (camera.parent === undefined || camera.parent === null) camera.updateMatrixWorld();

    if (camera.worldUpTransform)
    this.#viewInverseEnv.multiplyMatrices(camera.worldUpTransform, camera.matrixWorld);else

    this.#viewInverseEnv.copy(camera.matrixWorld);

    this.#projScreenMatrix.multiplyMatrices(NO_2_ZO, camera.projectionMatrix);
    this.#viewProjectionMatrix.multiplyMatrices(this.#projScreenMatrix, camera.matrixWorldInverse);

    this.setBuffer(0, this.#projScreenMatrix.elements);
    this.setBuffer(16, camera.matrixWorldInverse.elements);
    this.setBuffer(32, camera.matrixWorld.elements);
    this.setBuffer(48, this.#viewInverseEnv.elements);

    this.setFloat(64, camera.near);
    this.setFloat(65, camera.far);

    this.upload();
  }

  getViewProjectionMatrix() {
    return this.#viewProjectionMatrix;
  }

}