import * as THREE from "three";
import { EventDispatcher } from "../application/EventDispatcher";
import * as et from "../application/EventTypes";
import { EventUtils } from "../application/EventUtils";
import { GlobalManagerMixin } from "../application/GlobalManagerMixin";
const { AnimatedParam } = require("../tools/viewtransitions/ViewTransition");

/**
 * Returns the max horizontal pixel distance of the screen projection of both diagonals of a AABB.
 * This is not meant to work with the original mesh, just its bounding box.
 * @param viewer
 * @param box
 * @param {boolean} isScreenXAxis
 * @return {number}
 */
export const getMaxPixelAxialDistance = (viewer, box, isScreenXAxis) => {
  const axis = isScreenXAxis ? 'x' : 'y';
  const distance = (viewer, p1, p2, axis) => {
    const d1 = viewer.impl.worldToClient(p1)[axis];
    const d2 = viewer.impl.worldToClient(p2)[axis];

    return Math.abs(d2 - d1);
  };

  const mainLengthPx = distance(viewer, box.min, box.max, axis);

  const min = _tmpV31.copy(box.min);
  const max = _tmpV32.copy(box.max);
  max.x = box.min.x;
  min.x = box.max.x;
  const offLengthPx = distance(viewer, min, max, axis);

  // Also accounting for the off-diagonal, as BB are axis-aligned, and we want the one that's most horizontal.
  return Math.max(mainLengthPx, offLengthPx);
};

const Events = {
  DRAG_START: "dragStart",
  DRAG_END: "dragEnd",
  CLICK: "click",
  DBL_CLICK: "dblclick",
  ON_MOUSE_OVER: "onMouseOver",
  ON_MOUSE_OUT: "onMouseOut",
  ON_MOUSE_UP: "onMouseUp"
};

let _initialMouseDownEvent = null;
const kClickThreshold = 2; // Pixels

let _tmpV31 = new THREE.Vector3();
let _tmpV32 = new THREE.Vector3();

// Singleton canvas element used to find label measurements
let labelMeasurementContext;

function getOrCreateLabelMeasurementContext(viewer) {
  if (!labelMeasurementContext) {
    const document = viewer.canvasWrap.ownerDocument;
    // Initialize singleton canvas if it's not
    const canvas = document.createElement("canvas");
    labelMeasurementContext = canvas.getContext("2d");
  }

  return labelMeasurementContext;
}
/**
 * Adapts the Label3D behaviour.
 * @typedef {Object} Label3DOptions
 * @property {boolean} noDefaultStyle - Do not add any styling class - keep it plain.
 */

// A Label3D is an html div whose position is synchronized with a fixed world-space position in LMV.
export class Label3D extends EventDispatcher {

  // @param {Viewer3D}      viewer
  // @param {THREE.Vector3} [pos3D] - By default (0,0,0). Can be set later by changing this.pos3D.
  // @param {string}        [text]  - If undefined, label will be empty/invisible by default and you have to configure this.container yourself.
  // @param {Label3DOptions}        [options]
  constructor(viewer) {let pos3D = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new THREE.Vector3();let text = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '<Empty>';let options = arguments.length > 3 ? arguments[3] : undefined;
    super();
    this.viewer = viewer;
    this.pos3D = pos3D;
    this.pos2D = new THREE.Vector3(); // updated automatically. z is the depth value
    this.draggable = false;
    this.isRepellable = false; // If it can be repelled by other labels.

    this.setGlobalManager(viewer.globalManager);

    // keep position in-sync with camera changes
    this.cameraChangeCb = this.update.bind(this);
    this.viewer.addEventListener(et.CAMERA_CHANGE_EVENT, this.cameraChangeCb);
    this.viewer.addEventListener(et.VIEWER_RESIZE_EVENT, this.cameraChangeCb);

    // Create container
    const document = viewer.canvasWrap.ownerDocument; // (might be != global document in popout scenarios)
    this.container = document.createElement('div');

    // Note: It's essential that we add it to viewer.canvasWrap instead of viewer.container:
    //       ToolController listens to events on canvasWrap. Therefore, if we would add
    //       it to viewer.container, all mouse events captured would never reach the ToolController
    //       no matter whether the gizmo handles them or not.
    viewer.canvasWrap.appendChild(this.container);

    // For fadeIn/Out effects
    const setOpacity = (t) => {
      this.container.style.opacity = t;
    };
    this.opacityParam = new AnimatedParam(0.0, setOpacity, 0.5);

    // We control position via transform. So, left/top usually keep (0,0)
    this.container.style.left = '0px';
    this.container.style.top = '0px';
    this.container.style.position = 'absolute';
    this.container.style.pointerEvents = 'auto';
    this.container.style.cursor = 'pointer';

    // Only used for text labels
    this.textDiv = null;
    this.noDefaultStyle = options?.noDefaultStyle ?? false;
    if (text) {
      this.setText(text);
    }

    // Level-of-detail (optional)
    this.worldBox = null;
    this.minPixels = 0;

    this.offset = {
      x: 0,
      y: 0
    };

    // Update position and fade-in
    this.styleHidden = false;
    this.setVisible(true);

    this.onClick = this.onClick.bind(this);
    this.onDblClick = this.onDblClick.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);

    this.container.addEventListener("click", this.onClick);
    this.container.addEventListener("dblclick", this.onDblClick);
    this.container.addEventListener("mouseover", this.onMouseOver);
    this.container.addEventListener("mouseout", this.onMouseOut);
    this.container.addEventListener("mousedown", this.onMouseDown);
    this.container.addEventListener("mouseup", this.onMouseUp);
  }

  // Decides if the label should be shown or hidden.
  // We hide the label if the projected box diagonal falls below this.minPixels.
  shouldBeHidden() {
    if (!this.worldBox) {
      return false;
    }

    const availablePx = getMaxPixelAxialDistance(this.viewer, this.worldBox, true);
    return availablePx < this.minPixels;
  }

  // Optional: WorldBox of the annotated object. Used for level-of-detail: We only show the label
  //           if the projected screen-size of the box is >= a given minimum pixel size.
  // @param {Box3}   worldBox
  // @param {number} minPixels
  setWorldBox(box, minPixels) {
    this.worldBox = box;
    this.minPixels = minPixels;
    this.update(); // hide this label immediately if projected world-box is very small
  }

  // Configure this label to display text
  initTextLabel() {

    // Create textDiv child div
    const document = this.viewer.container.ownerDocument;
    this.textDiv = document.createElement('div');
    this.container.appendChild(this.textDiv);

    // Use measure-tool styles by default
    if (!this.noDefaultStyle) {
      this.container.classList.add('measure-length');
      this.container.classList.add('visible');
      this.textDiv.classList.add('measure-length-text');
    }
  }

  setText(text) {
    if (!this.textDiv) {
      this.initTextLabel();
    }
    this.textDiv.textContent = Autodesk.Viewing.i18n.translate(text);
  }

  dtor() {
    this.container.remove();
    this.viewer.removeEventListener(et.CAMERA_CHANGE_EVENT, this.cameraChangeCb);
    this.viewer.removeEventListener(et.VIEWER_RESIZE_EVENT, this.cameraChangeCb);
  }

  // To change the position, just modify this.pos3D directly and call update().
  update() {
    // Get canvas position corresponding to this.pos3D
    const { x, y } = this.viewer.impl.worldToClient(this.pos3D);

    this.pos2D.set(x, y, 0);

    // Transform the div, so that its center is anchored in (x,y)
    this.container.style.transform = `translate(calc(${x}px - 50%), calc(${y}px - 50%))`;

    // Hide label if the annotated object is small on screen
    const hidden = !this.visible || this.shouldBeHidden();

    // If the label should be visible, immediately restore the container visibility, so the fade-in will be displayed.
    if (!hidden) {
      this.changeContainerVisibility(!hidden);
    }

    // this.opacityParam.skipAnim();
    this.opacityParam.fadeTo(hidden ? 0.0 : 1.0, () => {
      // If the label should be hidden, change container visibility only after the fade-out animation finished.
      // This is needed in order that the element won't be touchable while hidden.
      this.changeContainerVisibility(!hidden);
    });
  }

  // Necessary in addition to the opacity change, in order to remove from the DOM rendering.
  changeContainerVisibility(show) {
    if (!show && !this.styleHidden) {
      this.styleHidden = true;
      this.container.style.display = 'none';
    } else if (show && this.styleHidden) {
      this.styleHidden = false;
      this.container.style.display = 'block';
    }
  }

  setPosition(pos) {
    this.pos3D.copy(pos);
    this.update();
  }

  setVisible(visible) {
    this.visible = visible;
    this.update();
  }

  // Fade out and dispose label when done
  dispose() {
    this.setVisible(false);

    // Make sure that we clean up when fading is done.
    window.setTimeout(() => this.dtor(), 1000 * this.opacityParam.animTime);
  }

  /** Meant to be called with the rectangle of the available draw space. */
  isOnScreen(_ref) {let { width, height } = _ref;
    const { x, y } = this.pos2D;
    return x >= 0 && y >= 0 && x < width && y < height;
  }

  // @param {number} offset - Optional: Vertical offset in screen-pixels. Positive values shift down.
  setVerticalOffset(offset) {
    this.container.style.top = offset + 'px';
  }

  onMouseDown(event) {
    this.viewer.toolController.__clientToCanvasCoords(event);

    if (this.draggable) {
      this.container.style.cursor = "grabbing";

      this.fireEvent({ type: Events.DRAG_START, event });

      this.addDocumentEventListener("mouseup", this.onMouseUp, { once: true });
    } else {
      // Keep mouse down event and listen to mousemove to see if dragging starts
      _initialMouseDownEvent = event;

      this.addDocumentEventListener("mousemove", this.onMouseMove);
    }

    // Potential click, suppress event to prevent selection behind the label
    event.stopPropagation();
  }

  onMouseUp(event) {
    this.viewer.toolController.__clientToCanvasCoords(event);

    if (this.draggable) {
      this.container.style.cursor = "grab";

      this.fireEvent({ type: Events.DRAG_END, event });
    } else if (_initialMouseDownEvent) {
      // The initial mouse down event is still defined, which means dragging didn't start
      // so no-op
      _initialMouseDownEvent = null;
      this.removeDocumentEventListener("mousemove", this.onMouseMove);

      this.fireEvent({ type: Events.ON_MOUSE_UP, event });

      if (event.bubbles) {
        // Forces specific events to bubble up to the viewer, if stopPropagation isn't called.
        if (EventUtils.isRightClick(event)) {
          this.viewer.triggerContextMenu(event);
        }
      }
    }
  }

  onMouseMove(event) {
    if (_initialMouseDownEvent) {
      this.viewer.toolController.__clientToCanvasCoords(event);
      const deltaX = _initialMouseDownEvent.canvasX - event.canvasX;
      const deltaY = _initialMouseDownEvent.canvasY - event.canvasY;

      if (Math.abs(deltaX) > kClickThreshold || Math.abs(deltaY) > kClickThreshold) {
        // Once dragging starts, reset and ToolController handle the rest
        this.viewer.toolController.mousedown(_initialMouseDownEvent);
        _initialMouseDownEvent = null;
        this.removeDocumentEventListener("mousemove", this.onMouseMove);
      }
    }
  }

  onClick(event) {
    this.viewer.toolController.__clientToCanvasCoords(event);
    this.fireEvent({ type: Events.CLICK, event });
  }

  onDblClick(event) {
    this.fireEvent({ type: Events.DBL_CLICK, event });
  }

  onMouseOver(event) {
    this.fireEvent({ type: Events.ON_MOUSE_OVER, event });
  }

  onMouseOut(event) {
    this.fireEvent({ type: Events.ON_MOUSE_OUT, event });
  }

  setDraggable(draggable) {
    if (draggable && !this.draggable) {
      this.container.style.cursor = "grab";
      this.container.style.pointerEvents = 'auto';
    } else if (!draggable && this.draggable) {
      this.container.style.cursor = "";
      this.container.style.pointerEvents = 'none';
    }

    this.draggable = draggable;
  }

  /**
   * Remove mouse listeners (over/out/click) in parent container.
  */
  removeLabelListeners() {
    this.container.removeEventListener("click", this.onClick);
    this.container.removeEventListener("dblclick", this.onDblClick);
    this.container.removeEventListener("mouseover", this.onMouseOver);
    this.container.removeEventListener("mouseout", this.onMouseOut);

    this.container.style.pointerEvents = 'none';
    this.container.style.cursor = "";
  }

  setOffset(offset) {}

  static configureTextMeasurement(viewer, options) {
    const ctx = getOrCreateLabelMeasurementContext(viewer);

    for (const opt in options) {
      ctx[opt] = options[opt];
    }
  }

  static getTextMeasurements(viewer, text) {
    const ctx = getOrCreateLabelMeasurementContext(viewer);

    return ctx.measureText(text);
  }
}

GlobalManagerMixin.call(Label3D.prototype);

Label3D.Events = Events;