import * as THREE from 'three';

import { HUDLabel, LABEL_ANCHORING } from './HudLabel';

const SVG_NS = 'http://www.w3.org/2000/svg';
const PIN_DEFAULT = 'M18.0065 16.8529L18.0066 16.8528C19.4753 15.1219 20.3664 12.8826 20.3664 10.4331C20.3667 4.94732 15.9193 0.5 10.4333 0.5C4.94732 0.5 0.5 4.94732 0.5 10.4333C0.5 12.8829 1.39109 15.1219 2.8598 16.8531L2.85993 16.8532L9.2899 24.4259C9.88895 25.1314 10.9777 25.1313 11.5768 24.4258L18.0065 16.8529Z';
const PIN_WIDTH = 21;
const PIN_HEIGHT = 26;
const PIN_MIN_SCALE = 0.5;
const CHARCOAL700 = '#666666';

const _vec3 = new THREE.Vector3();

/** A HUD Pin is a HUD graphic with only an icon when collapsed and icon + label when expanded. */
export class HUDPinLabel extends HUDLabel {
  constructor(viewer, pos3D, text, labelId, options) {
    super(viewer, pos3D, text, labelId, options);

    const _document = this.viewer.canvasWrap.ownerDocument;

    // Base element containing pin, circle, and icon
    this.iconHost = _document.createElementNS(SVG_NS, 'svg');
    this.iconHost.setAttribute('width', PIN_WIDTH.toString());
    this.iconHost.setAttribute('height', PIN_HEIGHT.toString());
    this.iconHost.setAttribute('viewBox', `0 0 ${PIN_WIDTH} ${PIN_HEIGHT}`);
    this.iconHost.style.transformOrigin = 'bottom';

    // Pin
    this.pinEl = _document.createElementNS(SVG_NS, 'path');
    this.pinEl.setAttribute('d', PIN_DEFAULT);
    this.pinEl.setAttribute('stroke', CHARCOAL700);

    // Background circle
    this.circleEl = _document.createElementNS(SVG_NS, 'circle');
    this.circleEl.setAttribute('cx', `${PIN_WIDTH * 0.5}`);
    this.circleEl.setAttribute('cy', `${PIN_HEIGHT * 0.4}`);
    this.circleEl.setAttribute('r', '7.5');
    this.circleEl.setAttribute('stroke', CHARCOAL700);

    this.container.prepend(this.iconHost);
    this.iconHost.appendChild(this.pinEl);
    this.iconHost.appendChild(this.circleEl);

    this.container.style.display = 'flex';
    this.container.style.borderRadius = '15px';

    this.inFocus = false;
    this.baseOpacity = 1;
    this.baseZIndex = this.container.style.zIndex;
    this.boundOnMouseOver = this.#onMouseOver.bind(this);
    this.boundOnMouseOut = this.#onMouseOut.bind(this);

    this.isWorldScaled = options?.isWorldScaled ?? false;

    this.setIcon(null);
    this.setCollapsible(true);

    this.setColor('black');
    this.iconHost.setAttribute('fill', 'white');

    this.labelAnchor = LABEL_ANCHORING.BOTTOM_MIDDLE;
  }

  update() {
    if (this.offsetAnimControl?.isRunning) {
      return;
    }

    // Get canvas position corresponding to this.pos3D
    let { x, y } = this.viewer.impl.worldToClient(this.pos3D);
    x += this.offset.x;
    y += this.offset.y;

    this.pos2D.set(x, y, 0);

    // Hide label if the annotated object is small on screen
    let hidden = !this.visible || this.shouldBeHidden();

    // Scaling, if requested;
    if (this.isWorldScaled) {
      const scale = getScale(this?.viewer?.impl?.camera, this?.pos3D);
      if (scale) {
        this.iconHost.style.transform = `scale(${scale})`;
        // Hide label if the annotated object is small on screen
        hidden ||= scale < PIN_MIN_SCALE;
      }
    }

    // Transform the div, so that its center is anchored in (x,y)
    this.container.style.transform = this.getAnchoredTransform(x, y);

    let opacityTarget = 0;

    // If the label should be visible, immediately restore the container visibility, so the fade-in will be displayed.
    // Also update position on screen, set z-index, and reassign opacity target.
    if (!hidden) {
      this.changeContainerVisibility(!hidden);
      this.container.style.zIndex = this.inFocus ? this.baseZIndex + 1000 : this.baseZIndex;
      opacityTarget = this.isCollapsed ? this.baseOpacity : 1;
    }

    this.opacityParam.fadeTo(opacityTarget, () => {
      // If the label should be hidden, change container visibility only after the fade-out animation finished.
      // This is needed in order that the element won't be touchable while hidden.
      this.changeContainerVisibility(!hidden);
    });

    // If visibility didn't change, immediately skip to desired opacity.
    if (hidden === this.styleHidden) {
      this.opacityParam.skipAnim();
    }
  }

  /** Collapse the pin to an icon only */
  setCollapsed(wantsCollapsed) {
    if (this.isCollapsed === wantsCollapsed || !this.isCollapsible) return;

    if (wantsCollapsed) {
      this.container.style.boxShadow = 'none';
      this.container.style.border = 'none';
      super.setBGColor('transparent');

      this.textDiv.style.display = 'none';
      this.iconHost.style.display = 'block';
    } else {
      // important when showing icons only as they often overlap.
      this.container.style.boxShadow = '0 0 7px rgba(0,0,0,1.0)';
      this.container.style.border = '3px solid white';
      super.setBGColor(this.backgroundColor);

      this.textDiv.style.display = 'block';
      this.iconHost.style.display = 'none';
    }

    this.isCollapsed = wantsCollapsed;
  }

  setBGColor(color) {
    this.backgroundColor = color;
    super.setBGColor(this.isCollapsed ? 'transparent' : this.backgroundColor);
    this.circleEl.setAttribute('fill', this.backgroundColor);
  }

  /** Sets whether the pin should be only an icon when not hovered */
  setCollapsible(wantsCollapsible) {
    if (this.isCollapsible === wantsCollapsible) return;

    if (wantsCollapsible) {
      this.addEventListener(HUDLabel.Events.ON_MOUSE_OVER, this.boundOnMouseOver);
      this.addEventListener(HUDLabel.Events.ON_MOUSE_OUT, this.boundOnMouseOut);
    } else {
      this.removeEventListener(HUDLabel.Events.ON_MOUSE_OVER, this.boundOnMouseOver);
      this.removeEventListener(HUDLabel.Events.ON_MOUSE_OUT, this.boundOnMouseOut);
    }

    this.isCollapsible = true;
    this.setCollapsed(wantsCollapsible);
    this.isCollapsible = wantsCollapsible;
  }

  /** Returns the DOM element of the icon */
  getIcon() {
    return this.iconEl;
  }

  /** Set the icon as an DOM element. */
  setIcon(iconEl) {
    this.iconEl?.remove();
    this.iconEl = iconEl;

    if (iconEl) {
      this.iconHost.appendChild(iconEl);
    }

    return this;
  }

  /** Set the icon as SVG path. */
  setIconFromSvgPath(svgPath) {let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const _document = this.viewer.canvasWrap.ownerDocument;

    let iconEl = _document.createElementNS(SVG_NS, 'path');
    iconEl.setAttribute('d', svgPath);

    for (const opt in opts) {
      iconEl.setAttribute(opt, opts[opt]);
    }

    return this.setIcon(iconEl);
  }

  dtor() {
    if (this.isCollapsible) {
      this.removeEventListener(HUDLabel.Events.ON_MOUSE_OVER, this.boundOnMouseOver);
      this.removeEventListener(HUDLabel.Events.ON_MOUSE_OUT, this.boundOnMouseOut);
    }

    super.dtor();
  }

  #onMouseOver(_ref) {let { event } = _ref;
    event.preventDefault();
    event.stopPropagation();

    // Prevent on hover label as it doesn't work with scaling.
    if (this.isWorldScaled) return;

    this.container.style.zIndex = this.baseZIndex + 1000;

    this.opacityParam.fadeTo(1);

    this.setCollapsed(false);
    this.inFocus = true;
  }

  #onMouseOut(_ref2) {let { event } = _ref2;
    event.preventDefault();
    event.stopPropagation();

    this.container.style.zIndex = this.baseZIndex;

    this.opacityParam.fadeTo(this.baseOpacity);

    this.setCollapsed(true);
    this.inFocus = false;
  }
}

/** Returns the distance dependant relative scale of the pin. */
function getScale(camera, pos3D) {
  // Point to camera distance.
  const dist = _vec3.copy(pos3D).sub(camera.position).length();
  // pixel-per-unit scale at a given distance from the camera
  const ratio = camera.pixelsPerUnitAtDistance(dist);
  // Getting a scale that is half a unit height.  0.75 / (PIN_HEIGHT) ~= 0.02
  return ratio * 0.03;
}