//TODO: This needs to be changed to be owned by the DtApp instance.

//Use a global property worker thread, which does caching of
//shared property databases (and database files).
import { logger } from "../logger/Logger";
import { createWorker } from "./DtWorkerCreator";

var WORKER_USER_FUNCTION = "USER_FUNCTION";
var WORKER_UNLOAD_PROPERTYDB = "UNLOAD_PROPERTYDB";

export class DtPropWorker {

  constructor(defaultHandler) {
    //Keep track of all pending operations/callbacks going into the property worker
    this.PROPDB_CB_COUNTER = 1;
    this.PROPDB_CALLBACKS = {};

    this.propWorker = createWorker('Property Worker');
    this.defaultListener = (e) => this.propertyWorkerCallback(e, defaultHandler);
    this.propWorker.addEventListener('message', this.defaultListener);
  }

  registerWorkerCallback(onSuccess, onError, onProgress) {
    let cbId = this.PROPDB_CB_COUNTER++;

    this.PROPDB_CALLBACKS[cbId] = [onSuccess, onError, onProgress];

    return cbId;
  }

  unregisterWorkerCallback(cbId) {
    delete this.PROPDB_CALLBACKS[cbId];
  }

  propertyWorkerCallback(e, defaultHandler) {

    var data = e.data;

    if (data && data.debug) {
      logger.debug(data.message);
      return;
    }

    // find worker callbacks for this message: [onSuccess, onError, onProgress]
    var cbs = data && data.cbId && this.PROPDB_CALLBACKS[data.cbId];
    if (!cbs) {

      //No callback registered, call the default handler if any
      defaultHandler && defaultHandler.handleChangeNotification(data);
      return;
    }

    // Handle progress callbacks. Unlike success/error event, they may be triggered multiple times for the same operation/cbId.
    if (data.progress) {
      if (cbs[2]) {
        cbs[2](data.progress);
      }

      // Note that we don't remove the cbId here yet. The cbId of this operation
      // is cleaned up later when getting the success or error message (see below)
      return;
    }

    if (data.error) {
      if (cbs[1])
      cbs[1](data.error);
    } else {
      if (cbs[0])
      cbs[0](data.result);
    }

    // Getting success/error message implies that the current operation/cbId is finished.
    delete this.PROPDB_CALLBACKS[data.cbId];
  }

  doOperation(operationContext) {
    this.propWorker.doOperation(operationContext);
  }

  async asyncDatabaseOperation(operationContext) {

    return new Promise((resolve, reject) => {

      let opArgs = {
        ...operationContext
      };

      opArgs.operation = "DT_" + opArgs.operation;

      if (typeof opArgs.cbId !== "undefined") {
        console.warn("Unexpected worker operation with callback ID");
      }

      const success = (result) => {
        this.unregisterWorkerCallback(opArgs.cbId);
        resolve(result);
      };

      const fail = (error) => {
        this.unregisterWorkerCallback(opArgs.cbId);
        reject(error);
      };

      opArgs.cbId = this.registerWorkerCallback(success, fail, null);

      this.propWorker.doOperation(opArgs); // Send data to our worker.
    });
  }

  clearPropertyWorkerCache() {
    if (!this.propWorker)
    return;

    this.propWorker.doOperation({
      "operation": WORKER_UNLOAD_PROPERTYDB,
      "clearCaches": true
    });
  }

  //Used by Autodesk.Viewing.shutdown() to shutdown the propdb worker thread
  shutdown() {
    if (this.propWorker) {
      this.propWorker.removeEventListener('message', this.defaultListener);
      this.defaultListener = null;
      this.propWorker.terminate();
      this.propWorker = undefined;
    }
  }

  /**
   * Allows executing user supplied function code on the worker thread against the
   * {@link PropertyDatabase} instance. The returned value from the supplied function will
   * be used to resolve the returned Promise. The function must be named `userFunction`.
   *
   * @example
   *     function userFunction(worker, userData) {
   *          var pdb = worker.pdbCache.get("modelID").pdb
   *          var dbId = 1;
   *          pdb.enumObjectProperties(dbId, function(propId, valueId) {
   *                // do stuff
   *          });
   *          return 42 * userData; // userData will be 2 in this example
   *     }
   *     executeUserFunction(userFunction, 2).then(function(result) {
   *          console.log(result); // result === 84 === 42 * 2
   *     })
   *
   * @param {function|string} code - Function takes 1 argument, the {@link PropertyDatabase} instance.
   * @param {*} userData - A value that will get passed to the `code` function when run in the property
   *                       worker context. it needs to be serializable.
   *
   * @returns {Promise} - Resolves with the return value of user function.
   *
   * @alias Autodesk.Viewing.Private.PropDbLoader#executeUserFunction
   */
  async executeUserFunction(code, userData) {
    if (typeof code === "function") {
      code = code.toString();
    } else if (typeof code !== "string") {
      return Promise.reject("Expected Function or string.");
    }

    let blob = new Blob([code], { type: 'application/javascript' });
    let blobUrl = URL.createObjectURL(blob);

    return this.asyncDatabaseOperation({
      "operation": WORKER_USER_FUNCTION,
      "userFunction": blobUrl,
      "userData": userData
    });
  }

}