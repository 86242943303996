import { SystemElement, SystemConnectionType } from "./Connectivity";
import { PALETTE_SOURCE_FILES as distinctColors } from "../facets/Facets";
import { DtFacility } from "../DtFacility";
import { DtConstants } from "../schema/DtConstants";
import { OUTSIDE, INTERSECTS, CONTAINS, geomIntersectsNDC } from "../../wgs/scene/FrustumIntersector";
import { KeyFlags } from "../schema/dt-schema";
import RC from "../resources/cats_enum.json";
import * as THREE from "three";

// Direction colors
const UPSTREAM_COLOR = 0xd06120;
const DOWNSTREAM_COLOR = 0x208fd0;
const RED = 0xff0000;
const GREEN = 0x00ff00;
const BLUE = 0x0000ff;

const _color = new THREE.Color(0x000000);
const tmpMat = new THREE.Matrix4();
const tmpV3 = new THREE.Vector3();
const tmpBox = new THREE.Box3();
const boxArr = new Array(6);

/**
 * Returns a list of elements' dbIds, grouped by DtModel
 * @param {SystemElement[]} elements
 * @returns {Map<DtModel, int[]>} dbIdsByModel
 */
function getElementsDbIdsByModel(elements) {
  const perModel = new Map();

  for (const element of elements) {
    const model = element.model;
    const dbId = element.dbId;

    let dbIds = perModel.get(model);
    if (!dbIds) {
      dbIds = [];
      perModel.set(model, dbIds);
    }

    dbIds.push(dbId);
  }

  return perModel;
}

/**
 * Returns a list of elements and their connections' dbIds, grouped by modelURN
 * @param {SystemElement[]} elements
 * @returns {{ urn: [dbIds]}}
 */
function getElementsDbIdsByURN(elements) {
  const dbIdsByURN = {};

  const appendElement = (element) => {
    const urn = element.model.urn();
    const perModel = dbIdsByURN[urn] = dbIdsByURN[urn] ?? new Set();
    perModel.add(element.dbId);
  };

  for (const element of elements) {
    appendElement(element);

    for (const [conn, _] of element.allConnections()) {
      appendElement(conn);
    }
  }

  return dbIdsByURN;
}

/**
 * Filter all elements using a System filter and group their dbIds by URN
 * @param {DtFacility} facility
 * @returns {{ urn: [dbIds]}}
 */
function getFilteredDbIdsByURN(facility, filter) {

  const dbIdsByURN = {};

  for (const model of facility.getModels()) {

    dbIdsByURN[model.urn()] = [];
    // Contains all dbIds
    const dbId2SysClass = model.getData().dbId2SystemClass;
    const flags = model.getData().dbId2flags;

    for (const dbId in dbId2SysClass) {
      let isSystemElement;

      if (flags[dbId] === DtConstants.ElementFlags.System) {
        // Skip system elements
        continue;
      }

      if (filter.systemClasses & dbId2SysClass[dbId]) {
        // SystemClass filter
        isSystemElement = true;
      }

      // TODO: Add filter checks here when we allow more system filters
      // ex: if (!isSystemElement && /*SOME_CHECK*/) { isSystemElement = true; };

      if (isSystemElement) {
        dbIdsByURN[model.urn()].push(parseInt(dbId));
      }
    }
  }

  return dbIdsByURN;
}

/**
 * Count the number of elements that match a given system's filter
 * @param {DtFacility} facility
 * @param {System} system
 */
function countSystemElements(facility, system) {

  system.elementsCount = 0;

  const dbIdsByURN = getFilteredDbIdsByURN(facility, system.filter);
  for (const urn in dbIdsByURN) {
    system.elementsCount += dbIdsByURN[urn].length;
  }

  return system.elementsCount;
}

/**
 * Check whether filtered system elements contain center lines
 * @param {DtFacility} facility
 * @param {*} filter
 * @returns {boolean}
 */
function hasCenterlines(facility, filter) {
  const dbIdsByURN = getFilteredDbIdsByURN(facility, filter);

  for (const urn in dbIdsByURN) {
    const model = facility.getModelByUrn(urn);
    const modelData = model.getData();
    const dbId2catId = modelData.dbId2catId;

    for (const dbId of dbIdsByURN[urn]) {
      if (DtConstants.CenterlineCatIDs.has(dbId2catId[dbId])) {
        return true;
      }
    }
  }

  return false;
}

function colorElement(element, color) {
  _color.set(color);
  element.model.setThemingColor(element.dbId, new THREE.Vector4(_color.r, _color.g, _color.b, 0.2));
}

/**
 * Must invalidate after to see color change
 */
function colorElements(elements, color) {
  for (const element of elements) {
    colorElement(element, color);
  }
}

function aggregateIsolateElements(viewer, elements) {
  const dbIdsByModel = getElementsDbIdsByModel(elements);
  const selection = [];

  for (const [model, dbIds] of dbIdsByModel) {
    if (model.selector) {
      selection.push({ model, ids: dbIds });
    }
  }

  viewer.impl.visibilityManager.aggregateIsolate(selection);
}

/**
 * Set aggregate selection on a list of elements
 * @param {SystemElement[]} elements
 */
function aggregateSelectElements(viewer) {let elements = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  const dbIdsByModel = getElementsDbIdsByModel(elements);
  const selection = [];

  for (const [model, dbIds] of dbIdsByModel) {
    if (model.selector) {
      selection.push({ model, ids: dbIds });
    }
  }

  viewer.setAggregateSelection(selection);
}

/**
 * Shows a set of rooms as "contextual" by increasing their respective opacity.
 * @param viewer
 * @param rooms
 * @return {(function(): void)} Call to clean the effect up.
 */
function showContextualRooms(viewer, rooms) {
  const cleanups = [];
  for (const { dbId, model } of rooms.values()) {
    const fl = model.getFragmentList();
    const it = model.getInstanceTree();

    it.enumNodeFragments(dbId, (fragId) => {
      const mat = fl.getMaterial(fragId);
      if (!mat || !mat.isRoomMaterial) return;

      const cloned = viewer.impl.matman().cloneMaterial(mat, model);
      cloned.needsUpdate = true;
      cloned.transparent = true;
      cloned.opacity = 0.5;
      cloned.depthWrite = false;

      fl.setMaterial(fragId, cloned);
      cleanups.push(() => {
        cloned.dispose();
        fl.materialmap && fl.setMaterial(fragId, mat);
      });
    }, true);
  }

  viewer.impl.invalidate(true);

  return () => {
    let revertCb;
    while (revertCb = cleanups.pop()) {revertCb();}

    viewer.impl.invalidate(true);
  };
}

/**
 * @param {SystemElement} element
 * @param {Boolean} includeStart
 * @param {Boolean} skipInvalidate
 * @param {Number} color
 */
function colorUpstreamElements(viewer, element, includeStart, skipInvalidate) {let color = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : UPSTREAM_COLOR;
  if (!element) return;
  const upstreamElements = getUpstreamConnections(element, includeStart);
  colorElements(upstreamElements, color);

  !skipInvalidate && viewer.refresh(true);
}

/**
 * @param {SystemElement} element
 * @param {Boolean} includeStart
 * @param {Boolean} skipInvalidate
 * @param {Number} color
 */
function colorDownstreamElements(viewer, element, includeStart, skipInvalidate) {let color = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : DOWNSTREAM_COLOR;
  if (!element) return;
  const downstreamElements = getDownstreamConnections(element, includeStart);
  colorElements(downstreamElements, color);

  !skipInvalidate && viewer.refresh(true);
}

/**
 * @param {SystemElement} element
 * @param {Number} color
 */
function colorConnected(viewer, element) {let color = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : BLUE;
  if (!element) return;
  const connectedElements = getConnectedElements(element, true);
  colorElements(connectedElements, color);

  viewer.refresh(true);
}

/**
 * Color all elements that have 3 or more connections
 * @param {Number} color
 */
function colorForks(viewer, system) {let color = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : RED;
  for (const element of system.allElements()) {
    if (element.isFork()) {
      colorElement(element, color);
    }
  }

  viewer.refresh(true);
}

function colorNeighbours(viewer, element) {let color = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : GREEN;
  if (!element) return;
  const neighbourElements = element.getNeighbours(true);
  colorElements(neighbourElements, color);

  viewer.refresh(true);
}

/**
 * @param {SystemElement[]} elements
 * @returns {boolean} true if any connection exists between two elements within a list of elements
 */
function hasConnection() {let elements = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  for (let i = 0; i < elements.length - 1; i++) {
    for (let j = i; j < elements.length; j++) {
      if (elements[i].getConnectionType(elements[j]) !== null) {
        return true;
      }
    }
  }
  return false;
}

/**
 * @param {SystemElement[]} elements
 * @returns {boolean} true if connection exists between all elements within a list of elements
 */
function allConnected() {let elements = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  for (let i = 0; i < elements.length - 1; i++) {
    for (let j = i + 1; j < elements.length; j++) {
      if (elements[i].getConnectionType(elements[j]) === null) {
        return false;
      }
    }
  }

  return true;
}

function disconnectMultiple() {let elements = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  for (let i = 0; i < elements.length - 1; i++) {
    for (let j = i + 1; j < elements.length; j++) {
      removeConnection(elements[i], elements[j]);
    }
  }
}

/**
 * Color group elements by sub-systems
 * IE. groups of elements where every node has a path to every other node
 */
function colorSubSystems(viewer, system) {
  const subSystems = getSubSystems(system);

  for (const subSystemIdx in subSystems) {
    const color = distinctColors[subSystemIdx % distinctColors.length];
    colorElements(subSystems[subSystemIdx], color);
  }
  viewer.refresh(true);
}

/**
 * Remove connection between elements
 * @param {SystemElement} element1
 * @param {SystemElement} element2
 */
function removeConnection(element1, element2) {
  element1.disconnect(element2);
  element2.disconnect(element1);
}

/**
 * Set connection between elements
 * @param {SystemElement} prev
 * @param {SystemElement} next
 * @param {(prev: SystemElement, next: SystemElement)} propagate
 */
function createConnection(prev, next, propagate) {
  prev.connect(next, SystemConnectionType.Next);
  next.connect(prev, SystemConnectionType.Previous);

  if (propagate instanceof Function) {
    propagate(prev, next);
  }
}

function _getElementsRecursive(element, direction) {let acc = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new Set();
  if (acc.has(element)) {
    return;
  }

  acc.add(element);

  element.enumConnections(direction, (_, conn) => _getElementsRecursive(conn, direction, acc));
}

/**
 * Get all elements connected to start element, regardless of connection type.
 * @param {SystemElement} element Start element
 * @param {boolean} inclusiveSelect To include start element in the results
 * @returns {[SystemElement]}
 */
function getConnectedElements(element) {let inclusiveSelect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let elements = new Set();
  _getElementsRecursive(element, null, elements);

  if (!inclusiveSelect) {
    elements.delete(element);
  }

  return [...elements];
}

/**
 * Get all elements upstream from start element
 * @param {SystemElement} element Start element
 * @param {boolean} inclusiveSelect To include start element in the results
 * @returns {[SystemElement]}
 */
function getUpstreamConnections(element) {let inclusiveSelect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let elements = new Set();
  _getElementsRecursive(element, SystemConnectionType.Previous, elements);

  if (!inclusiveSelect) {
    elements.delete(element);
  }

  return [...elements];
}

/**
 * Get all elements downstream from start element
 * @param {SystemElement} element Start element
 * @param {boolean} inclusiveSelect To include start element in the results
 * @returns {[SystemElement]}
 */
function getDownstreamConnections(element) {let inclusiveSelect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let elements = new Set();
  _getElementsRecursive(element, SystemConnectionType.Next, elements);

  if (!inclusiveSelect) {
    elements.delete(element);
  }

  return [...elements];
}

/**
 * @returns {Array<Set<SystemElement>>} Sub systems
 */
function getSubSystems(system) {
  const checked = new Set();
  const subSystems = [];

  function cb(element, acc) {
    if (checked.has(element)) {
      return;
    }

    checked.add(element);
    acc.add(element);

    element.enumConnections(null, (_, conn) => cb(conn, acc));
  }

  for (const element of system.allElements()) {
    const subSystem = new Set();

    cb(element, subSystem);

    if (subSystem.size) {
      subSystems.push(subSystem);
    }
  }

  return subSystems;
}

function getForks(system) {
  const forkElements = [];

  for (const element of system.allElements()) {
    if (element.isFork()) {
      forkElements.push(element);
    }
  }

  return forkElements;
}

/**
 * Toggle connection direction between two elements
 * @param {SystemElement} element1
 * @param {SystemElement} element2
 */
function toggleDirection(element1, element2) {
  const existingConnection = element1.getConnectionType(element2);

  // Order doesn't matter when there's no prior direction, flip it otherwise
  let prev = existingConnection === SystemConnectionType.Next ? element2 : element1;
  let next = existingConnection !== SystemConnectionType.Next ? element2 : element1;

  createConnection(prev, next);
}

/**
 * Set connection direction based on a single starting point
 * @param {SystemElement} element
 * @returns {SystemElement[]}
 */
function setDirectionStart(element) {
  return propagateSingleStart(element);
}

/**
 * Single starting point, breadth first
 * @param {SystemElement} element
 * @returns {SystemElement[]}
 */
function propagateSingleStart(element) {
  const traversed = new Set().add(element);
  const traversalQueue = [];

  const cb = (element, connection) => {
    if (!traversed.has(connection)) {
      // Sets direction regardless of previous connections
      createConnection(element, connection);

      traversed.add(connection);
      traversalQueue.push(() => connection.enumConnections(null, cb));
    }
  };

  element.enumConnections(null, cb);

  // Run previously queued traversal
  while (traversalQueue.length) {
    const queued = traversalQueue.shift();
    queued();
  }

  return traversed;
}

const skipCategories = new Set([
RC.Walls, RC.Floors, RC.Roofs, RC.Ceilings,
RC.Rooms, RC.MEPSpaces, RC.AnalyticSpaces, RC.Levels,
RC.Mass, RC.MassFloor, RC.MassForm]
);

function getNearby(facility, system, element) {let includeGhosted = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  let models = facility.getModels();

  tmpBox.makeEmpty();
  // Get facility bbox size to expand search box
  for (const model of models) {
    const modelBox = model.getBoundingBox(true);
    tmpBox.union(modelBox);
  }

  const targetModel = element.model;
  const targetDbId = element.dbId;
  const targetBox = new THREE.Box3();
  const targetIT = targetModel.getInstanceTree();

  targetIT.getNodeBox(targetDbId, boxArr);
  targetBox.min.set(boxArr[0], boxArr[1], boxArr[2]);
  targetBox.max.set(boxArr[3], boxArr[4], boxArr[5]);

  // Expand search box by 0.5% of the facility size
  tmpBox.getSize(tmpV3).multiplyScalar(0.005);
  targetBox.expandByVector(tmpV3);

  let res = [];

  for (let model of models) {

    const fl = model.getFragmentList();
    const mData = model.getData();

    let ids = new Set();

    const customIntersector = {
      intersectsBox: function (box) {
        if (targetBox.containsBox(box)) {
          return CONTAINS;
        } else if (targetBox.intersectsBox(box)) {
          return INTERSECTS;
        } else {
          return OUTSIDE;
        }
      }
    };

    // intersectFrustum already ensures that frag-bbox clips target-bbox prior to callback
    model.getIterator().intersectFrustum(customIntersector, (fragId, containmentKnown) => {
      const dbId = fl.getDbIds(fragId);

      if (model === targetModel && dbId === targetDbId || ids.has(dbId)) {
        return;
      }

      // Ignore system elements
      if (system.filter.systemClasses & mData.dbId2SystemClass[dbId]) {
        return;
      }

      // Ignore logical elements, room-bounders, room, spaces, and levels
      const flags = mData.dbId2flags[dbId];
      const catId = mData.dbId2catId[dbId];
      if (flags & KeyFlags.Logical || skipCategories.has(catId)) {
        return;
      }

      // Frag-bbox is entirely contained by scaled target-bbox, so close enough
      if (containmentKnown) {
        ids.add(dbId);
        return;
      }

      // Frag geom itself clips or is contained by target-bbox
      const geom = fl.getGeometry(fragId);
      fl.getWorldMatrix(fragId, tmpMat);

      if (geomIntersectsNDC(geom, tmpMat, targetBox)) {
        ids.add(dbId);
      }
    }, includeGhosted);

    if (ids.size) {
      res.push({ model, ids: [...ids] });
    }
  }

  return res;
}

export const ConnectivityUtils = {
  getConnectedElements,
  getUpstreamConnections,
  getDownstreamConnections,
  getSubSystems,
  getForks,
  getElementsDbIdsByModel,
  getElementsDbIdsByURN,
  getFilteredDbIdsByURN,
  countSystemElements,
  hasCenterlines,

  createConnection,
  removeConnection,
  disconnectMultiple,
  toggleDirection,

  colorElement,
  colorElements,
  colorConnected,
  colorNeighbours,
  colorSubSystems,
  colorForks,
  colorUpstreamElements,
  colorDownstreamElements,

  aggregateIsolateElements,
  aggregateSelectElements,
  showContextualRooms,

  hasConnection,
  allConnected,

  setDirectionStart,
  getNearby
};