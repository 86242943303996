import i18n from "i18next";

import { HeatmapConfigUi } from "./HeatmapConfigUi";
import { Button } from "../../gui/controls/Button";
import { AlertIcon, CheckmarkIcon, HeatmapIcon, OfflineIcon, PinIcon, PulseIcon, WarningIcon } from "./icons";
import { SubMenu } from "../../gui/controls/SubMenu";
import { TOOLBAR } from "../../gui/GuiViewerToolbarConst";
import * as dte from "../DtEventTypes";
import * as et from "../../application/EventTypes";
import { DtConstants } from "../schema/DtConstants";

function setButtonDisabled(btn, isDisabled) {
  if (isDisabled) {
    btn.setState(Button.State.DISABLED);
    return;
  }
  if (btn.getState() === Button.State.DISABLED) {
    btn.setState(Button.State.INACTIVE);
  }
}

/** View for the stream data visualization controls. */
export class DataVizControls {
  constructor(streamMgr, eventSource, heatmap, markers) {
    this.eventSource = eventSource;
    this.heatmap = heatmap;
    this.markers = markers;
    this.streamMgr = streamMgr;

    this.heatmapButton = this.createHeatmapButton();
    this.markersButton = this.createMarkersButton();

    this.offlineFilterButton = this.createOfflineFilterButton();
    this.alertFilterButton = this.createAlertFilterButton();
    this.warningFilterButton = this.createWarningFilterButton();
    this.normalFilterButton = this.createNormalFilterButton();

    this.toolbarButton = this.createMenu([
    this.heatmapButton,
    this.markersButton,
    this.offlineFilterButton,
    this.alertFilterButton,
    this.warningFilterButton,
    this.normalFilterButton]
    );

    this.boundOnHeatmapChanged = this.onHeatmapChanged.bind(this);
    this.boundOnStreamMarkersChanged = this.onStreamMarkersChanged.bind(this);
    this.boundOnStreamsInfoChanged = this.onStreamsInfoChanged.bind(this);
    this.boundOnIsolationChanged = this.onIsolationChanged.bind(this);
  }

  init(viewer) {
    this.viewer = viewer;
    this.viewer.waitForToolbar().
    then(() => this.addToolbarButton()).
    then(() => this.updateButtonState());
    this.heatmapCfgUi = new HeatmapConfigUi(viewer, this, this.heatmap, this.streamMgr);
    this.eventSource.addEventListener(dte.DT_HEATMAP_CHANGED_EVENT, this.boundOnHeatmapChanged);
    this.eventSource.addEventListener(dte.DT_STREAM_MARKER_CHANGED_EVENT, this.boundOnStreamMarkersChanged);
    this.eventSource.addEventListener(dte.DT_STREAMS_INFO_CHANGED_EVENT, this.boundOnStreamsInfoChanged);
    this.viewer.addEventListener(et.AGGREGATE_ISOLATION_CHANGED_EVENT, this.boundOnIsolationChanged);
  }

  dispose() {
    this.viewer.getToolbar()?.getControl(TOOLBAR.MODELTOOLSID).removeControl(this.toolbarButton);
    this.heatmapCfgUi?.dispose();
    this.eventSource.removeEventListener(dte.DT_HEATMAP_CHANGED_EVENT, this.boundOnHeatmapChanged);
    this.eventSource.removeEventListener(dte.DT_STREAM_MARKER_CHANGED_EVENT, this.boundOnStreamMarkersChanged);
    this.eventSource.removeEventListener(dte.DT_STREAMS_INFO_CHANGED_EVENT, this.boundOnStreamsInfoChanged);
    this.viewer.removeEventListener(et.AGGREGATE_ISOLATION_CHANGED_EVENT, this.boundOnIsolationChanged);
  }

  /**
   * Toggles the disabled status of the parent control
   * @param isDisabled
   */
  setDisabled(isDisabled) {
    setButtonDisabled(this.heatmapButton, isDisabled);
    setButtonDisabled(this.markersButton, isDisabled);
    setButtonDisabled(this.offlineFilterButton, isDisabled);
    setButtonDisabled(this.alertFilterButton, isDisabled);
    setButtonDisabled(this.warningFilterButton, isDisabled);
    setButtonDisabled(this.normalFilterButton, isDisabled);
  }

  /**
   * Sets the visibility of both inner controls.
   * @param isHeatmapVisible
   * @param isMarkerVisible
   */
  setVisibility(isHeatmapVisible, isMarkerVisible) {
    this.setHeatmapVisibility(isHeatmapVisible);
    this.setMarkersVisibility(isMarkerVisible);
  }

  /**
   * Sets the visibility of the heatmap widget.
   * @param isVisible
   */
  setHeatmapVisibility(isVisible) {
    if (this.heatmapButton.getState() !== Button.State.DISABLED) {
      this.heatmapCfgUi.setVisible(isVisible);
      this.heatmapButton.setState(isVisible ? Button.State.ACTIVE : Button.State.INACTIVE);
    }
  }

  /**
   * Sets the visibility of the stream markers.
   * @param isVisible
   */
  setMarkersVisibility(isVisible) {
    if (this.markersButton.getState() !== Button.State.DISABLED) {
      this.markers.setVisibility(isVisible);
    }
  }

  /**
   * Gets the visibility of both inner controls.
   * @returns {boolean[]}
   */
  getVisibility() {
    if (this.toolbarButton.getState() !== Button.State.ACTIVE) {
      return [false, false];
    }
    return [this.heatmapButton, this.markersButton].map((ctrl) => ctrl.getState() === Button.State.ACTIVE);
  }

  /**
   * Adds itself to the toolbar
   * @private
   */
  addToolbarButton() {
    const wasAdded = this.viewer.getToolbar().
    getControl(TOOLBAR.MODELTOOLSID).
    addControl(this.toolbarButton);

    console.assert(wasAdded, 'Unable to add stream visualization toolbar control');
  }

  /**
   * Creates the heatmap button control.
   * @returns {Autodesk.Viewing.UI.Button}
   * @private
   */
  createHeatmapButton() {
    const button = new Button('toolbar-heatmap');
    button.icon.innerHTML = HeatmapIcon;
    button.setIcon('icon-heatmap');
    button.setState(Button.State.DISABLED);
    button.setToolTip(i18n.t('Heatmap'));
    button.onClick = () => {
      this.setHeatmapVisibility(button.getState() === Button.State.INACTIVE);
    };
    return button;
  }

  /**
   * Creates the stream marker button control.
   * @returns {Autodesk.Viewing.UI.Button}
   * @private
   */
  createMarkersButton() {
    const button = new Button('toolbar-streams-pin');
    button.icon.innerHTML = PinIcon;
    button.setIcon('icon-streams-pin');
    button.setState(Button.State.DISABLED);
    button.setToolTip(i18n.t('Stream pins'));
    button.onClick = () => {
      this.markers.setVisibility(button.getState() === Button.State.INACTIVE);
    };
    return button;
  }

  /**
   * Creates the button which toggles visibility of stream markers which are in "offline" state.
   * @returns {Autodesk.Viewing.UI.Button}
   * @private
   */
  createOfflineFilterButton() {
    const button = new Button('toolbar-offline-pin');
    button.icon.innerHTML = OfflineIcon;
    button.setIcon('icon-streams-offline');
    button.setToolTip(button.getState() === Button.State.INACTIVE ? i18n.t('Show offline pins') : i18n.t('Hide offline pins'));
    button.onClick = () => {
      const enabledMarkerTypes = this.getEnabledMarkerTypes();
      button.getState() === Button.State.INACTIVE ?
      enabledMarkerTypes.add(DtConstants.StreamStates.Offline) :
      enabledMarkerTypes.delete(DtConstants.StreamStates.Offline);
      this.markers.setVisibility(enabledMarkerTypes.size > 0, enabledMarkerTypes);
    };
    return button;
  }

  /**
   * Creates the button which toggles visibility of stream markers which are in "alert" state.
   * @returns {Autodesk.Viewing.UI.Button}
   * @private
   */
  createAlertFilterButton() {
    const button = new Button('toolbar-alert-pin');
    button.icon.innerHTML = AlertIcon;
    button.setIcon('icon-streams-alert');
    button.setToolTip(button.getState() === Button.State.INACTIVE ? i18n.t('Show alert pins') : i18n.t('Hide alert pins'));
    button.onClick = () => {
      const enabledMarkerTypes = this.getEnabledMarkerTypes();
      button.getState() === Button.State.INACTIVE ?
      enabledMarkerTypes.add(DtConstants.StreamStates.Alert) :
      enabledMarkerTypes.delete(DtConstants.StreamStates.Alert);
      this.markers.setVisibility(enabledMarkerTypes.size > 0, enabledMarkerTypes);
    };
    return button;
  }

  /**
   * Creates the button which toggles visibility of stream markers which are in "warning" state.
   * @returns {Autodesk.Viewing.UI.Button}
   * @private
   */
  createWarningFilterButton() {
    const button = new Button('toolbar-warning-pin');
    button.icon.innerHTML = WarningIcon;
    button.setIcon('icon-streams-warning');
    button.setToolTip(button.getState() === Button.State.INACTIVE ? i18n.t('Show warning pins') : i18n.t('Hide warning pins'));
    button.onClick = () => {
      const enabledMarkerTypes = this.getEnabledMarkerTypes();
      button.getState() === Button.State.INACTIVE ?
      enabledMarkerTypes.add(DtConstants.StreamStates.Warning) :
      enabledMarkerTypes.delete(DtConstants.StreamStates.Warning);
      this.markers.setVisibility(enabledMarkerTypes.size > 0, enabledMarkerTypes);
    };
    return button;
  }

  /**
   * Creates the button which toggles visibility of stream markers which are in "normal" state.
   * @returns {Autodesk.Viewing.UI.Button}
   * @private
   */
  createNormalFilterButton() {
    const button = new Button('toolbar-normal-pin');
    button.icon.innerHTML = CheckmarkIcon;
    button.setIcon('icon-streams-normal');
    button.setToolTip(button.getState() === Button.State.INACTIVE ? i18n.t('Show normal pins') : i18n.t('Hide normal pins'));
    button.onClick = () => {
      const enabledMarkerTypes = this.getEnabledMarkerTypes();
      button.getState() === Button.State.INACTIVE ?
      enabledMarkerTypes.add(DtConstants.StreamStates.Normal) :
      enabledMarkerTypes.delete(DtConstants.StreamStates.Normal);
      this.markers.setVisibility(enabledMarkerTypes.size > 0, enabledMarkerTypes);
    };
    return button;
  }

  /**
   * Creates the sub-menu (fly-out) control, and adds sub controls.
   * @param controls Controls to be present in the sub-menu (in-order)
   * @returns {Autodesk.Viewing.UI.SubMenu}
   * @private
   */
  createMenu(controls) {
    const menu = new SubMenu('toolbar-dataviz');
    menu.icon.innerHTML = PulseIcon;
    menu.setIcon('icon-dataviz');
    menu.setState(Button.State.DISABLED);
    menu.setToolTip(i18n.t('Stream Data Visualization'));

    controls.forEach((control) => menu.addControl(control));

    return menu;
  }

  /**
   * On changes to the heatmap visibility or configuration.
   * @param attrIdFilter The new stream attribute
   * @param ctype Type of change
   * @private
   */
  onHeatmapChanged(_ref) {let { change: { attrIdFilter, ctype } } = _ref;
    if (!this.heatmapCfgUi) return;

    switch (ctype) {
      case 'shading':
        return this.heatmapCfgUi.setActiveAttribute(attrIdFilter);
      case 'range':
        return this.heatmapCfgUi.updateScale(attrIdFilter);
    }
  }

  /**
   * Aggregate isolation changed.
   * @private
   */
  onIsolationChanged() {
    const defaultModel = this.streamMgr.defaultModel;
    const isVisible = defaultModel && this.streamMgr.facility.isModelVisible(defaultModel);

    if (!isVisible) {
      this.setVisibility(false, false);
      this.setDisabled(true);
    } else {
      this.updateButtonState();
    }
  }

  /**
   * Stream info changed.
   * @private
   */
  onStreamsInfoChanged() {
    this.updateButtonState();
  }

  /**
   * Stream marker visibility or configuration changed.
   * @param isVisible Whether the markers are now visible
   * @param markerTypes Set of marker states which should be displayed (alert, warning, normal)
   * @param ctype Change type
   * @private
   */
  onStreamMarkersChanged(_ref2) {let { change: { isVisible, markerTypes, ctype } } = _ref2;
    if (ctype === 'visibility' && this.markersButton.getState() !== Button.State.DISABLED) {
      this.markersButton.setState(isVisible ? Button.State.ACTIVE : Button.State.INACTIVE);

      this.offlineFilterButton.setState(markerTypes.has(DtConstants.StreamStates.Offline) ? Button.State.ACTIVE : Button.State.INACTIVE);
      this.alertFilterButton.setState(markerTypes.has(DtConstants.StreamStates.Alert) ? Button.State.ACTIVE : Button.State.INACTIVE);
      this.warningFilterButton.setState(markerTypes.has(DtConstants.StreamStates.Warning) ? Button.State.ACTIVE : Button.State.INACTIVE);
      this.normalFilterButton.setState(markerTypes.has(DtConstants.StreamStates.Normal) ? Button.State.ACTIVE : Button.State.INACTIVE);
    }
  }

  /**
   * Sets enabled-ness of controls based on the presence of displayable stream and stream attributes
   * @returns {Promise<void>}
   * @private
   */
  async updateButtonState() {
    const streamInfos = await this.streamMgr.getAllStreamInfos();
    const hasStreams = streamInfos.length > 0;
    if (!hasStreams) this.markers.setVisibility(false);
    setButtonDisabled(this.markersButton, !hasStreams);
    setButtonDisabled(this.offlineFilterButton, !hasStreams);
    setButtonDisabled(this.alertFilterButton, !hasStreams);
    setButtonDisabled(this.warningFilterButton, !hasStreams);
    setButtonDisabled(this.normalFilterButton, !hasStreams);

    const hasStreamAttrs = streamInfos.flatMap((s) => s.streamAttrs).length > 0;
    if (!hasStreamAttrs) await this.heatmap.show(null);
    setButtonDisabled(this.heatmapButton, !hasStreamAttrs);
    // Update heatmap Cfg UI from here instead of registering it for events.
    this.heatmapCfgUi?.setStreamInfos(streamInfos);
  }


  /**
   * Computes the enabled marker types set from the marker type filter buttons states
   * @returns {Set<DtConstants.StreamStates>}
   * @private
   */
  getEnabledMarkerTypes() {
    const enabledMarkerTypes = new Set();

    if (this.offlineFilterButton.getState() === Button.State.ACTIVE) enabledMarkerTypes.add(DtConstants.StreamStates.Offline);
    if (this.alertFilterButton.getState() === Button.State.ACTIVE) enabledMarkerTypes.add(DtConstants.StreamStates.Alert);
    if (this.warningFilterButton.getState() === Button.State.ACTIVE) enabledMarkerTypes.add(DtConstants.StreamStates.Warning);
    if (this.normalFilterButton.getState() === Button.State.ACTIVE) enabledMarkerTypes.add(DtConstants.StreamStates.Normal);

    return enabledMarkerTypes;
  }
}