import { fetchJson } from "../net/fetch";
import { DtConstants } from "./schema/DtConstants";
import { DT_FACILITY_CHANGED_EVENT } from "./DtEventTypes";

export class DtFacilityTemplate {
  /**
   * @param {DtFacility} facility
   */
  constructor(facility, template) {
    this.facility = facility;
    this.data = template;
  }

  urn() {
    return this.facility.urn();
  }

  async _fetch() {
    try {
      const template = await fetchJson(this.facility.loadContext, `/twins/${this.urn()}/inlinetemplate`);
      upgradeApplicationFilter(template); // ensure backward compatibility with existing templates
      this.data = template;
    } catch (e) {
      if (e.status === 404) {
        console.info(`No template applied for facility ${this.urn()}`);
      } else {
        console.error(e);
      }
      this.data = undefined;
    }
    this.loadPromise = null;
    return this.data;
  }

  async get(forceRefresh) {
    // note: this object is initialized with a templateSkeleton (as part of the facility settings)
    // this satisfies some needs (e.g. getting the ID of used classificartion) but not all.
    // callers of this getter look for the inlined template which requires a server roundtrip.
    // TODO: we should entangle the template skeleton from the inlined one but this is a larger
    // change that needs more planning
    // also note, that no data is not considered a refresh reason
    if (forceRefresh || this.data && !this.data.classification) {
      return this.loadPromise || (this.loadPromise = this._fetch());

    }

    return this.data;
  }

  getClassificationID() {
    return this.data?.classificationId;
  }

  async apply(template, skipMapping) {
    const payload = { template };
    if (skipMapping) payload.queryParams = 'skipTask';

    await this._updateFacilityTemplate(template, payload);
  }

  validateApply(template) {
    return fetchJson(this.facility.loadContext, `/twins/${this.urn()}/validate-template`, "POST", template);
  }

  async unapply() {
    await this._updateFacilityTemplate(undefined, { deleteMode: true });
  }

  /**
   * Opportunistically update the local cache before updating DB to make sure any calls would
   * resolve to the latest data, see discussion https://git.autodesk.com/tandem/dt-client/pull/2727
   * @param {Object|undefined} newValue of the facility template
   * @param {Object} payload of the asyncDatabaseOperation for APPLY_TEMPLATE
   */
  async _updateFacilityTemplate(newValue, payload) {
    const prev = this.data;
    this.data = newValue;
    try {
      await Promise.all([
      // the actual operation
      this.facility.asyncDatabaseOperation({ operation: "APPLY_TEMPLATE", ...payload }),
      // the (in this case synthetic) event triggered after the template application was handled
      // by DtFacility.onFacilityChanged (updating attributes etc)
      this.facility.app.waitForEvent(
        DT_FACILITY_CHANGED_EVENT,
        {
          ctype: newValue ? DtConstants.ChangeTypes.ApplyTemplate : DtConstants.ChangeTypes.RemoveTemplate,
          twinId: this.facility.urn(),
          isOwn: true
        })]
      );
    } catch (error) {
      this.data = prev;
      throw error;
    }
  }
}

// legacy application filters are stored with "masterFormat" as key to indicate any sort of user classification.
// this method updates them in-place.
function upgradeApplicationFilter(template) {
  for (const pset of template.psets) {
    for (const param of pset.parameters) {
      if (!param.applicationFilters?.userClass && param.applicationFilters?.masterFormat) {
        param.applicationFilters.userClass = param.applicationFilters.masterFormat;
        delete param.applicationFilters.masterFormat;
      }
    }
  }
}