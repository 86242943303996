import { DtWs } from "../net/DtWebSocket";

const WebSocket = require('isomorphic-ws');

const DT_MSG_CLIENT_RECREATE = 4001;

//DT web socket protocol.
export class DtMsgWs extends DtWs {

  constructor(loadContext, errorCB, sessionId)
  {let onReconnect = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};

    super("msg_ws", loadContext, null, errorCB);

    this.sessionId = sessionId;
    this.changeSubscribers = {};
    this.onReconnect = onReconnect;
    this.pendingReconnect = null;
    this.lastMessageReceivedAt = {};
  }

  // if this method gets called multiple times,
  // later subscribers just take over.
  // This happens when a DtModel gets loaded for instance.
  // TODO consider allowing multiple callbacks per modelId,
  // plus unsubscribe for making things more obvious
  subscribeForChanges(targetId, cb) {

    //console.log("Subscribing for", targetId);

    if (!this._wsUsable || !cb) {
      return;
    }

    const prevSubscriber = this.changeSubscribers[targetId];

    this.changeSubscribers[targetId] = cb;

    if (!prevSubscriber) {
      //If we don't have a web socket yet, the subscription
      //will happen in onOpen().
      const ws = this.getWebSocket();
      if (ws) {
        const ts = this.lastMessageReceivedAt[targetId] /* || Math.floor(new Date().getTime()) - 60 * 60 * 1000*/;

        if (!ts) {
          // TODO: uncomment to enable replay events on reconnect
          this.lastMessageReceivedAt[targetId] = new Date().getTime();
        }

        ws.send(`/subscribe/${targetId}${
        ts > 0 ?
        "/offlinesince/" + ts :
        ""
        }`
        );
      }
    }
  }

  unsubscribeFromChanges(targetId) {
    console.log("Unsubscribing from", targetId);
    const ws = this.getWebSocket();
    if (ws) {
      ws.send(`/unsubscribe/${targetId}`);
    }

    delete this.lastMessageReceivedAt[targetId];
    delete this.changeSubscribers[targetId];
  }

  replayOnReconnect() {
    const ws = this.getWebSocket();
    if (ws?.readyState === WebSocket.OPEN) {
      this.subscribeTargets(true);
    } else {
      this._pendingCallbacks.push(() => this.subscribeTargets(true));
    }
  }

  subscribeTargets() {let isReplayOnReconnect = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    for (let targetId in this.changeSubscribers) {
      const ts = this.lastMessageReceivedAt[targetId] /*(new Date().getTime()) - 60 * 60 * 1000*/;
      // const ts = (new Date().getTime()) - 60 * 60 * 1000;
      this.ws.send(`/subscribe/${targetId}${isReplayOnReconnect ? "/offlinesince/" + ts : ""}`);

      if (!ts) {
        this.lastMessageReceivedAt[targetId] = new Date().getTime();
      }
    }
  }

  onOpen(event) {
    super.onOpen(event);
    this.subscribeTargets();
  }

  onMessage(data) {
    let change;
    try {
      change = JSON.parse(data);
    } catch (err) {
      console.error(`Failed to parse change notification ${data}`, err);
      return;
    }

    if (this.sessionId && change.sessionId && this.sessionId === change.sessionId) {
      // This is the change notification, originating from the same browser session
      change.isOwn = true;
    }
    // Now we need to route the change to the proper model
    //console.log('Change received', change);
    let targetId = change.modelId || change.twinId || change.groupId;
    // TODO: uncomment to enable replay events on reconnect
    this.lastMessageReceivedAt[targetId] = new Date().getTime();

    // No invalidation or OT rebuild required if it's from a local change
    if (change.isOwn) {
      const reducedEvent = { ...change, changedElements: `[${change.changedElements?.length}]` };
      console.info("ignore notification about own change", reducedEvent);
      return;
    } else {
      console.info("received remote event:", change.ctype);
    }

    let cb = this.changeSubscribers[targetId];
    cb && cb(change);
    if (!cb) {
      console.warn(`${targetId} is not subscribed`);
    }
  }

  onClose(event) {
    console.log("DTMSG CLOSED:", event);
    // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent#status_codes
    const reconnectOn = [1006, 1012, 1013, 1014];

    if (reconnectOn.includes(event.code)) {
      this.reconnect();
    }
  }

  onError(event) {
    console.log("DTMSG ERR:", event);
    this.reconnect();
  }

  reconnect() {let immediate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    console.log("Trying to reconnect");
    if (this.pendingReconnect) {
      return;
    }
    this.pendingReconnect = true;

    if (immediate) {
      this._reconnectImpl(0, immediate);
      return;
    }

    if (navigator?.onLine) {
      console.log("Reconnecting now, navigator", navigator?.onLine);
      // The client has a network connection, but ws connection was dropped anyway, retry once
      this._reconnectImpl(Math.round(15000 + Math.random() * 1000));
    }
  }

  _reconnectImpl() {let delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 5000;let immediate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const onDone = (ws) => {
      if (ws?.readyState === WebSocket.OPEN) {
        console.log("WS is back online");
        this.onReconnect();
        this.pendingReconnect = false;
      }
    };

    if (immediate) {
      const ws = this.getWebSocket();

      // in case of connectivity loss, existing socket might stay in READY state for a long time, so we just force close it
      ws?.close(DT_MSG_CLIENT_RECREATE);
    }

    this._opening = false;
    // clear callbacks queue
    this._pendingCallbacks = [];
    setTimeout(() => {
      this.startSession(this.loadContext, onDone);
      if (this.reconnectOnOnline) {
        // after client went back online, we only try to reconect once
        clearInterval(this.reconnectOnOnline);
      }
    }, delay ?? 0);
  }
}