
import { theExtensionManager } from "../src/application/ExtensionManager";
import { externalExtensions, getExtensionEntryKey } from './externalExtensions';


const ext = BUILD_FLAG__MINIFIED_BUILD ? 'min.js' : 'js';

// Register them all
externalExtensions.forEach((ee) => {

  let key = getExtensionEntryKey(ee);
  let filePath = `extensions/${key}/${key}.${ext}`;
  let dependencies = ee.dependencies;
  ee.ids.forEach((id) => {
    theExtensionManager.registerExternalExtension(id, filePath, dependencies);
  });
});