import { isMobileDevice, getGlobal, isNodeJS } from "../../compat";
import { BufferGeometryUtils } from "../../wgs/scene/BufferGeometry";
import { createWorker } from "../DtWorkerCreator";
import { initLoadContext } from "../../net/endpoints";
import { EventDispatcher } from "../../application/EventDispatcher";
import * as et from "../../application/EventTypes";
import { getParameterByName } from "../../globals";
import { ProgressState } from "../../application/ProgressState";
import { PriorityQueue } from "./PriorityQueue";

export var MESH_RECEIVE_EVENT = "meshReceived";
export var MESH_FAILED_EVENT = "meshFailed";
export var MATERIAL_RECEIVE_EVENT = "materialReceived";
export var MATERIAL_FAILED_EVENT = "materialFailed";

const MAX_REQUESTS_PER_WORKER = 10000;
const INITIAL_REQUESTS_PER_WORKER = 200;

getGlobal().USE_OPFS = true;

let useOpfs, disableIndexedDb, disableWebSocket;

function initLoadContextGeomCache(msg) {

  if (useOpfs === undefined) {
    // DISABLE_INDEXED_DB is set via the viewer settings (disable local cache)
    // We should probably rename it to DISABLE_CACHE once we remove the IndexedDB code.
    useOpfs = (getGlobal().USE_OPFS || getParameterByName('useOPFS') === 'true') && !getGlobal().DISABLE_INDEXED_DB;
    disableIndexedDb = useOpfs || getParameterByName("disableIndexedDb").toLowerCase() === "true" || getGlobal().DISABLE_INDEXED_DB;
    disableWebSocket = getParameterByName("disableWebSocket").toLowerCase() === "true" || getGlobal().DISABLE_WEBSOCKET;
  }

  var ctx = initLoadContext(msg);
  ctx.useOpfs = useOpfs;
  ctx.disableIndexedDb = disableIndexedDb;
  ctx.disableWebSocket = disableWebSocket;
  return ctx;
}

// Helper function used for cache cleanup
function compareGeomsByImportance(geom1, geom2) {
  return geom1.importance - geom2.importance;
}

/** Shared cache of BufferGeometries and material JSONs used by different OtgLoaders. */
export function DtResourceCache() {

  // all geometries, indexed by geom hashes
  var _geoms = new Map();
  var _mats = new Map();

  // A single geometry may be requested by one or more model loaders.
  // This map keeps track of requests already in progress so that
  // we don't issue multiple simultaneously
  var _hash2Requests = new Map();

  var _workTotal = 0;
  var _workDone = 0;
  var _currentProgress = -1;

  // worker for geometry loading
  var NUM_WORKERS = 1; //isMobileDevice() ? 2 : 6;
  // This value changes with the number of enqueued requests.
  // We start with a low value to properly prioritize more queued up requests first.
  var _requestsPerWorker = INITIAL_REQUESTS_PER_WORKER;
  var _workers = [];

  for (var i = 0; i < NUM_WORKERS; i++) {
    const worker = createWorker('Geometry Worker');
    worker.addEventListener('message', handleMessage);
    _workers.push(worker);
  }

  // worker for fragment list loading
  var _fragListWorker = createWorker('Fraglist Worker');
  _fragListWorker.addEventListener('message', handleFragListMessage);
  var _fragListCallbacks = new Map();

  this.initialized = true;

  // track memory consumption
  this.byteSize = 0;
  this.refCount = 0;

  // A request is called in-progress if we have sent it to the worker and didn't receive a result yet.
  // We restrict the number of _requestsInProgress. If the limit is reached, all additional requests
  // are enqueued in _waitingRequests.
  var _requestsInProgress = 0;
  var _totalRequested = 0;
  var _totalReceived = 0;
  var _lifeTimeTotal = 0;


  var _timeout = undefined;

  var _queue = new PriorityQueue((a, b) => a.importance > b.importance);

  var _this = this;

  // mem limits for cache cleanup
  var MB = 1024 * 1024;
  var _maxMemory = 100 * MB; // geometry limit at which cleanup is activated
  var _minCleanup = 50 * MB; // minimum amount of freed memory for a single cleanup run

  var _timeStamp = 0; // used for cache-cleanup to identify which geoms are in use

  // A cleanup will fail if there are no unused geometries anymore.
  // If this happens, we skip cleanup until the next model unload occurs.
  var _allGeomsInUse = false;

  function onModelUnloaded() {
    _allGeomsInUse = false;
  }

  // Needed for cache-cleanup to check which RenderModels are loaded
  var _viewers = [];

  this.addViewer = function (viewer) {
    viewer.addEventListener(et.MODEL_UNLOADED_EVENT, onModelUnloaded);
    _viewers.push(viewer);
  };

  this.removeViewer = function (viewer) {
    const index = _viewers.indexOf(viewer);

    if (index !== -1) {
      viewer.removeEventListener(et.MODEL_UNLOADED_EVENT, onModelUnloaded);
      _viewers.splice(index, 1);
    }

    if (_viewers.length === 0) {
      this.dtor();
    }
  };

  this.dtor = function () {
    _viewers = [];

    for (var i = 0; i < NUM_WORKERS; i++) {
      _workers[i].removeEventListener('message', handleMessage);
      _workers[i].terminate();
    }

    this.initialized = false;
  };

  this.isIdle = function () {
    //The logic here is that the cache can be idle only after it has loaded something.
    //It will not be idle initially, when nothing has yet been loaded or queued for loading.
    //This ensures that code that waits for geometry loading to be "done" does not activate too early.
    //Note that this is consistent with calls to onGeomLoadComplete() which trigger only after _something_
    //is loaded.
    return _workTotal === 0 && _queue.isEmpty() && _lifeTimeTotal > 0;
  };

  this.addWorkInProgress = function (count) {
    _workTotal += count;
    for (let i = 0; i < _viewers.length; i++) {
      _viewers[i].impl.trackFrameBudget(false);
    }
  };

  this.updateProgress = function (count) {
    _workDone += count;

    //Signal loading progress to the attached viewers
    const loadProgressPercent = _workDone / _workTotal * 100;
    const loadProgressRounded = Math.round(loadProgressPercent);
    if (_currentProgress !== loadProgressRounded) {
      _currentProgress = loadProgressRounded;
      for (let i = 0; i < _viewers.length; i++) {
        _viewers[i].impl.signalProgress(_currentProgress, ProgressState.LOADING, null);
      }
    }

    //Reset progress percentage when we reach an idle state
    if (loadProgressPercent >= 100 && _workTotal > 0 && _workDone === _workTotal) {
      // Reset stats
      _lifeTimeTotal += _workTotal;
      _workTotal = 0;
      _workDone = 0;
      _currentProgress = -1;
      _requestsPerWorker = INITIAL_REQUESTS_PER_WORKER;

      // Tell the viewer to repaint once we reach idle state (presumably we are done loading something)
      for (let i = 0; i < _viewers.length; i++) {
        _viewers[i].impl.onGeomLoadComplete();
        _viewers[i].impl.trackFrameBudget(true);
      }
    }
  };

  function scheduleQueueProcessing() {
    if (!_timeout && !_queue.isEmpty()) {
      _timeout = setTimeout(() => {
        _timeout = null;
        processQueuedItems();
      }, 0);
    }
  }

  // function to handle messages from OtgLoadWorker (posted in onGeometryLoaded)
  function handleMessage(msg) {

    if (!msg.data) {
      return;
    }

    if (msg.data.error) {
      var error = msg.data.error;

      // get hash for which request failed
      var hash = error.args ? error.args.hash : undefined;

      var type = error.args ? error.args.type : "g";

      // inform affected clients.
      if (hash) {
        if (type === "m") {
          _mats.set(hash, error); //create an error entry in the cache
          _this.fireEvent({ type: MATERIAL_FAILED_EVENT, error: error });
          console.warn("Error loading material", hash);
        } else {
          _geoms.set(hash, error); //create an error entry in the cache
          _this.fireEvent({ type: MESH_FAILED_EVENT, error: error });
          console.warn("Error loading mesh", hash);
        }

        _hash2Requests.delete(error.hash);

        // track number of requests in progress
        _requestsInProgress--;
        _totalReceived++;

        //Schedule another spin through the task queue
        scheduleQueueProcessing();
      }
    } else if (msg.data.material) {
      var mdata = msg.data;
      // add material to cache
      var hash = mdata.hash;
      var mat = mdata.material;
      _mats.set(hash, mat);

      // pass geometry to all receiver callbacks
      _this.fireEvent({ type: MATERIAL_RECEIVE_EVENT, material: mat, hash: hash });

      _hash2Requests.delete(mdata.hash);

      _requestsInProgress--;
      _totalReceived++;

      //Schedule another spin through the task queue
      scheduleQueueProcessing();

    } else {

      var meshlist = msg.data;

      // Request new geometries before processing the ones we just received
      _requestsInProgress -= meshlist.length;
      _totalReceived += meshlist.length;

      if (!_queue.isEmpty()) {
        if (_timeout) {
          clearTimeout(_timeout);
          _timeout = null;
        }
        processQueuedItems();
      }

      for (var i = 0; i < meshlist.length; i++) {

        var mdata = meshlist[i];

        if (mdata.hash && mdata.mesh) {
          // convert geometry data to GeometryBuffer (result is mdata.geometry)
          BufferGeometryUtils.meshToGeometry(mdata);

          // add geom to cache
          var hash = mdata.hash;
          var geom = mdata.geometry;
          _geoms.set(hash, geom);

          // track summed cache size in bytes
          _this.byteSize += geom.byteSize;

          // free old unused geoms if necessary
          _this.cleanup();

          // pass geometry to all receiver callbacks
          _this.fireEvent({ type: MESH_RECEIVE_EVENT, geom: geom });

          // recall and store the load order importance, so we can
          // later use it for unloading
          let msg = _hash2Requests.get(mdata.hash);
          geom.importance = msg.importance;

          _hash2Requests.delete(mdata.hash);
        }
      }
    }
  }

  function assignWorkerForTask(resId) {
    if (typeof resId === "number") {
      return resId % NUM_WORKERS;
    }

    return 0 | Math.random() * NUM_WORKERS;
  }

  function handleFragListMessage(msg) {
    if (!msg.data) {
      return;
    }

    const message = msg.data;
    const { onDone, onError, onData } = _fragListCallbacks.get(message.resourcePath);

    if (message.error) {
      onError(...message.error);
    } else if (message.done) {
      _fragListCallbacks.delete(message.resourcePath);
      onDone();
    } else {
      onData(message.chunk, message.dbHashes, message.geomHashes, message.matHashes);
    }
  }

  this.loadFragmentList = function (resourcePath, metadata, dbIdMap, geomIdMap, matIdMap, postData, onDone, onError, onData) {
    _fragListCallbacks.set(resourcePath, { onDone, onError, onData });

    const msg = {
      operation: "DT_LOAD_FRAGMENT_LIST",
      resourcePath,
      metadata,
      dbIdMap,
      geomIdMap,
      matIdMap,
      postData
    };

    _fragListWorker.doOperation(initLoadContextGeomCache(msg));
  };

  this.setFragmentListMetadata = function (resourcePath, metadata) {
    if (!_fragListCallbacks.has(resourcePath)) {
      return;
    }

    const msg = {
      operation: "DT_SET_FRAGMENT_METADATA",
      resourcePath,
      metadata
    };

    _fragListWorker.doOperation(initLoadContext(msg));
  };

  this.initWorker = function (modelId) {

    // Prepare load workers
    for (var i = 0; i < NUM_WORKERS; i++) {

      var msg = {
        operation: "INIT_WORKER_OTG",
        modelId
      };

      _workers[i].doOperation(initLoadContextGeomCache(msg));
    }
  };

  this.unregisterLoader = function (modelId) {
    // Tell the workers that a loader has gone idle
    for (var i = 0; i < NUM_WORKERS; i++) {
      var msg = {
        operation: "UNREGISTER_LOADER_OTG",
        modelId
      };

      _workers[i].doOperation(initLoadContextGeomCache(msg));
    }
  };

  this.updateMruTimestamps = function () {

    //Tell each worker which ranges of the geometry pack it's responsible for.
    for (var i = 0; i < NUM_WORKERS; i++) {

      var msg = {
        operation: "UPDATE_MRU_TIMESTAMPS_OTG",
        endSession: _queue.isEmpty()
      };

      _workers[i].doOperation(initLoadContextGeomCache(msg));
    }

  };

  this.getGeometry = function (geomHash) {
    return _geoms.get(geomHash);
  };

  /**  Get a geometry from cache or load it.
   *    @param {string}   url         - base request url of the geometry/ies resource -- geometry hash is added to the end of that when HTTP is used to load data
   *    @param {string}   geomHash    - hash key to identify requested geometry/ies
   *    @param {int} geomIdx          - the geometry ID/index in the model's geometry hash list (optional, pass 0 to skip use of geometry packs)
   *    @param {string}   queryParams - additional param passed to file query
   *    @param {Number} importance    - importance metric for the request geometry (e.g. surface area of the object bbox)
   */
  this.requestGeometry = function (url, geomHash, geomIdx, queryParams, importance) {

    // if this geometry is in memory, just return it directly
    var geom = _geoms.get(geomHash);
    if (geom && geom.args) {
      //it failed to load previously
      if (isNodeJS()) {
        setTimeout(() => this.fireEvent({
          type: MESH_FAILED_EVENT,
          error: geom,
          hash: geomHash,
          repeated: true
        }), 0);
      } else {
        this.fireEvent({ type: MESH_FAILED_EVENT, error: geom, hash: geomHash, repeated: true });
      }
      return;
    } else if (geom) {
      //it was already cached
      if (isNodeJS()) {
        setTimeout(() => this.fireEvent({ type: MESH_RECEIVE_EVENT, geom: geom }), 0);
      } else {
        this.fireEvent({ type: MESH_RECEIVE_EVENT, geom: geom });
      }
      return;
    }

    // if geometry is already loading, just increment
    // the request counter.
    var task = _hash2Requests.get(geomHash);
    if (task && task.refcount) {
      task.refcount++;
      return;
    }

    // geom is neither in memory nor loading.
    // we have to request it.
    var msg = {
      operation: "LOAD_CDN_RESOURCE_OTG",
      type: "g",
      url: url,
      hash: geomHash,
      queryParams: queryParams,
      importance: importance,
      geomIdx: geomIdx,
      refcount: 1
    };

    _totalRequested++;

    _queue.push(msg);
    _hash2Requests.set(geomHash, msg);

    // Update max number of in-flight requests, based on queue length
    this._updateMaxRequests();

    scheduleQueueProcessing();
  };


  this.requestMaterial = function (url, matHash, matIdx, queryParams) {

    // if this material is in memory, just return it directly
    var mat = _mats.get(matHash);
    if (mat && mat.args) {
      //it failed to load previously
      setTimeout(() => this.fireEvent({
        type: MATERIAL_FAILED_EVENT,
        error: mat,
        hash: matHash,
        repeated: true
      }), 0);
      return;
    } else if (mat) {
      //it was already cached
      setTimeout(() => this.fireEvent({ type: MATERIAL_RECEIVE_EVENT, material: mat, hash: matHash }), 0);
      return;
    }

    // if material is already loading, just increment
    // the request counter.
    var task = _hash2Requests.get(matHash);
    if (task && task.refcount) {
      task.refcount++;
      return;
    }

    // material is neither in memory nor loading.
    // we have to request it.
    var msg = {
      operation: "LOAD_CDN_RESOURCE_OTG",
      type: "m",
      urls: [url],
      hashes: [matHash],
      queryParams: queryParams,
      refcount: 1,
      importances: [0x7fffffff] //use highest priority for materials
    };

    _hash2Requests.set(matHash, msg);

    //Material requests are sent to the worker immediately, without going through the
    //priority queue.
    var whichWorker = assignWorkerForTask(matIdx);
    _workers[whichWorker].doOperation(initLoadContextGeomCache(msg));
    _requestsInProgress++;
    _totalRequested++;
  };


  function processQueuedItems() {

    var howManyCanWeDo = _requestsPerWorker * NUM_WORKERS - _requestsInProgress;

    if (howManyCanWeDo <= 0) {
      return;
    }

    var msgPerWorker = [];
    var tasksAdded = 0;

    while (!_queue.isEmpty() && tasksAdded < howManyCanWeDo) {

      var task = _queue.pop();

      //Find which worker thread is preferred for the task
      var whichWorker = assignWorkerForTask(task.geomIdx);
      var msg = msgPerWorker[whichWorker];

      if (!msg) {
        msg = {
          operation: "LOAD_CDN_RESOURCE_OTG",
          type: "g",
          urls: [task.url],
          hashes: [task.hash],
          importances: [task.importance],
          queryParams: task.queryParams
        };

        msgPerWorker[whichWorker] = msg;
      } else {
        msg.urls.push(task.url);
        msg.hashes.push(task.hash);
        msg.importances.push(task.importance);
      }

      tasksAdded++;
    }

    for (var i = 0; i < msgPerWorker.length; i++) {
      var msg = msgPerWorker[i];
      if (msg) {
        // send request to worker
        _workers[i].doOperation(initLoadContextGeomCache(msg));
        _requestsInProgress += msg.urls.length;
      }
    }
  }

  // remove all open requests of this client
  // input is a map whose keys are geometry hashes
  this.cancelRequests = function (geomHashMap) {

    for (var [hash] of geomHashMap) {
      var task = _hash2Requests.get(hash);

      if (task)
      task.refcount--;
      /*
      if (task.refcount === 1) {
      	_hash2Requests.delete(hash);
      }*/
    }

    var hiPrioList = [];
    var heap = _queue._heap;
    for (var i = 0; i < heap.length; i++) {
      var t = heap[i];
      var req = _hash2Requests.get(t.hash);
      if (req) {
        if (req.refcount)
        hiPrioList.push(t);else

        _hash2Requests.delete(t.hash);
      }
    }

    //TODO: perhaps we can leave requests with refcount = 0 in the queue
    //but sort the queue based on refcount so that those get deprioritized

    _totalRequested -= heap.length - hiPrioList.length;

    //Reset the priority queue with the remaining items
    _queue._heap.length = 0;
    for (let i = 0; i < hiPrioList.length; i++) {
      _queue.push(hiPrioList[i]);
    }

    // TODO: To make switches faster, we should also inform the worker thread,
    //       so that it doesn't spend too much time with loading geometries that noone is waiting for.

    if (hiPrioList.length === 0) {
      //Make sure geom load event gets fired if the queue is all of a sudden empty
      for (let i = 0; i < _viewers.length; i++) {
        _viewers[i].impl.onGeomLoadComplete();
      }
    }
  };

  this.cleanup = function () {

    var unusedGeoms = [];

    if (this.byteSize < _maxMemory) {
      return;
    }

    // get array of models in memory
    var loadedModels = [];

    for (var i = 0; i < _viewers.length; i++) {
      var mq = _viewers[i].impl.modelQueue();
      loadedModels = loadedModels.concat(mq.getModels().concat(_viewers[i].getHiddenModels()));
    }

    if (_allGeomsInUse) {
      // On last run, we discovered that we have no unused geometries anymore. As long as no model
      // is unloaded, we should not retry. Otherwise, we would waste a lot of time for each single new geometry.
      // Note that this has huge performance impact, because rerunning for each geometry is extremely slow.
      return;
    }

    // mark all geometries in-use with latest time-stamp
    // We consider a geometry as in-use if it is currently loaded by the viewer
    _timeStamp++;
    for (var i = 0; i < loadedModels.length; i++) {

      // get geom hashes for this model
      var model = loadedModels[i];
      var data = model.getData();
      if (!model.isOTG()) {
        // if this is not an Oscar model, it cannot contain shared geoms.
        // We can skip it.
        continue;
      }
      // For OTG models, we can assume that data is an OtgPackage and contains hashes

      // hashes may by null for empty models
      var hashes = data.geomMetadata.hashes;
      if (!hashes) {
        continue;
      }

      // update timestamp for all geoms that are referenced by the hash list (Note that hashes may be null for empty models)
      var hashCount = hashes.length / data.geomMetadata.byteStride;
      for (var j = 1; j < hashCount; j++) {// start at 1, because index 0 is reserved value for invalid geomIndex

        // If the geom for this hash is in cache, update its timestamp
        var hash = data.getGeometryHash(j);
        var geom = _geoms.get(hash);
        if (geom) {
          geom.timeStamp = _timeStamp;
        }
      }
    }

    // verify that no geom is leaked in the reused tmp array
    if (unusedGeoms.length > 0) {
      console.warn("DtResourceCache.cleanup(): array must be empty");
    }

    // Collect all unused geoms, i.e., all geoms that do not have the latest timeStamp
    for (var hash in _geoms) {
      var geom = _geoms.get(hash);
      if (geom.timeStamp !== _timeStamp) {
        unusedGeoms.push(geom);
      }
    }

    // Sort unused geoms by ascending importance
    unusedGeoms.sort(compareGeomsByImportance);

    // Since cleanup is too expensive to run per geometry,
    // we always remove a bit more than strictly necessary,
    // so that we can load some more new geometries before we have to
    // run cleanup again.
    var targetMem = _maxMemory - _minCleanup;

    // Remove geoms until we reach mem target
    var i = 0;
    for (; i < unusedGeoms.length && this.byteSize >= targetMem; i++) {

      var geom = unusedGeoms[i];

      // remove it from cache
      delete _geoms.delete(geom.hash);

      // update mem consumption. Note that we run this only for geoms that
      // are not referenced by any RenderModel in memory, so that removing them
      // should actually free memory.
      this.byteSize -= geom.byteSize;

      // Dispose GPU mem.
      // NOTE: In case we get performance issues in Chrome, try commenting this out
      // (see hack in GeometryList.dispose)
      geom.dispose();
    }

    if (i === unusedGeoms.length) {
      // No more unused geometries. Any subsequent attempt to cleanup will fail until
      // the next model unload.
      _allGeomsInUse = true;
    }

    // clear reused temp array. Note that it's essential to do this immediately. Otherwise,
    // the geoms would be leaked until next cleanup.
    unusedGeoms.length = 0;
  };


  this.getGeometry = function (hash) {
    return _geoms.get(hash);
  };

  this._updateMaxRequests = function () {
    _requestsPerWorker = Math.min(Math.max(Math.round(_queue.size() * 0.1), _requestsPerWorker), MAX_REQUESTS_PER_WORKER);
  };
}

EventDispatcher.prototype.apply(DtResourceCache.prototype);