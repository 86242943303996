import THREE from "three";
import { Label3D } from "../../gui/Label3D";
import { enumMeshVertices } from "../../wgs/scene/VertexEnumerator";
import { BACK_ELEMENT_CLONE_OVERLAY, ELEMENT_CLONE_OVERLAY } from '../facets/FacetVizEffects';

const ANIM_FPS = 30;
const ANIM_FRAME_INTERVAL = 1000 / ANIM_FPS;
const ANIM_STEPS = 100;
const CONNECTION_LINE_SPEED = 0.025;
const CONNECTION_LINE_SEGMENT_LENGTH = 25;

const CANVAS_LINE_COLOR = "rgba(233,6,112,1)";
const CANVAS_LINE_SHADOW_COLOR = "rgba(69,2,32,0.5)";
const CANVAS_LINE_STROKE_WIDTH = 1;

const boxArr = new Array(6);
const tmpMat = new THREE.Matrix4();
const tmpLine3 = new THREE.Line3();
const tmpPlane = new THREE.Plane();
const tmpVec4_1 = new THREE.Vector4();
const tmpVec4_2 = new THREE.Vector4();
const tmpFrustum = new THREE.Frustum();

export class SystemVisualizer {
  /** Needed to clean up only system visualization labels */
  labelIds = new Set();

  createLabel(element) {
    const label = this.hud.addLabel(element.model, element.dbId, true, { clickSelects: true });
    label.setElement(element);

    this.labelIds.add(label.labelId);

    return label;
  }

  dispose() {
    this.labelIds.forEach((lid) => this.hud.deleteLabel(lid));
    this.labelIds.clear();
    this.hud = null;
  }

  init(facility) {
    this.facility = facility;
    this.viewer = facility.viewer;
    this.hud = this.facility.getHUD();
    this.animTick = 0;
  }

  removeAllCachedLabels() {
    this.labelIds.forEach((lid) => this.hud.deleteLabel(lid));
    this.labelIds.clear();
    this.hud.update();
  }

  /**
   * Add draw function to HUD's draw queue to visualize connections,
   * starting from a single element and traverse down to provided depth.
   * @returns Cleanup function
   */
  canvasConnectionsWithCleanup(systemElement) {let depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    if (!depth) {
      return () => {};
    }

    const traversed = new Set().add(systemElement);
    const traversalQueue = [systemElement];

    const nodePairs = [];
    let currentDepth = 0;

    const collectEdge = (element, connection) => {
      const start = element.getCenterPoint();
      const end = connection.getCenterPoint();

      if (start && end) {
        nodePairs.push(start, end);
      }

      if (currentDepth < depth && !traversed.has(connection)) {
        traversed.add(connection);
        traversalQueue.push(connection);
      }
    };

    // Run previously queued traversal
    while (traversalQueue.length) {
      currentDepth++;

      let depthSize = traversalQueue.length;
      while (depthSize > 0) {
        depthSize--;
        const element = traversalQueue.shift();
        element.enumConnections(null, collectEdge);
      }
    }

    function drawFunc(hud, mvpMatrix) {
      tmpFrustum.setFromProjectionMatrix(mvpMatrix);

      let planeNear = tmpFrustum.planes[5];
      let clipPlaneNear = tmpPlane.copy(planeNear).applyMatrix4(mvpMatrix);

      const edges = [];
      for (let i = 0; i < nodePairs.length; i += 2) {
        // Clone to avoid changing cached coordinates
        const start = nodePairs[i].clone();
        const end = nodePairs[i + 1].clone();

        let skip = false;

        for (const plane of tmpFrustum.planes) {
          const startToPlaneDist = plane.distanceToPoint(start);
          const endToPlaneDist = plane.distanceToPoint(end);
          if (startToPlaneDist < 0 && endToPlaneDist < 0) {
            // Skip edge if both points are culled by the same plane
            skip = true;
            break;
          }
        }

        if (skip) continue;

        clipAndProject(start, end, clipPlaneNear, mvpMatrix);

        start.x = (start.x * 0.5 + 0.5) * hud.width;
        start.y = (-start.y * 0.5 + 0.5) * hud.height;

        end.x = (end.x * 0.5 + 0.5) * hud.width;
        end.y = (-end.y * 0.5 + 0.5) * hud.height;

        edges.push([start, end]);
      }

      // Sort by depth, but it makes some lines blink in and out of existence
      edges.sort((e1, e2) => e2[0].z + e2[1].z - (e1[0].z + e1[1].z));

      for (let i = 0; i < edges.length; i++) {
        drawConnectionLine(hud.ctx, hud.dpr, edges[i][0], edges[i][1]);
      }
    }

    // Push to queue and trigger canvas redraw
    const drawOrder = -1;
    this.hud.addDrawStep([drawOrder, drawFunc]);
    this.hud.invalidate();
    this.hud.update();

    return () => {
      for (const element of traversed) {
        element.center = undefined;
      }
      this.hud.resetDrawQueue();
      this.hud.invalidate();
      this.hud.update();
    };
  }

  canvasMultiPathsWithCleanup(startElement, pathsMap) {

    const traversed = new Set().add(startElement);
    const traversalQueue = [startElement];
    const nodePairs = [];

    // Run previously queued traversal
    while (traversalQueue.length) {
      const element = traversalQueue.shift();
      const nextElements = pathsMap.get(element);
      const endPoints = [];

      const mainStart = element.getCenterPoint();
      let maxDistSq = 0;
      let mainEnd;

      // Collect visible connection points and keep track of the farthest one (kept at index 0)
      for (const connection of nextElements) {
        const isLeaf = pathsMap.get(connection).size === 0;

        if (!traversed.has(connection)) {
          traversed.add(connection);
          !isLeaf && traversalQueue.push(connection);
        }

        if (!mainStart) continue;

        const end = connection.center = isLeaf ?
        connection.getCenterPoint() :
        getMeshBoxIntersectionAvg(element, connection) ?? connection.getCenterPoint();

        if (!end) continue;

        const distSq = mainStart.distanceToSquared(end);

        if (distSq === 0) continue;

        if (maxDistSq < distSq) {
          maxDistSq = distSq;
          endPoints.unshift(end);
        } else {
          endPoints.push(end);
        }
      }

      if (!endPoints.length || !mainStart) {
        continue;
      }

      if (element === startElement) {
        for (const end of endPoints) {
          nodePairs.push(mainStart, end);
        }
        continue;
      }

      // Also check connections that are not part of path to be drawn
      for (const [neighbour] of element.allConnections()) {
        if (traversed.has(neighbour)) continue;

        const end = getMeshBoxIntersectionAvg(element, neighbour) ?? neighbour.getCenterPoint();

        if (!end) continue;

        const distSq = mainStart.distanceToSquared(end);
        if (maxDistSq < distSq) {
          maxDistSq = distSq;
          mainEnd = end;
        }
      }

      if (mainEnd) {
        // Farthest point is from a connection that's not part of path
        // Find farthest point from path connections and draw the main edge
        // as close to that point as possible.
        tmpLine3.set(mainStart, mainEnd);
        const closestPoint = tmpLine3.closestPointToPoint(endPoints[0], true);
        nodePairs.push(mainStart, closestPoint);
      } else {
        // Farthest point is from a path element, set the main edge and draw that line
        mainEnd = endPoints.shift();
        tmpLine3.set(mainStart, mainEnd);
        nodePairs.push(mainStart, mainEnd);
      }

      for (const end of endPoints) {
        const closestPoint = tmpLine3.closestPointToPoint(end, true);
        nodePairs.push(closestPoint, end);
      }
    }

    const visualizer = this;
    let edges,lastFrameTime = 0;

    const animStep = {
      init: (hud, mvpMatrix) => {
        edges = [];

        tmpFrustum.setFromProjectionMatrix(mvpMatrix);

        let planeNear = tmpFrustum.planes[5];
        let clipPlaneNear = tmpPlane.copy(planeNear).applyMatrix4(mvpMatrix);

        // Map to store distance from root
        const distFromRoot = new Map();

        for (let i = 0; i < nodePairs.length; i += 2) {
          // Clone to avoid changing cached coordinates
          const start = nodePairs[i].clone();
          const end = nodePairs[i + 1].clone();

          let skip = false;

          for (const plane of tmpFrustum.planes) {
            const startToPlaneDist = plane.distanceToPoint(start);
            const endToPlaneDist = plane.distanceToPoint(end);
            if (startToPlaneDist < 0 && endToPlaneDist < 0) {
              // Skip edge if both points are culled by the same plane
              skip = true;
              break;
            }
          }

          if (skip) continue;

          clipAndProject(start, end, clipPlaneNear, mvpMatrix);

          start.x = (start.x * 0.5 + 0.5) * hud.width;
          start.y = (-start.y * 0.5 + 0.5) * hud.height;

          end.x = (end.x * 0.5 + 0.5) * hud.width;
          end.y = (-end.y * 0.5 + 0.5) * hud.height;

          const startHash = hash2DCoord(start.x, start.y);
          const offset = distFromRoot.get(startHash) ?? 0;
          edges.push([start, end, offset]);

          // Store offset value for this edge's end point
          const endHash = hash2DCoord(end.x, end.y);
          if (!distFromRoot.has(endHash)) {
            const edgeLength = dist2D(start, end);
            const endOffset = offset + edgeLength;
            distFromRoot.set(endHash, endOffset);
          }
        }

        // Sort by depth
        edges.sort((e1, e2) => e2[0].z + e2[1].z - (e1[0].z + e1[1].z));
      },
      update: (ts) => {
        const delta = ts - lastFrameTime;
        if (delta > ANIM_FRAME_INTERVAL) {
          visualizer.animTick += delta * CONNECTION_LINE_SPEED;
          visualizer.animTick %= ANIM_STEPS;
          lastFrameTime = ts;
          return true;
        }
      },
      draw: (hud) => {
        const animOffset = visualizer.animTick;
        hud.animCtx.setLineDash([CONNECTION_LINE_SEGMENT_LENGTH, CONNECTION_LINE_SEGMENT_LENGTH]);
        for (const [start, end, offset] of edges) {
          hud.animCtx.lineDashOffset = offset - animOffset;
          drawConnectionLine(hud.animCtx, hud.dpr, start, end);
        }
      }
    };

    // Push to queue and trigger canvas redraw
    const priority = -1;
    this.hud.addAnimStep([priority, animStep]);
    this.hud.invalidate();
    this.hud.update();

    return () => {
      for (const element of traversed) {
        element.center = undefined;
      }
      this.hud.resetAnimQueue();
      this.hud.invalidate();
      this.hud.update();
    };
  }

  /**
   * Draw a set of system elements as overlays.
   *
   * All elements, not in foreground will be placed in an orange (background) overlay.
   * @param {Iterable} elements System elements
   * @param {boolean} isForeground Foreground overlay is blue, background is orange.
   * @returns Cleanup function
   */
  overlayHighlightWithCleanup(elements, isForeground) {
    const allMeshes = [];
    for (const { model, dbId } of elements) {
      const meshes = model.getProxyMeshes(dbId);
      allMeshes.push(...meshes);
    }

    const overlayName = isForeground ? ELEMENT_CLONE_OVERLAY : BACK_ELEMENT_CLONE_OVERLAY;
    this.viewer.impl.addMultipleOverlays(overlayName, allMeshes);

    return () => {
      this.viewer.impl.removeMultipleOverlays(overlayName, allMeshes);
    };
  }
}

/**
 * Determine the average position of the intersection between two elements.
 * The intersection is between the smaller element's mesh and larger element's bbox,
 * size is defined by the bbox of both elements.
 */
function getMeshBoxIntersectionAvg(element, connection) {
  let bbox1 = new THREE.Box3();
  let bbox2 = new THREE.Box3();

  // Get bboxes and use larger box
  element.model.getInstanceTree().getNodeBox(element.dbId, boxArr);
  bbox1.min.set(boxArr[0], boxArr[1], boxArr[2]);
  bbox1.max.set(boxArr[3], boxArr[4], boxArr[5]);

  connection.model.getInstanceTree().getNodeBox(connection.dbId, boxArr);
  bbox2.min.set(boxArr[0], boxArr[1], boxArr[2]);
  bbox2.max.set(boxArr[3], boxArr[4], boxArr[5]);

  const bbox1Size = bbox1.size().length();
  const bbox2Size = bbox2.size().length();

  // Use the bigger bbox
  if (bbox2Size > bbox1Size) {
    bbox1 = bbox2;
  } else {
    element = connection;
  }

  // TODO: find something better
  bbox1.expandByScalar(1);

  // Get start-mesh
  const fl = element.model.getFragmentList();
  let fragIds = fl.fragments.dbId2fragId[element.dbId];
  fragIds = Array.isArray(fragIds) ? fragIds : [fragIds];

  const intersectingAvg = new THREE.Vector3(0, 0, 0);
  let intersectingPointCount = 0;

  for (const fragId of fragIds) {
    const geom = fl.getGeometry(fragId);
    if (!geom) continue;
    fl.getWorldMatrix(fragId, tmpMat);
    enumMeshVertices(geom, (p) => {
      if (bbox1.containsPoint(p)) {
        intersectingAvg.add(p);
        intersectingPointCount++;
      }
    }, tmpMat);
  }

  if (intersectingPointCount === 0) {
    return null;
  }

  intersectingAvg.divideScalar(intersectingPointCount);

  return intersectingAvg;
}

function drawConnectionLine(ctx, dpr, start, end) {

  // Three strokes per edge
  ctx.beginPath();

  ctx.moveTo(start.x, start.y);
  ctx.lineTo(end.x, end.y);

  ctx.globalCompositeOperation = "source-over";

  // 1- Contrast line (open cap), drawn on top of existing strokes
  ctx.lineCap = "butt";
  ctx.lineWidth = CANVAS_LINE_STROKE_WIDTH * 4 * dpr;
  ctx.strokeStyle = CANVAS_LINE_SHADOW_COLOR;
  ctx.stroke();

  // 2- Connection line, drawn on top of existing strokes
  ctx.lineCap = "round";
  ctx.lineWidth = CANVAS_LINE_STROKE_WIDTH * dpr;
  ctx.strokeStyle = CANVAS_LINE_COLOR;
  ctx.stroke();

  ctx.globalCompositeOperation = "destination-over";

  // 3- Contrast line (round cap), drawn underneath existing strokes
  ctx.lineCap = "round";
  ctx.lineWidth = CANVAS_LINE_STROKE_WIDTH * 4 * dpr;
  ctx.strokeStyle = CANVAS_LINE_SHADOW_COLOR;
  ctx.stroke();
}

function clipAndProject(start, end, clipPlaneNear, m) {
  // Project start/end into clip space to check against near plane
  const clipSpaceStart = tmpVec4_1.copy(start).applyMatrix4(m);
  const clipSpaceEnd = tmpVec4_2.copy(end).applyMatrix4(m);

  if (clipPlaneNear.distanceToPoint(clipSpaceStart) < 0) {
    tmpLine3.set(clipSpaceEnd, clipSpaceStart);
    // Clip start
    clipPlaneNear.intersectLine(tmpLine3, start);
  } else {
    // No clipping needed, do perspective divide
    start.copy(clipSpaceStart);
    start.multiplyScalar(1.0 / clipSpaceStart.w);
  }

  if (clipPlaneNear.distanceToPoint(clipSpaceEnd) < 0) {
    tmpLine3.set(clipSpaceStart, clipSpaceEnd);
    // Clip end
    clipPlaneNear.intersectLine(tmpLine3, end);
  } else {
    // No clipping needed, do perspective divide
    end.copy(clipSpaceEnd);
    end.multiplyScalar(1.0 / clipSpaceEnd.w);
  }
}

function dist2D(p1, p2) {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  const distSq = dx * dx + dy * dy;
  return Math.sqrt(distSq);
}

const HASH_PRIME = 7927;
function hash2DCoord(x, y) {
  return x * 100 * HASH_PRIME ^ y * 100;
}