export const LabelIcon = `
    <svg width="26" height="26" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
        <g stroke-width="1" fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
            <path d="M7.02113 10.9342H4.14317L3.51334 13.1299H2L4.54556 4.78467H6.58375L9.26052 13.1299H7.71219L7.02113 10.9342ZM4.47558 9.72707H6.67123L5.56028 5.83438L4.47558 9.72707ZM14.859 7.63639C15.2456 7.89476 15.5493 8.25927 15.7338 8.68611C15.9308 9.18978 16.0202 9.72912 15.9962 10.2694V10.4006C16.003 10.8995 15.8952 11.3933 15.6813 11.844C15.4629 12.2825 15.1305 12.6542 14.719 12.92C14.3287 13.1739 13.8725 13.3077 13.4069 13.3048C13.0982 13.3124 12.7914 13.2547 12.5065 13.1357C12.2217 13.0166 11.9651 12.8388 11.7536 12.6138L11.6224 13.1561H10.4677V4.78467H11.946V7.92506C12.1589 7.70666 12.4154 7.5354 12.6986 7.42239C12.9819 7.30937 13.2858 7.25711 13.5906 7.26899C14.041 7.25897 14.4837 7.38719 14.859 7.63639ZM14.5178 10.2607C14.5178 9.03601 14.1067 8.42368 13.2932 8.42368C13.0293 8.42139 12.7685 8.48133 12.5321 8.59863C12.2989 8.71835 12.0979 8.89236 11.946 9.10599V11.6865C12.0999 11.8553 12.2879 11.9893 12.4975 12.0798C12.7072 12.1703 12.9337 12.2152 13.162 12.2114C13.3555 12.2171 13.5475 12.1754 13.7213 12.09C13.8951 12.0047 14.0454 11.8782 14.1592 11.7215C14.4222 11.3137 14.5479 10.8325 14.5178 10.3482V10.2607Z" />
        </g>
    </svg>
`;

export const SpacesIcon = `
    <svg width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 8V9H4.5V8H6.5V3H2.5V13H7.5V14H1.5V2H14.5V6V14H10.5V13H11.5H13.5V6V3H7.5V8H9.5Z"/>
    </svg>
`;

export const LevelsIcon = `
    <svg width="24" height="24" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
        <g stroke-width="0" fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
            <path d="M8.50067 1.5C7.21506 1.49987 5.95829 1.88097 4.88928 2.59512C3.82027 3.30927 2.98705 4.32439 2.49498 5.5121C2.00291 6.6998 1.87409 8.00676 2.12482 9.26768C2.37555 10.5286 2.99456 11.6869 3.90357 12.596C4.81259 13.5051 5.97078 14.1242 7.23167 14.3751C8.49257 14.6259 9.79953 14.4972 10.9873 14.0053C12.175 13.5133 13.1903 12.6802 13.9045 11.6113C14.6188 10.5423 15 9.28561 15 8C14.9977 6.27691 14.3122 4.62504 13.0939 3.40657C11.8756 2.1881 10.2238 1.50247 8.50067 1.5ZM8.00067 2.52V7.5H3.02C3.14003 6.2198 3.70327 5.02168 4.61248 4.11247C5.52168 3.20327 6.7198 2.64003 8 2.52H8.00067ZM9.00067 13.48V8.5H13.9807C13.8606 9.7803 13.2973 10.9785 12.3879 11.8877C11.4786 12.7969 10.2803 13.3601 9 13.48H9.00067Z" />
        </g>
    </svg>
`;

export const AssetsIcon = `
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor">
            <path d="M21.62,11.42,13.55,3.35a3.7,3.7,0,0,0-3-1.17c-2.63,0-7.61,0-7.65,0h0a.74.74,0,0,0-.74.76c0,.05,0,5,0,7.65a3.78,3.78,0,0,0,1.16,3l8.08,8.07A3.54,3.54,0,0,0,13,22.73a2.07,2.07,0,0,0,.47.06,2.14,2.14,0,0,0,1.52-.7l3.53-3.53L22.05,15C23.64,13.43,22.12,11.92,21.62,11.42ZM21,14,17.46,17.5,13.93,21c-.32.31-.48.27-.57.24a2.38,2.38,0,0,1-.92-.67L4.36,12.53a2.26,2.26,0,0,1-.72-1.92c0-2,0-5.41,0-6.93,1.52,0,4.91,0,6.93,0a2.14,2.14,0,0,1,1.92.73l8.07,8.07C21.52,13.43,21.28,13.67,21,14ZM8.65,6.37A1.64,1.64,0,0,1,6.33,8.69a1.66,1.66,0,0,1,0-2.32A1.64,1.64,0,0,1,8.65,6.37Z" />
        </g>
    </svg>
`;