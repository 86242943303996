import { FacetSpaces, FacetTypes } from "../../../facets/Facets";
import { areSetsEqual } from "../LayerUtils";

const CONTEXT_MENU_KEY = 'Spaces';

export function registerSpacesCxtMenuCb(viewer, facility) {
  viewer.registerContextMenuCallback(CONTEXT_MENU_KEY, (menu) => {
    if (!facility.facetsManager.getFacetDefs().find((_ref) => {let { id } = _ref;return id === FacetTypes.spaces;})) {
      // Every action require the space facet.
      return;
    }

    // Check if single, and get the facet ID for this single space
    const idOfSingleSpc = getIdOfSingleSpc(facility.viewer.getAggregateSelection());
    if (idOfSingleSpc == null) {
      return;
    }

    const filterMap = facility.facetsManager.getFilterMap();
    const spaceIdFilter = new Set([idOfSingleSpc]);

    if (!areSetsEqual(spaceIdFilter, filterMap.spaces)) {
      menu.push({
        title: 'Filter to this space',
        // Using the new ordering system, above of normal but leaving space above.
        order: 10,
        target: () => {
          facility.facetsManager.setFilterMap({
            ...filterMap,
            spaces: spaceIdFilter
          });
        }
      });
    }
  });
}

export function unregisterSpacesCxtMenuCb(viewer) {
  viewer.unregisterContextMenuCallback(CONTEXT_MENU_KEY);
}

function getIdOfSingleSpc(modelSelectionEntries) {
  if (modelSelectionEntries.length !== 1) return;

  const { model, selection } = modelSelectionEntries[0];
  if (selection.length !== 1) return;

  const dbId = selection[0];
  const room = model.getRooms()[dbId];
  if (!room) return;

  return FacetSpaces.makeId(room);
}