import { ProgressState } from "../application/ProgressState";

export function ProgressBar(container) {

  let _document = container.ownerDocument;

  this.bg = _document.createElement('div');
  this.bg.className = 'progressbg';

  this.fg = _document.createElement('div');
  this.fg.classList.add('progressfg');

  this.bg.appendChild(this.fg);
  container.appendChild(this.bg);

  this.lastValue = -1;
  this.lastState = null;

  this.fg.style.transform = `scale(0, 1)`;
}

ProgressBar.prototype.setPercent = function (pct, state) {

  if (pct === this.lastValue && state === this.lastState)
  return;

  if (this.lastState !== state) {
    if (state === ProgressState.LOADING) {
      this.fg.classList.remove('progressfggraphics');
      this.fg.classList.add('progressfgloading');
    } else {
      this.fg.classList.remove('progressfgloading');
      this.fg.classList.add('progressfggraphics');
    }
  }

  this.lastValue = pct;
  this.lastState = state;


  if (pct >= 99) {
    this.bg.style.opacity = "0";
    this.fg.style.transform = `scale(${0 / 100}, 1)`;
  } else {
    this.bg.style.opacity = "1";
    this.fg.style.transform = `scale(${pct / 100}, 1)`;
  }
};

ProgressBar.prototype.setStyle = function (style) {
  !this.fg.classList.contains(style) && this.fg.classList.add(style);
};

ProgressBar.prototype.removeStyle = function (style) {
  this.fg.classList.contains(style) && this.fg.classList.remove(style);
};