
import { SolidDefConvert } from './SolidDefConvert.js';
import { SketchRegionSolver, getRegionEdges, getBoundedRegionFaces, computeCurveCurveIntersections } from '@adsk/solid-definition';

// Result values of classifySets
const SetContainment = {
  Contains: 0, // A contains B (not vice versa)
  IsContainedIn: 1, // B contains A (not vice versa)
  Disjoint: 2, // No common elements
  Overlapping: 3, // Intersecting, but not equal
  Equal: 4 // Sets are identical
};

// @param {SketchRegionSolver} solver       - initialized with all edges of subject and cutLoop
// @param {Edges[]}            loopEdges    - edges of the loop that we test against.
// @returns {Face[]} subset of solver.getFaces(). All faces encludes by the loopEdges.
export const getFacesInsideLoop = (solver, loopEdges) => {

  // Get all faces that we obtained by intersecting all edges against each other
  const regionFaces = solver.getFaces();

  // Get ordered array of loop edges within solver that correspond to the cutLoop
  const cutRegionEdges = getRegionEdges(solver, loopEdges);

  // Find all faces that are 
  return getBoundedRegionFaces(regionFaces, cutRegionEdges);
};

// Tolerance for self-intersection tests: If intersections are very close to a shared vertex, we ignore them.
// Note that the tolerance is not in units but a fraction of an edge.
const Precision = 1.e-5;

// Check whether a single loop has self-intersections
// TODO: There is one edge case that we would not detect here: If a loop passes the same vertex multiple times.
const hasSelfIntersections = (loopEdges) => {
  for (let i = 0; i < loopEdges.length; i++) {
    const edge1 = loopEdges[i];

    // Check all subsequent edges.
    for (let j = i + 1; j < loopEdges.length; j++) {

      // Check intersections of both edges
      const edge2 = loopEdges[j];
      const cuts = computeCurveCurveIntersections(edge1, edge2, false, true);

      // Check if there are any intersections (except for shared vertices)
      for (let i = 0; i < cuts.length; i++) {
        const cut = cuts[i];

        // Ignore intersections at a shared vertex
        // Due to accuracy issues, the cut may also be just close to a vertex
        const param1 = cut.cutInfo.param;
        const param2 = cut.cutByInfo.param;

        const range1 = edge1.getRange();
        const range2 = edge2.getRange();

        // Check if both parameters are very close to range start/end of an edge
        const dist1 = Math.min(Math.abs(param1 - range1[0]), Math.abs(param1 - range1[1]));
        const dist2 = Math.min(Math.abs(param2 - range2[0]), Math.abs(param2 - range2[1]));
        const d = Math.max(dist1, dist2);

        // If cut was not approximately equal to a shared vertex,
        // consider it as a self-intersection.
        if (d > Precision) {
          return true;
        }
      }
    }
  }
  return false;
};

// Given two sets of values, faces, each indexed by integer faceIds, this function checks how the sets are related.
const classifySets = (A, B) => {

  // Track which kind of indices we found
  let foundCommon = false; // >=0 elems are in both
  let foundAOnly = false; // >=0 elems are only in set A
  let foundBOnly = false; // >=0 elems are only in set B

  const checkElems = (elemIndex) => {
    const isInA = A.has(elemIndex);
    const isInB = B.has(elemIndex);

    if (isInA && isInB) foundCommon = true;else
    if (isInA) foundAOnly = true;else
    if (isInB) foundBOnly = true;
  };

  for (let elem of A) {
    checkElems(elem);
  }

  for (let elem of B) {
    checkElems(elem);
  }

  if (!foundCommon) {
    return SetContainment.Disjoint;
  }

  if (foundAOnly && !foundBOnly) {
    return SetContainment.Contains;
  }

  if (foundBOnly && !foundAOnly) {
    return SetContainment.IsContainedIn;
  }

  if (!foundAOnly && !foundBOnly) {
    return SetContainment.Equal;
  }

  return SetContainment.Overlapping;
};

// Contains loop containment for a path
export const computeLoopContainment = (path) => {

  // Convert to SolidDef Path
  const pathSd = SolidDefConvert.toSolidDefPath(path);

  // get path as wires
  const wireBody = pathSd.getWireBody();
  const wires = wireBody.getWires();

  // get path as edge array
  const edges = wireBody.getEdges();

  // Init empty loop infos
  const loopInfos = [];
  for (let l = 0; l < wires.length; l++) {

    // Get loop edges
    const w = wires[l];
    const loopEdges = w.getEdges();

    loopInfos[l] = {
      containedLoops: [],
      rank: 0,

      // Indicates if loop containment could not properly computed. 
      // This happens if 
      //   a) The loop has self-intersections
      //   b) The loop is overlapping with another one
      //   c) The loop is exactly matching with another one
      error: hasSelfIntersections(loopEdges) // Initially, we detect only a)
    };
  }

  // For only a single loop or less, we are done here.
  if (wires.length < 2) {
    return loopInfos;
  }

  // Feed them into solver to intersect them against each other and extract the resulting region faces.
  const solver = new SketchRegionSolver();
  solver.compute(edges);

  // check which of the faces are within path and cutPath
  const faces = solver.getFaces();

  // attach arrayIndex to each face
  faces.forEach((f, index) => f.arrayIndex = index);

  // for each loop l, collect a set facesPerLoop[l] that contains the array indices of all enclosed faces.     
  const facesPerLoop = [];
  for (let l = 0; l < wires.length; l++) {

    // get edges of loop i
    const w = wires[l];
    const loopEdges = w.getEdges();

    // If a loop has self-intersections, just mark it as invalid and skip it
    if (hasSelfIntersections(loopEdges)) {
      loopInfos[l].error = true;
      facesPerLoop[l] = new Set();
      continue;
    }

    // get all faces within this loop
    const enclosedFaceIds = new Set();
    const faces = getFacesInsideLoop(solver, loopEdges);
    faces.forEach((f) => {
      enclosedFaceIds.add(f.arrayIndex);
    });

    facesPerLoop[l] = enclosedFaceIds;
  }

  // Use the faceId sets to derive which loop is contained in which other    
  for (let a = 0; a < facesPerLoop.length; a++) {

    // indices of all faces enclosed by loop a
    const A = facesPerLoop[a];

    for (let b = a + 1; b < facesPerLoop.length; b++) {
      // indices of all faces enclosed by loop i
      const B = facesPerLoop[b];

      // Check set relation between A and B
      const cont = classifySets(A, B);
      switch (cont) {
        case SetContainment.Disjoint:
          // No common faces at all. E.g., for two holes.
          continue;
        case SetContainment.Contains:
          loopInfos[a].containedLoops.push(b);
          loopInfos[b].rank++;
          break;
        case SetContainment.IsContainedIn:
          loopInfos[b].containedLoops.push(b);
          loopInfos[a].rank++;
          break;
        default:
          loopInfos[a].error = true;
          loopInfos[b].error = true;
      }
    }
  }

  // If the original paths contained empty loops, we may have to reindex the loopInfos,
  // because empty loops will not produce a corresponding wire in the SolidDef representation.
  if (wires.length !== path.loopCount) {
    const reindexed = [];
    const srcIndex = 0;
    for (let l = 0; l < path.loopCount; l++) {
      // Only non-empty loops get loopInfos
      if (path.getVertexCount(l)) {
        reindexed[i] = loopInfos[srcIndex++];
      }
    }
    loopInfos = reindexed;
  }

  return loopInfos;
};