import i18n from "i18next";

import { SketchToolsToolbar } from './SketchToolsToolbar';

const av = Autodesk.Viewing;

// Based on https://github.com/grommet/grommet-icons/blob/master/public/img/edit.svg
// but with changed stroke width and color
const Edit2DIcon = `
	<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<g stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path d="M14,4 L20,10 L14,4 Z M22.2942268,5.29422684 C22.6840146,5.68401459 22.6812861,6.3187139 22.2864907,6.71350932 L9,20 L2,22 L4,15 L17.2864907,1.71350932 C17.680551,1.319449 18.3127724,1.31277239 18.7057732,1.70577316 L22.2942268,5.29422684 Z M3,19 L5,21 M7,17 L15,9"/>
		</g>
	</svg>
`;

function fuzzyEquals(a, b) {let eps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1e-1;
  return (
    Math.abs(a.x - b.x) < eps &&
    Math.abs(a.y - b.y) < eps &&
    Math.abs(a.z - b.z) < eps);

}

export class SketchToolsControls {

  /**
   * @param {DtFacility} facility
   */
  constructor(facility) {
    this.facility = facility;
    this.viewer = null;
    this.edit2dExt = null;

    this.toolBarButton = null;
    this.sketchingToolbar = null;

    this.boundOnRootLoadedEvent = this.#onRootLoadedEvent.bind(this);
  }

  /**
   * @param {Autodesk.Viewing.Viewer3D} viewer
   */
  async init(viewer) {
    this.viewer = viewer;

    const defaultModel = this.facility.getDefaultModel();
    if (!defaultModel) {
      await this.facility.createDefaultModel();
    }

    await this.viewer.waitForToolbar();

    this.#createToolbarButton();
    this.#addToolbarButton();

    const edit2dExtName = 'Autodesk.Edit2D';

    const edit2dExt = this.viewer.getExtension(edit2dExtName);
    if (!edit2dExt) {
      await this.viewer.loadExtension(edit2dExtName, { enableHeightComputation: true });
      this.edit2dExt = this.viewer.getExtension(edit2dExtName);
    }

    if (!this.viewer.hasEventListener(av.MODEL_ROOT_LOADED_EVENT, this.boundOnRootLoadedEvent)) {
      this.viewer.addEventListener(av.MODEL_ROOT_LOADED_EVENT, this.boundOnRootLoadedEvent);
    }
  }

  #createToolbarButton() {
    const BtnStates = av.UI.Button.State;

    const defaultModel = this.facility.getDefaultModel();

    const button = new av.UI.Button('toolbar-edit2d');
    if (!defaultModel.isLoaded()) {
      button.setState(BtnStates.DISABLED);
    } else {
      button.setState(BtnStates.INACTIVE);
    }

    button.icon.innerHTML = Edit2DIcon;
    button.setToolTip(i18n.t('Sketching Tools'));
    button.onClick = async () => {
      const state = button.getState();
      if (state === BtnStates.INACTIVE) {
        await this.#activate();
        button.setState(BtnStates.ACTIVE);
      } else if (state === BtnStates.ACTIVE) {
        this.#deactivate();
        button.setState(BtnStates.INACTIVE);
      }
    };

    this.toolBarButton = button;
  }

  #addToolbarButton() {
    const toolbar = this.viewer.getToolbar?.();
    const navTools = toolbar?.getControl(av.TOOLBAR.MODELTOOLSID);
    if (!navTools) {
      return;
    }

    const exists = navTools.getControl(this.toolBarButton.getId());
    if (!exists) {
      navTools.addControl(this.toolBarButton, { index: navTools._controls.length });
    }
  }

  async #activate() {
    let defaultModel = this.facility.getDefaultModel();
    if (!defaultModel.isWorldBBoxSet()) {
      await defaultModel.resetWorldBBoxForDefaultModel();
      this.facility.unloadModel(defaultModel);
      this.facility.loadModel(defaultModel);
      await defaultModel.waitForLoad(false, true);
    }

    const _activate = () => {
      if (!this.sketchingToolbar) {
        const xform = defaultModel.getData().placementWithOffset;
        this.sketchingToolbar = new SketchToolsToolbar(this.facility, this.viewer, this.edit2dExt, xform);
      }
    };

    if (this.#isTopDownView()) {
      _activate();
    } else {
      let xform;

      let bbox = new THREE.Box3();

      // combine bboxes
      const models = this.facility.getModels();
      for (let model of models) {
        if (model.isPrimaryModel()) {
          xform = model.myData.placementWithOffset;
        }

        if (!this.facility.isModelVisible(model)) {
          continue;
        }

        model.invalidateBBoxes();
        bbox.union(model.getVisibleBounds());
      }

      // clone final camera view
      const dstView = this.viewer.impl.camera.clone();

      // set target
      const center = bbox.getCenter(dstView.target);

      // set position
      const size = bbox.size();
      const dist = Math.max(size.x, size.y, size.z);
      const viewDir = { x: 0, y: 0, z: 1 };
      dstView.position.set(center.x + viewDir.x * dist, center.y + viewDir.y * dist, center.z + viewDir.z * dist);

      // set and transform up
      let up = new THREE.Vector3(0, 1, 0);
      if (xform) {
        up.transformDirection(xform);
      }
      dstView.up.copy(up);

      this.viewAnimControl?.stop();

      const onFinished = () => _activate();
      this.viewAnimControl = Autodesk.Viewing.Private.flyToView(this.viewer, dstView, 2, onFinished, false);
    }

    return true;
  }

  #deactivate() {
    this.sketchingToolbar.dtor();
    this.sketchingToolbar = null;
  }

  #isTopDownView() {
    const camera = this.viewer.impl.camera;
    const target = camera.target.clone();

    const dir = target.sub(camera.position).normalize();
    return fuzzyEquals(dir, { x: 0.0, y: 0.0, z: -1.0 });
  }

  #onRootLoadedEvent(_ref) {let { model } = _ref;
    if (model.isDefault()) {
      const state = this.toolBarButton.getState();
      const BtnStates = av.UI.Button.State;

      if (state === BtnStates.DISABLED) {
        this.toolBarButton.setState(av.UI.Button.State.INACTIVE);
      };
    }
  }
};