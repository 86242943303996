import { formatValueWithUnits } from "../../measurement/UnitFormatter";
import { DtConstants } from "../schema/DtConstants";
import { AttributeType } from "../schema/Attribute";
import { SystemClassNames, SystemClassToList } from "../schema/system-class";
import { ElementFlags } from "../schema/dt-schema";
import i18n from "i18next";

const RC = require("../resources/cats_enum.json");

/**
 * @typedef {object} FacetSettings configuration object used to deserialize a Facet
 * @property {string} id required id that represents a facet type.
 *          One of: "levels" | "systems" | "attributes" | "models" | "cats" | "fams" | "types" | "spaces"
 * @property {string|undefined} attributeHash attribute hash.
 *          Required for id: "attributes"
 */

const ROOT_ID = Symbol('root');

export const UNASSIGNED = "(Unassigned)";
const unassignedLabel = () => i18n.t("(Unassigned)");
export const UNCLASSIFIED = "(Unclassified)";
const unclassifiedLabel = () => i18n.t("(Unclassified)");
export const UNDEFINED = "(Undefined)";
const undefinedLabel = () => i18n.t("(Undefined)");

export const FacetTypes = {
  levels: "levels",
  assemblyCode: "systems",
  classifications: "classifications",
  attributes: "attributes",
  models: "models",
  categories: "cats",
  families: "fams",
  parameters: "parameters",
  types: "types",
  spaces: "spaces",
  status: "status",
  systemClasses: "systemClasses",
  mepSystems: "mepSystems"
};

export const PALETTE_SOURCE_FILES = [
'#00ffff', // 'aqua'
'#8a2be2', // 'blueviolet'
'#ff7f50', // 'coral'
'#0000cd', // 'mediumblue'
'#ff1493', // 'deeppink'
'#1e90ff', // 'dodgerblue'
'#ffd700', // 'gold'
'#adff2f', // 'greenyellow'
'#006400', // 'darkgreen'
'#ff6347', // 'tomato'
'#ffff00', // 'yellow'
'#6495ed', // 'cornflowerblue'
'#87ceeb', // 'skyblue'
'#808000', // 'olive'
'#ff00ff', // 'magenta'
'#90ee90' // 'lightgreen'
];

const PALETTE_SYSTEMS = {
  [UNCLASSIFIED]: '#191970', // 'midnightblue', //Unclassified: 0
  'A': '#708090', // 'slategrey', //Substructure:  1,	//Uniformat A
  'B': '#ffe4e1', // 'mistyrose', //Shell:         2,	//Uniformat B
  'C': '#add8e6', // 'lightblue', //Interior:      3,	//Uniformat C
  'D10': '#4169e1', // 'royalblue', //Conveying:     4,	//Uniformat D10
  'D20': '#00ff00', // 'lime', //Plumbing:      5,	//Uniformat D20
  'D30': '#ffff00', // 'yellow', //HVAC:          6,	//Uniformat D30
  'D40': '#ff0000', // 'red', //Fire:          7,	//Uniformat D40
  'D50': '#ff00ff', // 'magenta', //Electrical:    8,	//Uniformat D50
  'E': '#ffa500', // 'orange', //Equipment:     9,	//Uniformat E
  'F': '#ff7f50', // 'coral', //Special:      10,	//Uniformat F
  'G': '#006400' // 'darkgreen', //Sitework:     11,	//Uniformat G
};

// trailingLabels is an array of string labels that are supposed to be sorted
// to the back of the resulting list, in the order they appear in the array
function sortByLabel(trailingLabels) {
  return (a, b) => {
    const va = a.label?.toString() || a.id?.toString() || '';
    const vb = b.label?.toString() || b.id?.toString() || '';

    const ca = trailingLabels.indexOf(va);
    const cb = trailingLabels.indexOf(vb);

    if (ca == -1 && cb == -1) {
      return va.localeCompare(vb);
    } else if (ca == -1) {
      return -1;
    } else if (cb == -1) {
      return 1;
    } else {
      return ca - cb;
    }
  };
}

export class FacetDef {
  /**
   */
  constructor() {
    this.id = null;
    this.filter = new Set();
    this.theme = {};
    this.palette = [];
  }

  /**
   * @param {FacetSettings} settings
   * @param {import("./DtFacility").DtFacility} facility
   * @param {Object} options FacetsManager options
   */
  async init(settings, facility, options) {
    this.setSettings(settings);
    this.options = options;
  }

  /**
   * @returns {FacetSettings}
   */
  getSettings() {
    return {
      id: this.id,
      ...this.settings
    };
  }

  /**
   * @param {FacetSettings} settings
   */
  setSettings() {let { id, attributeHash } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    this.settings = { attributeHash };
  }

  isLoaded() {
    return true;
  }

  /**
   * @param {MergedFacetNode[]} mergedFacetNodes
   * @returns {MergedFacetNode[]}
   */
  formatMergedFacetNodes(mergedFacetNodes, partialFacets) {
    for (const node of mergedFacetNodes) {
      const pNode = partialFacets[node.id];
      if (!pNode) {
        continue;
      }

      let anyHidden = false;
      let anyVisible = false;

      for (const key in node.idsSets) {
        const totalIdCount = node.idsSets[key].size;
        const hiddenIdCount = pNode[key]?.size ?? 0;

        anyHidden ||= hiddenIdCount > 0;
        anyVisible ||= hiddenIdCount < totalIdCount;

        if (anyHidden && anyVisible) {
          node.partial = true;
          break;
        }
      }
    }

    return mergedFacetNodes.sort(this.getSorter());
  }

  getSorter() {
    return sortByLabel([UNASSIGNED]);
  }
}


export class FacetLevels extends FacetDef {

  constructor() {
    super();
    this.id = FacetTypes.levels;
    this.label = i18n.t("Levels");
  }

  async getClassifier(model) {

    const noLevel = {
      id: UNASSIGNED,
      label: unassignedLabel(),
      elev: undefined,
      order: Infinity,
      isUnassignedId: true
    };

    let lvlId2lvl = model.getLevels();

    // Model was most likely unloaded, no point in continuing.
    if (!model.getData()) {
      return () => noLevel;
    }
    let dbId2levelId = model.getData().dbId2levelId;
    let dbId2topLevel = model.getData().dbId2topLevel;

    let itemCache = {};

    const getFacetItem = (level) => {
      let cached = itemCache[level.name || UNASSIGNED];
      if (cached) {
        return cached;
      }

      itemCache[level.name || UNASSIGNED] = cached = {
        id: level.name || UNASSIGNED,
        label: level.name || unassignedLabel(),
        elev: level.elev,
        order: level.order ?? Infinity,
        isUnassignedId: !level.name
      };

      return cached;
    };

    return (dbId) => {

      const useTopLevel = this.options.useTopLevel;

      let level = lvlId2lvl[dbId2levelId[dbId]];

      //Assign level objects to themselves, in case we are displaying them
      if (!level) {
        level = lvlId2lvl[dbId];
      }

      if (!level) {
        return noLevel;
      }

      if (useTopLevel) {
        let topLevel = lvlId2lvl[dbId2topLevel[dbId]];

        if (topLevel) {

          let res = [];

          for (let levelId in lvlId2lvl) {
            let curLevel = lvlId2lvl[levelId];

            if (curLevel.elev >= level.elev && curLevel.elev < topLevel.elev) {
              res.push(getFacetItem(curLevel));
            }
          }

          return res;

        } else {
          return getFacetItem(level);
        }
      } else {
        return getFacetItem(level);
      }
    };
  }

  static sortByElev(a, b) {

    if (a.elev === undefined) {
      if (b.elev === undefined) {
        return a.order - b.order;
      } else {
        return 1;
      }
    } else if (b.elev === undefined) {
      return -1;
    }

    return a.elev - b.elev;
  }

  getSorter() {
    return FacetLevels.sortByElev;
  }
}

export class FacetSystems extends FacetDef {
  constructor() {
    super();
    this.id = FacetTypes.mepSystems;
    this.label = i18n.t("Systems");
  }

  async getClassifier(model, allModels) {

    const nullSystem = {
      id: UNASSIGNED,
      glowEffectId: -1,
      label: unassignedLabel(),
      isUnassignedId: true
    };

    const defaultModel = allModels.find((m) => m.isDefault());
    // Model was either unloaded or default model wasn't yet loaded, no point in continuing.
    if (!model.getData() || !defaultModel) {
      return () => nullSystem;
    }


    // We need the filters, so fetch system definitions if they're missing
    const systems = await model.getParentFacility().systemsManager.getAll();
    const dbId2SysClass = model.getData().dbId2SystemClass;

    return (dbId) => {
      const result = [];
      const sysClass = dbId2SysClass[dbId];

      // The assumption is that if sysClass equals to 0, the element is not assigned to any system
      if (!sysClass) {
        return nullSystem;
      }

      for (const system of systems) {
        if (system.filter.systemClasses & sysClass) {
          result.push({ id: system.id, glowEffectId: system.filter.systemClasses, label: system.name });
        }
      }

      if (result.length > 0) {
        return result.length === 1 ? result[0] : result;
      } else {
        return nullSystem;
      }
    };
  }

  getSorter() {
    return sortByLabel([UNASSIGNED]);
  }
}

export class FacetSpaces extends FacetDef {
  // Hide floors, ceilings and roofs so that one can see inside the room
  static isHiddenFacetCategory(catId) {
    return catId === RC.Floors || catId === RC.Roofs || catId === RC.Ceilings;
  }

  constructor() {
    super();
    this.id = FacetTypes.spaces;
    this.label = i18n.t("Spaces");
  }

  async getClassifier(model, refModels) {

    function makeRoomEntry(roomInfo) {
      const rmName = roomInfo.name || unassignedLabel();
      const id = FacetSpaces.makeId(roomInfo);

      return {
        id: id,
        label: rmName,
        dbId: roomInfo.dbId,
        roomSrcModel: roomInfo.model,
        isMEPSpace: roomInfo.isMEPSpace
      };
    }

    function makeNullEntry() {
      return {
        id: UNASSIGNED,
        label: unassignedLabel(),
        dbId: 0,
        roomSrcModel: null,
        isMEPSpace: false,
        isUnassignedId: true
      };
    }

    let dbIdToCatId = model.getData().dbId2catId;
    let dbIdToFlags = model.getData().dbId2flags;

    const refModelsByUrn = refModels.reduce((byUrn, model) => {
      byUrn[model.urn()] = model;
      return byUrn;
    }, {});

    return (dbId) => {
      let catId = dbIdToCatId[dbId];

      if (FacetSpaces.isHiddenFacetCategory(catId)) {
        return makeNullEntry();
      }

      //Assign rooms/spaces to themselves in case we are displaying them
      if (dbIdToFlags[dbId] === ElementFlags.Room) {
        let roomMap = model.getRooms();
        let myRoom = roomMap[dbId];
        if (myRoom) {
          return makeRoomEntry(myRoom);
        } else {
          return makeNullEntry();
        }
      }

      const rooms = model.getRoomsOfElement(dbId, refModelsByUrn);
      let res = rooms.map((room) => makeRoomEntry(room));

      if (res.length === 1) {
        return res[0];
      } else if (res.length > 0) {
        return res;
      }

      return makeNullEntry();
    };
  }

  static makeId(roomInfo) {
    return `${roomInfo.model.urn()}:${roomInfo.externalId}${roomInfo.isMEPSpace ? ':MEPSpace' : ''}`;
  }
}

export class FacetSystemClasses extends FacetDef {

  constructor() {
    super();
    this.id = FacetTypes.systemClasses;
    this.label = i18n.t("System Classes");
  }

  async getClassifier(model) {
    const undefSystem = {
      id: -1,
      label: undefinedLabel(),
      isUnassignedId: true
    };

    // Model was most likely unloaded, no point in continuing.
    if (!model.getData()) {
      return () => undefSystem;
    }

    const masks = model.getData().dbId2SystemClass;

    let allSystems = SystemClassNames.map((name, idx) => {
      return { id: idx, label: name };
    });

    return (dbId) => {
      const mask = masks[dbId];
      if (mask) {
        let res = [];
        for (let i = 0; i < SystemClassNames.length; i++) {
          if (mask & 1 << i) {
            res.push(allSystems[i]);
          }
        }
        return res;
      } else {
        return undefSystem;
      }
    };
  }

  getSorter() {
    return (a, b) => {
      //Make the undefined system class (-1) sort last
      if (a < 0) a = 1e10;
      if (b < 0) b = 1e10;

      return a - b;
    };
  }
}

export class FacetParameters extends FacetDef {

  constructor() {
    super();
    this.id = FacetTypes.parameters;
    this.label = i18n.t("Parameters");
  }

  get hideClustering() {
    return true;
  }

  get hideColoring() {
    return true;
  }

  // returns an object mapping dbId to the set of keys of properties that have stream reading
  // e.g., {2: {'z:BA', 'z:EQ', 'z:Ew'}, 8: {'z:BA', 'z:EQ', 'z:Ew'}}
  async _getDbIdToPropsWithStreamReading(facility) {
    const streamManager = facility.getStreamManager();
    const streamIds = await streamManager.getStreamIds();
    const lastReadings = await streamManager.getLastReadings(streamIds);

    const dbIdToPropsWithStreamReading = {};
    streamIds.forEach((streamId, i) => {
      const streamAttrKeys = Object.keys(lastReadings[i] || {}); // e.g., [z:BA, z:EQ, z:Ew] (corresponding to CO2, Humidity, temp)
      dbIdToPropsWithStreamReading[streamId] = new Set(streamAttrKeys);
    });

    return dbIdToPropsWithStreamReading;
  }

  async getClassifier(model) {

    const dbIdToPropsWithStreamReading = model.isDefault() ? await this._getDbIdToPropsWithStreamReading(model.getParentFacility()) : {};

    const propFam = DtConstants.ColumnFamilies.DtProperties;
    const propTypeFam = "type|" + propFam;

    function getPropertiesColumnIdx(cols) {
      const propIdx = [];
      for (let i = 0; i < cols.length; i++) {
        const id = cols[i].id;
        if (id.startsWith(propFam) || id.startsWith(propTypeFam)) {
          propIdx.push(i);
        }
      }
      return propIdx;
    }

    const unassignedParams = {
      id: UNASSIGNED,
      label: unassignedLabel(),
      isUnassignedId: true
    };
    const { rows, cols } = await model.getClassifiedAssets();

    const rowIdxByDbId = [];
    for (let i = 0; i < rows.length; i++) {
      rowIdxByDbId[rows[i][DtConstants.QC.LmvDbId]] = i;
    }

    const propertyById = new Map();
    for (const idx of getPropertiesColumnIdx(cols)) {
      const property = cols[idx];
      propertyById.set(property.id, property);
    }

    return (dbId) => {
      const rowIdx = rowIdxByDbId[dbId];
      if (rowIdx === undefined) {
        return unassignedParams;
      }

      const row = rows[rowIdx];
      const res = [];

      const propsWithStreamReading = dbIdToPropsWithStreamReading[dbId];

      let nodeCache = {};
      for (let key in row) {
        const isElement = key.startsWith(propFam);
        const isType = key.startsWith(propTypeFam);

        if (!isElement && !isType) continue;

        const { category, uuid, name, flags } = propertyById.get(key) || {};

        const hasStreamReading = propsWithStreamReading?.has(key) ?? false;
        const value = row[key];
        // Not tagged will be either null, undefined, or "".
        const isTagged = !(value == null || value === "") || hasStreamReading;
        const id = `${uuid}|${isTagged ? 'tagged' : 'untagged'}`;
        let node = nodeCache[id];
        if (!node) {
          node = { id, key, label: name, isTagged, category, flags, isType };
          nodeCache[id] = node;
        }
        res.push(node);
      }
      return res.length ? res : unassignedParams;
    };
  }

  getSorter() {
    return sortByLabel([UNDEFINED]);
  }

  /**
   * Return a 2-level hierarchy:
   * - first level: the property name
   * - second level: the tagged status: "Tagged" or "Untagged"
   */
  formatMergedFacetNodes(mergedFacetNodes, partialFacets) {
    const listWithHierarchy = [];
    const paramNodesByUuid = {};

    for (const node of mergedFacetNodes) {
      const { id, isTagged, isType } = node;
      if (id === UNASSIGNED) {
        if (!node.children) node.children = [];
        listWithHierarchy.push(node);
        continue;
      }

      const [uuid] = id.split('|');
      let paramNode = paramNodesByUuid[uuid];
      if (!paramNode) {
        paramNode = new MergedFacetNode({
          id: uuid,
          getSharedProps: () => ({ id: uuid, label: node.label, isType, children: [] })
        }, this.filter.has(id));
        // Overriding total count as children is a single level partition, so total will always be x2.
        paramNode.getTotalCount = () => paramNode.count;
        paramNodesByUuid[uuid] = paramNode;
        listWithHierarchy.push(paramNode);
      }

      paramNode.children.push(node);
      paramNode.count += node.count;
      if (!node.children) node.children = [];
      node.label = isTagged ? i18n.t('Tagged') : i18n.t('Untagged');
    }
    return listWithHierarchy.sort(this.getSorter());
  }
}

export class FacetStatus extends FacetDef {
  constructor() {
    super();
    this.id = FacetTypes.status;
    this.label = i18n.t("Status");
  }

  static get Type() {
    return {
      notStarted: { id: "Not started", label: i18n.t("Not started") },
      inProgress: { id: "In progress", label: i18n.t("In progress") },
      complete: { id: "Complete", label: i18n.t("Complete") }
    };
  }

  async getClassifier(model) {
    const classifiedAssets = await model.getClassifiedAssets();
    const classifiedIdsMap = {},taggedIdsMap = {},completeIdsMap = {};

    for (const row of classifiedAssets.rows) {
      const dbId = row[DtConstants.QC.LmvDbId];
      classifiedIdsMap[dbId] = row;
      let tagged = false;
      let complete = true;
      for (const key of Object.keys(row)) {
        const isPropKey = key.startsWith(`${DtConstants.ColumnFamilies.DtProperties}:`);
        if (isPropKey) {
          if (row[key]) {
            tagged = true;
          } else {
            complete = false;
          }
        }
      }
      if (tagged) taggedIdsMap[dbId] = row;
      if (tagged && complete) completeIdsMap[dbId] = row;
    }

    const getStatus = (dbId) => {
      if (completeIdsMap[dbId]) return FacetStatus.Type.complete;
      if (taggedIdsMap[dbId]) return FacetStatus.Type.inProgress;
      if (classifiedIdsMap[dbId]) return FacetStatus.Type.notStarted;
      return { id: UNDEFINED, label: undefinedLabel() };
    };

    return (dbId) => {
      return getStatus(dbId);
    };
  }

  getSorter() {
    return (a, b) => {
      const order = [FacetStatus.Type.notStarted.id, FacetStatus.Type.inProgress.id, FacetStatus.Type.complete.id, UNDEFINED];
      return order.indexOf(a.id) - order.indexOf(b.id);
    };
  }
}

export class FacetClassifications extends FacetDef {
  constructor() {
    super();
    this.id = FacetTypes.classifications;
    this.label = i18n.t("Classifications");
    this.palette = Object.values(PALETTE_SYSTEMS);
    this.paletteById = PALETTE_SYSTEMS;
  }

  async init(settings, facility, options) {
    await super.init(settings, facility, options);
    this.facility = facility;
    const classification = await this.getClassification(this.facility);
    this.setClassification(classification);
  }

  getClassification(facility) {
    return facility.getClassificationTemplate();
  }

  setClassification(classification) {
    this.classification = classification;

    // Cache classification tree
    this.classificationRoot = FacetClassifications.generateClassificationTree(this.classification);
  }

  static generateClassificationTree(classification) {
    // Cache classification tree
    const classificationRoot = { id: ROOT_ID, children: [] };

    // To reconstruct the tree, we run through rows and keep track of the last parents in a stack.
    // When going one level deeper, each next row (at the same level) are siblings and their parent is the previous row
    // Example of a classification table:
    // [code, description, level]
    // ["A", "Substructure", 1]
    // ["A10", "Foundations", 2]
    // ["A1010", "Standard Foundations", 3]
    // ["A1010.10", "Wall Foundations", 4]
    // ["A1010.30", "Column Foundations", 4]
    // ["A1010.90", "Standard Foundation Supplementary Components", 4]
    // ["A1020", "Special Foundations", 3]
    // ["A1020.10", "Driven Piles", 4]
    // ...
    // ["A1020.80", "Grade Beams", 4]
    // ["A20", "Subgrade Enclosures", 2]

    const nodeById = {};
    // running stack of parents
    const parentsIndices = [];

    for (let i = 0; i < classification.rows.length; i++) {
      const row = classification.rows[i];

      nodeById[row[0]] = { id: row[0], label: row[1], children: [] };

      // compare to previous row
      // if level increases, push parent
      // if level decreases, pop parent
      if (i > 0) {
        const lastRow = classification.rows[i - 1];
        if (row[2] > lastRow[2]) {
          parentsIndices.push(i - 1);
        } else if (row[2] < lastRow[2]) {
          for (let j = 0; j < lastRow[2] - row[2]; j++) {
            parentsIndices.pop();
          }
        }
      }
      // Current node parent is at the top of the stack
      const lastParentRow = classification.rows[parentsIndices[parentsIndices.length - 1]];
      const currentNode = nodeById[row[0]];
      if (lastParentRow === undefined) {
        // Root nodes don't have a parent
        classificationRoot.children.push(currentNode);
      } else {
        const lastParentNode = nodeById[lastParentRow[0]];
        lastParentNode.children.push(currentNode);
      }
    }

    // Add unclassified
    classificationRoot.children.push({
      id: UNCLASSIFIED,
      label: unclassifiedLabel(),
      children: [],
      isUnassignedId: true
    });

    return classificationRoot;
  }

  getDbId2Class(model) {
    return (dbId) => model.getElementCustomClass(dbId);
  }

  async getClassifier(model) {
    if (!this.classification) {
      const classification = await this.getClassification(this.facility);
      this.setClassification(classification);
    }

    const dbId2ClassFn = this.getDbId2Class(model);

    const classId2Row = {}; // id - [code, description, level]
    for (let i = 0; i < this.classification.rows.length; i++) {
      let row = this.classification.rows[i];
      classId2Row[row[0]] = {
        id: row[0],
        label: row[1],
        description: row[0]
      };
    }

    const unclassified = {
      id: UNCLASSIFIED,
      label: unclassifiedLabel(),
      isUnassignedId: true
    };

    return (dbId) => {

      const elClass = dbId2ClassFn(dbId);
      const classRow = classId2Row[elClass];

      if (classRow) {
        return classRow;
      } else {
        return unclassified;
      }
    };
  }

  /**
   * Rearrange facet nodes into a tree to reflect the hierarchical nature of classes
   * @param {MergedFacetNode[]} mergedFacetNodes
   * @returns {MergedFacetNode[]}
   */
  formatHierarchy(mergedFacetNodes) {
    // Reconstruct classification tree by keeping only given mergedFacetNodes and eventually adding missing parents
    const dfs = (node, mergedFacetNodeById) => {
      if (!node) {
        return { found: false, node: null };
      }

      let found = (node.id in mergedFacetNodeById);

      const children = (node.children || []).
      map((child) => {
        const next = dfs(child, mergedFacetNodeById);
        found = found || next.found;
        return next.node;
      }).
      filter((n) => n !== null);

      if (found) {
        let mergedFacetNode = mergedFacetNodeById[node.id];
        if (!mergedFacetNode) {
          // Add missing parent
          mergedFacetNode = new MergedFacetNode({
            id: node.id,
            getSharedProps: () => ({ id: node.id, label: node.label, description: node.id })
          }, this.filter.has(node.id));
          mergedFacetNodeById[node.id] = mergedFacetNode;
        }
        mergedFacetNode.children = children;
        return { found, node: mergedFacetNode };
      }

      return { found, node: null };
    };

    // Accumulate given mergedFacetNodes by id
    const mergedFacetNodeById = { [ROOT_ID]: {} };
    for (const mergedFacetNode of mergedFacetNodes) {
      mergedFacetNodeById[mergedFacetNode.id] = mergedFacetNode;
    }

    const { node: root } = dfs(this.classificationRoot, mergedFacetNodeById);
    return root?.children ?? [];
  }

  getSorter() {
    return (a, b) => {
      // last
      if (a.id === UNCLASSIFIED) {
        if (b.id === UNCLASSIFIED)
        return 0;else

        return 1;
      } else if (b.id === UNCLASSIFIED) {
        return -1;
      }

      return a.label.localeCompare(b.label);
    };
  }

  formatMergedFacetNodes(mergedFacetNodes, partialFacets) {
    return this.formatHierarchy(mergedFacetNodes);
  }
}

export class FacetAssemblyCode extends FacetClassifications {
  constructor() {
    super();
    this.id = FacetTypes.assemblyCode;
    this.label = i18n.t("Assembly Code");
    this.palette = Object.values(PALETTE_SYSTEMS);
    this.paletteById = PALETTE_SYSTEMS;
  }

  getDbId2Class(model) {
    return (dbId) => model.getElementUfClass(dbId);
  }

  getClassification() {
    return DtConstants.getClassification(DtConstants.UNIFORMAT_UUID);
  }
}

export class FacetAttribute extends FacetDef {
  constructor() {
    super();
    this.id = FacetTypes.attributes;
    this.label = ""; // attribute name
    this.palette = PALETTE_SOURCE_FILES;
    this.attribute = null;
    this.facility = null;
  }

  async init(settings, facility, options) {
    super.init(settings, facility, options);
    this.facility = facility;
  }

  setSettings(settings) {
    super.setSettings(settings);
    // we allow multiple facets of type "attributes". They differ from the attribute used
    this.id = FacetTypes.attributes + "_" + this.settings.attributeHash;
  }

  setAttribute(attribute) {
    this.attribute = attribute;
    this.label = attribute.name;
  }

  isLoaded() {
    return this.attribute !== null;
  }

  isSystemClassificationAttribute() {
    return this.attribute.category === "Mechanical" && this.attribute.name === "System Classification";
  }

  async getClassifier(model, hash2CustomFacetPromise) {

    const nullFacet = {
      id: UNDEFINED,
      label: undefinedLabel(),
      isUnassignedId: true
    };

    // Model with the attribute definition isn't loaded yet
    // return placeholder classifier until the model is loaded
    if (!this.attribute) {
      return () => nullFacet;
    }

    const hash2CustomFacet = await hash2CustomFacetPromise;
    const { buckets, dbId2bucketIdx } = hash2CustomFacet[this.settings.attributeHash] || {};

    if (this.isSystemClassificationAttribute()) {

      //Special classifier for Revit System Classification property.
      //In Revit, that's encoded as a comma separated list of Revit Systems,
      //so we split the string value and generate multiple possible facets for each element

      return (dbId) => {
        if (!buckets) {
          return nullFacet;
        }

        let result = [];

        let ids = buckets[dbId2bucketIdx[dbId]].split(",");

        for (let i = 0; i < ids.length; i++) {
          result.push({ id: ids[i], label: ids[i], value: ids[i] });
        }

        return result;
      };

    } else {

      return (dbId) => {
        if (!buckets) {
          return nullFacet;
        }

        let id = buckets[dbId2bucketIdx[dbId]];
        let label = id;
        let value = id;

        // Attribute formatting
        if (this.attribute && id !== UNDEFINED) {
          if (this.attribute.dataType === AttributeType.Url) {
            const document = this.facility.settings.docs?.find((doc) => doc.id === id);
            if (document) {
              label = document.name;
            } else {
              id = value = UNDEFINED;
              label = undefinedLabel();
            }
          } else if (this.attribute.dataType === AttributeType.DateTime) {
            value = new Date(id);
            label = value.toLocaleString();
          } else {
            const forgeUnit = this.attribute.forgeUnit ? "autodesk.unit.unit:" + this.attribute.forgeUnit + "-1.0.0" : this.attribute.dataTypeContext;
            id = formatValueWithUnits(id, forgeUnit, this.attribute.dataType, this.attribute.precision, { symbolKey: this.attribute.forgeSymbol });
            label = id;
          }
        }

        return { id, label, value, isUnassignedId: id === UNDEFINED };
      };
    }
  }

  getSorter() {
    return (a, b) => {
      // last
      if (a.id === UNDEFINED || a.label === undefined) {
        if (b.id === UNDEFINED || b.label === undefined)
        return 0;else

        return 1;
      } else if (b.id === UNDEFINED || b.label === undefined) {
        return -1;
      }

      let dt = this.attribute?.dataType || AttributeType.Unknown;
      if (
      dt === AttributeType.Integer ||
      dt === AttributeType.Double ||
      dt === AttributeType.Float)
      {
        return a.value - b.value;
      }

      return a.label.toString().localeCompare(b.label.toString());
    };
  }
}

export class FacetModels extends FacetDef {
  constructor() {
    super();
    this.id = FacetTypes.models;
    this.label = i18n.t("Sources");
    this.palette = PALETTE_SOURCE_FILES;
  }

  async getClassifier(model) {
    this.model = model;

    return (dbId) => {
      return {
        id: this.model.urn(),
        label: this.model.displayName()
      };
    };
  }
}

export class FacetRvtCategories extends FacetDef {

  constructor() {
    super();
    this.id = FacetTypes.categories;
    this.label = i18n.t("Revit Categories");
  }

  async getClassifier(model) {

    const nullCategory = {
      id: UNASSIGNED,
      label: unassignedLabel(),
      isUnassignedId: true
    };

    if (!model.getData()) {
      return () => nullCategory;
    }

    const CATEGORIES = await DtConstants.getRevitCategories();
    let dbId2catId = model.getData().dbId2catId;

    let nodeCache = {};

    return (dbId) => {
      let cat = dbId2catId[dbId];

      let cached = nodeCache[cat];
      if (cached) {
        return cached;
      } else
      {
        nodeCache[cat] = cached = {
          id: cat,
          label: CATEGORIES[cat]
        };

        return cached;
      }
    };
  }
}

export class FacetRvtFamilies extends FacetDef {

  constructor() {
    super();
    this.id = FacetTypes.families;
    this.label = i18n.t("Revit Families");
  }

  async getClassifier(model) {

    const nullFamily = {
      id: UNASSIGNED,
      label: unassignedLabel(),
      isUnassignedId: true
    };

    if (!model.getData()) {
      return () => nullFamily;
    }

    let it = model.getInstanceTree();
    let dbId2ftypeId = model.getData().dbId2ftypeId;

    return (dbId) => {
      let parentId = it.getNodeParentId(dbId2ftypeId[dbId]);
      if (!parentId) {
        return nullFamily;
      }

      return { id: it.getNodeName(parentId) };
    };
  }
}

export class FacetRvtTypes extends FacetDef {

  constructor() {
    super();
    this.id = FacetTypes.types;
    this.label = i18n.t("Revit Types");
  }

  async getClassifier(model) {

    const nullRvtType = {
      id: UNASSIGNED,
      label: unassignedLabel(),
      isUnassignedId: true
    };

    if (!model.getData()) {
      return () => nullRvtType;
    }

    let dbId2ftypeId = model.getData().dbId2ftypeId;
    let it = model.getInstanceTree();

    return (dbId) => {
      let tId = dbId2ftypeId[dbId];
      if (!tId) {
        return nullRvtType;
      }
      return { id: it.getNodeName(tId) };
    };
  }
}

export class PerModelFacet {

  constructor(props, modelUrn) {

    this.modelUrn = modelUrn;

    //TODO: we can get rid of the Object.assign
    //by removing the dependency from FacetManager
    Object.assign(this, props);
    this.id = props.id;
    this.sharedProps = props; //id, plus any extra properties returned by the facet classifier function (like elevation for Floors)

    this.children = {};
    this.ids = [];

    this.idsInMultipleFacets = null;
  }

  findOrAddChild(dbId, facetForElement) {
    this.ids.push(dbId);

    let nextFacet = this.children[facetForElement.id];
    if (!nextFacet) {
      this.children[facetForElement.id] = nextFacet = new PerModelFacet(facetForElement, this.modelUrn);
    }

    return nextFacet;
  }

  addLeaf(dbId) {
    this.ids.push(dbId);
  }

  getSharedProps() {
    return this.sharedProps;
  }

  markAsAddedToMultipleFacets(dbId) {
    if (!this.idsInMultipleFacets) {
      this.idsInMultipleFacets = new Set();
    }

    this.idsInMultipleFacets.add(dbId);
  }
}


export class MergedFacetNode {

  /**
   * @param {PerModelFacet} protoFacetNode
   * @param {Boolean} selected
   */
  constructor(protoFacetNode, selected) {

    this.id = protoFacetNode.id;
    this.count = 0;
    this.selected = selected;
    this.idsSets = {};
    this.children = null;
    this.label = null;
    this.idsInMultipleFacets = {};

    //Copy other necessary facet node information (see PerModelFacet)
    Object.assign(this, protoFacetNode.getSharedProps());
  }

  /**
   * Get total number of items in this Facet tree
   * @returns {number} totalCount
   */
  getTotalCount() {
    let total = this.count;
    let children = this.children;
    if (children) {
      for (let i = 0; i < children.length; i++) {
        total += children[i].getTotalCount();
      }
    }
    return total;
  }

  /**
   * @param {PerModelFacet} facetNode
   */
  addFacetNode(facetNode) {

    //Process leaf node children
    let ids = facetNode.ids;

    if (facetNode.idsInMultipleFacets) {
      if (!this.idsInMultipleFacets[facetNode.modelUrn]) {
        this.idsInMultipleFacets[facetNode.modelUrn] = new Set();
      }

      facetNode.idsInMultipleFacets.forEach((dbId) => this.idsInMultipleFacets[facetNode.modelUrn].add(dbId));
    }

    //Recalculate the total number of elements in this combined facet node.
    //Because an element can appear in multiple facet nodes (like in multiple rooms)
    //we need fancy set logic to de-duplicate them.
    let combinedSet = this.idsSets[facetNode.modelUrn];
    if (!combinedSet) {
      this.idsSets[facetNode.modelUrn] = combinedSet = new Set();
    }
    let oldSize = combinedSet.size;
    for (let i = 0; i < ids.length; i++) {
      combinedSet.add(ids[i]);
    }

    this.count += combinedSet.size - oldSize;
  }

  collectMatchingIds(urn, partialFacets) {

    let res = [];

    const fids = this.idsSets[urn];
    if (fids) {
      const hiddenIds = partialFacets[this.id]?.[urn];
      if (hiddenIds) {
        let filteredIds = [];
        for (let id of fids) {
          if (!hiddenIds.has(id)) {
            filteredIds.push(id);
          }
        }
        res = filteredIds;
        this.partial = filteredIds.length < fids.size;
      } else {
        res = fids.values();
      }
    }

    return res;
  }

}

export const FacetRegistry = {
  [FacetTypes.levels]: FacetLevels,
  [FacetTypes.assemblyCode]: FacetAssemblyCode,
  [FacetTypes.classifications]: FacetClassifications,
  [FacetTypes.attributes]: FacetAttribute,
  [FacetTypes.models]: FacetModels,
  [FacetTypes.categories]: FacetRvtCategories,
  [FacetTypes.families]: FacetRvtFamilies,
  [FacetTypes.types]: FacetRvtTypes,
  [FacetTypes.spaces]: FacetSpaces,
  [FacetTypes.status]: FacetStatus,
  [FacetTypes.systemClasses]: FacetSystemClasses,
  [FacetTypes.mepSystems]: FacetSystems,
  [FacetTypes.parameters]: FacetParameters
};

export function deserializeFacet(facetDefId) {
  let ctor;

  for (let type in FacetRegistry) {
    // Facet ids are prefixed by their types
    if (facetDefId.startsWith(type)) {
      ctor = FacetRegistry[type];
      break;
    }
  }

  if (!ctor) {
    console.error("Cannot instantiate facet for", facetDefId);
    return null;
  }

  return new ctor();
}

export function getDefaultFacetList(wantClassification) {
  const defaultFacetList = [
  FacetModels,
  FacetLevels,
  FacetSpaces,
  wantClassification && FacetClassifications,
  FacetAssemblyCode,
  FacetRvtCategories
  //FacetRvtFamilies, //off by default
  //FacetRvtTypes, //off by default
  //FacetSystems, //off by default
  ];
  return defaultFacetList.filter(Boolean);
}