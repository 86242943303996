const SECOND = 1000;
const MIN = 60 * SECOND;
const HOUR = 60 * MIN;
const DAY = 24 * HOUR;

/**
 * Enum of temporal granularity for time-series readings.
 *
 * Granularity is limited to a day of data, and only includes divisions which are factors of a day. (no remainder)
 * @readonly
 * @enum {number}
 */
export const GRANULARITY = Object.freeze({
  Day: DAY,
  SixHours: 6 * HOUR,
  ThreeHours: 3 * HOUR,
  Hour: HOUR,
  HalfHour: 30 * MIN,
  Default: 15 * MIN, // default for roll-ups
  Raw: 0 // default for individual events
});

/**
 * Date interval for bulk stream readings
 * @typedef {Array} DateRange
 * @property {string} 0 - Start of the interval as a UTC date ex: 2006-01-02
 * @property {string} 1 - Exclusive end of the interval as a UTC date ex: 2006-01-02
 */

/** Retrieves all streams having data for a date-range. (cached) */
export default class ReadingsByDate {
  #intervalID;
  #streamMgr;

  constructor(streamMgr) {
    this.#streamMgr = streamMgr;
  }

  /**
   * Get all stream readings for a date-range.
   * @param {DateRange} range Date interval for bulk stream readings
   * @param {GRANULARITY} granularity Desired temporal granularity (binning) of TS readings.
   * @param {boolean?} forceRefresh Forces a refresh of the requested range.
   */
  async getReadings(range, granularity, forceRefresh) {
    const model = await this.#streamMgr._getDefaultModel();
    if (!model) {
      return [];
    }
    if (this.#intervalID == null) {
      this.setupStreamGC(model);
    }

    return await model.asyncPropertyOperation({
      "operation": "GET_BULK_READINGS",
      "range": range,
      "granularity": granularity,
      "forceRefresh": forceRefresh
    });
  }

  /** @private */
  setupStreamGC(model) {
    this.#intervalID = setInterval(() => model.asyncPropertyOperation({ "operation": "GC_BULK_READINGS" }), MIN);
  }
}