import THREE from "three";

export const SystemConnectionType = Object.freeze({
  Previous: -1,
  Next: 1
});

/** MEP system */
export class System {

  constructor(id, dbId, name, filter, tolerance) {let isDirty = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;let mapCenterLines = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;

    // Defined by the backend
    this.id = id;
    this.dbId = dbId;

    /** @type {Map<string, Map<number, SystemElement>>} connections per model */
    this.connections = new Map();

    // User defined
    this.name = name;
    this.tolerance = tolerance;
    this.mapCenterLines = mapCenterLines;

    // Encoded filter, same as server-side
    this.filter = filter;
    // Connections get outdated due to element changes
    this.isDirty = isDirty;

    // Client-side data
    this.elementsCount = 0;
  }

  clear() {
    this.elementsCount = 0;
    this.connections.clear();
  }

  /**
   * @returns {bool}
   */
  hasCachedConnections() {
    return this.connections.size > 0;
  }

  /**
   * @param {SystemElement} element
   */
  addElement(element) {
    const urn = element.model.urn();
    const dbId = element.dbId;

    if (this.hasElement(urn, dbId)) {
      return;
    }

    if (!this.connections.has(urn)) {
      this.connections.set(urn, new Map());
    }

    const perModel = this.connections.get(urn);
    perModel.set(dbId, element);
  }

  /**
   * @param {String} urn
   * @param {Number} dbId
   * @returns {SystemElement}
   */
  getElement(urn, dbId) {
    return this.connections.get(urn)?.get(dbId);
  }

  /**
   * @param {String} urn
   * @param {Number} dbId
   */
  hasElement(urn, dbId) {
    return !!this.getElement(urn, dbId);
  }

  /**
   * @param {String} urn
   * @param {Number} dbId
   */
  removeElement(urn, dbId) {
    this.getElement(urn, dbId)?.dtor();
    return this.get(urn)?.delete(dbId);
  }

  /**
   * Generator, iterate through all elements of the system
   */
  *allElements() {
    for (const [, perModel] of this.connections) {
      for (const [, element] of perModel) {
        yield element;
      }
    }
  }
}

const _tmpBox = new THREE.Box3();
const _boxArr = new Array(6);

export class SystemElement {
  /**
   * Object that represents an element within a System.
   * @param {DtModel} model
   * @param {Number} dbId
   */
  constructor(model, dbId) {
    this.model = model;
    this.dbId = dbId;

    this.previous = new Set();
    this.next = new Set();
  }

  /**
   * @param {SystemElement} conn
   * @returns {SystemConnectionType}
   */
  getConnectionType(conn) {
    if (this.previous.has(conn)) return SystemConnectionType.Previous;else
    if (this.next.has(conn)) return SystemConnectionType.Next;else
    return null;
  }

  /**
   * Connect SystemElement to this element.
   * Does not propagate, you should handle possible mirror connection.
   * @param {SystemElement} el
   * @param {SystemConnectionType} connType Relationship `el` has on this element (default: Next)
   * (Ex: connType === SystemConnectionType.Next) `el` is downstream from this element.
   */
  connect(el) {let connType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : SystemConnectionType.Next;

    // no-op
    if (connType === this.getConnectionType(el)) {
      return;
    }

    this.disconnect(el);

    // Add connection
    if (connType === SystemConnectionType.Previous) {
      this.previous.add(el);
    } else {
      this.next.add(el);
    }
  }

  /**
   * Removes SystemElement connection from this element.
   * Does not propagate, you should handle possible mirror connection.
   * @param {SystemElement} conn
   */
  disconnect(el) {
    this.previous.delete(el);
    this.next.delete(el);
  }

  /**
   * Remove all reference to this System Element and delete connection sets
   */
  dtor() {
    for (const [conn] of this.allConnections()) {
      conn.disconnect(this);
    }

    delete this.previous;
    delete this.next;
  }

  isStandalone() {
    return this.connectionCount() === 0;
  }

  isLeaf() {
    return this.connectionCount() === 1;
  }

  isFork() {
    return this.connectionCount() > 2;
  }

  connectionCount() {
    return this.previous.size + this.next.size;
  }

  /**
   * @param {boolean} inclusiveSelect
   * @returns {[SystemElement]} neighbours
   */
  getNeighbours() {let inclusiveSelect = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    const neighbours = [...this.previous, ...this.next];

    if (inclusiveSelect) {
      neighbours.push(this);
    }

    return neighbours;
  }

  *allConnections() {
    for (const conn of this.previous) {
      yield [conn, SystemConnectionType.Previous];
    }
    for (const conn of this.next) {
      yield [conn, SystemConnectionType.Next];
    }
  }

  /**
   * Enum connected elements of given element
   * @param {SystemConnectionType?} direction Direction of travel, all known connections if unspecified
   * @param {(element: SystemElement, connectedElement: SystemElement) => {}} cb
   */
  enumConnections(direction, cb) {let _depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    if (!(cb instanceof Function)) {
      return;
    }

    _depth++;

    if (direction !== SystemConnectionType.Next) {
      for (const conn of this.previous) {
        cb(this, conn, _depth);
      }
    }

    if (direction !== SystemConnectionType.Previous) {
      for (const conn of this.next) {
        cb(this, conn, _depth);
      }
    }
  }

  /**
   * Callback for bfsConnections
   * Return true to stop search beyond current node
   * @callback pathFilterCB
   * @param {SystemElement} current
   * @param {SystemElement} next
   * @param {Number} depth
   * @returns {boolean} True to stop traversal from current node
   */

  /**
   * Breadth first search traversal of a sub-system starting from root.
   *
   * Note: Callback is called on edges using current and next nodes as params,
   * which means callback will not be called for root nodes without neighbours,
   * and leaf nodes will never be passed as current node.
   * @param {SystemElement} root
   * @param {pathFilterCB} cb Determines whether to stop traversal from current node
   * @returns {Set<SystemElement>}
   */
  bfsConnections() {let cb = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : () => false;let traversed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Set();

    const queue = [this];
    let depth = 0;

    while (queue.length > 0) {
      const depthSize = queue.length;

      for (let i = 0; i < depthSize; i++) {
        const curr = queue.shift();

        if (traversed?.has(curr)) continue;
        traversed?.add(curr);

        for (const [next] of curr.allConnections()) {
          !cb(curr, next, depth) && queue.push(next);
        }
      }

      depth++;
    }

    return traversed;
  }

  getCenterPoint() {
    if (this.center) {
      // Use previous calculation
      return this.center;
    }

    const it = this.model.getInstanceTree();

    _tmpBox.makeEmpty();

    it.getNodeBox(this.dbId, _boxArr);
    _tmpBox.min.set(_boxArr[0], _boxArr[1], _boxArr[2]);
    _tmpBox.max.set(_boxArr[3], _boxArr[4], _boxArr[5]);

    if (_tmpBox.isEmpty()) {
      // Hidden element
      return;
    }

    this.center = _tmpBox.getCenter();

    return this.center;
  }
}