
const WebSocket = require('isomorphic-ws');

//Copied from compat.js to avoid importing ES6 exports from plain node.js forge-tools.
const isBrowser = typeof navigator !== "undefined";
const isNodeJS = function () {
  return !isBrowser;
};

//DT web socket protocol.
export class DtWs {

  constructor(endpointName, loadContext, loadCB, errorCB) {
    this.endpointName = endpointName;
    this.ws = null;
    this._pendingCallbacks = [];
    this._retriedOpen = 0;
    this._wsUsable = !loadContext.disableWebSocket && typeof WebSocket !== "undefined" && !!loadContext[this.endpointName];
    this.loadCB = loadCB;
    this.errorCB = errorCB;

    this.numRequests = 0;
  }

  notifyPendingCallbacks(ws) {
    this._pendingCallbacks.forEach(function (cb) {
      cb(ws);
    });
    this._pendingCallbacks = [];
  }

  getWebSocket() {

    //Socket already established?
    var ws = this.ws;
    if (ws && ws.readyState === WebSocket.OPEN) {
      return ws;
    } else if (ws && ws.readyState === WebSocket.CONNECTING) {
      console.warn("Reentry into getWebSocket. Should not happen.");
      //If we get called while the web socket is still opening,
      //ignore the callback, we will continue processing once it's open.
      return null;
    }

    return null;
  }

  startSession(loadContext, doneCB) {

    if (!this._wsUsable) {
      doneCB && doneCB(null);
      return;
    }

    //Remember the given callback
    doneCB && this._pendingCallbacks.push(doneCB);

    //Are we still in the process of opening the socket?
    if (this._opening) {
      return;
    }

    //If socket is already open, notify the callback (and any previously pending callbacks
    var ws = this.getWebSocket();
    if (ws) {
      this.notifyPendingCallbacks(ws);
      return;
    }

    if (loadContext)
    this.loadContext = loadContext;

    //console.log("Init worker called");

    this.openWebSocket((ws) => {
      //console.log("Web socket open.");

      this.notifyPendingCallbacks(ws);
    });
  }

  endSession() {

    //The worker can be used by multiple loaders, so only close the
    //web socket if it's not waiting on other requests.
    if (this.numRequests) {
      console.warn("Messages still pending. Leaving WebSocket open.");
      return;
    }

    var ws = this.getWebSocket();
    if (ws && ws.readyState === 1) {

      console.log("Web socket close.");

      this._opening = false;
      this._clientClose = true;
      ws.close(1000);
      this.ws = null;
    }
  }

  openWebSocket() {

    let loadContext = this.loadContext;

    this._opening = true;

    //http and 7124->7125 are here to support local debugging, when the endpoints are overridden to
    //point directly to local node.js process(es).
    let url = loadContext[this.endpointName].replace("https:", "wss:").replace("http:", "ws:").replace(":7124", ":7125");

    if (loadContext.queryParams) {
      url += "?" + loadContext.queryParams;
    }

    let ws = new WebSocket(url, undefined, { headers: loadContext.headers });

    ws.addEventListener('open', (event) => {
      this.ws = ws;
      this._opening = false;
      this.onOpen(event);
      this.notifyPendingCallbacks(ws);
    });

    ws.addEventListener('message', (event) => {
      this.onMessage(event.data);
    });

    ws.addEventListener('close', (event) => {
      this.onClose(event);
    });

    ws.addEventListener('error', (data) => {
      this.onError(data);
    });
  }

  onOpen(event) {

    this.ws.binaryType = "arraybuffer";

    //On web clients that do not use the cookie approach, the headers
    //will not get sent (unlike on node.js WebSocket implementation
    //so we send the Authorization first thing after open
    if (!isNodeJS()) {
      //console.log("Sending headers as message", JSON.stringify(loadContext.headers));
      this.ws.send("/headers/" + JSON.stringify(this.loadContext.headers));
    }
  }

  onMessage(data) {

    //To be overloaded by inheriting class.
  }
  onError(data) {

    console.log("ws error, reverting to plain http.", data);

    this._opening = false;

    this._wsUsable = false;

    this.notifyPendingCallbacks(this.ws);

    this.errorCB && this.errorCB(true);

    this.ws = null;
  }

  onClose(event) {

    this._opening = false;

    if (!this._clientClose && this.numRequests) {

      console.log("Abnormal socket close. Retrying.", event.code, event.reason, "pending:", this.numRequests);
      this._retriedOpen++;

      this.numRequests = 0;

      //case where there were pending requests when the socket
      //closed -- we have to reissue those requests.
      if (this._retriedOpen <= 3) {
        setTimeout(() => {
          this.openWebSocket((ws) => {
            this.notifyPendingCallbacks(ws);
          });
        }, 2000);
      } else {
        console.error("Too many WebSocket failures. Giving up on mesh load.");

        this._wsUsable = false;

        //Tell our owner that they need to retry or fail or something.
        this.errorCB && this.errorCB(this.loadContext);
      }
    }

    this._clientClose = false;
    this.ws = null;

  }

}