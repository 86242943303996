import { QC } from "./schema/dt-schema";

/**
 * Intersect dbIds properties and values
 * @param {Object[]} props
 * @param {string} xKey key used to intersect the properites. ex: "internalName"
 */
export function intersectPropertiesDt(props, xKey) {
  const eltProps = {};
  const typeProps = {};
  const parentProps = {};
  let eltPropsCount = 0;
  let typePropsCount = 0;
  let parentPropsCount = 0;

  function intersectSystems(fst, systems) {
    // will be equal to zero for just one element,
    // "varies" will be "false" in this case
    let union = 0;
    let intersection = 0;

    for (let i = 0; i < systems.length; i++) {
      const sys = systems[i];
      union |= sys.displayValue;
      intersection = i === 0 ? sys.displayValue : intersection & sys.displayValue;
    }

    fst.union = union;
    fst.intersection = intersection;
    fst.difference = union ^ intersection;

    return fst;
  }

  function groupPropsBy(props, acc, key) {
    for (const prop of props) {
      if (!acc.hasOwnProperty(prop[key])) {
        acc[prop[key]] = [];
      }
      acc[prop[key]].push(prop);
    }
  }

  function intersectProps(acc, count) {
    for (const key in acc) {
      // filter out properties that are not shared across all dbIds
      if (acc[key].length !== count) {
        delete acc[key];
      } else {
        // intersect values
        const fst = acc[key][0];
        const val = fst.displayValue;
        // a multi-selection prop value "varies" if values are different
        const allEquals = acc[key].every((prop) => prop.displayValue === val);
        // if a prop value "varies" across props of the same model, it also "varies" across props of different models
        const someVaries = !allEquals || acc[key].some((prop) => prop.varies);
        // all prop values are filled (not undefined or null)
        const allPresent = acc[key].every((prop) => ![null, undefined].includes(prop.displayValue));
        fst.varies = someVaries;
        fst.allPresent = allPresent;
        // for anything but systems we keep only one prop. for systems we store additional information about unified and intersected
        // systems of all elements
        if (fst.internalName === QC.SystemClass) {
          acc[key] = intersectSystems(fst, acc[key]);
        } else {
          acc[key] = fst;
        }
      }
    }
  }

  // remove residual history
  function removePropsHistoryBy(props, key) {
    const groups = {};

    for (const prop of props) {
      if (!groups.hasOwnProperty(prop[key])) {
        groups[prop[key]] = [];
      }
      groups[prop[key]].push(prop);
    }

    return Object.values(groups).map((p) => {
      p.sort((a, b) => b.timestamp - a.timestamp);
      return p[0];
    });
  }

  let eltIds = [];
  let typeIds = [];
  let parentIds = [];
  let models = [];
  let haveModels = false;

  for (const p of props) {

    if (p.model) {
      haveModels = true;
    }

    //Is it a multi-select result
    if (p.element.dbIds) {
      //We want array indices to match for element/type/parent/model, so we do it
      //regardless of whether each of those has any properties
      for (let i = 0; i < p.element.dbIds.length; i++) {
        models.push(p.model);

        eltIds.push(p.element.dbIds[i]);
        typeIds.push(p.type?.dbIds[i]);
        parentIds.push(p.element.parentDbIds[i]);
      }
    } else {
      models.push(p.model);

      //We want array indices to match for element/type/parent/model, so we do it
      //regardless of whether each of those has any properties
      eltIds.push(p.element.dbId);
      parentIds.push(p.element.parentDbId);
      typeIds.push(p.type?.dbId);
    }

    if (p.element) {
      eltPropsCount++;
      groupPropsBy(removePropsHistoryBy(p.element.properties, xKey), eltProps, xKey);
    }
    if (p.type) {
      typePropsCount++;
      groupPropsBy(removePropsHistoryBy(p.type.properties, xKey), typeProps, xKey);
    }
  }

  intersectProps(eltProps, eltPropsCount);
  intersectProps(typeProps, typePropsCount);

  //When running this on the worker side, we don't have the model instances.
  //When running on the main thread, we do. This just sets the models array to null
  //when running on the worker side, so we don't send meaningless data to the main thread.
  if (!haveModels) {
    models = undefined;
  }

  //Note here we use plural dbIds instead of singular dbId, to indicate that
  //this is a multiple selection. Same for models vs. model.
  const element = { dbIds: eltIds, parentDbIds: parentIds, properties: Object.values(eltProps) };
  const type = { dbIds: typeIds, properties: Object.values(typeProps) };
  return { models, element, type };
}