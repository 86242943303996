import { base64DecToArr, base64EncArr } from "./base64";
import { DtConstants } from "../schema/DtConstants";

function writeVarint(buf, offset, value) {
  var startOffset = offset[0];
  value = 0 | value;
  do {
    var byte = 0 | value & 0x7f;
    value >>>= 7;
    if (value) {
      byte |= 0x80;
    }
    buf[offset[0]++] = byte;
  } while (value);

  return offset[0] - startOffset;
}

function readVarint(buf, offset) {
  var b;
  var value = 0;
  var shiftBy = 0;
  do {
    b = buf[offset[0]++];
    value |= (b & 0x7f) << shiftBy;
    shiftBy += 7;
  } while (b & 0x80);
  return value;
}

//A varint encoded int64 can take up to 9 bytes, so this is enough
let _buf = new Uint8Array(9);
let _offset = [0];

//Returns a base64 encoded string of a varint representation of the input integer
function vlqEncode(int) {
  _offset[0] = 0;
  let len = writeVarint(_buf, _offset, int);
  return base64EncArr(_buf.slice(0, len));
}

function vlqDecode(str) {
  let len = base64DecToArr(str, _buf);
  _offset[0] = 0;
  return readVarint(_buf, _offset);
}

let keyBuff = new Uint8Array(24);

// Used for logical System elements stored in default model
export function vlqID2ElementKey(encodedID) {
  const keyLen = base64DecToArr(DtConstants.SystemElementFirstKey, keyBuff);
  const decodedID = vlqDecode(encodedID);

  keyBuff[keyLen - 4] = decodedID >> 24 & 0xff;
  keyBuff[keyLen - 3] = decodedID >> 16 & 0xff;
  keyBuff[keyLen - 2] = decodedID >> 8 & 0xff;
  keyBuff[keyLen - 1] = decodedID & 0xff;

  return base64EncArr(keyBuff);
}

// Used for logical System elements stored in default model
export function elementKey2vlqID(elementKey) {
  const keyLen = base64DecToArr(elementKey, keyBuff);

  let id = keyBuff[keyLen - 4] << 24;
  id |= keyBuff[keyLen - 3] << 16;
  id |= keyBuff[keyLen - 2] << 8;
  id |= keyBuff[keyLen - 1];

  return vlqEncode(id);
}