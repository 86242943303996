import vertexTextureQuad from "../post/quad.vert.wgsl";
import { wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";
import ghosting from "../chunks/ghosting.wgsl";

function getFragmentShader() {

  return wgsl /*wgsl*/`

	${ghosting}

	@id(0) override r1: f32 = 0.9;
	@id(1) override g1: f32 = 0.9;
	@id(2) override b1: f32 = 0.9;

	@id(3) override r2: f32 = 0.59;
	@id(4) override g2: f32 = 0.59;
	@id(5) override b2: f32 = 0.59;

	@fragment
	fn main(
		@builtin(position) coord : vec4f,
		@location(0) @interpolate(linear) vUv: vec2f
	) -> @location(0) vec4f {
		var outColor = mix(vec3f(r2, g2, b2), vec3f(r1, g1, b1), vUv.y);
		return vec4f(outColor + orderedDithering(vec2i(floor(coord.xy))) / 256.0, 1.0);
	}
	`;

}

export function GradientPass(renderer) {

  let _renderer = renderer;
  let _device;

  let _pipeline;
  let _passDescriptor;
  let _vShader;
  let _fShader;

  let _r1, _g1, _b1, _r2, _g2, _b2;

  function createPipeline() {
    _pipeline = _device.createRenderPipeline({
      layout: "auto",
      vertex: {
        module: _vShader,
        entryPoint: "main"
      },
      fragment: {
        module: _fShader,
        entryPoint: "main",
        targets: [
        {
          format: _renderer.getRenderTargets().getColorTarget().format
        }],

        constants: {
          0: _r1, 1: _g1, 2: _b1,
          3: _r2, 4: _g2, 5: _b2
        }
      },
      primitive: {
        topology: 'triangle-list',
        cullMode: 'back'
      }
    });
  }

  function createResources() {

    if (!_vShader) {
      _vShader = _device.createShaderModule({ code: vertexTextureQuad });
    }

    if (!_fShader) {
      _fShader = _device.createShaderModule({ code: getFragmentShader() });
    }

    if (!_pipeline) {
      createPipeline();
    }

    if (!_passDescriptor) {
      _passDescriptor = {
        colorAttachments: [
        {
          // view is acquired and set in render loop.
          view: undefined,

          clearValue: { r: 0.0, g: 0.0, b: 0.0, a: 1.0 },
          loadOp: 'clear',
          storeOp: 'store'
        }]


      };
    }

  }

  this.init = function () {
    _device = _renderer.getDevice();
    createResources();
  };

  this.run = function () {

    if (!_device) {
      return;
    }

    let commandEncoder = _device.createCommandEncoder();

    _passDescriptor.colorAttachments[0].view = _renderer.getRenderTargets().getColorTargetView();

    let pass = commandEncoder.beginRenderPass(_passDescriptor);

    pass.setPipeline(_pipeline);
    pass.draw(3);
    pass.end();

    _device.queue.submit([commandEncoder.finish()]);

  };

  this.setClearColors = function (r1, g1, b1, r2, g2, b2) {
    _r1 = r1;
    _g1 = g1;
    _b1 = b1;

    _r2 = r2;
    _g2 = g2;
    _b2 = b2;

    _pipeline = null;
    if (_device) {
      createPipeline();
    }
  };

  this.getClearColors = function () {
    return [_r1, _g1, _b1, _r2, _g2, _b2];
  };

}