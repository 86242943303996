import { DepthFormat, NormalBlend } from "../CommonRenderTargets";

import { getColorShader } from "./GroundShadowShaders";

export class GroundShadowColorPipeline {

  #renderer;
  #device;

  #pipeline;

  // Defines at which location the different bind groups are to be bound.
  // Everything that relies on this order is set up automatically, including the shader code.
  #bindGroupOrder = {
    camera: 0,
    texture: 1
  };
  #bindGroupLayouts = [];
  #targets = [];

  constructor(renderer, cameraUniformLayout, textureLayout, targetFormat, geomStride) {
    this.#renderer = renderer;
    this.#device = renderer.getDevice();

    for (let key in this.#bindGroupOrder) {
      switch (key) {
        case 'camera':
          this.#bindGroupLayouts[this.#bindGroupOrder[key]] = cameraUniformLayout;
          break;
        case 'texture':
          this.#bindGroupLayouts[this.#bindGroupOrder[key]] = textureLayout;
          break;
      }
    }

    this.#targets.push({
      format: targetFormat,
      blend: NormalBlend
    });


    const shader = this.#device.createShaderModule({
      label: 'ground shadow color shader',
      code: getColorShader(this.#bindGroupOrder.camera, this.#bindGroupOrder.texture)
    });

    this.#pipeline = this.#device.createRenderPipeline({
      label: 'ground shadow color pipeline',
      layout: this.#device.createPipelineLayout({
        bindGroupLayouts: this.#bindGroupLayouts
      }),
      vertex: {
        module: shader,
        entryPoint: 'vsmain',
        buffers: [
        {
          arrayStride: geomStride * 4,
          attributes: [
          {
            shaderLocation: 0, // pos
            offset: 0,
            format: "float32x3"
          },
          {
            shaderLocation: 1, // uv
            offset: 12,
            format: "float32x2"
          }]

        }]

      },
      fragment: {
        module: shader,
        entryPoint: 'psmain',
        targets: this.#targets
      },
      primitive: {
        cullMode: 'back',
        topology: 'triangle-list'
      },
      depthStencil: {
        depthWriteEnabled: false,
        depthCompare: 'less',
        format: DepthFormat,
        depthBias: 0,
        depthBiasSlopeScale: 0
      }
    });
  }

  getPipeline() {
    return this.#pipeline;
  }

  getBindGroupOrder() {
    return this.#bindGroupOrder;
  }
}