
import { Label3D } from './Label3D.js';

const createIcon = (icon) => {
  return [
  '<svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">',
  '<g stroke-width="2" stroke="currentColor" fill="none">',
  icon,
  '</g>',
  '</svg>'].
  join('');
};

const Icons = {
  Cross: '<path d="M0 25 L50 25 M25 0 L25 50"/>',
  Circle: '<circle r="5" cx="25" cy="27.5" fill="currentColor" stroke-width="0" />',
  Empty: ''
};

export class PointMarker extends Label3D {

  constructor(viewer, pos3D, labelText) {let icon = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : Icons.Cross;
    super(viewer, pos3D, null);

    this.setIcon(icon);

    // Create another label for the text
    if (labelText) {
      this.label = new Label3D(viewer, pos3D, labelText);

      // Center text above the actual position
      this.label.setVerticalOffset(-45);
    }

    // Set label visible by default. If text is empty, it is hidden anyway.
    this.labelVisible = true;

    // Assign container as default listeners target
    this.target = this.container;
  }

  // @param {string} Color string in css style (e.g. 'rgb(255, 255, 255)');
  setColor(color) {
    this.container.style.color = color;
  }

  setPosition(pos) {
    super.setPosition(pos);
    this.label && this.label.setPosition(pos);
  }

  setVisible(visible) {
    super.setVisible(visible);
    this.label && this.label.setVisible(this.labelVisible && visible);
  }

  setLabelVisible(visible) {
    this.label && this.label.setVisible(this.visible && visible);
  }

  setIcon(icon) {
    this.container.innerHTML = createIcon(icon);
  }

  /**
   * Adds a zone of interactivity within a PointMarker's icon.
   *
   * Useful when the SVG is much larger than the active zone.
    * Also replace mouseout event with mouseleave so it doesn't trigger when mouse
   * exits a descendant, but still fire ON_MOUSE_OUT under the hood for simplicity.
   *
   * @param {string} elementId DOM ID of the icon sub-element to be used as target for listeners
   */

  addListenersToIconComponent(elementId) {
    const target = this.container.querySelector('#' + elementId);

    target.addEventListener("mouseleave", this.onMouseOut);
    target.addEventListener("mouseover", this.onMouseOver);
    target.addEventListener("click", this.onClick);
    target.style.pointerEvents = "auto";
    target.style.cursor = "pointer";

    this.target = target;
  }
}

PointMarker.Events = Label3D.Events;
PointMarker.Icons = Icons;