import { logger } from "../logger/Logger";
import { ErrorCodes } from "./ErrorCodes";
import { blobToJson } from '../dt/encoding/utf8';
import { endpoint } from "./endpoints";
import { isNodeJS, getGlobal } from "../compat";

function nextPowerOfTwo(value) {
  value--;
  value |= value >> 1;
  value |= value >> 2;
  value |= value >> 4;
  value |= value >> 8;
  value |= value >> 16;
  value++;

  return value;
}

export let ViewingService = {};

//Maps a relative resource path (like a pack file or texture)
//to an absolute URL. If absoluteBasePath is specified, it is
//used to construct the absolute URL, otherwise the window location
//is used.
export function pathToURL(path, absoluteBasePath) {

  if (path.indexOf("://") !== -1 ||
  path.indexOf("urn:") === 0) {
    return path;
  }

  if (absoluteBasePath) {
    return absoluteBasePath + path;
  }

  if (typeof window === "undefined")
  return path;

  const _window = getGlobal();
  var rootRelPath = _window.location.pathname;
  //chop off the index.html part
  var lastSlash = rootRelPath.lastIndexOf("/");
  rootRelPath = rootRelPath.substr(0, lastSlash + 1);
  var absPath = _window.location.protocol + "//" + _window.location.host + rootRelPath + path;
  return absPath;
}

export function textToArrayBuffer(textBuffer, startOffset) {
  var len = textBuffer.length - startOffset;
  var arrayBuffer = new ArrayBuffer(len);
  var ui8a = new Uint8Array(arrayBuffer, 0);
  for (var i = 0, j = startOffset; i < len; i++, j++)
  ui8a[i] = textBuffer.charCodeAt(j) & 0xff;
  return ui8a;
}

/**
 * Construct full URL given a potentially partial viewing service "urn:" prefixed resource
 * @returns {string}
 */
ViewingService.generateUrl = function (baseUrl, api, path, apiData) {

  path = path || "";

  //NODE
  if (isNodeJS() && !isRemotePath(baseUrl, path)) {
    return path;
  }

  //Check if it's a viewing service item path
  //Public/static content will not have the urn: prefix.
  //So URL construction is a no-op
  if (!api || decodeURIComponent(path).indexOf('urn:') !== 0) {
    if (isRemotePath(null, path))
    return path;else

    return baseUrl + path;
  }

  return endpoint.getItemApi(baseUrl, path, apiData);
};

function isRemotePath(baseUrl, path) {
  if (path.indexOf("file://") !== -1)
  return false;
  if (path.indexOf("://") !== -1)
  return true;
  if (baseUrl)
  return true;
}


//Conditional GET request implementation for node vs. browser
if (isNodeJS()) {

  (function () {

    var fs = require('fs');
    var zlib = require('zlib');
    var https = require('https');
    var http = require('http');
    var urllib = require('url');

    let httpsAgent = new https.Agent({
      keepAlive: true,
      keepAliveMsecs: 100,
      maxSockets: 10
    });
    let httpAgent = new http.Agent({
      keepAlive: true,
      keepAliveMsecs: 100,
      maxSockets: 10
    });


    var forgeAgent = new https.Agent({ maxSockets: 10 });

    function loadLocalFile(url, onSuccess, onFailure, options) {

      if (url.indexOf("file://") === 0)
      url = url.substr(7);

      function postProcess(data) {
        if (options.responseType === "json") {
          try {
            return JSON.parse(data.toString("utf8"));
          } catch (e) {
            onFailure(e);
          }
        }
        return data;
      }

      //Always use async on Node
      fs.readFile(url, function (error, data) {
        if (error) {
          onFailure(0, 0, { httpStatusText: error, url: url });
        } else {
          if (data[0] === 31 && data[1] === 139) {
            zlib.gunzip(data, null, function (error, data) {
              if (error)
              onFailure(0, 0, { httpStatusText: error, url: url });else
              {
                data = postProcess(data);
                if (options.ondata)
                options.ondata(data);
                onSuccess(data);
              }
            });
          } else {
            data = postProcess(data);
            if (options.ondata)
            options.ondata(data);
            onSuccess(data);
          }
        }
      });
    }

    function needsGunzip(res, pathname) {

      if (res.headers['content-encoding'] === 'gzip')
      return true;

      //These SVF related files come pre-gzipped
      //regardless of content-encoding header

      if (pathname.endsWith(".json.gz"))
      return true;

      return false;
    }


    /**
     *  Performs a GET/HEAD request to Viewing Service. (Node.js specific implementation)
     *
     * @param {string} viewingServiceBaseUrl - The base url for the viewing service.
     * @param {string} api - The api to call in the viewing service.
     *  @param {string} url - The url for the request.
     *  @param {function} onSuccess - A function that takes a single parameter that represents the response
     *                                returned if the request is successful.
     *  @param {function} onFailure - A function that takes an integer status code, and a string status, which together represent
     *                                the response returned if the request is unsuccessful, and a third data argument, which
     *                                has more information about the failure.  The data is a dictionary that minimally includes
     *                                the url, and an exception if one was raised.
     *  @param {Object=} [options] - A dictionary of options that can include:
     *                               headers - A dictionary representing the additional headers to add.
     *                               queryParams - A string representing the query parameters
     *                               responseType - A string representing the response type for this request.
     *                               {boolean} [encodeUrn] - when true, encodes the document urn if found.
     *                               {boolean} [noBody] - when true, will perform a HEAD request
     */
    ViewingService.rawGet = function (viewingServiceBaseUrl, api, url, onSuccess, onFailure, options) {

      options = options || {};

      url = ViewingService.generateUrl(viewingServiceBaseUrl, api, url, undefined);

      if (!isRemotePath(viewingServiceBaseUrl, url)) {
        loadLocalFile(url, onSuccess, onFailure, options);
        return;
      }

      if (options.queryParams) {
        var concatSymbol = url.indexOf('?') === -1 ? '?' : '&';
        url = url + concatSymbol + options.queryParams;
      }

      var parsed = urllib.parse(url);

      var req = {
        host: parsed.hostname,
        port: parsed.port,
        method: options.method || "GET",
        path: parsed.path,
        headers: {},
        retryCount: 0,
        agent: parsed.protocol === "https:" ? httpsAgent : httpAgent
      };

      //Don't overload derivative service with requests
      if (req.host.endsWith(".api.autodesk.com") && (
      req.path.startsWith("/derivativeservice") || req.path.startsWith("/modelderivative"))) {
        req.agent = forgeAgent;
      }

      if (options.headers) {
        for (var p in options.headers) {
          req.headers[p] = options.headers[p];
        }
      }

      if (!req.headers['accept-encoding']) {
        req.headers['accept-encoding'] = 'gzip, deflate';
      }

      if (options.range) {
        req.headers["Range"] = "bytes=" + options.range.min + "-" + options.range.max;
      }

      //Undo hack used to make streaming receive work on browser XHR -- the hack
      //involves processing the response as text, so responseType is set to "".
      if (options.ondata || options.onprogress) {
        options.responseType = "arraybuffer";
      }

      var request = (parsed.protocol === "https:" ? https : http).request(req, function (res) {

        function extractResponseHeaders() {
          if (!options.responseHeaders) {
            return;
          }

          var headers = {};
          options.responseHeaders.forEach((header) => {
            var value = res.headers[header];
            if (value) {
              headers[header] = value;
            }
          });
          return headers;
        }

        //Pipe through gunzip if needed
        var stream = res;
        if (needsGunzip(res, parsed.pathname) && !options.skipDecompress) {
          stream = res.pipe(zlib.createGunzip());
        }

        //Decode as UTF8 string if needed
        if (options.responseType === "json" || options.responseType === "text" || !options.responseType)
        stream.setEncoding('utf8');

        var chunks = [];
        var receiveBuffer = Buffer.allocUnsafe(65536);
        var receivedLen = 0;
        stream.on('data', function (chunk) {

          //The onprogress callback is special in that it
          //want us to accumulate the data as we receive it, and it only looks at it.
          if (options.onprogress) {

            if (chunk.length + receivedLen > receiveBuffer.length) {
              var nb = Buffer.allocUnsafe(0 | Math.ceil(receiveBuffer.length * 1.5));
              receiveBuffer.copy(nb, 0, 0, receivedLen);
              receiveBuffer = nb;
            }

            chunk.copy(receiveBuffer, receivedLen, 0, chunk.length);
            receivedLen += chunk.length;
            let abort = options.onprogress(receiveBuffer, receivedLen);
            if (abort)
            request.abort();
            return;
          } else {
            chunks.push(chunk);
          }

          if (options.ondata) {
            options.ondata(chunk);
          }

        });

        stream.on('end', function () {

          if (res.statusCode >= 200 && res.statusCode < 400) {

            if (options.responseType === "json") {
              var jsobj = chunks.length === 0 ? null : JSON.parse(chunks.join(''));
              onSuccess(jsobj, extractResponseHeaders());
              return;
            }

            if (options.responseType === "text" || options.responseType === "") {
              var str = chunks.join('');
              onSuccess(str);
              return;
            }

            var buf = options.onprogress ? receiveBuffer : Buffer.concat(chunks);

            if (!options.skipDecompress && buf[0] === 31 && buf[1] === 139) {

              logger.warn("An LMV resource (" + url + ") was double compressed, or Content-Encoding header missing");

              try {
                buf = zlib.gunzipSync(buf);
                receivedLen = buf.length;
              } catch (err) {
                onFailure(ErrorCodes.BAD_DATA,
                "Malformed data received when requesting file",
                { "url": url, "exception": err.toString(), "stack": err.stack });
              }
            }

            if (request.status === 200 && options.range) {
              //If we requested a range, but the entire content was returned,
              //make sure to give back just the requested subset to the caller
              buf = new Uint8Array(buf, options.range.min, options.range.max - options.range.min);
            }

            onSuccess(buf, receivedLen);

          } else {

            if (onFailure) {
              if (options.responseType === "json") {
                var jsobj = {};
                try {
                  jsobj = chunks.length === 0 ? null : JSON.parse(chunks.join(""));
                } catch (err) {
                  console.warn("expected JSON but received something else", err);
                }
                onFailure(res.statusCode, res.statusMessage, { url: url }, { response: jsobj });
              } else {
                onFailure(res.statusCode, res.statusMessage, { url: url });
              }
            }

          }
        });

      });

      request.on("error", function (error) {
        if (onFailure)
        onFailure(error.code, error.message, { url: url });
      });

      if (options.postData) {
        request.write(options.postData);
      }

      request.end();

    };

  })();

} else {

  var Pend = require("pend");
  var xhrThrottle = new Pend();
  xhrThrottle.max = 100;

  ViewingService.rawGet = function (viewingServiceBaseUrl, api, url, onSuccess, onFailure, options) {

    xhrThrottle.go((pendCB) => {

      let getFunc = options?.useFetch ? ViewingService._rawGetFetch : ViewingService._rawGet;

      let onFailureWrapped = function () {for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {args[_key] = arguments[_key];}

        const errorCode = args[0];
        const method = (options?.method || (options?.noBody ? 'HEAD' : 'GET')).toLowerCase();
        let shouldRetry = method === 'get' && (errorCode === 429 || errorCode >= 500);

        // Don't retry if the request was cancelled explicitly.
        const data = args[2];
        if (data.aborted) {
          shouldRetry = false;
        }

        if (shouldRetry) {
          if (options.retryCount === undefined) {
            options.retryCount = 0;
          }
          if (options.maxRetries === undefined) {
            options.maxRetries = 3;
          }

          if (options.retryCount < options.maxRetries) {
            const request = args[3];
            let delayMs = 100;
            // 429 - too many requests, 503 - Service Unavailable until
            const retryAfter = (errorCode === 429 || errorCode === 503) && request?.getResponseHeader('Retry-After');
            if (retryAfter) {
              // retryAfter could be either delay-seconds or http-date
              const seconds = Number(retryAfter);
              delayMs = !isNaN(seconds) && seconds * 1000 || Date.parse(retryAfter) - new Date().getTime();
              delayMs = delayMs > 100 ? delayMs : 100;
            }
            options.retryCount++;
            delayMs *= options.retryCount; //not exponential, just linear, but we only retry twice
            setTimeout(() => {
              getFunc(viewingServiceBaseUrl, api, url, onSuccessWrapped, onFailureWrapped, options);
            }, delayMs);
          } else {
            pendCB();
            onFailure && onFailure.apply(onFailure, args);
          }
        } else {
          pendCB();
          onFailure && onFailure.apply(onFailure, args);
        }
      };

      let onSuccessWrapped = function () {
        pendCB();for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {args[_key2] = arguments[_key2];}
        onSuccess && onSuccess.apply(onSuccess, args);
      };

      getFunc(viewingServiceBaseUrl, api, url, onSuccessWrapped, onFailureWrapped, options);
    });
  };

  /**
   *  Performs a GET/HEAD request to Viewing Service.
   *
   * @param {string} viewingServiceBaseUrl - The base url for the viewing service.
   * @param {string} api - The api to call in the viewing service.
   *  @param {string} url - The url for the request.
   *  @param {function} onSuccess - A function that takes a single parameter that represents the response
   *                                returned if the request is successful.
   *  @param {function} onFailure - A function that takes an integer status code, and a string status, which together represent
   *                                the response returned if the request is unsuccessful, and a third data argument, which
   *                                has more information about the failure.  The data is a dictionary that minimally includes
   *                                the url, and an exception if one was raised.
   *  @param {Object=} [options] - A dictionary of options that can include:
   *                               headers - A dictionary representing the additional headers to add.
   *                               queryParams - A string representing the query parameters
   *                               responseType - A string representing the response type for this request.
   *                               {boolean} [encodeUrn] - when true, encodes the document urn if found.
   *                               {boolean} [noBody] - when true, will perform a HEAD request
   *                               {string[]} [responseHeaders] - array of response headers to be returned as 2nd parameter to onSuccess
   * @returns {Promise} that resolves with a simple success or fail of the request
   */
  ViewingService._rawGet = function (viewingServiceBaseUrl, api, url, onSuccess, onFailure, options) {

    options = options || {};

    url = ViewingService.generateUrl(viewingServiceBaseUrl, api, url, options.apiData);

    // If we are dealing with signed URL, adding additional query params will prevent it from working.
    if (options.queryParams) {
      var concatSymbol = url.indexOf('?') === -1 ? '?' : '&';
      url = url + concatSymbol + options.queryParams;
    }

    var request = new XMLHttpRequest();

    function onError(e) {
      if (onFailure)
      onFailure(request.status, request.statusText, { url: url }, request);
    }

    function onAbort(e) {
      if (onFailure)
      onFailure(request.status, 'request was aborted', { url: url, aborted: true }, request);
    }

    function fixJsonResponse(response) {
      if (options.responseType === "json") {
        try {
          if (response instanceof Uint8Array) {
            //This should only happen in the node.js case so we can do toString
            //instead of using the LMV utf8 converter.
            return blobToJson(response);
          } else if (typeof response === "string") {
            return JSON.parse(response);
          }
        } catch (e) {}
      }
      return response;
    }

    function extractResponseHeaders() {
      if (!options.responseHeaders) {
        return;
      }

      var headers = {};
      options.responseHeaders.forEach((header) => {
        var value = request.getResponseHeader(header);
        if (value) {
          headers[header] = value;
        }
      });
      return headers;
    }

    function onLoad(e) {
      if (request.status >= 200 && request.status < 400) {

        if (request.response &&
        request.response instanceof ArrayBuffer) {

          var rawbuf;
          if (request.status === 200 && options.range) {
            //If we requested a range, but the entire content was returned,
            //make sure to give back just the requested subset to the caller
            rawbuf = new Uint8Array(request.response, options.range.min, options.range.max - options.range.min);
          } else {
            rawbuf = new Uint8Array(request.response);
          }

          onSuccess && onSuccess(fixJsonResponse(rawbuf), extractResponseHeaders(), request.status);
        } else
        {
          var res = request.response;
          if (!res && (!options.responseType || options.responseType === "text"))
          res = request.responseText;

          onSuccess && onSuccess(fixJsonResponse(res), extractResponseHeaders(), request.status);
        }
      } else
      {
        onError(e);
      }
    }

    try {

      var isAsync = options.hasOwnProperty('asynchronous') ? options.asynchronous : true;
      request.open(options.method || (options.noBody ? 'HEAD' : 'GET'), url, isAsync);

      if (options.hasOwnProperty('responseType')) {
        request.responseType = options.responseType;
      }

      if (options.range) {
        request.setRequestHeader("Range", "bytes=" + options.range.min + "-" + options.range.max);
      }

      request.withCredentials = true;

      if (options.hasOwnProperty("withCredentials"))
      request.withCredentials = options.withCredentials;

      if (options.headers) {
        for (var header in options.headers) {
          request.setRequestHeader(header, options.headers[header]);

          // Disable withCredentials if header is Authorization type
          // NOTE: using withCredentials attaches cookie data to request
          if (header.toLocaleLowerCase() === "authorization") {
            request.withCredentials = false;
          }
        }
      }

      if (isAsync) {
        request.onload = onLoad;
        request.onerror = onError;
        request.ontimeout = onError;
        request.onabort = onAbort;

        if (options.ondata || options.onprogress) {

          //Set up incremental progress notification
          //if needed. We have to do some magic in order
          //to get the received data progressively.
          //https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest
          request.overrideMimeType('text/plain; charset=x-user-defined');
          options._dlProgress = {
            streamOffset: 0
          };

          request.onreadystatechange = function () {

            if (request.readyState > 2 && request.status === 200) {

              if (options.ondata) {

                var textBuffer = request.responseText;

                // No new data coming in.
                if (options._dlProgress.streamOffset >= textBuffer.length)
                return;

                var arrayBuffer = textToArrayBuffer(textBuffer, options._dlProgress.streamOffset);

                options._dlProgress.streamOffset = textBuffer.length;

                options.ondata(arrayBuffer);

              } else if (options.onprogress) {

                let abort = options.onprogress(request.responseText);
                if (abort)
                request.abort();
              }
            }
          };
        }
      }

      request.send(options.postData);

      if (!isAsync) {
        onLoad();
      }
    }
    catch (e) {
      onFailure(request.status, request.statusText, { url: url, exception: e }, request);
    }
  };

  // TODO: This method is not fully implemented yet, though (see todos in the code). At the moment, it's only fully
  // tested for the progressive loading of binary data, i.e. the case where options.onprogress is defined. All other
  // cases and response types are not supported yet.
  /**
   * A specific implementation of `rawGet` that uses fetch. See `rawGet` for most of the common parameters.
   * We only document implementation-specific behavior here.
   *
   * @param {Object} [options] - Details on the common options documented on `rawGet`:
   *  Progressive loading (onprogress / ondata) will always return binary data (array buffers), regardless of
   *  `responseType`.
   */
  ViewingService._rawGetFetch = async function (viewingServiceBaseUrl, api, url, onSuccess, onFailure, options) {

    options = options || {};

    url = ViewingService.generateUrl(viewingServiceBaseUrl, api, url, options.apiData);

    // If we are dealing with signed URL, adding additional query params will prevent it from working.
    if (options.queryParams) {
      let concatSymbol = url.indexOf('?') === -1 ? '?' : '&';
      url = url + concatSymbol + options.queryParams;
    }

    let request = new Request(url);
    let response;
    let abortController = new AbortController();

    /**
     * The fetch API is not fully compatible with the XMLHttpRequest API.
     * For _rawGet, the `onFailure` callback can receive the XMLHttpRequest's `request` object as the 4th parameter.
     *  There is no corresponding object for fetch that would provide a compatible API. A thorough search through the
     * code base suggests that this parameter is only used in the `rawGet` error handler to read the `retryAfter`
     * header, so we provide a proxy object that mimics this single API.
     * @param {Response} response The response of the fetch request.
     * @returns {Object} A proxy object that mimics the API of an XMLHttpRequest's 'request' instance, to the extent
     *  that we found to be used in the code.
     */
    function wrapResponse(response) {
      return {
        getResponseHeader: (header) => {return response?.headers?.get(header);}
      };
    }

    /**
     * This is called when the request failed with an HTTP error code (not for actual exceptions, e.g. network errors)
     * @param {Number} status The status code of the response.
     * @param {String} statusText The status text of the response.
     * @param {Response} response The response object of the fetch request.
     */
    function onError(status, statusText, response) {
      if (onFailure)
      onFailure(status, statusText, { url: url }, wrapResponse(response));
    }

    /**
     * This is called when the request is aborted by the code that initiated the request.
     * @param {Response} [response=undefined] The response of the fetch request. Can be undefined if the request is
     *  aborted before any response is received.
     */
    function onAbort() {let response = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
      if (onFailure)
      onFailure(0, 'request was aborted', { url: url, aborted: true }, wrapResponse(response));
    }

    /**
     * This is called when the request completed successfully.
     * @param {Response} response The response object of the fetch request.
     * @param {ArrayBuffer|undefined} data The response data. This will only be set if the response has already been
     *  processed progressively (as we cannot access the data via the response anymore in that case). Otherwise, the
     *  data needs to be accessed through the response.
     */
    async function onLoad(response) {let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;

      if (response.status >= 200 && response.status < 400) {

        let res;
        if (response.bodyUsed) {// the data has already been read progressively
          res = data;
        } else if (!options.responseType || options.responseType === "text") {
          res = await response.text();
        } else if (options.responseType === "json") {
          res = await response.json();
        } else {
          res = await response.arrayBuffer();

          if (request.status === 200 && options.range) {
            //If we requested a range, but the entire content was returned,
            //make sure to give back just the requested subset to the caller
            res = new Uint8Array(res, options.range.min, options.range.max - options.range.min);
          }
        }

        onSuccess && onSuccess(res);
      } else {
        let resText = await response.text();
        onError(response.status, resText, response);
      }
    }

    try {

      const requestOptions = {
        method: options.method || (options.noBody ? 'HEAD' : 'GET'),
        body: options.postData,
        headers: options.headers || {},
        signal: abortController.signal
      };

      if (options.range) {
        requestOptions.headers.Range = "bytes=" + options.range.min + "-" + options.range.max;
      }
      if (options.withCredentials) {
        requestOptions.credentials = 'include'; //NOTE: can't use same-origin when running on localhost, but our Cookie is marked SameSite anyway
      } else {
        requestOptions.credentials = 'omit';
      }

      if (options.headers) {
        for (var header in options.headers) {
          // Disable withCredentials if Authorization header is provided
          if (header.toLocaleLowerCase() === "authorization") {
            requestOptions.credentials = 'omit';
          }
        }
      }

      response = await fetch(request, requestOptions);

      if (!response.ok) {
        // We got a status code that indicates an error (400+)
        onError(response.status, response.statusText, response);
        return;
      }

      if (!options.ondata && !options.onprogress) {
        // requester expects one-time full response
        onLoad(response);
        return;
      }

      // requester expects progressive updates

      const reader = response.body.getReader();

      let aggregateBuffer;
      let currentView;
      if (options.onprogress) {
        const contentLength = parseInt(response.headers.get('content-length'));
        // Note that content-length will usually refer to the gzipped size, while this array is supposed
        // to store the uncompressed data. So we will have to resize it during the download.
        if (contentLength > 0) {
          aggregateBuffer = new Uint8Array(nextPowerOfTwo(contentLength));
        } else {
          aggregateBuffer = new Uint8Array(65536);
        }
      }

      let receivedLength = 0;

      for (;;) {

        let { done, value } = await reader.read();

        if (done) {
          if (options.onprogress) {
            // TODO: Is it better to copy instead of just creating a view, so that unused memory can be freed?
            aggregateBuffer = new Uint8Array(aggregateBuffer.buffer, 0, receivedLength);
            onLoad(response, aggregateBuffer);
          } else {
            onLoad(response);
          }
          return;
        }

        if (options.ondata) {
          options.ondata(value); // only send the incremental update
        } else {// onprogress
          // We need to keep track of the entire response.
          if (aggregateBuffer.length < value.length + receivedLength) {
            const newlen = Math.max(Math.ceil(aggregateBuffer.length * 3 / 2), value.length + receivedLength);
            var newBuffer = new Uint8Array(newlen);
            newBuffer.set(aggregateBuffer);
            aggregateBuffer = newBuffer;
          }
          aggregateBuffer.set(value, receivedLength);
          receivedLength += value.length;

          currentView = new Uint8Array(aggregateBuffer.buffer, 0, receivedLength);

          const abort = options.onprogress(currentView); // send everything that has been received so far

          if (abort) {
            reader.cancel();
            abortController.abort();
            onAbort(response);
            return;
          }
        }
      }
    } catch (e) {
      // We might not have a valid response object in this case.
      if (response) {
        onFailure(response.status, response.statusText, { url: url, exception: e }, wrapResponse(response));
      } else {
        onFailure(0, "network error", { url: url, exception: e });
      }
    }
  };

} //rawGet conditional implementation

// Create the default failure callback.
//
ViewingService.defaultFailureCallback = function (httpStatus, httpStatusText, data) {
  if (httpStatus == 403) {
    this.raiseError(
      ErrorCodes.NETWORK_ACCESS_DENIED,
      "Access denied to remote resource",
      { "url": data.url, "httpStatus": httpStatus, "httpStatusText": httpStatusText });
  } else
  if (httpStatus == 404) {
    this.raiseError(
      ErrorCodes.NETWORK_FILE_NOT_FOUND,
      "Remote resource not found",
      { "url": data.url, "httpStatus": httpStatus, "httpStatusText": httpStatusText });
  } else
  if (httpStatus === 0 && data.aborted) {
    this.raiseError(
      ErrorCodes.LOAD_CANCELED,
      "Request aborted",
      { "url": data.url, "httpStatus": httpStatus, "httpStatusText": httpStatusText });
  } else
  if (httpStatus >= 500 && httpStatus < 600) {
    this.raiseError(
      ErrorCodes.NETWORK_SERVER_ERROR,
      "Server error when accessing resource",
      { "url": data.url, "httpStatus": httpStatus, "httpStatusText": httpStatusText });
  } else
  if (data.exception) {
    this.raiseError(
      ErrorCodes.NETWORK_FAILURE,
      "Network failure",
      { "url": data.url, "exception": data.exception.toString(), "stack": data.exception.stack });
  } else
  {
    this.raiseError(
      ErrorCodes.NETWORK_UNHANDLED_RESPONSE_CODE,
      "Unhandled response code from server",
      { "url": data.url, "httpStatus": httpStatus, "httpStatusText": httpStatusText, data: data });
  }
};



function copyOptions(loadContext, options) {

  //Those are the usual defaults when called from the LMV worker

  if (!options.hasOwnProperty("responseType"))
  options.responseType = "arraybuffer";

  //Add options junk we got from the main thread context

  if (!options.hasOwnProperty("withCredentials"))
  options.withCredentials = !!loadContext.auth;

  options.headers = Object.assign(options.headers || {}, loadContext.headers);
  options.queryParams = loadContext.queryParams;
  options.endpoint = loadContext.endpoint;
}

//Utility function called from the web worker to set up the options for a get request,
//then calling ViewingService.get internally
ViewingService.getItem = function (loadContext, url, onSuccess, onFailure, options) {

  options = options || {};

  copyOptions(loadContext, options);

  //If the endpoint does not support range requests (Apigee), then convert
  //the range to start/end URL parameters.
  if (options.range && !loadContext.supportsRangeRequests) {

    let rangeParam = "start=" + options.range.min + "&end=" + options.range.max;
    if (options.queryParams) {
      options.queryParams += "&" + rangeParam;
    } else {
      options.queryParams = rangeParam;
    }

    options.range = undefined;
  }

  ViewingService.rawGet(loadContext.endpoint, 'items', url, onSuccess, onFailure, options);

};