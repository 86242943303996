import { DtConstants } from './schema/DtConstants';

export let PermissionChecker = function () {
};

PermissionChecker.prototype = {
  constructor: PermissionChecker,
  apply: function (object) {
    object.isOwner = PermissionChecker.prototype.isOwner;
    object.canManage = PermissionChecker.prototype.canManage;
    object.canEdit = PermissionChecker.prototype.canEdit;
    object.canRead = PermissionChecker.prototype.canRead;
    object.noAccess = PermissionChecker.prototype.noAccess;
  },
  isOwner: function () {
    return this._getAccessLevel() === DtConstants.DtAccessLevel.OWNER;
  },
  canManage: function () {
    return this._getAccessLevel() === DtConstants.DtAccessLevel.MANAGE || this.isOwner();
  },
  canEdit: function () {
    return this._getAccessLevel() === DtConstants.DtAccessLevel.READWRITE || this.canManage();
  },
  canRead: function () {
    return this._getAccessLevel() === DtConstants.DtAccessLevel.READ || this.canEdit();
  },
  noAccess: function () {
    return this._getAccessLevel() === DtConstants.DtAccessLevel.NONE;
  }
};