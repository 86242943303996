import * as THREE from "three";
import { GPUMemoryTracker } from "./GPUMemoryTracker";
import { getPolygonCount } from "./VertexEnumerator";

/**
 * Maintains a list of buffer geometries and running totals of their memory usage, etc.
 * Each geometry gets an integer ID to be used as reference in packed fragment lists.
 * @param {number} numObjects Number of objects (may be 0 if not known in advance).
 * @param {GPUMemoryTracker} memTracker the globally shared GPU memory tracker
 * @param {boolean} is2d True for 2D datasets.
 * @param {boolean} [disableStreaming] Set to true for small models to enforce full GPU upload (only if global memory tracker is not used).
 * @param {Boolean} isUnitBoxes indicates that every mesh has a unit bbox [-0.5, 0.5]
 * @constructor
 */
export function GeometryList(numObjects, memTracker, is2d, disableStreaming, isUnitBoxes) {
  // array of BufferGeometry instances. Indexed by svfid.
  this.geoms = [null]; //keep index 0 reserved for invalid id

  this.memTracker = memTracker || new GPUMemoryTracker(is2d, disableStreaming);

  this.is2d = is2d;

  if (!numObjects) {
    numObjects = 255;
  }

  // 6 floats per geometry
  this.geomBoxes = isUnitBoxes ? null : new Float32Array(Math.max(1, numObjects + 1) * 6);
  this.numObjects = numObjects;
}

GeometryList.prototype.getGeometry = function (svfid) {
  return this.geoms[svfid];
};

GeometryList.prototype._copyBoundingBox = function (svfid, geometry) {
  if (this.geomBoxes) {
    // resize this.geombboxes if necessary
    let fill = this.geomBoxes.length / 6 | 0;
    if (fill < this.geoms.length) {
      let end = this.geoms.length * 3 / 2 | 0;
      let nb = new Float32Array(6 * end);
      nb.set(this.geomBoxes);
      // Make all of the new bounds empty
      let empty = new THREE.Box3();
      empty.makeEmpty();
      while (fill < end) {
        nb[fill * 6] = empty.min.x;
        nb[fill * 6 + 1] = empty.min.y;
        nb[fill * 6 + 2] = empty.min.z;
        nb[fill * 6 + 3] = empty.max.x;
        nb[fill * 6 + 4] = empty.max.y;
        nb[fill++ * 6 + 5] = empty.max.z;
      }
      this.geomBoxes = nb;
    }

    // copy geometry bbox to this.geomBoxes
    let bb = geometry.boundingBox;
    if (!bb) {
      if (!geometry.hash) {
        console.error("Mesh without bbox and without hash should not be.");
      }
      this.geomBoxes[svfid * 6] = -0.5;
      this.geomBoxes[svfid * 6 + 1] = -0.5;
      this.geomBoxes[svfid * 6 + 2] = -0.5;
      this.geomBoxes[svfid * 6 + 3] = 0.5;
      this.geomBoxes[svfid * 6 + 4] = 0.5;
      this.geomBoxes[svfid * 6 + 5] = 0.5;
    } else {
      this.geomBoxes[svfid * 6] = bb.min.x;
      this.geomBoxes[svfid * 6 + 1] = bb.min.y;
      this.geomBoxes[svfid * 6 + 2] = bb.min.z;
      this.geomBoxes[svfid * 6 + 3] = bb.max.x;
      this.geomBoxes[svfid * 6 + 4] = bb.max.y;
      this.geomBoxes[svfid * 6 + 5] = bb.max.z;
    }
  }

  //Free the bbx objects if we don't want them.
  if (!this.is2d) {
    geometry.boundingBox = null;
    geometry.boundingSphere = null;
  }
};

/**
 * Adds a BufferGeometry object to this GeometryList while also update the 
 * BufferGeometry in the following ways:
 * 
 *  - Sets its 'streamingDraw' and 'streamingIndex' properties to determine if
 *    it should be stored in the system or GPU memory.
 *  - Sets its 'svfid' property so that each BufferGeometry knows its index in
 *    the internal array 'this.geoms'.
 *  - Deletes its bounding box and bounding sphere to conserve memory.
 * 
 * Note that this method is not meant to be called multiple times for the same 
 * svfid, as doing so would mess up the statistics.
 * 
 * @param {THREE.BufferGeometry|LmvBufferGeometry} geometry A mandatory parameter that must not
 * be null. The same BufferGeometry cannot be addd to more than one GeometryList.
 * @param {number} numInstances The number of fragments that made up the Mesh 
 * object that owns this BufferGeometry object. The default value is 1 if the 
 * parameter is not supplied.
 * @param {number} svfid The index of the BufferGeometry when it is stored in
 * the internal list 'this.geoms'. If this parameter is not defined, equals to 
 * zero, or is a negative number, the BufferGeometry is appended to the end of 
 * 'this.geoms' array.
 */
GeometryList.prototype.addGeometry = function (geometry, numInstances, svfid) {

  // track polygon count
  geometry.polyCount = getPolygonCount(geometry);


  this.memTracker.addGeometry(geometry, geometry.polyCount, numInstances);

  geometry.refcount++;

  // if no svfid is defined
  if (svfid === undefined || svfid <= 0)
  svfid = this.geoms.length;

  // store geometry (may increase array length)
  this.geoms[svfid] = geometry;

  this._copyBoundingBox(svfid, geometry);

  // Record the count that can be decrease properly when geometry removed.
  geometry.instanceCount = numInstances || 1;

  geometry.svfid = svfid;

  return svfid;
};

/**
 * Removes the geometry with svfid 'idx' from the list.
 * Note: Unlike addGeometry, this method only updates this.numGeomsInMemory. All other statistics keep the same.
 * @param {int} idx - Geometry ID.
 * @param {WebGLRenderer} renderer
 */
GeometryList.prototype.removeGeometry = function (idx, renderer) {
  // if there is no geom assigned, just return 0
  let geometry = this.getGeometry(idx);
  if (!geometry) {
    return 0;
  }

  geometry.refcount--;

  if (renderer && geometry.refcount === 0) {
    renderer.deallocateGeometry(geometry);
  }

  // remove geometry from the list
  this.geoms[idx] = null;

  this.memTracker.removeGeometry(geometry);
};


/**
 * Returns bounding box of a geometry.
 * @param {number} geomid - Geometry ID.
 * @param {THREE.Box3|LmvBox3} dst - Set to empty is there is no geometry of this id.
 */
GeometryList.prototype.getModelBox = function (geomid, dst) {

  //In case of OTG models, we do not store the geometry bounds, because
  //they are all unit boxes.
  if (!this.geomBoxes) {
    // Note: Since 0 is reserved as invalid geometry-index, the geometries start at 1
    //       and this.numObjects itself is still a valid index. Therefore <=.
    if (geomid >= 1 && geomid <= this.numObjects) {
      dst.min.x = -0.5;
      dst.min.y = -0.5;
      dst.min.z = -0.5;
      dst.max.x = 0.5;
      dst.max.y = 0.5;
      dst.max.z = 0.5;
    } else {
      dst.makeEmpty();
    }
    return;
  }

  // return empty box if geomid is out of bounds. If the id is in bounds
  // then the stored bbox is empty if the geometry hasn't been loaded 
  if (geomid === 0 || this.geomBoxes.length / 6 <= geomid) {
    dst.makeEmpty();
    return;
  }

  // extract bbox values from Float32Array this.geomboxes
  let off = geomid * 6;
  let bb = this.geomBoxes;
  dst.min.x = bb[off];
  dst.min.y = bb[off + 1];
  dst.min.z = bb[off + 2];
  dst.max.x = bb[off + 3];
  dst.max.y = bb[off + 4];
  dst.max.z = bb[off + 5];
};

/**
 * Tell renderer to release all GPU buffers.
 * @param {WebGLRenderer} renderer
 */
GeometryList.prototype.dispose = function (renderer) {
  if (!renderer)
  return;

  for (let i = 0, iEnd = this.geoms.length; i < iEnd; i++) {
    this.removeGeometry(i, renderer);
  }
};