import * as dte from "../../../DtEventTypes";
import { HUD_LAYER, HudLayer } from '../HudLayer';
import { DtConstants } from "../../../schema/DtConstants";
import { COLOR_SCHEME } from "../../../streams/StreamMarkers";

const LBL_OPTS = { noDefaultStyle: true, clickSelects: true, isWorldScaled: true };
const { StreamStatePriority, StreamStates } = DtConstants;

export class AssetsLayer extends HudLayer {
  assetById = new Map();
  labelById = new Map();

  constructor(facility, hud, viewer) {
    super(HUD_LAYER.ASSETS.id, facility, hud, viewer);

    this.boundOnStreamsInfoChanged = this.#onStreamsInfoChanged.bind(this);
  }

  dispose() {
    this.labelById.forEach((l) => l.dispose());
    this.labelById.clear();
    this.assetById.clear();
    this.facility.eventTarget.removeEventListener(dte.DT_STREAMS_INFO_CHANGED_EVENT, this.boundOnStreamsInfoChanged);
  }

  init() {
    this.facility.eventTarget.addEventListener(dte.DT_STREAMS_INFO_CHANGED_EVENT, this.boundOnStreamsInfoChanged);
    return this;
  }

  onCameraChanged() {this.invalidate();}

  onIsolationChanged() {this.invalidate();}

  onLayerVisibilityChanged(visible) {this.invalidate();}

  update(ts, ctx) {
    let hasChanged = false;
    const unseen = new Set(this.labelById.keys());

    const isLayerVisible = this.isVisible();
    for (const [assetKey, asset] of this.assetById) {
      unseen.delete(assetKey);

      if (!isLayerVisible || !isElementVisible(asset)) {
        hasChanged |= this.#updateLabel(assetKey, false);
        continue;
      }

      hasChanged |= this.#updateLabel(assetKey, true);
    }

    for (const assetKey of unseen) {
      const label = this.labelById.get(assetKey);
      hasChanged |= label.visible;
      label.setVisible(false);
    }

    ctx.layerChanged[HUD_LAYER.ASSETS] = hasChanged;
  }

  #onStreamsInfoChanged() {
    const streamMgr = this.facility.streamMgr;
    const streams = streamMgr.getAllStreamInfosFromCache();

    streamMgr.getLastReadings(streams.map((_ref) => {let { dbId } = _ref;return dbId;}), true).
    then((readings) => this.#updateWithLastReadings(streams, readings)).
    catch((err) => console.error(err));
  }

  #updateWithLastReadings(streams, lastReadings) {
    this.assetById.clear();
    for (let i = 0; i < lastReadings.length; i++) {
      const { hostElement } = streams[i];
      if (!hostElement) continue;

      const reading = lastReadings[i];
      if (!reading) continue;

      const state = getPriorityState(reading);
      if (StreamStatePriority[state] < StreamStatePriority[StreamStates.Alert]) continue;

      const { hostId: dbId, model } = hostElement;
      const assetKey = `${dbId}|${model.id}`;
      const asset = this.assetById.get(assetKey);
      if (asset) {
        asset.state = StreamStatePriority[state] > StreamStatePriority[asset.state] ? state : asset.state;
      } else {
        this.assetById.set(assetKey, { dbId, model, state });
      }
    }

    this.invalidate();
  }

  #updateLabel(assetKey, isVisible) {
    let label = this.labelById.get(assetKey);
    const asset = this.assetById.get(assetKey);

    if (!label) {
      if (!isVisible) {
        return false;
      }

      label = this.hud.addLabel(asset.model, asset.dbId, true, LBL_OPTS);
      label.setElement(asset);
      this.labelById.set(assetKey, label);
    }

    let hasChanged = false;
    hasChanged |= label.visible !== isVisible;
    label.setVisible(isVisible);

    let bgColor = asset.state === StreamStates.Offline ? COLOR_SCHEME.offline : COLOR_SCHEME.error;
    hasChanged |= label.backgroundColor !== bgColor;
    label.setBGColor(bgColor);
    return hasChanged;
  }
}

function isElementVisible(_ref2) {let { dbId, model } = _ref2;
  const it = model.getInstanceTree();
  return !it.isNodeHidden(dbId) && !it.isNodeOff(dbId);
}

function getPriorityState(reading) {
  if (reading.state === StreamStates.Offline) {
    return StreamStates.Offline;
  }

  let worstState = StreamStates.Normal;
  for (const attrID in reading) {
    const state = reading[attrID].state;
    if (StreamStatePriority[state] > StreamStatePriority[worstState]) {
      worstState = state;
    }
  }

  return worstState;
}