import * as et from "../application/EventTypes";
import { logger } from "../logger/Logger";
import * as THREE from "three";
import { flyToView } from "./viewtransitions/ViewTransition";
import { BoundsCallback, VertexBufferReader } from "../wgs/scene/VertexBufferReader";


/**
* Used internally only by Viewer3DImpl::get3DSelectionBounds() -- [TS] Should be, but that ship has apparently sailed.
* Support multiple 3D models.
* @private
*/
export function get3DSelectionBounds(viewer, aggregateSelection) {

  // First, check if there's anything selected.
  let bNodeSelection = false;
  for (let j = 0; j < aggregateSelection.length; ++j) {
    if (aggregateSelection[j].selection.length > 0) {
      bNodeSelection = true;
      break;
    }
  }

  let bounds = new THREE.Box3();
  let box = new THREE.Box3();

  if (!bNodeSelection) {
    // When there is no node selection, then we need to fit to the whole model(s)
    bounds.union(viewer.impl.getVisibleBounds(false, false));
  } else {

    // Fit to selected elements only
    for (let i = 0; i < aggregateSelection.length; ++i) {

      let selection = aggregateSelection[i].selection;
      if (selection.length === 0)
      continue;

      // Specific nodes
      let model = aggregateSelection[i].model;
      let instanceTree = model.getInstanceTree();
      let fragList = model.getFragmentList();

      // instanceTree may be null, e.g., if instanceTree is not loaded yet
      if (!instanceTree) {
        continue;
      }

      for (let s = 0; s < selection.length; ++s) {
        let dbId = parseInt(selection[s]);
        instanceTree.enumNodeFragments(dbId, function (fragId) {
          fragList.getWorldBounds(fragId, box);
          bounds.union(box);
        }, true);
      }
    }

  }

  return bounds;
}


/**
 * Used internally only by Viewer3DImpl::fitToView()
 * Support multiple 3D models.
 * @private
 */
function _fitToView3d(viewer, aggregateSelection, immediate) {

  const bounds = get3DSelectionBounds(viewer, aggregateSelection);

  if (!bounds.isEmpty()) {
    viewer.navigation.fitBounds(immediate, bounds);
    return true;
  }

  // Unhandled 3D
  return false;
}

/**
 * Supports Fit-To-View for 2D models
 * @private
 */
function find2DLayerBounds(model, fragId, visibleLayerIds, bc) {
  let mesh = model.getFragmentList().getVizmesh(fragId);
  let vbr = new VertexBufferReader(mesh.geometry);
  vbr.enumGeomsForVisibleLayer(visibleLayerIds, bc);
}

/**
 * Supports Fit-To-View for 2D models
 * @private
 */
function find2DBounds(model, fragId, dbId, bc) {
  let mesh = model.getFragmentList().getVizmesh(fragId);
  let vbr = new VertexBufferReader(mesh.geometry);
  vbr.enumGeomsForObject(dbId, bc);
}


/**
 * Compute the 2d bounds of selected object.
 * @public
 * @param {Array} selection array of object's ids
 * @param {RenderModel} model
 * @param {BoundsCallback} bc
 */
function computeSelectionBounds(dbIds, model, bc) {

  if (!bc) {
    bc = new BoundsCallback(new THREE.Box3());
  }

  let dbId2fragId = model.getData().fragments?.dbId2fragId;

  for (let i = 0; i < dbIds.length; i++) {
    let remappedId = model.reverseMapDbIdFor2D(dbIds[i]);
    let fragIds = dbId2fragId[remappedId];
    // fragId is either a single vertex buffer or an array of vertex buffers
    if (Array.isArray(fragIds)) {
      for (let j = 0; j < fragIds.length; j++) {
        // go through each vertex buffer, looking for the object id
        find2DBounds(model, fragIds[j], remappedId, bc);
      }
    } else if (typeof fragIds === 'number') {
      // go through the specific vertex buffer, looking for the object id
      find2DBounds(model, fragIds, remappedId, bc);
    }
  }

  // Apply model transform to the bounds, since in find2DBounds the transform is not being taken into account.
  bc.bounds.applyMatrix4(model.getPlacementTransform());

  return bc.bounds;
}

function _fitToView2d(viewer, aggregateSelection, immediate) {

  if (aggregateSelection.length > 1) {
    logger.warn('fitToView() doesn\'t support multiple 2D models. Using the first one...');
  }

  // Selection
  let model = aggregateSelection[0].model;
  let selection = aggregateSelection[0].selection;

  // Helpers
  let bounds = new THREE.Box3();
  let bc = new BoundsCallback(bounds);

  if (!selection || selection.length === 0) {
    if (viewer.anyLayerHidden()) {

      // Fit only to the visible layers
      let frags = model.getData().fragments;
      let visibleLayerIndices = viewer.impl.getVisibleLayerIndices();
      for (let i = 0; i < frags.length; i++) {
        find2DLayerBounds(model, i, visibleLayerIndices, bc);
      }

    } else {
      // Fit to the whole page
      bounds = viewer.impl.getVisibleBounds();
    }
  } else
  {
    computeSelectionBounds(selection, model, bc);
  }


  if (!bounds.isEmpty()) {
    viewer.navigation.fitBounds(immediate, bounds);
    return true;
  }

  // Unhandled 2D
  return false;
}



export function fitToView(viewer, aggregateSelection, immediate) {

  immediate = !!immediate;
  if (aggregateSelection.length === 0) {
    // If the array is empty, assume that we want
    // all models and no selection
    let allModels = viewer.impl.modelQueue().getModels();
    aggregateSelection = allModels.map(function (model) {
      return {
        model: model,
        selection: []
      };
    });
  }

  if (aggregateSelection.length === 0) {
    return false;
  }

  // Early exit if parameters are not right
  let count2d = 0;
  for (let i = 0; i < aggregateSelection.length; ++i) {

    let model = aggregateSelection[i].model;
    if (!model)
    return false;

    if (model.is2d()) {
      count2d++;
    }
  }

  // Start processing.
  let processed = false;
  if (count2d === aggregateSelection.length) {
    // Aggregate selection on 2d models.
    processed = _fitToView2d(viewer, aggregateSelection, immediate);
  } else {
    // Aggregate selection on 3d models or 2d/3d hybrid.
    processed = _fitToView3d(viewer, aggregateSelection, immediate);
  }

  if (!processed)
  return false;

  if (viewer.impl.modelQueue().getModels().length === 1) {
    // Single Model (backwards compatibility)
    viewer.dispatchEvent({
      type: et.FIT_TO_VIEW_EVENT,
      nodeIdArray: aggregateSelection[0].selection,
      immediate: immediate,
      model: aggregateSelection[0].model
    });
  }

  // Dispatches in both single and multi-model context
  viewer.dispatchEvent({
    type: et.AGGREGATE_FIT_TO_VIEW_EVENT,
    selection: aggregateSelection,
    immediate: immediate
  });

  return true;
}

const position = new THREE.Vector3();
const target = new THREE.Vector3();
const up = new THREE.Vector3();
/** Flies to the top of the given box in the facility space, with up facing back of view cube. */
export function flyToTopOfBox(facility, box, durationSec) {
  const xform = facility.getPrimaryModel().getData()?.placementWithOffset;

  // Set the view target as the center of the box.
  box.getCenter(target);

  // Move the camera away from the target, by a heuristic of the box max dimension.
  position.copy(target);
  const size = box.size();
  const dist = Math.max(size.x, size.y, size.z);
  position.z += dist;

  // z-toward "back" of view cube, transform in model space.
  up.set(0, 1, 0);
  if (xform) {
    up.transformDirection(xform);
  }

  return new Promise((resolve) => flyToView(facility.viewer, { position, target, up }, durationSec, resolve, false));
}