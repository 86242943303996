import THREE from "three";
const RC = require("../resources/cats_enum.json");

//TODO: Get Level heights from AecModelData hints. That will require some work in the DtLoader.
//Until then we compute a fuzzy box based on some reasonable categories.
const BOX_INCLUDE_CATEGORIES = new Set([
RC.Floors,
RC.Roofs,
RC.Ceilings,
RC.Doors,
RC.Walls,
RC.CurtainWallPanels,
RC.CurtainWallMullions]
);

let dummyMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });

/**
 * @param {MergedFacetNode} item
 */
export function createFloorFootprint(facility, item, cached) {

  const ext = facility.viewer.getExtension('Autodesk.CompGeom');
  if (!ext) {
    // Nothing to show for the moment.
    cached.isComplete = false;
    return null;
  }

  const cg = Autodesk.Viewing.Extensions.CompGeom;

  //Used when scaling coordinates to integer space for use by ClipperLib
  const SCALE = 128;

  let elementBox = new THREE.Box3();
  let tmpBox = new THREE.Box3();
  let tmpVec = new THREE.Vector3();

  const allFloors = {};
  const fragIds = {};
  let floorSizeSum = 0;

  for (let urn in item.idsSets) {
    const model = facility.getModelByUrn(urn);
    const modelData = model.getData();
    if (!modelData) {
      cached.isComplete = false;
      return null;
    }

    const fl = model.getFragmentList();
    const it = model.getInstanceTree();
    const { dbId2catId, dbId2topLevel } = modelData;

    const set = item.idsSets[urn];
    fragIds[urn] = new Set();

    //Collect all the floor elements first -- there can sometimes be many thousands
    //of irrelevant "floors" that are really carpets etc, and we need to ignore these
    //for the sake of efficiency
    allFloors[urn] = [];

    for (let dbId of set) {

      //Do not include elements that have a top constraint -- those will mess up the height
      if (dbId2topLevel[dbId]) {
        continue;
      }

      let catId = dbId2catId[dbId];

      elementBox.makeEmpty();

      if (BOX_INCLUDE_CATEGORIES.has(catId)) {
        it.enumNodeFragments(dbId, (fid) => {

          fragIds[urn].add(fid);

          if (catId !== RC.CurtainWallPanels && catId !== RC.CurtainWallMullions) {
            fl.getWorldBounds(fid, tmpBox);
            elementBox.union(tmpBox);
          }

          // Check if this pass' result is final (all floor geometries are loaded)
          const isLoaded = !!fl.getGeometry(fid);
          if (cached.isComplete && !isLoaded) {
            cached.isComplete = false;
          }
        }, true);
      }

      if (!elementBox.isEmpty()) {
        const floorSize = elementBox.size(tmpVec).length();
        allFloors[urn].push({ dbId, size: floorSize });
        floorSizeSum += floorSize;
      }
    }
  }

  if (floorSizeSum === 0) {
    // Nothing to show, but do another pass in case new geoms are loaded
    // The expensive part is after this anyway
    cached.isComplete = false;
    return null;
  }

  /* Expensive part: generate a floor highlight mesh. */
  let footprint = new cg.MeshFootprint(SCALE);
  let totalBox = new THREE.Box3();

  for (let urn in item.idsSets) {
    const model = facility.getModelByUrn(urn);
    const fl = model.getFragmentList();
    const it = model.getInstanceTree();

    //Only process up to a reasonable number of floor elements so that we
    //don't block the application for too long.
    allFloors[urn].sort((a, b) => b.size - a.size);
    let numFloors = Math.min(100, allFloors[urn].length);
    for (let i = 0; i < numFloors; i++) {
      let floor = allFloors[urn][i];

      it.enumNodeFragments(floor.dbId, (fid) => {
        let mesh = fl.getVizmesh(fid);
        footprint.addMesh(mesh, true);
      }, true);
    }

    let fbox = model.getFuzzyBox({ allowlist: fragIds[urn], quantil: 0.75 });
    totalBox.union(fbox);
  }

  let complexPolygon = footprint.toComplexPolygon(1.0);

  if (!complexPolygon) {
    return null;
  }

  complexPolygon.triangulate();

  // extrude for given thickness
  const geom = complexPolygon.toExtrudedMesh(totalBox.max.z - totalBox.min.z);
  if (geom) {
    const mesh = new THREE.Mesh(geom, dummyMaterial);
    mesh.matrix.elements[14] = totalBox.max.z; //Raise the extruded mesh to top out at the correct max elevation
    mesh.matrixAutoUpdate = false;
    cached.mesh = mesh;
  }

  return null;
}


const StairRailings = [RC.Railings, RC.RailingHandRail, RC.RailingHandRailAboveCut, RC.RailingTopRailAboveCut,
RC.StairsRailing, RC.StairsRailingBaluster, RC.StairsRailingRail];

/**
 * @param {DtFacility} facility
 * @param {MergedFacetNode} item
 */
export function getFloorBoxFuzzy(facility, item, quantile, excludeGhosted) {

  let totalBox = new THREE.Box3();

  for (let urn in item.idsSets) {
    const model = facility.getModelByUrn(urn);
    const fl = model.getFragmentList();
    const it = model.getInstanceTree();
    const dbId2topLevel = model.getData().dbId2topLevel;
    const dbId2catId = model.getData().dbId2catId;

    const set = item.idsSets[urn];
    let setForBox = new Set();

    for (let dbId of set) {

      //Do not include elements that have a top constraint -- those will mess up the height
      if (dbId2topLevel[dbId]) {
        continue;
      }

      //exclude stairs, because they often cross floors in a way that screws up the bounds
      if (StairRailings.includes(dbId2catId[dbId])) {
        continue;
      }

      it.enumNodeFragments(dbId, (fid) => {

        if (excludeGhosted && !fl.isFragVisible(fid)) {
          return;
        }

        setForBox.add(fid);
      }, false);
    }

    if (setForBox.size === 0) {
      continue;
    }

    let fbox = model.getFuzzyBox({ allowlist: setForBox, quantil: quantile || 0.90, useOriginalBounds: true });
    totalBox.union(fbox);
  }

  if (totalBox.isEmpty()) {
    return null;
  }

  return totalBox;
}