// Collection of basic edit actions that support undo/redo.
//
// Each action provides undo() and redo() functions. UndoStack takes care that actions are always called in consistent order.
// I.e., an individual action can assume that undo/redo is only called if the state allows it. (e.g. target shape exists and has expected number of vertices etc.)

import { EdgeType, EllipseArcParams, PolyBase, PolyIndex } from './EditShapes.js';
import { BooleanOps } from './BooleanOps.js';

class Action {
  constructor(layer) {
    this.layer = layer;
  }

  undo() {
    throw new Error('Abstract method invoked');
  }

  redo() {
    throw new Error('Abstract method invoked');
  }

  // Optional: Can be be implemented to guide what should be selected before/after change.
  // @param {bool} afterUndo - If true, return recommended selection state after undo, otherwise after redo.
  // @returns {SelectionHint|null}
  getSelectionHint(afterUndo) {
    return null;
  }
}

// Struct for possible return values of getSelectionHint
class SelectioHint {
  constructor() {
    // {Shape}
    this.shape = null;

    // {Shape[]} - only needed for multiselect operations
    this.shapes = null;

    // {PolyIndex} - selected vertex
    this.vertex = null;

    // {PolyIndex} - selected edge
    this.edge = null;
  }
}

class AddShape extends Action {
  constructor(layer, shape) {
    super(layer);
    this.shape = shape;
  }

  undo() {
    this.layer.removeShape(this.shape);
  }

  redo() {
    this.layer.addShape(this.shape);
  }

  // After redo, new shape should be selected
  getSelectionHint(afterUndo) {
    return afterUndo ? null : { shape: this.shape };
  }
}

class AddShapes extends Action {

  // @param {Shape[]} shapes
  constructor(layer, shapes) {
    super(layer);
    this.shapes = shapes;
  }

  undo() {
    this.shapes.forEach((s) => this.layer.removeShape(s));
  }

  redo() {
    this.shapes.forEach((s) => this.layer.addShape(s));
  }

  // After redo, new shape should be selected
  getSelectionHint(afterUndo) {
    return afterUndo ? null : { shapes: this.shapes };
  }
}

class MoveShapes extends Action {
  constructor(layer, shapes, dx, dy) {
    super(layer);
    this.shapes = shapes;
    this.delta = { x: dx, y: dy };

    // Note that transforming back and forth is not always exactly 1:1.
    this.beforeState = this.shapes.map((s) => s.clone());
    this.afterState = this.shapes.map((s) => s.clone());

    this.afterState.forEach((s) => s.move(dx, dy));
  }

  undo() {
    this.shapes.forEach((s, i) => s.copy(this.beforeState[i]));
  }

  redo() {
    this.shapes.forEach((s, i) => s.copy(this.afterState[i]));
  }

  // After undo/redo, moved shapes should be selected
  getSelectionHint(afterUndo) {
    return { shapes: this.shapes };
  }
}

class RemoveShape extends Action {
  constructor(layer, shape) {
    super(layer);
    this.shape = shape;
  }

  undo() {
    this.layer.addShape(this.shape);
  }

  redo() {
    this.layer.removeShape(this.shape);
  }

  // After undo, recovered shape should be selected
  getSelectionHint(afterUndo) {
    return afterUndo ? { shape: this.shape } : null;
  }
}

class RemoveShapes extends Action {
  constructor(layer, shapes) {
    super(layer);
    this.shapes = shapes.slice();
  }

  undo() {
    this.shapes.forEach((s) => this.layer.addShape(s));
  }

  redo() {
    this.shapes.forEach((s) => this.layer.removeShape(s));
  }

  // After undo, recovered shapes should be selected
  getSelectionHint(afterUndo) {
    return afterUndo ? { shapes: this.shapes } : null;
  }
}

class AddVertex extends Action {
  constructor(layer, poly, polyIndex, p) {
    super(layer);
    this.poly = poly;
    this.polyIndex = polyIndex;
    this.point = p.clone();
  }

  undo() {
    this.poly.removePoint(this.polyIndex.vertex, this.polyIndex.loop);
  }

  redo() {
    this.poly.insertPoint(this.polyIndex.vertex, this.point, this.polyIndex.loop);
  }

  // After redo, select the new vertex
  getSelectionHint(afterUndo) {
    return {
      shape: this.poly,
      vertex: afterUndo ? null : this.polyIndex
    };
  }
}

// Only for polygons and polylines
class MoveVertex extends Action {
  constructor(layer, poly, polyIndex, newPos) {
    super(layer);
    this.poly = poly;
    this.polyIndex = polyIndex;
    this.posBefore = poly.getPoint(polyIndex.vertex, polyIndex.loop);
    this.posAfter = newPos.clone();
  }

  undo() {
    this.poly.updatePoint(this.polyIndex.vertex, this.posBefore.x, this.posBefore.y, this.polyIndex.loop);
  }
  redo() {
    this.poly.updatePoint(this.polyIndex.vertex, this.posAfter.x, this.posAfter.y, this.polyIndex.loop);
  }

  // After undo/redo, select moved vertex
  getSelectionHint(afterUndo) {
    return {
      shape: this.poly,
      vertex: this.polyIndex
    };
  }
}

// Returns an object that allows for recovering all arc params assoicated with a vertex
const copyArcParams = (poly, index, loop) => {
  if (!poly.isPath() || !poly.edgeIndexValid(index, loop)) {
    return null;
  }

  const type = poly.getEdgeType(index, loop);
  switch (type) {
    // Note that the getter already returns a copy
    case EdgeType.Ellipse:return poly.getEllipseArcParams(index, loop);
    case EdgeType.Bezier:return {
        cp1: poly.getControlPoint(index, 1, loop),
        cp2: poly.getControlPoint(index, 2, loop)
      };
    case EdgeType.Line:return null;
    default:console.warn('unexpected edge type');
  }
};

// Recover an arc based on the result returned by copyArcParam
const restoreArc = (poly, index, loop, arcParams) => {
  if (!arcParams) {
    // nothing to restore
    return;
  }

  if (arcParams instanceof EllipseArcParams) {
    // recover ellipse arc
    poly.setEllipseArc(index, arcParams);
  } else if (arcParams.cp1) {
    // recover bezier arc
    const cp1 = arcParams.cp1;
    const cp2 = arcParams.cp2;
    poly.setBezierArc(index, cp1.x, cp1.y, cp2.x, cp2.y);
  }
};

class RemoveVertex extends Action {
  constructor(layer, poly, polyIndex) {
    super(layer);
    this.poly = poly;
    this.vertex = polyIndex.vertex;
    this.loop = polyIndex.loop;
    this.point = poly.getPoint(this.vertex, this.loop);

    // For consistency and simplicity, we remove arcs if start or end vertex is removed.
    this.arcBefore = copyArcParams(poly, poly.edgeBeforeVertex(this.vertex, this.loop), this.loop);
    this.arcAfter = copyArcParams(poly, poly.edgeAfterVertex(this.vertex, this.loop), this.loop);
  }

  undo() {
    this.poly.insertPoint(this.vertex, this.point, this.loop);

    // recover arc params
    if (this.poly.prevEdgeExists(this.vertex, this.loop)) {
      restoreArc(this.poly, this.poly.edgeBeforeVertex(this.vertex, this.loop), this.loop, this.arcBefore);
    }
    if (this.poly.nextEdgeExists(this.vertex, this.loop)) {
      restoreArc(this.poly, this.poly.edgeAfterVertex(this.vertex, this.loop), this.loop, this.arcAfter);
    }
  }

  redo() {
    // remove arcs at edges starting/ending at the vertex
    if (this.poly.isPath()) {
      if (this.poly.prevEdgeExists(this.vertex, this.loop)) {
        this.poly.removeArc(this.poly.edgeBeforeVertex(this.vertex, this.loop), this.loop);
      }
      if (this.poly.nextEdgeExists(this.vertex, this.loop)) {
        this.poly.removeArc(this.poly.edgeAfterVertex(this.vertex, this.loop), this.loop);
      }
    }

    this.poly.removePoint(this.vertex, this.loop);
  }

  // After undo, select recovered vertex
  getSelectionHint(afterUndo) {
    return {
      shape: this.poly,
      vertex: afterUndo ? this.polyIndex : null
    };
  }
}


// Moves an edge to a new position specified by new positions for start and end vertex.
// Optionally, start and end vertex may be duplicated before moving the edge. In this case, the neighbar edges keep unchanged and
// we introduce new intermediate edges to connect the old start/end position with the new one.
class MoveEdge extends Action {

  // @param {EditLayer} layer
  // @param {Polybase}  poly      - must be at start before the change
  // @param {PolyIndex} polyIndex - edge to be modified
  // @param {Vector3}   newPosA, newPosB
  // @param {bool}      duplicateStartVertex, duplicateEndVertex - Optional, start and end vertex of the edge may be duplicated.
  constructor(layer, poly, polyIndex, newPosA, newPosB, duplicateStartVertex, duplicateEndVertex) {
    super(layer);
    this.poly = poly;

    // store edge index
    this.edgeIndex = polyIndex.vertex;
    this.loopIndex = polyIndex.loop;

    // store duplicate flags
    this.duplicateStartVertex = duplicateStartVertex;
    this.duplicateEndVertex = duplicateEndVertex;

    const ia = this.edgeIndex;
    const ib = poly.nextIndex(ia, this.loopIndex);

    // get edge
    const a = poly.getPoint(ia, this.loopIndex);
    const b = poly.getPoint(ib, this.loopIndex);

    this.edgeBefore = {
      a: a,
      b: b
    };
    this.edgeAfter = {
      a: newPosA.clone(),
      b: newPosB.clone()
    };
  }

  undo() {
    // get current edgeIndex (after duplicating vertices)
    const newEdgeIndex = MoveEdge.getNewEdgeIndex(this.poly, this.edgeIndex, this.loopIndex, this.duplicateStartVertex, this.duplicateEndVertex);

    // get indices of the two edge vertices
    const ia = newEdgeIndex;
    const ib = this.poly.nextIndex(ia, this.loopIndex);

    // Restore original edge positions
    this.poly.updatePoint(ia, this.edgeBefore.a.x, this.edgeBefore.a.y, this.loopIndex);
    this.poly.updatePoint(ib, this.edgeBefore.b.x, this.edgeBefore.b.y, this.loopIndex);

    // Remove extra vertices
    MoveEdge.revertDuplicateVertices(this.poly, this.edgeIndex, this.loopIndex, this.duplicateStartVertex, this.duplicateEndVertex);
  }

  redo() {
    // Duplicate start/end vertex if wanted
    MoveEdge.duplicateVertices(this.poly, this.edgeIndex, this.loopIndex, this.duplicateStartVertex, this.duplicateEndVertex);

    // get edgeIndex after duplicating vertices
    const newEdgeIndex = MoveEdge.getNewEdgeIndex(this.poly, this.edgeIndex, this.loopIndex, this.duplicateStartVertex, this.duplicateEndVertex);

    // get indices of the two edge vertices
    const ia = newEdgeIndex;
    const ib = this.poly.nextIndex(ia, this.loopIndex);

    // apply new positions
    this.poly.updatePoint(ia, this.edgeAfter.a.x, this.edgeAfter.a.y, this.loopIndex);
    this.poly.updatePoint(ib, this.edgeAfter.b.x, this.edgeAfter.b.y, this.loopIndex);
  }

  // Insert a duplicate of vertex i to position i+1. Note that vertex i only copies
  // the position. If vertex i contained arc params, these will be at vertex i+1.
  static duplicateVertex(poly, index) {let loopIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    const p = poly.getPoint(index, loopIndex);
    poly.insertPoint(index, { x: p.x, y: p.y }, loopIndex);
  }

  // Duplicates start and/or end vertex of a given edge in a polyline/polygon.
  static duplicateVertices(poly, edgeIndex, loopIndex, duplicateStartVertex, duplicateEndVertex) {

    let startVertex = edgeIndex;

    if (duplicateStartVertex) {
      MoveEdge.duplicateVertex(poly, startVertex, loopIndex);

      // After duplicating, the actual edge start vertex has shifted by 1.
      startVertex++;
    }

    if (duplicateEndVertex) {
      const vNext = poly.nextIndex(startVertex, loopIndex);
      MoveEdge.duplicateVertex(poly, vNext, loopIndex);
    }
  }

  // Reverts the extra vertices inserted by duplicateVertices. Note that edgeIndex refers
  // to the polygon before duplicating the vertices, i.e., should be identical with
  // the one used in the duplicateVertices(..) to be reverted.
  static revertDuplicateVertices(poly, edgeIndex, loopIndex, duplicateStartVertex, duplicateEndVertex) {

    // get edge index after considering vertex duplication
    let curEdgeIndex = MoveEdge.getNewEdgeIndex(poly, edgeIndex, loopIndex, duplicateStartVertex, duplicateEndVertex);

    // If the end vertex was duplicated, revert that now
    if (duplicateStartVertex) {

      // Note that it is important to remove the vertex BEFORE edge start instead of the edge start itself.
      // Although both have identical positions, the edge start vertex may contain additional arc params.
      let iPrev = curEdgeIndex - 1;
      poly.removePoint(iPrev, loopIndex);

      // This shifts the edgeIndex back by 1
      curEdgeIndex--;
    }

    if (duplicateEndVertex) {
      // Always remove the first of the two duplicate vertices. The first one is the copy that just contains
      // the position, while the second (=original) one may contain additional arc params.
      let iNext = poly.nextIndex(curEdgeIndex, loopIndex);
      poly.removePoint(iNext, loopIndex);
    }
  }

  // If we duplicate start/end vertex of an edge, the index of that edge may change.
  // This function returns the new index of the edge after duplicating start/end vertex.
  //
  // Note: poly is assumed to contain the duplicated vertices.
  static getNewEdgeIndex(poly, edgeIndex, loopIndex, duplicateStartVertex, duplicateEndVertex) {

    let newIndex = edgeIndex;

    // Duplicating the start vertex always shift the edgeIndex by 1
    if (duplicateStartVertex) {
      newIndex++;
    }

    // get vertexCount of the polygon before insertion of duplicated vertices.
    const vertexCountBefore = poly.getVertexCount(loopIndex) - (duplicateStartVertex ? 1 : 0) - (duplicateEndVertex ? 1 : 0);

    // Check if edge was the 'closing edge' of the original polygon, i.e., the edge that
    // connects the last vertex with vertex 0
    const isClosingEdge = edgeIndex === vertexCountBefore - 1;

    // Duplicating the end vertex may also shift the edgeIndex. This happens if the edge start
    // vertex is the last one in a polygon.
    if (duplicateEndVertex && isClosingEdge) {
      newIndex++;
    }
    return newIndex;
  }

  // After undo/redo, select the moved edge. Note that its index may change due to vertex duplication.
  getSelectionHint(afterUndo) {
    const edgeIndex = afterUndo ? this.edgeIndex : MoveEdge.getNewEdgeIndex(this.poly, this.edgeIndex, this.loopIndex, this.duplicateStartVertex, this.duplicateEndVertex);
    return {
      shape: this.poly,
      edge: new PolyIndex({ vertex: edgeIndex, loop: this.loopIndex })
    };
  }
}

// Change arc type of a Path edge. It can also be used to change arc params without changing the actual type.
class ChangeEdgeType extends Action {

  // @param {EditLayer} layer
  // @param {Path}      path  - must be in "before" state of the action
  // @param {PolyIndex} polyIndex
  // @param {EdgeType}  edgeType
  // @param {Vector2}   [cp1] - Control points. Only needed when changing to Bezier
  // @param {Vector2}   [cp2]
  // @param {EllipseArcParams} [arcParams] - only needed when changing to EllipseArc
  constructor(layer, path, polyIndex, edgeType, cp1, cp2, arcParams) {
    super(layer);

    this.path = path;
    this.edgeIndex = polyIndex.vertex;
    this.loopIndex = polyIndex.loop;

    // Store previous edge props
    const isBezier = path.isBezierArc(this.edgeIndex, this.loopIndex);
    this.oldEdgeType = path.getEdgeType(this.edgeIndex, this.loopIndex);
    this.oldCp1 = isBezier && path.getControlPoint(this.edgeIndex, 1, this.loopIndex);
    this.oldCp2 = isBezier && path.getControlPoint(this.edgeIndex, 2, this.loopIndex);
    this.oldEllipseArcParams = path.getEllipseArcParams(this.edgeIndex, this.loopIndex);

    // Store new control points (if any)
    this.newEdgeType = edgeType;
    this.newCp1 = cp1;
    this.newCp2 = cp2;
    this.newEllipseArcParams = arcParams;
  }

  redo() {
    switch (this.newEdgeType) {
      case EdgeType.Line:this.path.removeArc(this.edgeIndex, this.loopIndex);break;
      case EdgeType.Ellipse:this.path.setEllipseArc(this.edgeIndex, this.newEllipseArcParams, this.loopIndex);break;
      case EdgeType.Bezier:this.path.setBezierArc(this.edgeIndex, this.newCp1.x, this.newCp1.y, this.newCp2.x, this.newCp2.y, this.loopIndex);break;
    }
  }

  undo() {
    switch (this.oldEdgeType) {
      case EdgeType.Line:this.path.removeArc(this.edgeIndex, this.loopIndex);break;
      case EdgeType.Bezier:this.path.setBezierArc(this.edgeIndex, this.oldCp1.x, this.oldCp1.y, this.oldCp2.x, this.oldCp2.y, this.loopIndex);break;
      case EdgeType.Ellipse:this.path.setEllipseArc(this.edgeIndex, this.oldEllipseArcParams, this.loopIndex);break;
    }
  }

  // After undo/redo, select changed edge
  getSelectionHint(afterUndo) {
    return {
      shape: this.path,
      edge: new PolyIndex({ vertex: this.edgeIndex, loop: this.loopIndex })
    };
  }
}

class ApplyCutOut extends Action {

  constructor(layer, cutPath) {
    super(layer);

    // collect all other polygons and closed paths in the layer that overlap the cutPath bbox
    const cutBox = cutPath.getBBox();
    this.paths = layer.shapes.filter((s) => {

      if (s === cutPath) {
        return false;
      }

      // cutout is only be applied on Polygons and closed Paths
      const isClosed = s instanceof PolyBase && s.isClosed;
      if (!isClosed) {
        return false;
      }

      const box = s.getBBox();
      return cutBox.intersectsBox(box);
    });

    // subtract cutPath from all overlapping shapes
    this.clippedPaths = this.paths.map((p) => {
      return BooleanOps.apply(p, cutPath, BooleanOps.Operator.Difference);
    });

    // create backups of original paths before cutout
    this.originalShapes = this.paths.map((p) => p.clone());

    this.cutPath = cutPath;
  }

  redo() {
    // replace geometry of all shapes by clipped versions
    this.paths.forEach((p, i) => p.copyGeometry(this.clippedPaths[i]));
    this.layer.update();
  }

  undo() {
    // replace all shapes by original versions
    this.paths.forEach((p, i) => p.copyGeometry(this.originalShapes[i]));
  }

  // After undo/redo, select shape that was used for cutout
  getSelectionHint(afterUndo) {
    return {
      shape: this.cutPath
    };
  }
}

class RemoveLoops extends Action {

  constructor(layer, poly, loops) {
    super(layer);

    this.poly = poly;
    this.loops = loops;

    // create a backup of the shape before change
    this.before = this.poly.clone();
  }

  redo() {
    this.poly.removeLoops(this.loops);
  }

  undo() {
    this.poly.copy(this.before);
  }

  // After undo/redo, select modified shape
  getSelectionHint(afterUndo) {
    return {
      shape: this.poly
    };
  }
}

class MoveLoop extends Action {

  constructor(layer, poly, loopIndex, dx, dy) {
    super(layer);

    this.poly = poly;
    this.loopIndex = loopIndex;
    this.before = this.poly.clone();

    this.dx = dx;
    this.dy = dy;
  }

  redo() {
    this.poly.moveLoop(this.dx, this.dy, this.loopIndex);
  }

  undo() {
    this.poly.copy(this.before);
  }

  // After undo/redo, select modified shape
  getSelectionHint(afterUndo) {
    return {
      shape: this.poly
    };
  }
}

// Merges all shapes into the first one
class UnifyShapes extends Action {

  // @param {EditLayer}  layer
  // @param {PolyBase[]} shapes     - shapes to merge
  // @param {number}     shapeIndex - index within shapes array. All other shapes will be merged into this one.
  constructor(layer, shapes, shapeIndex) {
    super(layer);

    this.merged = BooleanOps.apply(shapes[0], shapes[1], BooleanOps.Operator.Union, shapes.slice(2));
    this.before = shapes[shapeIndex].clone();

    this.mainShape = shapes[shapeIndex];
    this.otherShapes = shapes.filter((s) => s !== this.mainShape);
  }

  redo() {

    // Merge other shapes into main shape
    this.mainShape.copyGeometry(this.merged);

    // Remove all other shapes
    this.layer.removeShapes(this.otherShapes);
  }

  undo() {
    // Recover original main shape
    this.mainShape.copyGeometry(this.before);

    // Add other shapes back to layer
    this.layer.addShapes(this.otherShapes);
  }

  // After undo/redo, select mainShape
  getSelectionHint(afterUndo) {
    return {
      shape: this.mainShape
    };
  }
}

// Changes Bezier arc tangent at a vertex v. This may affect up to two edge adjacent to v.
class ChangeBezierTangent {

  // @param {EditLayer} layer
  // @param {Path}      path
  // @param {number}    vertex     - index of the vertex to be changed
  // @param {number}    loopIndex
  // @param {Vector2}   pStart     - tangent start. This is cp2 of the previous bezier segment (if any)
  // @param {Vector2}   pEnd       - tangent end
  constructor(layer, path, index, loopIndex, pStart, pEnd) {
    this.layer = layer;
    this.path = path;
    this.index = index;
    this.loopIndex = loopIndex;
    this.pStart = pStart;
    this.pEnd = pEnd;

    // Backup of original shape
    this.before = path.clone();
  }

  // Set the two control points that define the bezier tangent. Usually, pEnd is just pStart mirrored at the vertex
  setTangent(pStart, pEnd) {
    this.pStart = pStart;
    this.pEnd = pEnd;
  }

  // Apply modified tangent endpoints after dragging on of the tangent vertices.
  //
  // @param {Vector2} pStart, pEnd - Tangent start/end point in layer coords.
  redo() {

    // Set start point: This is cp2 of previous arc segment (if any)
    const prevEdgeIndex = this.path.edgeBeforeVertex(this.index, this.loopIndex);
    const prevExists = prevEdgeIndex !== -1;
    if (prevExists && this.path.isBezierArc(prevEdgeIndex, this.loopIndex)) {
      this.path.updateControlPoint(prevEdgeIndex, 2, this.pStart.x, this.pStart.y, this.loopIndex);
    }

    // Set end point: This is cp1 of current segment
    const nextEdgeIndex = this.path.edgeAfterVertex(this.index, this.loopIndex);
    const nextExists = nextEdgeIndex !== -1;
    if (nextExists && this.path.isBezierArc(nextEdgeIndex, this.loopIndex)) {
      this.path.updateControlPoint(this.index, 1, this.pEnd.x, this.pEnd.y, this.loopIndex);
    }
  }

  undo() {
    this.path.copyGeometry(this.before);
  }

  // ChangeBezierTangent may happen if a vertex or an edge is selected. So, the hint must be set from
  // outside when the information is available.
  //   @param {PolyIndex} vertex
  //   @param {PolyIndex} edge
  setSelectionItem(vertex, edge) {
    this.selectionHint = {
      shape: this.path,
      vertex: vertex,
      edge: edge
    };
  }

  // After undo/redo, select the edge that was modified
  getSelectionHint(afterUndo) {
    return this.selectionHint;
  }
}

export const Actions = {
  Action,
  AddShape,
  AddShapes,
  MoveShapes,
  RemoveShape,
  RemoveShapes,
  AddVertex,
  MoveVertex,
  RemoveVertex,
  MoveEdge,
  ChangeEdgeType,
  ApplyCutOut,
  RemoveLoops,
  UnifyShapes,
  MoveLoop,
  ChangeBezierTangent
};