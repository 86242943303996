
const SIZE_VB = 64 * 1024 * 1024;
const SIZE_IB = 16 * 1024 * 1024;
const SIZE_MB = 32 * 1024 * 1024;

let nextId = 1;

//Maintains a list of free memory block within a GPU side buffer. Basically a minimal heap implementation.
//Used when we load/unload geometry repeatedly and dynamically when switching views
//This is a naive implementation that needs optimization after we do some benchmarking.
function FreeList(heapSize) {
  this.starts = new Map();
  this.ends = new Map();

  //Add the initial free block
  this.addFreeBlock(0, heapSize);
}

FreeList.prototype.addFreeBlock = function (offset, size) {

  let start = offset;
  let end = offset + size;

  //See if the newly freed block is to the left of (before) an already free block, and combine them
  let endNext = this.starts.get(end);
  if (endNext !== undefined) {
    this.starts.delete(end);
    end = endNext;
  }

  //See if the newly freed block is to the right of (after) an already free block, and combine them
  //That this can be true even if there was a free block on the right (case above)
  let startBefore = this.ends.get(start);
  if (startBefore !== undefined) {
    this.ends.delete(start);
    start = startBefore;
  }

  this.starts.set(start, end);
  this.ends.set(end, start);
};

FreeList.prototype.findBlock = function (size) {

  //Look for a free block that's big enough to hold the requested size
  //This is just a naive spin through all free blocks
  let foundStart, foundEnd;
  for (const [start, end] of this.starts) {
    if (end - start >= size) {
      foundStart = start;
      foundEnd = end;
      break;
    }
  }

  if (foundStart === undefined) {
    return undefined;
  }

  let remainingStart = foundStart + size;

  this.starts.delete(foundStart);
  if (remainingStart === foundEnd) {
    //No space left in this chunk, remove it from the free list completely
    this.ends.delete(foundEnd);
  } else {
    //Create a smaller new chunk
    this.starts.set(remainingStart, foundEnd);
    this.ends.set(foundEnd, remainingStart);
  }

  return foundStart;
};



export class BumpAllocator {

  #vbListPerStride = {};
  #ibList = [];
  #materialList = [];
  #device;

  constructor(device) {
    this.#device = device;
  }

  /**
   * Allocate memory for geometry vertex buffer.
   * @param {number} size The amount of memory to allocate, in bytes.
   * @param {number} strideBytes The stride of the vertex buffer, in bytes.
   * @returns {Array<any>} An array where the first entry is an object that represents the underlying buffer, and the
   * 	second entry is the index of the first vertex in the underlying buffer.
   */
  vAlloc(size, strideBytes) {

    let curVB;
    let resOffset;

    //Search any previously allocated buffers that might have space
    let vbListPerStride = this.#vbListPerStride[strideBytes];
    if (resOffset === undefined && vbListPerStride) {
      for (let i = vbListPerStride.length - 1; i >= 0; i--) {
        resOffset = vbListPerStride[i].heap.findBlock(size);
        if (resOffset !== undefined) {
          curVB = vbListPerStride[i];
          break;
        }
      }
    }

    if (resOffset === undefined) {

      let vb = this.#device.createBuffer({
        size: SIZE_VB,
        usage: GPUBufferUsage.VERTEX | GPUBufferUsage.COPY_DST,
        mappedAtCreation: false
      });

      curVB = {
        buffer: vb,
        heap: new FreeList(SIZE_VB),
        id: nextId++
      };

      if (!this.#vbListPerStride[strideBytes]) {
        this.#vbListPerStride[strideBytes] = [curVB];
      } else {
        this.#vbListPerStride[strideBytes].push(curVB);
      }

      resOffset = curVB.heap.findBlock(size);
    }

    return [curVB, resOffset / strideBytes];
  }

  /**
   * Allocate memory for geometry index buffer.
   * @param {number} size The amount of memory to allocate, in bytes.
   * @returns {Array<any>} An array where the first entry is an object that represents the underlying buffer, and the
   * 	second entry is the index of the first vertex index in the underlying buffer.
   */
  iAlloc(size) {

    let curIB;
    let resOffset;

    for (let i = this.#ibList.length - 1; i >= 0; i--) {
      resOffset = this.#ibList[i].heap.findBlock(size);
      if (resOffset !== undefined) {
        curIB = this.#ibList[i];
        break;
      }
    }

    if (resOffset === undefined) {

      let ib = this.#device.createBuffer({
        size: SIZE_IB,
        usage: GPUBufferUsage.INDEX | GPUBufferUsage.COPY_DST,
        mappedAtCreation: false
      });

      curIB = {
        buffer: ib,
        heap: new FreeList(SIZE_IB),
        id: nextId++
      };

      this.#ibList.push(curIB);

      resOffset = curIB.heap.findBlock(size);
    }

    return [curIB, resOffset];
  }

  /**
   * Allocate memory for a material.
   * @param {number} size The amount of memory to allocate, in bytes.
   * @returns {Array<any>} An array where the first entry is an object that represents the underlying buffer, and the
   * 	second entry is the offset of the newly allocated memory chunk in the underlying buffer.
   */
  mAlloc(size) {

    let curMB;
    let resOffset;

    for (let i = this.#materialList.length - 1; i >= 0; i--) {
      resOffset = this.#materialList[i].heap.findBlock(size);
      if (resOffset !== undefined) {
        curMB = this.#materialList[i];
        break;
      }
    }

    if (resOffset === undefined) {

      const mb = this.#device.createBuffer({
        size: SIZE_MB,
        usage: GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_DST
      });

      curMB = {
        buffer: mb,
        stride: size,
        heap: new FreeList(SIZE_MB),
        id: nextId++
      };

      this.#materialList.push(curMB);

      resOffset = curMB.heap.findBlock(size);
    }

    return [curMB, resOffset];
  }

  gFree(geometry) {
    if (geometry.__gpuvb) {
      geometry.__gpuvb.heap.addFreeBlock(geometry.__gpuvbBaseVertex * geometry.vbstride * 4, geometry.__gpuvbSize);
      geometry.__gpuvb = undefined;
    }

    if (geometry.__gpuib) {
      geometry.__gpuib.heap.addFreeBlock(geometry.__gpuibBaseIndex << geometry.__gpuibShift, geometry.__gpuibSize);
      geometry.__gpuib = undefined;
    }

    geometry.__gpu = undefined;
  }

  mFree(material) {
    if (material.__gpumb) {
      material.__gpumb.heap.addFreeBlock(material.__gpumbOffset, material.__gpumb.stride);
      material.__gpumb = undefined;
      material.__gpumbOffset = undefined;
    }
  }

  stats() {
    console.log(this.#vbListPerStride, this.#ibList);
  }
}