import { DtConstants } from "./DtConstants";
import { ElementIdWithFlagsSize, ModelIdSize, RowKeySize } from "./dt-schema";
import { base64EncArr, base64DecToArr, base64DecodedLen } from "../encoding/base64";

let _tmpBuf = new Uint8Array(0);

/**
 * @param {number} len Length of decoded value
 * @param {number} expectedMultipleOf
 * @returns {Error}
 */
class B64DecKeyInvalidLengthError extends Error {
  constructor(len, expectedMultipleOf) {
    const message = `Invalid decoded key(s) length: ${len}, decoded key length must be a multiple of ${expectedMultipleOf}`;
    super(message);
  }
}

/**
 * Callback for enumB64ElementIdWithFlags, return true to stop iterating
 *
 * @callback enumElmtIdWithFlagsCB
 * @param {string} elementIdWithFlags
 * @returns {boolean}
 */

/**
 * @param {String} b64Str Base64 encoded string representing a list of 24Bytes elementIdWithFlags
 * @param {enumElmtIdWithFlagsCB} cb Callback function executed on every ID, iteration stops when function returns true
 * @param {Number} off Offset integer, start iteration at 'off' number of keys
 * @throws B64DecKeyInvalidLengthError
 */
export function enumB64ElementIdWithFlags(b64Str, cb) {let off = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  const len = base64DecodedLen(b64Str.length);

  if (len % ElementIdWithFlagsSize !== 0) {
    throw new B64DecKeyInvalidLengthError(len, ElementIdWithFlagsSize);
  }

  if (len > _tmpBuf.length) {
    _tmpBuf = new Uint8Array(len);
  }

  base64DecToArr(b64Str, _tmpBuf);

  for (off *= ElementIdWithFlagsSize; off < len; off += ElementIdWithFlagsSize) {
    const elementID = base64EncArr(_tmpBuf, off, ElementIdWithFlagsSize);

    if (cb(elementID)) {
      // Early exit
      return;
    }
  }
}

/**
 * Callback for enumB64FullId, return true to stop iterating
 *
 * @callback enumFullIdCB
 * @param {string} urn
 * @param {string} elementIdWithFlags
 * @returns {boolean}
 */

/**
 * @param {String} b64Str Base64 encoded string representing a list of 40Bytes rowIds
 * @param {enumFullIdCB} cb Callback function executed on every ID, iteration stops when function returns true
 * @param {Number} off Offset integer, start iteration at 'off' number of keys
 * @throws B64DecKeyInvalidLengthError
 */
export function enumB64FullId(b64Str, cb) {let off = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  const len = base64DecodedLen(b64Str.length);

  if (len % RowKeySize !== 0) {
    throw new B64DecKeyInvalidLengthError(len, RowKeySize);
  }

  if (len > _tmpBuf.length) {
    _tmpBuf = new Uint8Array(len);
  }

  base64DecToArr(b64Str, _tmpBuf);

  for (off *= RowKeySize; off < len; off += RowKeySize) {
    const urn = DtConstants.DT_MODEL_URN_PREFIX + ":" + base64EncArr(_tmpBuf, off, ModelIdSize);
    const elementID = base64EncArr(_tmpBuf, off + ModelIdSize, ElementIdWithFlagsSize);

    if (cb(urn, elementID)) {
      // Early exit
      return;
    }
  }
}