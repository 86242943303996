import { Label3D } from "../../gui/Label3D";
import { fadeValue } from "../../tools/viewtransitions/ViewTransition";
import { LmvBox3 } from "../../wgs/scene/LmvBox3";

const BOX_OFFSET = 1;
let _tmpBox1 = new LmvBox3();
export const HUD_LABEL_TRANSFORM_EVENT = "hud_label_transform_event";
export const LABEL_ANCHORING = Object.freeze({
  START: 's',
  BOTTOM_MIDDLE: 'bm',
  MIDDLE: 'm',
  END: 'e'
});

/**
 * Advanced label configuration.
 * @typedef {Object} HUDLabelOptions
 * @extends Label3DOptions
 * @property {boolean} clickSelects - Whether to treat click on the label as selecting the element
 */

export class HUDLabel extends Label3D {
  /**
   * Label class for the purpose of the HUD.
   * @param {Viewer3D} viewer
   * @param {THREE.Vector3} pos3D World position of the label.
   * @param {string} text
   * @param {string} labelId
   * @param {HUDLabelOptions} options
   */
  constructor(viewer, pos3D, text, labelId, options) {
    super(viewer, pos3D, text, options);
    this.labelId = labelId;

    // Assign container as default listeners target
    this.target = this.container;

    this.priority = 0;
    this.offsetAnimControl = null;
    this.worldBox = null;

    this.element = null;
    this.labelAnchor = LABEL_ANCHORING.MIDDLE;

    if (options?.clickSelects) {
      this.boundOnClick = (_ref) => {let { event } = _ref;
        if (selectElement(this.viewer, this.element, Boolean(event.shiftKey))) {
          event.preventDefault();
          event.stopPropagation();
        }
      };
      this.addEventListener(Label3D.Events.CLICK, this.boundOnClick);
    }
  }

  setText(text) {
    super.setText(text);

    // approximation to get width of the element when "this.textDiv.getBoundingClientRect()" returns 0
    this.textMetrics = Label3D.getTextMeasurements(this.viewer, text);
  }

  getText() {
    return this.textDiv.textContent || '';
  }

  getTextWidthInPixel() {
    const bcr = this.textDiv.getBoundingClientRect();
    return bcr.width ?? this.textMetrics.width;
  }

  getTextHeightInPixel() {
    const bcr = this.textDiv.getBoundingClientRect();
    return bcr.height ?? this.textMetrics.fontBoundingBoxAscent + this.textMetrics.fontBoundingBoxDescent;
  }

  setBGColor(color) {
    this.container.style.backgroundColor = color;
  }

  setColor(color) {
    this.container.style.color = color;
  }

  update() {
    if (this.offsetAnimControl?.isRunning) {
      return;
    }

    // Get canvas position corresponding to this.pos3D
    let { x, y } = this.viewer.impl.worldToClient(this.pos3D);
    x += this.offset.x;
    y += this.offset.y;

    this.pos2D.set(x, y, 0);

    this.container.style.transform = this.getAnchoredTransform(x, y);

    // Hide label if the annotated object is small on screen
    const hidden = !this.visible || this.shouldBeHidden();

    // If the label should be visible, immediately restore the container visibility, so the fade-in will be displayed.
    if (!hidden) {
      this.changeContainerVisibility(!hidden);
    }

    // this.opacityParam.skipAnim();
    this.opacityParam.fadeTo(hidden ? 0.0 : 1.0, () => {
      // If the label should be hidden, change container visibility only after the fade-out animation finished.
      // This is needed in order that the element won't be touchable while hidden.
      this.changeContainerVisibility(!hidden);
    });
  }

  draw(hud, mvpMatrix, drawBox, drawLeadLine) {
    const ctx = hud.ctx;
    const offset = this.offset;
    const worldbox = this.worldBox;

    ctx.lineWidth = 2.5;
    ctx.strokeStyle = "rgba(127,255,127,1)";

    _tmpBox1.copy(worldbox);
    _tmpBox1.applyProjection(mvpMatrix);

    // Draw line to label, if label is visible and not going to move
    if (drawLeadLine && this.visible && !this.offsetAnimControl?.isRunning) {
      const pos2D = _tmpBox1.getCenter();

      const x = (pos2D.x * 0.5 + 0.5) * hud.width;
      const y = (-pos2D.y * 0.5 + 0.5) * hud.height;
      const lx = x + offset.x * hud.dpr;
      const ly = y + offset.y * hud.dpr;

      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(lx, ly);
      ctx.stroke();
    }

    if (drawBox) {
      const left = (_tmpBox1.min.x * 0.5 + 0.5) * hud.width;
      const right = (_tmpBox1.max.x * 0.5 + 0.5) * hud.width;
      const bottom = (-_tmpBox1.min.y * 0.5 + 0.5) * hud.height;
      const top = (-_tmpBox1.max.y * 0.5 + 0.5) * hud.height;

      //box around objects
      ctx.beginPath();
      ctx.moveTo(left - BOX_OFFSET, top - BOX_OFFSET);
      ctx.lineTo(right + BOX_OFFSET, top - BOX_OFFSET);
      ctx.lineTo(right + BOX_OFFSET, bottom + BOX_OFFSET);
      ctx.lineTo(left - BOX_OFFSET, bottom + BOX_OFFSET);
      ctx.closePath();

      ctx.stroke();
    }
  }

  getAnchoredTransform(x, y) {
    switch (this.labelAnchor) {
      case LABEL_ANCHORING.START:
        return `translate(calc(${x}px), calc(${y}px - 50%))`;
      case LABEL_ANCHORING.END:
        return `translate(calc(${x}px - 100%), calc(${y}px - 50%))`;
      case LABEL_ANCHORING.BOTTOM_MIDDLE:
        return `translate(calc(${x}px - 50%), calc(${y}px - 100%))`;
      case LABEL_ANCHORING.MIDDLE:
      default:
        return `translate(calc(${x}px - 50%), calc(${y}px - 50%))`;
    }
  }

  getCanvasPosition() {
    return {
      x: this.pos2D.x,
      y: this.pos2D.y
    };
  }

  setOffset(offset, noEvent) {
    if (this.offsetAnimControl?.isRunning) {
      return;
    }

    const dx = offset.x - this.offset.x;
    const dy = offset.y - this.offset.y;

    const onTimer = (t) => {
      let { x, y } = this.viewer.impl.worldToClient(this.pos3D);
      x += this.offset.x + t * dx;
      y += this.offset.y + t * dy;

      this.container.style.transform = this.getAnchoredTransform(x, y);
    };

    const onFinished = () => {
      this.offset = offset;
      if (!noEvent) {
        this.viewer.dispatchEvent(HUD_LABEL_TRANSFORM_EVENT);
      }
    };

    this.offsetAnimControl = fadeValue(0.0, 1.0, 0.5, onTimer, onFinished);
  }

  setPriority(priority) {
    this.priority = priority;
  }

  getPriority() {
    return this.priority;
  }

  setElement(element) {
    this.element = element;
  }

  getElement() {
    return this.element;
  }
}

function selectElement(viewer, element, isToggle) {
  if (!element) return false;

  const { dbId, model } = element;
  if (isToggle) {
    viewer.toggleSelect(dbId, model);
  } else {
    viewer.select(dbId, model);
  }

  return true;
}

HUDLabel.Events = Label3D.Events;