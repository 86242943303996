import { wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";
import { getCameraUniformsDeclaration } from "./CameraUniforms";
import { getObjectUniformsDeclaration } from "./ObjectUniforms";
import ghosting from "../chunks/ghosting.wgsl";
import { getMaterialUniformsDeclaration } from "./MaterialUniforms";
import { getIBLDeclaration } from "./IBL";


export function getEdgeShader() {let ghosted = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

  return wgsl /*wgsl*/`

	${ghosting}
	${getIBLDeclaration(0)}
	${getCameraUniformsDeclaration(1)}
	${getObjectUniformsDeclaration(2)}
	${getMaterialUniformsDeclaration(3)}


	struct VertexOutput {
		@builtin(position) Position : vec4f,
		@location(0) @interpolate(flat) instance: u32,
		@location(4) vWorldPosition: vec3f
	}

	@vertex
	fn vsmain(
		@location(0) position : vec3f,
		@builtin(instance_index) instance : u32
	) -> VertexOutput {

		var objectUniforms = getObjectUniforms(instance);

		var output : VertexOutput;
		var pos4 = vec4f(position.x, position.y, position.z, 1.0);

		var mvMatrix = uniforms.viewMatrix * objectUniforms.modelMatrix;
		var mvPosition = mvMatrix * pos4;

		output.Position = uniforms.projectionMatrix * mvPosition;
		output.vWorldPosition = (objectUniforms.modelMatrix * pos4).xyz;

		//TODO: world position for cutplanes

		output.instance = instance;

		return output;
	}

	@fragment
	fn psmain(
		in: VertexOutput
	) -> @location(0) vec4f {

		var objectUniforms = getObjectUniforms(in.instance);
		var materialUniforms = getMaterialUniforms(objectUniforms.materialIndex);

		//check doNotCut flag
		if (commonMaterialUniforms.doNotCutOverride + (materialUniforms.renderFlags & 2) == 0) {
			checkCutPlanes(in.vWorldPosition);
		}

		var color = intToVecf(commonMaterialUniforms.edgeColor);

		#if ${ghosted}
		checkGhosting(vec2i(floor(in.Position.xy)), true);
		#endif

		return vec4(color.r, color.g, color.b, color.a);
	}
	`;

}