
export class Debounce {

  //Given operation must return a Promise
  constructor(operation) {
    this.inProgress = {};
    this.operation = operation;
  }

  async debounce(key, args) {

    //Check if the given key is already in progress
    if (!this.inProgress[key]) {

      let resultP = this.inProgress[key] = this.operation.apply(null, args);
      let result;

      try {
        result = await resultP;
      } catch (e) {
        result = Promise.reject(e);
      }

      delete this.inProgress[key];

      return result;

    } else {

      //Debounce -- join the request for the same resource already in progress
      return this.inProgress[key];
    }
  }

}