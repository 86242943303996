

import { SolidDefConvert } from './SolidDefConvert.js';
import { SketchRegionSolver, mergeConnectedFaces } from '@adsk/solid-definition';
import { getFacesInsideLoop } from './LoopContainment.js';

const Operator = {
  Intersect: 1, // only keep regions where path1 and path2 are overlapping
  Union: 2, // unify both shapes
  Difference: 3, // path1 after removing all regions that are overlapped by path2
  Xor: 4 // only keep regions that are either covered by path1 or path2, but not both
};

// Returns all faces that are "inside the given wireBody", whereby:
//  - All wires in wireBody must be closed loops without branching
//  - A face is considered as "inside" if it is enclosed by an odd number of wires ("Even-Odd-Rule")
//
// @param {SketchRegionSolver} solver   - initialized with all edges of subject and cutLoop
// @param {SolidDef.WireBody}  wireBody - must contain closed loops.
// Returns the subset of region faces that is inside the given wireBody. 
const getFacesInside = (solver, wireBody) => {

  // Mark all faces as rank 0, indicating that we did not find an enclosing loop yet
  // Note: It's not perfectly clean to add extra attributes, but...
  //  a) Since faces don't have unique ids, we don't have proper way to index them without modifying
  //  b) All faces here are only temporary.
  const regionFaces = solver.getFaces();
  regionFaces.forEach((f) => f.rank = 0);

  // for each wire
  const wires = wireBody.getWires();
  wires.forEach((w) => {

    // get faces inside this loop
    const loopEdges = w.getEdges();
    const facesInside = getFacesInsideLoop(solver, loopEdges);

    // increase rank for all faces inside this loop
    facesInside.forEach((f) => f.rank++);
  });

  // Return all faces whose rank is not a multiple of 2
  const hasOddRank = (f) => Boolean(f.rank & 1);
  return regionFaces.filter(hasOddRank);
};

// Apply boolean operation on two SolidDef paths (must be closed).
//
// @param {SolidDef.Path2D}   path1           - the path to be clipped.
// @param {SolidDef.Path2D}   path2           - to be cut away. Must be a single loop.
// @param {Operator}          operator
// @param {SolidDef.Path2D[]} [extraOperands] - Unify supports more than 2 operands.
// @returns {SolidDef.Face[]} 
const applyOperation = (path1, path2, operator, extraOperands) => {

  // get paths as wires
  const wireBody1 = path1.getWireBody();
  const wireBody2 = path2.getWireBody();

  // get both paths as edge arrays
  const edges1 = wireBody1.getEdges();
  const edges2 = wireBody2.getEdges();

  // get unified array with edges of both parts 
  const allEdges = edges1.concat(edges2);

  // add edges of additional operands
  extraOperands && extraOperands.forEach((p) => {
    const wb = p.getWireBody();
    const edges = wb.getEdges();
    allEdges.push(...edges);
  });

  // Feed them into solver to intersect them against each other and extract the resulting region faces.
  const solver = new SketchRegionSolver();
  solver.compute(allEdges);
  const allFaces = solver.getFaces();

  // check which of the faces are within path and cutPath        
  const facesInPath1 = getFacesInside(solver, wireBody1);
  const facesInPath2 = getFacesInside(solver, wireBody2);

  // check which faces are in any of the extra operands
  const facesInExtraPaths = extraOperands && extraOperands.map((p) => {
    const wb = p.getWireBody();
    return getFacesInside(solver, wb);
  });

  // Filter faces based on operation type.
  // Note: We have a O(numEdges^2) runtime here, which might be an issue for number of faces. 
  //       If needed, this could be optimized by tagging the edges with unique IDs in advance and indexing
  //       the faces by id.
  const filter = (f) => {
    const inPath1 = facesInPath1.includes(f);
    const inPath2 = facesInPath2.includes(f);

    // Check if face is contained in any of the extra paths
    const inExtraPath = facesInExtraPaths && facesInExtraPaths.some((faceSet) => faceSet.includes(f));

    switch (operator) {
      case Operator.Union:return inPath1 || inPath2 || inExtraPath;
      case Operator.Intersect:return inPath1 && inPath2;
      case Operator.Difference:return inPath1 && !inPath2;
      case Operator.Xor:return inPath1 !== inPath2;
    }
  };
  const selectedFaces = allFaces.filter(filter);

  // Finally, merge these faces to obtain result
  return mergeConnectedFaces(selectedFaces);
};

//  @param {PolyBase[]} [extraOperands] - For unify, we allow moore than 2 operands.
const apply = (path1, path2, operator, extraOperands) => {
  // Convert to SolidDef
  const path1Sd = SolidDefConvert.toSolidDefPath(path1);
  const path2Sd = SolidDefConvert.toSolidDefPath(path2);

  const extraOperandsSD = extraOperands && extraOperands.map((p) => SolidDefConvert.toSolidDefPath(p));

  // Run operation
  const faces = applyOperation(path1Sd, path2Sd, operator, extraOperandsSD);

  // Convert SolidDef faces back to Edit2D paths
  const result = SolidDefConvert.facesToShape(faces);

  // Let result inherit style of path1
  result.style.copy(path1.style);

  return result;
};

export const BooleanOps = {
  Operator,
  apply
};